import { WidgetSettings } from "./WidgetSettings"
import { useEditor, useNode } from "@craftjs/core";
import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Component from "./Component";
import Draggable from 'react-draggable';
import ACTION_TRIGGER_VIEW from "../../../../../../actions/TriggerView/ACTION_TRIGGER_VIEW";
import {useDispatch} from "react-redux";

export const Wizard = (props) => {
    const {
            actions: {setProp},
connectors: { connect },
    } = useNode((node) => ({
      selected: node.events.selected,
    }));
  
    const {
      setActiveView,
dataBaseDataArr,
      eventsDataArr,
      x,
      y,
      applyFilter
      } = props

      const [resultsArr, setResultsArr] = useState(null)
      useEffect(() => {
        if(dataBaseDataArr) {
          console.log(dataBaseDataArr)
          let newArr = dataBaseDataArr?.results
          let db = dataBaseDataArr.db
          setResultsArr(newArr)
        }
      },[dataBaseDataArr])
    const { text, textComponent, color, ...otherProps } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [activeSelect, setActiveSelect] = useState("Organizations")

  const {
    enabled,
    connectors, 
    actions,
    json,
    nodes
  } = useEditor((state, query) => ({
    enabled: state.options.enabled || false,
    json: query.serialize() ,
    nodes: state.nodes
  }));

  const changeView = (id) => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = {_id: id}
    })
  }
  const changeSelect = (ob) => {
    setActiveSelect(ob.name)
    changeView(ob._id)
  }
  
  const resetView = () => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = null
    })
    setActiveSelect("Organizations")
  }
const [startingPos, setStartingPos] = useState({ x: x, y: y })
const handleStop = (e, data) => {
    console.log(data, 123)
    setStartingPos( { x: data.x, y: data.y })
    setProp(prop => {
      prop.x = data.x;
      prop.y = data.y;
    }, 500)}
  useEffect(() => {
    if(eventsDataArr) {

      console.log(eventsDataArr)
    }
  },[eventsDataArr])
    return (        
  <Draggable defaultClassName="dgs" onStop={handleStop} position={startingPos}>
                  <div  ref={connect}>  
      <Component />
    </div>
</Draggable> 
    
    );
  };
  
   Wizard.craft = {
    displayName: 'Wizard',
    props: {
      background: { r: 255, g: 255, b: 255, a: 0.5 },
      color: { r: 92, g: 90, b: 90, a: 1 }, 
      buttonStyle: 'full',
      text: 'Button',
      margin: ['5', '0', '5', '0'],   
      x:0,
      y:0, 
       applyFilter: null
    },
    related: {
      toolbar: WidgetSettings,
    },
  };
  