import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Ecommerce1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap -m-4">
      <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
        <a class="block relative rounded overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div363181"} is="div">
          <ImageC alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/420x260"  />
        </Element>
        </a>
        <div class="mt-4">
          <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1"> {<Element canvas style={{display:"inline-block"}} id="856626" is="div"><Text text="CATEGORY" /></Element>} </h3>
          <h2 class="text-gray-900 title-font text-lg font-medium"> {<Element canvas style={{display:"inline-block"}} id="576524" is="div"><Text text="The Catalyzer" /></Element>} </h2>
          <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="802060" is="div"><Text text="$16.00" /></Element>} </p>
        </div>
      </div>
      <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
        <a class="block relative rounded overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div904108"} is="div">
          <ImageC alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/421x261"  />
        </Element>
        </a>
        <div class="mt-4">
          <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1"> {<Element canvas style={{display:"inline-block"}} id="818123" is="div"><Text text="CATEGORY" /></Element>} </h3>
          <h2 class="text-gray-900 title-font text-lg font-medium"> {<Element canvas style={{display:"inline-block"}} id="487798" is="div"><Text text="Shooting Stars" /></Element>} </h2>
          <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="820218" is="div"><Text text="$21.15" /></Element>} </p>
        </div>
      </div>
      <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
        <a class="block relative rounded overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div920836"} is="div">
          <ImageC alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/422x262"  />
        </Element>
        </a>
        <div class="mt-4">
          <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1"> {<Element canvas style={{display:"inline-block"}} id="840082" is="div"><Text text="CATEGORY" /></Element>} </h3>
          <h2 class="text-gray-900 title-font text-lg font-medium"> {<Element canvas style={{display:"inline-block"}} id="725733" is="div"><Text text="Neptune" /></Element>} </h2>
          <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="100391" is="div"><Text text="$12.00" /></Element>} </p>
        </div>
      </div>
      <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
        <a class="block relative rounded overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div861076"} is="div">
          <ImageC alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/423x263"  />
        </Element>
        </a>
        <div class="mt-4">
          <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1"> {<Element canvas style={{display:"inline-block"}} id="641716" is="div"><Text text="CATEGORY" /></Element>} </h3>
          <h2 class="text-gray-900 title-font text-lg font-medium"> {<Element canvas style={{display:"inline-block"}} id="163336" is="div"><Text text="The 400 Blows" /></Element>} </h2>
          <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="945539" is="div"><Text text="$18.40" /></Element>} </p>
        </div>
      </div>
      <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
        <a class="block relative rounded overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div345260"} is="div">
          <ImageC alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/424x264"  />
        </Element>
        </a>
        <div class="mt-4">
          <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1"> {<Element canvas style={{display:"inline-block"}} id="263211" is="div"><Text text="CATEGORY" /></Element>} </h3>
          <h2 class="text-gray-900 title-font text-lg font-medium"> {<Element canvas style={{display:"inline-block"}} id="852670" is="div"><Text text="The Catalyzer" /></Element>} </h2>
          <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="316737" is="div"><Text text="$16.00" /></Element>} </p>
        </div>
      </div>
      <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
        <a class="block relative rounded overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div719358"} is="div">
          <ImageC alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/425x265"  />
        </Element>
        </a>
        <div class="mt-4">
          <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1"> {<Element canvas style={{display:"inline-block"}} id="353389" is="div"><Text text="CATEGORY" /></Element>} </h3>
          <h2 class="text-gray-900 title-font text-lg font-medium"> {<Element canvas style={{display:"inline-block"}} id="807377" is="div"><Text text="Shooting Stars" /></Element>} </h2>
          <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="714491" is="div"><Text text="$21.15" /></Element>} </p>
        </div>
      </div>
      <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
        <a class="block relative rounded overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div608998"} is="div">
          <ImageC alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/427x267"  />
        </Element>
        </a>
        <div class="mt-4">
          <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1"> {<Element canvas style={{display:"inline-block"}} id="379676" is="div"><Text text="CATEGORY" /></Element>} </h3>
          <h2 class="text-gray-900 title-font text-lg font-medium"> {<Element canvas style={{display:"inline-block"}} id="436035" is="div"><Text text="Neptune" /></Element>} </h2>
          <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="643853" is="div"><Text text="$12.00" /></Element>} </p>
        </div>
      </div>
      <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
        <a class="block relative rounded overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div634826"} is="div">
          <ImageC alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://dummyimage.com/428x268"  />
        </Element>
        </a>
        <div class="mt-4">
          <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1"> {<Element canvas style={{display:"inline-block"}} id="612365" is="div"><Text text="CATEGORY" /></Element>} </h3>
          <h2 class="text-gray-900 title-font text-lg font-medium"> {<Element canvas style={{display:"inline-block"}} id="951636" is="div"><Text text="The 400 Blows" /></Element>} </h2>
          <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="473932" is="div"><Text text="$18.40" /></Element>} </p>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Ecommerce1.craft = {
  displayName: "Ecommerce 1"
};