import React from "react";
import { useLocation } from "react-router-dom";
import TableContent from "./tableContent";
import "./accountTable.scss"
import CampaignTabel from "./tableContent/syncfusionTable";

export const AccountTable = (props) => {
    const {
        subScreen,
        accountType,
        accountColumns,
        accountData,
        columns,
        labeling,
        
    } = props

    const location = useLocation()
    
    console.log(accountColumns)
    return (
        <div className="accountContainer" style={{height: accountType == "Site" ? "auto" : "100%"}}>    
                {true ? <TableContent subScreen={subScreen} accountData={accountData} accountColumns={accountColumns} /> :
                <CampaignTabel columns={columns} data={accountData} /> }
        </div>
    )
}

