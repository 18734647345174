import './index.scss';
import * as React from 'react';
import { DropDownTreeComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { SampleBase } from './sample-base';

import * as dataSource from './dataSource.json'; 

class Component extends SampleBase {
  data = dataSource;

  fields = { dataSource: this.data.filterData, value: 'id', parentValue: 'pid', text: 'name', hasChildren: 'hasChild' };


  render() {
      return (<div className='control-pane'>
      <div className='control-section dropdowntree-filtering'>
        <div className='control_wapper'>
          <DropDownTreeComponent {...this.props.allEvents} id="filter" filterBarPlaceholder='Search' allowFiltering={true} fields={this.fields} placeholder="Select an item" popupHeight="220px"/>
        </div>
      </div>

    </div>);
    }
}

export default Component