import { gql } from "apollo-boost";

const allWorkspacesQuery = gql`
  
    query(
    $user: ID
  ) { 
    allWorkspaces (
      user: $user
    ) {
      _id
    user,
    name,
    users
    editors
    }
  }
  
`;

export default allWorkspacesQuery;
