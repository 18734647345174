import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Footer30 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <footer class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
    <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
      <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </svg>
        <span class="ml-3 text-xl"> {<Element canvas style={{display:"inline-block"}} id="765372" is="div"><Text text="Tailblocks" /></Element>} </span>
      </a>
      <p class="mt-2 text-sm text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="477687" is="div"><Text text="Air plant banjo lyft occupy retro adaptogen indego" /></Element>} </p>
    </div>
    <div class="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="167360" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="201709" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="655596" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="602561" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="720049" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="357960" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="370597" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="996350" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="507619" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="346818" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="332725" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="778861" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="450117" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="704950" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="913165" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="792358" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="201157" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="237463" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="920850" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="134423" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
    </div>
  </div>
  <div class="bg-gray-100">
    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
      <p class="text-gray-500 text-sm text-center sm:text-left"> {<Element canvas style={{display:"inline-block"}} id="134252" is="div"><Text text="© 2020 Tailblocks —" /></Element>} <Element canvas style={{ width: "100%" }} id={"div706631"} is="div">
            <Button href={"https://twitter.com/knyttneve"} classn={"text-gray-600 ml-1"}>
               {<Element canvas style={{display:"inline-block"}} id="767044" is="div"><Text text="@knyttneve" /></Element>} 
            </Button>
          </Element>
      </p>
      <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start items-end">
        <a class="text-gray-500">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </div>
    </footer>

      </div>;
};
Footer30.craft = {
  displayName: "Footer 30"
};