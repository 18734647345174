const ACTIVE_ORG = "ACTIVE_ORG";

const ACTION_ACTIVE_ORG = (payload) => {
  return {
    type: ACTIVE_ORG,
    payload
  };
};

export default ACTION_ACTIVE_ORG;
