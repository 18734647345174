import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Team7 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="573799" is="div"><Text text="Our Team" /></Element>} </h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="730284" is="div"><Text text="Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them." /></Element>} </p>
    </div>
    <div class="flex flex-wrap -m-2">
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div494967"} is="div">
          <ImageC alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/80x80"  />
        </Element>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> {<Element canvas style={{display:"inline-block"}} id="699915" is="div"><Text text="Holden Caulfield" /></Element>} </h2>
            <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="335716" is="div"><Text text="UI Designer" /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div257447"} is="div">
          <ImageC alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/84x84"  />
        </Element>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> {<Element canvas style={{display:"inline-block"}} id="534038" is="div"><Text text="Henry Letham" /></Element>} </h2>
            <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="437864" is="div"><Text text="CTO" /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div965175"} is="div">
          <ImageC alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/88x88"  />
        </Element>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> {<Element canvas style={{display:"inline-block"}} id="119353" is="div"><Text text="Oskar Blinde" /></Element>} </h2>
            <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="167542" is="div"><Text text="Founder" /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div700786"} is="div">
          <ImageC alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/90x90"  />
        </Element>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> {<Element canvas style={{display:"inline-block"}} id="283425" is="div"><Text text="John Doe" /></Element>} </h2>
            <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="902097" is="div"><Text text="DevOps" /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div833854"} is="div">
          <ImageC alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/94x94"  />
        </Element>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> {<Element canvas style={{display:"inline-block"}} id="935591" is="div"><Text text="Martin Eden" /></Element>} </h2>
            <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="443274" is="div"><Text text="Software Engineer" /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div394720"} is="div">
          <ImageC alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/98x98"  />
        </Element>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> {<Element canvas style={{display:"inline-block"}} id="784405" is="div"><Text text="Boris Kitua" /></Element>} </h2>
            <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="950224" is="div"><Text text="UX Researcher" /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div139530"} is="div">
          <ImageC alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/100x90"  />
        </Element>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> {<Element canvas style={{display:"inline-block"}} id="291589" is="div"><Text text="Atticus Finch" /></Element>} </h2>
            <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="375312" is="div"><Text text="QA Engineer" /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div876949"} is="div">
          <ImageC alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/104x94"  />
        </Element>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> {<Element canvas style={{display:"inline-block"}} id="586746" is="div"><Text text="Alper Kamu" /></Element>} </h2>
            <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="492189" is="div"><Text text="System" /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
        <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div235913"} is="div">
          <ImageC alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="https://dummyimage.com/108x98"  />
        </Element>
          <div class="flex-grow">
            <h2 class="text-gray-900 title-font font-medium"> {<Element canvas style={{display:"inline-block"}} id="384561" is="div"><Text text="Rodrigo Monchi" /></Element>} </h2>
            <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="258162" is="div"><Text text="Product Manager" /></Element>} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Team7.craft = {
  displayName: "Team 7"
};