import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Footer10 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <footer class="bg-gray-50">
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <span class="block h-10 w-32 rounded-lg bg-gray-200"></span>

    <div class="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
      <div>
        <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="159889" is="div"><Text text="Company" /></Element>} </p>

        <nav class="mt-4 flex flex-col space-y-2 text-sm text-gray-500">
          <Element canvas style={{ width: "100%" }} id={"div180780"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="473547" is="div"><Text text="About" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div512244"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="143057" is="div"><Text text="Meet the Team" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div288380"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="649656" is="div"><Text text="History" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div895952"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="206220" is="div"><Text text="Careers" /></Element>} 
            </Button>
          </Element>
        </nav>
      </div>

      <div>
        <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="228320" is="div"><Text text="Services" /></Element>} </p>

        <nav class="mt-4 flex flex-col space-y-2 text-sm text-gray-500">
          <Element canvas style={{ width: "100%" }} id={"div821292"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="737672" is="div"><Text text="1on1 Coaching" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div806801"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="113989" is="div"><Text text="Company Review" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div846474"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="865830" is="div"><Text text="Accounts Review" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div135602"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="934955" is="div"><Text text="HR Consulting" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div601561"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="571911" is="div"><Text text="SEO Optimisation" /></Element>} 
            </Button>
          </Element>
        </nav>
      </div>

      <div>
        <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="763723" is="div"><Text text="Helpful Links" /></Element>} </p>

        <nav class="mt-4 flex flex-col space-y-2 text-sm text-gray-500">
          <Element canvas style={{ width: "100%" }} id={"div586822"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="409042" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div136440"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="892287" is="div"><Text text="FAQs" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div111278"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="720821" is="div"><Text text="Live Chat" /></Element>} 
            </Button>
          </Element>
        </nav>
      </div>

      <div>
        <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="933710" is="div"><Text text="Legal" /></Element>} </p>

        <nav class="mt-4 flex flex-col space-y-2 text-sm text-gray-500">
          <Element canvas style={{ width: "100%" }} id={"div895978"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="883063" is="div"><Text text="Privacy Policy" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div413505"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="495050" is="div"><Text text="Terms & Conditions" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div928511"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="356851" is="div"><Text text="Returns Policy" /></Element>} 
            </Button>
          </Element>
          <Element canvas style={{ width: "100%" }} id={"div645475"} is="div">
            <Button href={""} classn={"hover:opacity-75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="730103" is="div"><Text text="Accessibility" /></Element>} 
            </Button>
          </Element>
        </nav>
      </div>
    </div>

    <div class="mt-8 border-t border-gray-100 pt-8 sm:flex sm:items-center sm:justify-between">
      <p class="text-xs text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="193628" is="div"><Text text="© 2022 Company Name" /></Element>} </p>

      <strong class="inline-flex items-center gap-2 rounded border border-gray-200 p-2 text-sm font-medium">
        <span> {<Element canvas style={{display:"inline-block"}} id="890306" is="div"><Text text="Status:" /></Element>} </span>

        <span class="h-3 w-3 rounded-full bg-green-600"></span>

        <span class="font-medium text-green-600"> {<Element canvas style={{display:"inline-block"}} id="122592" is="div"><Text text="All systems operational" /></Element>} </span>
      </strong>
    </div>
  </div>
    </footer>

      </div>;
};
Footer10.craft = {
  displayName: "Footer 10"
};