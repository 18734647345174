import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Faq2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-12 mx-auto">
        <h1 class="text-4xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="591912" is="div"><Text text="Frequently asked questions" /></Element>} </h1>

        <div class="mt-8 space-y-8 lg:mt-12">
            <div class="p-8 bg-gray-100 rounded-lg dark:bg-gray-800">
                <Element canvas style={{ width: "100%" }} id={"div615699"} is="div">
            <Button classn={"flex items-center justify-between w-full"}>
              
                    <h1 class="font-semibold text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="160881" is="div"><Text text="How i can play for my appoinment ?" /></Element>} </h1>

                    <span class="text-gray-400 bg-gray-200 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                        </svg>
                    </span>
                
            </Button>
          </Element>

                <p class="mt-6 text-sm text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="555960" is="div"><Text text="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas eaque nobis, fugit odit omnis fugiat deleniti animi ab maxime cum laboriosam recusandae facere dolorum veniam quia pariatur obcaecati illo ducimus?" /></Element>} </p>
            </div>

            <div class="p-8 bg-gray-100 rounded-lg dark:bg-gray-800">
                <Element canvas style={{ width: "100%" }} id={"div647973"} is="div">
            <Button classn={"flex items-center justify-between w-full"}>
              
                    <h1 class="font-semibold text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="680541" is="div"><Text text="Is the cost of the appoinment covered by private health insurance?" /></Element>} </h1>

                    <span class="text-white bg-blue-500 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    </span>
                
            </Button>
          </Element>
            </div>

            <div class="p-8 bg-gray-100 rounded-lg dark:bg-gray-800">
                <Element canvas style={{ width: "100%" }} id={"div423112"} is="div">
            <Button classn={"flex items-center justify-between w-full"}>
              
                    <h1 class="font-semibold text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="276768" is="div"><Text text="Do i need a referral?" /></Element>} </h1>

                    <span class="text-white bg-blue-500 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    </span>
                
            </Button>
          </Element>
            </div>

            <div class="p-8 bg-gray-100 rounded-lg dark:bg-gray-800">
                <Element canvas style={{ width: "100%" }} id={"div732877"} is="div">
            <Button classn={"flex items-center justify-between w-full"}>
              
                    <h1 class="font-semibold text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="727843" is="div"><Text text="What are your opening house?" /></Element>} </h1>

                    <span class="text-white bg-blue-500 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    </span>
                
            </Button>
          </Element>
            </div>

            <div class="p-8 bg-gray-100 rounded-lg dark:bg-gray-800">
                <Element canvas style={{ width: "100%" }} id={"div833251"} is="div">
            <Button classn={"flex items-center justify-between w-full"}>
              
                    <h1 class="font-semibold text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="217910" is="div"><Text text="What can i expect at my first consultation?" /></Element>} </h1>

                    <span class="text-white bg-blue-500 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    </span>
                
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Faq2.craft = {
  displayName: "Faq 2"
};