import { gql } from "apollo-boost";

const allTemplatesQuery = gql`
   { 
    allTemplates {
      _id
      name
      html
      css
      createdAt
    }
  }
  
`; 

export default allTemplatesQuery;
