import * as React from 'react';
import './index.scss';

import { CarouselComponent, CarouselItemsDirective, CarouselItemDirective } from '@syncfusion/ej2-react-navigations';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
  
render() {
    return (<div className='control-pane'>
    <div className='control-section partial-carousel-section'>
      <div className='control carousel-sample'>
        {/* Render the Carousel Component */}
        {this.props.items?.length ? <CarouselComponent {...this.props.allEvents} cssClass="partial-carousel" width={"700px"} partialVisible={true}>
          <CarouselItemsDirective>
            {this.props.items.map(x => (
              <CarouselItemDirective template={() => <figure className="img-container">
              <img src={x.url} alt="rocks" style={{ height: "100%", width: "100% " }}/>
              <figcaption className="img-caption">{x.title}</figcaption>
            </figure>}/>
            ))}
          </CarouselItemsDirective>
        </CarouselComponent> : <div style={{width:"700px", display:"flex", justifyContent:"center", alignItems:"center"}}>
          click edit to begin
          </div>}
      </div></div>
  </div>);
    }
}

export default Component