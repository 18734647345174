import React, { useEffect, useState } from "react";
import "./adminSideBar.scss"

import { faBriefcase, faChartLine, faFile, faProjectDiagram, faShieldAlt, faUserFriends, faCertificate, faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
useHistory, useLocation
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sideLogo from "../../../assests/images/sideLogo.svg"
import ACTION_BACK_ROUTE_RESET from "../../../actions/route/ACTION_BACK_ROUTE_RESET";
import ACTION_RESET_SIDE_BAR from "../../../actions/ActiveSideBar/ACTION_RESET_SIDE_BAR";
import ACTION_ACTIVE_ORG from "../../../actions/ActiveOrg/ACTION_ACTIVE_ORG";
import logout from "../../../assests/images/logout.svg"
import search from "../../../assests/images/search.svg"
import settings from "../../../assests/images/settings.svg"
import support from "../../../assests/images/support.svg"
import teamsIcon from "../../../assests/images/teams.svg"
import dashboardIcon from "../../../assests/images/dashboard.svg"
import usersIcon from "../../../assests/images/users.svg"
import workspace from "../../../assests/images/workspace.svg"
import app from "../../../assests/images/app.svg"
import arrow from "../../../assests/images/arrow.svg"
import arrowDown from "../../../assests/images/arrowDown.svg"
import community from "../../../assests/images/community.svg"
import department from "../../../assests/images/department.svg"
import forms from "../../../assests/images/forms.svg"
import add from "../../../assests/images/add.svg"
import { DataSpinner } from "../../resuable/spinner/dataSpinner";
import AddOrganizationModal from "../../resuable/modals/addOrganizationModal";
import { useMutation } from "@apollo/react-hooks";
import addOrganizationMutation from "../../../graphql/mutations/organization/addOrganizationMutation";
import addDepartmentMutation from "../../../graphql/mutations/department/addDepartmentMutation";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import AddTeamModal from "../../resuable/modals/addTeamModal";
import addGroupMutation from "../../../graphql/mutations/group/addGroupMutation";
import updateUserMutation from "../../../graphql/mutations/user/updateUserMutation";
import addCollectionMutation from "../../../graphql/mutations/collection/addCollectionMutation";
import AddTableModal from "../../resuable/modals/addTableModal";
import AddWorkspaceModal from "../../resuable/modals/addWorkspaceModal";
import addWorkspaceMutation from "../../../graphql/mutations/workspace/addWorkspaceMutation";




const AdminSideBar = (props) => {

    const {
        path,
        user,
        organizations,
        organizationsRefetch,
        setUsersData,
        users,
        teams,
        teamsRefetch,
        departments,
        departmentsRefetch,
        workspaces,
        workspacesRefetch,
        apps,
        usersRefetch,
        changeActiveRoute,
        handleLogout,
        industrys,
        tables,
        tablesRefetch,
        activeTable,
    } = props

    const dispatch = useDispatch()
    const location = useLocation()
    const activeSideBar = useSelector((state) => state.activeSideBar.active);
    const activeOrg = useSelector((state) => state.activeOrg.org);
    const activeOrgName = useSelector((state) => state.activeOrg.orgName);

    const history = useHistory()

    
const [addOrganization, {error: addOrganizationError, data: addOrganizationData, loading: addOrganizationLoading}] = useMutation(addOrganizationMutation)
const [addGroup, {error: addGroupError, data: addGroupData, loading: addGroupLoading}] = useMutation(addGroupMutation)
const [addWorkspace, {error: addWorkspaceError, data: addWorkspaceData, loading: addWorkspaceLoading}] = useMutation(addWorkspaceMutation)
const [addTable, {error: addTableError, data: addTableData, loading: addTableLoading}] = useMutation(addCollectionMutation)
const [addDepartment, {error: addDepartmentError, data: addDepartmentData, loading: addDepartmentLoading}] = useMutation(addDepartmentMutation)
const [updateUser, {error: updateUserError, data: updateUserData, loading: updateUserLoading}] = useMutation(updateUserMutation)

    const [activeRoute, setActiveRoute] = useState("dashboard")
    const [superAdminList, setSuperAdminList] = useState([
        {
            icon: search,
            name: "Search",
        },
        {
            icon: usersIcon,
            name: "Users", 
            path: "users",
        },
        {
            icon: teamsIcon,
            name: "Super admins", 
            path: "admins",
        },
        {
            icon: community,
            name: "Industries", 
            path: "industries",
        },
        {
            icon: workspace,
            name: "Templates", 
            path: "templates",
        }
    ])
    const [sidebarList, setSideBarList] = useState([
         {
            icon: dashboardIcon,
            name: "Dashboard", 
            path: "dashboard",
        }, 
        {
            icon: workspace,
            name: "Workspaces",
            // path: "workspaces",
            arrow: false,
            active: false,
            children: [ 
                {_id: "111",name: "My workspace"}
                // // children: [
                // //     {_id:"112",name: "workspace 1"},
                // //     {_id:"113",name: "workspace 2"}
                // // ],
                // arrow: false, active: false},
                // {_id:"121",name: "Other workspaces",children: [
                //     {_id:"122",name: "workspace 1"},
                //     {_id:"123",name: "workspace 2"}
                // ], arrow: false, active: false},
                // {_id:"sharedView",name: "Shared views", arrow: false, active: false},
                // {_id:"authentication",name: "Authentication Pages", arrow: false, active: false},
                // {
                //     _id:"131",
                //     icon: add,
                //     name: "Add a workspace",

                // }

            ]
        },
        {
            icon: app,
            name: "Apps",
            arrow: false,
            active: false,
            // children: [
            //     {_id: "111",name: "My Apps", children: [
            //         {_id:"112",name: "workspace 1"},
            //         {_id:"113",name: "workspace 2"}
            //     ], arrow: true, active: false},
            //     {_id:"121",name: "Other apps", children: [
            //         {_id:"122",name: "workspace 1"},
            //         {_id:"123",name: "workspace 2"}
            //     ], 
            //     arrow: true, active: false},
            // ]
        },
        {
            icon: forms,
            name: "Forms",
            arrow: false,
            // children: [{_id:"131",icon: add,name: "Add a Form"}]
        },
        {
            icon: community,
            name: "Database",
            arrow: false,
            // children: [{_id:"131",icon: add,name: "New connection"}]
        },
        {
            icon: teamsIcon,
            name: "Authentication",
            path: "teams",
            arrow: false,
            // children: [{_id:"131",icon: add,name: "Add a Team"}]            
        },
        // {
        //     icon: usersIcon,
        //     name: "Users", 
        //     path: "users",
        // },
    ])
    const [sidebarDownList, setSideBarDownList] = useState([
        {
            icon: settings,
            name: "Settings",
            path: "settings",
        },
        {
            icon: support,
            name: "Support",
            path: "support",
        },
        
    ])

    // useEffect(() => {
    //   setActiveRoute(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
    // }, [location]);
    
    const [reload, setReload] = useState(false)
    const [organizationModal, setOrganizaionModal] = useState(false)


    const [teamsData, setTeamsData] = useState(null)

    const [tablesData, setTablesData] = useState(null)

    useEffect(() => {
        if(teams) {
            setTeamsData(teams.allGroups)
        }
    },[teams])

    useEffect(() => {
        if(tables) {
            setTablesData(tables.allCollections)
            
            if(activeTable) {
                let bb = tables.allCollections.filter(x => x._id == activeTable._id)
                if(bb) {
                    let newbb = bb.map(x => {
                        return{
                            _id:x._id,
                            name: x.name,
                            forms: x.forms ? JSON.parse(x.forms) : []
                         }
                    })
                    
                                  changeActiveRoute("Database", newbb[0])
  
                }

        } 
    }
    },[tables])

    useEffect(() => {
        if(teamsData) {
            setSideBarList([...sidebarList.map(x => {
                if(x.name !== "Teams") {
                    return x
                } else {
                    // x.children = [...teamsData.map(x => {
                    //     return{
                    //        _id:x._id,
                    //        name: x.name 
                    //     }
                    // }), {_id:"131",icon: add,name: "Add a Team"}]
                    return x
                } 
            })])
        }
    },[teamsData])

    useEffect(() => {
        if(tablesData) {
            setSideBarList([...sidebarList.map(x => {
                if(x.name !== "Database") {
                    return x
                } else {
                    // x.children = [...tablesData.map(x => {
                    //     return{
                    //        _id:x._id,
                    //        name: x.name,
                    //        forms: x.forms ? JSON.parse(x.forms) : []
                    //     }
                    // }), {_id:"131",icon: add,name: "New connection"}]
                    return x
                } 
            })])
        }
    },[tablesData])

    // departments

    const [departmentsData, setDepartmentsData] = useState(null)

    useEffect(() => {
        if(departments) {
            setDepartmentsData(departments.allDepartments)
        }
    },[departments])

    useEffect(() => {
        if(departmentsData) {
            setSideBarList([...sidebarList.map(x => {
                if(x.name !== "Departments") {
                    return x
                } else {
                    // x.children = [...departmentsData.map(x => {
                    //     return{
                    //        _id:x._id,
                    //        name: x.name 
                    //     }
                    // }), {_id:"131",icon: add,name: "Add a Department"}]
                    return x
                } 
            })])
        }
    },[departmentsData])

    // workspaces

    
    const [workspacesData, setWorkspacesData] = useState(null)

    useEffect(() => {
        if(workspaces) {
            setWorkspacesData(workspaces.allWorkspaces)
        }
    },[workspaces])

    // useEffect(() => {
    //     if(workspacesData) {
    //         /////console.log(workspacesData)
    //         setSideBarList([...sidebarList.map(x => {
    //             if(x.name !== "Workspaces") {
    //                 return x
    //             } 
    //             // else {
    //             //     if(x.children?.length)
    //             //     x.children[0]._id = workspacesData.find((x) => x.user == user.user._id)?._id
    //             //     if(x.children?.length)
    //             //     x.children[1].children = [...workspacesData.filter((x) => x.user !== user.user._id)?.map(x => {
    //             //         return{
    //             //            _id:  x._id,
    //             //            name: x.name,
    //             //            type: x.users.some(z => z == user.user._id) ? "viewer" : x.editors.some(z => z == user.user._id) ? "editor" : "others"
    //             //         } 
    //             //     })]
    //             //     return x
    //             // } 
    //         })])
    //     }
    // },[workspacesData])


    // workspaces

    
    const [appsData, setAppsData] = useState(null)

    useEffect(() => {
        if(apps) {
            setAppsData(apps.allApps)
        }
    },[apps])

    useEffect(() => {
        if(appsData) {
            /////console.log(appsData)
            setSideBarList([...sidebarList.map(x => {
                if(x.name !== "Apps") {
                    return x
                } else {
                    if(x.children?.length)
                    x.children[0].children = [...appsData.filter((x) => x.user == user.user._id)?.map(x => {
                        return{
                           _id:  x._id,
                           name: x.name,
                           workspace: x.workspace,
                           type: "owner"
                        }
                    })]
                    if(x.children?.length)

                    x.children[1].children = [...appsData.filter((x) => x.user !== user.user._id)?.map(x => {
                        return{
                           _id:  x._id,
                           name: x.name,
                           workspace: x.workspace,
                           type: x.users.some(z => z == user.user._id) ? "viewer" : x.editors.some(z => z == user.user._id) ? "editor" : "others"
                        }
                    })]
                    return x
                } 
            })])
        }
    },[appsData])

    

// add table


const [addTableModal, setAddTableModal] = useState(null)
const [tableName, setTableName] = useState("")

// add table func

const addTableFunc = () => {
    addTable({
        variables:{
            org: activeOrg,
            name: tableName,
        }           
    }).then(() => {
        tablesRefetch()
        setAddTableModal(null)
    })
}

// addTableModal
// setAddTableModal
// tableName
// setTableName
// addTableFunc

// add workspace


// add team

const [workspaceAddModal, setWorkspaceAddModal] = useState(null)
const [workspaceName,setWorkspaceName] = useState("")
const [workspaceNameError,setWorkspaceNameError] = useState("")

const [usersAtWorkspace, setUsersAtWorkspace] = useState(null)

const addUserToWorkspace = (user) => {
    if(!usersAtWorkspace) {
        user.role == "viewer"
        setUsersAtWorkspace([user])
    } else {
        setUsersAtWorkspace([...usersAtWorkspace, user])
    }
}

const [usersSearchWork, setUsersSearchWork] = useState("")


useEffect(() => {
    if(usersSearchWork) {
        /////console.log(usersSearchWork.length)
        if(usersSearchWork.length) {
          setUsersFilter([...users.allUsers].filter((x) => {
            return (
              new RegExp(usersSearchWork, "gi").test(
                x.name
              ) || new RegExp(usersSearchWork, "gi").test(
                x.email
              )
            )
        }).filter(y => !usersAtWorkspace ? true : usersAtWorkspace.some((user)=>user._id==y._id) ? false : true).map(y => {
            return {
                _id: y._id,
                name: y.name,
                role: "viewer"
            }
        }))
        }   
}
},[usersSearchWork])


// add workspace func

const addWorkspaceFunc = () => {
    /////console.log(usersAtWorkspace)
    addWorkspace({
        variables:{
            user: user.user._id,
            name: workspaceName,
            users: usersAtWorkspace.filter(x=>x.role == "viewer")?.map(x => x._id),
            editors: usersAtWorkspace.filter(x=>x.role == "editor")?.map(x => x._id)
        }
    }).then(async res => {
            setUsersAtWorkspace(null)
            workspacesRefetch()
            usersRefetch()        
    })
}

// add team

const [teamAddModal, setTeamAddModal] = useState(null)
const [teamName,setTeamName] = useState("")
const [teamNameError,setTeamNameError] = useState("")
const [usersSearch,setUsersSearch] = useState("")
const [usersSearchError, setUsersSearchError] = useState("")
const [usersFilter, setUsersFilter] = useState("")
const [userRole,setUserRole] = useState("User")

const [usersAtTeam, setUsersAtTeam] = useState(null)

const addUserToTeam = (user) => {
    if(!usersAtTeam) {
        setUsersAtTeam([user])
    } else {
        setUsersAtTeam([...usersAtTeam, user])
    }
}



useEffect(() => {
    if(usersSearch) {
        /////console.log(usersSearch.length)
        if(usersSearch.length) {
          setUsersFilter([...users.allUsers].filter((x) => {
            return (
              new RegExp(usersSearch, "gi").test(
                x.name
              ) || new RegExp(usersSearch, "gi").test(
                x.email
              )
            )
        }).filter(y => !usersAtTeam ? true : usersAtTeam.some((user)=>user._id==y._id) ? false : true))
        }   
}
},[usersSearch])


// add team func

const addTeamFunc = () => {
    addGroup({
        variables:{
            org: activeOrg,
            name: teamName,
        }
    }).then(async res => {
        if(usersAtTeam?.length) {
        const resAll =  await Promise.all(usersAtTeam.map(async (x) => {
            const update = await updateUser({
                variables:{
                    _id:x._id,
                    group: res.data.addGroup._id,
                    groupAdmin: x.role == "Admin" ? true : false
                }
            }) 
        }))
        if(resAll) {
            setUsersAtTeam(null)
            teamsRefetch()
            usersRefetch()
        }
    }
    })
}

const addDepartmentFunc = () => {
    addDepartment({
        variables:{
            org: activeOrg,
            name: teamName,
        }
    }).then(async res => {
        const resAll =  await Promise.all(usersAtTeam.map(async (x) => {
            const update = await updateUser({
                variables:{
                    _id:x._id,
                    department: res.data.addDepartment._id,
                    departmentAdmin: x.role == "Admin" ? true : false
                }
            }) 
        }))
        if(resAll) {
            setUsersAtTeam(null)
            departmentsRefetch()
            usersRefetch()
        }
    })
}

// add organization
const [organizationAddModal, setOrganizationAddModal] = useState(null)
const [orgName, setOrgName] = useState("")
const [orgError, setOrgError] = useState("")
const [industries, setIndustries] = useState([])
const [industry, setIndustry] = useState("")
let timeZones = [
    { label: "-12:00 International Date Line West (Etc/GMT+12)", value: "-12:00" },
    { label: "-11:00 Midway Island, Samoa (Pacific/Midway)", value: "-11:00" },
    { label: "-10:00 Hawaii (Pacific/Honolulu)", value: "-10:00" },
    { label: "-09:00 Alaska (US/Alaska)", value: "-09:00" },
    { label: "-08:00 Pacific Time (US & Canada) (America/Los_Angeles)", value: "-08:00" },
    { label: "-08:00 Tijuana, Baja California (America/Tijuana)", value: "-08:00" },
    { label: "-07:00 Arizona (US/Arizona)", value: "-07:00" },
    { label: "-07:00 Chihuahua, La Paz, Mazatlan (America/Chihuahua)", value: "-07:00" },
    { label: "-07:00 Mountain Time (US & Canada) (US/Mountain)", value: "-07:00" },
    { label: "-06:00 Central America (America/Managua)", value: "-06:00" },
    { label: "-06:00 Central Time (US & Canada) (US/Central)", value: "-06:00" },
    { label: "-06:00 Guadalajara, Mexico City, Monterrey (America/Mexico_City)", value: "-06:00" },
    { label: "-06:00 Saskatchewan (Canada/Saskatchewan)", value: "-06:00" },
    { label: "-05:00 Bogota, Lima, Quito, Rio Branco (America/Bogota)", value: "-05:00" },
    { label: "-05:00 Eastern Time (US & Canada) (US/Eastern)", value: "-05:00" },
    { label: "-05:00 Indiana (East) (US/East-Indiana)", value: "-05:00" },
    { label: "-04:00 Atlantic Time (Canada) (Canada/Atlantic)", value: "-04:00" },
    { label: "-04:00 Caracas, La Paz (America/Caracas)", value: "-04:00" },
    { label: "-04:00 Manaus (America/Manaus)", value: "-04:00" },
    { label: "-04:00 Santiago (America/Santiago)", value: "-04:00" },
    { label: "-03:30 Newfoundland (Canada/Newfoundland)", value: "-03:30" },
    { label: "-03:00 Brasilia (America/Sao_Paulo)", value: "-03:00" },
    { label: "-03:00 Buenos Aires, Georgetown (America/Argentina/Buenos_Aires)", value: "-03:00" },
    { label: "-03:00 Greenland (America/Godthab)", value: "-03:00" },
    { label: "-03:00 Montevideo (America/Montevideo)", value: "-03:00" },
    { label: "-02:00 Mid-Atlantic (America/Noronha)", value: "-02:00" },
    { label: "-01:00 Cape Verde Is. (Atlantic/Cape_Verde)", value: "-01:00" },
    { label: "-01:00 Azores (Atlantic/Azores)", value: "-01:00" },
    { label: "+00:00 Casablanca, Monrovia, Reykjavik (Africa/Casablanca)", value: "+00:00" },
    { label: "+00:00 Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (Etc/Greenwich)", value: "+00:00" },
    { label: "+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Amsterdam)", value: "+01:00" },
    { label: "+01:00 Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Belgrade)", value: "+01:00" },
    { label: "+01:00 Brussels, Copenhagen, Madrid, Paris (Europe/Brussels)", value: "+01:00" },
    { label: "+01:00 Sarajevo, Skopje, Warsaw, Zagreb (Europe/Sarajevo)", value: "+01:00" },
    { label: "+01:00 West Central Africa (Africa/Lagos)", value: "+01:00" },
    { label: "+02:00 Amman (Asia/Amman)", value: "+02:00" },
    { label: "+02:00 Athens, Bucharest, Istanbul (Europe/Athens)", value: "+02:00" },
    { label: "+02:00 Beirut (Asia/Beirut)", value: "+02:00" },
    { label: "+02:00 Cairo (Africa/Cairo)", value: "+02:00" },
    { label: "+02:00 Harare, Pretoria (Africa/Harare)", value: "+02:00" },
    { label: "+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Helsinki)", value: "+02:00" },
    { label: "+02:00 Jerusalem (Asia/Jerusalem)", value: "+02:00" },
    { label: "+02:00 Minsk (Europe/Minsk)", value: "+02:00" },
    { label: "+02:00 Windhoek (Africa/Windhoek)", value: "+02:00" },
    { label: "+03:00 Kuwait, Riyadh, Baghdad (Asia/Kuwait)", value: "+03:00" },
    { label: "+03:00 Moscow, St. Petersburg, Volgograd (Europe/Moscow)", value: "+03:00" },
    { label: "+03:00 Nairobi (Africa/Nairobi)", value: "+03:00" },
    { label: "+03:00 Tbilisi (Asia/Tbilisi)", value: "+03:00" },
    { label: "+03:30 Tehran (Asia/Tehran)", value: "+03:30" },
    { label: "+04:00 Abu Dhabi, Muscat (Asia/Muscat)", value: "+04:00" },
    { label: "+04:00 Baku (Asia/Baku)", value: "+04:00" },
    { label: "+04:00 Yerevan (Asia/Yerevan)", value: "+04:00" },
    { label: "+04:30 Kabul (Asia/Kabul)", value: "+04:30" },
    { label: "+05:00 Yekaterinburg (Asia/Yekaterinburg)", value: "+05:00" },
    { label: "+05:00 Islamabad, Karachi, Tashkent (Asia/Karachi)", value: "+05:00" },
    { label: "+05:30 Chennai, Kolkata, Mumbai, New Delhi (Asia/Calcutta)", value: "+05:30" },
    { label: "+05:30 Sri Jayawardenapura (Asia/Calcutta)", value: "+05:30" },
    { label: "+05:45 Kathmandu (Asia/Katmandu)", value: "+05:45" },
    { label: "+06:00 Almaty, Novosibirsk (Asia/Almaty)", value: "+06:00" },
    { label: "+06:00 Astana, Dhaka (Asia/Dhaka)", value: "+06:00" },
    { label: "+06:30 Yangon (Rangoon) (Asia/Rangoon)", value: "+06:30" },
    { label: "+07:00 Bangkok, Hanoi, Jakarta (Asia/Bangkok)", value: "+07:00" },
    { label: "+07:00 Krasnoyarsk (Asia/Krasnoyarsk)", value: "+07:00" },
    { label: "+08:00 Beijing, Chongqing, Hong Kong, Urumqi (Asia/Hong_Kong)", value: "+08:00" },
    { label: "+08:00 Kuala Lumpur, Singapore (Asia/Kuala_Lumpur)", value: "+08:00" },
    { label: "+08:00 Irkutsk, Ulaan Bataar (Asia/Irkutsk)", value: "+08:00" },
    { label: "+08:00 Perth (Australia/Perth)", value: "+08:00" },
    { label: "+08:00 Taipei (Asia/Taipei)", value: "+08:00" },
    { label: "+09:00 Osaka, Sapporo, Tokyo (Asia/Tokyo)", value: "+09:00" },
    { label: "+09:00 Seoul (Asia/Seoul)", value: "+09:00" },
    { label: "+09:00 Yakutsk (Asia/Yakutsk)", value: "+09:00" },
    { label: "+09:30 Adelaide (Australia/Adelaide)", value: "+09:30" },
    { label: "+09:30 Darwin (Australia/Darwin)", value: "+09:30" },
    { label: "+10:00 Brisbane (Australia/Brisbane)", value: "+10:00" },
    { label: "+10:00 Canberra, Melbourne, Sydney (Australia/Canberra)", value: "+10:00" },
    { label: "+10:00 Hobart (Australia/Hobart)", value: "+10:00" },
    { label: "+10:00 Guam, Port Moresby (Pacific/Guam)", value: "+10:00" },
    { label: "+10:00 Vladivostok (Asia/Vladivostok)", value: "+10:00" },
    { label: "+11:00 Magadan, Solomon Is., New Caledonia (Asia/Magadan)", value: "+11:00" },
    { label: "+12:00 Auckland, Wellington (Pacific/Auckland)", value: "+12:00" },
    { label: "+12:00 Fiji, Kamchatka, Marshall Is. (Pacific/Fiji)", value: "+12:00" },
    { label: "+13:00 Nuku'alofa (Pacific/Tongatapu)", value: "+13:00" }
  ]
const [timeZone, setTimeZone] = useState("+00:00")
const languages= ['English', "Italian", "Spanish", "German", "Arabic"] 
const [language, setLanguage] = useState("English")
const currencies = ['USD', "EUR", "GBP", "CHF", "EG"] 
const [currency, setCurrency] = useState("USD")

useEffect(() => {
    if(industrys) {
      setIndustries(industrys.allIndustrys.map(x => x.name))
    }
},[industrys])
const addOrganizationFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setOrganizationAddModal(null)
    addOrganization({
        variables:{
            owner: user.user._id,
            name: orgName,
            industry,
            language,
            currency,
            timeZone,
        }
    }).then((res) => {
        organizationsRefetch()
        
        setOrgName("")
        setIndustry("")
        setLanguage("")
        setCurrency("")
        setTimeZone("")
    })
}

useEffect(() => {
    if(organizations) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
},[organizations])

const [addGroupDatas, setAddGroupDatas] = useState(null)
useEffect(() => {
    if(addGroupData) {
        setAddGroupDatas(addGroupData)
    } else if(addDepartmentData) {
        setAddGroupDatas(addDepartmentData)
    }
},[addGroupData, addDepartmentData])


const [addWorkspaceDatas, setAddWorkspaceDatas] = useState(null)
useEffect(() => {
    if(addWorkspaceData) {
        setAddWorkspaceDatas(addWorkspaceData)
    }
},[addWorkspaceData])
 

const [searchClicked, setSearchClicked]  = useState(false)

if(!organizations && user && user.user.role !== "superAdmin") {
    return (
        <DataSpinner />
    ) 
}

    return (
        <div className={`${ activeSideBar ? "mobileSideBar sidebarContainer" : "mobileSideBar sidebarContainer"} ${reload ? "reload" : "reloaded"}`}>
            <AddOrganizationModal 
            open={organizationAddModal}
            setOpen={setOrganizationAddModal}
            orgName={orgName}
            setOrgName={setOrgName}
            orgError={orgError}
            setOrgError={setOrgError}
            industries={industries}
            industry={industry}
            setIndustry={setIndustry}
            timeZones={timeZones}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
            languages={languages}
            language={language}
            setLanguage={setLanguage}
            currencies={currencies}
            currency={currency}
            setCurrency={setCurrency}
            addOrganization={addOrganizationFunc}
            />

            <AddTableModal 
                open={addTableModal}
                setOpen={setAddTableModal}
                tableName={tableName}
                setTableName={setTableName}
                addTable={addTableFunc}
                addTableLoading={addTableLoading}
            />

            <AddWorkspaceModal 
            user={user}
            open={workspaceAddModal}
            setOpen={setWorkspaceAddModal}
            addWorkspace={addWorkspaceFunc}
            workspaceName={workspaceName}
            setWorkspaceName={setWorkspaceName}
            workspaceNameError={workspaceNameError}
            setWorkspaceNameError={setWorkspaceNameError}
            usersFilter={usersFilter}
            usersSearchWork={usersSearchWork}
            setUsersSearchWork={setUsersSearchWork}
            usersSearchWorkError={usersSearchError}
            setUsersSearchWorkError={setUsersSearchError}
            addWorkspaceLoading={addWorkspaceLoading}
            usersAtWorkspace={usersAtWorkspace}
            setUsersAtWorkspace={setUsersAtWorkspace}
            addUserToWorkspace={addUserToWorkspace}
            userRole={userRole}
            setUserRole={setUserRole}
            addWorkspaceData={addWorkspaceDatas}
            setAddWorkspaceData={setAddWorkspaceDatas}

            />

            <AddTeamModal 
            user={user}
            open={teamAddModal}
            setOpen={setTeamAddModal}
            addTeam={addTeamFunc}
            addDepartment={addDepartmentFunc}
            teamName={teamName}
            setTeamName={setTeamName}
            teamNameError={teamNameError}
            setTeamNameError={setTeamNameError}
            usersFilter={usersFilter}
            usersSearch={usersSearch}
            setUsersSearch={setUsersSearch}
            usersSearchError={usersSearchError}
            setUsersSearchError={setUsersSearchError}
            addTeamLoading={addGroupLoading || addDepartmentLoading}
            usersAtTeam={usersAtTeam}
            setUsersAtTeam={setUsersAtTeam}
            addUserToTeam={addUserToTeam}
            userRole={userRole}
            setUserRole={setUserRole}
            addGroupData={addGroupDatas}
            setAddGroupData={setAddGroupDatas}

            />
            {/* <FontAwesomeIcon
                className="activeSideBarX"
                icon={faBars}
                style={{position:"absolute", fontSize:"20px", right:10, top:10, cursor:"pointer", zIndex:10}}
                onClick={() => dispatch(ACTION_RESET_SIDE_BAR())}
              /> */}
            <div className="sidebarChildren" >
                <div className="sidebarLogo" style={{position:"relative"}}>
                    <div style={{width:"100%", marginBottom:".5rem",  marginLeft:".5rem", height:"50px", position:"relative", display:"flex", alignItems:"center"}}>
                    {organizations && organizations.map((x,i) => ( i < 3 ? <img height="40px"  onClick={() => setOrganizaionModal(!organizationModal)} style={{cursor:"pointer", position:"absolute", left:i*30, zIndex:-i, opacity:1-(i*.1)}} src={sideLogo} title={x.name} /> : i == 3 ? (<div style={{ position:"absolute",right:0}}>{organizations.length - 3}+</div>) : null ))}
                    </div>
                    <div className="accountInformation">
                    <div className="sideName">{user && user.user.name}</div>
                    <div className="sideRole"><span style={{color:"#90EE90", fontWeight:"500"}}>{activeOrgName}</span> <span style={{margin:"0 .5rem"}}>-</span> {user && user.user.role}</div>
                </div>

            {organizationModal && (
                <div onClick={(e) => {
                    e.stopPropagation()
                    setOrganizaionModal(false)
                }} style={{width:"100vw", zIndex:3, background:"#00000090", height:"100vh", top:0, bottom:0, left:0,right:0, position:"fixed"}}>
                    <div className="orgsC" style={{position:"fixed", zIndex:2, height:"500px", width:"300px", top:"10%", left:"3%", borderRadius:"8px", backgroundColor:"white"}}>
                        <div className="orgsContainers" >
                        {organizations && organizations.map(y => (
                          <div onClick={() => {
                              setUsersData(null)
                              dispatch(ACTION_ACTIVE_ORG(y))
                              setOrganizaionModal(false)
                          }} className="oneOrg">
                        <img height="45px" src={sideLogo} style={{marginRight:"12px"}} title={y.name} />
                        <div className="oneOrgDetails">
                            <div className="orgTitle">{y.name}</div>
                            <div className="userTitle">{y.owner == user.user._id ? "Admin" : "User"} <div className="userTitle" style={{color:"green"}}> <span style={{margin:"0 5px", color:"green"}}>-</span> {`${y.industry}`}</div></div>
                        </div>
                        </div>  
                        ))}
                        { user.user.role == "admin" && <div onClick={() => {
                            setOrganizaionModal(false)
                            setOrganizationAddModal("Organization")
                        }}  className="hoverAddWorkspace">
                        <img
                                        className="routeIcon"
                                        src={add}
                            />
                        <div className={`addWorkspaceButton`}>
                            Add an Organization
                        </div>
                        </div>}
                        </div>
                    </div>
                    </div>
                )}
                </div>
                
                <div className="sideLine"></div>
                <div className="sidebarContent">
                    {user ? user.user.role == "superAdmin" ? (
                        superAdminList.map((route, i) => (
                            <>
                            {route.name == "Search" && searchClicked ? (
                                <input placeholder="Search" className={`routeContainer ${route.name == "Search" && "sideSearch"}`}   />
                            ) :
                            <div key={route.name} className={`routeContainer ${route.name == "Search" && "sideSearch"} ${ route.path && activeRoute == route.path ? "activeRoute" : "false"}`}
                            onClick={() => {
                                route.name == "Search" && setSearchClicked(true)
                                route.path && history.push(`${path}/${route.path}`)
                                route.path && dispatch(ACTION_RESET_SIDE_BAR())
                                route.active = !route.active
                                setReload(!reload)
                            }}>
                                <img
                                            className="routeIcon"
                                            src={route.icon}
                                />
                                <div className="routeName">
                                    {route.name}
                                </div>
                               { route.arrow && <img
                                            className="routeIcon forceRight"
                                            src={route.active ? arrowDown : arrow}
                                />}
    
                        </div> }                       
                        </>
                        ))
                    ) : (
                    sidebarList.map((route, i) => (
                            <>
                            {console.log(route, 109)}
                            <div key={route.name} className={`routeContainer ${route.name == "Search" && "sideSearch"} ${ route.path && activeRoute == route.path ? "activeRoute" : "false"}`}
                            onClick={() => {
                                if(route.name == "Workspaces") {
                                    setActiveRoute("myoriginalworkspace")
                                    dispatch(ACTION_BACK_ROUTE_RESET())
                                     history.push({
                                     pathname: `${path}/workspaces`,
                                     state: {
                                     workspace: route
                                     }
                                 })  
                                 dispatch(ACTION_RESET_SIDE_BAR())                             
                                 } else {
                                route.path && history.push(`${path}/${route.path}`)
                                route.active = !route.active
                                setReload(!reload)
                                 }
                            }}>
                                <img
                                            className="routeIcon"
                                            src={route.icon}
                                />
                                <div className="routeName">
                                    {route.name}
                                </div>
                               { route.arrow && <img
                                            className="routeIcon forceRight"
                                            src={route.active ? arrowDown : arrow}
                                />}
    
                        </div>
                        {route.children && route.active && (
                          route.children?.map(child => (
                              <>
                              {console.log(child, 12)}
                            <div key={child.name} className={`routeContainer ${activeRoute == child._id && "activeRoute"}`}
                            onClick={() => {
                               
                                if(!child.name.includes("Add") && !child.name.includes("connection") && !child.name.includes("Authentication")) {
                                    
                                if(child.arrow) {
                                    child.active = !child.active
                                    setReload(!reload)
                                } else if(route.name == "Workspaces") {
                                    setActiveRoute(child._id)
                                    dispatch(ACTION_BACK_ROUTE_RESET())
                                     history.push({
                                     pathname: `${path}/workspaces`,
                                     state: {
                                     workspace: child
                                     }
                                 })  
                                 dispatch(ACTION_RESET_SIDE_BAR())                             
                                 }else {
                                    setActiveRoute(child._id)
                                    changeActiveRoute(route.name, child)
                                    history.push({
                                        pathname: `${path}/${route.name.toLocaleLowerCase()}`,
                                        state: {
                                        workspace: child
                                        }
                                    }) 
                                }} else {
                                if(child.name  == "Add a workspace") { 
                                    let newUser = {}
                                    newUser._id = user.user._id
                                    newUser.name = user.user.name
                                    newUser.role = "editor" 
                                    addUserToWorkspace(newUser)
                                    setWorkspaceAddModal("Workspace")
                                } else if(child.name  == "Authentication Pages") { 
                                    // let newUser = {}
                                    // newUser._id = user.user._id
                                    // newUser.name = user.user.name
                                    // newUser.role = "editor"
                                    // addUserToWorkspace(newUser)
                                    // setWorkspaceAddModal("Workspace")
                                    window.location.href = `https://app.allyoucancloud.com/auth/${activeOrg}`
                                } else if(child.name  == "Add a Department") {
                                    setTeamAddModal("Department") 
                                }  else if(child.name  == "Add a Team") {
                                    setTeamAddModal("Team")
                                } else if (child.name == "New connection") {
                                    setAddTableModal("Table")
                                }
                            }
                                setReload(!reload)                            
                            }}>
                                { child.icon ? <img
                                            className="routeIcon"
                                            src={child.icon}
                                /> : <div className="routeIcon">
                                    </div>}
                                <div  style={{color: child.name == "My workspace" ? "#90EE90" : child.name == "Authentication Pages"  ? "#f4993e" : ""}} className={`routeName ${child.name  == "Add a workspace" || child.name  == "Add a Department" || child.name == "Add a Team" ? "addWorkspaceButton" : "false"}`}>
                                    {child.name}
                                </div>
                               { child.arrow && <img
                                            className="routeIcon forceRight"
                                            src={child.active ? arrowDown : arrow}
                                />}
     
                        </div>
                        {child.children && child.active && (
                          child.children?.map(child => (
                            <div key={child.name} className={`routeContainer ${activeRoute == child._id && "activeRoute"}`}
                            onClick={() => {
                                console.log(route.name)
                                if(route.name == "Workspaces") {
                                   setActiveRoute(child._id)
                                   dispatch(ACTION_BACK_ROUTE_RESET())
                                    history.push({
                                    pathname: `${path}/workspaces`,
                                    state: {
                                    workspace: child
                                    }
                                })  
                                } else {
                                    dispatch(ACTION_BACK_ROUTE_RESET())
                                    window.open(window.location.origin + `/admin/app/${child.workspace}/${child._id}`, '_blank', 'toolbar=0,location=0,menubar=0, toolbar=no, addressbar=no');
                                //     history.push({
                                //     pathname: `${path}/app`,
                                //     state: {
                                //     workspace: child
                                //     }
                                // })    
                                }
                               
                                dispatch(ACTION_RESET_SIDE_BAR())                            
                            }}>  
                            <div className="routeIcon">
                                    </div>                         
                                <div className={`routeName`} style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                                    <span>{child.name}</span>
                                    <span style={{color:"#90EE90"}}>{child.type}</span>
                                </div>
    
                        </div> 
                          ))  
                        )}
                        </>
                          ))  
                        )}
                        {i == 3 && (
                            <div className="sideLine"></div>
                        )}
                        </>
                        ))
                    ) : <DataSpinner />}
                    
                </div>
                
                <div className="sidebarContent" style={{height:"fit-content"}}>
                    {sidebarDownList.map((route, i) => (
                        <>
                        <div key={route.name} className={`routeContainer  ${ route.path && activeRoute == route.path ? "activeRoute" : "false"}`}
                        onClick={() => {
                            user.user.role !== "superAdmin" && route.path && setActiveRoute(route.path)
                            dispatch(ACTION_BACK_ROUTE_RESET())
                            user.user.role !== "superAdmin" && route.path && history.push(`${path}/${route.path}`)
                            dispatch(ACTION_RESET_SIDE_BAR())
                        }}>
                            <img
                                        className="routeIcon" 
                                        src={route.icon}
                            />
                            <div className="routeName">
                                {route.name} 
                            </div>
                           { route.arrow && <img
                                        className="routeIcon forceRight"
                                        src={arrow}
                            />}

                    </div>
                    </>
                    ))}
                </div>
                 <div className="sidebarContent" style={{height:"fit-content", marginTop:"1rem"}}>                  
                        <div className={`routeContainer logoutH`}
                        style={{display:"flex", justifyContent:"center", alignItems:"center", background:"#ABB0B9", borderRadius:"8px"}}
                        onClick={() => {
                            handleLogout()
                        }}>
                            
                            <div className="routeName" style={{marginTop:"0px", fontWeight:"600"}}>
                                Log Out
                            </div>
                            <img
                                        className="routeIcon"
                                        src={logout}
                            />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSideBar