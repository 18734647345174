import { gql } from "apollo-boost";

const addOrganizationMutation = gql`
  mutation(
    $name: String,
    $owner: ID,
    $domain: ID
    $industry: String
    $language: String
    $currency: String
    $timeZone: String
    $email: String
    $password: String
  ) {
    addOrganization(
    name:$name,
    domain:$domain
    owner:$owner,
    industry:$industry,
    language:$language,
    currency:$currency,
    timeZone:$timeZone,
    email: $email
    password: $password
    ) {
    _id
    domain
    name,
    industry,
    language,
    currency,
    timeZone,
    }
  }
`;

export default addOrganizationMutation;
