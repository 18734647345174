import { gql } from "apollo-boost";

const addKeywordMutation = gql`
  mutation(
    $before: String,
    $after: String,
    $language: String,
    $org: ID,
    $default: Boolean
  ) {
    addKeyword(
    before:$before,
    after:$after,
    language:$language,
    org:$org,
    default: $default
    ) {
    _id
    before,
    language,
    after,
    org,
    default
    }
  }
`;

export default addKeywordMutation;
