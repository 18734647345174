import { gql } from "apollo-boost";

const allViewsQuery = gql`
  
    query(
    $org: ID 
    $app: ID 
    $user: ID
  ) { 
    allViews (
      org: $org
      app: $app
      user: $user
    ) {
      _id
    org,
    name
    user
    users
    html
    css
    editors
    }
  }
  
`;

export default allViewsQuery;
