import './index.scss';
import * as React from 'react';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { debounce } from '@syncfusion/ej2-base';
import * as data from './dataSource.json';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
  temp = 'countries';
    //define the filtering data
    data = data[this.temp];
    query = new Query();
    // maps the appropriate column to fields property
    fields = { text: 'name'};
    // filtering event handler to filter a country
    onFiltering = debounce((e) => {
        let query = new Query();
        //frame the query based on search string with filter type.
        query = (e.text != "") ? query.where("name", "startswith", e.text, true) : query;
        //pass the filter data source, filter query to updateData method.
        e.updateData(this.data, query);
    }, 400);
    render() {
        return (
        <div className='control-section' style={{height:"100%"}}>
            <div id='multifilter' className="control-styles">
              <h4>{this.props.placeholder}</h4>
              <MultiSelectComponent {...this.props.allEvents} id="comboelement" dataSource={this.props.items} filtering={this.onFiltering.bind(this)} allowFiltering={true} fields={this.fields} placeholder={this.props.placeholder}/>
        </div>
        
      </div>);
    }
}

export default Component