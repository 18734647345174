import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Content5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <h2 class="sm:text-3xl text-2xl text-gray-900 font-medium title-font mb-2 md:w-2/5"> {<Element canvas style={{display:"inline-block"}} id="606749" is="div"><Text text="Kickstarter Actually Pinterest Brunch Bitters Occupy" /></Element>} </h2>
    <div class="md:w-3/5 md:pl-6">
      <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="883378" is="div"><Text text="Taxidermy bushwick celiac master cleanse microdosing seitan. Fashion axe four dollar toast truffaut, direct trade kombucha brunch williamsburg keffiyeh gastropub tousled squid meh taiyaki drinking vinegar tacos." /></Element>} </p>
      <div class="flex md:mt-4 mt-6">
        <Element canvas style={{ width: "100%" }} id={"div633423"} is="div">
            <Button classn={"inline-flex text-white bg-indigo-500 border-0 py-1 px-4 focus:outline-none hover:bg-indigo-600 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="416723" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
        <a class="text-indigo-500 inline-flex items-center ml-4"> {<Element canvas style={{display:"inline-block"}} id="902473" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Content5.craft = {
  displayName: "Content 5"
};