import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Pricing4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-8 mx-auto">
        <div class="xl:items-center xl:-mx-8 xl:flex">
            <div class="flex flex-col items-center xl:items-start xl:mx-8">
                <h1 class="text-3xl font-medium text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="198341" is="div"><Text text="Our Pricing Plan" /></Element>} </h1>

                <div class="mt-4">
                    <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                    <span class="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full"></span>
                    <span class="inline-block w-1 h-1 bg-blue-500 rounded-full"></span>
                </div>

                <p class="mt-4 font-medium text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="969672" is="div"><Text text="You can get All Access by selecting your plan!" /></Element>} </p>

                <Element canvas style={{ width: "100%" }} id={"div694129"} is="div">
            <Button href={"#"} classn={"flex items-center mt-4 -mx-1 text-sm text-gray-700 capitalize dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500"}>
              
                    <span class="mx-1"> {<Element canvas style={{display:"inline-block"}} id="972764" is="div"><Text text="read more" /></Element>} </span>
                    <svg class="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                
            </Button>
          </Element>
            </div>

            <div class="flex-1 xl:mx-8">
                <div class="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0">
                    <div class="max-w-sm mx-auto border rounded-lg md:mx-4 dark:border-gray-700">
                        <div class="p-6">
                            <h1 class="text-xl font-medium text-gray-700 capitalize lg:text-3xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="781565" is="div"><Text text="Essential" /></Element>} </h1>

                            <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="691228" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quam voluptatibus" /></Element>} </p>

                            <h2 class="mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="155700" is="div"><Text text="$3.00" /></Element>} <span class="text-base font-medium"> {<Element canvas style={{display:"inline-block"}} id="461958" is="div"><Text text="/Month" /></Element>} </span></h2>

                            <p class="mt-1 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="569676" is="div"><Text text="Yearly payment" /></Element>} </p>

                            <Element canvas style={{ width: "100%" }} id={"div270920"} is="div">
            <Button classn={"w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="345105" is="div"><Text text="Start Now" /></Element>} 
            </Button>
          </Element>
                        </div>

                        <hr class="border-gray-200 dark:border-gray-700" />

                        <div class="p-6">
                            <h1 class="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="594076" is="div"><Text text="What’s included:" /></Element>} </h1>

                            <div class="mt-8 space-y-4">
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="848850" is="div"><Text text="All limited links" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="394736" is="div"><Text text="Own analytics platform" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="324224" is="div"><Text text="Chat support" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="380390" is="div"><Text text="Optimize hashtags" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="236477" is="div"><Text text="Mobile app" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="986737" is="div"><Text text="Unlimited users" /></Element>} </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="max-w-sm mx-auto border rounded-lg md:mx-4 dark:border-gray-700">
                        <div class="p-6">
                            <h1 class="text-xl font-medium text-gray-700 capitalize lg:text-3xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="600243" is="div"><Text text="Premium" /></Element>} </h1>

                            <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="989343" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quam voluptatibus" /></Element>} </p>

                            <h2 class="mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="658651" is="div"><Text text="$50.00" /></Element>} <span class="text-base font-medium"> {<Element canvas style={{display:"inline-block"}} id="275579" is="div"><Text text="/life time" /></Element>} </span></h2>

                            <p class="mt-1 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="176281" is="div"><Text text="One time payment" /></Element>} </p>

                            <Element canvas style={{ width: "100%" }} id={"div623651"} is="div">
            <Button classn={"w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="929403" is="div"><Text text="Start Now" /></Element>} 
            </Button>
          </Element>
                        </div>

                        <hr class="border-gray-200 dark:border-gray-700" />

                        <div class="p-6">
                            <h1 class="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="492239" is="div"><Text text="What’s included:" /></Element>} </h1>

                            <div class="mt-8 space-y-4">
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="851328" is="div"><Text text="All limited links" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="185777" is="div"><Text text="Own analytics platform" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="625805" is="div"><Text text="Chat support" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="142138" is="div"><Text text="Optimize hashtags" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="514784" is="div"><Text text="Mobile app" /></Element>} </span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="361017" is="div"><Text text="Unlimited users" /></Element>} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

      </div>;
};
Pricing4.craft = {
  displayName: "Pricing 4"
};