import React, { useEffect } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { DataSpinner } from '../../spinner/dataSpinner';
import viewQuery from '../../../../graphql/queries/view/viewQuery';
import lz from 'lzutf8';
import { useLazyQuery } from '@apollo/client';
import { InstanceView } from '../../editor/editor/Viewport/InstanceView';
import { useEditor } from '@craftjs/core';
import InstancePreview from './InstancePreview';




const PreviewModal = (props) => {

  const dispatch = useDispatch()


    const {
       open,
        html,
        dataBasesData,
        eventsData,

    } = props

  console.log(open, 1)
  return(
        open ? <div style={{height:"100%", width:"100%"}} >  
          <h1 className="header" style={{justifyContent:"center", display:"flex", marginTop:"2rem", fontSize:"10px"}}>Instance preview</h1>
          <div style={{height:"100%", width:"100%"}}>
                <InstancePreview
                open={open}
                  html={html}
                  dataBasesData={dataBasesData}
                  eventsData={eventsData}
                />
          </div>
            
        </div> : (<DataSpinner />)
  )}

  export default PreviewModal
       