import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  return (
    <div style={{height:"100%", overflow:"auto"}}>
    <ToolbarSection
      title="Filter"
      props={['allowReordering']}
    >
      <ToolbarItem full={true} propKey="allowReordering" type="switch" label="Active" />
    </ToolbarSection>
    <ToolbarSection
      title="Sorting"
      props={['allowFiltering']}
    >
      <ToolbarItem full={true} propKey="allowFiltering" type="switch" label="Active" />
    </ToolbarSection>
    <ToolbarSection
      title="Ordering"
      props={["allowSorting"]}
    >
      <ToolbarItem full={true} propKey="allowSorting" type="switch" label="Active" />
    </ToolbarSection>
  </div>
  );
};
