import { useNode, useEditor } from '@craftjs/core';
import cx from 'classnames';
import { debounce } from 'debounce';
import { Resizable } from 're-resizable';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

export const Resizer = ({ propKey, className, children, style }) => {
  const {
    actions: { setProp },
    connectors: { connect },
  } = useNode()

  return (
    <div
    style={style}
    ref={connect}
    className={className} 
    >
      {children}
    </div>
    
  );
};