import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Content3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="861654" is="div"><Text text="Pitchfork Kickstarter Taxidermy" /></Element>} </h1>
      <p class="lg:w-1/2 w-full leading-relaxed text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="511883" is="div"><Text text="Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table." /></Element>} </p>
    </div>
    <div class="flex flex-wrap -m-4">
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg">
          <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="703969" is="div"><Text text="Shooting Stars" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="814406" is="div"><Text text="Fingerstache flexitarian street art 8-bit waist co, subway tile poke farm." /></Element>} </p>
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg">
          <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
              <circle cx="6" cy="6" r="3"></circle>
              <circle cx="6" cy="18" r="3"></circle>
              <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
            </svg>
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="653308" is="div"><Text text="The Catalyzer" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="638302" is="div"><Text text="Fingerstache flexitarian street art 8-bit waist co, subway tile poke farm." /></Element>} </p>
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg">
          <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="445591" is="div"><Text text="Neptune" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="863610" is="div"><Text text="Fingerstache flexitarian street art 8-bit waist co, subway tile poke farm." /></Element>} </p>
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg">
          <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
              <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
            </svg>
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="300653" is="div"><Text text="Melanchole" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="182530" is="div"><Text text="Fingerstache flexitarian street art 8-bit waist co, subway tile poke farm." /></Element>} </p>
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg">
          <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
              <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
            </svg>
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="187560" is="div"><Text text="Bunker" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="300371" is="div"><Text text="Fingerstache flexitarian street art 8-bit waist co, subway tile poke farm." /></Element>} </p>
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg">
          <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
            </svg>
          </div>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="566019" is="div"><Text text="Ramona Falls" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="201492" is="div"><Text text="Fingerstache flexitarian street art 8-bit waist co, subway tile poke farm." /></Element>} </p>
        </div>
      </div>
    </div>
    <Element canvas style={{ width: "100%" }} id={"div701640"} is="div">
            <Button classn={"flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"}>
               {<Element canvas style={{display:"inline-block"}} id="123843" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
  </div>
    </section>

      </div>;
};
Content3.craft = {
  displayName: "Content 3"
};