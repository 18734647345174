import { useEditor, Element } from '@craftjs/core';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Header } from './Header';
import { Toolbox } from './Toolbox';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {components} from '../../components'; 

import Editor, { loader }  from "@monaco-editor/react";



import {Column1} from "../../selectors/basic/Column/Column1"
import {Column2} from "../../selectors/basic/Column/Column2"
import {Column3} from "../../selectors/basic/Column/Column3"

import {Banner1} from "../../selectors/basic/Banner/Banner1"
import {Banner2} from "../../selectors/basic/Banner/Banner2"
import {Banner3} from "../../selectors/basic/Banner/Banner3"

import {Blog1} from "../../selectors/basic/Blog/Blog1"
import {Blog2} from "../../selectors/basic/Blog/Blog2"
import {Blog3} from "../../selectors/basic/Blog/Blog3"
import {Blog4} from "../../selectors/basic/Blog/Blog4"
import {Blog5} from "../../selectors/basic/Blog/Blog5"
import {Blog6} from "../../selectors/basic/Blog/Blog6"
import {Blog7} from "../../selectors/basic/Blog/Blog7"
import {Blog8} from "../../selectors/basic/Blog/Blog8"
import {Blog9} from "../../selectors/basic/Blog/Blog9"
import {Blog10} from "../../selectors/basic/Blog/Blog10"
import {Blog11} from "../../selectors/basic/Blog/Blog11"
import {Contact1} from "../../selectors/basic/Contact/Contact1"
import {Contact2} from "../../selectors/basic/Contact/Contact2"
import {Contact3} from "../../selectors/basic/Contact/Contact3"
import {Contact4} from "../../selectors/basic/Contact/Contact4"
import {Contact5} from "../../selectors/basic/Contact/Contact5"
import {Contact6} from "../../selectors/basic/Contact/Contact6"
import {Contact7} from "../../selectors/basic/Contact/Contact7"
import {Contact8} from "../../selectors/basic/Contact/Contact8"
import {Contact9} from "../../selectors/basic/Contact/Contact9"
import {Cta1} from "../../selectors/basic/Cta/Cta1"
import {Cta2} from "../../selectors/basic/Cta/Cta2"
import {Cta3} from "../../selectors/basic/Cta/Cta3"
import {Cta4} from "../../selectors/basic/Cta/Cta4"
import {Cta5} from "../../selectors/basic/Cta/Cta5"
import {Cta6} from "../../selectors/basic/Cta/Cta6"
import {Cta7} from "../../selectors/basic/Cta/Cta7"
import {Cta8} from "../../selectors/basic/Cta/Cta8"
import {Cta9} from "../../selectors/basic/Cta/Cta9"
import {Cta10} from "../../selectors/basic/Cta/Cta10"
import {Cta11} from "../../selectors/basic/Cta/Cta11"
import {Cta12} from "../../selectors/basic/Cta/Cta12"
import {Cta13} from "../../selectors/basic/Cta/Cta13"
import {Cta14} from "../../selectors/basic/Cta/Cta14"
import {Cta15} from "../../selectors/basic/Cta/Cta15"
import {Cta16} from "../../selectors/basic/Cta/Cta16"
import {Content1} from "../../selectors/basic/Content/Content1"
import {Content2} from "../../selectors/basic/Content/Content2"
import {Content3} from "../../selectors/basic/Content/Content3"
import {Content4} from "../../selectors/basic/Content/Content4"
import {Content5} from "../../selectors/basic/Content/Content5"
import {Content6} from "../../selectors/basic/Content/Content6"
import {Content7} from "../../selectors/basic/Content/Content7"
import {Content8} from "../../selectors/basic/Content/Content8"
import {Ecommerce1} from "../../selectors/basic/Ecommerce/Ecommerce1"
import {Ecommerce2} from "../../selectors/basic/Ecommerce/Ecommerce2"
import {Ecommerce3} from "../../selectors/basic/Ecommerce/Ecommerce3"
import {Faq1} from "../../selectors/basic/Faq/Faq1"
import {Faq2} from "../../selectors/basic/Faq/Faq2"
import {Faq3} from "../../selectors/basic/Faq/Faq3"
import {Faq4} from "../../selectors/basic/Faq/Faq4"
import {Faq5} from "../../selectors/basic/Faq/Faq5"
import {Faq6} from "../../selectors/basic/Faq/Faq6"
import {Faq7} from "../../selectors/basic/Faq/Faq7"
import {Faq8} from "../../selectors/basic/Faq/Faq8"
import {Faq9} from "../../selectors/basic/Faq/Faq9"
import {Features1} from "../../selectors/basic/Features/Features1"
import {Features2} from "../../selectors/basic/Features/Features2"
import {Features3} from "../../selectors/basic/Features/Features3"
import {Features4} from "../../selectors/basic/Features/Features4"
import {Features5} from "../../selectors/basic/Features/Features5"
import {Features6} from "../../selectors/basic/Features/Features6"
import {Features7} from "../../selectors/basic/Features/Features7"
import {Features8} from "../../selectors/basic/Features/Features8"
import {Features9} from "../../selectors/basic/Features/Features9"
import {Features10} from "../../selectors/basic/Features/Features10"
import {Features11} from "../../selectors/basic/Features/Features11"
import {Features12} from "../../selectors/basic/Features/Features12"
import {Features13} from "../../selectors/basic/Features/Features13"
import {Features14} from "../../selectors/basic/Features/Features14"
import {Features15} from "../../selectors/basic/Features/Features15"
import {Footer1} from "../../selectors/basic/Footer/Footer1"
import {Footer2} from "../../selectors/basic/Footer/Footer2"
import {Footer3} from "../../selectors/basic/Footer/Footer3"
import {Footer4} from "../../selectors/basic/Footer/Footer4"
import {Footer5} from "../../selectors/basic/Footer/Footer5"
import {Footer6} from "../../selectors/basic/Footer/Footer6"
import {Footer7} from "../../selectors/basic/Footer/Footer7"
import {Footer8} from "../../selectors/basic/Footer/Footer8"
import {Footer9} from "../../selectors/basic/Footer/Footer9"
import {Footer10} from "../../selectors/basic/Footer/Footer10"
import {Footer11} from "../../selectors/basic/Footer/Footer11"
import {Footer12} from "../../selectors/basic/Footer/Footer12"
import {Footer13} from "../../selectors/basic/Footer/Footer13"
import {Footer14} from "../../selectors/basic/Footer/Footer14"
import {Footer15} from "../../selectors/basic/Footer/Footer15"
import {Footer16} from "../../selectors/basic/Footer/Footer16"
import {Footer17} from "../../selectors/basic/Footer/Footer17"
import {Footer18} from "../../selectors/basic/Footer/Footer18"
import {Footer19} from "../../selectors/basic/Footer/Footer19"
import {Footer20} from "../../selectors/basic/Footer/Footer20"
import {Footer21} from "../../selectors/basic/Footer/Footer21"
import {Footer22} from "../../selectors/basic/Footer/Footer22"
import {Footer23} from "../../selectors/basic/Footer/Footer23"
import {Footer24} from "../../selectors/basic/Footer/Footer24"
import {Footer25} from "../../selectors/basic/Footer/Footer25"
import {Footer26} from "../../selectors/basic/Footer/Footer26"
import {Footer27} from "../../selectors/basic/Footer/Footer27"
import {Footer28} from "../../selectors/basic/Footer/Footer28"
import {Footer29} from "../../selectors/basic/Footer/Footer29"
import {Footer30} from "../../selectors/basic/Footer/Footer30"
import {Footer31} from "../../selectors/basic/Footer/Footer31"
import {Footer32} from "../../selectors/basic/Footer/Footer32"
import {Footer33} from "../../selectors/basic/Footer/Footer33"
import {Form1} from "../../selectors/basic/Form/Form1"
import {Form2} from "../../selectors/basic/Form/Form2"
import {Form3} from "../../selectors/basic/Form/Form3"
import {Form4} from "../../selectors/basic/Form/Form4"
import {Form5} from "../../selectors/basic/Form/Form5"
import {Form6} from "../../selectors/basic/Form/Form6"
import {Form7} from "../../selectors/basic/Form/Form7"
import {Form8} from "../../selectors/basic/Form/Form8"
import {Hero1} from "../../selectors/basic/Hero/Hero1"
import {Hero2} from "../../selectors/basic/Hero/Hero2"
import {Hero3} from "../../selectors/basic/Hero/Hero3"
import {Hero4} from "../../selectors/basic/Hero/Hero4"
import {Hero5} from "../../selectors/basic/Hero/Hero5"
import {Hero6} from "../../selectors/basic/Hero/Hero6"
import {Hero7} from "../../selectors/basic/Hero/Hero7"
import {Hero8} from "../../selectors/basic/Hero/Hero8"
import {Hero9} from "../../selectors/basic/Hero/Hero9"
import {Hero10} from "../../selectors/basic/Hero/Hero10"
import {Hero11} from "../../selectors/basic/Hero/Hero11"
import {Hero12} from "../../selectors/basic/Hero/Hero12"
import {Hero13} from "../../selectors/basic/Hero/Hero13"
import {Hero14} from "../../selectors/basic/Hero/Hero14"
import {Hero15} from "../../selectors/basic/Hero/Hero15"
import {Hero16} from "../../selectors/basic/Hero/Hero16"
import {Hero17} from "../../selectors/basic/Hero/Hero17"
import {Gallery1} from "../../selectors/basic/Gallery/Gallery1"
import {Gallery2} from "../../selectors/basic/Gallery/Gallery2"
import {Gallery3} from "../../selectors/basic/Gallery/Gallery3"
import {Header1} from "../../selectors/basic/Header/Header1"
import {Header2} from "../../selectors/basic/Header/Header2"
import {Header3} from "../../selectors/basic/Header/Header3"
import {Header4} from "../../selectors/basic/Header/Header4"
import {Navigation1} from "../../selectors/basic/Navigation/Navigation1"
import {Navigation2} from "../../selectors/basic/Navigation/Navigation2"
import {Navigation3} from "../../selectors/basic/Navigation/Navigation3"
import {Navigation4} from "../../selectors/basic/Navigation/Navigation4"
import {Navigation5} from "../../selectors/basic/Navigation/Navigation5"
import {Navigation6} from "../../selectors/basic/Navigation/Navigation6"
import {Navigation7} from "../../selectors/basic/Navigation/Navigation7"
import {Navigation8} from "../../selectors/basic/Navigation/Navigation8"
import {Navigation9} from "../../selectors/basic/Navigation/Navigation9"
import {Navigation10} from "../../selectors/basic/Navigation/Navigation10"
import {Navigation11} from "../../selectors/basic/Navigation/Navigation11"
import {Navigation12} from "../../selectors/basic/Navigation/Navigation12"
import {Navigation13} from "../../selectors/basic/Navigation/Navigation13"
import {Navigation14} from "../../selectors/basic/Navigation/Navigation14"
import {Navigation15} from "../../selectors/basic/Navigation/Navigation15"
import {Navigation16} from "../../selectors/basic/Navigation/Navigation16"
import {Portfolio1} from "../../selectors/basic/Portfolio/Portfolio1"
import {Portfolio2} from "../../selectors/basic/Portfolio/Portfolio2"
import {Portfolio3} from "../../selectors/basic/Portfolio/Portfolio3"
import {Portfolio4} from "../../selectors/basic/Portfolio/Portfolio4"
import {Portfolio5} from "../../selectors/basic/Portfolio/Portfolio5"
import {Pricing1} from "../../selectors/basic/Pricing/Pricing1"
import {Pricing2} from "../../selectors/basic/Pricing/Pricing2"
import {Pricing3} from "../../selectors/basic/Pricing/Pricing3"
import {Pricing4} from "../../selectors/basic/Pricing/Pricing4"
import {Pricing5} from "../../selectors/basic/Pricing/Pricing5"
import {Pricing6} from "../../selectors/basic/Pricing/Pricing6"
import {Pricing7} from "../../selectors/basic/Pricing/Pricing7"
import {Pricing8} from "../../selectors/basic/Pricing/Pricing8"
import {Pricing9} from "../../selectors/basic/Pricing/Pricing9"
import {Team1} from "../../selectors/basic/Team/Team1"
import {Team2} from "../../selectors/basic/Team/Team2"
import {Team3} from "../../selectors/basic/Team/Team3"
import {Team4} from "../../selectors/basic/Team/Team4"
import {Team5} from "../../selectors/basic/Team/Team5"
import {Team6} from "../../selectors/basic/Team/Team6"
import {Team7} from "../../selectors/basic/Team/Team7"
import {Team8} from "../../selectors/basic/Team/Team8"
import {Team9} from "../../selectors/basic/Team/Team9"
import {Review1} from "../../selectors/basic/Review/Review1"
import {Section1} from "../../selectors/basic/Section/Section1"
import {Section2} from "../../selectors/basic/Section/Section2"
import {Section3} from "../../selectors/basic/Section/Section3"
import {Section4} from "../../selectors/basic/Section/Section4"
import {Section5} from "../../selectors/basic/Section/Section5"
import {Section6} from "../../selectors/basic/Section/Section6"
import {Stats1} from "../../selectors/basic/Stats/Stats1"
import {Stats2} from "../../selectors/basic/Stats/Stats2"
import {Statistics1} from "../../selectors/basic/Statistics/Statistics1"
import {Statistics2} from "../../selectors/basic/Statistics/Statistics2"
import {Statistics3} from "../../selectors/basic/Statistics/Statistics3"
import {Steps1} from "../../selectors/basic/Steps/Steps1"
import {Steps2} from "../../selectors/basic/Steps/Steps2"
import {Steps3} from "../../selectors/basic/Steps/Steps3"
import {Testimonials1} from "../../selectors/basic/Testimonials/Testimonials1"
import {Testimonials2} from "../../selectors/basic/Testimonials/Testimonials2"
import {Testimonials3} from "../../selectors/basic/Testimonials/Testimonials3"
import {Testimonials4} from "../../selectors/basic/Testimonials/Testimonials4"
import {Testimonials5} from "../../selectors/basic/Testimonials/Testimonials5"
import {Testimonials6} from "../../selectors/basic/Testimonials/Testimonials6"
import {Testimonials7} from "../../selectors/basic/Testimonials/Testimonials7"
import {Testimonials8} from "../../selectors/basic/Testimonials/Testimonials8"
import {Testimonials9} from "../../selectors/basic/Testimonials/Testimonials9"
import {Testimonials10} from "../../selectors/basic/Testimonials/Testimonials10"
import {Testimonials11} from "../../selectors/basic/Testimonials/Testimonials11"
import {Testimonials12} from "../../selectors/basic/Testimonials/Testimonials12"
import {Testimonials13} from "../../selectors/basic/Testimonials/Testimonials13"
import {Testimonials14} from "../../selectors/basic/Testimonials/Testimonials14"
import {Testimonials15} from "../../selectors/basic/Testimonials/Testimonials15"
import {Testimonials16} from "../../selectors/basic/Testimonials/Testimonials16"
import { Input, Tooltip } from 'reactstrap';
import { PopupModal } from './popup';
import Text from '../../selectors/basic/Text';
import { ImageC } from '../../selectors/basic/Image';
import { Button } from '../../selectors/basic/Button';
import ACTION_OPTIONS from '../../../../../../actions/Options/ACTION_ACTIVE_ORG';
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE';
import ACTION_LOADING_SPINNER_RESET from '../../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET';
import { useLazyQuery, useMutation } from '@apollo/client';
import addShareableMutation from '../../../../../../graphql/mutations/shareable/addShareableMutation';
import allShareableQuery from '../../../../../../graphql/queries/Shareable/allShareableQuery';
import ACTION_FORMBUILDER from '../../../../../../actions/formBuilder/ACTION_FORMBUILDER';
import { Banner_1 } from '../../selectors/basic/Banner/newBanner';

 


loader.config({
  paths: {
    vs: '/monaco-editor/min/vs'
  }
});

const Item = styled.a`
svg { 
  width: 22px;
  height: 22px;
  fill: #707070;
}
`;

export const Viewport = ({
  children,
  renderMarkup,
  downloadContent
 }) => {

  const dispatch = useDispatch()
  const [allShareable, { error: shareableError, data: shareableData, loading: shareableLoading, refetch: shareableRefetch }] = useLazyQuery(allShareableQuery, {
    fetchPolicy: "no-cache",
  });

  const [addShareable, {error: addShareableError, data: addShareableData, loading: addShareableLoading}] = useMutation(addShareableMutation)
  const formBuilder = useSelector((state) => state.formBuilder.formBuilder);


  useEffect(() => {
    allShareable({
      variables:{
        org: "builder"
      }
    })
  },[])

  useEffect(() => {
    if(shareableData) {
      console.log(shareableData, 1010)
    }
  },[shareableData])
  useEffect(() => {
console.log(formBuilder, 109)
    if(formBuilder) {
      let name = prompt("Template Name", "")
                  if (name!==null) {
                   

      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        addShareable({ 
        variables: {
          org: "builder", 
          id: formBuilder,
          type: "widget",
          name: name
      }
    }).then(res => {
      shareableRefetch()
      dispatch(ACTION_FORMBUILDER(null))
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }) 
  } else {
    dispatch(ACTION_FORMBUILDER(null))
  }
    }
 

  },[formBuilder])

  const [codeContent, setCodeContent] = useState("")
  const monacoRef = useRef(null);


  function handleEditorWillMount(monaco) {
    // here is the monaco instance
    // do something before editor is mounted
    // monaco.languages.typescript.javascriptDefaults.addExtraLib("declare const testVar : () => 3")
    // monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare const allNodesArr: ${JSON.stringify(allNodes)}`)
    // monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare const allKeysArr: ${JSON.stringify(allKeys)}`)

  }

  function handleEditorDidMount(editor, monaco) {
    // here is another way to get monaco instance
    // you can also store it in `useRef` for further usage
    monacoRef.current = editor;
  }


  const [codeError, setCodeError] = useState("")
  function handleEditorValidation(markers) {
    // model markers
    markers?.length ? markers.forEach((marker) => setCodeError(marker.message)) : setCodeError("")

  }

  const components = [
    {name:"Column1", component:<Column1></Column1>},
    {name:"Column2", component:<Column2></Column2>},
    {name:"Column3", component:<Column3></Column3>},

    {name:"Basic1", component:<Text></Text>},
    {name:"Basic2", component:<ImageC></ImageC>},
    {name:"Basic3", component:<Button></Button>},

    {name:"Column3", component:<Column3></Column3>},
    // {name:"Banner1", component:<Banner1></Banner1>},
    {name:"Banner1", component:<Banner_1></Banner_1>},
    {name:"Banner2", component:<Banner2></Banner2>},
    {name:"Banner3", component:<Banner3></Banner3>},
    {name:"Blog1", component:<Blog1></Blog1>},
    {name:"Blog2", component:<Blog2></Blog2>},
    {name:"Blog3", component:<Blog3></Blog3>},
    {name:"Blog4", component:<Blog4></Blog4>},
    {name:"Blog5", component:<Blog5></Blog5>},
    {name:"Blog6", component:<Blog6></Blog6>},
    {name:"Blog7", component:<Blog7></Blog7>},
    {name:"Blog8", component:<Blog8></Blog8>},
    {name:"Blog9", component:<Blog9></Blog9>},
    {name:"Blog10", component:<Blog10></Blog10>},
    {name:"Blog11", component:<Blog11></Blog11>},
    {name:"Contact1", component:<Contact1></Contact1>},
    {name:"Contact2", component:<Contact2></Contact2>},
    {name:"Contact3", component:<Contact3></Contact3>},
    {name:"Contact4", component:<Contact4></Contact4>},
    {name:"Contact5", component:<Contact5></Contact5>},
    {name:"Contact6", component:<Contact6></Contact6>},
    {name:"Contact7", component:<Contact7></Contact7>},
    {name:"Contact8", component:<Contact8></Contact8>},
    {name:"Contact9", component:<Contact9></Contact9>},
    {name:"Cta1", component:<Cta1></Cta1>},
    {name:"Cta2", component:<Cta2></Cta2>},
    {name:"Cta3", component:<Cta3></Cta3>},
    {name:"Cta4", component:<Cta4></Cta4>},
    {name:"Cta5", component:<Cta5></Cta5>},
    {name:"Cta6", component:<Cta6></Cta6>},
    {name:"Cta7", component:<Cta7></Cta7>},
    {name:"Cta8", component:<Cta8></Cta8>},
    {name:"Cta9", component:<Cta9></Cta9>},
    {name:"Cta10", component:<Cta10></Cta10>},
    {name:"Cta11", component:<Cta11></Cta11>},
    {name:"Cta12", component:<Cta12></Cta12>},
    {name:"Cta13", component:<Cta13></Cta13>},
    {name:"Cta14", component:<Cta14></Cta14>},
    {name:"Cta15", component:<Cta15></Cta15>},
    {name:"Cta16", component:<Cta16></Cta16>},
    {name:"Content1", component:<Content1></Content1>},
    {name:"Content2", component:<Content2></Content2>},
    {name:"Content3", component:<Content3></Content3>},
    {name:"Content4", component:<Content4></Content4>},
    {name:"Content5", component:<Content5></Content5>},
    {name:"Content6", component:<Content6></Content6>},
    {name:"Content7", component:<Content7></Content7>},
    {name:"Content8", component:<Content8></Content8>},
    {name:"Ecommerce1", component:<Ecommerce1></Ecommerce1>},
    {name:"Ecommerce2", component:<Ecommerce2></Ecommerce2>},
    {name:"Ecommerce3", component:<Ecommerce3></Ecommerce3>},
    {name:"Faq1", component:<Faq1></Faq1>},
    {name:"Faq2", component:<Faq2></Faq2>},
    {name:"Faq3", component:<Faq3></Faq3>},
    {name:"Faq4", component:<Faq4></Faq4>},
    {name:"Faq5", component:<Faq5></Faq5>},
    {name:"Faq6", component:<Faq6></Faq6>},
    {name:"Faq7", component:<Faq7></Faq7>},
    {name:"Faq8", component:<Faq8></Faq8>},
    {name:"Faq9", component:<Faq9></Faq9>},
    {name:"Features1", component:<Features1></Features1>},
    {name:"Features2", component:<Features2></Features2>},
    {name:"Features3", component:<Features3></Features3>},
    {name:"Features4", component:<Features4></Features4>},
    {name:"Features5", component:<Features5></Features5>},
    {name:"Features6", component:<Features6></Features6>},
    {name:"Features7", component:<Features7></Features7>},
    {name:"Features8", component:<Features8></Features8>},
    {name:"Features9", component:<Features9></Features9>},
    {name:"Features10", component:<Features10></Features10>},
    {name:"Features11", component:<Features11></Features11>},
    {name:"Features12", component:<Features12></Features12>},
    {name:"Features13", component:<Features13></Features13>},
    {name:"Features14", component:<Features14></Features14>},
    {name:"Features15", component:<Features15></Features15>},
    {name:"Footer1", component:<Footer1></Footer1>},
    {name:"Footer2", component:<Footer2></Footer2>},
    {name:"Footer3", component:<Footer3></Footer3>},
    {name:"Footer4", component:<Footer4></Footer4>},
    {name:"Footer5", component:<Footer5></Footer5>},
    {name:"Footer6", component:<Footer6></Footer6>},
    {name:"Footer7", component:<Footer7></Footer7>},
    {name:"Footer8", component:<Footer8></Footer8>},
    {name:"Footer9", component:<Footer9></Footer9>},
    {name:"Footer10", component:<Footer10></Footer10>},
    {name:"Footer11", component:<Footer11></Footer11>},
    {name:"Footer12", component:<Footer12></Footer12>},
    {name:"Footer13", component:<Footer13></Footer13>},
    {name:"Footer14", component:<Footer14></Footer14>},
    {name:"Footer15", component:<Footer15></Footer15>},
    {name:"Footer16", component:<Footer16></Footer16>},
    {name:"Footer17", component:<Footer17></Footer17>},
    {name:"Footer18", component:<Footer18></Footer18>},
    {name:"Footer19", component:<Footer19></Footer19>},
    {name:"Footer20", component:<Footer20></Footer20>},
    {name:"Footer21", component:<Footer21></Footer21>},
    {name:"Footer22", component:<Footer22></Footer22>},
    {name:"Footer23", component:<Footer23></Footer23>},
    {name:"Footer24", component:<Footer24></Footer24>},
    {name:"Footer25", component:<Footer25></Footer25>},
    {name:"Footer26", component:<Footer26></Footer26>},
    {name:"Footer27", component:<Footer27></Footer27>},
    {name:"Footer28", component:<Footer28></Footer28>},
    {name:"Footer29", component:<Footer29></Footer29>},
    {name:"Footer30", component:<Footer30></Footer30>},
    {name:"Footer31", component:<Footer31></Footer31>},
    {name:"Footer32", component:<Footer32></Footer32>},
    {name:"Footer33", component:<Footer33></Footer33>},
    {name:"Form1", component:<Form1></Form1>},
    {name:"Form2", component:<Form2></Form2>},
    {name:"Form3", component:<Form3></Form3>},
    {name:"Form4", component:<Form4></Form4>},
    {name:"Form5", component:<Form5></Form5>},
    {name:"Form6", component:<Form6></Form6>},
    {name:"Form7", component:<Form7></Form7>},
    {name:"Form8", component:<Form8></Form8>},
    {name:"Hero1", component:<Hero1></Hero1>},
    {name:"Hero2", component:<Hero2></Hero2>},
    {name:"Hero3", component:<Hero3></Hero3>},
    {name:"Hero4", component:<Hero4></Hero4>},
    {name:"Hero5", component:<Hero5></Hero5>},
    {name:"Hero6", component:<Hero6></Hero6>},
    {name:"Hero7", component:<Hero7></Hero7>},
    {name:"Hero8", component:<Hero8></Hero8>},
    {name:"Hero9", component:<Hero9></Hero9>},
    {name:"Hero10", component:<Hero10></Hero10>},
    {name:"Hero11", component:<Hero11></Hero11>},
    {name:"Hero12", component:<Hero12></Hero12>},
    {name:"Hero13", component:<Hero13></Hero13>},
    {name:"Hero14", component:<Hero14></Hero14>},
    {name:"Hero15", component:<Hero15></Hero15>},
    {name:"Hero16", component:<Hero16></Hero16>},
    {name:"Hero17", component:<Hero17></Hero17>},
    {name:"Gallery1", component:<Gallery1></Gallery1>},
    {name:"Gallery2", component:<Gallery2></Gallery2>},
    {name:"Gallery3", component:<Gallery3></Gallery3>},
    {name:"Header1", component:<Header1></Header1>},
    {name:"Header2", component:<Header2></Header2>},
    {name:"Header3", component:<Header3></Header3>},
    {name:"Header4", component:<Header4></Header4>},
    {name:"Navigation1", component:<Navigation1></Navigation1>},
    {name:"Navigation2", component:<Navigation2></Navigation2>},
    {name:"Navigation3", component:<Navigation3></Navigation3>},
    {name:"Navigation4", component:<Navigation4></Navigation4>},
    {name:"Navigation5", component:<Navigation5></Navigation5>},
    {name:"Navigation6", component:<Navigation6></Navigation6>},
    {name:"Navigation7", component:<Navigation7></Navigation7>},
    {name:"Navigation8", component:<Navigation8></Navigation8>},
    {name:"Navigation9", component:<Navigation9></Navigation9>},
    {name:"Navigation10", component:<Navigation10></Navigation10>},
    {name:"Navigation11", component:<Navigation11></Navigation11>},
    {name:"Navigation12", component:<Navigation12></Navigation12>},
    {name:"Navigation13", component:<Navigation13></Navigation13>},
    {name:"Navigation14", component:<Navigation14></Navigation14>},
    {name:"Navigation15", component:<Navigation15></Navigation15>},
    {name:"Navigation16", component:<Navigation16></Navigation16>},
    {name:"Portfolio1", component:<Portfolio1></Portfolio1>},
    {name:"Portfolio2", component:<Portfolio2></Portfolio2>},
    {name:"Portfolio3", component:<Portfolio3></Portfolio3>},
    {name:"Portfolio4", component:<Portfolio4></Portfolio4>},
    {name:"Portfolio5", component:<Portfolio5></Portfolio5>},
    {name:"Pricing1", component:<Pricing1></Pricing1>},
    {name:"Pricing2", component:<Pricing2></Pricing2>},
    {name:"Pricing3", component:<Pricing3></Pricing3>},
    {name:"Pricing4", component:<Pricing4></Pricing4>},
    {name:"Pricing5", component:<Pricing5></Pricing5>},
    {name:"Pricing6", component:<Pricing6></Pricing6>},
    {name:"Pricing7", component:<Pricing7></Pricing7>},
    {name:"Pricing8", component:<Pricing8></Pricing8>},
    {name:"Pricing9", component:<Pricing9></Pricing9>},
    {name:"Team1", component:<Team1></Team1>},
    {name:"Team2", component:<Team2></Team2>},
    {name:"Team3", component:<Team3></Team3>},
    {name:"Team4", component:<Team4></Team4>},
    {name:"Team5", component:<Team5></Team5>},
    {name:"Team6", component:<Team6></Team6>},
    {name:"Team7", component:<Team7></Team7>},
    {name:"Team8", component:<Team8></Team8>},
    {name:"Team9", component:<Team9></Team9>},
    {name:"Review1", component:<Review1></Review1>},
    {name:"Section1", component:<Section1></Section1>},
    {name:"Section2", component:<Section2></Section2>},
    {name:"Section3", component:<Section3></Section3>},
    {name:"Section4", component:<Section4></Section4>},
    {name:"Section5", component:<Section5></Section5>},
    {name:"Section6", component:<Section6></Section6>},
    {name:"Stats1", component:<Stats1></Stats1>},
    {name:"Stats2", component:<Stats2></Stats2>},
    {name:"Statistics1", component:<Statistics1></Statistics1>},
    {name:"Statistics2", component:<Statistics2></Statistics2>},
    {name:"Statistics3", component:<Statistics3></Statistics3>},
    {name:"Steps1", component:<Steps1></Steps1>},
    {name:"Steps2", component:<Steps2></Steps2>},
    {name:"Steps3", component:<Steps3></Steps3>},
    {name:"Testimonials1", component:<Testimonials1></Testimonials1>},
    {name:"Testimonials2", component:<Testimonials2></Testimonials2>},
    {name:"Testimonials3", component:<Testimonials3></Testimonials3>},
    {name:"Testimonials4", component:<Testimonials4></Testimonials4>},
    {name:"Testimonials5", component:<Testimonials5></Testimonials5>},
    {name:"Testimonials6", component:<Testimonials6></Testimonials6>},
    // {name:"Testimonials7", component:<Testimonials7></Testimonials7>},
    // {name:"Testimonials8", component:<Testimonials8></Testimonials8>},
    // {name:"Testimonials9", component:<Testimonials9></Testimonials9>},
    // {name:"Testimonials10", component:<Testimonials10></Testimonials10>},
    // {name:"Testimonials11", component:<Testimonials11></Testimonials11>},
    // {name:"Testimonials12", component:<Testimonials12></Testimonials12>},
    // {name:"Testimonials13", component:<Testimonials13></Testimonials13>},
    {name:"Testimonials14", component:<Testimonials14></Testimonials14>},
    {name:"Testimonials15", component:<Testimonials15></Testimonials15>},
    {name:"Testimonials16", component:<Testimonials16></Testimonials16>},
  ]


  const [ allComponents, setAllComponents ] = useState([])

  useEffect(() => {
    const fetchImages = async () => {
      const urls = await Promise.all(
        components.map(async (imageObj) => {
          const { name } = imageObj;
          return {
            name:imageObj.name,
            component:imageObj.component,
            url: null

          };
        })
      );

      const groupedComponents = {};


      urls.forEach((x) => {
        const groupName = x.name.replace(/\d+$/, ''); // Remove the index at the end
    
        if (!groupedComponents[groupName]) {
          groupedComponents[groupName] = [];
        }
    
        groupedComponents[groupName].push(x);
      });
    

      setAllComponents(groupedComponents);

    };

    fetchImages();
  }, []);


  const widgetSettings = useSelector((state) => state.viewSettings.viewSettings);
  const activeIdButton = useSelector((state) => state.activeId.id);
  

  const {
    enabled,
    connectors, 
    actions,
    json,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled || false,
    json: query.serialize() ,
    nodes: state.nodes
  }));

  const [canvasView, setCanvasView] = useState("pc")
  const [layers, setLayers] = useState(true)
  const [addWidgetActive, setAddWidgetActive] = useState(false)
  const [addPagesActive, setAddPagesActive] = useState(false)
  const [groupActive, setGroupActive] = useState(null)
  const [previewMode, setPreviewMode] = useState(false)
  const [over, setOver] = useState(false)
  

const Group = ({ name, components }) => {
  console.log(components, "hey there")
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{color:"white", margin:"5px 0", cursor:"pointer"}}>
      <div onClick={toggleExpanded}>{name}</div>
      <div style={{display:"flex", flexWrap:"wrap", height:"fit-content"}}>
      {true && components.map((x, index) => (
        <div 
        className='widgetItemIn kok'
        onDragStart={() => setOver(false)}
        onDragEnd={() => {
          setOver(true)
          setAddWidgetActive(false)
          setAddPagesActive(false)
          setGroupActive(null)
        }}
        
        ref={(ref) => 
          connectors?.create(
            ref,
           x.component
          )
        } 
      >

<div style={{width:"100%"}} className="border-solid border-[#e9e9ea] bg-white flex flex-col justify-end gap-6 w-1/2 h-32 items-center py-3 border rounded-lg">
        <img
          src={x.url}
          className="w-10"
          id="Icons1"
        />
        <div className="text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
         {x.name}
        </div>
      </div>
{/* 
        <div title={x.name} className='widgetItemName' style={{marginTop:"30px", width:"100%", marginLeft:0, fontSize:"16px"}}>
        <img src={x.url} />
        </div> */}
      </div>
      ))}
      </div>
    </div>
  );
};



const treeData = useSelector((state) => state.options.options);
const [zoomSize, setZoomSize] = useState(1440)

// const [treeData, setTreeData] = useState([{_id: 1,name:"home",  children:[]}]) 
  
function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const [expanded, setExpanded] = useState([]);
const [activeId, setActiveId] = useState("home");
const [allNodes, setAllNodes] = useState([])

useEffect(() => {
  if(activeIdButton) {
    setActiveId(activeIdButton)
  }
},[activeIdButton])

useEffect(() => {
  let found = allNodes.find(x => x.name == activeId)
  found ? 
  actions.deserialize(found.json) : 
  actions.deserialize({"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true, "props":{ "flexDirection":"column","alignItems":"flex-start","justifyContent":"flex-start","fillSpace":"no","padding":["40","40","40","40"],"margin":["0","0","0","0"],"background":{"r":255,"g":255,"b":255,"a":1},"color":{"r":0,"g":0,"b":0,"a":1},"shadow":0,"radius":0,"width":"100%%","height":"518px"},"displayName":"Container","custom":{"displayName":"App"},"hidden":false,"nodes":[],"linkedNodes":{}}})
},[activeId])

useEffect(() => {
  if(json) {
    setAllNodes([...allNodes.filter(x => x.name !== activeId), {name: activeId , json: JSON.parse(json)}])
  }
},[json])

function TreeView({ data }) {


  function findNodeById(nodeId, nodes) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node._id === nodeId) {
        return node;
      }
      if (node.children) {
        const childNode = findNodeById(nodeId, node.children);
        if (childNode) {
          return childNode;
        }
      }
    }
    return null;
  }

  const [editingId, setEditingId] = useState(null);
console.log(expanded, 111111)
  const handleToggle = (name , nodeId, selected) => {
    setExpanded((prevState) =>
      prevState.includes(nodeId) ? !selected
        ? prevState.filter((id) => id !== nodeId)
        : [...prevState] : [...prevState, nodeId]
    )
    setActiveId(selected || name)
  };

  const handleDoubleClick = (nodeId) => {
    setEditingId(nodeId);
  }; 

  const handleEditNode = (nodeId, newName) => {
    const nodeToEdit = findNodeById(nodeId, data);
    nodeToEdit.name = newName;
    setEditingId(null);
    setExpanded(expanded);
  };
  const dispatch = useDispatch()

  const handleAddAction = (name, parentId) => {
    // generate a unique ID for the new action
    const newActionId = generateRandomString(5);

    // create a new action object with default values
    const newAction = {
      _id: newActionId,
      name: name || "New Page",
      children: [],
    };

    // find the parent node in the data array
    const parent = findNodeById(parentId, data);

    // add the new action to the parent node's children array
    if(parent) {
      parent?.children.push(newAction)
    } else if (treeData) {
      dispatch(ACTION_OPTIONS([...treeData, newAction]))
    }
    //  || treeData?.push(newAction)
    handleToggle(name, parentId, newAction.name)
    
  };

  const renderTree = (nodes, child, level = 0) => {
    return (
      <ul style={{ padding: 0, width: "100%" }}>

{level == 0 ?<div
          className='widgetItemIn dm active'
          onClick={() => setGroupActive("grids")}
        >
          <div className='widgetItemName' style={{marginTop:"30px", width:"100%", marginLeft:0, fontSize:"16px"}}>
            Pages
          </div>


          
        </div> : null
}
        {nodes.map((node, i) => {
          const isExpanded = expanded.includes(node._id);
          
          return (
            <li key={node._id}>
              <div
                style={{
                  width: "100%",
                  // borderTop: i == 0 ? "1px solid #2d3038" : "0px",
                  cursor: "pointer",
                  // background: "#959dad14",
                  color: activeId == node.name ? "#6db290" : "white"
                }}
                // className="viewNC"
                onClick={() => {
                  handleToggle(node.name);
                }}
                onDoubleClick={() => { 
                  handleDoubleClick(node._id);
                }}
              >



 <div
 style={{width:"100%"}}
        className="border-solid border-[#e9e9ea] self-stretch flex flex-row justify-between h-10 shrink-0 items-center pt-1 px-5 border rounded-[30px]"
        id="BasicInput"
      >
        <div
          className="font-['Inter'] leading-[24px] text-[#192746] self-start mt-1"
          id="InputText1"
        >
         {node.name}
        </div>
        <div style={{display:"flex", width:"50px", justifyContent:"space-between"}}>
        <img
          src="https://file.rendit.io/n/DqyNHz5H5CTmX1wIOlv2.svg"
          className="w-5 shrink-0"
          id="Icons1"
        />
         <PopupModal onClick={(e) => e.stopPropagation()} onButtonclicked={() => handleToggle(node.name, node._id)}  >
                
                
                <div className='viewNC buttonNC dmColor' style={{margin:"10px auto"}} onClick={() => {
                  
                  let name = prompt("Page Name", "")
                  if (name!==null) {
                    handleAddAction(name , node._id)
                  }
                  }}>add page</div>
              </PopupModal>
              </div>
      </div>
      

              {/* <div style={{width:"1.9rem", height:"1.6rem", background: !child ? "rgba(171, 176, 185, .6)" : "transparent", borderRadius:"3px"}}>{!child ? <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.794 12.104a.644.644 0 00.393-.524 4.904 4.904 0 014.884-4.372 4.91 4.91 0 014.834 4.026.643.643 0 00.626.527 4.53 4.53 0 014.47 4.515 4.521 4.521 0 01-4.517 4.516H9.516a4.514 4.514 0 01-1.722-8.689z" fill="#959dad"></path></svg>: null}</div>  */}
              {/* {node.children && (
                  <span style={{ marginRight: "0.5rem" }}>
                    {isExpanded ? "▼" : "►"}
                  </span>)} */}

                {/* <span className="viewWN" style={{width:"100%", padding:".125rem .625rem", marginLeft:`${level * 5}px`}}>{node.name}</span> */}

               </div>

               {node.children && isExpanded && renderTree(node.children, true, level + 1)}
            </li>
          );
        })}

<div style={{marginTop:"50px", background:"white", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
<PopupModal custom onClick={(e) => e.stopPropagation()}>

             
<div
style={{width:"150px"}}
onClick={() => {
            
  let name = prompt("Page Name", "")
  if (name!==null) {
    handleAddAction(name)
  }
  }}

  className="bg-[#0867fc] flex flex-row justify-center gap-2 w-2/3 h-12 shrink-0 items-center rounded-[30px]"
  id="Button1"
>
  <img
    src="https://file.rendit.io/n/upLFn3oZBNeXDSH38tKa.svg"
    className="w-6 shrink-0"
    id="Icons"
  />
  <div
    className="text-center text-lg font-['Inter'] font-bold leading-[24px] text-white"
    id="Label"
  >
    Add page
  </div>
</div>


          {/* <div className='viewNC buttonNC dmColor' style={{margin:"10px auto"}} >add page</div> */}
        </PopupModal>
        </div>
        
      </ul>
    );
  };

  return <div style={{width:"100%"}}>{renderTree(data)}</div>;
}

async function loadPageContent(page) {
  // Simulating loading the content with a delay of 1 second
  setActiveId(page.name)
  await new Promise(resolve => setTimeout(resolve, 1000));
  
  // Perform the necessary logic to load the content of the page
  // ...
  return page; // Return the updated page object with loaded content
}



async function savePage(page) {
  // Perform the necessary logic to save the page
  // ...
  
  // Simulating saving the page with a delay of 1 second

  renderMarkup(codeContent, page.name)


  await new Promise(resolve => setTimeout(resolve, 1000));
  
  return page
}

// Function to set the active page, load its content, and save the entire page
async function setActivePageAndSave(page) {
  // Set the active page
  // ...
  // Load the content of the active page
  const pageWithContent = await loadPageContent(page);
  // Save the entire page
  await savePage(pageWithContent);
}

async function processPages(pages) {
  for (const page of pages) {
    await setActivePageAndSave(page);
  }
}


  return (
    <div className="viewport" onDrop={() => {
      
      
      setAddPagesActive(false)
      setAddWidgetActive(false)
    }}>
      {!codeError ? <style>{codeContent}</style> : null}
      {widgetSettings && <div style={{position:"fixed", top:0, right:0, bottom:0, zIndex:100}}>
        <Toolbox settings={widgetSettings}>
        </Toolbox>
      </div>}
  
      <div
        className={cx(['flex h-full overflow-hidden flex-row w-full fixed'])}
      >
        {
          true && <Toolbox
          enabled={enabled}
          layers={layers}
          setLayers={setLayers}
          addWidgetActive={addWidgetActive}
          addPagesActive={addPagesActive}
          setAddWidgetActive={setAddWidgetActive}
          setAddPagesActive={setAddPagesActive}
          />
        }

        <div className="page-container flex flex-1 h-full flex-col"
        style={{alignItems:"center", position:"relative", width:"100%"}}
        >

          {(addPagesActive ) && enabled ? <div onClick={(() => {
            setAddPagesActive(false)
            setGroupActive(null)
          })} style={{ width:  over ? "100vw" : "0px", height:  over ? "100vh" : "0px", zIndex:"100000", position:"absolute", left:0, right:0}}> { addPagesActive ? <div onClick={(event) => event.stopPropagation()}> 
          <div style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",height:"100vh", overflowY:"auto",position:"absolute", left:"0px", width:"300px", zIndex:99991, background:"#16171c"}}>          

 <div style={{width:"100%", height:"100%", background:"white"}}><div className="flex flex-col w-full" id="SidebarComponentRoot">
  <div className="shadow-[2px_0px_10px_0px_rgba(164,_164,_164,_0.1)] bg-white flex flex-col gap-16 h-[1000px] shrink-0 px-5 py-8">
    <div className="flex flex-col justify-between gap-5 items-center">
      <div className="text-2xl font-['Inter'] font-bold leading-[33.6px] uppercase text-[#161717]">
        Pages
      </div>

        <TreeView data={treeData} />

    </div>
    <div className="flex flex-col justify-between gap-5 items-center">
      <div className="text-2xl font-['Inter'] font-bold leading-[33.6px] uppercase text-[#161717]">
        styling
      </div>
      <div
        className="border-solid border-[#e9e9ea] self-stretch flex flex-col gap-16 h-[140px] shrink-0 items-center pt-2 pb-1 pr-1 border rounded-lg"
        id="TextareaInput"
      >
        <div
          className="text-sm font-['Rubik'] leading-[19.6px] text-[#989191] w-full"
          id="InputText"
        >
          Use custom CSS classes to style components, add styles in app settings
          Code tab.
        </div>
        <div className="self-end relative flex flex-col pt-1 w-2 items-start">
          <img
            src="https://file.rendit.io/n/Mzb3OmllXxEMHfkXMyX1.svg"
            className="w-2 h-2 absolute top-0 left-0"
            id="Line"
          />
          <img
            src="https://file.rendit.io/n/LoSAu4Tk1BnZaiqeeZEL.svg"
            className="relative ml-1 w-1"
            id="Line1"
          />
        </div>
      </div>
      <button
        className="bg-[#e9e9ea] flex flex-col justify-center w-2/3 h-12 shrink-0 items-center rounded-[430px]"
        id="Button2"
      >
        <div
          className="text-center text-lg font-['Inter'] font-bold leading-[24px] text-[#989191]"
          id="Label1"
        >
          Apply 
        </div>
      </button>
    </div>
  </div>
</div>
</div>


        {/* <div className='containerList' style={{display:"flex", flexDirection:"column"}}>



        <div 
          className='widgetItemIn dm topheader'
        >
          <div className='widgetItemName' style={{marginTop:"30px", width:"100%", marginLeft:0, fontSize:"16px"}}>
            styling
          </div>
        </div>


        <div className='widgetItemNameD style={{marginTop:"30px", width:"100%", marginLeft:0, fontSize:"16px"}}es'>
        Use custom CSS classes to style components, add styles in app settings Code tab.
          </div>

        <Editor
        height="300px"
        width="100%"
        defaultLanguage="scss"
        value={codeContent}
        onValidate={handleEditorValidation}
        onChange={(e) => setCodeContent(e)}
        beforeMount={handleEditorWillMount}
        onMount={handleEditorDidMount}
        />

        <div className='hori'  /> 

          </div> */}
          </div>
          </div>  : null }
        

          </div> : null
          }

          {(addWidgetActive || groupActive ) && enabled ? <div onClick={(() => {
            setAddWidgetActive(false)
            setGroupActive(null)
          })} style={{width:  over ? "100vw" : "0px", height:  over ? "100vh" : "0px", zIndex:"100000", position:"absolute", left:0, right:0}}> { addWidgetActive ? <div style={{width:"300px", height:"100vh"}} onClick={(event) => event.stopPropagation()}> 
         <div style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",width:"100%", height:"100%", background:"white"}}><div className="flex flex-col w-full" id="SidebarComponentRoot">
  <div className="shadow-[2px_0px_10px_0px_rgba(164,_164,_164,_0.1)] bg-white flex flex-col gap-1 h-[1000px] shrink-0 py-8">
    <div className="text-2xl font-['Inter'] font-bold leading-[33.6px] text-[#020214] self-center mb-4">
      COMPONENTS
    </div>
    <div
      className="border-solid border-[#e9e9ea] flex flex-row gap-2 h-10 shrink-0 items-center mb-4 mx-5 px-5 border rounded-[30px]"
      id="BasicInput"
    >
      <img
        src="https://file.rendit.io/n/zOBxVFkBtEBgP2Skh7sr.svg"
        className="w-6 shrink-0"
        id="Icons"
      />
      <div
        className="font-['Inter'] leading-[24px] text-[#989191]"
        id="InputText"
      >
        Search
      </div>
    </div>
    <button
      className="border-solid border-[#0867fc] bg-white flex flex-col justify-center h-10 shrink-0 items-center mb-6 mx-5 border rounded-[30px]"
      id="Button1"
    >
      <div
        className="text-center text-sm font-['Inter'] font-bold leading-[16px] text-[#0671e0]"
        id="Label"
      >
        Quick Setup{" "}
      </div>
    </button>
    <div className="flex flex-row gap-10 items-start mx-6">
      <div className="text-lg font-['Inter'] font-medium leading-[25.2px] text-[#0867fc]">
        Basic
      </div>
      <div className="text-lg font-['Inter'] leading-[25.2px] text-[#161717] mr-px">
        Blocks
      </div>
      <div className="text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
        Templates
      </div>
    </div>
    <div className="bg-[#e9e9ea] flex flex-col mb-6 items-start px-5">
      <div className="bg-[#0867fc] w-12 h-1 shrink-0 rounded-sm" />
    </div>


    <div  className='containerList' style={{display:"flex", flexDirection:"column"}}>
    {Object.entries(allComponents).filter(([groupName, components]) => groupName == "Banner").map(([groupName, components], index) => (
      <>
      <Group key={index} name={groupName} components={components} />
      </>
    ))}

    </div>
      {/* <div className="border-solid border-[#e9e9ea] bg-white flex flex-col justify-end gap-6 w-1/2 h-32 items-center py-3 border rounded-lg">
        <img
          src="https://file.rendit.io/n/J4qU3igp4o4ZpJF3sfWX.svg"
          className="w-10"
          id="Icons1"
        />
        <div className="text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
          1 column
        </div>
      </div>
      <div className="border-solid border-[#e9e9ea] bg-white flex flex-col justify-end gap-4 w-1/2 h-32 items-center py-3 border rounded-lg">
        <img
          src="https://file.rendit.io/n/mhoBCH0kgORvq79g4y6U.svg"
          className="w-12"
          id="Icons6"
        />
        <div className="text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
          2 column
        </div>
      </div> */}

    {/*



    <div className="flex flex-row gap-2 items-start mb-1 mx-5">
      <div className="border-solid border-[#e9e9ea] bg-white flex flex-col justify-center gap-1 w-1/2 items-center pt-5 pb-3 px-6 border rounded-lg">
        <img
          src="https://file.rendit.io/n/KdvPWwbvHG5GIFLGQdRw.svg"
          className="w-16"
          id="Icons2"
        />
        <div className="text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
          3 column
        </div>
      </div>
      <div className="border-solid border-[#e9e9ea] bg-white flex flex-col justify-end gap-6 w-1/2 h-32 items-center py-3 border rounded-lg">
        <img
          src="https://file.rendit.io/n/zIaBBiVfxHm2J0I9hl2A.svg"
          className="w-8"
          id="Icons7"
        />
        <div className="text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
          Text
        </div>
      </div>
    </div>
    <div className="flex flex-row gap-2 items-start mb-1 mx-5">
      <div className="border-solid border-[#e9e9ea] bg-white flex flex-col justify-end gap-4 w-1/2 h-32 items-center py-3 border rounded-lg">
        <img
          src="https://file.rendit.io/n/P6ehXZxh5nQ7NoX1Tmvn.svg"
          className="w-12"
          id="Icons8"
        />
        <div className="text-center text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
          Image
        </div>
      </div>
      <div className="border-solid border-[#e9e9ea] bg-white flex flex-col justify-end gap-4 w-1/2 h-32 items-center py-3 border rounded-lg">
        <img
          src="https://file.rendit.io/n/l7wRCZN3tYExceeKPy7I.svg"
          className="w-12"
          id="Icons3"
        />
        <div className="text-center text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
          Price
        </div>
      </div>
    </div>
    <div className="flex flex-row gap-2 items-start mx-5">
      <div className="border-solid border-[#e9e9ea] bg-white flex flex-col justify-end gap-3 w-1/2 h-32 items-center py-3 border rounded-lg">
        <img
          src="https://file.rendit.io/n/BM7mXQK2Y59E0UGGwurn.svg"
          className="w-12"
          id="Icons4"
        />
        <div className="text-center text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
          Step
        </div>
      </div>
      <div className="border-solid border-[#e9e9ea] bg-white flex flex-col justify-end gap-3 w-1/2 h-32 items-center py-3 border rounded-lg">
        <img
          src="https://file.rendit.io/n/9476nmx06V9eNnnIvKPW.svg"
          className="w-12"
          id="Icons5"
        />
        <div className="text-center text-lg font-['Inter'] leading-[25.2px] text-[#161717]">
          Blog
        </div>
      </div>
    </div> */}
  </div>
</div>
</div>

          </div>  : null }
        

          </div> : null
          }
        <div className="viewsHeader" style={{alignItems:"center", justifyContent:"space-between"}}>
          <div style={{display:"flex", alignItems:"flex-end", height:"100%"}}>
                </div>
          {!downloadContent ? <div style={{display:"flex", width:"100%", height:"200px", flexDirection:"row", alignItems:"center"}}>
        
          <div style={{width:"100%"}} className="flex flex-col w-full" id="HeaderRoot">
          <div className="flex flex-col w-full" id="HeaderRoot">
  <div className="shadow-[0px_2px_10px_0px_rgba(164,_164,_164,_0.1)] bg-white flex flex-row justify-between h-20 shrink-0 items-center pl-6 pr-16">
    <div className="flex flex-row gap-12 items-start">
      <img
        src="https://file.rendit.io/n/Si0NuN4d5ArLow4pjmoZ.svg"
        className="mt-px w-8 shrink-0"
        id="Logo"
      />
      <div className="text-xl font-['Inter'] font-medium leading-[30.8px] text-[#161717]">
        Project name
      </div>
    </div>
    <div className="flex flex-row justify-between w-3/5 items-start">
      <div className="flex flex-row justify-between w-1/2 items-start">
        <div className="bg-[#0867fc] flex flex-col w-12 shrink-0 h-12 items-center py-2 rounded-lg">
          <img
            src="https://file.rendit.io/n/bysOCJRnVdAPB8uvmYyY.svg"
            className="w-8"
            id="Icons4"
          />
        </div>
        <div className="flex flex-row gap-2 items-start">
          <div className="bg-[#e9e9ea] flex flex-col w-12 shrink-0 items-center p-2 rounded-lg">
            <img
              src="https://file.rendit.io/n/aA7Vcq7WdNoSHKkcJ0tD.svg"
              className="w-8"
              id="Icons"
            />
          </div>
          <div className="bg-[#e9e9ea] flex flex-col w-12 shrink-0 items-center p-2 rounded-lg">
            <img
              src="https://file.rendit.io/n/uK4rmT3SVU2zHptvjWqN.svg"
              className="w-8"
              id="Icons1"
            />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-2 items-start">
          <div className="bg-[#0867fc20] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-lg">
            <img
              src="https://file.rendit.io/n/IKqpBGUHf8r5EvGhEvPd.svg"
              className="w-8"
              id="Phdesktop"
            />
          </div>
          <div className="bg-[#e9e9ea] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-lg">
            <img
              src="https://file.rendit.io/n/4gZ9cIknSrQS0v6HJl2a.svg"
              className="w-8"
              id="Fluenttabletregular"
            />
          </div>
          <div className="bg-[#e9e9ea] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-lg">
            <img
              src="https://file.rendit.io/n/UnpcAU3FK9yNdILHh8qf.svg"
              className="w-6"
            />
          </div>
        </div>
        <div className="bg-[#e9e9ea] flex flex-row gap-6 h-12 items-center pl-1 pr-2 py-3 rounded-lg">
          <img
            src="https://file.rendit.io/n/zcuZSJc4ubsFkOef3Iyx.svg"
            className="w-5 shrink-0"
            id="Icons2"
            style={{opacity:zoomSize >= 1900 ? ".4" : "1", cursor:"pointer"}}
            onClick={() => zoomSize < 1900 ? setZoomSize(zoomSize + 50) : setZoomSize(zoomSize)}
          />
          <div className="font-['Inter'] leading-[22.4px] text-[#989191] mr-px">
            {zoomSize}
          </div>
          <img
            src="https://file.rendit.io/n/3cKKxdPq96ysSmHAK2MV.svg"
            className="w-4 shrink-0"
            style={{opacity:zoomSize <= 100 ? ".4" : "1", cursor:"pointer"}}
            id="Icons3"
            onClick={() => zoomSize > 100 ?  setZoomSize(zoomSize - 50) : setZoomSize(zoomSize)}
          />
        </div>
      </div>
      <div className="flex flex-row gap-5 items-start">
        <button
          className="border-solid border-[#0867fc] flex flex-col justify-center w-[180px] shrink-0 h-12 items-center border rounded-[30px]"
          id="Button2"
        >
          <div
            className="text-center text-lg font-['Inter'] font-bold leading-[24px] text-[#0671e0]"
            id="Label1"
          >
            Preview
          </div>
        </button>
        <button
          className="bg-[#0867fc] flex flex-col justify-center w-[180px] shrink-0 h-12 items-center rounded-[30px]"
          id="Button1"
        >
          <div
            className="text-center font-['Inter'] font-bold leading-[24px] text-white"
            id="Label"
          >
            Download
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

</div>

        
         {/* {enabled ? <div  style={{cursor:"pointer", width:"100px", fontSize:"12px", display:"flex", justifyContent:"center", alignItems:"center", color:"white", backgroundColor:"#116dff",margin:"0 5px", padding:"0 18px", height:"25px", borderRadius:"4px"}}
            onClick={() => {
              actions.setOptions((options) => (options.enabled = false))
              
            }}
          >
            {"Preview"}
            </div> : null} */}
            {/* <div  style={{cursor:"pointer", width:"100px", fontSize:"12px", display:"flex", justifyContent:"center", alignItems:"center", color:"white", backgroundColor:"#116dff",margin:"0 5px", padding:"0 18px", height:"25px", borderRadius:"4px"}}
            onClick={() => {
              actions.setOptions((options) => (options.enabled = !enabled))
              if(enabled) {
              
                  processPages(treeData)

                  // renderMarkup(codeContent, activeId, treeData)
                
              }
            }}
          >
            {enabled ? 'Download' : 'Edit App'}
            </div> */}
          </div> : null }
          </div>
          {/* {true ? enabled ? <Header
            canvasView={canvasView}
            setCanvasView={setCanvasView}
          /> : null : null} */}
          <div
            className={cx([
              `craftjs-renderer flex-1 h-full w-full transition pb-8 overflow-auto ${previewMode && "previewModeCx"}`,
              {
                'bg-renderer-gray': enabled,
              },
            ])}
            ref={(ref) => connectors.select(connectors.hover(ref, null), null)}
            style={{ 
              borderRadius:canvasView == "pc" ? "0px" : "15px",display:"flex", justifyContent:"center", width:canvasView == "pc" ? "100%" : canvasView == "tablet" ? "800px" : "360px"}}
          >
            {previewMode && <div style={{position:"fixed", top:"10px", right:"10px", zIndex:99999, cursor:"pointer"}}>
            <FontAwesomeIcon
          className="modal_x"
          icon={faTimes}
          style={{color:"red"}}
          onClick={() => {
            setPreviewMode(false)
            actions.setOptions((options) => (options.enabled = true));
            
          }}
          /> 
            </div>}
            <div className="relative flex-col flex items-center appRuler" style={{paddingRight:"1rem", paddingLeft:"1rem", width:"100%", paddingTop:"20px"}}>
            <div className='childrenParent'>
              {children}
            </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
};
