import * as React from 'react';
import './index.scss';
import { SpeedDialComponent } from '@syncfusion/ej2-react-buttons';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
    items = [
        {
            title: 'Home',
            iconCss: 'speeddial-icons speeddial-icon-house'
        },
        {
            title: 'People',
            iconCss: 'speeddial-icons speeddial-icon-people'
        },
        {
            title: 'Search',
            iconCss: 'speeddial-icons speeddial-icon-search'
        },
        {
            title: 'Message',
            iconCss: 'speeddial-icons speeddial-icon-message'
        }
    ];
    render() {
        return (<div className='control-pane'>
                <div className='control-section'>
                    <div id="speed-dial-wrapper">
                        <div id="speeddialtarget" className="speeddial-default-target  custom-index">
                            <SpeedDialComponent {...this.props.allEvents} title="Menu" openIconCss='speeddial-icons speeddial-icon-menu' closeIconCss='speeddial-icons speeddial-icon-close' target='#speeddialtarget' position='BottomCenter' items={this.items}></SpeedDialComponent>
                        </div>
                    </div>
                </div>
            </div>
      );
    } 
}

export default Component