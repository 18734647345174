const ACTIVE_ID = "ACTIVE_ID";

const ACTION_ACTIVE_ID = (payload) => {
  return {
    type: ACTIVE_ID,
    payload
  };
};

export default ACTION_ACTIVE_ID;
