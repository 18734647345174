import React, { useEffect, useState } from "react";
import {  Link, useLocation, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";

import Cookies from "js-cookie";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";
import ACTION_LOGIN_EMAIL_INVALID from "../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_EMAIL_RESET from "../../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET";


import ACTION_LOADING_SPINNER_ACTIVE from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import LoginEmail from "../LoginEmail/LoginEmail";
import LoginPassword from "../LoginPassword/LoginPassword";
import ACTION_LOADING_SPINNER_RESET from "../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";


import illustration2 from "../../../../assests/images/illustration2.png"
import illustration3 from "../../../../assests/images/illustration3.png"

import logo from "../../../../assests/images/Logo.png"
import google from "../../../../assests/icons/google.png"
import UserConfirmNewPassword from "../NewPassword/UserConfirmNewPassword";
import UserNewPassword from "../NewPassword/UserNewPassword";
import addUserMutation from "../../../../graphql/mutations/user/addUserMutation";
import TextInput from "../TextInput"
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import { DataSpinner } from "../../../resuable/spinner/dataSpinner";

const Signup = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  const loginEmail = useSelector((state) => state.loginEmail.login_email);
  const loginEmailInvalid = useSelector(
    (state) => state.loginEmailInvalid.login_email_invalid
  );
  const loginTempoInvalid = useSelector(
    state => state.loginTempoInvalid.login_tempo_invalid
  );
  const loginPassword = useSelector(
    (state) => state.loginPassword.login_password
  );
  const loginPasswordInvalid = useSelector(
    (state) => state.loginPasswordInvalid.login_password_invalid
  );
  const registeredUserFound = useSelector(
    (state) => state.registeredUserFound.registered_user_found
  );

  const confirmNewPassword = useSelector(
    (state) => state.confirmNewPassword.confirm_new_password
  );
  const newPasswordValid = useSelector(
    (state) => state.newPasswordValid.new_password_valid
  );
  const confirmNewPasswordValid = useSelector(
    (state) =>
      state.confirmNewPasswordValid.confirm_new_password_valid
  );
  const userAuthenticated = useSelector(
    (state) => state.userAuthenticated.user_authenticated
  );

  const temporaryDummyToken = useSelector((state) => state.temporaryDummyToken.temporary_dummy_token);
  const handleUserSignup = () => {

    if (registeredUserFound) {
      if (loginEmailInvalid) {
        dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
      }

      if (loginPasswordInvalid) {
        dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
      } 
      if(loginTempoInvalid) {
        dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID())
      }
    }

  };
// user information

const [firstName, setFirstName] = useState("")
const [firstNameError, setFirstNameError] = useState("")

const [LastName, setLastName] = useState("")
const [lastnameError, setLastNameError] = useState("")


  const [
    addUser,
    { data: addUserData },
  ] = useMutation(addUserMutation)

  const [userSignedUp, setUserSignedUp] = useState(false)

  useEffect(() => {
    if(userSignedUp) {

      history.push(`/admin/${loginEmail}/11111111`)
    }
  },[userSignedUp])
  const handleSignupClick = (e) => {
      if(loginEmail && !loginEmailInvalid
        && firstName && LastName && !loginTempoInvalid)
         {
            dispatch(ACTION_LOADING_SPINNER_ACTIVE())
            e && e.preventDefault()
                addUser({
                variables: {
                    name: firstName + " " + LastName,
                    email: loginEmail,
                    password: confirmNewPassword,
                    role: "admin"
                }
                }).then((res, error) => {
                    if(res) {
                        setUserSignedUp(true)
                        dispatch(ACTION_LOADING_SPINNER_RESET())
                    }
                }
                ).catch((error) => {
                    if (error) {
                        dispatch(ACTION_LOADING_SPINNER_RESET())
                        /////console.log(error.message)
                        if (error.message) {
                          if (error.message.includes("email")) {
                            dispatch(ACTION_LOGIN_EMAIL_INVALID());
                            dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
                          }
                        }
                      }
                }) 
        }
    
  };




  useEffect(() => {
    if (location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <div className="signinPageContainer">
      <div className=" signinPageContainer singinPageContent">
      <div className="signinContainer">
        <div className="logoContainer">
          <img src={logo} width={50} />
          { !userSignedUp && <div onClick={() => {
            dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID())
            history.push("/admin")
          }} style={{width:"125px", marginTop:"0px", marginBottom:"0px", background:"transparent", border:"1px solid #ABB0B9", color:"black", fontWeight:"600"}} className="auth-button hoverS">
            sign in
          </div>}
        </div>
        { !userSignedUp ? <div className="signinContent">
        <div className="signinBoxContainer">

        <div className="auth-header">
        {temporaryDummyToken ? "Change the password" : "Sign Up"}
        </div>
        <div>
          
<div className="auth-group">
          <TextInput 
            type="firstName"
            label="First Name"
            value={firstName}
            setValue={setFirstName}
            placeHolder="Type your first name"
            error={firstNameError}
            setError={setFirstNameError} 
          />
          </div>
          <div className="auth-group">
          <TextInput 
            type="lastName"
            label="Last Name"
            value={LastName}
            setValue={setLastName}
            placeHolder="Type your last name"
            error={lastnameError}
            setError={setLastNameError} 
          />
          </div>


          <div className="auth-group">

          <LoginEmail />
          </div>

          {/* <div className="auth-group">
          <UserNewPassword
          confirm={true}
          />
          </div>
          <div className="auth-group">
          <UserConfirmNewPassword
          confirm={true}
          />
          </div> */}

          <div>
            <div
             onClick={handleSignupClick} 
            style={{
              color: loginEmail && !loginEmailInvalid
              ? firstName && LastName
                ? "rgb(255, 255, 255)"
                : "white"
                 : "white",
              pointerEvents: loginEmail && !loginEmailInvalid
                ? firstName && LastName
                  ? "auto"
                  : "none"
                : "none",
              background: loginEmail && !loginEmailInvalid
               ? firstName && LastName
                  ? "#B20312"
                  : "#B2031280"
                   :"#B2031280",
              transition: "background 0.5s ease, color 0.5s ease",
              width:"100%",
              minWidth:"140px"
          }} 
          className="auth-button hoverButton">
    Sign up
            </div>
            <div className="orContainer">
            <div className="or"></div>
            <div style={{color:"#ABB0B9"}}> or </div>
            <div className="or"></div>
            </div>
            <div className="auth-button hoverS" style={{background:"transparent", color:"black", border:"1px solid #ABB0B9", fontSize:"14px"}}>
              <img src={google} style={{margin:"0 .5rem"}} /> Sign up with google
            </div>
          </div>
        </div>
        </div>
        </div> : (
          // <DataSpinner /> 
            <div className="signinContent">
                                <div className="signinBoxContainer">

        <div className="auth-header">
            Check your email
        </div>
        <div>
                        <div className="doneText">
                        We’ve sent confirmation email to
                        <span style={{fontWeight:"700"}}> {loginEmail ? loginEmail : "Your email"}</span>
                        </div>
                <div className="doneText">
                If it doesn’t arrive soon, check your spam folder or
    send the email again.
                </div>
                <div className="doneText">Need help? <span style={{fontWeight:"700"}}>Contact support.</span></div>
                <div className="auth-button hoverButton" onClick={() => {
                  history.push("/admin")
                  }}>Back to Home</div>
</div>
</div>
            </div>
        )}
        { !userSignedUp && <div className="privacy">
          <span style={{textDecorationLine:"underline"}}>Privacy policy</span> &amp; <span style={{textDecorationLine:"underline"}}>Terms and Conditions</span>
        </div>}
        </div>
        <div className="rightSignInContainer">
        <div className="loginRightContainer">
          {!userSignedUp ? <img src={illustration2} width={560} /> : <img src={illustration3} style={{top:'30px'}} width={560} /> }
      <div className="loginHeader">
      Bring your Organization <br/>
into the <span style={{fontWeight:"bold"}}>Digital Era.</span>
          </div>
          </div>
      </div>
        </div>
  </div>
  );
};

export default Signup;
