import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Section4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-gray-900 text-white">
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-lg text-center">
      <h2 class="text-3xl font-bold sm:text-4xl"> {<Element canvas style={{display:"inline-block"}} id="682436" is="div"><Text text="Kickstart your marketing" /></Element>} </h2>

      <p class="mt-4 text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="345037" is="div"><Text text="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequuntur
        aliquam doloribus nesciunt eos fugiat. Vitae aperiam fugit consequuntur
        saepe laborum." /></Element>} </p>
    </div>

    <div class="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
      <Element canvas style={{ width: "100%" }} id={"div371745"} is="div">
            <Button href={"/services/digital-campaigns"} classn={"block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10 cursor-pointer"}>
              
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
        </svg>

        <h3 class="mt-4 text-xl font-bold text-white"> {<Element canvas style={{display:"inline-block"}} id="904150" is="div"><Text text="Digital campaigns" /></Element>} </h3>

        <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="214289" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium." /></Element>} </p>
      
            </Button>
          </Element>

      <Element canvas style={{ width: "100%" }} id={"div756161"} is="div">
            <Button href={"/services/digital-campaigns"} classn={"block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10 cursor-pointer"}>
              
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
        </svg>

        <h3 class="mt-4 text-xl font-bold text-white"> {<Element canvas style={{display:"inline-block"}} id="746639" is="div"><Text text="Digital campaigns" /></Element>} </h3>

        <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="949426" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium." /></Element>} </p>
      
            </Button>
          </Element>

      <Element canvas style={{ width: "100%" }} id={"div812816"} is="div">
            <Button href={"/services/digital-campaigns"} classn={"block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10 cursor-pointer"}>
              
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
        </svg>

        <h3 class="mt-4 text-xl font-bold text-white"> {<Element canvas style={{display:"inline-block"}} id="577999" is="div"><Text text="Digital campaigns" /></Element>} </h3>

        <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="173981" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium." /></Element>} </p>
      
            </Button>
          </Element>

      <Element canvas style={{ width: "100%" }} id={"div986247"} is="div">
            <Button href={"/services/digital-campaigns"} classn={"block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10 cursor-pointer"}>
              
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
        </svg>

        <h3 class="mt-4 text-xl font-bold text-white"> {<Element canvas style={{display:"inline-block"}} id="498151" is="div"><Text text="Digital campaigns" /></Element>} </h3>

        <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="987346" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium." /></Element>} </p>
      
            </Button>
          </Element>

      <Element canvas style={{ width: "100%" }} id={"div394719"} is="div">
            <Button href={"/services/digital-campaigns"} classn={"block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10 cursor-pointer"}>
              
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
        </svg>

        <h3 class="mt-4 text-xl font-bold text-white"> {<Element canvas style={{display:"inline-block"}} id="124151" is="div"><Text text="Digital campaigns" /></Element>} </h3>

        <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="619555" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium." /></Element>} </p>
      
            </Button>
          </Element>

      <Element canvas style={{ width: "100%" }} id={"div112297"} is="div">
            <Button href={"/services/digital-campaigns"} classn={"block rounded-xl border border-gray-800 p-8 shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10 cursor-pointer"}>
              
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
        </svg>

        <h3 class="mt-4 text-xl font-bold text-white"> {<Element canvas style={{display:"inline-block"}} id="225476" is="div"><Text text="Digital campaigns" /></Element>} </h3>

        <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="303643" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex ut quo
          possimus adipisci distinctio alias voluptatum blanditiis laudantium." /></Element>} </p>
      
            </Button>
          </Element>
    </div>

    <div class="mt-12 text-center">
      <Element canvas style={{ width: "100%" }} id={"div678056"} is="div">
            <Button href={"/get-started"} classn={"mt-8 inline-flex items-center rounded border border-pink-600 bg-pink-600 px-8 py-3 text-white hover:bg-transparent focus:outline-none focus:ring active:text-pink-500 cursor-pointer"}>
              
        <span class="text-sm font-medium"> {<Element canvas style={{display:"inline-block"}} id="241769" is="div"><Text text="Get Started" /></Element>} </span>

        <svg class="ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
        </svg>
      
            </Button>
          </Element>
    </div>
  </div>
    </section>

      </div>;
};
Section4.craft = {
  displayName: "Section 4"
};