import * as React from 'react';
import './index.scss';

import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { SampleBase } from './sample-base';


class Component extends SampleBase {

    render() {
        return (<div className='control-pane'>
                <div className='control-section'>
                    <div className='dropdownbutton-section'>
                        <div id='dropdownbutton-control'>
                            <div className='row'>
                                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6">
                                    <DropDownButtonComponent {...this.props.allEvents} items={this.props.items} iconCss='e-ddb-icons e-profile'></DropDownButtonComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      );
    } 
}

export default Component