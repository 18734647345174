import { gql } from "apollo-boost";

const addUserMutation = gql`
  mutation(
    $name: String,
    $email: String,
    $phoneNumber: String,
    $active: Boolean,  
    $role: String
    $image: String
    $org: ID,
    $group: String
    $firstTime: Boolean
    $password: String

  ) {
    addUser(
    name: $name
    email: $email
    phoneNumber: $phoneNumber
    active: $active
    role: $role
    image: $image
    org: $org
    group: $group
    firstTime: $firstTime
    password: $password
    ) {
      name
      email
      phoneNumber
      active
      role
      image
      org
      group
    }
  }
`;

export default addUserMutation;
