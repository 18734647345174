import { gql } from "apollo-boost";

const getUserQuery = gql`
  query(
    $_id: ID
    $name: String
    $email: String
    $phoneNumber: String
  ) {
    user(
    _id: $_id
    name: $name
    email: $email
    phoneNumber: $phoneNumber
     
    ) {
      _id
      name
      email
      phoneNumber
      active
      firstTime
      role
      image
      org
      orgs
      group
      department
      departmentAdmin
      groupAdmin
    }
  }
`;

export default getUserQuery;
