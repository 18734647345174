import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Pricing5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-8 mx-auto">
        <div class="flex flex-col items-center justify-center space-y-8 lg:-mx-4 lg:flex-row lg:items-stretch lg:space-y-0">
            <div class="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-gray-200 rounded-lg lg:mx-4 dark:bg-gray-900 dark:border-gray-700">
                <div class="flex-shrink-0">
                    <h2 class="inline-flex items-center justify-center px-2 font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700"> {<Element canvas style={{display:"inline-block"}} id="259579" is="div"><Text text="Casual" /></Element>} </h2>
                </div>

                <div class="flex-shrink-0">
                    <span class="pt-2 text-4xl font-bold text-gray-800 uppercase dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="961846" is="div"><Text text="Free" /></Element>} </span>
                </div>

                <ul class="flex-1 space-y-4">
                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="404755" is="div"><Text text="Up to 5 projects" /></Element>} </li>

                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="284072" is="div"><Text text="Up to 10 collaborators" /></Element>} </li>

                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="526231" is="div"><Text text="2Gb of storage" /></Element>} </li>
                </ul>

                <Element canvas style={{ width: "100%" }} id={"div943123"} is="div">
            <Button classn={"inline-flex items-center justify-center px-4 py-2 font-medium text-white uppercase transition-colors bg-blue-500 rounded-lg hover:bg-blue-700 focus:outline-none"}>
               {<Element canvas style={{display:"inline-block"}} id="482152" is="div"><Text text="Start free" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-gray-200 rounded-lg lg:mx-4 dark:bg-gray-900 dark:border-gray-700">
                <div class="flex-shrink-0">
                    <h2 class="inline-flex items-center justify-center px-2 font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700"> {<Element canvas style={{display:"inline-block"}} id="442701" is="div"><Text text="Professional" /></Element>} </h2>
                </div>

                <div class="flex-shrink-0">
                    <span class="pt-2 text-4xl font-bold text-gray-800 uppercase dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="139541" is="div"><Text text="$24.90" /></Element>} </span>
                    
                    <span class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="460624" is="div"><Text text="/month" /></Element>} </span>
                </div>

                <ul class="flex-1 space-y-4">
                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="288321" is="div"><Text text="Up to 10 projects" /></Element>} </li>

                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="248334" is="div"><Text text="Up to 20 collaborators" /></Element>} </li>

                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="857117" is="div"><Text text="10Gb of storage" /></Element>} </li>

                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="755057" is="div"><Text text="Real-time collaborations" /></Element>} </li>
                </ul>

                <Element canvas style={{ width: "100%" }} id={"div948032"} is="div">
            <Button classn={"inline-flex items-center justify-center px-4 py-2 font-medium text-white uppercase transition-colors bg-blue-500 rounded-lg hover:bg-blue-700 focus:outline-none"}>
               {<Element canvas style={{display:"inline-block"}} id="359650" is="div"><Text text="Start free trial" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-gray-200 rounded-lg lg:mx-4 dark:bg-gray-900 dark:border-gray-700">
                <div class="flex-shrink-0">
                    <h2 class="inline-flex items-center justify-center px-2 font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700"> {<Element canvas style={{display:"inline-block"}} id="141994" is="div"><Text text="Expert" /></Element>} </h2>
                </div>

                <div class="flex-shrink-0">
                    <span class="pt-2 text-4xl font-bold text-gray-800 uppercase dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="907983" is="div"><Text text="$49.90" /></Element>} </span>

                    <span class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="336780" is="div"><Text text="/month" /></Element>} </span>
                </div>

                <ul class="flex-1 space-y-4">
                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="400002" is="div"><Text text="Unlimited projects" /></Element>} </li>
                    
                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="805508" is="div"><Text text="Unlimited collaborators" /></Element>} </li>
                    
                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="245140" is="div"><Text text="Unlimited storage" /></Element>} </li>
                    
                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="137898" is="div"><Text text="Real-time collaborations" /></Element>} </li>
                    
                    <li class="text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="680717" is="div"><Text text="24x7 Support" /></Element>} </li>
                </ul>

                <Element canvas style={{ width: "100%" }} id={"div134927"} is="div">
            <Button classn={"inline-flex items-center justify-center px-4 py-2 font-medium text-white uppercase transition-colors bg-blue-500 rounded-lg hover:bg-blue-700 focus:outline-none"}>
               {<Element canvas style={{display:"inline-block"}} id="792520" is="div"><Text text="Start free trial" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </div>

      </div>;
};
Pricing5.craft = {
  displayName: "Pricing 5"
};