import { gql } from "apollo-boost";

const updateTemplateMutation = gql`
  mutation(
    $_id: ID, 
    $name: String,   
    $user: ID,
    $html: String,
    $css: String,
  ) {
    updateTemplate(
    _id: $_id, 
    name:$name,    
    user: $user
    html:$html,
    css:$css,
  
    ) {
    _id 
    name
    user,
    html
    css
  
    }
  }
  `;

export default updateTemplateMutation;
