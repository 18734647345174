import React,{useState} from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {  
  const [items, setItems] = useState([])

  return (
    <div style={{height:"100%", overflow:"auto"}}>
       <ToolbarSection
        title="new item"
        onClick={() => setItems([...items, {}])}
      >
      </ToolbarSection>
      {items.map((x,i) => (<><ToolbarSection
        title={`item ${i+1}`}
        props={['newItem', 'content']}
      >
        <ToolbarItem
          full={true}
          propKey="items"
          type="text"
          label="Item title"
          addTo="items"
          theKey="content"
          index={i}
        />
         {/* <ToolbarItem propKey="buttonStyle" theKey="type" type="radio" label="Style">
         <ToolbarRadio value="Vertical" label="Vertical" />
         <ToolbarRadio value="Horizontal" label="Horizontal" />
       </ToolbarItem> */}
      </ToolbarSection>
     </>
      ))}
    </div>
  );
};
