import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { useLocation } from "react-router-dom";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import "./index.scss"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import ACTION_LOGIN_EMAIL_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CalendarPicker from '@mui/lab/CalendarPicker';
import arrowDownUsers from "../../../assests/images/arrowDownUsers.svg"
import searchInput from "../../../assests/images/searchInput.svg"
import setting from "../../../assests/images/setting.svg"
import arrowRight from "../../../assests/images/arrowRight.svg"
import edit from "../../../assests/images/edit.svg"
import trash from "../../../assests/images/trash.svg"
import add from "../../../assests/images/add.svg"
import x from "../../../assests/images/x.svg"
import {useMutation} from "@apollo/client"
import addUserMutation from "../../../graphql/mutations/user/addUserMutation"
import {AccountTable as UsersTable} from "../../resuable/accountTable"
import AddUserModal from "../../resuable/modals/addUserModal";
import { DataSpinner } from "../../resuable/spinner/dataSpinner";
import ACTION_LOGIN_EMAIL_RESET from "../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET";
import ACTION_LOGIN_TEMPO_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID";
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import moment from "moment"
import ACTION_LOGIN_TEMPO_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_INVALID";
import Grid from '@mui/material/Grid';

const AdminUsers = ({user, users, usersData, setUsersData, usersRefetch}) => {
    const [usersDataFilter, setUsersDataFilter] = useState([])
    let location = useLocation()
    const [date, setDate] = React.useState(new Date());

    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const dispatch = useDispatch()

    const loginEmail = useSelector((state) => state.loginEmail.login_email);
    const activeOrg = useSelector((state) => state.activeOrg.org);

    const loginEmailInvalid = useSelector(
      (state) => state.loginEmailInvalid.login_email_invalid
    );
    


    const minDate = new Date('2020-01-01T00:00:00.000');
    const maxDate = new Date('2034-01-01T00:00:00.000');
    
    const usersColumn = [
        
        {
            name: 'user name',
            filter:["none"],
            selector: row => row.userName,
            filterable: true,
            sortable:true
        },
        {
            name: 'Email',
            filter:["none"],
            filterable: true,
            sortable:true,
            cell: row => (
                <div style={{textTransform:"lowercase"}}>
                    {row.email}
                </div>
            ),
        },
        {
            name: 'date',
            filter:["none"],
            filterable: true,
            sortable:true,
            selector: row => row.date,
        },
        {
            name: 'Active Workspace',
            filter:["none"],
            filterable: true,
            sortable:true,
            ignoreRowClick: true,
            allowOverflow: false,
            cell: row => (
                <div style={{ display:"flex", alignItems:"center"}}>
                    <div style={{color:"#3C3C3C", fontSize:"15px", marginRight:".8rem"}}>{row.workspaces}</div>
                    <div className="moreButton">More <img style={{marginLeft:".5rem"}} src={arrowRight}/></div>
                </div>
            ),
        },
        {
            name: 'Active Forms',
            filter:["none"],
            filterable: true,
            sortable:true,
            ignoreRowClick: true,
            allowOverflow: false,
            cell: row => (
                <div style={{ display:"flex", alignItems:"center"}}>
                    <div style={{color:"#3C3C3C", fontSize:"15px", marginRight:".8rem"}}>{row.forms}</div>
                    <div className="moreButton">More <img style={{marginLeft:".5rem"}} src={arrowRight}/></div>
                </div>
            ),
        },
        {
            name: 'Role',
            filter:["none"],
            filterable: true,
            sortable:true,
            cell: row => (
                <div className="print-status">
                <span>{row.state}</span>
            </div>
            ),
            
        },
        {
            name: 'State',
            filter:["none"],
            filterable: true,
            sortable:true,
            cell: row => (
                <div className={`print-status ${row.active ? "greenActive" : "redDeactive"}`}>
                <span>{row.active ? "Active" : "Deactive"}</span>
            </div>
            ),
            
        },{
            name: 'Actions',
            filter:["none"],
            filterable: true,
            sortable:true,
            ignoreRowClick: true,
            allowOverflow: false,
            cell: row => (
                <div className="actions-buttons">
                    <img style={{marginRight:".4rem"}} className="actionHover" src={edit}/>
                    <img style={{marginLeft:".4rem"}} className="actionHover" src={trash}/>
                </div>
            ),
            
        },
        
    ];
    

    // add user modal


    useEffect(() => {
        if(users) {
            setUsersData(users.allUsers.map(x => {
                return {
                    userName : x.name,
                    email: x.email,
                    date: moment.unix(x.createdAt/1000).format("MM/DD/YYYY"),
                    state: x.role,
                    id: x._id,
                    workspaces: 0,
                    forms: 0,
                    active: x.active
                }
            }))
        }
    },[users])
    
// get started information
const [userAddModal, setUserAddModal] = useState(null)

// add user info

const [firstName, setFirstName] = useState("")
const [firstNameError, setFirstNameError] = useState("")

const [LastName, setLastName] = useState("")
const [lastnameError, setLastNameError] = useState("")



const [addUser, {error: addUserError, data: addUserData, loading: addUserLoading}] = useMutation(addUserMutation)



const addUserFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    if(loginEmail && !loginEmailInvalid) {
            /////console.log(activeOrg)
            /////console.log(user.user)
    addUser({
        variables:{
            org: user.user.role == "admin" ? activeOrg : null, 
            name: firstName + " " + LastName,
            email: loginEmail,
            role: user.user.role == "superAdmin" ? "admin" : "user"
        }
    }).then((res) => {
        usersRefetch()
        setUserAddModal(null)   
        setFirstName("")
        setLastName("")
        dispatch(ACTION_LOGIN_EMAIL_RESET())
        dispatch(ACTION_LOADING_SPINNER_RESET())
        dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID())
        dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID())
    }).catch((error) => {
        if (error) {
            /////console.log(error.message)
            if (error.message) {
              if (error.message.includes("email")) {
                dispatch(ACTION_LOGIN_TEMPO_INVALID());
                dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
                dispatch(ACTION_LOADING_SPINNER_RESET())
              } 

            }
          }
    })
}
}

useEffect(() => {
    if(users) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
},[users])

let [filterItems, setFilterItems] = useState([
    {name: "Visible columns", expanded:false},
    {name: "Date", expanded:false},
    {name: "Active workspace", expanded:false},
    {name: "Active forms", expanded:false},
    {name: "Status", expanded:false},

])

let [columns, setColumns] = useState([
    {name: "userName", active: true},
    {name: "role", active: true},
    {name: "state", active: true},
    {name: "date", active: true},
    {name: "email", active: true},
    {name: "workspaces", active: true},
    {name: "forms", active: true},
])

const [filterToggle, setFilterToggle] = useState(false)


const [activeWorkspace,setActiveWorkspace] = useState(false)

const [activForm,setActiveForm] = useState(false)

const [activeStatus,setActiveStatus] = useState(false)

const [searchActive, setSearchActive] = useState(false)

const [searchFilter, setSearchFilter] = useState("")


useEffect(() => {
    if(activeWorkspace) {
        
    }
},[activeWorkspace])
useEffect(() => {
    if(activForm) {
    }
},[activForm])
useEffect(() => {
    if(activeStatus) {
        setUsersDataFilter(
            [...usersData].filter((x) => {
                return (
                  new RegExp(activeStatus, "gi").test(x.role)
                );
              })
            )
    } else  {
        setUsersDataFilter([])
    }
},[activeStatus])
useEffect(() => {
    if(searchFilter) {

        setUsersDataFilter(
            [...usersData].filter((x) => {
                return (
                  new RegExp(searchFilter, "gi").test(x.userName) ||
                  new RegExp(searchFilter, "gi").test(x.email) ||
                  new RegExp(searchFilter, "gi").test(x.state) ||
                  new RegExp(searchFilter, "gi").test(x.active) ||
                  new RegExp(searchFilter, "gi").test(x.role)

                );
              })
            )
    } else  {
        setUsersDataFilter([])
    }
},[searchFilter])
// if(!usersData) {
//     return (
//         <DataSpinner />
//     )
// }




 return (
        <div className="adminContent column" 
        // onClick={() => {
        //     filterToggle && setFilterToggle(false)
        // }}
        >
            {returnToHomeScreen()}
            <AddUserModal 
                open={userAddModal}
                setOpen={setUserAddModal}
                addUser={addUserFunc}
                firstName={firstName}
                setFirstName={setFirstName}
                firstNameError={firstNameError}
                setFirstNameError={setFirstNameError}
                LastName={LastName}
                setLastName={setLastName}
                lastnameError={lastnameError}
                setLastNameError={setLastNameError}
                addUserLoading={addUserLoading}
            />
            <div className="workspaceHeader">
                <div className="workspaceName" style={{display:"flex", alignItems:"center"}}>
                        Users
                        <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => setUserAddModal("User")}
                        
                        />
                </div>
                <div className="workspaceButtons">
                    {searchActive ? (
                        <input value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} placeholder="Search" className={`routeContainer sideSearch userSearch`} style={{width:"269px" , margin:"0px", fontWeight:"normal", justifyContent:"flex-start"}} />
                    ) : (
                    <div className={`routeContainer sideSearch userSearch`} onClick={() =>setSearchActive(true)} style={{width:"269px" , margin:"0px", fontWeight:"normal", justifyContent:"flex-start"}}>
                    <img
                                className="routeIcon"
                                src={searchInput}
                    />
                    <div className="searchT" style={{margin:"0 16px", color:"#5E6A7A"}}>
                        Search                        
                    </div>
                    </div> 
                    )}
                    
                    <div className="adminButton" style={{width:"fit-content"}}> 
                    <span style={{margin:"0 16px", color:"#5E6A7A"}} className="buttonIconImage">Newest</span>
                    <img className="buttonIconImage" style={{margin:"0 12px"}} src={arrowDownUsers} />
                    </div>
                    <div style={{position:"relative"}}>
                    <div className="adminButton" onClick={() => setFilterToggle(!filterToggle)} >
                    <img className="buttonIconImage" src={setting} />
                    </div>
                    {filterToggle && (
                    <div className="orgsC" style={{padding:"10px 24px",position:"absolute", height:"fit-content", maxHeight:"88vh", overflow:"auto",width:"375px", top:"130%", right:0, borderRadius:"8px", backgroundColor:"white", zIndex:"2"}}>
                        <div className="orgsContainers" style={{height:"fit-content"}}>
                        {filterItems.map((x, i) => (
                            <>
                            <div key={i} className="oneOrg" onClick={() => {
                                        x.expanded = !x.expanded
                                        setFilterItems(filterItems.map(ar => ar.name == x.name ? x : ar))
                                    }} >
                            <div className="oneOrgDetails" style={{padding:"10px 8px", flexDirection:"row", justifyContent:"space-between", display:"flex", width:"100%"}}>
                                <div className="orgTitle">{x.name} </div>
                                <img style={{marginRight:".5rem"}} src={arrowRight}/>
                            </div>
                            </div> 
                            <div className={`${x.expanded ? "areaContainerOn" : "areaContainerOff"}`}>
                              
                            { x.name == "Visible columns" &&
                        <div className="checksContainer">
                            {columns.map((x,i) => (
                                <div key={i} className="rememberMeContainer"><label>
                                    <input type='checkbox'/>
                                    <span className={`${x.active && "spanChecked"}`} onClick={() => {
                                        x.active = !x.active
                                        setColumns(columns.map(ar => ar.name == x.name ? x : ar))
                                    }}></span>
                                    {x.name}
                                </label></div>
                            ))}
                            </div>
                                   }
                              
                               { x.name == "Date" &&
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={12} md={6}>
                                    <CalendarPicker minDate={minDate}
           maxDate={maxDate} date={date} onChange={(newDate) => setDate(newDate)} />
                                  </Grid>
                                  </LocalizationProvider>
                                   }
                                   {(x.name == "Active workspace") && (
                                      <div className="checksContainer">
                                       <div className="rememberMeContainer"><label>
            <input type='checkbox'/>
            <span className={`${activeWorkspace == "0 - 5" && "spanChecked"}`} onClick={() => setActiveWorkspace("0 - 5")}></span>
            0 - 5
          </label></div>
          <div className="rememberMeContainer"><label>
            <input type='checkbox'/>
            <span className={`${activeWorkspace == "6 - 20" && "spanChecked"}`} onClick={() => setActiveWorkspace("6 - 20")}></span>
            6 - 20
          </label></div>
          <div className="rememberMeContainer"><label>
            <input type='checkbox'/>
            <span className={`${activeWorkspace == "More than 20" && "spanChecked"}`} onClick={() => setActiveWorkspace("More than 20")}></span>
            More than 20
          </label></div>
                                       </div>
                                   )}
                                   {(x.name == "Active forms") && (
                                      <div className="checksContainer">
                                       <div className="rememberMeContainer"><label>
            <input type='checkbox'/>
            <span className={`${activForm == "0 - 5" && "spanChecked"}`} onClick={() => setActiveForm("0 - 5")}></span>
            0 - 5
          </label></div>
          <div className="rememberMeContainer"><label>
            <input type='checkbox'/>
            <span className={`${activForm == "6 - 20" && "spanChecked"}`} onClick={() => setActiveForm("6 - 20")}></span>
            6 - 20
          </label></div>
          <div className="rememberMeContainer"><label>
            <input type='checkbox'/>
            <span className={`${activForm == "More than 20" && "spanChecked"}`} onClick={() => setActiveForm("More than 20")}></span>
            More than 20
          </label></div>
                                       </div>
                                   )}
                                   {(x.name == "Status") && (
                                      <div className="checksContainer">
                                       <div className="rememberMeContainer"><label>
                                            <input type='checkbox'/>
                                            <span className={`${activeStatus == "admin" && "spanChecked"}`} onClick={() => setActiveStatus("admin")}></span>
                                            Admin
                                        </label></div>
                                        <div className="rememberMeContainer"><label>
                                            <input type='checkbox'/>
                                            <span className={`${activeStatus == "user" && "spanChecked"}`} onClick={() => setActiveStatus("user")}></span>
                                        User
                                        </label></div>
                                        </div>
                                   )}
                            </div> 
                            </>  
                        ))} 
                        <div className="applyButton" style={{height:"50px", padding:"0 0"}}>
                        <div className="oneOrgDetailsButton" style={{display:"flex", justifyContent:"center", alignItems:"center", width:"70%"}}>
                            <div className="orgTitle" style={{color:"white"}}>Apply</div>
                        </div>
                        <div className="adminButton" style={{padding:"10px 8px", flexDirection:"row", justifyContent:"space-between", display:"flex", width:"20%"}}>
                        <img className="buttonIconImage" src={x} />
                        </div>
                        </div>  
                        </div> 
                    </div>
                )}
                    </div>

                </div>
            </div>
            <div className="workspaceContainer">
            <UsersTable
                accountColumns={usersColumn}
                columns={columns}
                accountData={ usersDataFilter && usersDataFilter.length ? usersDataFilter : usersData}
                />
            </div>
        </div>
    )
}

export default AdminUsers