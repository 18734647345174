import * as React from 'react';
import './index.scss';
import { SampleBase } from './sample-base';

 
class Component extends SampleBase {

    render() {
        return (<div className="control-pane">
            <div className="control-section">
                <div className="fab-grid-container custom-index">
                </div>
            </div>
        </div>
      ); 
    } 
}

export default Component