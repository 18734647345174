import React from "react" 
import { css } from "@emotion/css";
import ClipLoader from "react-spinners/ClipLoader";



export const DataSpinner = () => {
    
        const override = css`
    display: block;
    position: absolute;
    left: 25%;
    right: 25%;
  `;

        return (
          <div
            className="clip_loader_spinner_container"
            style={{ display: "flex" }}
          >
            <ClipLoader
              size={100}
              css={override}
              color={"rgb(44, 44, 52)"}
              loading={true}
            />
          </div>
        );
}