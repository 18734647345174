import { useLazyQuery } from '@apollo/client';
import { useEditor, Element } from '@craftjs/core';
import cx from 'classnames';
import lz from 'lzutf8';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import allViewsQuery from '../../../../../graphql/queries/view/allViewsQuery';
import viewQuery from '../../../../../graphql/queries/view/viewQuery';


const Item = styled.a`
svg {
  width: 22px;
  height: 22px;
  fill: #707070;
}
${(props) =>
  props.move &&
  `
  cursor: move;
`} 
`;

export const InstanceView = ({ 
  children,
  html,
  dataBasesData,
  eventsData,
  open,

 }) => {
  const {
    enabled,
    connectors, 
    actions,
    nodes
  } = useEditor((state, query) => ({
    enabled: state.options.enabled || false,
    json: query.serialize() ,
    nodes: state.nodes
  }));

 const dispatch = useDispatch()
 const [dataBaseDataArr, setDataBaseDataArr] = useState(null)
 const [eventsDataArr, setEventsDataArr] = useState(null)

 const [views, setViews] = useState(null)

 const [activeView, setActiveView] = useState(null)
 
  const [getView, { error: viewError, data: viewData, loading: viewLoading, refetch: viewRefetch }] = useLazyQuery(viewQuery, {
    fetchPolicy: "no-cache",
  });

  const [allViews, { error: viewsError, data: viewsData, loading: viewsLoading, refetch: viewsRefetch }] = useLazyQuery(allViewsQuery, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if(open) {
        console.log(open, 111)
      if(open.type == "view") {
        setViews(null)
      getView({
        variables:{
          _id: open.id 
        }
      })} else if(open.type == "widget") {
        setViews(null)
        if(open.id) {
             let widget = JSON.parse(open.id)

             console.log(widget, "widget is")
        let root = {"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true,"props":{"flexDirection":"column","alignItems":"flex-start","justifyContent":"flex-start","fillSpace":"no","padding":["40","40","40","40"],"margin":["0","0","0","0"],"background":{"r":255,"g":255,"b":255,"a":1},"color":{"r":0,"g":0,"b":0,"a":1},"shadow":0,"radius":0,"width":"100%","height":"518px","allData":[]},"displayName":"Container","custom":{"displayName":"App"},"hidden":false,"nodes":[...Object.keys(widget)],"linkedNodes":{}}, ...widget}
        console.log(root, 123)
        actions.deserialize(root)
        }
       
      } else if (open.type == "workspace") {
        setViews(null)
        allViews({
            variables:{
              workspace: open.id
            }
          }) 
        
    } else {
      const json = lz.decompress(lz.decodeBase64(open.id));
      console.log(json, 109)
      actions.deserialize(json)
    }
    }
  },[open])

  useEffect(() => {
    if(viewsData) {
        setViews(viewsData.allViews ? viewsData.allViews : [])
    }
},[viewsData])

useEffect(() => {
  if(views) {
    setActiveView(views[0])
  }
},[views])


useEffect(() => {
let html = activeView?.html
    if(html) {
      const json = lz.decompress(lz.decodeBase64(html));
      actions.deserialize(json)
    } else {
      actions.deserialize({"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true, "props":{ "flexDirection":"column","alignItems":"flex-start","justifyContent":"flex-start","fillSpace":"no","padding":["40","40","40","40"],"margin":["0","0","0","0"],"background":{"r":255,"g":255,"b":255,"a":1},"color":{"r":0,"g":0,"b":0,"a":1},"shadow":0,"radius":0,"width":"100%%","height":"518px"},"displayName":"Container","custom":{"displayName":"App"},"hidden":false,"nodes":[],"linkedNodes":{}}})
    }

},[activeView])

 useEffect(() => {
    if(viewData) {
      console.log(viewData)
      let html = viewData?.view?.html
      if(html) {
        const json = lz.decompress(lz.decodeBase64(html));
        console.log(json, 123)
        actions.deserialize(json)
      } else {
        actions.deserialize({"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true, "props":{"flexDirection":"column","alignItems":"flex-start","justifyContent":"flex-start","fillSpace":"no","padding":["40","40","40","40"],"margin":["0","0","0","0"],"background":{"r":255,"g":255,"b":255,"a":1},"color":{"r":0,"g":0,"b":0,"a":1},"shadow":0,"radius":0,"width":"100%","height":"518px"},"displayName":"Container","custom":{"displayName":"App"},"hidden":false,"nodes":[],"linkedNodes":{}}})
      }
    }
  },[viewData])

 useEffect(() => {
    if(dataBasesData) {
      console.log(dataBasesData)
      let dataBaseResults = dataBasesData.allDataBases.map(x => {
        return {
          widget: x.widget,
          db: {
            url: x.url,
            db: x.db,
            table: x.table
          },
          results: JSON.parse(x.results)
        }
      })
      setDataBaseDataArr(dataBaseResults)
    }
   },[dataBasesData])
  
   useEffect(() => {
    if(eventsData) {
      console.log(eventsData)
      let eventsResults = eventsData.allEvents?.map(x => {
        return {
          widget: x.widgetId,
          node: x.nodeId,
          eventName: x.eventName,
          event: {
            eventName: x.eventName,
            eventType: x.eventType,
            eventContent: x.eventContent,
            key: x.key
          },
        }
      })
      setEventsDataArr(eventsResults)
    }
   },[eventsData])


 const applyFilter = (allData, collection, key, value) => {
  console.log(allData, collection, key, value)
  let collectionData = allData?.find(x => x.db.table == collection)
  value ? Object.keys(nodes)?.some(y => y == collectionData.widget) && actions.setProp(collectionData.widget, props => {
    props.dataBaseDataArr = {db: collectionData.db, results: collectionData.results.filter(y => {
      return y[key].includes(value)
    })}
    props.applyFilter = applyFilter

  }) : Object.keys(nodes)?.some(y => y == collectionData.widget) && actions.setProp(collectionData.widget, props => {
    props.dataBaseDataArr = {db: collectionData.db, results: collectionData.results}
    props.applyFilter = applyFilter

  })
 }

 useEffect(() => {
  if(dataBaseDataArr) {
    dataBaseDataArr.map(x => {
      console.log(x)
      Object.keys(nodes)?.some(y => y == x.widget) && actions.setProp(x.widget, props => {
        props.dataBaseDataArr = {db: x.db, results: x.results}

      })
    })
  }
 },[dataBaseDataArr])

 useEffect(() => {
  if(dataBaseDataArr) {
    Object.keys(nodes).map(x => {
      actions.setProp(x, props => {
        props.allData = dataBaseDataArr
        props.applyFilter = applyFilter

      })
    })
  }
 },[dataBaseDataArr])
 
 useEffect(() => {
  if(eventsDataArr && eventsDataArr?.length) {
    eventsDataArr?.map((x, i ,a) => {
      Object.keys(nodes)?.some(y => y == x.widget) && actions.setProp(x.widget, props => {
        props.eventsDataArr = a.filter(y => y.widget == x.widget)
      })
    })
  }
 },[eventsDataArr])


  const [canvasView, setCanvasView] = useState("pc")


  

  

  return (
    <div className="viewport" style={{width:"100%", height:"100%"}}>
     {views?.length > 0? <div className="viewsHeader" style={{alignItems:"center", justifyContent:"space-between"}}>
          <div style={{display:"flex", alignItems:"flex-end", height:"100%"}}>
                {views? views?.length > 0 ? views.map((x, i) => (
                    <div onClick={() => setActiveView(x)} className={`viewContainer`} style={{fontWeight: activeView?._id == x._id ? "600" : "500", width:"fit-content"}}>
                        <span className="viewWN">{x.name}</span>
                            <div className={`${activeView?._id == x._id ? "activeView" : "hoverView"}`} />
                    </div>
                )) : (
                    <div style={{fontSize:"1.5em", color:"gray"}}>This workspace have no views yet</div>
                ) : null}
                </div>
                </div> : null}
            <div
            style={{ height:"90%"}}
              className={cx(['flex h-full overflow-hidden flex-row w-full'])}
            >
        <div className="page-container flex flex-1 h-full flex-col"
        style={{alignItems:"center", position:"relative", width:"100%"}}
        > 
          <div
            className={cx([
            `craftjs-renderer flex-1 h-full w-full transition pb-8 overflow-auto`,
            {
              'bg-renderer-gray': enabled,
            },
            ])}
            ref={(ref) => connectors.select(connectors.hover(ref, null), null)}
            style={{ 
            borderRadius:canvasView == "pc" ? "0px" : "15px", display:"flex", justifyContent:"center", width:canvasView == "pc" ? "100%" : canvasView == "pc" ? "800px" : "360px"}}
          >
            <div className="relative flex-col flex items-center pt-8"
            style={{paddingRight:"1rem", paddingLeft:"1rem", width:"100%"}}
            >
              {children}
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
};
