import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Gallery3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="530189" is="div"><Text text="Master Cleanse Reliac Heirloom" /></Element>} </h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="282978" is="div"><Text text="Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom." /></Element>} </p>
    </div>
    <div class="flex flex-wrap -m-4">
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <Element canvas style={{ width: "100%" }} id={"div445416"} is="div">
          <ImageC alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="https://dummyimage.com/600x360"  />
        </Element>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1"> {<Element canvas style={{display:"inline-block"}} id="885692" is="div"><Text text="THE SUBTITLE" /></Element>} </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="215357" is="div"><Text text="Shooting Stars" /></Element>} </h1>
            <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="274318" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <Element canvas style={{ width: "100%" }} id={"div607468"} is="div">
          <ImageC alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="https://dummyimage.com/601x361"  />
        </Element>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1"> {<Element canvas style={{display:"inline-block"}} id="735467" is="div"><Text text="THE SUBTITLE" /></Element>} </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="980654" is="div"><Text text="The Catalyzer" /></Element>} </h1>
            <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="275398" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <Element canvas style={{ width: "100%" }} id={"div510791"} is="div">
          <ImageC alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="https://dummyimage.com/603x363"  />
        </Element>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1"> {<Element canvas style={{display:"inline-block"}} id="116000" is="div"><Text text="THE SUBTITLE" /></Element>} </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="977206" is="div"><Text text="The 400 Blows" /></Element>} </h1>
            <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="508274" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <Element canvas style={{ width: "100%" }} id={"div754493"} is="div">
          <ImageC alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="https://dummyimage.com/602x362"  />
        </Element>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1"> {<Element canvas style={{display:"inline-block"}} id="782169" is="div"><Text text="THE SUBTITLE" /></Element>} </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="208022" is="div"><Text text="Neptune" /></Element>} </h1>
            <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="615427" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <Element canvas style={{ width: "100%" }} id={"div848805"} is="div">
          <ImageC alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="https://dummyimage.com/605x365"  />
        </Element>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1"> {<Element canvas style={{display:"inline-block"}} id="798556" is="div"><Text text="THE SUBTITLE" /></Element>} </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="843527" is="div"><Text text="Holden Caulfield" /></Element>} </h1>
            <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="346367" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <Element canvas style={{ width: "100%" }} id={"div857917"} is="div">
          <ImageC alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="https://dummyimage.com/606x366"  />
        </Element>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1"> {<Element canvas style={{display:"inline-block"}} id="469338" is="div"><Text text="THE SUBTITLE" /></Element>} </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="576533" is="div"><Text text="Alper Kamu" /></Element>} </h1>
            <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="696571" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Gallery3.craft = {
  displayName: "Gallery 3"
};