import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Testimonials11 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="mx-auto max-w-5xl px-4 py-8">
  <section class="rounded-lg bg-gray-100 p-8">
    <div class="grid grid-cols-1 gap-12 sm:grid-cols-3 sm:items-center">
      <Element canvas style={{ width: "100%" }} id={"div873121"} is="div">
          <ImageC alt="Man" src="https://images.unsplash.com/photo-1625019030820-e4ed970a6c95?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" class="aspect-square w-full rounded-lg object-cover"  />
        </Element>

      <blockquote class="sm:col-span-2">
        <p class="text-xl font-medium sm:text-2xl"> {<Element canvas style={{display:"inline-block"}} id="586881" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
          perspiciatis cumque neque ut nobis excepturi, quasi iure quisquam
          autem alias." /></Element>} </p>

        <cite class="mt-8 inline-flex items-center not-italic">
          <span class="hidden h-px w-6 bg-gray-400 sm:inline-block"></span>
          <p class="text-sm uppercase text-gray-500 sm:ml-3">
            <strong> {<Element canvas style={{display:"inline-block"}} id="945089" is="div"><Text text="Justin Harrison" /></Element>} </strong> {<Element canvas style={{display:"inline-block"}} id="580649" is="div"><Text text=", Beatle Inc." /></Element>} </p>
        </cite>
      </blockquote>
    </div>
  </section>
    </div>

      </div>;
};
Testimonials11.craft = {
  displayName: "Testimonials 11"
};