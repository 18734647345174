import React, { useState } from "react"
import NewEditor from "./editor"

const Builder = () => {


    return (
     
    <NewEditor />
    )
}
export default Builder