import React from "react";
import { useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import "./tableContent.scss"
import { useDispatch } from "react-redux";
import { DataSpinner as RenderSitesLoader } from "../../spinner/dataSpinner";

 
const TableContent = (props) => {

  
    const {

        accountColumns,
        accountData,
    } = props
    const dispatch = useDispatch()
    const location = useLocation()

    console.log(accountColumns)
    return (
        <div className="contentContainer">
             <DataTable
                columns={accountColumns}
                data={accountData ? accountData : []}
                progressPending={accountData == null}
                progressComponent={RenderSitesLoader()}
                onRowClicked={(row) => props.subScreen && props.subScreen(row)}

        />
        </div>
    ) 
}

export default TableContent
