import './index.scss';
import * as React from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
    dateValue = new Date();

    render() {
        console.log(this.props.allEvents)

        return (<div className='control-pane'>
        <div className='control-section'>
            <div className='datepicker-control-section'>
                <DatePickerComponent 
                {...this.props.allEvents}
                value={this.dateValue}></DatePickerComponent>
            </div>
        </div>
    </div>);
    }
}

export default Component