import * as React from 'react';
import './index.scss';
import { SampleBase } from './sample-base';

import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { flatList, groupData } from "./data-source"

class Component extends SampleBase {
    //Map the appropriate columns to fields property
    fields = { groupBy: 'category' };
    render() {
        return (<div className='control-pane'>
        <div className='control-section'> 
            <div id="flat-list">
                <h4>{this.props.listTitle || "List title"}</h4>
                {/* ListView element */}
                <ListViewComponent {...this.props.allEvents} width="500px" id="sample-list-flat" dataSource={this.props.items || []}></ListViewComponent>
            </div>
        </div>


      </div>
      );
    } 
}

export default Component