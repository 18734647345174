import { WidgetSettings } from "./WidgetSettings"
import { useEditor, useNode } from "@craftjs/core";
import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'; 
import Component from "./Component";
import AddDbRecordModal from '../../../../modals/AddDbRecordModal';
import addDbRecordMutation from '../../../../../../graphql/mutations/addRecord/addDbRecordMutation';
import updateDbRecordMutation from '../../../../../../graphql/mutations/addRecord/updateDbRecordMutation';
import deleteDbRecordMutation from '../../../../../../graphql/mutations/addRecord/deleteDbRecordMutation';
import { useMutation } from '@apollo/client';
import ACTION_LOADING_SPINNER_RESET from '../../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET';
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE';
import { useDispatch } from 'react-redux';
import add from "../../../../../../assests/images/add.svg"
import edit from "../../../../../../assests/images/edit.svg"
import trash from "../../../../../../assests/images/trash.svg"
import Draggable from 'react-draggable';
import ACTION_TRIGGER_VIEW from "../../../../../../actions/TriggerView/ACTION_TRIGGER_VIEW";


export const DataGrid = (props) => {
    const {
                 actions: {setProp},
 connectors: { connect },
    } = useNode((node) => ({
      selected: node.events.selected, 
    })); 
   
    const {
      setActiveView,
dataBaseDataArr,
      eventsDataArr, 
      x,
      y,
      
      applyFilter
      } = props
      const [accountColumns, setAccountColumns] = useState([])
      const [resultsArr, setResultsArr] = useState(null)
      useEffect(() => {
        if(dataBaseDataArr) {
          console.log(dataBaseDataArr)
          let newArr = dataBaseDataArr?.results
          let db = dataBaseDataArr.db
          setActiveDb(db)
          setResultsArr(newArr)
          setAccountColumns(Object.keys(newArr[0]).map(x => {
            return {name: x}
          }))
        }
      },[dataBaseDataArr])
    const { text, textComponent, color, ...otherProps } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [activeSelect, setActiveSelect] = useState("Organizations")

  const {
    enabled,
    connectors, 
    actions,
    json,
    nodes
  } = useEditor((state, query) => ({
    enabled: state.options.enabled || false,
    json: query.serialize() ,
    nodes: state.nodes
  }));

  const changeView = (id) => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = {_id: id}
    })
  }
  const changeSelect = (ob) => {
    setActiveSelect(ob.name)
    changeView(ob._id)
  }
  
  const resetView = () => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = null
    })
    setActiveSelect("Organizations")
  }

  const [addDbRecord, {error: addDbRecordError, data: addDbRecordData, loading: addDbRecordLoading}] = useMutation(addDbRecordMutation)
  const [updateDbRecord, {error: updateDbRecordError, data: updateDbRecordData, loading: updateDbRecordLoading}] = useMutation(updateDbRecordMutation)
  const [deleteDbRecord, {error: deleteDbRecordError, data: deleteDbRecordData, loading: deleteDbRecordLoading}] = useMutation(deleteDbRecordMutation)



  const [selectedRow, setSelectedRow] = useState(null)    
    const [addRecordModal, setAddRecordModal] = useState(false)
    
    const [state, setState] = useState(null)
    const [activeDb, setActiveDb] = useState(null)

  
    
  const addRecordFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setSelectedRow(null)
    setAddRecordModal(false)
    addDbRecord({
      variables:{
        url: activeDb.url,
        db: activeDb.db,
        table: activeDb.table,
        record: JSON.stringify(state)
      }
    }).then(res => {
      if(res?.data?.addDbRecord?.url == "done") {
        setState(null)
        dispatch(ACTION_LOADING_SPINNER_RESET())
                allEvents.onCreateRecord ? allEvents.onCreateRecord() : null

      }
    })
    }

    const updateRecordFunc = () => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setSelectedRow(null)
      setAddRecordModal(false)
      updateDbRecord({
        variables:{
          url: activeDb.url,
          db: activeDb.db,
          table: activeDb.table,
          record: JSON.stringify(state)
        }
      }).then(res => {
        if(res?.data?.updateDbRecord?.url == "done") {
          setState(null)

          dispatch(ACTION_LOADING_SPINNER_RESET())
                  allEvents.onUpdateRecord ? allEvents.onUpdateRecord() : null

        }
      })
      .catch(err => {
        if(err) {
          dispatch(ACTION_LOADING_SPINNER_RESET())
        }
      })
      }


      

      const deleteRecordFunc = () => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        // setSelectedRow(null)
        // setAddRecordModal(false)
        deleteDbRecord({
          variables:{
            url: activeDb.url,
            db: activeDb.db,
            table: activeDb.table,
            record: JSON.stringify(state)
          }
        }).then(res => {
          if(res?.data?.deleteDbRecord?.url == "done") {
            setState(null)
            dispatch(ACTION_LOADING_SPINNER_RESET())
                        allEvents.onUpdateRecord ? allEvents.onUpdateRecord() : null

          }
        })
        .catch(err => {
          if(err) {
            console.log(err)
            dispatch(ACTION_LOADING_SPINNER_RESET())
          }
        })
        }


        const { query } = useEditor((state, query) => ({
          hoveredNodeId: state.events.hovered
        }));

        
  const addNode = (el) => {
    //     console.log(el)
    //     var tempDiv = document.createElement('div')
    //     tempDiv.innerHTML = el
    let convertString = (el) => {
      if(el.includes("grid_widget")) {
        return <GridWidget />
      } else if(el.includes("text_widget")) {
        return <Text />
      } else if(el.includes("card_widget")) {
        return <Card />
      } else {
         const reactElement = parse(el);
         return reactElement;
      }
    }
    
        const nodeTree = query.parseReactElement(convertString(el)).toNodeTree()
        actions.addNodeTree(nodeTree, "ROOT")
    
      }
      const dispatch = useDispatch()
const applyTrigger = (xs) => {
        console.log(xs)
        xs.map(x => {
          let type = x.event.eventType
        if(type == "Alert") {
          alert(x.event.eventContent)
        } else if(type == "Navigate") {
    dispatch(ACTION_TRIGGER_VIEW(x.event.key))
} else if (type == "Filter") {
          applyFilter && applyFilter(allData, x.node, x.event.key, inputRef?.current?.value)
          console.log("filters", inputRef?.current?.value, x)
        } else if(type == "Code") {
          console.log(x)
          let fun = new Function("inputValue", "applyFilter", "addNode", x.event.eventContent)
          fun(inputRef?.current?.value, applyFilter, addNode)
        } else if(type == "Inject") {
          addNode(x.event.eventContent)
        }
        })
      }

      const [allEvents, setAllEvents] = useState({})
      function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
          var key = obj[property.replaceAll(' ','')];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});
      }
      
      useEffect(() => {
        if(eventsDataArr && applyFilter) {
          console.log(eventsDataArr)
          let newEventsData = groupBy(eventsDataArr, "eventName")
    
          let allv = Object.assign({}, ...Object.keys(newEventsData).map((key) => {
            console.log(newEventsData[key])
            let arr = [...newEventsData[key]]
            newEventsData[key] = () => applyTrigger(arr);
            return {[key]: newEventsData[key]}
          }))
          // console.log(allv)
          // let ob =  ...(allv.map(item => ({ [Object.keys(item)[0]] : () => applyTrigger(item) }) )))
          
          console.log(allv,111111111111111)
          setAllEvents(allv)
    
        }
      },[eventsDataArr, applyFilter])
  
const [startingPos, setStartingPos] = useState({ x: x, y: y })
const handleStop = (e, data) => {
    console.log(data, 123)
    setStartingPos( { x: data.x, y: data.y })
    setProp(prop => {
      prop.x = data.x;
      prop.y = data.y;
    }, 500)}
    return (        
     <Draggable defaultClassName="dgs" onStop={handleStop} position={startingPos}>
 <div  ref={connect}>  
 {console.log(dataBaseDataArr, 12)}
    <AddDbRecordModal 
          open={addRecordModal}
          dataBaseDataArr={dataBaseDataArr}
          setOpen={setAddRecordModal}
          state={state}
          setState={setState}
          addRecordFunc={addRecordFunc}
          updateRecordFunc={updateRecordFunc}
          accountColumns={accountColumns}
        />
    {dataBaseDataArr && <div style={{ height:"50px", display:"flex", borderBottom:"0.5px solid rgba(171, 176, 185, 0.5)", alignItems:"center"}}>
          <div style={{ width:"50px", display:"flex", justifyContent:"center", borderRadius:"5px", alignItems:"center"}}>
          <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => {
                          setState(accountColumns.map(x => {
                            return {
                              name: x.name,
                              value: ""
                            }
                          }).reduce((obj, cur) => ({...obj, [cur.name]: cur}), {}))
                          setAddRecordModal("Record")
                        }}
                        
                        />
          </div>
          <div style={{ opacity: selectedRow ? 1: .5, width:"50px", display:"flex", justifyContent:"center", borderRadius:"5px", alignItems:"center"}}>
          <img
                        className="routeIcon"
                        src={edit}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => {
                          console.log(resultsArr, resultsArr?.find(y => y[Object.keys(y)[0]] == selectedRow[Object.keys(selectedRow)[0]]))
                          setState(accountColumns.map(x => {
                            return {
                              name: x.name,
                              value: resultsArr?.find(y => y[Object.keys(y)[0]] == selectedRow[Object.keys(selectedRow)[0]])[x.name]
                            }
                          }).reduce((obj, cur) => ({...obj, [cur.name]: cur}), {}))
                          setAddRecordModal("Edit Record")
                        }}
                        
                        />
          </div> 
          <div style={{ opacity: selectedRow ? 1: .5, width:"50px", display:"flex", justifyContent:"center", borderRadius:"5px", alignItems:"center"}}>
          <img
                        className="routeIcon"
                        src={trash}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => {
                          setState(accountColumns.map(x => {
                            return {
                              name: x.name,
                              value: resultsArr?.find(y => y[Object.keys(y)[0]] == selectedRow[Object.keys(selectedRow)[0]])[x.name]
                            }
                          }).reduce((obj, cur) => ({...obj, [cur.name]: cur}), {}))
                          deleteRecordFunc()
                        }}
                        
                        />
          </div>
        </div>}
      <Component
      allEvents={allEvents}
      setSelectedRow={setSelectedRow}
      resultsArr={resultsArr?.length ? resultsArr : []}
      allowGrouping={props.allowGrouping}
      enableHover={props.enableHover}
      rowHeight={props.rowHeight}
      allowFiltering={props.allowFiltering}
      allowSorting={props.allowSorting}
      allowSelection={props.allowSelection}
      />
    </div>
</Draggable> 

    );
  };
  
   DataGrid.craft = {
    displayName: 'Grid',
    props: {
      allowGrouping: false,
      enableHover: false,
      rowHeight: 50,
      allowFiltering: false,
      allowSorting: false,
      allowSelection: false,x:0,
      y:0, 
      
    },
    related: {
      toolbar: WidgetSettings,
    },
  };
  