import React, { useEffect, useRef, useState } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTh, faThLarge, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { DataSpinner } from '../../spinner/dataSpinner';
import addViewImg from "../../../../assests/images/addTeamImg.svg"
import updated from "../../../../assests/images/updated.svg"
import ACTION_VIEW_SETTINGS_RESET from '../../../../actions/viewSettings/ACTION_VIEW_SETTINGS_RESET';
import Select from "react-select"
import addEventMutation from '../../../../graphql/mutations/event/addEventMutation';
import { useMutation} from '@apollo/client';
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE';
import ACTION_LOADING_SPINNER_RESET from '../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET';
import Editor from "@monaco-editor/react";
import { Sidebar } from '../../editor/editor/Viewport/Sidebar';
import EditDbModal from './EditDbModal';
import arrowDownDark from "../../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../../assests/images/arrowUp.svg"
import Dropdown from "react-dropdown";

//


const ViewSettings = (props) => {

    const [selectedEvent, setSelectedEvent] = useState("")
    const dispatch = useDispatch()
    const [addEvent, {error: addEventError, data: addEventData, loading: addEventLoading}] = useMutation(addEventMutation)
    const widgetIdSetting = useSelector((state) => state.viewSettings.viewSettings);

    const {
      dbonly,
      views,
      dataBasesData,
      eventsDataArr,
dataBaseDataArr,
        open,
        _id, 
        layers,
        setLayers,
        widgetSettings,
        widgetName,
        setOpen,
        setDataBaseUrl,
        dataBaseUrl,
        addDataBase,
        dataBaseDb,
        setDataBaseDb,
        dataBaseAllDb,
        setDataBaseAllDb,
        dataBaseTable,
        setDataBaseTable,
        dataBaseAllTable,
        setDataBaseAllTable,
        nodes,
        eventType,
        setEventType

    } = props

    const getCode = (type, id, preCode) => {

        const code = `

        ${`//use "applyFilter(dataToFilter, collectionName, tableName, value)" functionto apply filter to data sources`}

        ${`//use "addNode(domElement) to add node to the editor`}

        ${`// available widgets to implement`}

        let grid = "grid_widget"
        let input = "text_widget"
        let card = "card_widget"
        
      

        let allData = ${JSON.stringify(dataBaseDataArr)}
        let collections = ${JSON.stringify(dataBaseDataArr.map((x) => x.db.table))}
        let keys = ${JSON.stringify(dataBaseDataArr.map(x => {
          return {
            collection: x.db.table,
            keys: Object.keys(x.results[0]).map(y => y)
          }
        }))}

        let value = inputValue ${`// is the node input value`}

        applyFilter(allData, collections[0], keys[0], value)

        addNode("<div>hello</div>")

        `;
        if(preCode) {
          return preCode
        } else {
          return code
        }
    }
    const [code, setCode] = useState("")
    const [codeError, setCodeError] = useState("")
    function handleEditorValidation(markers) {
      // model markers
      markers?.length ? markers.forEach((marker) => setCodeError(marker.message)) : setCodeError("")

    }
  
    const [allNodes, setAllNodes] = useState(null)
    const [allKeys, setAllKeys] = useState(null)
    const [selectedNode, setSelectedNode] = useState(null)
    const [selectedNodes, setSelectedNodes] = useState([])
    const [eventContent, setEventContent] = useState(null)
    
    useEffect(() => {
      if(eventType) {
        setCodeError("")
      }
    },[eventType])

    const addEventFunc = (selectedEvent,selectedNode,eventType,eventContent, key) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      console.log({
        view: _id,
        widgetId: widgetSettings,
        eventName: selectedEvent?.value,
        nodeId: selectedNode?.value?.db?.table,
        eventType: eventType,
        eventContent: eventContent,        
        key: key?.value
      })
      addEvent({
        variables: {
        view: _id,
        widgetId: widgetSettings,
        eventName: selectedEvent?.value,
        nodeId: selectedNode?.value?.db?.table,
        eventType: eventType,
        eventContent: eventContent,        
        key: key?.value
        }
      }).then(res => {
        console.log(res)
        setEventType && setEventType(null)
        setActiveView("recordSource")
        setActiveEvent("")
        setAddActionActive("")
        dispatch(ACTION_LOADING_SPINNER_RESET())
      })

    }
      // useEffect(() => {
      //   if(nodes) {
      //     let nodesArr = Object.entries(nodes)
      //     setAllNodes(nodesArr.map(([key, x]) => {
      //       return {
      //         value: key,
      //         label:x.data.name
      //       }
      //     } ))
      //   }
      // },[nodes])

    useEffect(() => {
    if(dataBaseDataArr?.length) {
      console.log(dataBaseDataArr)
      setAllNodes(dataBaseDataArr.map((x) => {
        return {
          value: x,
          label:x.db.table,
          widget: x.widget
        }
      } ))
      setAllKeys(dataBaseDataArr.map(x => {
        return {
          widget: x.widget,
          keys: Object.keys(x.results[0]).map(y => {
            return {
              label:y,
              value:y
            }
          })
        }
      }))
    }
  },[dataBaseDataArr])
    const [tableKey, setTableKey] = useState("")
    const [selectedToNavigate, setSelectedToNavigate] = useState("")

    // useEffect(()=> {
    //   if(allNodes) {
    //     console.log(allNodes)
    //   }
    // },[allNodes])
    useEffect(() => {
      if(dataBaseAllDb) {
        console.log(dataBaseAllDb)
      }
    },[dataBaseAllDb])

    useEffect(() => {
      if(dataBaseDataArr) {
        console.log(dataBaseDataArr)
      }
    },[dataBaseDataArr])
    useEffect(() => {
      if(dataBaseAllTable) {
        console.log(dataBaseAllTable)
      }
    },[dataBaseAllTable])
    const [activeView, setActiveView] = useState("recordSource")
    const [activeEvent, setActiveEvent] = useState("")
    const [addActionActive, setAddActionActive] = useState("")

    useEffect(() => {
      if(selectedNode) {
        console.log(selectedNode)
        setSelectedNode(selectedNode)
      }
    },[selectedNode])

    const [dbs, setDbs] = useState(null)
    useEffect(() => {
      if(dataBaseDataArr?.length && widgetIdSetting) {
        console.log(dataBaseDataArr, 1234)
        setDbs(dataBaseDataArr.filter(x => x.widget == widgetIdSetting))
      }
    },[dataBaseDataArr, widgetIdSetting])
    const [eventsList, setEventsList] =  useState([
      
    // Accordion

    {label:"clicked", value:"clicked", yes:["Accordion"]},
    {label:"created", value:"created", yes:["Accordion"]},
    {label:"destroyed", value:"destroyed", yes:["Accordion"]},
    {label:"expanded", value:"expanded", yes:["Accordion"]},
    {label:"expanding", value:"expanding", yes:["Accordion"]},


    // Accumulation Chart
    {label:"afterExport", value:"afterExport", yes:["AccumulationChart"]},
    {label:"animationComplete", value:"animationComplete", yes:["AccumulationChart"]},
    {label:"annotationRender", value:"annotationRender", yes:["AccumulationChart"]},
    {label:"beforePrint", value:"beforePrint", yes:["AccumulationChart"]},
    {label:"beforeResize", value:"beforeResize", yes:["AccumulationChart"]},
    {label:"chartDoubleClick", value:"chartDoubleClick", yes:["AccumulationChart"]},
    {label:"chartMouseClick", value:"chartMouseClick", yes:["AccumulationChart"]},
    {label:"chartMouseDown", value:"chartMouseDown", yes:["AccumulationChart"]},
    {label:"chartMouseLeave", value:"chartMouseLeave", yes:["AccumulationChart"]},
    {label:"chartMouseMove", value:"chartMouseMove", yes:["AccumulationChart"]},
    {label:"chartMouseUp", value:"chartMouseUp", yes:["AccumulationChart"]},
    {label:"legendClick", value:"legendClick", yes:["AccumulationChart"]},
    {label:"legendRender", value:"legendRender", yes:["AccumulationChart"]},
    {label:"load", value:"load", yes:["AccumulationChart"]},
    {label:"loaded", value:"loaded", yes:["AccumulationChart"]},
    {label:"pointClick", value:"pointClick", yes:["AccumulationChart"]},
    {label:"pointMove", value:"pointMove", yes:["AccumulationChart"]},
    {label:"pointRender", value:"pointRender", yes:["AccumulationChart"]},
    {label:"resized", value:"resized", yes:["AccumulationChart"]},
    {label:"selectionComplete", value:"selectionComplete", yes:["AccumulationChart"]},
    {label:"seriesRender", value:"seriesRender", yes:["AccumulationChart"]},
    {label:"textRender", value:"textRender", yes:["AccumulationChart"]},
    {label:"tooltipRender", value:"tooltipRender", yes:["AccumulationChart"]},

    // Appbar

    {label:"created",  value:"created", yes:["Appbar"]},
    {label:"destroyed",  value:"destroyed", yes:["Appbar"]},
    // AutoComplete

{label:"actionBegin", value:"actionBegin", yes:["AutoComplete"]},
{label:"actionComplete", value:"actionComplete", yes:["AutoComplete"]},
{label:"actionFailure", value:"actionFailure", yes:["AutoComplete"]},
{label:"beforeOpen", value:"beforeOpen", yes:["AutoComplete"]},
{label:"blur", value:"blur", yes:["AutoComplete"]},
{label:"change", value:"change", yes:["AutoComplete"]},
{label:"close", value:"close", yes:["AutoComplete"]},
{label:"created", value:"created", yes:["AutoComplete"]},
{label:"customValueSpecifier", value:"customValueSpecifier", yes:["AutoComplete"]},
{label:"dataBound", value:"dataBound", yes:["AutoComplete"]},
{label:"destroyed", value:"destroyed", yes:["AutoComplete"]},
{label:"filtering", value:"filtering", yes:["AutoComplete"]},
{label:"focus", value:"focus", yes:["AutoComplete"]},
{label:"open", value:"open", yes:["AutoComplete"]},
{label:"select", value:"select", yes:["AutoComplete"]},

    // Barcode

    {label:"invalid", value:"invalid", yes:["Barcode"]},

    // Breadcrumb

  {label:"beforeItemRender", value:"beforeItemRender", yes:["Breadcrumb"]},
  {label:"created", value:"created", yes:["Breadcrumb"]},
  {label:"itemClick", value:"itemClick", yes:["Breadcrumb"]},

    // Bullet Chart

    {label:"beforePrint", value:"beforePrint", yes:["BulletChart"]},
    {label:"bulletChartMouseClick", value:"bulletChartMouseClick", yes:["BulletChart"]},
    {label:"legendRender", value:"legendRender", yes:["BulletChart"]},
    {label:"load", value:"load", yes:["BulletChart"]},
    {label:"loaded", value:"loaded", yes:["BulletChart"]},
    {label:"tooltipRender", value:"tooltipRender", yes:["BulletChart"]},

    // Button

{label:"created", value:"created", yes:["Button"]},

    // Calendar

    {label:"change", value:"change", yes:["Calendar"]},
{label:"created", value:"created", yes:["Calendar"]},
{label:"destroyed", value:"destroyed", yes:["Calendar"]},
{label:"navigated", value:"navigated", yes:["Calendar"]},
{label:"renderDayCell", value:"renderDayCell", yes:["Calendar"]},

    // Carousel
    {label: "slideChanged", value:"slideChanged", yes:["Carousel"]},
    {label: "slideChanging", value:"slideChanging", yes:["Carousel"]},

    // Chart

    {label:"afterExport", value:"afterExport", yes:["Chart"]},
    {label:"animationComplete", value:"animationComplete", yes:["Chart"]},
    {label:"annotationRender", value:"annotationRender", yes:["Chart"]},
    {label:"axisLabelClick", value:"axisLabelClick", yes:["Chart"]},
    {label:"axisLabelRender", value:"axisLabelRender", yes:["Chart"]},
    {label:"axisMultiLabelRender", value:"axisMultiLabelRender", yes:["Chart"]},
    {label:"axisRangeCalculated", value:"axisRangeCalculated", yes:["Chart"]},
    {label:"beforeExport", value:"beforeExport", yes:["Chart"]},
    {label:"beforePrint", value:"beforePrint", yes:["Chart"]},
    {label:"beforeResize", value:"beforeResize", yes:["Chart"]},
    {label:"chartDoubleClick", value:"chartDoubleClick", yes:["Chart"]},
    {label:"chartMouseClick", value:"chartMouseClick", yes:["Chart"]},
    {label:"chartMouseDown", value:"chartMouseDown", yes:["Chart"]},
    {label:"chartMouseLeave", value:"chartMouseLeave", yes:["Chart"]},
    {label:"chartMouseMove", value:"chartMouseMove", yes:["Chart"]},
    {label:"chartMouseUp", value:"chartMouseUp", yes:["Chart"]},
    {label:"drag", value:"drag", yes:["Chart"]},
    {label:"dragComplete", value:"dragComplete", yes:["Chart"]},
    {label:"dragEnd", value:"dragEnd", yes:["Chart"]},
    {label:"dragStart", value:"dragStart", yes:["Chart"]},
    {label:"legendClick", value:"legendClick", yes:["Chart"]},
    {label:"legendRender", value:"legendRender", yes:["Chart"]},
    {label:"load", value:"load", yes:["Chart"]},
    {label:"loaded", value:"loaded", yes:["Chart"]},
    {label:"multiLevelLabelClick", value:"multiLevelLabelClick", yes:["Chart"]},
    {label:"onZooming", value:"onZooming", yes:["Chart"]},
    {label:"pointClick", value:"pointClick", yes:["Chart"]},
    {label:"pointDoubleClick", value:"pointDoubleClick", yes:["Chart"]},
    {label:"pointMove", value:"pointMove", yes:["Chart"]},
    {label:"pointRender", value:"pointRender", yes:["Chart"]},
    {label:"resized", value:"resized", yes:["Chart"]},
    {label:"scrollChanged", value:"scrollChanged", yes:["Chart"]},
    {label:"scrollEnd", value:"scrollEnd", yes:["Chart"]},
    {label:"scrollStart", value:"scrollStart", yes:["Chart"]},
    {label:"selectionComplete", value:"selectionComplete", yes:["Chart"]},
    {label:"seriesRender", value:"seriesRender", yes:["Chart"]},
    {label:"sharedTooltipRender", value:"sharedTooltipRender", yes:["Chart"]},
    {label:"textRender", value:"textRender", yes:["Chart"]},
    {label:"tooltipRender", value:"tooltipRender", yes:["Chart"]},
    {label:"zoomComplete", value:"zoomComplete", yes:["Chart"]},
    // CheckBox
    {label:"change", value:"change", yes:["change"]},
    {label:"created", value:"created", yes:["created"]},

    // Chips

    {label:"beforeClick", value:"beforeClick", yes:["Chips"]},
    {label:"click", value:"click", yes:["Chips"]},
    {label:"created", value:"created", yes:["Chips"]},
    {label:"delete", value:"delete", yes:["Chips"]},
    {label:"deleted", value:"deleted", yes:["Chips"]},
    // Circular Gauge

    {label:"animationComplete", value:"animationComplete", yes:["CircularGauge"]},
    {label:"annotationRender", value:"annotationRender", yes:["CircularGauge"]},
    {label:"axisLabelRender", value:"axisLabelRender", yes:["CircularGauge"]},
    {label:"beforePrint", value:"beforePrint", yes:["CircularGauge"]},
    {label:"dragEnd", value:"dragEnd", yes:["CircularGauge"]},
    {label:"dragMove", value:"dragMove", yes:["CircularGauge"]},
    {label:"dragStart", value:"dragStart", yes:["CircularGauge"]},
    {label:"gaugeMouseDown", value:"gaugeMouseDown", yes:["CircularGauge"]},
    {label:"gaugeMouseLeave", value:"gaugeMouseLeave", yes:["CircularGauge"]},
    {label:"gaugeMouseMove", value:"gaugeMouseMove", yes:["CircularGauge"]},
    {label:"gaugeMouseUp", value:"gaugeMouseUp", yes:["CircularGauge"]},
    {label:"legendRender", value:"legendRender", yes:["CircularGauge"]},
    {label:"load", value:"load", yes:["CircularGauge"]},
    {label:"loaded", value:"loaded", yes:["CircularGauge"]},
    {label:"radiusCalculate", value:"radiusCalculate", yes:["CircularGauge"]},
    {label:"resized", value:"resized", yes:["CircularGauge"]},
    {label:"tooltipRender", value:"tooltipRender", yes:["CircularGauge"]},

    // ColorPicker
    
    {label:"beforeClose", value:"beforeClose", yes:["beforeClose"]},
    {label:"beforeModeSwitch", value:"beforeModeSwitch", yes:["beforeModeSwitch"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["beforeOpen"]},
    {label:"beforeTileRender", value:"beforeTileRender", yes:["beforeTileRender"]},
    {label:"change", value:"change", yes:["change"]},
    {label:"created", value:"created", yes:["created"]},
    {label:"onModeSwitch", value:"onModeSwitch", yes:["onModeSwitch"]},
    {label:"open", value:"open", yes:["open"]},
    {label:"select", value:"select", yes:["select"]},

    // ComboBox

    {label:"actionBegin", value:"actionBegin", yes:["ComboBox"]},
    {label:"actionComplete", value:"actionComplete", yes:["ComboBox"]},
    {label:"actionFailure", value:"actionFailure", yes:["ComboBox"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["ComboBox"]},
    {label:"blur", value:"blur", yes:["ComboBox"]},
    {label:"change", value:"change", yes:["ComboBox"]},
    {label:"close", value:"close", yes:["ComboBox"]},
    {label:"created", value:"created", yes:["ComboBox"]},
    {label:"customValueSpecifier", value:"customValueSpecifier", yes:["ComboBox"]},
    {label:"dataBound", value:"dataBound", yes:["ComboBox"]},
    {label:"destroyed", value:"destroyed", yes:["ComboBox"]},
    {label:"filtering", value:"filtering", yes:["ComboBox"]},
    {label:"focus", value:"focus", yes:["ComboBox"]},
    {label:"open", value:"open", yes:["ComboBox"]},
    {label:"select", value:"select", yes:["ComboBox"]},
    // ContextMenu

    {label:"beforeClose", value:"beforeClose", yes:["ContextMenu"]},
    {label:"beforeItemRender", value:"beforeItemRender", yes:["ContextMenu"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["ContextMenu"]},
    {label:"created", value:"created", yes:["ContextMenu"]},
    {label:"onClose", value:"onClose", yes:["ContextMenu"]},
    {label:"onOpen", value:"onOpen", yes:["ContextMenu"]},
    {label:"select", value:"select", yes:["ContextMenu"]},
    // Dashboard Layout

    {label:"change", value:"change", yes:["DashboardLayout"]},
    {label:"created", value:"created", yes:["DashboardLayout"]},
    {label:"destroyed", value:"destroyed", yes:["DashboardLayout"]},
    {label:"drag", value:"drag", yes:["DashboardLayout"]},
    {label:"dragStart", value:"dragStart", yes:["DashboardLayout"]},
    {label:"dragStop", value:"dragStop", yes:["DashboardLayout"]},
    {label:"resize", value:"resize", yes:["DashboardLayout"]},
    {label:"resizeStart", value:"resizeStart", yes:["DashboardLayout"]},
    {label:"resizeStop", value:"resizeStop", yes:["DashboardLayout"]},
    // DatePicker

    {label:"blur", value:"blur", yes:["DatePicker"]},
    {label:"change", value:"change", yes:["DatePicker"]},
    {label:"cleared", value:"cleared", yes:["DatePicker"]},
    {label:"close", value:"close", yes:["DatePicker"]},
    {label:"created", value:"created", yes:["DatePicker"]},
    {label:"destroyed", value:"destroyed", yes:["DatePicker"]},
    {label:"focus", value:"focus", yes:["DatePicker"]},
    {label:"navigated", value:"navigated", yes:["DatePicker"]},
    {label:"open", value:"open", yes:["DatePicker"]},
    {label:"renderDayCell", value:"renderDayCell", yes:["DatePicker"]},

    // DateRangePicker
    
    {label:"blur", value:"blur", yes:["DateRangePicker"]},
{label:"change", value:"change", yes:["DateRangePicker"]},
{label:"cleared", value:"cleared", yes:["DateRangePicker"]},
{label:"close", value:"close", yes:["DateRangePicker"]},
{label:"created", value:"created", yes:["DateRangePicker"]},
{label:"destroyed", value:"destroyed", yes:["DateRangePicker"]},
{label:"focus", value:"focus", yes:["DateRangePicker"]},
{label:"navigated", value:"navigated", yes:["DateRangePicker"]},
{label:"open", value:"open", yes:["DateRangePicker"]},
{label:"renderDayCell", value:"renderDayCell", yes:["DateRangePicker"]},
{label:"select", value:"select", yes:["DateRangePicker"]},

    // DateTimePicker

    {label:"blur", value:"blur", yes:["DateTimePicker"]},
    {label:"change", value:"change", yes:["DateTimePicker"]},
    {label:"cleared", value:"cleared", yes:["DateTimePicker"]},
    {label:"close", value:"close", yes:["DateTimePicker"]},
    {label:"created", value:"created", yes:["DateTimePicker"]},
    {label:"destroyed", value:"destroyed", yes:["DateTimePicker"]},
    {label:"focus", value:"focus", yes:["DateTimePicker"]},
    {label:"navigated", value:"navigated", yes:["DateTimePicker"]},
    {label:"open", value:"open", yes:["DateTimePicker"]},
    {label:"renderDayCell", value:"renderDayCell", yes:["DateTimePicker"]},

    // Diagram

    {label:"animationComplete", value:"animationComplete", yes:["Diagram"]},
    {label:"click", value:"click", yes:["Diagram"]},
    {label:"collectionChange", value:"collectionChange", yes:["Diagram"]},
    {label:"commandExecute", value:"commandExecute", yes:["Diagram"]},
    {label:"connectionChange", value:"connectionChange", yes:["Diagram"]},
    {label:"contextMenuBeforeItemRender", value:"contextMenuBeforeItemRender", yes:["Diagram"]},
    {label:"contextMenuClick", value:"contextMenuClick", yes:["Diagram"]},
    {label:"contextMenuOpen", value:"contextMenuOpen", yes:["Diagram"]},
    {label:"created", value:"created", yes:["Diagram"]},
    {label:"dataLoaded", value:"dataLoaded", yes:["Diagram"]},
    {label:"doubleClick", value:"doubleClick", yes:["Diagram"]},
    {label:"dragEnter", value:"dragEnter", yes:["Diagram"]},
    {label:"dragLeave", value:"dragLeave", yes:["Diagram"]},
    {label:"dragOver", value:"dragOver", yes:["Diagram"]},
    {label:"drop", value:"drop", yes:["Diagram"]},
    {label:"expandStateChange", value:"expandStateChange", yes:["Diagram"]},
    {label:"fixedUserHandleClick", value:"fixedUserHandleClick", yes:["Diagram"]},
    {label:"historyChange", value:"historyChange", yes:["Diagram"]},
    {label:"historyStateChange", value:"historyStateChange", yes:["Diagram"]},
    {label:"keyDown", value:"keyDown", yes:["Diagram"]},
    {label:"keyUp", value:"keyUp", yes:["Diagram"]},
    {label:"mouseEnter", value:"mouseEnter", yes:["Diagram"]},
    {label:"mouseLeave", value:"mouseLeave", yes:["Diagram"]},
    {label:"mouseOver", value:"mouseOver", yes:["Diagram"]},
    {label:"mouseWheel", value:"mouseWheel", yes:["Diagram"]},
    {label:"onImageLoad", value:"onImageLoad", yes:["Diagram"]},
    {label:"onUserHandleMouseDown", value:"onUserHandleMouseDown", yes:["Diagram"]},
    {label:"onUserHandleMouseEnter", value:"onUserHandleMouseEnter", yes:["Diagram"]},
    {label:"onUserHandleMouseLeave", value:"onUserHandleMouseLeave", yes:["Diagram"]},
    {label:"onUserHandleMouseUp", value:"onUserHandleMouseUp", yes:["Diagram"]},
    {label:"positionChange", value:"positionChange", yes:["Diagram"]},
    {label:"propertyChange", value:"propertyChange", yes:["Diagram"]},
    {label:"rotateChange", value:"rotateChange", yes:["Diagram"]},
    {label:"scrollChange", value:"scrollChange", yes:["Diagram"]},
    {label:"segmentCollectionChange", value:"segmentCollectionChange", yes:["Diagram"]},
    {label:"selectionChange", value:"selectionChange", yes:["Diagram"]},
    {label:"sizeChange", value:"sizeChange", yes:["Diagram"]},
    {label:"sourcePointChange", value:"sourcePointChange", yes:["Diagram"]},
    {label:"targetPointChange", value:"targetPointChange", yes:["Diagram"]},
    {label:"textEdit    ", value:"textEdit", yes:["Diagram"]},

    // Dialog
    {label:"beforeClose", value:"beforeClose", yes:["Dialog"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["Dialog"]},
    {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["Dialog"]},
    {label:"close", value:"close", yes:["Dialog"]},
    {label:"created", value:"created", yes:["Dialog"]},
    {label:"destroyed", value:"destroyed", yes:["Dialog"]},
    {label:"drag", value:"drag", yes:["Dialog"]},
    {label:"dragStart", value:"dragStart", yes:["Dialog"]},
    {label:"dragStop", value:"dragStop", yes:["Dialog"]},
    {label:"open", value:"open", yes:["Dialog"]},
    {label:"overlayClick", value:"overlayClick", yes:["Dialog"]},
    {label:"resizeStart", value:"resizeStart", yes:["Dialog"]},
    {label:"resizeStop", value:"resizeStop", yes:["Dialog"]},
    {label:"resizing", value:"resizing", yes:["Dialog"]},

    // Document Editor Container
    {label:"beforeCommentAction", value:"beforeCommentAction", yes:["DocumentEditorContainer"]},
    {label:"beforePaneSwitch", value:"beforePaneSwitch", yes:["DocumentEditorContainer"]},
    {label:"commentDelete", value:"commentDelete", yes:["DocumentEditorContainer"]},
    {label:"contentChange", value:"contentChange", yes:["DocumentEditorContainer"]},
    {label:"contentControl", value:"contentControl", yes:["DocumentEditorContainer"]},
    {label:"created", value:"created", yes:["DocumentEditorContainer"]},
    {label:"customContextMenuBeforeOpen", value:"customContextMenuBeforeOpen", yes:["DocumentEditorContainer"]},
    {label:"customContextMenuSelect", value:"customContextMenuSelect", yes:["DocumentEditorContainer"]},
    {label:"destroyed", value:"destroyed", yes:["DocumentEditorContainer"]},
    {label:"documentChange", value:"documentChange", yes:["DocumentEditorContainer"]},
    {label:"selectionChange", value:"selectionChange", yes:["DocumentEditorContainer"]},
    {label:"serviceFailure", value:"serviceFailure", yes:["DocumentEditorContainer"]},
    {label:"toolbarClick", value:"toolbarClick", yes:["DocumentEditorContainer"]},
    {label:"trackChange", value:"trackChange", yes:["DocumentEditorContainer"]},

    // DocumentEditor

    {label:"actionComplete", value:"actionComplete", yes:["DocumentEditor"]},
    {label:"afterFormFieldFill", value:"afterFormFieldFill", yes:["DocumentEditor"]},
    {label:"beforeCommentAction", value:"beforeCommentAction", yes:["DocumentEditor"]},
    {label:"beforeFileOpen", value:"beforeFileOpen", yes:["DocumentEditor"]},
    {label:"beforeFormFieldFill", value:"beforeFormFieldFill", yes:["DocumentEditor"]},
    {label:"beforePaneSwitch", value:"beforePaneSwitch", yes:["DocumentEditor"]},
    {label:"commentBegin", value:"commentBegin", yes:["DocumentEditor"]},
    {label:"commentDelete", value:"commentDelete", yes:["DocumentEditor"]},
    {label:"commentEnd", value:"commentEnd", yes:["DocumentEditor"]},
    {label:"contentChange", value:"contentChange", yes:["DocumentEditor"]},
    {label:"contentControl", value:"contentControl", yes:["DocumentEditor"]},
    {label:"created", value:"created", yes:["DocumentEditor"]},
    {label:"customContextMenuBeforeOpen", value:"customContextMenuBeforeOpen", yes:["DocumentEditor"]},
    {label:"customContextMenuSelect", value:"customContextMenuSelect", yes:["DocumentEditor"]},
    {label:"destroyed", value:"destroyed", yes:["DocumentEditor"]},
    {label:"documentChange", value:"documentChange", yes:["DocumentEditor"]},
    {label:"keyDown", value:"keyDown", yes:["DocumentEditor"]},
    {label:"requestNavigate", value:"requestNavigate", yes:["DocumentEditor"]},
    {label:"searchResultsChange", value:"searchResultsChange", yes:["DocumentEditor"]},
    {label:"selectionChange", value:"selectionChange", yes:["DocumentEditor"]},
    {label:"serviceFailure", value:"serviceFailure", yes:["DocumentEditor"]},
    {label:"trackChange", value:"trackChange", yes:["DocumentEditor"]},
    {label:"viewChange", value:"viewChange", yes:["DocumentEditor"]},
    {label:"zoomFactorChange", value:"zoomFactorChange", yes:["DocumentEditor"]},
    // DropDownButton
    {label:"beforeClose", value:"beforeClose", yes:["DropDownButton"]},
    {label:"beforeItemRender", value:"beforeItemRender", yes:["DropDownButton"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["DropDownButton"]},
    {label:"close", value:"close", yes:["DropDownButton"]},
    {label:"created", value:"created", yes:["DropDownButton"]},
    {label:"open", value:"open", yes:["DropDownButton"]},
    {label:"select", value:"select", yes:["DropDownButton"]},

    // DropDownList

    {label:"actionBegin", value:"actionBegin", yes:["DropDownList"]},
{label:"actionComplete", value:"actionComplete", yes:["DropDownList"]},
{label:"actionFailure", value:"actionFailure", yes:["DropDownList"]},
{label:"beforeOpen", value:"beforeOpen", yes:["DropDownList"]},
{label:"blur", value:"blur", yes:["DropDownList"]},
{label:"change", value:"change", yes:["DropDownList"]},
{label:"close", value:"close", yes:["DropDownList"]},
{label:"created", value:"created", yes:["DropDownList"]},
{label:"dataBound", value:"dataBound", yes:["DropDownList"]},
{label:"destroyed", value:"destroyed", yes:["DropDownList"]},
{label:"filtering", value:"filtering", yes:["DropDownList"]},
{label:"focus", value:"focus", yes:["DropDownList"]},
{label:"open", value:"open", yes:["DropDownList"]},
{label:"select", value:"select", yes:["DropDownList"]},

    // Dropdown Tree
    {label:"actionFailure", value:"actionFailure", yes:["actionFailure"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["beforeOpen"]},
    {label:"blur", value:"blur", yes:["blur"]},
    {label:"change", value:"change", yes:["change"]},
    {label:"close", value:"close", yes:["close"]},
    {label:"created", value:"created", yes:["created"]},
    {label:"dataBound", value:"dataBound", yes:["dataBound"]},
    {label:"destroyed", value:"destroyed", yes:["destroyed"]},
    {label:"filtering", value:"filtering", yes:["filtering"]},
    {label:"focus", value:"focus", yes:["focus"]},
    {label:"keyPress", value:"keyPress", yes:["keyPress"]},
    {label:"open", value:"open", yes:["open"]},
    {label:"select", value:"select", yes:["select"]},

    // FileManager
{label:"beforeDownload", value:"beforeDownload", yes:["FileManager"]},
{label:"beforeImageLoad", value:"beforeImageLoad", yes:["FileManager"]},
{label:"beforePopupClose", value:"beforePopupClose", yes:["FileManager"]},
{label:"beforePopupOpen", value:"beforePopupOpen", yes:["FileManager"]},
{label:"beforeSend", value:"beforeSend", yes:["FileManager"]},
{label:"created", value:"created", yes:["FileManager"]},
{label:"destroyed", value:"destroyed", yes:["FileManager"]},
{label:"failure", value:"failure", yes:["FileManager"]},
{label:"fileDragStart", value:"fileDragStart", yes:["FileManager"]},
{label:"fileDragStop", value:"fileDragStop", yes:["FileManager"]},
{label:"fileDragging", value:"fileDragging", yes:["FileManager"]},
{label:"fileDropped", value:"fileDropped", yes:["FileManager"]},
{label:"fileLoad", value:"fileLoad", yes:["FileManager"]},
{label:"fileOpen", value:"fileOpen", yes:["FileManager"]},
{label:"fileSelect", value:"fileSelect", yes:["FileManager"]},
{label:"fileSelection", value:"fileSelection", yes:["FileManager"]},
{label:"menuClick", value:"menuClick", yes:["FileManager"]},
{label:"menuOpen", value:"menuOpen", yes:["FileManager"]},
{label:"popupClose", value:"popupClose", yes:["FileManager"]},
{label:"popupOpen", value:"popupOpen", yes:["FileManager"]},
{label:"success", value:"success", yes:["FileManager"]},
{label:"toolbarClick", value:"toolbarClick", yes:["FileManager"]},
{label:"toolbarCreate", value:"toolbarCreate", yes:["FileManager"]},
{label:"uploadListCreate", value:"uploadListCreate", yes:["FileManager"]},

    // Floating Action Button


    // Gantt
    {label:"actionBegin", value:"actionBegin", yes:["Gantt"]},
    {label:"actionComplete", value:"actionComplete", yes:["Gantt"]},
    {label:"actionFailure", value:"actionFailure", yes:["Gantt"]},
    {label:"beforeExcelExport", value:"beforeExcelExport", yes:["Gantt"]},
    {label:"beforePdfExport", value:"beforePdfExport", yes:["Gantt"]},
    {label:"beforeTooltipRender", value:"beforeTooltipRender", yes:["Gantt"]},
    {label:"cellDeselected", value:"cellDeselected", yes:["Gantt"]},
    {label:"cellDeselecting", value:"cellDeselecting", yes:["Gantt"]},
    {label:"cellEdit", value:"cellEdit", yes:["Gantt"]},
    {label:"cellSelected", value:"cellSelected", yes:["Gantt"]},
    {label:"cellSelecting", value:"cellSelecting", yes:["Gantt"]},
    {label:"collapsed", value:"collapsed", yes:["Gantt"]},
    {label:"collapsing", value:"collapsing", yes:["Gantt"]},
    {label:"columnDrag", value:"columnDrag", yes:["Gantt"]},
    {label:"columnDragStart", value:"columnDragStart", yes:["Gantt"]},
    {label:"columnDrop", value:"columnDrop", yes:["Gantt"]},
    {label:"columnMenuClick", value:"columnMenuClick", yes:["Gantt"]},
    {label:"columnMenuOpen", value:"columnMenuOpen", yes:["Gantt"]},
    {label:"contextMenuClick", value:"contextMenuClick", yes:["Gantt"]},
    {label:"contextMenuOpen", value:"contextMenuOpen", yes:["Gantt"]},
    {label:"created", value:"created", yes:["Gantt"]},
    {label:"dataBound", value:"dataBound", yes:["Gantt"]},
    {label:"dataStateChange", value:"dataStateChange", yes:["Gantt"]},
    {label:"destroyed", value:"destroyed", yes:["Gantt"]},
    {label:"endEdit", value:"endEdit", yes:["Gantt"]},
    {label:"excelExportComplete", value:"excelExportComplete", yes:["Gantt"]},
    {label:"excelHeaderQueryCellInfo", value:"excelHeaderQueryCellInfo", yes:["Gantt"]},
    {label:"excelQueryCellInfo", value:"excelQueryCellInfo", yes:["Gantt"]},
    {label:"expanded", value:"expanded", yes:["Gantt"]},
    {label:"expanding", value:"expanding", yes:["Gantt"]},
    {label:"headerCellInfo", value:"headerCellInfo", yes:["Gantt"]},
    {label:"load", value:"load", yes:["Gantt"]},
    {label:"onMouseMove", value:"onMouseMove", yes:["Gantt"]},
    {label:"onTaskbarClick", value:"onTaskbarClick", yes:["Gantt"]},
    {label:"pdfColumnHeaderQueryCellInfo", value:"pdfColumnHeaderQueryCellInfo", yes:["Gantt"]},
    {label:"pdfExportComplete", value:"pdfExportComplete", yes:["Gantt"]},
    {label:"pdfQueryCellInfo", value:"pdfQueryCellInfo", yes:["Gantt"]},
    {label:"pdfQueryTaskbarInfo", value:"pdfQueryTaskbarInfo", yes:["Gantt"]},
    {label:"pdfQueryTimelineCellInfo", value:"pdfQueryTimelineCellInfo", yes:["Gantt"]},
    {label:"queryCellInfo", value:"queryCellInfo", yes:["Gantt"]},
    {label:"queryTaskbarInfo", value:"queryTaskbarInfo", yes:["Gantt"]},
    {label:"recordDoubleClick", value:"recordDoubleClick", yes:["Gantt"]},
    {label:"resizeStart", value:"resizeStart", yes:["Gantt"]},
    {label:"resizeStop", value:"resizeStop", yes:["Gantt"]},
    {label:"resizing", value:"resizing", yes:["Gantt"]},
    {label:"rowDataBound", value:"rowDataBound", yes:["Gantt"]},
    {label:"rowDeselected", value:"rowDeselected", yes:["Gantt"]},
    {label:"rowDeselecting", value:"rowDeselecting", yes:["Gantt"]},
    {label:"rowDrag", value:"rowDrag", yes:["Gantt"]},
    {label:"rowDragStart", value:"rowDragStart", yes:["Gantt"]},
    {label:"rowDragStartHelper", value:"rowDragStartHelper", yes:["Gantt"]},
    {label:"rowDrop", value:"rowDrop", yes:["Gantt"]},
    {label:"rowSelected", value:"rowSelected", yes:["Gantt"]},
    {label:"rowSelecting", value:"rowSelecting", yes:["Gantt"]},
    {label:"splitterResizeStart", value:"splitterResizeStart", yes:["Gantt"]},
    {label:"splitterResized", value:"splitterResized", yes:["Gantt"]},
    {label:"splitterResizing", value:"splitterResizing", yes:["Gantt"]},
    {label:"taskbarEdited", value:"taskbarEdited", yes:["Gantt"]},
    {label:"taskbarEditing", value:"taskbarEditing", yes:["Gantt"]},
    {label:"toolbarClick", value:"toolbarClick", yes:["Gantt"]},

    // Grid
    {label:"actionBegin", value:"actionBegin", yes:["Grid"]},
    {label:"actionComplete", value:"actionComplete", yes:["Grid"]},
    {label:"actionFailure", value:"actionFailure", yes:["Grid"]},
    {label:"batchAdd", value:"batchAdd", yes:["Grid"]},
    {label:"batchCancel", value:"batchCancel", yes:["Grid"]},
    {label:"batchDelete", value:"batchDelete", yes:["Grid"]},
    {label:"beforeAutoFill", value:"beforeAutoFill", yes:["Grid"]},
    {label:"beforeBatchAdd", value:"beforeBatchAdd", yes:["Grid"]},
    {label:"beforeBatchDelete", value:"beforeBatchDelete", yes:["Grid"]},
    {label:"beforeBatchSave", value:"beforeBatchSave", yes:["Grid"]},
    {label:"beforeCopy", value:"beforeCopy", yes:["Grid"]},
    {label:"beforeDataBound", value:"beforeDataBound", yes:["Grid"]},
    {label:"beforeExcelExport", value:"beforeExcelExport", yes:["Grid"]},
    {label:"beforeOpenAdaptiveDialog", value:"beforeOpenAdaptiveDialog", yes:["Grid"]},
    {label:"beforeOpenColumnChooser", value:"beforeOpenColumnChooser", yes:["Grid"]},
    {label:"beforePaste", value:"beforePaste", yes:["Grid"]},
    {label:"beforePdfExport", value:"beforePdfExport", yes:["Grid"]},
    {label:"beforePrint", value:"beforePrint", yes:["Grid"]},
    {label:"beginEdit", value:"beginEdit", yes:["Grid"]},
    {label:"cellDeselected", value:"cellDeselected", yes:["Grid"]},
    {label:"cellDeselecting", value:"cellDeselecting", yes:["Grid"]},
    {label:"cellEdit", value:"cellEdit", yes:["Grid"]},
    {label:"cellSave", value:"cellSave", yes:["Grid"]},
    {label:"cellSaved", value:"cellSaved", yes:["Grid"]},
    {label:"cellSelected", value:"cellSelected", yes:["Grid"]},
    {label:"cellSelecting", value:"cellSelecting", yes:["Grid"]},
    {label:"checkBoxChange", value:"checkBoxChange", yes:["Grid"]},
    {label:"columnDataStateChange", value:"columnDataStateChange", yes:["Grid"]},
    {label:"columnDeselected", value:"columnDeselected", yes:["Grid"]},
    {label:"columnDeselecting", value:"columnDeselecting", yes:["Grid"]},
    {label:"columnDrag", value:"columnDrag", yes:["Grid"]},
    {label:"columnDragStart", value:"columnDragStart", yes:["Grid"]},
    {label:"columnDrop", value:"columnDrop", yes:["Grid"]},
    {label:"columnMenuClick", value:"columnMenuClick", yes:["Grid"]},
    {label:"columnMenuOpen", value:"columnMenuOpen", yes:["Grid"]},
    {label:"columnSelected", value:"columnSelected", yes:["Grid"]},
    {label:"columnSelecting", value:"columnSelecting", yes:["Grid"]},
    {label:"commandClick", value:"commandClick", yes:["Grid"]},
    {label:"contextMenuClick", value:"contextMenuClick", yes:["Grid"]},
    {label:"contextMenuOpen", value:"contextMenuOpen", yes:["Grid"]},
    {label:"created", value:"created", yes:["Grid"]},
    {label:"dataBound", value:"dataBound", yes:["Grid"]},
    {label:"dataSourceChanged", value:"dataSourceChanged", yes:["Grid"]},
    {label:"dataStateChange", value:"dataStateChange", yes:["Grid"]},
    {label:"destroyed", value:"destroyed", yes:["Grid"]},
    {label:"detailDataBound", value:"detailDataBound", yes:["Grid"]},
    {label:"excelAggregateQueryCellInfo", value:"excelAggregateQueryCellInfo", yes:["Grid"]},
    {label:"excelExportComplete", value:"excelExportComplete", yes:["Grid"]},
    {label:"excelHeaderQueryCellInfo", value:"excelHeaderQueryCellInfo", yes:["Grid"]},
    {label:"excelQueryCellInfo", value:"excelQueryCellInfo", yes:["Grid"]},
    {label:"exportDetailDataBound", value:"exportDetailDataBound", yes:["Grid"]},
    {label:"exportGroupCaption", value:"exportGroupCaption", yes:["Grid"]},
    {label:"headerCellInfo", value:"headerCellInfo", yes:["Grid"]},
    {label:"keyPressed", value:"keyPressed", yes:["Grid"]},
    {label:"lazyLoadGroupCollapse", value:"lazyLoadGroupCollapse", yes:["Grid"]},
    {label:"lazyLoadGroupExpand", value:"lazyLoadGroupExpand", yes:["Grid"]},
    {label:"load", value:"load", yes:["Grid"]},
    {label:"pdfAggregateQueryCellInfo", value:"pdfAggregateQueryCellInfo", yes:["Grid"]},
    {label:"pdfExportComplete", value:"pdfExportComplete", yes:["Grid"]},
    {label:"pdfHeaderQueryCellInfo", value:"pdfHeaderQueryCellInfo", yes:["Grid"]},
    {label:"pdfQueryCellInfo", value:"pdfQueryCellInfo", yes:["Grid"]},
    {label:"printComplete", value:"printComplete", yes:["Grid"]},
    {label:"queryCellInfo", value:"queryCellInfo", yes:["Grid"]},
    {label:"recordClick", value:"recordClick", yes:["Grid"]},
    {label:"recordDoubleClick", value:"recordDoubleClick", yes:["Grid"]},
    {label:"resizeStart", value:"resizeStart", yes:["Grid"]},
    {label:"resizeStop", value:"resizeStop", yes:["Grid"]},
    {label:"resizing", value:"resizing", yes:["Grid"]},
    {label:"rowDataBound", value:"rowDataBound", yes:["Grid"]},
    {label:"rowDeselected", value:"rowDeselected", yes:["Grid"]},
    {label:"rowDeselecting", value:"rowDeselecting", yes:["Grid"]},
    {label:"rowDrag", value:"rowDrag", yes:["Grid"]},
    {label:"rowDragStart", value:"rowDragStart", yes:["Grid"]},
    {label:"rowDragStartHelper", value:"rowDragStartHelper", yes:["Grid"]},
    {label:"rowDrop", value:"rowDrop", yes:["Grid"]},
    {label:"rowSelected", value:"rowSelected", yes:["Grid"]},
    {label:"rowSelecting", value:"rowSelecting", yes:["Grid"]},
    {label:"toolbarClick", value:"toolbarClick", yes:["Grid"]},

    // HeatMap
    {label:"cellClick", value:"cellClick", yes:["HeatMap"]},
    {label:"cellRender", value:"cellRender", yes:["HeatMap"]},
    {label:"cellSelected", value:"cellSelected", yes:["HeatMap"]},
    {label:"created", value:"created", yes:["HeatMap"]},
    {label:"legendRender", value:"legendRender", yes:["HeatMap"]},
    {label:"load", value:"load", yes:["HeatMap"]},
    {label:"loaded", value:"loaded", yes:["HeatMap"]},
    {label:"resized", value:"resized", yes:["HeatMap"]},
    {label:"tooltipRender", value:"tooltipRender", yes:["HeatMap"]},

    // Image Editor
    {label:"beforeSave", value:"beforeSave", yes:["ImageEditor"]},
    {label:"created",value:"created", yes:["ImageEditor"]},
    {label:"cropping",value:"cropping", yes:["ImageEditor"]},
    {label:"destroyed",value:"destroyed", yes:["ImageEditor"]},
    {label:"fileOpened",value:"fileOpened", yes:["ImageEditor"]},
    {label:"finetuneValueChanging",value:"finetuneValueChanging", yes:["ImageEditor"]},
    {label:"flipping",value:"flipping", yes:["ImageEditor"]},
    {label:"imageFiltering",value:"imageFiltering", yes:["ImageEditor"]},
    {label:"panning",value:"panning", yes:["ImageEditor"]},
    {label:"rotating",value:"rotating", yes:["ImageEditor"]},
    {label:"saved",value:"saved", yes:["ImageEditor"]},
    {label:"shapeChanging",value:"shapeChanging", yes:["ImageEditor"]},
    {label:"toolbarCreated",value:"toolbarCreated", yes:["ImageEditor"]},
    {label:"toolbarItemClicked",value:"toolbarItemClicked", yes:["ImageEditor"]},
    {label:"toolbarUpdating",value:"toolbarUpdating", yes:["ImageEditor"]},
    {label:"zooming",value:"zooming", yes:["ImageEditor"]},

    // In-place Editor
    {label:"actionBegin", value:"actionBegin", yes:["InPlaceEditor"]},
    {label:"actionFailure", value:"actionFailure", yes:["InPlaceEditor"]},
    {label:"actionSuccess", value:"actionSuccess", yes:["InPlaceEditor"]},
    {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["InPlaceEditor"]},
    {label:"beginEdit", value:"beginEdit", yes:["InPlaceEditor"]},
    {label:"cancelClick", value:"cancelClick", yes:["InPlaceEditor"]},
    {label:"change", value:"change", yes:["InPlaceEditor"]},
    {label:"created", value:"created", yes:["InPlaceEditor"]},
    {label:"destroyed", value:"destroyed", yes:["InPlaceEditor"]},
    {label:"endEdit", value:"endEdit", yes:["InPlaceEditor"]},
    {label:"submitClick", value:"submitClick", yes:["InPlaceEditor"]},
    {label:"validating", value:"validating", yes:["InPlaceEditor"]},

    // Kanban

    {label:"actionBegin", value:"actionBegin", yes:["Kanban"]},
{label:"actionComplete", value:"actionComplete", yes:["Kanban"]},
{label:"actionFailure", value:"actionFailure", yes:["Kanban"]},
{label:"cardClick", value:"cardClick", yes:["Kanban"]},
{label:"cardDoubleClick", value:"cardDoubleClick", yes:["Kanban"]},
{label:"cardRendered", value:"cardRendered", yes:["Kanban"]},
{label:"created", value:"created", yes:["Kanban"]},
{label:"dataBinding", value:"dataBinding", yes:["Kanban"]},
{label:"dataBound", value:"dataBound", yes:["Kanban"]},
{label:"dataSourceChanged", value:"dataSourceChanged", yes:["Kanban"]},
{label:"dataStateChange", value:"dataStateChange", yes:["Kanban"]},
{label:"dialogClose", value:"dialogClose", yes:["Kanban"]},
{label:"dialogOpen", value:"dialogOpen", yes:["Kanban"]},
{label:"drag", value:"drag", yes:["Kanban"]},
{label:"dragStart", value:"dragStart", yes:["Kanban"]},
{label:"dragStop", value:"dragStop", yes:["Kanban"]},
{label:"queryCellInfo", value:"queryCellInfo", yes:["Kanban"]},

    // Linear Gauge
{label:"animationComplete", value:"animationComplete",yes:["LinearGauge"]},
{label:"annotationRender", value:"annotationRender",yes:["LinearGauge"]},
{label:"axisLabelRender", value:"axisLabelRender",yes:["LinearGauge"]},
{label:"beforePrint", value:"beforePrint",yes:["LinearGauge"]},
{label:"dragEnd", value:"dragEnd",yes:["LinearGauge"]},
{label:"dragMove", value:"dragMove",yes:["LinearGauge"]},
{label:"dragStart", value:"dragStart",yes:["LinearGauge"]},
{label:"gaugeMouseDown", value:"gaugeMouseDown",yes:["LinearGauge"]},
{label:"gaugeMouseLeave", value:"gaugeMouseLeave",yes:["LinearGauge"]},
{label:"gaugeMouseMove", value:"gaugeMouseMove",yes:["LinearGauge"]},
{label:"gaugeMouseUp", value:"gaugeMouseUp",yes:["LinearGauge"]},
{label:"load", value:"load",yes:["LinearGauge"]},
{label:"loaded", value:"loaded",yes:["LinearGauge"]},
{label:"resized", value:"resized",yes:["LinearGauge"]},
{label:"tooltipRender", value:"tooltipRender",yes:["LinearGauge"]},
{label:"valueChange", value:"valueChange",yes:["LinearGauge"]},

    // ListBox
    {label:"actionBegin", value:"actionBegin", yes:["ListBox"]},
    {label:"actionComplete", value:"actionComplete", yes:["ListBox"]},
    {label:"actionFailure", value:"actionFailure", yes:["ListBox"]},
    {label:"beforeDrop", value:"beforeDrop", yes:["ListBox"]},
    {label:"beforeItemRender", value:"beforeItemRender", yes:["ListBox"]},
    {label:"change", value:"change", yes:["ListBox"]},
    {label:"created", value:"created", yes:["ListBox"]},
    {label:"destroyed", value:"destroyed", yes:["ListBox"]},
    {label:"drag", value:"drag", yes:["ListBox"]},
    {label:"dragStart", value:"dragStart", yes:["ListBox"]},
    {label:"drop", value:"drop", yes:["ListBox"]},
    {label:"filtering", value:"filtering", yes:["ListBox"]},

    // ListView

    {label:"actionBegin", value:"actionBegin", yes:["ListView"]},
    {label:"actionComplete", value:"actionComplete", yes:["ListView"]},
    {label:"actionFailure", value:"actionFailure", yes:["ListView"]},
    {label:"select", value:"select", yes:["ListView"]},
    // Maps

    {label:"animationComplete", value:"animationComplete", yes:["Maps"]},
    {label:"annotationRendering", value:"annotationRendering", yes:["Maps"]},
    {label:"beforePrint", value:"beforePrint", yes:["Maps"]},
    {label:"bubbleClick", value:"bubbleClick", yes:["Maps"]},
    {label:"bubbleMouseMove", value:"bubbleMouseMove", yes:["Maps"]},
    {label:"bubbleRendering", value:"bubbleRendering", yes:["Maps"]},
    {label:"click", value:"click", yes:["Maps"]},
    {label:"dataLabelRendering", value:"dataLabelRendering", yes:["Maps"]},
    {label:"doubleClick", value:"doubleClick", yes:["Maps"]},
    {label:"itemHighlight", value:"itemHighlight", yes:["Maps"]},
    {label:"itemSelection", value:"itemSelection", yes:["Maps"]},
    {label:"layerRendering", value:"layerRendering", yes:["Maps"]},
    {label:"legendRendering", value:"legendRendering", yes:["Maps"]},
    {label:"load", value:"load", yes:["Maps"]},
    {label:"loaded", value:"loaded", yes:["Maps"]},
    {label:"markerClick", value:"markerClick", yes:["Maps"]},
    {label:"markerClusterClick", value:"markerClusterClick", yes:["Maps"]},
    {label:"markerClusterMouseMove", value:"markerClusterMouseMove", yes:["Maps"]},
    {label:"markerClusterRendering", value:"markerClusterRendering", yes:["Maps"]},
    {label:"markerMouseMove", value:"markerMouseMove", yes:["Maps"]},
    {label:"markerRendering", value:"markerRendering", yes:["Maps"]},
    {label:"onclick", value:"onclick", yes:["Maps"]},
    {label:"pan", value:"pan", yes:["Maps"]},
    {label:"resize", value:"resize", yes:["Maps"]},
    {label:"rightClick", value:"rightClick", yes:["Maps"]},
    {label:"shapeHighlight", value:"shapeHighlight", yes:["Maps"]},
    {label:"shapeRendering", value:"shapeRendering", yes:["Maps"]},
    {label:"shapeSelected", value:"shapeSelected", yes:["Maps"]},
    {label:"tooltipRender", value:"tooltipRender", yes:["Maps"]},
    {label:"tooltipRenderComplete", value:"tooltipRenderComplete", yes:["Maps"]},
    {label:"zoom", value:"zoom", yes:["Maps"]},
    // MaskedTextBox
    {label:"blur", value:"blur", yes:["MaskedTextBox"]},
    {label:"change", value:"change", yes:["MaskedTextBox"]},
    {label:"created", value:"created", yes:["MaskedTextBox"]},
    {label:"destroyed", value:"destroyed", yes:["MaskedTextBox"]},
    {label:"focus", value:"focus", yes:["MaskedTextBox"]},

    // Mention
    {label:"actionBegin", value:"actionBegin", yes:["Mention"]},
    {label:"actionComplete", value:"actionComplete", yes:["Mention"]},
    {label:"actionFailure", value:"actionFailure", yes:["Mention"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["Mention"]},
    {label:"change", value:"change", yes:["Mention"]},
    {label:"closed", value:"closed", yes:["Mention"]},
    {label:"created", value:"created", yes:["Mention"]},
    {label:"dataBound", value:"dataBound", yes:["Mention"]},
    {label:"destroyed", value:"destroyed", yes:["Mention"]},
    {label:"filtering", value:"filtering", yes:["Mention"]},
    {label:"opened", value:"opened", yes:["Mention"]},
    {label:"select", value:"select", yes:["Mention"]},

    // Menu Bar
    {label:"beforeClose", value:"beforeClose", yes:["Menu"]},
    {label:"beforeItemRender", value:"beforeItemRender", yes:["Menu"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["Menu"]},
    {label:"created", value:"created", yes:["Menu"]},
    {label:"onClose", value:"onClose", yes:["Menu"]},
    {label:"onOpen", value:"onOpen", yes:["Menu"]},
    {label:"select", value:"select", yes:["Menu"]},

    // Message

    {label:"closed", value:"closed", yes:["Message"]},
    {label:"created", value:"created", yes:["Message"]},
    {label:"destroyed", value:"destroyed", yes:["Message"]},
    // MultiSelect
    {label:"actionBegin", value:"actionBegin", yes:["MultiSelect"]},
    {label:"actionComplete", value:"actionComplete", yes:["MultiSelect"]},
    {label:"actionFailure", value:"actionFailure", yes:["MultiSelect"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["MultiSelect"]},
    {label:"beforeSelectAll", value:"beforeSelectAll", yes:["MultiSelect"]},
    {label:"blur", value:"blur", yes:["MultiSelect"]},
    {label:"change", value:"change", yes:["MultiSelect"]},
    {label:"chipSelection", value:"chipSelection", yes:["MultiSelect"]},
    {label:"close", value:"close", yes:["MultiSelect"]},
    {label:"created", value:"created", yes:["MultiSelect"]},
    {label:"customValueSelection", value:"customValueSelection", yes:["MultiSelect"]},
    {label:"dataBound", value:"dataBound", yes:["MultiSelect"]},
    {label:"destroyed", value:"destroyed", yes:["MultiSelect"]},
    {label:"filtering", value:"filtering", yes:["MultiSelect"]},
    {label:"focus", value:"focus", yes:["MultiSelect"]},
    {label:"open", value:"open", yes:["MultiSelect"]},
    {label:"removed", value:"removed", yes:["MultiSelect"]},
    {label:"removing", value:"removing", yes:["MultiSelect"]},
    {label:"select", value:"select", yes:["MultiSelect"]},
    {label:"selectedAll", value:"selectedAll", yes:["MultiSelect"]},
    {label:"tagging", value:"tagging", yes:["MultiSelect"]},

    // NumericTextBox
    {label:"blur", value:"blur", yes:["NumericTextBox"]},
    {label:"change", value:"change", yes:["NumericTextBox"]},
    {label:"created", value:"created", yes:["NumericTextBox"]},
    {label:"destroyed", value:"destroyed", yes:["NumericTextBox"]},
    {label:"focus", value:"focus", yes:["NumericTextBox"]},

    // Overview
{label:"created", value:"created", yes:["Overview"]},

    // Pager


    // PDF Viewer
    {label:"addSignature", value:"addSignature", yes:["PDF"]},
    {label:"ajaxRequestFailed", value:"ajaxRequestFailed", yes:["PDF"]},
    {label:"ajaxRequestInitiate", value:"ajaxRequestInitiate", yes:["PDF"]},
    {label:"ajaxRequestSuccess", value:"ajaxRequestSuccess", yes:["PDF"]},
    {label:"annotationAdd", value:"annotationAdd", yes:["PDF"]},
    {label:"annotationDoubleClick", value:"annotationDoubleClick", yes:["PDF"]},
    {label:"annotationMouseLeave", value:"annotationMouseLeave", yes:["PDF"]},
    {label:"annotationMouseover", value:"annotationMouseover", yes:["PDF"]},
    {label:"annotationMove", value:"annotationMove", yes:["PDF"]},
    {label:"annotationMoving", value:"annotationMoving", yes:["PDF"]},
    {label:"annotationPropertiesChange", value:"annotationPropertiesChange", yes:["PDF"]},
    {label:"annotationRemove", value:"annotationRemove", yes:["PDF"]},
    {label:"annotationResize", value:"annotationResize", yes:["PDF"]},
    {label:"annotationSelect", value:"annotationSelect", yes:["PDF"]},
    {label:"annotationUnSelect", value:"annotationUnSelect", yes:["PDF"]},
    {label:"beforeAddFreeText", value:"beforeAddFreeText", yes:["PDF"]},
    {label:"bookmarkClick", value:"bookmarkClick", yes:["PDF"]},
    {label:"buttonFieldClick", value:"buttonFieldClick", yes:["PDF"]},
    {label:"commentAdd", value:"commentAdd", yes:["PDF"]},
    {label:"commentDelete", value:"commentDelete", yes:["PDF"]},
    {label:"commentEdit", value:"commentEdit", yes:["PDF"]},
    {label:"commentSelect", value:"commentSelect", yes:["PDF"]},
    {label:"commentStatusChanged", value:"commentStatusChanged", yes:["PDF"]},
    {label:"created", value:"created", yes:["PDF"]},
    {label:"documentLoad", value:"documentLoad", yes:["PDF"]},
    {label:"documentLoadFailed", value:"documentLoadFailed", yes:["PDF"]},
    {label:"documentUnload", value:"documentUnload", yes:["PDF"]},
    {label:"downloadEnd", value:"downloadEnd", yes:["PDF"]},
    {label:"downloadStart", value:"downloadStart", yes:["PDF"]},
    {label:"exportFailed", value:"exportFailed", yes:["PDF"]},
    {label:"exportStart", value:"exportStart", yes:["PDF"]},
    {label:"exportSuccess", value:"exportSuccess", yes:["PDF"]},
    {label:"extractTextCompleted", value:"extractTextCompleted", yes:["PDF"]},
    {label:"formFieldAdd", value:"formFieldAdd", yes:["PDF"]},
    {label:"formFieldClick", value:"formFieldClick", yes:["PDF"]},
    {label:"formFieldDoubleClick", value:"formFieldDoubleClick", yes:["PDF"]},
    {label:"formFieldFocusOut", value:"formFieldFocusOut", yes:["PDF"]},
    {label:"formFieldMouseLeave", value:"formFieldMouseLeave", yes:["PDF"]},
    {label:"formFieldMouseover", value:"formFieldMouseover", yes:["PDF"]},
    {label:"formFieldMove", value:"formFieldMove", yes:["PDF"]},
    {label:"formFieldPropertiesChange", value:"formFieldPropertiesChange", yes:["PDF"]},
    {label:"formFieldRemove", value:"formFieldRemove", yes:["PDF"]},
    {label:"formFieldResize", value:"formFieldResize", yes:["PDF"]},
    {label:"formFieldSelect", value:"formFieldSelect", yes:["PDF"]},
    {label:"formFieldUnselect", value:"formFieldUnselect", yes:["PDF"]},
    {label:"hyperlinkClick", value:"hyperlinkClick", yes:["PDF"]},
    {label:"hyperlinkMouseOver", value:"hyperlinkMouseOver", yes:["PDF"]},
    {label:"importFailed", value:"importFailed", yes:["PDF"]},
    {label:"importStart", value:"importStart", yes:["PDF"]},
    {label:"importSuccess", value:"importSuccess", yes:["PDF"]},
    {label:"moveSignature", value:"moveSignature", yes:["PDF"]},
    {label:"pageChange", value:"pageChange", yes:["PDF"]},
    {label:"pageClick", value:"pageClick", yes:["PDF"]},
    {label:"pageMouseover", value:"pageMouseover", yes:["PDF"]},
    {label:"printEnd", value:"printEnd", yes:["PDF"]},
    {label:"printStart", value:"printStart", yes:["PDF"]},
    {label:"removeSignature", value:"removeSignature", yes:["PDF"]},
    {label:"resizeSignature", value:"resizeSignature", yes:["PDF"]},
    {label:"signaturePropertiesChange", value:"signaturePropertiesChange", yes:["PDF"]},
    {label:"signatureSelect", value:"signatureSelect", yes:["PDF"]},
    {label:"textSearchComplete", value:"textSearchComplete", yes:["PDF"]},
    {label:"textSearchHighlight", value:"textSearchHighlight", yes:["PDF"]},
    {label:"textSearchStart", value:"textSearchStart", yes:["PDF"]},
    {label:"textSelectionEnd", value:"textSelectionEnd", yes:["PDF"]},
    {label:"textSelectionStart", value:"textSelectionStart", yes:["PDF"]},
    {label:"thumbnailClick", value:"thumbnailClick", yes:["PDF"]},
    {label:"validateFormFields", value:"validateFormFields", yes:["PDF"]},
    {label:"zoomChange", value:"zoomChange", yes:["PDF"]},

    // Pivotfieldlist
    {label:"actionBegin", value:"actionBegin", yes:["PivotFieldList"]},
    {label:"actionComplete", value:"actionComplete", yes:["PivotFieldList"]},
    {label:"actionFailure", value:"actionFailure", yes:["PivotFieldList"]},
    {label:"aggregateCellInfo", value:"aggregateCellInfo", yes:["PivotFieldList"]},
    {label:"aggregateMenuOpen", value:"aggregateMenuOpen", yes:["PivotFieldList"]},
    {label:"beforeServiceInvoke", value:"beforeServiceInvoke", yes:["PivotFieldList"]},
    {label:"calculatedFieldCreate", value:"calculatedFieldCreate", yes:["PivotFieldList"]},
    {label:"created", value:"created", yes:["PivotFieldList"]},
    {label:"dataBound", value:"dataBound", yes:["PivotFieldList"]},
    {label:"destroyed", value:"destroyed", yes:["PivotFieldList"]},
    {label:"enginePopulated", value:"enginePopulated", yes:["PivotFieldList"]},
    {label:"enginePopulating", value:"enginePopulating", yes:["PivotFieldList"]},
    {label:"fieldDragStart", value:"fieldDragStart", yes:["PivotFieldList"]},
    {label:"fieldDrop", value:"fieldDrop", yes:["PivotFieldList"]},
    {label:"fieldRemove", value:"fieldRemove", yes:["PivotFieldList"]},
    {label:"load", value:"load", yes:["PivotFieldList"]},
    {label:"memberEditorOpen", value:"memberEditorOpen", yes:["PivotFieldList"]},
    {label:"memberFiltering", value:"memberFiltering", yes:["PivotFieldList"]},
    {label:"onFieldDropped", value:"onFieldDropped", yes:["PivotFieldList"]},
    {label:"onHeadersSort", value:"onHeadersSort", yes:["PivotFieldList"]},

    // Pivot Table
    {label:"chartExport", value:"chartExport", yes:["PivotTable"]},
    {label:"createCalculatedFieldDialog", value:"createCalculatedFieldDialog", yes:["PivotTable"]},
    {label:"csvExport", value:"csvExport", yes:["PivotTable"]},
    {label:"destroy", value:"destroy", yes:["PivotTable"]},
    {label:"excelExport", value:"excelExport", yes:["PivotTable"]},
    {label:"getPersistData", value:"getPersistData", yes:["PivotTable"]},
    {label:"loadPersistData", value:"loadPersistData", yes:["PivotTable"]},
    {label:"pdfExport", value:"pdfExport", yes:["PivotTable"]},
    {label:"printChart", value:"printChart", yes:["PivotTable"]},
    {label:"refresh", value:"refresh", yes:["PivotTable"]},
    {label:"showConditionalFormattingDialog", value:"showConditionalFormattingDialog", yes:["PivotTable"]},
    {label:"showNumberFormattingDialog", value:"showNumberFormattingDialog", yes:["PivotTable"]},
    {label:"Events", value:"Events", yes:["PivotTable"]},
    {label:"actionBegin", value:"actionBegin", yes:["PivotTable"]},
    {label:"actionComplete", value:"actionComplete", yes:["PivotTable"]},
    {label:"actionFailure", value:"actionFailure", yes:["PivotTable"]},
    {label:"aggregateCellInfo", value:"aggregateCellInfo", yes:["PivotTable"]},
    {label:"aggregateMenuOpen", value:"aggregateMenuOpen", yes:["PivotTable"]},
    {label:"beforeExport", value:"beforeExport", yes:["PivotTable"]},
    {label:"beforeServiceInvoke", value:"beforeServiceInvoke", yes:["PivotTable"]},
    {label:"beginDrillThrough", value:"beginDrillThrough", yes:["PivotTable"]},
    {label:"calculatedFieldCreate", value:"calculatedFieldCreate", yes:["PivotTable"]},
    {label:"cellClick", value:"cellClick", yes:["PivotTable"]},
    {label:"cellSelected", value:"cellSelected", yes:["PivotTable"]},
    {label:"cellSelecting", value:"cellSelecting", yes:["PivotTable"]},
    {label:"chartSeriesCreated", value:"chartSeriesCreated", yes:["PivotTable"]},
    {label:"conditionalFormatting", value:"conditionalFormatting", yes:["PivotTable"]},
    {label:"created", value:"created", yes:["PivotTable"]},
    {label:"dataBound", value:"dataBound", yes:["PivotTable"]},
    {label:"destroyed", value:"destroyed", yes:["PivotTable"]},
    {label:"drill", value:"drill", yes:["PivotTable"]},
    {label:"drillThrough", value:"drillThrough", yes:["PivotTable"]},
    {label:"editCompleted", value:"editCompleted", yes:["PivotTable"]},
    {label:"enginePopulated", value:"enginePopulated", yes:["PivotTable"]},
    {label:"enginePopulating", value:"enginePopulating", yes:["PivotTable"]},
    {label:"exportComplete", value:"exportComplete", yes:["PivotTable"]},
    {label:"fetchReport", value:"fetchReport", yes:["PivotTable"]},
    {label:"fieldDragStart", value:"fieldDragStart", yes:["PivotTable"]},
    {label:"fieldDrop", value:"fieldDrop", yes:["PivotTable"]},
    {label:"fieldListRefreshed", value:"fieldListRefreshed", yes:["PivotTable"]},
    {label:"fieldRemove", value:"fieldRemove", yes:["PivotTable"]},
    {label:"hyperlinkCellClick", value:"hyperlinkCellClick", yes:["PivotTable"]},
    {label:"load", value:"load", yes:["PivotTable"]},
    {label:"loadReport", value:"loadReport", yes:["PivotTable"]},
    {label:"memberEditorOpen", value:"memberEditorOpen", yes:["PivotTable"]},
    {label:"memberFiltering", value:"memberFiltering", yes:["PivotTable"]},
    {label:"newReport", value:"newReport", yes:["PivotTable"]},
    {label:"numberFormatting", value:"numberFormatting", yes:["PivotTable"]},
    {label:"onFieldDropped", value:"onFieldDropped", yes:["PivotTable"]},
    {label:"onHeadersSort", value:"onHeadersSort", yes:["PivotTable"]},
    {label:"onPdfCellRender", value:"onPdfCellRender", yes:["PivotTable"]},
    {label:"removeReport", value:"removeReport", yes:["PivotTable"]},
    {label:"renameReport", value:"renameReport", yes:["PivotTable"]},
    {label:"saveReport", value:"saveReport", yes:["PivotTable"]},
    {label:"toolbarClick", value:"toolbarClick", yes:["PivotTable"]},
    {label:"toolbarRender", value:"toolbarRender", yes:["PivotTable"]},

    // ProgressButton

    {label:"begin", value:"begin", yes:["ProgressButton"]},
    {label:"created", value:"created", yes:["ProgressButton"]},
    {label:"end", value:"end", yes:["ProgressButton"]},
    {label:"fail", value:"fail", yes:["ProgressButton"]},
    {label:"progress", value:"progress", yes:["ProgressButton"]},
    // Progressbar
    {label:"animationComplete", value:"animationComplete", yes:["ProgressBar"]},
    {label:"load", value:"load", yes:["ProgressBar"]},
    {label:"loaded", value:"loaded", yes:["ProgressBar"]},
    {label:"mouseClick", value:"mouseClick", yes:["ProgressBar"]},
    {label:"mouseDown", value:"mouseDown", yes:["ProgressBar"]},
    {label:"mouseLeave", value:"mouseLeave", yes:["ProgressBar"]},
    {label:"mouseMove", value:"mouseMove", yes:["ProgressBar"]},
    {label:"mouseUp", value:"mouseUp", yes:["ProgressBar"]},
    {label:"progressCompleted", value:"progressCompleted", yes:["ProgressBar"]},
    {label:"textRender", value:"textRender", yes:["ProgressBar"]},
    {label:"valueChanged", value:"valueChanged", yes:["ProgressBar"]},

    // QueryBuilder
    {label:"actionBegin", value:"actionBegin", yes:["QueryBuilder"]},
    {label:"beforeChange", value:"beforeChange", yes:["QueryBuilder"]},
    {label:"change", value:"change", yes:["QueryBuilder"]},
    {label:"created", value:"created", yes:["QueryBuilder"]},
    {label:"dataBound", value:"dataBound", yes:["QueryBuilder"]},
    {label:"ruleChange", value:"ruleChange", yes:["QueryBuilder"]},

    // RadioButton
    {label:"change",value:"change", yes:["RadioButton"]},
    {label:"created",value:"created", yes:["RadioButton"]},

    // Range Navigator
    {label:"beforePrint", value:"beforePrint", yes:["RangeNavigator"]},
    {label:"beforeResize", value:"beforeResize", yes:["RangeNavigator"]},
    {label:"changed", value:"changed", yes:["RangeNavigator"]},
    {label:"labelRender", value:"labelRender", yes:["RangeNavigator"]},
    {label:"load", value:"load", yes:["RangeNavigator"]},
    {label:"loaded", value:"loaded", yes:["RangeNavigator"]},
    {label:"resized", value:"resized", yes:["RangeNavigator"]},
    {label:"selectorRender", value:"selectorRender", yes:["RangeNavigator"]},
    {label:"tooltipRender", value:"tooltipRender", yes:["RangeNavigator"]},

    // Rating
    {label:"beforeItemRender", value:"beforeItemRender", yes:["Rating"]},
{label:"created", value:"created", yes:["Rating"]},
{label:"onItemHover", value:"onItemHover", yes:["Rating"]},
{label:"valueChanged", value:"valueChanged", yes:["Rating"]},

    // Recurrence Editor
    {label:"change", value:"change", yes:["RecurrenceEditor"]},

    // RichTextEditor
    {label:"actionBegin", value:"actionBegin", yes:["RichTextEditor"]},
    {label:"actionComplete", value:"actionComplete", yes:["RichTextEditor"]},
    {label:"afterImageDelete", value:"afterImageDelete", yes:["RichTextEditor"]},
    {label:"afterMediaDelete", value:"afterMediaDelete", yes:["RichTextEditor"]},
    {label:"afterPasteCleanup", value:"afterPasteCleanup", yes:["RichTextEditor"]},
    {label:"beforeDialogClose", value:"beforeDialogClose", yes:["RichTextEditor"]},
    {label:"beforeDialogOpen", value:"beforeDialogOpen", yes:["RichTextEditor"]},
    {label:"beforeFileUpload", value:"beforeFileUpload", yes:["RichTextEditor"]},
    {label:"beforeImageDrop", value:"beforeImageDrop", yes:["RichTextEditor"]},
    {label:"beforeImageUpload", value:"beforeImageUpload", yes:["RichTextEditor"]},
    {label:"beforePasteCleanup", value:"beforePasteCleanup", yes:["RichTextEditor"]},
    {label:"beforeQuickToolbarOpen", value:"beforeQuickToolbarOpen", yes:["RichTextEditor"]},
    {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["RichTextEditor"]},
    {label:"blur", value:"blur", yes:["RichTextEditor"]},
    {label:"change", value:"change", yes:["RichTextEditor"]},
    {label:"created", value:"created", yes:["RichTextEditor"]},
    {label:"destroyed", value:"destroyed", yes:["RichTextEditor"]},
    {label:"dialogClose", value:"dialogClose", yes:["RichTextEditor"]},
    {label:"dialogOpen", value:"dialogOpen", yes:["RichTextEditor"]},
    {label:"fileRemoving", value:"fileRemoving", yes:["RichTextEditor"]},
    {label:"fileSelected", value:"fileSelected", yes:["RichTextEditor"]},
    {label:"fileUploadFailed", value:"fileUploadFailed", yes:["RichTextEditor"]},
    {label:"fileUploadSuccess", value:"fileUploadSuccess", yes:["RichTextEditor"]},
    {label:"fileUploading", value:"fileUploading", yes:["RichTextEditor"]},
    {label:"focus", value:"focus", yes:["RichTextEditor"]},
    {label:"imageRemoving", value:"imageRemoving", yes:["RichTextEditor"]},
    {label:"imageSelected", value:"imageSelected", yes:["RichTextEditor"]},
    {label:"imageUploadFailed", value:"imageUploadFailed", yes:["RichTextEditor"]},
    {label:"imageUploadSuccess", value:"imageUploadSuccess", yes:["RichTextEditor"]},
    {label:"imageUploading", value:"imageUploading", yes:["RichTextEditor"]},
    {label:"quickToolbarClose", value:"quickToolbarClose", yes:["RichTextEditor"]},
    {label:"quickToolbarOpen", value:"quickToolbarOpen", yes:["RichTextEditor"]},
    {label:"resizeStart", value:"resizeStart", yes:["RichTextEditor"]},
    {label:"resizeStop", value:"resizeStop", yes:["RichTextEditor"]},
    {label:"resizing", value:"resizing", yes:["RichTextEditor"]},
    {label:"toolbarClick", value:"toolbarClick", yes:["RichTextEditor"]},
    {label:"toolbarStatusUpdate", value:"toolbarStatusUpdate", yes:["RichTextEditor"]},
    {label:"updatedToolbarStatus", value:"updatedToolbarStatus", yes:["RichTextEditor"]},

    // Schedule

    {label:"actionBegin", value:"actionBegin", yes:["Scheduler"]},
    {label:"actionComplete", value:"actionComplete", yes:["Scheduler"]},
    {label:"actionFailure", value:"actionFailure", yes:["Scheduler"]},
    {label:"cellClick", value:"cellClick", yes:["Scheduler"]},
    {label:"cellDoubleClick", value:"cellDoubleClick", yes:["Scheduler"]},
    {label:"created", value:"created", yes:["Scheduler"]},
    {label:"dataBinding", value:"dataBinding", yes:["Scheduler"]},
    {label:"dataBound", value:"dataBound", yes:["Scheduler"]},
    {label:"destroyed", value:"destroyed", yes:["Scheduler"]},
    {label:"drag", value:"drag", yes:["Scheduler"]},
    {label:"dragStart", value:"dragStart", yes:["Scheduler"]},
    {label:"dragStop", value:"dragStop", yes:["Scheduler"]},
    {label:"eventClick", value:"eventClick", yes:["Scheduler"]},
    {label:"eventRendered", value:"eventRendered", yes:["Scheduler"]},
    {label:"hover", value:"hover", yes:["Scheduler"]},
    {label:"moreEventsClick", value:"moreEventsClick", yes:["Scheduler"]},
    {label:"navigating", value:"navigating", yes:["Scheduler"]},
    {label:"popupClose", value:"popupClose", yes:["Scheduler"]},
    {label:"popupOpen", value:"popupOpen", yes:["Scheduler"]},
    {label:"renderCell", value:"renderCell", yes:["Scheduler"]},
    {label:"resizeStart", value:"resizeStart", yes:["Scheduler"]},
    {label:"resizeStop", value:"resizeStop", yes:["Scheduler"]},
    {label:"resizing", value:"resizing", yes:["Scheduler"]},
    {label:"select", value:"select", yes:["Scheduler"]},
    // Sidebar

    {label:"change", value:"change", yes:["Sidebar"]},
    {label:"close", value:"close", yes:["Sidebar"]},
    {label:"created", value:"created", yes:["Sidebar"]},
    {label:"destroyed", value:"destroyed", yes:["Sidebar"]},
    {label:"open", value:"open", yes:["Sidebar"]},
    // Signature

{label:"beforeSave", value:"beforeSave", yes:["Signature"]},
{label:"change", value:"change", yes:["Signature"]},
{label:"created", value:"created", yes:["Signature"]},
    // Skeleton
    {label:"destroy", value:"destroy", yes:["Skeleton"]},

    // Slider
    {label:"change", value:"change", yes:["Slider"]},
    {label:"changed", value:"changed", yes:["Slider"]},
    {label:"created", value:"created", yes:["Slider"]},
    {label:"renderedTicks", value:"renderedTicks", yes:["Slider"]},
    {label:"renderingTicks", value:"renderingTicks", yes:["Slider"]},
    {label:"tooltipChange", value:"tooltipChange", yes:["Slider"]},

    // Smith Chart
    {label:"loaded", value:"loaded",yes:["SmithChart"]},
    {label:"seriesRender", value:"seriesRender",yes:["SmithChart"]},
    {label:"subtitleRender", value:"subtitleRender",yes:["SmithChart"]},
    {label:"textRender", value:"textRender",yes:["SmithChart"]},
    {label:"titleRender", value:"titleRender",yes:["SmithChart"]},
    {label:"tooltipRender", value:"tooltipRender",yes:["SmithChart"]},

    // Sparkline
    {label:"axisRendering", value:"axisRendering",yes:["SparkLine"]},
    {label:"dataLabelRendering", value:"dataLabelRendering",yes:["SparkLine"]},
    {label:"load", value:"load",yes:["SparkLine"]},
    {label:"loaded", value:"loaded",yes:["SparkLine"]},
    {label:"markerRendering", value:"markerRendering",yes:["SparkLine"]},
    {label:"pointRegionMouseClick", value:"pointRegionMouseClick",yes:["SparkLine"]},
    {label:"pointRegionMouseMove", value:"pointRegionMouseMove",yes:["SparkLine"]},
    {label:"pointRendering", value:"pointRendering",yes:["SparkLine"]},
    {label:"resize", value:"resize",yes:["SparkLine"]},
    {label:"seriesRendering", value:"seriesRendering",yes:["SparkLine"]},
    {label:"sparklineMouseClick", value:"sparklineMouseClick",yes:["SparkLine"]},
    {label:"sparklineMouseMove", value:"sparklineMouseMove",yes:["SparkLine"]},
    {label:"tooltipInitialize", value:"tooltipInitialize",yes:["SparkLine"]},

    // Speed Dial
    {label:"beforeClose", value:"beforeClose", yes:["SpeedDial"]},
    {label:"beforeItemRender", value:"beforeItemRender", yes:["SpeedDial"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["SpeedDial"]},
    {label:"clicked", value:"clicked", yes:["SpeedDial"]},
    {label:"created", value:"created", yes:["SpeedDial"]},
    {label:"onClose", value:"onClose", yes:["SpeedDial"]},
    {label:"onOpen", value:"onOpen", yes:["SpeedDial"]},

    // SplitButton
    {label:"beforeClose", value:"beforeClose", yes:["SplitButton"]},
    {label:"beforeItemRender", value:"beforeItemRender", yes:["SplitButton"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["SplitButton"]},
    {label:"click", value:"click", yes:["SplitButton"]},
    {label:"close", value:"close", yes:["SplitButton"]},
    {label:"created", value:"created", yes:["SplitButton"]},
    {label:"open", value:"open", yes:["SplitButton"]},
    {label:"select", value:"select", yes:["SplitButton"]},

    // Splitter
    {label:"beforeCollapse", value:"beforeCollapse", yes:["Splitter"]},
    {label:"beforeExpand", value:"beforeExpand", yes:["Splitter"]},
    {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["Splitter"]},
    {label:"collapsed", value:"collapsed", yes:["Splitter"]},
    {label:"created", value:"created", yes:["Splitter"]},
    {label:"expanded", value:"expanded", yes:["Splitter"]},
    {label:"resizeStart", value:"resizeStart", yes:["Splitter"]},
    {label:"resizeStop", value:"resizeStop", yes:["Splitter"]},
    {label:"resizing", value:"resizing", yes:["Splitter"]},

    // Spreadsheet
    {label:"actionBegin", value:"actionBegin", yes:["Spreadsheet"]},
    {label:"actionComplete", value:"actionComplete", yes:["Spreadsheet"]},
    {label:"afterHyperlinkClick", value:"afterHyperlinkClick", yes:["Spreadsheet"]},
    {label:"afterHyperlinkCreate", value:"afterHyperlinkCreate", yes:["Spreadsheet"]},
    {label:"beforeCellFormat", value:"beforeCellFormat", yes:["Spreadsheet"]},
    {label:"beforeCellRender", value:"beforeCellRender", yes:["Spreadsheet"]},
    {label:"beforeCellSave", value:"beforeCellSave", yes:["Spreadsheet"]},
    {label:"beforeCellUpdate", value:"beforeCellUpdate", yes:["Spreadsheet"]},
    {label:"beforeConditionalFormat", value:"beforeConditionalFormat", yes:["Spreadsheet"]},
    {label:"beforeDataBound", value:"beforeDataBound", yes:["Spreadsheet"]},
    {label:"beforeHyperlinkClick", value:"beforeHyperlinkClick", yes:["Spreadsheet"]},
    {label:"beforeHyperlinkCreate", value:"beforeHyperlinkCreate", yes:["Spreadsheet"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["Spreadsheet"]},
    {label:"beforeSave", value:"beforeSave", yes:["Spreadsheet"]},
    {label:"beforeSelect", value:"beforeSelect", yes:["Spreadsheet"]},
    {label:"beforeSort", value:"beforeSort", yes:["Spreadsheet"]},
    {label:"cellEdit", value:"cellEdit", yes:["Spreadsheet"]},
    {label:"cellEditing", value:"cellEditing", yes:["Spreadsheet"]},
    {label:"cellSave", value:"cellSave", yes:["Spreadsheet"]},
    {label:"contextMenuBeforeClose", value:"contextMenuBeforeClose", yes:["Spreadsheet"]},
    {label:"contextMenuBeforeOpen", value:"contextMenuBeforeOpen", yes:["Spreadsheet"]},
    {label:"contextMenuItemSelect", value:"contextMenuItemSelect", yes:["Spreadsheet"]},
    {label:"created", value:"created", yes:["Spreadsheet"]},
    {label:"dataBound", value:"dataBound", yes:["Spreadsheet"]},
    {label:"dataSourceChanged", value:"dataSourceChanged", yes:["Spreadsheet"]},
    {label:"dialogBeforeOpen", value:"dialogBeforeOpen", yes:["Spreadsheet"]},
    {label:"fileMenuBeforeClose", value:"fileMenuBeforeClose", yes:["Spreadsheet"]},
    {label:"fileMenuBeforeOpen", value:"fileMenuBeforeOpen", yes:["Spreadsheet"]},
    {label:"fileMenuItemSelect", value:"fileMenuItemSelect", yes:["Spreadsheet"]},
    {label:"openComplete", value:"openComplete", yes:["Spreadsheet"]},
    {label:"openFailure", value:"openFailure", yes:["Spreadsheet"]},
    {label:"queryCellInfo", value:"queryCellInfo", yes:["Spreadsheet"]},
    {label:"saveComplete", value:"saveComplete", yes:["Spreadsheet"]},
    {label:"select", value:"select", yes:["Spreadsheet"]},
    {label:"sortComplete", value:"sortComplete", yes:["Spreadsheet"]},

    // Stock Chart
    {label:"axisLabelRender", value:"axisLabelRender", yes:["StockChart"]},
    {label:"legendClick", value:"legendClick", yes:["StockChart"]},
    {label:"legendRender", value:"legendRender", yes:["StockChart"]},
    {label:"load", value:"load", yes:["StockChart"]},
    {label:"loaded", value:"loaded", yes:["StockChart"]},
    {label:"onZooming", value:"onZooming", yes:["StockChart"]},
    {label:"pointClick", value:"pointClick", yes:["StockChart"]},
    {label:"pointMove", value:"pointMove", yes:["StockChart"]},
    {label:"rangeChange", value:"rangeChange", yes:["StockChart"]},
    {label:"selectorRender", value:"selectorRender", yes:["StockChart"]},
    {label:"seriesRender", value:"seriesRender", yes:["StockChart"]},
    {label:"stockChartMouseClick", value:"stockChartMouseClick", yes:["StockChart"]},
    {label:"stockChartMouseDown", value:"stockChartMouseDown", yes:["StockChart"]},
    {label:"stockChartMouseLeave", value:"stockChartMouseLeave", yes:["StockChart"]},
    {label:"stockChartMouseMove", value:"stockChartMouseMove", yes:["StockChart"]},
    {label:"stockChartMouseUp", value:"stockChartMouseUp", yes:["StockChart"]},
    {label:"stockEventRender", value:"stockEventRender", yes:["StockChart"]},
    {label:"tooltipRender", value:"tooltipRender", yes:["StockChart"]},

    // Switch

    {label:"change", value:"change", yes:["Switch"]},
    {label:"created", value:"created", yes:["Switch"]},
    // Symbol Palette
    {label:"paletteExpanding", value:"paletteExpanding", yes:["SymbolPalette"]},
    {label:"paletteSelectionChange", value:"paletteSelectionChange", yes:["SymbolPalette"]},

    // Tabs
    {label:"added", value:"added", yes:["Tabs"]},
    {label:"adding", value:"adding", yes:["Tabs"]},
    {label:"created", value:"created", yes:["Tabs"]},
    {label:"destroyed", value:"destroyed", yes:["Tabs"]},
    {label:"dragged", value:"dragged", yes:["Tabs"]},
    {label:"dragging", value:"dragging", yes:["Tabs"]},
    {label:"onDragStart", value:"onDragStart", yes:["Tabs"]},
    {label:"removed", value:"removed", yes:["Tabs"]},
    {label:"removing", value:"removing", yes:["Tabs"]},
    {label:"selected", value:"selected", yes:["Tabs"]},
    {label:"selecting", value:"selecting", yes:["Tabs"]},

    // TextBox
    {label:"blur", value:"blur", yes:["TextBox"]},
    {label:"change", value:"change", yes:["TextBox"]},
    {label:"created", value:"created", yes:["TextBox"]},
    {label:"destroyed", value:"destroyed", yes:["TextBox"]},
    {label:"focus", value:"focus", yes:["TextBox"]},
    {label:"input", value:"input", yes:["TextBox"]},

    // TimePicker
    {label:"blur", value:"blur", yes:["TimePicker"]},
    {label:"change", value:"change", yes:["TimePicker"]},
    {label:"cleared", value:"cleared", yes:["TimePicker"]},
    {label:"close", value:"close", yes:["TimePicker"]},
    {label:"created", value:"created", yes:["TimePicker"]},
    {label:"destroyed", value:"destroyed", yes:["TimePicker"]},
    {label:"focus", value:"focus", yes:["TimePicker"]},
    {label:"itemRender", value:"itemRender", yes:["TimePicker"]},
    {label:"open", value:"open", yes:["TimePicker"]},

    // Toast
    {label:"beforeClose", value:"beforeClose", yes:["Toast"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["Toast"]},
    {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["Toast"]},
    {label:"click", value:"click", yes:["Toast"]},
    {label:"close", value:"close", yes:["Toast"]},
    {label:"created", value:"created", yes:["Toast"]},
    {label:"destroyed", value:"destroyed", yes:["Toast"]},
    {label:"open", value:"open", yes:["Toast"]},

    // Toolbar
    {label:"beforeCreate", value:"beforeCreate", yes:["Toolbar"]},
    {label:"clicked", value:"clicked", yes:["Toolbar"]},
    {label:"created", value:"created", yes:["Toolbar"]},
    {label:"destroyed", value:"destroyed", yes:["Toolbar"]},

    // Tooltip
{label:"afterClose", value:"afterClose", yes:["Tooltip"]},
{label:"afterOpen", value:"afterOpen", yes:["Tooltip"]},
{label:"beforeClose", value:"beforeClose", yes:["Tooltip"]},
{label:"beforeCollision", value:"beforeCollision", yes:["Tooltip"]},
{label:"beforeOpen", value:"beforeOpen", yes:["Tooltip"]},
{label:"beforeRender", value:"beforeRender", yes:["Tooltip"]},
{label:"created", value:"created", yes:["Tooltip"]},
{label:"destroyed", value:"destroyed", yes:["Tooltip"]},

    // TreeGrid
    {label:"actionBegin", value:"actionBegin", yes:["TreeGrid"]},
    {label:"actionComplete", value:"actionComplete", yes:["TreeGrid"]},
    {label:"actionFailure", value:"actionFailure", yes:["TreeGrid"]},
    {label:"batchAdd", value:"batchAdd", yes:["TreeGrid"]},
    {label:"batchCancel", value:"batchCancel", yes:["TreeGrid"]},
    {label:"batchDelete", value:"batchDelete", yes:["TreeGrid"]},
    {label:"beforeBatchAdd", value:"beforeBatchAdd", yes:["TreeGrid"]},
    {label:"beforeBatchDelete", value:"beforeBatchDelete", yes:["TreeGrid"]},
    {label:"beforeBatchSave", value:"beforeBatchSave", yes:["TreeGrid"]},
    {label:"beforeCopy", value:"beforeCopy", yes:["TreeGrid"]},
    {label:"beforeDataBound", value:"beforeDataBound", yes:["TreeGrid"]},
    {label:"beforeExcelExport", value:"beforeExcelExport", yes:["TreeGrid"]},
    {label:"beforePaste", value:"beforePaste", yes:["TreeGrid"]},
    {label:"beforePdfExport", value:"beforePdfExport", yes:["TreeGrid"]},
    {label:"beforePrint", value:"beforePrint", yes:["TreeGrid"]},
    {label:"beginEdit", value:"beginEdit", yes:["TreeGrid"]},
    {label:"cellDeselected", value:"cellDeselected", yes:["TreeGrid"]},
    {label:"cellDeselecting", value:"cellDeselecting", yes:["TreeGrid"]},
    {label:"cellEdit", value:"cellEdit", yes:["TreeGrid"]},
    {label:"cellSave", value:"cellSave", yes:["TreeGrid"]},
    {label:"cellSaved", value:"cellSaved", yes:["TreeGrid"]},
    {label:"cellSelected", value:"cellSelected", yes:["TreeGrid"]},
    {label:"cellSelecting", value:"cellSelecting", yes:["TreeGrid"]},
    {label:"checkboxChange", value:"checkboxChange", yes:["TreeGrid"]},
    {label:"collapsed", value:"collapsed", yes:["TreeGrid"]},
    {label:"collapsing", value:"collapsing", yes:["TreeGrid"]},
    {label:"columnDrag", value:"columnDrag", yes:["TreeGrid"]},
    {label:"columnDragStart", value:"columnDragStart", yes:["TreeGrid"]},
    {label:"columnDrop", value:"columnDrop", yes:["TreeGrid"]},
    {label:"columnMenuClick", value:"columnMenuClick", yes:["TreeGrid"]},
    {label:"columnMenuOpen", value:"columnMenuOpen", yes:["TreeGrid"]},
    {label:"contextMenuClick", value:"contextMenuClick", yes:["TreeGrid"]},
    {label:"contextMenuOpen", value:"contextMenuOpen", yes:["TreeGrid"]},
    {label:"created", value:"created", yes:["TreeGrid"]},
    {label:"dataBound", value:"dataBound", yes:["TreeGrid"]},
    {label:"dataSourceChanged", value:"dataSourceChanged", yes:["TreeGrid"]},
    {label:"dataStateChange", value:"dataStateChange", yes:["TreeGrid"]},
    {label:"detailDataBound", value:"detailDataBound", yes:["TreeGrid"]},
    {label:"excelExportComplete", value:"excelExportComplete", yes:["TreeGrid"]},
    {label:"excelHeaderQueryCellInfo", value:"excelHeaderQueryCellInfo", yes:["TreeGrid"]},
    {label:"excelQueryCellInfo", value:"excelQueryCellInfo", yes:["TreeGrid"]},
    {label:"expanded", value:"expanded", yes:["TreeGrid"]},
    {label:"expanding", value:"expanding", yes:["TreeGrid"]},
    {label:"headerCellInfo", value:"headerCellInfo", yes:["TreeGrid"]},
    {label:"load", value:"load", yes:["TreeGrid"]},
    {label:"pdfExportComplete", value:"pdfExportComplete", yes:["TreeGrid"]},
    {label:"pdfHeaderQueryCellInfo", value:"pdfHeaderQueryCellInfo", yes:["TreeGrid"]},
    {label:"pdfQueryCellInfo", value:"pdfQueryCellInfo", yes:["TreeGrid"]},
    {label:"printComplete", value:"printComplete", yes:["TreeGrid"]},
    {label:"queryCellInfo", value:"queryCellInfo", yes:["TreeGrid"]},
    {label:"recordDoubleClick", value:"recordDoubleClick", yes:["TreeGrid"]},
    {label:"resizeStart", value:"resizeStart", yes:["TreeGrid"]},
    {label:"resizeStop", value:"resizeStop", yes:["TreeGrid"]},
    {label:"resizing", value:"resizing", yes:["TreeGrid"]},
    {label:"rowDataBound", value:"rowDataBound", yes:["TreeGrid"]},
    {label:"rowDeselected", value:"rowDeselected", yes:["TreeGrid"]},
    {label:"rowDeselecting", value:"rowDeselecting", yes:["TreeGrid"]},
    {label:"rowDrag", value:"rowDrag", yes:["TreeGrid"]},
    {label:"rowDragStart", value:"rowDragStart", yes:["TreeGrid"]},
    {label:"rowDragStartHelper", value:"rowDragStartHelper", yes:["TreeGrid"]},
    {label:"rowDrop", value:"rowDrop", yes:["TreeGrid"]},
    {label:"rowSelected", value:"rowSelected", yes:["TreeGrid"]},
    {label:"rowSelecting", value:"rowSelecting", yes:["TreeGrid"]},
    {label:"toolbarClick", value:"toolbarClick", yes:["TreeGrid"]},

    // TreeMap

    {label:"beforePrint", value:"beforePrint", yes:["TreeMap"]},
    {label:"click", value:"click", yes:["TreeMap"]},
    {label:"doubleClick", value:"doubleClick", yes:["TreeMap"]},
    {label:"drillEnd", value:"drillEnd", yes:["TreeMap"]},
    {label:"drillStart", value:"drillStart", yes:["TreeMap"]},
    {label:"itemClick", value:"itemClick", yes:["TreeMap"]},
    {label:"itemHighlight", value:"itemHighlight", yes:["TreeMap"]},
    {label:"itemMove", value:"itemMove", yes:["TreeMap"]},
    {label:"itemRendering", value:"itemRendering", yes:["TreeMap"]},
    {label:"itemSelected", value:"itemSelected", yes:["TreeMap"]},
    {label:"legendItemRendering", value:"legendItemRendering", yes:["TreeMap"]},
    {label:"legendRendering", value:"legendRendering", yes:["TreeMap"]},
    {label:"load", value:"load", yes:["TreeMap"]},
    {label:"loaded", value:"loaded", yes:["TreeMap"]},
    {label:"mouseMove", value:"mouseMove", yes:["TreeMap"]},
    {label:"resize", value:"resize", yes:["TreeMap"]},
    {label:"rightClick", value:"rightClick", yes:["TreeMap"]},
    {label:"tooltipRendering", value:"tooltipRendering", yes:["TreeMap"]},
    // TreeView
    {label:"actionFailure",value:"actionFailure", yes:["TreeView"]},
    {label:"created",value:"created", yes:["TreeView"]},
    {label:"dataBound",value:"dataBound", yes:["TreeView"]},
    {label:"dataSourceChanged",value:"dataSourceChanged", yes:["TreeView"]},
    {label:"destroyed",value:"destroyed", yes:["TreeView"]},
    {label:"drawNode",value:"drawNode", yes:["TreeView"]},
    {label:"keyPress",value:"keyPress", yes:["TreeView"]},
    {label:"nodeChecked",value:"nodeChecked", yes:["TreeView"]},
    {label:"nodeChecking",value:"nodeChecking", yes:["TreeView"]},
    {label:"nodeClicked",value:"nodeClicked", yes:["TreeView"]},
    {label:"nodeCollapsed",value:"nodeCollapsed", yes:["TreeView"]},
    {label:"nodeCollapsing",value:"nodeCollapsing", yes:["TreeView"]},
    {label:"nodeDragStart",value:"nodeDragStart", yes:["TreeView"]},
    {label:"nodeDragStop",value:"nodeDragStop", yes:["TreeView"]},
    {label:"nodeDragging",value:"nodeDragging", yes:["TreeView"]},
    {label:"nodeDropped",value:"nodeDropped", yes:["TreeView"]},
    {label:"nodeEdited",value:"nodeEdited", yes:["TreeView"]},
    {label:"nodeEditing",value:"nodeEditing", yes:["TreeView"]},
    {label:"nodeExpanded",value:"nodeExpanded", yes:["TreeView"]},
    {label:"nodeExpanding",value:"nodeExpanding", yes:["TreeView"]},
    {label:"nodeSelected",value:"nodeSelected", yes:["TreeView"]},
    {label:"nodeSelecting",value:"nodeSelecting", yes:["TreeView"]},

    // Uploader
    {label:"actionComplete", value:"actionComplete", yes:["Uploader"]},
    {label:"beforeRemove", value:"beforeRemove", yes:["Uploader"]},
    {label:"beforeUpload", value:"beforeUpload", yes:["Uploader"]},
    {label:"canceling", value:"canceling", yes:["Uploader"]},
    {label:"change", value:"change", yes:["Uploader"]},
    {label:"chunkFailure", value:"chunkFailure", yes:["Uploader"]},
    {label:"chunkSuccess", value:"chunkSuccess", yes:["Uploader"]},
    {label:"chunkUploading", value:"chunkUploading", yes:["Uploader"]},
    {label:"clearing", value:"clearing", yes:["Uploader"]},
    {label:"created", value:"created", yes:["Uploader"]},
    {label:"failure", value:"failure", yes:["Uploader"]},
    {label:"fileListRendering", value:"fileListRendering", yes:["Uploader"]},
    {label:"pausing", value:"pausing", yes:["Uploader"]},
    {label:"progress", value:"progress", yes:["Uploader"]},
    {label:"removing", value:"removing", yes:["Uploader"]},
    {label:"rendering", value:"rendering", yes:["Uploader"]},
    {label:"resuming", value:"resuming", yes:["Uploader"]},
    {label:"selected", value:"selected", yes:["Uploader"]},
    {label:"success", value:"success", yes:["Uploader"]},
    {label:"uploading", value:"uploading", yes:["Uploader"]},


    // layout
      // {label:"onFocus", value: "onFocus",},
      // {label:"onFocusCapture", value: "onFocusCapture"},
      // {label:"onBlur", value: "onBlur"},
      // {label:"onBlurCapture", value: "onBlurCapture"},
      // {label:"onChange", value: "onChange"},
      // {label:"onChangeCapture", value: "onChangeCapture"},
      // {label:"onSubmit", value: "onSubmit"},
      // {label:"onSubmitCapture", value: "onSubmitCapture"},
      // {label:"onLoad", value: "onLoad"},
      // {label:"onKeyDown", value: "onKey Down"},
      // {label:"onKeyDownCapture", value: "onKeyDownCapture"},
      // {label:"onKeyPress", value: "onKeyPress"},
      // {label:"onKeyPressCapture", value: "onKeyPressCapture"},
      // {label:"onKeyUp", value: "onKey Up"},
      // {label:"onKeyUpCapture", value: "onKeyUpCapture"},   
      // {label:"onClick", value: "onClick"},
      // {label:"onClickCapture", value: "onClickCapture"},
      // {label:"onContext Menu", value: "onContextMenu"},
      // {label:"onContext MenuCapture", value: "onContextMenuCapture"},
      // {label:"onDouble Click", value: "onDoubleClick"},
      // {label:"onDouble ClickCapture", value: "onDoubleClickCapture"},
      // {label:"onDrag", value: "onDrag"},
      // {label:"onDragCapture", value: "onDragCapture"},
      // {label:"onDragEnd", value: "onDragEnd"},
      // {label:"onDragEndCapture", value: "onDragEndCapture"},
      // {label:"onDragEnter", value: "onDragEnter"},
      // {label:"onDragEnterCapture", value: "onDragEnterCapture"},
      // {label:"onDragExit", value: "onDragExit"},
      // {label:"onDragExitCapture", value: "onDragExitCapture"},
      // {label:"onDragLeave", value: "onDragLeave"},
      // {label:"onDragLeaveCapture", value: "onDragLeaveCapture"},
      // {label:"onDragOver", value: "onDragOver"},
      // {label:"onDragOverCapture", value: "onDragOverCapture"},
      // {label:"onDragStart", value: "onDragStart"},
      // {label:"onDragStartCapture", value: "onDragStartCapture"},
      // {label:"onDrop", value: "onDrop"},
      // {label:"onDropCapture", value: "onDropCapture"},
      // {label:"onMouseDown", value: "onMouseDown"},
      // {label:"onMouseDownCapture", value: "onMouseDownCapture"},
      // {label:"onMouseEnter", value: "onMouseEnter"},
      // {label:"onMouseLeave", value: "onMouseLeave"},
      // {label:"onMouseMove", value: "onMouseMove"},
      // {label:"onMouseMoveCapture", value: "onMouseMoveCapture"},
      // {label:"onMouseOut", value: "onMouseOut"},
      // {label:"onMouseOutCapture", value: "onMouseOutCapture"},
      // {label:"onMouseOver", value: "onMouseOver"},
      // {label:"onMouseOverCapture", value: "onMouseOverCapture"},
      // {label:"onMouseUp", value: "onMouseUp"},
      // {label:"onMouseUpCapture", value: "onMouseUpCapture"},
      // {label:"onSelect", value: "onSelect"},
      // {label:"onSelectCapture", value: "onSelectCapture"},
      // {label:"onTouchCancel", value: "onTouchCancel"},
      // {label:"onTouchCancelCapture", value: "onTouchCancelCapture"},
      // {label:"onTouchEnd", value: "onTouchEnd"},
      // {label:"onTouchEndCapture", value: "onTouchEndCapture"},
      // {label:"onTouchMove", value: "onTouchMove"},
      // {label:"onTouchMoveCapture", value: "onTouchMoveCapture"},
      // {label:"onTouchStart", value: "onTouchStart"},
      // {label:"onTouchStartCapture", value: "onTouchStartCapture"},
    ])

    useEffect(() => {
      let el = [
      
        // Accordion
    
        {label:"clicked", value:"clicked", yes:["Accordion"]},
        {label:"created", value:"created", yes:["Accordion"]},
        {label:"destroyed", value:"destroyed", yes:["Accordion"]},
        {label:"expanded", value:"expanded", yes:["Accordion"]},
        {label:"expanding", value:"expanding", yes:["Accordion"]},
    
    
        // Accumulation Chart
        {label:"afterExport", value:"afterExport", yes:["AccumulationChart"]},
        {label:"animationComplete", value:"animationComplete", yes:["AccumulationChart"]},
        {label:"annotationRender", value:"annotationRender", yes:["AccumulationChart"]},
        {label:"beforePrint", value:"beforePrint", yes:["AccumulationChart"]},
        {label:"beforeResize", value:"beforeResize", yes:["AccumulationChart"]},
        {label:"chartDoubleClick", value:"chartDoubleClick", yes:["AccumulationChart"]},
        {label:"chartMouseClick", value:"chartMouseClick", yes:["AccumulationChart"]},
        {label:"chartMouseDown", value:"chartMouseDown", yes:["AccumulationChart"]},
        {label:"chartMouseLeave", value:"chartMouseLeave", yes:["AccumulationChart"]},
        {label:"chartMouseMove", value:"chartMouseMove", yes:["AccumulationChart"]},
        {label:"chartMouseUp", value:"chartMouseUp", yes:["AccumulationChart"]},
        {label:"legendClick", value:"legendClick", yes:["AccumulationChart"]},
        {label:"legendRender", value:"legendRender", yes:["AccumulationChart"]},
        {label:"load", value:"load", yes:["AccumulationChart"]},
        {label:"loaded", value:"loaded", yes:["AccumulationChart"]},
        {label:"pointClick", value:"pointClick", yes:["AccumulationChart"]},
        {label:"pointMove", value:"pointMove", yes:["AccumulationChart"]},
        {label:"pointRender", value:"pointRender", yes:["AccumulationChart"]},
        {label:"resized", value:"resized", yes:["AccumulationChart"]},
        {label:"selectionComplete", value:"selectionComplete", yes:["AccumulationChart"]},
        {label:"seriesRender", value:"seriesRender", yes:["AccumulationChart"]},
        {label:"textRender", value:"textRender", yes:["AccumulationChart"]},
        {label:"tooltipRender", value:"tooltipRender", yes:["AccumulationChart"]},
    
        // Appbar
    
        {label:"created",  value:"created", yes:["Appbar"]},
        {label:"destroyed",  value:"destroyed", yes:["Appbar"]},
        // AutoComplete
    
    {label:"actionBegin", value:"actionBegin", yes:["AutoComplete"]},
    {label:"actionComplete", value:"actionComplete", yes:["AutoComplete"]},
    {label:"actionFailure", value:"actionFailure", yes:["AutoComplete"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["AutoComplete"]},
    {label:"blur", value:"blur", yes:["AutoComplete"]},
    {label:"change", value:"change", yes:["AutoComplete"]},
    {label:"close", value:"close", yes:["AutoComplete"]},
    {label:"created", value:"created", yes:["AutoComplete"]},
    {label:"customValueSpecifier", value:"customValueSpecifier", yes:["AutoComplete"]},
    {label:"dataBound", value:"dataBound", yes:["AutoComplete"]},
    {label:"destroyed", value:"destroyed", yes:["AutoComplete"]},
    {label:"filtering", value:"filtering", yes:["AutoComplete"]},
    {label:"focus", value:"focus", yes:["AutoComplete"]},
    {label:"open", value:"open", yes:["AutoComplete"]},
    {label:"select", value:"select", yes:["AutoComplete"]},
    
        // Barcode
    
        {label:"invalid", value:"invalid", yes:["Barcode"]},
    
        // Breadcrumb
    
      {label:"beforeItemRender", value:"beforeItemRender", yes:["Breadcrumb"]},
      {label:"created", value:"created", yes:["Breadcrumb"]},
      {label:"itemClick", value:"itemClick", yes:["Breadcrumb"]},
    
        // Bullet Chart
    
        {label:"beforePrint", value:"beforePrint", yes:["BulletChart"]},
        {label:"bulletChartMouseClick", value:"bulletChartMouseClick", yes:["BulletChart"]},
        {label:"legendRender", value:"legendRender", yes:["BulletChart"]},
        {label:"load", value:"load", yes:["BulletChart"]},
        {label:"loaded", value:"loaded", yes:["BulletChart"]},
        {label:"tooltipRender", value:"tooltipRender", yes:["BulletChart"]},
    
        // Button
    
    {label:"created", value:"created", yes:["Button"]},
    
        // Calendar
    
        {label:"change", value:"change", yes:["Calendar"]},
    {label:"created", value:"created", yes:["Calendar"]},
    {label:"destroyed", value:"destroyed", yes:["Calendar"]},
    {label:"navigated", value:"navigated", yes:["Calendar"]},
    {label:"renderDayCell", value:"renderDayCell", yes:["Calendar"]},
    
        // Carousel
        {label: "slideChanged", value:"slideChanged", yes:["Carousel"]},
        {label: "slideChanging", value:"slideChanging", yes:["Carousel"]},
    
        // Chart
    
        {label:"afterExport", value:"afterExport", yes:["Chart"]},
        {label:"animationComplete", value:"animationComplete", yes:["Chart"]},
        {label:"annotationRender", value:"annotationRender", yes:["Chart"]},
        {label:"axisLabelClick", value:"axisLabelClick", yes:["Chart"]},
        {label:"axisLabelRender", value:"axisLabelRender", yes:["Chart"]},
        {label:"axisMultiLabelRender", value:"axisMultiLabelRender", yes:["Chart"]},
        {label:"axisRangeCalculated", value:"axisRangeCalculated", yes:["Chart"]},
        {label:"beforeExport", value:"beforeExport", yes:["Chart"]},
        {label:"beforePrint", value:"beforePrint", yes:["Chart"]},
        {label:"beforeResize", value:"beforeResize", yes:["Chart"]},
        {label:"chartDoubleClick", value:"chartDoubleClick", yes:["Chart"]},
        {label:"chartMouseClick", value:"chartMouseClick", yes:["Chart"]},
        {label:"chartMouseDown", value:"chartMouseDown", yes:["Chart"]},
        {label:"chartMouseLeave", value:"chartMouseLeave", yes:["Chart"]},
        {label:"chartMouseMove", value:"chartMouseMove", yes:["Chart"]},
        {label:"chartMouseUp", value:"chartMouseUp", yes:["Chart"]},
        {label:"drag", value:"drag", yes:["Chart"]},
        {label:"dragComplete", value:"dragComplete", yes:["Chart"]},
        {label:"dragEnd", value:"dragEnd", yes:["Chart"]},
        {label:"dragStart", value:"dragStart", yes:["Chart"]},
        {label:"legendClick", value:"legendClick", yes:["Chart"]},
        {label:"legendRender", value:"legendRender", yes:["Chart"]},
        {label:"load", value:"load", yes:["Chart"]},
        {label:"loaded", value:"loaded", yes:["Chart"]},
        {label:"multiLevelLabelClick", value:"multiLevelLabelClick", yes:["Chart"]},
        {label:"onZooming", value:"onZooming", yes:["Chart"]},
        {label:"pointClick", value:"pointClick", yes:["Chart"]},
        {label:"pointDoubleClick", value:"pointDoubleClick", yes:["Chart"]},
        {label:"pointMove", value:"pointMove", yes:["Chart"]},
        {label:"pointRender", value:"pointRender", yes:["Chart"]},
        {label:"resized", value:"resized", yes:["Chart"]},
        {label:"scrollChanged", value:"scrollChanged", yes:["Chart"]},
        {label:"scrollEnd", value:"scrollEnd", yes:["Chart"]},
        {label:"scrollStart", value:"scrollStart", yes:["Chart"]},
        {label:"selectionComplete", value:"selectionComplete", yes:["Chart"]},
        {label:"seriesRender", value:"seriesRender", yes:["Chart"]},
        {label:"sharedTooltipRender", value:"sharedTooltipRender", yes:["Chart"]},
        {label:"textRender", value:"textRender", yes:["Chart"]},
        {label:"tooltipRender", value:"tooltipRender", yes:["Chart"]},
        {label:"zoomComplete", value:"zoomComplete", yes:["Chart"]},
        // CheckBox
        {label:"change", value:"change", yes:["change"]},
        {label:"created", value:"created", yes:["created"]},
    
        // Chips
    
        {label:"beforeClick", value:"beforeClick", yes:["Chips"]},
        {label:"click", value:"click", yes:["Chips"]},
        {label:"created", value:"created", yes:["Chips"]},
        {label:"delete", value:"delete", yes:["Chips"]},
        {label:"deleted", value:"deleted", yes:["Chips"]},
        // Circular Gauge
    
        {label:"animationComplete", value:"animationComplete", yes:["CircularGauge"]},
        {label:"annotationRender", value:"annotationRender", yes:["CircularGauge"]},
        {label:"axisLabelRender", value:"axisLabelRender", yes:["CircularGauge"]},
        {label:"beforePrint", value:"beforePrint", yes:["CircularGauge"]},
        {label:"dragEnd", value:"dragEnd", yes:["CircularGauge"]},
        {label:"dragMove", value:"dragMove", yes:["CircularGauge"]},
        {label:"dragStart", value:"dragStart", yes:["CircularGauge"]},
        {label:"gaugeMouseDown", value:"gaugeMouseDown", yes:["CircularGauge"]},
        {label:"gaugeMouseLeave", value:"gaugeMouseLeave", yes:["CircularGauge"]},
        {label:"gaugeMouseMove", value:"gaugeMouseMove", yes:["CircularGauge"]},
        {label:"gaugeMouseUp", value:"gaugeMouseUp", yes:["CircularGauge"]},
        {label:"legendRender", value:"legendRender", yes:["CircularGauge"]},
        {label:"load", value:"load", yes:["CircularGauge"]},
        {label:"loaded", value:"loaded", yes:["CircularGauge"]},
        {label:"radiusCalculate", value:"radiusCalculate", yes:["CircularGauge"]},
        {label:"resized", value:"resized", yes:["CircularGauge"]},
        {label:"tooltipRender", value:"tooltipRender", yes:["CircularGauge"]},
    
        // ColorPicker
        
        {label:"beforeClose", value:"beforeClose", yes:["beforeClose"]},
        {label:"beforeModeSwitch", value:"beforeModeSwitch", yes:["beforeModeSwitch"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["beforeOpen"]},
        {label:"beforeTileRender", value:"beforeTileRender", yes:["beforeTileRender"]},
        {label:"change", value:"change", yes:["change"]},
        {label:"created", value:"created", yes:["created"]},
        {label:"onModeSwitch", value:"onModeSwitch", yes:["onModeSwitch"]},
        {label:"open", value:"open", yes:["open"]},
        {label:"select", value:"select", yes:["select"]},
    
        // ComboBox
    
        {label:"actionBegin", value:"actionBegin", yes:["ComboBox"]},
        {label:"actionComplete", value:"actionComplete", yes:["ComboBox"]},
        {label:"actionFailure", value:"actionFailure", yes:["ComboBox"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["ComboBox"]},
        {label:"blur", value:"blur", yes:["ComboBox"]},
        {label:"change", value:"change", yes:["ComboBox"]},
        {label:"close", value:"close", yes:["ComboBox"]},
        {label:"created", value:"created", yes:["ComboBox"]},
        {label:"customValueSpecifier", value:"customValueSpecifier", yes:["ComboBox"]},
        {label:"dataBound", value:"dataBound", yes:["ComboBox"]},
        {label:"destroyed", value:"destroyed", yes:["ComboBox"]},
        {label:"filtering", value:"filtering", yes:["ComboBox"]},
        {label:"focus", value:"focus", yes:["ComboBox"]},
        {label:"open", value:"open", yes:["ComboBox"]},
        {label:"select", value:"select", yes:["ComboBox"]},
        // ContextMenu
    
        {label:"beforeClose", value:"beforeClose", yes:["ContextMenu"]},
        {label:"beforeItemRender", value:"beforeItemRender", yes:["ContextMenu"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["ContextMenu"]},
        {label:"created", value:"created", yes:["ContextMenu"]},
        {label:"onClose", value:"onClose", yes:["ContextMenu"]},
        {label:"onOpen", value:"onOpen", yes:["ContextMenu"]},
        {label:"select", value:"select", yes:["ContextMenu"]},
        // Dashboard Layout
    
        {label:"change", value:"change", yes:["DashboardLayout"]},
        {label:"created", value:"created", yes:["DashboardLayout"]},
        {label:"destroyed", value:"destroyed", yes:["DashboardLayout"]},
        {label:"drag", value:"drag", yes:["DashboardLayout"]},
        {label:"dragStart", value:"dragStart", yes:["DashboardLayout"]},
        {label:"dragStop", value:"dragStop", yes:["DashboardLayout"]},
        {label:"resize", value:"resize", yes:["DashboardLayout"]},
        {label:"resizeStart", value:"resizeStart", yes:["DashboardLayout"]},
        {label:"resizeStop", value:"resizeStop", yes:["DashboardLayout"]},
        // DatePicker
    
        {label:"blur", value:"blur", yes:["DatePicker"]},
        {label:"change", value:"change", yes:["DatePicker"]},
        {label:"cleared", value:"cleared", yes:["DatePicker"]},
        {label:"close", value:"close", yes:["DatePicker"]},
        {label:"created", value:"created", yes:["DatePicker"]},
        {label:"destroyed", value:"destroyed", yes:["DatePicker"]},
        {label:"focus", value:"focus", yes:["DatePicker"]},
        {label:"navigated", value:"navigated", yes:["DatePicker"]},
        {label:"open", value:"open", yes:["DatePicker"]},
        {label:"renderDayCell", value:"renderDayCell", yes:["DatePicker"]},
    
        // DateRangePicker
        
        {label:"blur", value:"blur", yes:["DateRangePicker"]},
    {label:"change", value:"change", yes:["DateRangePicker"]},
    {label:"cleared", value:"cleared", yes:["DateRangePicker"]},
    {label:"close", value:"close", yes:["DateRangePicker"]},
    {label:"created", value:"created", yes:["DateRangePicker"]},
    {label:"destroyed", value:"destroyed", yes:["DateRangePicker"]},
    {label:"focus", value:"focus", yes:["DateRangePicker"]},
    {label:"navigated", value:"navigated", yes:["DateRangePicker"]},
    {label:"open", value:"open", yes:["DateRangePicker"]},
    {label:"renderDayCell", value:"renderDayCell", yes:["DateRangePicker"]},
    {label:"select", value:"select", yes:["DateRangePicker"]},
    
        // DateTimePicker
    
        {label:"blur", value:"blur", yes:["DateTimePicker"]},
        {label:"change", value:"change", yes:["DateTimePicker"]},
        {label:"cleared", value:"cleared", yes:["DateTimePicker"]},
        {label:"close", value:"close", yes:["DateTimePicker"]},
        {label:"created", value:"created", yes:["DateTimePicker"]},
        {label:"destroyed", value:"destroyed", yes:["DateTimePicker"]},
        {label:"focus", value:"focus", yes:["DateTimePicker"]},
        {label:"navigated", value:"navigated", yes:["DateTimePicker"]},
        {label:"open", value:"open", yes:["DateTimePicker"]},
        {label:"renderDayCell", value:"renderDayCell", yes:["DateTimePicker"]},
    
        // Diagram
    
        {label:"animationComplete", value:"animationComplete", yes:["Diagram"]},
        {label:"click", value:"click", yes:["Diagram"]},
        {label:"collectionChange", value:"collectionChange", yes:["Diagram"]},
        {label:"commandExecute", value:"commandExecute", yes:["Diagram"]},
        {label:"connectionChange", value:"connectionChange", yes:["Diagram"]},
        {label:"contextMenuBeforeItemRender", value:"contextMenuBeforeItemRender", yes:["Diagram"]},
        {label:"contextMenuClick", value:"contextMenuClick", yes:["Diagram"]},
        {label:"contextMenuOpen", value:"contextMenuOpen", yes:["Diagram"]},
        {label:"created", value:"created", yes:["Diagram"]},
        {label:"dataLoaded", value:"dataLoaded", yes:["Diagram"]},
        {label:"doubleClick", value:"doubleClick", yes:["Diagram"]},
        {label:"dragEnter", value:"dragEnter", yes:["Diagram"]},
        {label:"dragLeave", value:"dragLeave", yes:["Diagram"]},
        {label:"dragOver", value:"dragOver", yes:["Diagram"]},
        {label:"drop", value:"drop", yes:["Diagram"]},
        {label:"expandStateChange", value:"expandStateChange", yes:["Diagram"]},
        {label:"fixedUserHandleClick", value:"fixedUserHandleClick", yes:["Diagram"]},
        {label:"historyChange", value:"historyChange", yes:["Diagram"]},
        {label:"historyStateChange", value:"historyStateChange", yes:["Diagram"]},
        {label:"keyDown", value:"keyDown", yes:["Diagram"]},
        {label:"keyUp", value:"keyUp", yes:["Diagram"]},
        {label:"mouseEnter", value:"mouseEnter", yes:["Diagram"]},
        {label:"mouseLeave", value:"mouseLeave", yes:["Diagram"]},
        {label:"mouseOver", value:"mouseOver", yes:["Diagram"]},
        {label:"mouseWheel", value:"mouseWheel", yes:["Diagram"]},
        {label:"onImageLoad", value:"onImageLoad", yes:["Diagram"]},
        {label:"onUserHandleMouseDown", value:"onUserHandleMouseDown", yes:["Diagram"]},
        {label:"onUserHandleMouseEnter", value:"onUserHandleMouseEnter", yes:["Diagram"]},
        {label:"onUserHandleMouseLeave", value:"onUserHandleMouseLeave", yes:["Diagram"]},
        {label:"onUserHandleMouseUp", value:"onUserHandleMouseUp", yes:["Diagram"]},
        {label:"positionChange", value:"positionChange", yes:["Diagram"]},
        {label:"propertyChange", value:"propertyChange", yes:["Diagram"]},
        {label:"rotateChange", value:"rotateChange", yes:["Diagram"]},
        {label:"scrollChange", value:"scrollChange", yes:["Diagram"]},
        {label:"segmentCollectionChange", value:"segmentCollectionChange", yes:["Diagram"]},
        {label:"selectionChange", value:"selectionChange", yes:["Diagram"]},
        {label:"sizeChange", value:"sizeChange", yes:["Diagram"]},
        {label:"sourcePointChange", value:"sourcePointChange", yes:["Diagram"]},
        {label:"targetPointChange", value:"targetPointChange", yes:["Diagram"]},
        {label:"textEdit    ", value:"textEdit", yes:["Diagram"]},
    
        // Dialog
        {label:"beforeClose", value:"beforeClose", yes:["Dialog"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["Dialog"]},
        {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["Dialog"]},
        {label:"close", value:"close", yes:["Dialog"]},
        {label:"created", value:"created", yes:["Dialog"]},
        {label:"destroyed", value:"destroyed", yes:["Dialog"]},
        {label:"drag", value:"drag", yes:["Dialog"]},
        {label:"dragStart", value:"dragStart", yes:["Dialog"]},
        {label:"dragStop", value:"dragStop", yes:["Dialog"]},
        {label:"open", value:"open", yes:["Dialog"]},
        {label:"overlayClick", value:"overlayClick", yes:["Dialog"]},
        {label:"resizeStart", value:"resizeStart", yes:["Dialog"]},
        {label:"resizeStop", value:"resizeStop", yes:["Dialog"]},
        {label:"resizing", value:"resizing", yes:["Dialog"]},
    
        // Document Editor Container
        {label:"beforeCommentAction", value:"beforeCommentAction", yes:["DocumentEditorContainer"]},
        {label:"beforePaneSwitch", value:"beforePaneSwitch", yes:["DocumentEditorContainer"]},
        {label:"commentDelete", value:"commentDelete", yes:["DocumentEditorContainer"]},
        {label:"contentChange", value:"contentChange", yes:["DocumentEditorContainer"]},
        {label:"contentControl", value:"contentControl", yes:["DocumentEditorContainer"]},
        {label:"created", value:"created", yes:["DocumentEditorContainer"]},
        {label:"customContextMenuBeforeOpen", value:"customContextMenuBeforeOpen", yes:["DocumentEditorContainer"]},
        {label:"customContextMenuSelect", value:"customContextMenuSelect", yes:["DocumentEditorContainer"]},
        {label:"destroyed", value:"destroyed", yes:["DocumentEditorContainer"]},
        {label:"documentChange", value:"documentChange", yes:["DocumentEditorContainer"]},
        {label:"selectionChange", value:"selectionChange", yes:["DocumentEditorContainer"]},
        {label:"serviceFailure", value:"serviceFailure", yes:["DocumentEditorContainer"]},
        {label:"toolbarClick", value:"toolbarClick", yes:["DocumentEditorContainer"]},
        {label:"trackChange", value:"trackChange", yes:["DocumentEditorContainer"]},
    
        // DocumentEditor
    
        {label:"actionComplete", value:"actionComplete", yes:["DocumentEditor"]},
        {label:"afterFormFieldFill", value:"afterFormFieldFill", yes:["DocumentEditor"]},
        {label:"beforeCommentAction", value:"beforeCommentAction", yes:["DocumentEditor"]},
        {label:"beforeFileOpen", value:"beforeFileOpen", yes:["DocumentEditor"]},
        {label:"beforeFormFieldFill", value:"beforeFormFieldFill", yes:["DocumentEditor"]},
        {label:"beforePaneSwitch", value:"beforePaneSwitch", yes:["DocumentEditor"]},
        {label:"commentBegin", value:"commentBegin", yes:["DocumentEditor"]},
        {label:"commentDelete", value:"commentDelete", yes:["DocumentEditor"]},
        {label:"commentEnd", value:"commentEnd", yes:["DocumentEditor"]},
        {label:"contentChange", value:"contentChange", yes:["DocumentEditor"]},
        {label:"contentControl", value:"contentControl", yes:["DocumentEditor"]},
        {label:"created", value:"created", yes:["DocumentEditor"]},
        {label:"customContextMenuBeforeOpen", value:"customContextMenuBeforeOpen", yes:["DocumentEditor"]},
        {label:"customContextMenuSelect", value:"customContextMenuSelect", yes:["DocumentEditor"]},
        {label:"destroyed", value:"destroyed", yes:["DocumentEditor"]},
        {label:"documentChange", value:"documentChange", yes:["DocumentEditor"]},
        {label:"keyDown", value:"keyDown", yes:["DocumentEditor"]},
        {label:"requestNavigate", value:"requestNavigate", yes:["DocumentEditor"]},
        {label:"searchResultsChange", value:"searchResultsChange", yes:["DocumentEditor"]},
        {label:"selectionChange", value:"selectionChange", yes:["DocumentEditor"]},
        {label:"serviceFailure", value:"serviceFailure", yes:["DocumentEditor"]},
        {label:"trackChange", value:"trackChange", yes:["DocumentEditor"]},
        {label:"viewChange", value:"viewChange", yes:["DocumentEditor"]},
        {label:"zoomFactorChange", value:"zoomFactorChange", yes:["DocumentEditor"]},
        // DropDownButton
        {label:"beforeClose", value:"beforeClose", yes:["DropDownButton"]},
        {label:"beforeItemRender", value:"beforeItemRender", yes:["DropDownButton"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["DropDownButton"]},
        {label:"close", value:"close", yes:["DropDownButton"]},
        {label:"created", value:"created", yes:["DropDownButton"]},
        {label:"open", value:"open", yes:["DropDownButton"]},
        {label:"select", value:"select", yes:["DropDownButton"]},
    
        // DropDownList
    
        {label:"actionBegin", value:"actionBegin", yes:["DropDownList"]},
    {label:"actionComplete", value:"actionComplete", yes:["DropDownList"]},
    {label:"actionFailure", value:"actionFailure", yes:["DropDownList"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["DropDownList"]},
    {label:"blur", value:"blur", yes:["DropDownList"]},
    {label:"change", value:"change", yes:["DropDownList"]},
    {label:"close", value:"close", yes:["DropDownList"]},
    {label:"created", value:"created", yes:["DropDownList"]},
    {label:"dataBound", value:"dataBound", yes:["DropDownList"]},
    {label:"destroyed", value:"destroyed", yes:["DropDownList"]},
    {label:"filtering", value:"filtering", yes:["DropDownList"]},
    {label:"focus", value:"focus", yes:["DropDownList"]},
    {label:"open", value:"open", yes:["DropDownList"]},
    {label:"select", value:"select", yes:["DropDownList"]},
    
        // Dropdown Tree
        {label:"actionFailure", value:"actionFailure", yes:["actionFailure"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["beforeOpen"]},
        {label:"blur", value:"blur", yes:["blur"]},
        {label:"change", value:"change", yes:["change"]},
        {label:"close", value:"close", yes:["close"]},
        {label:"created", value:"created", yes:["created"]},
        {label:"dataBound", value:"dataBound", yes:["dataBound"]},
        {label:"destroyed", value:"destroyed", yes:["destroyed"]},
        {label:"filtering", value:"filtering", yes:["filtering"]},
        {label:"focus", value:"focus", yes:["focus"]},
        {label:"keyPress", value:"keyPress", yes:["keyPress"]},
        {label:"open", value:"open", yes:["open"]},
        {label:"select", value:"select", yes:["select"]},
    
        // FileManager
    {label:"beforeDownload", value:"beforeDownload", yes:["FileManager"]},
    {label:"beforeImageLoad", value:"beforeImageLoad", yes:["FileManager"]},
    {label:"beforePopupClose", value:"beforePopupClose", yes:["FileManager"]},
    {label:"beforePopupOpen", value:"beforePopupOpen", yes:["FileManager"]},
    {label:"beforeSend", value:"beforeSend", yes:["FileManager"]},
    {label:"created", value:"created", yes:["FileManager"]},
    {label:"destroyed", value:"destroyed", yes:["FileManager"]},
    {label:"failure", value:"failure", yes:["FileManager"]},
    {label:"fileDragStart", value:"fileDragStart", yes:["FileManager"]},
    {label:"fileDragStop", value:"fileDragStop", yes:["FileManager"]},
    {label:"fileDragging", value:"fileDragging", yes:["FileManager"]},
    {label:"fileDropped", value:"fileDropped", yes:["FileManager"]},
    {label:"fileLoad", value:"fileLoad", yes:["FileManager"]},
    {label:"fileOpen", value:"fileOpen", yes:["FileManager"]},
    {label:"fileSelect", value:"fileSelect", yes:["FileManager"]},
    {label:"fileSelection", value:"fileSelection", yes:["FileManager"]},
    {label:"menuClick", value:"menuClick", yes:["FileManager"]},
    {label:"menuOpen", value:"menuOpen", yes:["FileManager"]},
    {label:"popupClose", value:"popupClose", yes:["FileManager"]},
    {label:"popupOpen", value:"popupOpen", yes:["FileManager"]},
    {label:"success", value:"success", yes:["FileManager"]},
    {label:"toolbarClick", value:"toolbarClick", yes:["FileManager"]},
    {label:"toolbarCreate", value:"toolbarCreate", yes:["FileManager"]},
    {label:"uploadListCreate", value:"uploadListCreate", yes:["FileManager"]},
    
        // Floating Action Button
    
    
        // Gantt
        {label:"actionBegin", value:"actionBegin", yes:["Gantt"]},
        {label:"actionComplete", value:"actionComplete", yes:["Gantt"]},
        {label:"actionFailure", value:"actionFailure", yes:["Gantt"]},
        {label:"beforeExcelExport", value:"beforeExcelExport", yes:["Gantt"]},
        {label:"beforePdfExport", value:"beforePdfExport", yes:["Gantt"]},
        {label:"beforeTooltipRender", value:"beforeTooltipRender", yes:["Gantt"]},
        {label:"cellDeselected", value:"cellDeselected", yes:["Gantt"]},
        {label:"cellDeselecting", value:"cellDeselecting", yes:["Gantt"]},
        {label:"cellEdit", value:"cellEdit", yes:["Gantt"]},
        {label:"cellSelected", value:"cellSelected", yes:["Gantt"]},
        {label:"cellSelecting", value:"cellSelecting", yes:["Gantt"]},
        {label:"collapsed", value:"collapsed", yes:["Gantt"]},
        {label:"collapsing", value:"collapsing", yes:["Gantt"]},
        {label:"columnDrag", value:"columnDrag", yes:["Gantt"]},
        {label:"columnDragStart", value:"columnDragStart", yes:["Gantt"]},
        {label:"columnDrop", value:"columnDrop", yes:["Gantt"]},
        {label:"columnMenuClick", value:"columnMenuClick", yes:["Gantt"]},
        {label:"columnMenuOpen", value:"columnMenuOpen", yes:["Gantt"]},
        {label:"contextMenuClick", value:"contextMenuClick", yes:["Gantt"]},
        {label:"contextMenuOpen", value:"contextMenuOpen", yes:["Gantt"]},
        {label:"created", value:"created", yes:["Gantt"]},
        {label:"dataBound", value:"dataBound", yes:["Gantt"]},
        {label:"dataStateChange", value:"dataStateChange", yes:["Gantt"]},
        {label:"destroyed", value:"destroyed", yes:["Gantt"]},
        {label:"endEdit", value:"endEdit", yes:["Gantt"]},
        {label:"excelExportComplete", value:"excelExportComplete", yes:["Gantt"]},
        {label:"excelHeaderQueryCellInfo", value:"excelHeaderQueryCellInfo", yes:["Gantt"]},
        {label:"excelQueryCellInfo", value:"excelQueryCellInfo", yes:["Gantt"]},
        {label:"expanded", value:"expanded", yes:["Gantt"]},
        {label:"expanding", value:"expanding", yes:["Gantt"]},
        {label:"headerCellInfo", value:"headerCellInfo", yes:["Gantt"]},
        {label:"load", value:"load", yes:["Gantt"]},
        {label:"onMouseMove", value:"onMouseMove", yes:["Gantt"]},
        {label:"onTaskbarClick", value:"onTaskbarClick", yes:["Gantt"]},
        {label:"pdfColumnHeaderQueryCellInfo", value:"pdfColumnHeaderQueryCellInfo", yes:["Gantt"]},
        {label:"pdfExportComplete", value:"pdfExportComplete", yes:["Gantt"]},
        {label:"pdfQueryCellInfo", value:"pdfQueryCellInfo", yes:["Gantt"]},
        {label:"pdfQueryTaskbarInfo", value:"pdfQueryTaskbarInfo", yes:["Gantt"]},
        {label:"pdfQueryTimelineCellInfo", value:"pdfQueryTimelineCellInfo", yes:["Gantt"]},
        {label:"queryCellInfo", value:"queryCellInfo", yes:["Gantt"]},
        {label:"queryTaskbarInfo", value:"queryTaskbarInfo", yes:["Gantt"]},
        {label:"recordDoubleClick", value:"recordDoubleClick", yes:["Gantt"]},
        {label:"resizeStart", value:"resizeStart", yes:["Gantt"]},
        {label:"resizeStop", value:"resizeStop", yes:["Gantt"]},
        {label:"resizing", value:"resizing", yes:["Gantt"]},
        {label:"rowDataBound", value:"rowDataBound", yes:["Gantt"]},
        {label:"rowDeselected", value:"rowDeselected", yes:["Gantt"]},
        {label:"rowDeselecting", value:"rowDeselecting", yes:["Gantt"]},
        {label:"rowDrag", value:"rowDrag", yes:["Gantt"]},
        {label:"rowDragStart", value:"rowDragStart", yes:["Gantt"]},
        {label:"rowDragStartHelper", value:"rowDragStartHelper", yes:["Gantt"]},
        {label:"rowDrop", value:"rowDrop", yes:["Gantt"]},
        {label:"rowSelected", value:"rowSelected", yes:["Gantt"]},
        {label:"rowSelecting", value:"rowSelecting", yes:["Gantt"]},
        {label:"splitterResizeStart", value:"splitterResizeStart", yes:["Gantt"]},
        {label:"splitterResized", value:"splitterResized", yes:["Gantt"]},
        {label:"splitterResizing", value:"splitterResizing", yes:["Gantt"]},
        {label:"taskbarEdited", value:"taskbarEdited", yes:["Gantt"]},
        {label:"taskbarEditing", value:"taskbarEditing", yes:["Gantt"]},
        {label:"toolbarClick", value:"toolbarClick", yes:["Gantt"]},
    
        // Grid
        {label:"actionBegin", value:"actionBegin", yes:["Grid"]},
        {label:"actionComplete", value:"actionComplete", yes:["Grid"]},
        {label:"actionFailure", value:"actionFailure", yes:["Grid"]},
        {label:"batchAdd", value:"batchAdd", yes:["Grid"]},
        {label:"batchCancel", value:"batchCancel", yes:["Grid"]},
        {label:"batchDelete", value:"batchDelete", yes:["Grid"]},
        {label:"beforeAutoFill", value:"beforeAutoFill", yes:["Grid"]},
        {label:"beforeBatchAdd", value:"beforeBatchAdd", yes:["Grid"]},
        {label:"beforeBatchDelete", value:"beforeBatchDelete", yes:["Grid"]},
        {label:"beforeBatchSave", value:"beforeBatchSave", yes:["Grid"]},
        {label:"beforeCopy", value:"beforeCopy", yes:["Grid"]},
        {label:"beforeDataBound", value:"beforeDataBound", yes:["Grid"]},
        {label:"beforeExcelExport", value:"beforeExcelExport", yes:["Grid"]},
        {label:"beforeOpenAdaptiveDialog", value:"beforeOpenAdaptiveDialog", yes:["Grid"]},
        {label:"beforeOpenColumnChooser", value:"beforeOpenColumnChooser", yes:["Grid"]},
        {label:"beforePaste", value:"beforePaste", yes:["Grid"]},
        {label:"beforePdfExport", value:"beforePdfExport", yes:["Grid"]},
        {label:"beforePrint", value:"beforePrint", yes:["Grid"]},
        {label:"beginEdit", value:"beginEdit", yes:["Grid"]},
        {label:"cellDeselected", value:"cellDeselected", yes:["Grid"]},
        {label:"cellDeselecting", value:"cellDeselecting", yes:["Grid"]},
        {label:"cellEdit", value:"cellEdit", yes:["Grid"]},
        {label:"cellSave", value:"cellSave", yes:["Grid"]},
        {label:"cellSaved", value:"cellSaved", yes:["Grid"]},
        {label:"cellSelected", value:"cellSelected", yes:["Grid"]},
        {label:"cellSelecting", value:"cellSelecting", yes:["Grid"]},
        {label:"checkBoxChange", value:"checkBoxChange", yes:["Grid"]},
        {label:"columnDataStateChange", value:"columnDataStateChange", yes:["Grid"]},
        {label:"columnDeselected", value:"columnDeselected", yes:["Grid"]},
        {label:"columnDeselecting", value:"columnDeselecting", yes:["Grid"]},
        {label:"columnDrag", value:"columnDrag", yes:["Grid"]},
        {label:"columnDragStart", value:"columnDragStart", yes:["Grid"]},
        {label:"columnDrop", value:"columnDrop", yes:["Grid"]},
        {label:"columnMenuClick", value:"columnMenuClick", yes:["Grid"]},
        {label:"columnMenuOpen", value:"columnMenuOpen", yes:["Grid"]},
        {label:"columnSelected", value:"columnSelected", yes:["Grid"]},
        {label:"columnSelecting", value:"columnSelecting", yes:["Grid"]},
        {label:"commandClick", value:"commandClick", yes:["Grid"]},
        {label:"contextMenuClick", value:"contextMenuClick", yes:["Grid"]},
        {label:"contextMenuOpen", value:"contextMenuOpen", yes:["Grid"]},
        {label:"created", value:"created", yes:["Grid"]},
        {label:"dataBound", value:"dataBound", yes:["Grid"]},
        {label:"dataSourceChanged", value:"dataSourceChanged", yes:["Grid"]},
        {label:"dataStateChange", value:"dataStateChange", yes:["Grid"]},
        {label:"destroyed", value:"destroyed", yes:["Grid"]},
        {label:"detailDataBound", value:"detailDataBound", yes:["Grid"]},
        {label:"excelAggregateQueryCellInfo", value:"excelAggregateQueryCellInfo", yes:["Grid"]},
        {label:"excelExportComplete", value:"excelExportComplete", yes:["Grid"]},
        {label:"excelHeaderQueryCellInfo", value:"excelHeaderQueryCellInfo", yes:["Grid"]},
        {label:"excelQueryCellInfo", value:"excelQueryCellInfo", yes:["Grid"]},
        {label:"exportDetailDataBound", value:"exportDetailDataBound", yes:["Grid"]},
        {label:"exportGroupCaption", value:"exportGroupCaption", yes:["Grid"]},
        {label:"headerCellInfo", value:"headerCellInfo", yes:["Grid"]},
        {label:"keyPressed", value:"keyPressed", yes:["Grid"]},
        {label:"lazyLoadGroupCollapse", value:"lazyLoadGroupCollapse", yes:["Grid"]},
        {label:"lazyLoadGroupExpand", value:"lazyLoadGroupExpand", yes:["Grid"]},
        {label:"load", value:"load", yes:["Grid"]},
        {label:"pdfAggregateQueryCellInfo", value:"pdfAggregateQueryCellInfo", yes:["Grid"]},
        {label:"pdfExportComplete", value:"pdfExportComplete", yes:["Grid"]},
        {label:"pdfHeaderQueryCellInfo", value:"pdfHeaderQueryCellInfo", yes:["Grid"]},
        {label:"pdfQueryCellInfo", value:"pdfQueryCellInfo", yes:["Grid"]},
        {label:"printComplete", value:"printComplete", yes:["Grid"]},
        {label:"queryCellInfo", value:"queryCellInfo", yes:["Grid"]},
        {label:"recordClick", value:"recordClick", yes:["Grid"]},
        {label:"recordDoubleClick", value:"recordDoubleClick", yes:["Grid"]},
        {label:"resizeStart", value:"resizeStart", yes:["Grid"]},
        {label:"resizeStop", value:"resizeStop", yes:["Grid"]},
        {label:"resizing", value:"resizing", yes:["Grid"]},
        {label:"rowDataBound", value:"rowDataBound", yes:["Grid"]},
        {label:"rowDeselected", value:"rowDeselected", yes:["Grid"]},
        {label:"rowDeselecting", value:"rowDeselecting", yes:["Grid"]},
        {label:"rowDrag", value:"rowDrag", yes:["Grid"]},
        {label:"rowDragStart", value:"rowDragStart", yes:["Grid"]},
        {label:"rowDragStartHelper", value:"rowDragStartHelper", yes:["Grid"]},
        {label:"rowDrop", value:"rowDrop", yes:["Grid"]},
        {label:"rowSelected", value:"rowSelected", yes:["Grid"]},
        {label:"rowSelecting", value:"rowSelecting", yes:["Grid"]},
        {label:"toolbarClick", value:"toolbarClick", yes:["Grid"]},
    
        // HeatMap
        {label:"cellClick", value:"cellClick", yes:["HeatMap"]},
        {label:"cellRender", value:"cellRender", yes:["HeatMap"]},
        {label:"cellSelected", value:"cellSelected", yes:["HeatMap"]},
        {label:"created", value:"created", yes:["HeatMap"]},
        {label:"legendRender", value:"legendRender", yes:["HeatMap"]},
        {label:"load", value:"load", yes:["HeatMap"]},
        {label:"loaded", value:"loaded", yes:["HeatMap"]},
        {label:"resized", value:"resized", yes:["HeatMap"]},
        {label:"tooltipRender", value:"tooltipRender", yes:["HeatMap"]},
    
        // Image Editor
        {label:"beforeSave", value:"beforeSave", yes:["ImageEditor"]},
        {label:"created",value:"created", yes:["ImageEditor"]},
        {label:"cropping",value:"cropping", yes:["ImageEditor"]},
        {label:"destroyed",value:"destroyed", yes:["ImageEditor"]},
        {label:"fileOpened",value:"fileOpened", yes:["ImageEditor"]},
        {label:"finetuneValueChanging",value:"finetuneValueChanging", yes:["ImageEditor"]},
        {label:"flipping",value:"flipping", yes:["ImageEditor"]},
        {label:"imageFiltering",value:"imageFiltering", yes:["ImageEditor"]},
        {label:"panning",value:"panning", yes:["ImageEditor"]},
        {label:"rotating",value:"rotating", yes:["ImageEditor"]},
        {label:"saved",value:"saved", yes:["ImageEditor"]},
        {label:"shapeChanging",value:"shapeChanging", yes:["ImageEditor"]},
        {label:"toolbarCreated",value:"toolbarCreated", yes:["ImageEditor"]},
        {label:"toolbarItemClicked",value:"toolbarItemClicked", yes:["ImageEditor"]},
        {label:"toolbarUpdating",value:"toolbarUpdating", yes:["ImageEditor"]},
        {label:"zooming",value:"zooming", yes:["ImageEditor"]},
    
        // In-place Editor
        {label:"actionBegin", value:"actionBegin", yes:["InPlaceEditor"]},
        {label:"actionFailure", value:"actionFailure", yes:["InPlaceEditor"]},
        {label:"actionSuccess", value:"actionSuccess", yes:["InPlaceEditor"]},
        {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["InPlaceEditor"]},
        {label:"beginEdit", value:"beginEdit", yes:["InPlaceEditor"]},
        {label:"cancelClick", value:"cancelClick", yes:["InPlaceEditor"]},
        {label:"change", value:"change", yes:["InPlaceEditor"]},
        {label:"created", value:"created", yes:["InPlaceEditor"]},
        {label:"destroyed", value:"destroyed", yes:["InPlaceEditor"]},
        {label:"endEdit", value:"endEdit", yes:["InPlaceEditor"]},
        {label:"submitClick", value:"submitClick", yes:["InPlaceEditor"]},
        {label:"validating", value:"validating", yes:["InPlaceEditor"]},
    
        // Kanban
    
        {label:"actionBegin", value:"actionBegin", yes:["Kanban"]},
    {label:"actionComplete", value:"actionComplete", yes:["Kanban"]},
    {label:"actionFailure", value:"actionFailure", yes:["Kanban"]},
    {label:"cardClick", value:"cardClick", yes:["Kanban"]},
    {label:"cardDoubleClick", value:"cardDoubleClick", yes:["Kanban"]},
    {label:"cardRendered", value:"cardRendered", yes:["Kanban"]},
    {label:"created", value:"created", yes:["Kanban"]},
    {label:"dataBinding", value:"dataBinding", yes:["Kanban"]},
    {label:"dataBound", value:"dataBound", yes:["Kanban"]},
    {label:"dataSourceChanged", value:"dataSourceChanged", yes:["Kanban"]},
    {label:"dataStateChange", value:"dataStateChange", yes:["Kanban"]},
    {label:"dialogClose", value:"dialogClose", yes:["Kanban"]},
    {label:"dialogOpen", value:"dialogOpen", yes:["Kanban"]},
    {label:"drag", value:"drag", yes:["Kanban"]},
    {label:"dragStart", value:"dragStart", yes:["Kanban"]},
    {label:"dragStop", value:"dragStop", yes:["Kanban"]},
    {label:"queryCellInfo", value:"queryCellInfo", yes:["Kanban"]},
    
        // Linear Gauge
    {label:"animationComplete", value:"animationComplete",yes:["LinearGauge"]},
    {label:"annotationRender", value:"annotationRender",yes:["LinearGauge"]},
    {label:"axisLabelRender", value:"axisLabelRender",yes:["LinearGauge"]},
    {label:"beforePrint", value:"beforePrint",yes:["LinearGauge"]},
    {label:"dragEnd", value:"dragEnd",yes:["LinearGauge"]},
    {label:"dragMove", value:"dragMove",yes:["LinearGauge"]},
    {label:"dragStart", value:"dragStart",yes:["LinearGauge"]},
    {label:"gaugeMouseDown", value:"gaugeMouseDown",yes:["LinearGauge"]},
    {label:"gaugeMouseLeave", value:"gaugeMouseLeave",yes:["LinearGauge"]},
    {label:"gaugeMouseMove", value:"gaugeMouseMove",yes:["LinearGauge"]},
    {label:"gaugeMouseUp", value:"gaugeMouseUp",yes:["LinearGauge"]},
    {label:"load", value:"load",yes:["LinearGauge"]},
    {label:"loaded", value:"loaded",yes:["LinearGauge"]},
    {label:"resized", value:"resized",yes:["LinearGauge"]},
    {label:"tooltipRender", value:"tooltipRender",yes:["LinearGauge"]},
    {label:"valueChange", value:"valueChange",yes:["LinearGauge"]},
    
        // ListBox
        {label:"actionBegin", value:"actionBegin", yes:["ListBox"]},
        {label:"actionComplete", value:"actionComplete", yes:["ListBox"]},
        {label:"actionFailure", value:"actionFailure", yes:["ListBox"]},
        {label:"beforeDrop", value:"beforeDrop", yes:["ListBox"]},
        {label:"beforeItemRender", value:"beforeItemRender", yes:["ListBox"]},
        {label:"change", value:"change", yes:["ListBox"]},
        {label:"created", value:"created", yes:["ListBox"]},
        {label:"destroyed", value:"destroyed", yes:["ListBox"]},
        {label:"drag", value:"drag", yes:["ListBox"]},
        {label:"dragStart", value:"dragStart", yes:["ListBox"]},
        {label:"drop", value:"drop", yes:["ListBox"]},
        {label:"filtering", value:"filtering", yes:["ListBox"]},
    
        // ListView
    
        {label:"actionBegin", value:"actionBegin", yes:["ListView"]},
        {label:"actionComplete", value:"actionComplete", yes:["ListView"]},
        {label:"actionFailure", value:"actionFailure", yes:["ListView"]},
        {label:"select", value:"select", yes:["ListView"]},
        // Maps
    
        {label:"animationComplete", value:"animationComplete", yes:["Maps"]},
        {label:"annotationRendering", value:"annotationRendering", yes:["Maps"]},
        {label:"beforePrint", value:"beforePrint", yes:["Maps"]},
        {label:"bubbleClick", value:"bubbleClick", yes:["Maps"]},
        {label:"bubbleMouseMove", value:"bubbleMouseMove", yes:["Maps"]},
        {label:"bubbleRendering", value:"bubbleRendering", yes:["Maps"]},
        {label:"click", value:"click", yes:["Maps"]},
        {label:"dataLabelRendering", value:"dataLabelRendering", yes:["Maps"]},
        {label:"doubleClick", value:"doubleClick", yes:["Maps"]},
        {label:"itemHighlight", value:"itemHighlight", yes:["Maps"]},
        {label:"itemSelection", value:"itemSelection", yes:["Maps"]},
        {label:"layerRendering", value:"layerRendering", yes:["Maps"]},
        {label:"legendRendering", value:"legendRendering", yes:["Maps"]},
        {label:"load", value:"load", yes:["Maps"]},
        {label:"loaded", value:"loaded", yes:["Maps"]},
        {label:"markerClick", value:"markerClick", yes:["Maps"]},
        {label:"markerClusterClick", value:"markerClusterClick", yes:["Maps"]},
        {label:"markerClusterMouseMove", value:"markerClusterMouseMove", yes:["Maps"]},
        {label:"markerClusterRendering", value:"markerClusterRendering", yes:["Maps"]},
        {label:"markerMouseMove", value:"markerMouseMove", yes:["Maps"]},
        {label:"markerRendering", value:"markerRendering", yes:["Maps"]},
        {label:"onclick", value:"onclick", yes:["Maps"]},
        {label:"pan", value:"pan", yes:["Maps"]},
        {label:"resize", value:"resize", yes:["Maps"]},
        {label:"rightClick", value:"rightClick", yes:["Maps"]},
        {label:"shapeHighlight", value:"shapeHighlight", yes:["Maps"]},
        {label:"shapeRendering", value:"shapeRendering", yes:["Maps"]},
        {label:"shapeSelected", value:"shapeSelected", yes:["Maps"]},
        {label:"tooltipRender", value:"tooltipRender", yes:["Maps"]},
        {label:"tooltipRenderComplete", value:"tooltipRenderComplete", yes:["Maps"]},
        {label:"zoom", value:"zoom", yes:["Maps"]},
        // MaskedTextBox
        {label:"blur", value:"blur", yes:["MaskedTextBox"]},
        {label:"change", value:"change", yes:["MaskedTextBox"]},
        {label:"created", value:"created", yes:["MaskedTextBox"]},
        {label:"destroyed", value:"destroyed", yes:["MaskedTextBox"]},
        {label:"focus", value:"focus", yes:["MaskedTextBox"]},
    
        // Mention
        {label:"actionBegin", value:"actionBegin", yes:["Mention"]},
        {label:"actionComplete", value:"actionComplete", yes:["Mention"]},
        {label:"actionFailure", value:"actionFailure", yes:["Mention"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["Mention"]},
        {label:"change", value:"change", yes:["Mention"]},
        {label:"closed", value:"closed", yes:["Mention"]},
        {label:"created", value:"created", yes:["Mention"]},
        {label:"dataBound", value:"dataBound", yes:["Mention"]},
        {label:"destroyed", value:"destroyed", yes:["Mention"]},
        {label:"filtering", value:"filtering", yes:["Mention"]},
        {label:"opened", value:"opened", yes:["Mention"]},
        {label:"select", value:"select", yes:["Mention"]},
    
        // Menu Bar
        {label:"beforeClose", value:"beforeClose", yes:["Menu"]},
        {label:"beforeItemRender", value:"beforeItemRender", yes:["Menu"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["Menu"]},
        {label:"created", value:"created", yes:["Menu"]},
        {label:"onClose", value:"onClose", yes:["Menu"]},
        {label:"onOpen", value:"onOpen", yes:["Menu"]},
        {label:"select", value:"select", yes:["Menu"]},
    
        // Message
    
        {label:"closed", value:"closed", yes:["Message"]},
        {label:"created", value:"created", yes:["Message"]},
        {label:"destroyed", value:"destroyed", yes:["Message"]},
        // MultiSelect
        {label:"actionBegin", value:"actionBegin", yes:["MultiSelect"]},
        {label:"actionComplete", value:"actionComplete", yes:["MultiSelect"]},
        {label:"actionFailure", value:"actionFailure", yes:["MultiSelect"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["MultiSelect"]},
        {label:"beforeSelectAll", value:"beforeSelectAll", yes:["MultiSelect"]},
        {label:"blur", value:"blur", yes:["MultiSelect"]},
        {label:"change", value:"change", yes:["MultiSelect"]},
        {label:"chipSelection", value:"chipSelection", yes:["MultiSelect"]},
        {label:"close", value:"close", yes:["MultiSelect"]},
        {label:"created", value:"created", yes:["MultiSelect"]},
        {label:"customValueSelection", value:"customValueSelection", yes:["MultiSelect"]},
        {label:"dataBound", value:"dataBound", yes:["MultiSelect"]},
        {label:"destroyed", value:"destroyed", yes:["MultiSelect"]},
        {label:"filtering", value:"filtering", yes:["MultiSelect"]},
        {label:"focus", value:"focus", yes:["MultiSelect"]},
        {label:"open", value:"open", yes:["MultiSelect"]},
        {label:"removed", value:"removed", yes:["MultiSelect"]},
        {label:"removing", value:"removing", yes:["MultiSelect"]},
        {label:"select", value:"select", yes:["MultiSelect"]},
        {label:"selectedAll", value:"selectedAll", yes:["MultiSelect"]},
        {label:"tagging", value:"tagging", yes:["MultiSelect"]},
    
        // NumericTextBox
        {label:"blur", value:"blur", yes:["NumericTextBox"]},
        {label:"change", value:"change", yes:["NumericTextBox"]},
        {label:"created", value:"created", yes:["NumericTextBox"]},
        {label:"destroyed", value:"destroyed", yes:["NumericTextBox"]},
        {label:"focus", value:"focus", yes:["NumericTextBox"]},
    
        // Overview
    {label:"created", value:"created", yes:["Overview"]},
    
        // Pager
    
    
        // PDF Viewer
        {label:"addSignature", value:"addSignature", yes:["PDF"]},
        {label:"ajaxRequestFailed", value:"ajaxRequestFailed", yes:["PDF"]},
        {label:"ajaxRequestInitiate", value:"ajaxRequestInitiate", yes:["PDF"]},
        {label:"ajaxRequestSuccess", value:"ajaxRequestSuccess", yes:["PDF"]},
        {label:"annotationAdd", value:"annotationAdd", yes:["PDF"]},
        {label:"annotationDoubleClick", value:"annotationDoubleClick", yes:["PDF"]},
        {label:"annotationMouseLeave", value:"annotationMouseLeave", yes:["PDF"]},
        {label:"annotationMouseover", value:"annotationMouseover", yes:["PDF"]},
        {label:"annotationMove", value:"annotationMove", yes:["PDF"]},
        {label:"annotationMoving", value:"annotationMoving", yes:["PDF"]},
        {label:"annotationPropertiesChange", value:"annotationPropertiesChange", yes:["PDF"]},
        {label:"annotationRemove", value:"annotationRemove", yes:["PDF"]},
        {label:"annotationResize", value:"annotationResize", yes:["PDF"]},
        {label:"annotationSelect", value:"annotationSelect", yes:["PDF"]},
        {label:"annotationUnSelect", value:"annotationUnSelect", yes:["PDF"]},
        {label:"beforeAddFreeText", value:"beforeAddFreeText", yes:["PDF"]},
        {label:"bookmarkClick", value:"bookmarkClick", yes:["PDF"]},
        {label:"buttonFieldClick", value:"buttonFieldClick", yes:["PDF"]},
        {label:"commentAdd", value:"commentAdd", yes:["PDF"]},
        {label:"commentDelete", value:"commentDelete", yes:["PDF"]},
        {label:"commentEdit", value:"commentEdit", yes:["PDF"]},
        {label:"commentSelect", value:"commentSelect", yes:["PDF"]},
        {label:"commentStatusChanged", value:"commentStatusChanged", yes:["PDF"]},
        {label:"created", value:"created", yes:["PDF"]},
        {label:"documentLoad", value:"documentLoad", yes:["PDF"]},
        {label:"documentLoadFailed", value:"documentLoadFailed", yes:["PDF"]},
        {label:"documentUnload", value:"documentUnload", yes:["PDF"]},
        {label:"downloadEnd", value:"downloadEnd", yes:["PDF"]},
        {label:"downloadStart", value:"downloadStart", yes:["PDF"]},
        {label:"exportFailed", value:"exportFailed", yes:["PDF"]},
        {label:"exportStart", value:"exportStart", yes:["PDF"]},
        {label:"exportSuccess", value:"exportSuccess", yes:["PDF"]},
        {label:"extractTextCompleted", value:"extractTextCompleted", yes:["PDF"]},
        {label:"formFieldAdd", value:"formFieldAdd", yes:["PDF"]},
        {label:"formFieldClick", value:"formFieldClick", yes:["PDF"]},
        {label:"formFieldDoubleClick", value:"formFieldDoubleClick", yes:["PDF"]},
        {label:"formFieldFocusOut", value:"formFieldFocusOut", yes:["PDF"]},
        {label:"formFieldMouseLeave", value:"formFieldMouseLeave", yes:["PDF"]},
        {label:"formFieldMouseover", value:"formFieldMouseover", yes:["PDF"]},
        {label:"formFieldMove", value:"formFieldMove", yes:["PDF"]},
        {label:"formFieldPropertiesChange", value:"formFieldPropertiesChange", yes:["PDF"]},
        {label:"formFieldRemove", value:"formFieldRemove", yes:["PDF"]},
        {label:"formFieldResize", value:"formFieldResize", yes:["PDF"]},
        {label:"formFieldSelect", value:"formFieldSelect", yes:["PDF"]},
        {label:"formFieldUnselect", value:"formFieldUnselect", yes:["PDF"]},
        {label:"hyperlinkClick", value:"hyperlinkClick", yes:["PDF"]},
        {label:"hyperlinkMouseOver", value:"hyperlinkMouseOver", yes:["PDF"]},
        {label:"importFailed", value:"importFailed", yes:["PDF"]},
        {label:"importStart", value:"importStart", yes:["PDF"]},
        {label:"importSuccess", value:"importSuccess", yes:["PDF"]},
        {label:"moveSignature", value:"moveSignature", yes:["PDF"]},
        {label:"pageChange", value:"pageChange", yes:["PDF"]},
        {label:"pageClick", value:"pageClick", yes:["PDF"]},
        {label:"pageMouseover", value:"pageMouseover", yes:["PDF"]},
        {label:"printEnd", value:"printEnd", yes:["PDF"]},
        {label:"printStart", value:"printStart", yes:["PDF"]},
        {label:"removeSignature", value:"removeSignature", yes:["PDF"]},
        {label:"resizeSignature", value:"resizeSignature", yes:["PDF"]},
        {label:"signaturePropertiesChange", value:"signaturePropertiesChange", yes:["PDF"]},
        {label:"signatureSelect", value:"signatureSelect", yes:["PDF"]},
        {label:"textSearchComplete", value:"textSearchComplete", yes:["PDF"]},
        {label:"textSearchHighlight", value:"textSearchHighlight", yes:["PDF"]},
        {label:"textSearchStart", value:"textSearchStart", yes:["PDF"]},
        {label:"textSelectionEnd", value:"textSelectionEnd", yes:["PDF"]},
        {label:"textSelectionStart", value:"textSelectionStart", yes:["PDF"]},
        {label:"thumbnailClick", value:"thumbnailClick", yes:["PDF"]},
        {label:"validateFormFields", value:"validateFormFields", yes:["PDF"]},
        {label:"zoomChange", value:"zoomChange", yes:["PDF"]},
    
        // Pivotfieldlist
        {label:"actionBegin", value:"actionBegin", yes:["PivotFieldList"]},
        {label:"actionComplete", value:"actionComplete", yes:["PivotFieldList"]},
        {label:"actionFailure", value:"actionFailure", yes:["PivotFieldList"]},
        {label:"aggregateCellInfo", value:"aggregateCellInfo", yes:["PivotFieldList"]},
        {label:"aggregateMenuOpen", value:"aggregateMenuOpen", yes:["PivotFieldList"]},
        {label:"beforeServiceInvoke", value:"beforeServiceInvoke", yes:["PivotFieldList"]},
        {label:"calculatedFieldCreate", value:"calculatedFieldCreate", yes:["PivotFieldList"]},
        {label:"created", value:"created", yes:["PivotFieldList"]},
        {label:"dataBound", value:"dataBound", yes:["PivotFieldList"]},
        {label:"destroyed", value:"destroyed", yes:["PivotFieldList"]},
        {label:"enginePopulated", value:"enginePopulated", yes:["PivotFieldList"]},
        {label:"enginePopulating", value:"enginePopulating", yes:["PivotFieldList"]},
        {label:"fieldDragStart", value:"fieldDragStart", yes:["PivotFieldList"]},
        {label:"fieldDrop", value:"fieldDrop", yes:["PivotFieldList"]},
        {label:"fieldRemove", value:"fieldRemove", yes:["PivotFieldList"]},
        {label:"load", value:"load", yes:["PivotFieldList"]},
        {label:"memberEditorOpen", value:"memberEditorOpen", yes:["PivotFieldList"]},
        {label:"memberFiltering", value:"memberFiltering", yes:["PivotFieldList"]},
        {label:"onFieldDropped", value:"onFieldDropped", yes:["PivotFieldList"]},
        {label:"onHeadersSort", value:"onHeadersSort", yes:["PivotFieldList"]},
    
        // Pivot Table
        {label:"chartExport", value:"chartExport", yes:["PivotTable"]},
        {label:"createCalculatedFieldDialog", value:"createCalculatedFieldDialog", yes:["PivotTable"]},
        {label:"csvExport", value:"csvExport", yes:["PivotTable"]},
        {label:"destroy", value:"destroy", yes:["PivotTable"]},
        {label:"excelExport", value:"excelExport", yes:["PivotTable"]},
        {label:"getPersistData", value:"getPersistData", yes:["PivotTable"]},
        {label:"loadPersistData", value:"loadPersistData", yes:["PivotTable"]},
        {label:"pdfExport", value:"pdfExport", yes:["PivotTable"]},
        {label:"printChart", value:"printChart", yes:["PivotTable"]},
        {label:"refresh", value:"refresh", yes:["PivotTable"]},
        {label:"showConditionalFormattingDialog", value:"showConditionalFormattingDialog", yes:["PivotTable"]},
        {label:"showNumberFormattingDialog", value:"showNumberFormattingDialog", yes:["PivotTable"]},
        {label:"Events", value:"Events", yes:["PivotTable"]},
        {label:"actionBegin", value:"actionBegin", yes:["PivotTable"]},
        {label:"actionComplete", value:"actionComplete", yes:["PivotTable"]},
        {label:"actionFailure", value:"actionFailure", yes:["PivotTable"]},
        {label:"aggregateCellInfo", value:"aggregateCellInfo", yes:["PivotTable"]},
        {label:"aggregateMenuOpen", value:"aggregateMenuOpen", yes:["PivotTable"]},
        {label:"beforeExport", value:"beforeExport", yes:["PivotTable"]},
        {label:"beforeServiceInvoke", value:"beforeServiceInvoke", yes:["PivotTable"]},
        {label:"beginDrillThrough", value:"beginDrillThrough", yes:["PivotTable"]},
        {label:"calculatedFieldCreate", value:"calculatedFieldCreate", yes:["PivotTable"]},
        {label:"cellClick", value:"cellClick", yes:["PivotTable"]},
        {label:"cellSelected", value:"cellSelected", yes:["PivotTable"]},
        {label:"cellSelecting", value:"cellSelecting", yes:["PivotTable"]},
        {label:"chartSeriesCreated", value:"chartSeriesCreated", yes:["PivotTable"]},
        {label:"conditionalFormatting", value:"conditionalFormatting", yes:["PivotTable"]},
        {label:"created", value:"created", yes:["PivotTable"]},
        {label:"dataBound", value:"dataBound", yes:["PivotTable"]},
        {label:"destroyed", value:"destroyed", yes:["PivotTable"]},
        {label:"drill", value:"drill", yes:["PivotTable"]},
        {label:"drillThrough", value:"drillThrough", yes:["PivotTable"]},
        {label:"editCompleted", value:"editCompleted", yes:["PivotTable"]},
        {label:"enginePopulated", value:"enginePopulated", yes:["PivotTable"]},
        {label:"enginePopulating", value:"enginePopulating", yes:["PivotTable"]},
        {label:"exportComplete", value:"exportComplete", yes:["PivotTable"]},
        {label:"fetchReport", value:"fetchReport", yes:["PivotTable"]},
        {label:"fieldDragStart", value:"fieldDragStart", yes:["PivotTable"]},
        {label:"fieldDrop", value:"fieldDrop", yes:["PivotTable"]},
        {label:"fieldListRefreshed", value:"fieldListRefreshed", yes:["PivotTable"]},
        {label:"fieldRemove", value:"fieldRemove", yes:["PivotTable"]},
        {label:"hyperlinkCellClick", value:"hyperlinkCellClick", yes:["PivotTable"]},
        {label:"load", value:"load", yes:["PivotTable"]},
        {label:"loadReport", value:"loadReport", yes:["PivotTable"]},
        {label:"memberEditorOpen", value:"memberEditorOpen", yes:["PivotTable"]},
        {label:"memberFiltering", value:"memberFiltering", yes:["PivotTable"]},
        {label:"newReport", value:"newReport", yes:["PivotTable"]},
        {label:"numberFormatting", value:"numberFormatting", yes:["PivotTable"]},
        {label:"onFieldDropped", value:"onFieldDropped", yes:["PivotTable"]},
        {label:"onHeadersSort", value:"onHeadersSort", yes:["PivotTable"]},
        {label:"onPdfCellRender", value:"onPdfCellRender", yes:["PivotTable"]},
        {label:"removeReport", value:"removeReport", yes:["PivotTable"]},
        {label:"renameReport", value:"renameReport", yes:["PivotTable"]},
        {label:"saveReport", value:"saveReport", yes:["PivotTable"]},
        {label:"toolbarClick", value:"toolbarClick", yes:["PivotTable"]},
        {label:"toolbarRender", value:"toolbarRender", yes:["PivotTable"]},
    
        // ProgressButton
    
        {label:"begin", value:"begin", yes:["ProgressButton"]},
        {label:"created", value:"created", yes:["ProgressButton"]},
        {label:"end", value:"end", yes:["ProgressButton"]},
        {label:"fail", value:"fail", yes:["ProgressButton"]},
        {label:"progress", value:"progress", yes:["ProgressButton"]},
        // Progressbar
        {label:"animationComplete", value:"animationComplete", yes:["ProgressBar"]},
        {label:"load", value:"load", yes:["ProgressBar"]},
        {label:"loaded", value:"loaded", yes:["ProgressBar"]},
        {label:"mouseClick", value:"mouseClick", yes:["ProgressBar"]},
        {label:"mouseDown", value:"mouseDown", yes:["ProgressBar"]},
        {label:"mouseLeave", value:"mouseLeave", yes:["ProgressBar"]},
        {label:"mouseMove", value:"mouseMove", yes:["ProgressBar"]},
        {label:"mouseUp", value:"mouseUp", yes:["ProgressBar"]},
        {label:"progressCompleted", value:"progressCompleted", yes:["ProgressBar"]},
        {label:"textRender", value:"textRender", yes:["ProgressBar"]},
        {label:"valueChanged", value:"valueChanged", yes:["ProgressBar"]},
    
        // QueryBuilder
        {label:"actionBegin", value:"actionBegin", yes:["QueryBuilder"]},
        {label:"beforeChange", value:"beforeChange", yes:["QueryBuilder"]},
        {label:"change", value:"change", yes:["QueryBuilder"]},
        {label:"created", value:"created", yes:["QueryBuilder"]},
        {label:"dataBound", value:"dataBound", yes:["QueryBuilder"]},
        {label:"ruleChange", value:"ruleChange", yes:["QueryBuilder"]},
    
        // RadioButton
        {label:"change",value:"change", yes:["RadioButton"]},
        {label:"created",value:"created", yes:["RadioButton"]},
    
        // Range Navigator
        {label:"beforePrint", value:"beforePrint", yes:["RangeNavigator"]},
        {label:"beforeResize", value:"beforeResize", yes:["RangeNavigator"]},
        {label:"changed", value:"changed", yes:["RangeNavigator"]},
        {label:"labelRender", value:"labelRender", yes:["RangeNavigator"]},
        {label:"load", value:"load", yes:["RangeNavigator"]},
        {label:"loaded", value:"loaded", yes:["RangeNavigator"]},
        {label:"resized", value:"resized", yes:["RangeNavigator"]},
        {label:"selectorRender", value:"selectorRender", yes:["RangeNavigator"]},
        {label:"tooltipRender", value:"tooltipRender", yes:["RangeNavigator"]},
    
        // Rating
        {label:"beforeItemRender", value:"beforeItemRender", yes:["Rating"]},
    {label:"created", value:"created", yes:["Rating"]},
    {label:"onItemHover", value:"onItemHover", yes:["Rating"]},
    {label:"valueChanged", value:"valueChanged", yes:["Rating"]},
    
        // Recurrence Editor
        {label:"change", value:"change", yes:["RecurrenceEditor"]},
    
        // RichTextEditor
        {label:"actionBegin", value:"actionBegin", yes:["RichTextEditor"]},
        {label:"actionComplete", value:"actionComplete", yes:["RichTextEditor"]},
        {label:"afterImageDelete", value:"afterImageDelete", yes:["RichTextEditor"]},
        {label:"afterMediaDelete", value:"afterMediaDelete", yes:["RichTextEditor"]},
        {label:"afterPasteCleanup", value:"afterPasteCleanup", yes:["RichTextEditor"]},
        {label:"beforeDialogClose", value:"beforeDialogClose", yes:["RichTextEditor"]},
        {label:"beforeDialogOpen", value:"beforeDialogOpen", yes:["RichTextEditor"]},
        {label:"beforeFileUpload", value:"beforeFileUpload", yes:["RichTextEditor"]},
        {label:"beforeImageDrop", value:"beforeImageDrop", yes:["RichTextEditor"]},
        {label:"beforeImageUpload", value:"beforeImageUpload", yes:["RichTextEditor"]},
        {label:"beforePasteCleanup", value:"beforePasteCleanup", yes:["RichTextEditor"]},
        {label:"beforeQuickToolbarOpen", value:"beforeQuickToolbarOpen", yes:["RichTextEditor"]},
        {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["RichTextEditor"]},
        {label:"blur", value:"blur", yes:["RichTextEditor"]},
        {label:"change", value:"change", yes:["RichTextEditor"]},
        {label:"created", value:"created", yes:["RichTextEditor"]},
        {label:"destroyed", value:"destroyed", yes:["RichTextEditor"]},
        {label:"dialogClose", value:"dialogClose", yes:["RichTextEditor"]},
        {label:"dialogOpen", value:"dialogOpen", yes:["RichTextEditor"]},
        {label:"fileRemoving", value:"fileRemoving", yes:["RichTextEditor"]},
        {label:"fileSelected", value:"fileSelected", yes:["RichTextEditor"]},
        {label:"fileUploadFailed", value:"fileUploadFailed", yes:["RichTextEditor"]},
        {label:"fileUploadSuccess", value:"fileUploadSuccess", yes:["RichTextEditor"]},
        {label:"fileUploading", value:"fileUploading", yes:["RichTextEditor"]},
        {label:"focus", value:"focus", yes:["RichTextEditor"]},
        {label:"imageRemoving", value:"imageRemoving", yes:["RichTextEditor"]},
        {label:"imageSelected", value:"imageSelected", yes:["RichTextEditor"]},
        {label:"imageUploadFailed", value:"imageUploadFailed", yes:["RichTextEditor"]},
        {label:"imageUploadSuccess", value:"imageUploadSuccess", yes:["RichTextEditor"]},
        {label:"imageUploading", value:"imageUploading", yes:["RichTextEditor"]},
        {label:"quickToolbarClose", value:"quickToolbarClose", yes:["RichTextEditor"]},
        {label:"quickToolbarOpen", value:"quickToolbarOpen", yes:["RichTextEditor"]},
        {label:"resizeStart", value:"resizeStart", yes:["RichTextEditor"]},
        {label:"resizeStop", value:"resizeStop", yes:["RichTextEditor"]},
        {label:"resizing", value:"resizing", yes:["RichTextEditor"]},
        {label:"toolbarClick", value:"toolbarClick", yes:["RichTextEditor"]},
        {label:"toolbarStatusUpdate", value:"toolbarStatusUpdate", yes:["RichTextEditor"]},
        {label:"updatedToolbarStatus", value:"updatedToolbarStatus", yes:["RichTextEditor"]},
    
        // Schedule
    
        {label:"actionBegin", value:"actionBegin", yes:["Scheduler"]},
        {label:"actionComplete", value:"actionComplete", yes:["Scheduler"]},
        {label:"actionFailure", value:"actionFailure", yes:["Scheduler"]},
        {label:"cellClick", value:"cellClick", yes:["Scheduler"]},
        {label:"cellDoubleClick", value:"cellDoubleClick", yes:["Scheduler"]},
        {label:"created", value:"created", yes:["Scheduler"]},
        {label:"dataBinding", value:"dataBinding", yes:["Scheduler"]},
        {label:"dataBound", value:"dataBound", yes:["Scheduler"]},
        {label:"destroyed", value:"destroyed", yes:["Scheduler"]},
        {label:"drag", value:"drag", yes:["Scheduler"]},
        {label:"dragStart", value:"dragStart", yes:["Scheduler"]},
        {label:"dragStop", value:"dragStop", yes:["Scheduler"]},
        {label:"eventClick", value:"eventClick", yes:["Scheduler"]},
        {label:"eventRendered", value:"eventRendered", yes:["Scheduler"]},
        {label:"hover", value:"hover", yes:["Scheduler"]},
        {label:"moreEventsClick", value:"moreEventsClick", yes:["Scheduler"]},
        {label:"navigating", value:"navigating", yes:["Scheduler"]},
        {label:"popupClose", value:"popupClose", yes:["Scheduler"]},
        {label:"popupOpen", value:"popupOpen", yes:["Scheduler"]},
        {label:"renderCell", value:"renderCell", yes:["Scheduler"]},
        {label:"resizeStart", value:"resizeStart", yes:["Scheduler"]},
        {label:"resizeStop", value:"resizeStop", yes:["Scheduler"]},
        {label:"resizing", value:"resizing", yes:["Scheduler"]},
        {label:"select", value:"select", yes:["Scheduler"]},
        // Sidebar
    
        {label:"change", value:"change", yes:["Sidebar"]},
        {label:"close", value:"close", yes:["Sidebar"]},
        {label:"created", value:"created", yes:["Sidebar"]},
        {label:"destroyed", value:"destroyed", yes:["Sidebar"]},
        {label:"open", value:"open", yes:["Sidebar"]},
        // Signature
    
    {label:"beforeSave", value:"beforeSave", yes:["Signature"]},
    {label:"change", value:"change", yes:["Signature"]},
    {label:"created", value:"created", yes:["Signature"]},
        // Skeleton
        {label:"destroy", value:"destroy", yes:["Skeleton"]},
    
        // Slider
        {label:"change", value:"change", yes:["Slider"]},
        {label:"changed", value:"changed", yes:["Slider"]},
        {label:"created", value:"created", yes:["Slider"]},
        {label:"renderedTicks", value:"renderedTicks", yes:["Slider"]},
        {label:"renderingTicks", value:"renderingTicks", yes:["Slider"]},
        {label:"tooltipChange", value:"tooltipChange", yes:["Slider"]},
    
        // Smith Chart
        {label:"loaded", value:"loaded",yes:["SmithChart"]},
        {label:"seriesRender", value:"seriesRender",yes:["SmithChart"]},
        {label:"subtitleRender", value:"subtitleRender",yes:["SmithChart"]},
        {label:"textRender", value:"textRender",yes:["SmithChart"]},
        {label:"titleRender", value:"titleRender",yes:["SmithChart"]},
        {label:"tooltipRender", value:"tooltipRender",yes:["SmithChart"]},
    
        // Sparkline
        {label:"axisRendering", value:"axisRendering",yes:["SparkLine"]},
        {label:"dataLabelRendering", value:"dataLabelRendering",yes:["SparkLine"]},
        {label:"load", value:"load",yes:["SparkLine"]},
        {label:"loaded", value:"loaded",yes:["SparkLine"]},
        {label:"markerRendering", value:"markerRendering",yes:["SparkLine"]},
        {label:"pointRegionMouseClick", value:"pointRegionMouseClick",yes:["SparkLine"]},
        {label:"pointRegionMouseMove", value:"pointRegionMouseMove",yes:["SparkLine"]},
        {label:"pointRendering", value:"pointRendering",yes:["SparkLine"]},
        {label:"resize", value:"resize",yes:["SparkLine"]},
        {label:"seriesRendering", value:"seriesRendering",yes:["SparkLine"]},
        {label:"sparklineMouseClick", value:"sparklineMouseClick",yes:["SparkLine"]},
        {label:"sparklineMouseMove", value:"sparklineMouseMove",yes:["SparkLine"]},
        {label:"tooltipInitialize", value:"tooltipInitialize",yes:["SparkLine"]},
    
        // Speed Dial
        {label:"beforeClose", value:"beforeClose", yes:["SpeedDial"]},
        {label:"beforeItemRender", value:"beforeItemRender", yes:["SpeedDial"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["SpeedDial"]},
        {label:"clicked", value:"clicked", yes:["SpeedDial"]},
        {label:"created", value:"created", yes:["SpeedDial"]},
        {label:"onClose", value:"onClose", yes:["SpeedDial"]},
        {label:"onOpen", value:"onOpen", yes:["SpeedDial"]},
    
        // SplitButton
        {label:"beforeClose", value:"beforeClose", yes:["SplitButton"]},
        {label:"beforeItemRender", value:"beforeItemRender", yes:["SplitButton"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["SplitButton"]},
        {label:"click", value:"click", yes:["SplitButton"]},
        {label:"close", value:"close", yes:["SplitButton"]},
        {label:"created", value:"created", yes:["SplitButton"]},
        {label:"open", value:"open", yes:["SplitButton"]},
        {label:"select", value:"select", yes:["SplitButton"]},
    
        // Splitter
        {label:"beforeCollapse", value:"beforeCollapse", yes:["Splitter"]},
        {label:"beforeExpand", value:"beforeExpand", yes:["Splitter"]},
        {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["Splitter"]},
        {label:"collapsed", value:"collapsed", yes:["Splitter"]},
        {label:"created", value:"created", yes:["Splitter"]},
        {label:"expanded", value:"expanded", yes:["Splitter"]},
        {label:"resizeStart", value:"resizeStart", yes:["Splitter"]},
        {label:"resizeStop", value:"resizeStop", yes:["Splitter"]},
        {label:"resizing", value:"resizing", yes:["Splitter"]},
    
        // Spreadsheet
        {label:"actionBegin", value:"actionBegin", yes:["Spreadsheet"]},
        {label:"actionComplete", value:"actionComplete", yes:["Spreadsheet"]},
        {label:"afterHyperlinkClick", value:"afterHyperlinkClick", yes:["Spreadsheet"]},
        {label:"afterHyperlinkCreate", value:"afterHyperlinkCreate", yes:["Spreadsheet"]},
        {label:"beforeCellFormat", value:"beforeCellFormat", yes:["Spreadsheet"]},
        {label:"beforeCellRender", value:"beforeCellRender", yes:["Spreadsheet"]},
        {label:"beforeCellSave", value:"beforeCellSave", yes:["Spreadsheet"]},
        {label:"beforeCellUpdate", value:"beforeCellUpdate", yes:["Spreadsheet"]},
        {label:"beforeConditionalFormat", value:"beforeConditionalFormat", yes:["Spreadsheet"]},
        {label:"beforeDataBound", value:"beforeDataBound", yes:["Spreadsheet"]},
        {label:"beforeHyperlinkClick", value:"beforeHyperlinkClick", yes:["Spreadsheet"]},
        {label:"beforeHyperlinkCreate", value:"beforeHyperlinkCreate", yes:["Spreadsheet"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["Spreadsheet"]},
        {label:"beforeSave", value:"beforeSave", yes:["Spreadsheet"]},
        {label:"beforeSelect", value:"beforeSelect", yes:["Spreadsheet"]},
        {label:"beforeSort", value:"beforeSort", yes:["Spreadsheet"]},
        {label:"cellEdit", value:"cellEdit", yes:["Spreadsheet"]},
        {label:"cellEditing", value:"cellEditing", yes:["Spreadsheet"]},
        {label:"cellSave", value:"cellSave", yes:["Spreadsheet"]},
        {label:"contextMenuBeforeClose", value:"contextMenuBeforeClose", yes:["Spreadsheet"]},
        {label:"contextMenuBeforeOpen", value:"contextMenuBeforeOpen", yes:["Spreadsheet"]},
        {label:"contextMenuItemSelect", value:"contextMenuItemSelect", yes:["Spreadsheet"]},
        {label:"created", value:"created", yes:["Spreadsheet"]},
        {label:"dataBound", value:"dataBound", yes:["Spreadsheet"]},
        {label:"dataSourceChanged", value:"dataSourceChanged", yes:["Spreadsheet"]},
        {label:"dialogBeforeOpen", value:"dialogBeforeOpen", yes:["Spreadsheet"]},
        {label:"fileMenuBeforeClose", value:"fileMenuBeforeClose", yes:["Spreadsheet"]},
        {label:"fileMenuBeforeOpen", value:"fileMenuBeforeOpen", yes:["Spreadsheet"]},
        {label:"fileMenuItemSelect", value:"fileMenuItemSelect", yes:["Spreadsheet"]},
        {label:"openComplete", value:"openComplete", yes:["Spreadsheet"]},
        {label:"openFailure", value:"openFailure", yes:["Spreadsheet"]},
        {label:"queryCellInfo", value:"queryCellInfo", yes:["Spreadsheet"]},
        {label:"saveComplete", value:"saveComplete", yes:["Spreadsheet"]},
        {label:"select", value:"select", yes:["Spreadsheet"]},
        {label:"sortComplete", value:"sortComplete", yes:["Spreadsheet"]},
    
        // Stock Chart
        {label:"axisLabelRender", value:"axisLabelRender", yes:["StockChart"]},
        {label:"legendClick", value:"legendClick", yes:["StockChart"]},
        {label:"legendRender", value:"legendRender", yes:["StockChart"]},
        {label:"load", value:"load", yes:["StockChart"]},
        {label:"loaded", value:"loaded", yes:["StockChart"]},
        {label:"onZooming", value:"onZooming", yes:["StockChart"]},
        {label:"pointClick", value:"pointClick", yes:["StockChart"]},
        {label:"pointMove", value:"pointMove", yes:["StockChart"]},
        {label:"rangeChange", value:"rangeChange", yes:["StockChart"]},
        {label:"selectorRender", value:"selectorRender", yes:["StockChart"]},
        {label:"seriesRender", value:"seriesRender", yes:["StockChart"]},
        {label:"stockChartMouseClick", value:"stockChartMouseClick", yes:["StockChart"]},
        {label:"stockChartMouseDown", value:"stockChartMouseDown", yes:["StockChart"]},
        {label:"stockChartMouseLeave", value:"stockChartMouseLeave", yes:["StockChart"]},
        {label:"stockChartMouseMove", value:"stockChartMouseMove", yes:["StockChart"]},
        {label:"stockChartMouseUp", value:"stockChartMouseUp", yes:["StockChart"]},
        {label:"stockEventRender", value:"stockEventRender", yes:["StockChart"]},
        {label:"tooltipRender", value:"tooltipRender", yes:["StockChart"]},
    
        // Switch
    
        {label:"change", value:"change", yes:["Switch"]},
        {label:"created", value:"created", yes:["Switch"]},
        // Symbol Palette
        {label:"paletteExpanding", value:"paletteExpanding", yes:["SymbolPalette"]},
        {label:"paletteSelectionChange", value:"paletteSelectionChange", yes:["SymbolPalette"]},
    
        // Tabs
        {label:"added", value:"added", yes:["Tabs"]},
        {label:"adding", value:"adding", yes:["Tabs"]},
        {label:"created", value:"created", yes:["Tabs"]},
        {label:"destroyed", value:"destroyed", yes:["Tabs"]},
        {label:"dragged", value:"dragged", yes:["Tabs"]},
        {label:"dragging", value:"dragging", yes:["Tabs"]},
        {label:"onDragStart", value:"onDragStart", yes:["Tabs"]},
        {label:"removed", value:"removed", yes:["Tabs"]},
        {label:"removing", value:"removing", yes:["Tabs"]},
        {label:"selected", value:"selected", yes:["Tabs"]},
        {label:"selecting", value:"selecting", yes:["Tabs"]},
    
        // TextBox
        {label:"blur", value:"blur", yes:["TextBox"]},
        {label:"change", value:"change", yes:["TextBox"]},
        {label:"created", value:"created", yes:["TextBox"]},
        {label:"destroyed", value:"destroyed", yes:["TextBox"]},
        {label:"focus", value:"focus", yes:["TextBox"]},
        {label:"input", value:"input", yes:["TextBox"]},
    
        // TimePicker
        {label:"blur", value:"blur", yes:["TimePicker"]},
        {label:"change", value:"change", yes:["TimePicker"]},
        {label:"cleared", value:"cleared", yes:["TimePicker"]},
        {label:"close", value:"close", yes:["TimePicker"]},
        {label:"created", value:"created", yes:["TimePicker"]},
        {label:"destroyed", value:"destroyed", yes:["TimePicker"]},
        {label:"focus", value:"focus", yes:["TimePicker"]},
        {label:"itemRender", value:"itemRender", yes:["TimePicker"]},
        {label:"open", value:"open", yes:["TimePicker"]},
    
        // Toast
        {label:"beforeClose", value:"beforeClose", yes:["Toast"]},
        {label:"beforeOpen", value:"beforeOpen", yes:["Toast"]},
        {label:"beforeSanitizeHtml", value:"beforeSanitizeHtml", yes:["Toast"]},
        {label:"click", value:"click", yes:["Toast"]},
        {label:"close", value:"close", yes:["Toast"]},
        {label:"created", value:"created", yes:["Toast"]},
        {label:"destroyed", value:"destroyed", yes:["Toast"]},
        {label:"open", value:"open", yes:["Toast"]},
    
        // Toolbar
        {label:"beforeCreate", value:"beforeCreate", yes:["Toolbar"]},
        {label:"clicked", value:"clicked", yes:["Toolbar"]},
        {label:"created", value:"created", yes:["Toolbar"]},
        {label:"destroyed", value:"destroyed", yes:["Toolbar"]},
    
        // Tooltip
    {label:"afterClose", value:"afterClose", yes:["Tooltip"]},
    {label:"afterOpen", value:"afterOpen", yes:["Tooltip"]},
    {label:"beforeClose", value:"beforeClose", yes:["Tooltip"]},
    {label:"beforeCollision", value:"beforeCollision", yes:["Tooltip"]},
    {label:"beforeOpen", value:"beforeOpen", yes:["Tooltip"]},
    {label:"beforeRender", value:"beforeRender", yes:["Tooltip"]},
    {label:"created", value:"created", yes:["Tooltip"]},
    {label:"destroyed", value:"destroyed", yes:["Tooltip"]},
    
        // TreeGrid
        {label:"actionBegin", value:"actionBegin", yes:["TreeGrid"]},
        {label:"actionComplete", value:"actionComplete", yes:["TreeGrid"]},
        {label:"actionFailure", value:"actionFailure", yes:["TreeGrid"]},
        {label:"batchAdd", value:"batchAdd", yes:["TreeGrid"]},
        {label:"batchCancel", value:"batchCancel", yes:["TreeGrid"]},
        {label:"batchDelete", value:"batchDelete", yes:["TreeGrid"]},
        {label:"beforeBatchAdd", value:"beforeBatchAdd", yes:["TreeGrid"]},
        {label:"beforeBatchDelete", value:"beforeBatchDelete", yes:["TreeGrid"]},
        {label:"beforeBatchSave", value:"beforeBatchSave", yes:["TreeGrid"]},
        {label:"beforeCopy", value:"beforeCopy", yes:["TreeGrid"]},
        {label:"beforeDataBound", value:"beforeDataBound", yes:["TreeGrid"]},
        {label:"beforeExcelExport", value:"beforeExcelExport", yes:["TreeGrid"]},
        {label:"beforePaste", value:"beforePaste", yes:["TreeGrid"]},
        {label:"beforePdfExport", value:"beforePdfExport", yes:["TreeGrid"]},
        {label:"beforePrint", value:"beforePrint", yes:["TreeGrid"]},
        {label:"beginEdit", value:"beginEdit", yes:["TreeGrid"]},
        {label:"cellDeselected", value:"cellDeselected", yes:["TreeGrid"]},
        {label:"cellDeselecting", value:"cellDeselecting", yes:["TreeGrid"]},
        {label:"cellEdit", value:"cellEdit", yes:["TreeGrid"]},
        {label:"cellSave", value:"cellSave", yes:["TreeGrid"]},
        {label:"cellSaved", value:"cellSaved", yes:["TreeGrid"]},
        {label:"cellSelected", value:"cellSelected", yes:["TreeGrid"]},
        {label:"cellSelecting", value:"cellSelecting", yes:["TreeGrid"]},
        {label:"checkboxChange", value:"checkboxChange", yes:["TreeGrid"]},
        {label:"collapsed", value:"collapsed", yes:["TreeGrid"]},
        {label:"collapsing", value:"collapsing", yes:["TreeGrid"]},
        {label:"columnDrag", value:"columnDrag", yes:["TreeGrid"]},
        {label:"columnDragStart", value:"columnDragStart", yes:["TreeGrid"]},
        {label:"columnDrop", value:"columnDrop", yes:["TreeGrid"]},
        {label:"columnMenuClick", value:"columnMenuClick", yes:["TreeGrid"]},
        {label:"columnMenuOpen", value:"columnMenuOpen", yes:["TreeGrid"]},
        {label:"contextMenuClick", value:"contextMenuClick", yes:["TreeGrid"]},
        {label:"contextMenuOpen", value:"contextMenuOpen", yes:["TreeGrid"]},
        {label:"created", value:"created", yes:["TreeGrid"]},
        {label:"dataBound", value:"dataBound", yes:["TreeGrid"]},
        {label:"dataSourceChanged", value:"dataSourceChanged", yes:["TreeGrid"]},
        {label:"dataStateChange", value:"dataStateChange", yes:["TreeGrid"]},
        {label:"detailDataBound", value:"detailDataBound", yes:["TreeGrid"]},
        {label:"excelExportComplete", value:"excelExportComplete", yes:["TreeGrid"]},
        {label:"excelHeaderQueryCellInfo", value:"excelHeaderQueryCellInfo", yes:["TreeGrid"]},
        {label:"excelQueryCellInfo", value:"excelQueryCellInfo", yes:["TreeGrid"]},
        {label:"expanded", value:"expanded", yes:["TreeGrid"]},
        {label:"expanding", value:"expanding", yes:["TreeGrid"]},
        {label:"headerCellInfo", value:"headerCellInfo", yes:["TreeGrid"]},
        {label:"load", value:"load", yes:["TreeGrid"]},
        {label:"pdfExportComplete", value:"pdfExportComplete", yes:["TreeGrid"]},
        {label:"pdfHeaderQueryCellInfo", value:"pdfHeaderQueryCellInfo", yes:["TreeGrid"]},
        {label:"pdfQueryCellInfo", value:"pdfQueryCellInfo", yes:["TreeGrid"]},
        {label:"printComplete", value:"printComplete", yes:["TreeGrid"]},
        {label:"queryCellInfo", value:"queryCellInfo", yes:["TreeGrid"]},
        {label:"recordDoubleClick", value:"recordDoubleClick", yes:["TreeGrid"]},
        {label:"resizeStart", value:"resizeStart", yes:["TreeGrid"]},
        {label:"resizeStop", value:"resizeStop", yes:["TreeGrid"]},
        {label:"resizing", value:"resizing", yes:["TreeGrid"]},
        {label:"rowDataBound", value:"rowDataBound", yes:["TreeGrid"]},
        {label:"rowDeselected", value:"rowDeselected", yes:["TreeGrid"]},
        {label:"rowDeselecting", value:"rowDeselecting", yes:["TreeGrid"]},
        {label:"rowDrag", value:"rowDrag", yes:["TreeGrid"]},
        {label:"rowDragStart", value:"rowDragStart", yes:["TreeGrid"]},
        {label:"rowDragStartHelper", value:"rowDragStartHelper", yes:["TreeGrid"]},
        {label:"rowDrop", value:"rowDrop", yes:["TreeGrid"]},
        {label:"rowSelected", value:"rowSelected", yes:["TreeGrid"]},
        {label:"rowSelecting", value:"rowSelecting", yes:["TreeGrid"]},
        {label:"toolbarClick", value:"toolbarClick", yes:["TreeGrid"]},
    
        // TreeMap
    
        {label:"beforePrint", value:"beforePrint", yes:["TreeMap"]},
        {label:"click", value:"click", yes:["TreeMap"]},
        {label:"doubleClick", value:"doubleClick", yes:["TreeMap"]},
        {label:"drillEnd", value:"drillEnd", yes:["TreeMap"]},
        {label:"drillStart", value:"drillStart", yes:["TreeMap"]},
        {label:"itemClick", value:"itemClick", yes:["TreeMap"]},
        {label:"itemHighlight", value:"itemHighlight", yes:["TreeMap"]},
        {label:"itemMove", value:"itemMove", yes:["TreeMap"]},
        {label:"itemRendering", value:"itemRendering", yes:["TreeMap"]},
        {label:"itemSelected", value:"itemSelected", yes:["TreeMap"]},
        {label:"legendItemRendering", value:"legendItemRendering", yes:["TreeMap"]},
        {label:"legendRendering", value:"legendRendering", yes:["TreeMap"]},
        {label:"load", value:"load", yes:["TreeMap"]},
        {label:"loaded", value:"loaded", yes:["TreeMap"]},
        {label:"mouseMove", value:"mouseMove", yes:["TreeMap"]},
        {label:"resize", value:"resize", yes:["TreeMap"]},
        {label:"rightClick", value:"rightClick", yes:["TreeMap"]},
        {label:"tooltipRendering", value:"tooltipRendering", yes:["TreeMap"]},
        // TreeView
        {label:"actionFailure",value:"actionFailure", yes:["TreeView"]},
        {label:"created",value:"created", yes:["TreeView"]},
        {label:"dataBound",value:"dataBound", yes:["TreeView"]},
        {label:"dataSourceChanged",value:"dataSourceChanged", yes:["TreeView"]},
        {label:"destroyed",value:"destroyed", yes:["TreeView"]},
        {label:"drawNode",value:"drawNode", yes:["TreeView"]},
        {label:"keyPress",value:"keyPress", yes:["TreeView"]},
        {label:"nodeChecked",value:"nodeChecked", yes:["TreeView"]},
        {label:"nodeChecking",value:"nodeChecking", yes:["TreeView"]},
        {label:"nodeClicked",value:"nodeClicked", yes:["TreeView"]},
        {label:"nodeCollapsed",value:"nodeCollapsed", yes:["TreeView"]},
        {label:"nodeCollapsing",value:"nodeCollapsing", yes:["TreeView"]},
        {label:"nodeDragStart",value:"nodeDragStart", yes:["TreeView"]},
        {label:"nodeDragStop",value:"nodeDragStop", yes:["TreeView"]},
        {label:"nodeDragging",value:"nodeDragging", yes:["TreeView"]},
        {label:"nodeDropped",value:"nodeDropped", yes:["TreeView"]},
        {label:"nodeEdited",value:"nodeEdited", yes:["TreeView"]},
        {label:"nodeEditing",value:"nodeEditing", yes:["TreeView"]},
        {label:"nodeExpanded",value:"nodeExpanded", yes:["TreeView"]},
        {label:"nodeExpanding",value:"nodeExpanding", yes:["TreeView"]},
        {label:"nodeSelected",value:"nodeSelected", yes:["TreeView"]},
        {label:"nodeSelecting",value:"nodeSelecting", yes:["TreeView"]},
    
        // Uploader
        {label:"actionComplete", value:"actionComplete", yes:["Uploader"]},
        {label:"beforeRemove", value:"beforeRemove", yes:["Uploader"]},
        {label:"beforeUpload", value:"beforeUpload", yes:["Uploader"]},
        {label:"canceling", value:"canceling", yes:["Uploader"]},
        {label:"change", value:"change", yes:["Uploader"]},
        {label:"chunkFailure", value:"chunkFailure", yes:["Uploader"]},
        {label:"chunkSuccess", value:"chunkSuccess", yes:["Uploader"]},
        {label:"chunkUploading", value:"chunkUploading", yes:["Uploader"]},
        {label:"clearing", value:"clearing", yes:["Uploader"]},
        {label:"created", value:"created", yes:["Uploader"]},
        {label:"failure", value:"failure", yes:["Uploader"]},
        {label:"fileListRendering", value:"fileListRendering", yes:["Uploader"]},
        {label:"pausing", value:"pausing", yes:["Uploader"]},
        {label:"progress", value:"progress", yes:["Uploader"]},
        {label:"removing", value:"removing", yes:["Uploader"]},
        {label:"rendering", value:"rendering", yes:["Uploader"]},
        {label:"resuming", value:"resuming", yes:["Uploader"]},
        {label:"selected", value:"selected", yes:["Uploader"]},
        {label:"success", value:"success", yes:["Uploader"]},
        {label:"uploading", value:"uploading", yes:["Uploader"]},
    
    
        // layout
          // {label:"onFocus", value: "onFocus",},
          // {label:"onFocusCapture", value: "onFocusCapture"},
          // {label:"onBlur", value: "onBlur"},
          // {label:"onBlurCapture", value: "onBlurCapture"},
          // {label:"onChange", value: "onChange"},
          // {label:"onChangeCapture", value: "onChangeCapture"},
          // {label:"onSubmit", value: "onSubmit"},
          // {label:"onSubmitCapture", value: "onSubmitCapture"},
          // {label:"onLoad", value: "onLoad"},
          // {label:"onKeyDown", value: "onKey Down"},
          // {label:"onKeyDownCapture", value: "onKeyDownCapture"},
          // {label:"onKeyPress", value: "onKeyPress"},
          // {label:"onKeyPressCapture", value: "onKeyPressCapture"},
          // {label:"onKeyUp", value: "onKey Up"},
          // {label:"onKeyUpCapture", value: "onKeyUpCapture"},   
          // {label:"onClick", value: "onClick"},
          // {label:"onClickCapture", value: "onClickCapture"},
          // {label:"onContext Menu", value: "onContextMenu"},
          // {label:"onContext MenuCapture", value: "onContextMenuCapture"},
          // {label:"onDouble Click", value: "onDoubleClick"},
          // {label:"onDouble ClickCapture", value: "onDoubleClickCapture"},
          // {label:"onDrag", value: "onDrag"},
          // {label:"onDragCapture", value: "onDragCapture"},
          // {label:"onDragEnd", value: "onDragEnd"},
          // {label:"onDragEndCapture", value: "onDragEndCapture"},
          // {label:"onDragEnter", value: "onDragEnter"},
          // {label:"onDragEnterCapture", value: "onDragEnterCapture"},
          // {label:"onDragExit", value: "onDragExit"},
          // {label:"onDragExitCapture", value: "onDragExitCapture"},
          // {label:"onDragLeave", value: "onDragLeave"},
          // {label:"onDragLeaveCapture", value: "onDragLeaveCapture"},
          // {label:"onDragOver", value: "onDragOver"},
          // {label:"onDragOverCapture", value: "onDragOverCapture"},
          // {label:"onDragStart", value: "onDragStart"},
          // {label:"onDragStartCapture", value: "onDragStartCapture"},
          // {label:"onDrop", value: "onDrop"},
          // {label:"onDropCapture", value: "onDropCapture"},
          // {label:"onMouseDown", value: "onMouseDown"},
          // {label:"onMouseDownCapture", value: "onMouseDownCapture"},
          // {label:"onMouseEnter", value: "onMouseEnter"},
          // {label:"onMouseLeave", value: "onMouseLeave"},
          // {label:"onMouseMove", value: "onMouseMove"},
          // {label:"onMouseMoveCapture", value: "onMouseMoveCapture"},
          // {label:"onMouseOut", value: "onMouseOut"},
          // {label:"onMouseOutCapture", value: "onMouseOutCapture"},
          // {label:"onMouseOver", value: "onMouseOver"},
          // {label:"onMouseOverCapture", value: "onMouseOverCapture"},
          // {label:"onMouseUp", value: "onMouseUp"},
          // {label:"onMouseUpCapture", value: "onMouseUpCapture"},
          // {label:"onSelect", value: "onSelect"},
          // {label:"onSelectCapture", value: "onSelectCapture"},
          // {label:"onTouchCancel", value: "onTouchCancel"},
          // {label:"onTouchCancelCapture", value: "onTouchCancelCapture"},
          // {label:"onTouchEnd", value: "onTouchEnd"},
          // {label:"onTouchEndCapture", value: "onTouchEndCapture"},
          // {label:"onTouchMove", value: "onTouchMove"},
          // {label:"onTouchMoveCapture", value: "onTouchMoveCapture"},
          // {label:"onTouchStart", value: "onTouchStart"},
          // {label:"onTouchStartCapture", value: "onTouchStartCapture"},
        ]
      if(widgetName) {
          console.log(widgetName, 123)
        setEventsList([...el.filter(x => x.yes?.some(y=> y == widgetName))])
      } else {
        setEventsList(el)
      }
    },[widgetName])
const [eventsSearch, setEventsSearch] = useState("")    
const [filterEventsList, setFilterEventsList] = useState([])
useEffect(() => {
  if(eventsSearch) {
      if(eventsSearch.length) {
        setFilterEventsList([...eventsList].filter((x) => {
          return (
            new RegExp(eventsSearch, "gi").test(x.label)
          )
      }))
      }
} else {
        setFilterEventsList([])
      } 
},[eventsSearch, eventsList])

const [activeEventView, setActiveEventView] = useState("grid")



const [otherContent, setOtherContent] = useState(false)

const [updateMode, setUpdateMode] = useState(false)
const editAction= (item) => {
  setAddActionActive(true)
  setUpdateMode(true)
  setEventType && setEventType("Code")
  getCode(null, null, item.eventContent)
}


    const [editDbModal, setEditDbModal] = useState(null) 

    const [addEventActive, setAddEventActive] = useState("")

    const monacoRef = useRef(null);

    function handleEditorWillMount(monaco) {
      // here is the monaco instance
      // do something before editor is mounted
      monaco.languages.typescript.javascriptDefaults.addExtraLib("declare const testVar : () => 3")
      monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare const allNodesArr: ${JSON.stringify(allNodes)}`)
      monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare const allKeysArr: ${JSON.stringify(allKeys)}`)

    }
  
    function handleEditorDidMount(editor, monaco) {
      // here is another way to get monaco instance
      // you can also store it in `useRef` for further usage
      monacoRef.current = editor;
    }

    
    const databasesType = ["Mongodb", "MySQL", "PostgreSQL", "SQLite", "SQL Server", "MariaDB", "Oracle", "Cassandra", "Firebase", "IBM", "ElasticSearch", "ClickHouse", "Google", "Redis", "Neo4j"]
const [databaseType, setDatabaseType] = useState("")

        if(true) {
      return (
      <div style={{paddingLeft: "1rem", paddingRight:"1rem", height:"500px", width:"100%",overflow:"auto", background:"white"}}>  
        {/* <EditDbModal 
        widgetId={widgetSettings}
        open={editDbModal}
        setOpen={setEditDbModal}
        /> */}
       {!dbonly ? <FontAwesomeIcon
                className="modal_x"
                style={{top:10}}
                icon={faTimes}
                onClick={() => {
                    dispatch(ACTION_VIEW_SETTINGS_RESET())
                }}
              /> : null }
          <div style={{width:"100%", fontSize:"2vmax", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent:"center"}}>{widgetName}</div>
          {!dbonly ? <div style={{display:"flex", width:"100%"}}>
                    <div onClick={() => setActiveView("recordSource")} className={`viewContainer`} style={{fontWeight: activeView == "recordSource"? "600" : "500"}}>
                        <span className="viewWN">Record Sources</span>
                            <div className={`${activeView == "recordSource"? "activeView" : "hoverView"}`} />
                    </div>
                    {/* <div onClick={() => setActiveView("triggers")} className={`viewContainer`} style={{fontWeight: activeView == "triggers"? "600" : "500"}}>
                        <span className="viewWN">triggers</span>
                            <div className={`${activeView == "triggers"? "activeView" : "hoverView"}`} />
                    </div> */}
                    <div onClick={() => setActiveView("events")} className={`viewContainer`} style={{fontWeight: activeView == "events"? "600" : "500"}}>
                        <span className="viewWN">events</span>
                            <div className={`${activeView == "events"? "activeView" : "hoverView"}`} />
                    </div>
                    <div onClick={() => setActiveView("appearance")} className={`viewContainer`} style={{fontWeight: activeView == "appearance"? "600" : "500"}}>
                        <span className="viewWN">appearance</span>
                            <div className={`${activeView == "appearance"? "activeView" : "hoverView"}`} />
                    </div>

                    <div onClick={() => setActiveView("Widgets")} className={`viewContainer`} style={{fontWeight: activeView == "Widgets"? "600" : "500"}}>
                        <span className="viewWN">Widgets</span>
                            <div className={`${activeView == "Widgets"? "activeView" : "hoverView"}`} />
                    </div>
                    {/* <div onClick={() => setActiveView("restrictions")} className={`viewContainer`} style={{fontWeight: activeView == "restrictions"? "600" : "500"}}>
                        <span className="viewWN">restrictions</span>
                            <div className={`${activeView == "restrictions"? "activeView" : "hoverView"}`} />
                    </div> */}
          </div> : null}
                
          {activeView == "recordSource" ? (<><h1 className="header noBold" style={{width:"100%", justifyContent:"center", display:"flex",  marginTop:"2rem"}}>{dataBaseAllTable ? "select a table" : dataBaseAllDb ? "select a database " : ""}</h1>
          <div className="form-group-container task-form">
          {dataBaseAllTable ? (
              dataBaseAllTable.map(x => (
                <div onClick={() => {
                  setDataBaseTable(x.name)
                  addDataBase(dataBaseDb, x.name, widgetSettings)
                }} className="dbSelector">{x.name}</div>
              ))
          ) : dataBaseAllDb ? (
              dataBaseAllDb.databases.map(x => (
                <div onClick={() => {
                  setDataBaseDb(x.name)
                  addDataBase(x.name)
                }} className="dbSelector">{x.name}</div>
              ))
          ) : <div>
          {!addActionActive ? <><div onClick={() => setAddActionActive(true)} style={{cursor:"pointer", border:"2px solid green", width:"100%", height:"50px", margin:"30px 0", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"18px", fontWeight:"bold", borderRadius:"8px"}}>
                    <div>Connect Your Database</div>
                  </div>                   <div class="hr-sect">OR</div>
                  </>: null}

                  {!addActionActive ? 


<div className="auth-group">
                           <div className="auth-label">Create new database</div>
                         <Dropdown
                                 options={databasesType}
                                 controlClassName="auth-input" 
                                 arrowClosed={<span className="arrow-closed arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowDownDark}
                     /></span>}
                                 arrowOpen={<span className="arrow-open arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowUp}
                     /></span>}
                                 placeholder={"Select database type"}
                                 value={databaseType}
                                 onChange={(e) => {
                                    setDatabaseType(e.value)
                                  }}
                               />
                         </div> : null}

          {addActionActive ? <>
            <h1 className="header" style={{width:"100%", justifyContent:"space-between", display:"flex",  marginTop:"2rem" , fontSize:"1.5rem"}}><span style={{fontSize:"1.4rem", display:"flex", alignItems:"center", justifyContent:"center", color:"darkgray", cursor:"pointer"}} onClick={() => {
                 setAddActionActive(null) 
                  }}> {`BACK`}</span></h1>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"50px", width:"100%"}}>
                  
                  <div style={{width:"100%", marginBottom:"12px"}}>Database type</div>
                  <div style={{width:"100%"}}> 
                  </div>
                  </div>

                  <div className="auth-group">
                  <Dropdown
                                 options={databasesType}
                                 controlClassName="auth-input" 
                                 arrowClosed={<span className="arrow-closed arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowDownDark}
                     /></span>}
                                 arrowOpen={<span className="arrow-open arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowUp}
                     /></span>}
                                 placeholder={"Select database type"}
                                 value={databaseType}
                                 onChange={(e) => {
                                    setDatabaseType(e.value)
                                  }}
                               />
                               </div>

                  <div className="auth-group">
                    <TextInput 
                      type={`${"DataBaseName"}`}
                      label={`${"Database URL"}`}
                      value={dataBaseUrl}
                      setValue={setDataBaseUrl}
                      placeHolder={`Enter Your Database Connect URL`}
                    />

                    
                    </div> 
                    {/* {!dataBaseAllDb && <div
              className={`${dataBaseUrl ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={dataBaseUrl ?  () => addDataBase() : null }
            >
              
            {`Next`}
            </div>} */}
                    </> :
                  dbs?.map((x, i) => (
            <div 
             onClick={() => setEditDbModal(x)}
             key={i} style={{cursor:"pointer", border:"2px solid gray", width:"100%", height:"50px", margin:"30px 0", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"18px", fontWeight:"bold", borderRadius:"8px"}}>
            {/* <div>{x.db.url || "No URL"}</div> */}
            <div>{x.db.db || "No DB"} - {x.db.table || "No Table"} </div>
          </div>
          ))} 
          </div>}
          </div>
            </> ) : activeView == "triggers" ? (
              <><h1 className="header noBold" style={{width:"100%", justifyContent:"center", display:"flex",  marginTop:"2rem"}}>Triggers</h1>
          <div className="form-group-container task-form">
          
          </div>
           {false && <div
              className={`${true ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={true ?  () => null : null }
            >
              
            {`Next`}
            </div>}
            </> 
            ) : activeView == "events" ? (
              activeEvent ? (
                <>
                <>
                  <h1 className="header" style={{width:"100%", justifyContent:"space-between",display:"flex",  marginTop:"2rem" , fontSize:"1.5rem"}}><span style={{fontSize:"1.4rem", display:"flex", alignItems:"center", color:"darkgray", cursor:"pointer"}} onClick={() => {
                    setCodeError("")
                    setUpdateMode(false)
                    addActionActive ?  setAddActionActive(null) : setActiveEvent("")
                  }}> {`< BACK`}</span>{activeEvent}</h1>
                 
                  
                  {addActionActive ? <>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"50px", width:"100%"}}>
                  
                  <div style={{fontWeight:600, width:"100%"}}>ActionType</div>
                  <div style={{width:"100%"}}>
                  <Select
                isSearchable
                isClearable
                options={[
                  {label: "Code", value:"Code"},
                  {label: "Alert", value:"Alert"},
                  {label: "Message", value:"Message"},
                  {label: "Filter", value: "Filter"},
                  {label: "Inject", value:"Inject"},
                  {label: "Navigate", value:"Navigate"},
                  // {label: "Filter Data", value: "Filter"},
                ]}
                value={eventType ? {label:eventType, value:eventType} : {}}
                onChange={e => setEventType(e.value)}
                />
                </div>
                </div>
                {eventType == "Filter" && allNodes?.length &&<div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"50px", width:"100%"}}>

                <div style={{fontWeight:600, width:"100%"}}>Data to filter</div>
                <div style={{width:"100%"}}>
                <Select
                isSearchable
                isClearable
                options={allNodes}
                value={selectedNode}
                onChange={e => setSelectedNode(e)}
                />
                </div>
                </div>}

                {eventType == "Inject" && (
                  <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"50px", width:"100%"}}>
                  
                  <div style={{fontWeight:600, width:"100%"}}>Select Node</div>
                  <div style={{width:"100%"}}>
                  <Select
                    isSearchable
                    isClearable
                    options={[
                      {label: "Card", value:"card_widget"},
                      {label: "Grid", value:"grid_widget"},
                      {label: "Text", value:"text_widget"},
                      {label: "Other", value:"other"},
                    ]}
                    value={otherContent ? {label:"Other", value:"other"} : eventContent ? {label:[
                      {label: "Card", value:"card_widget"},
                      {label: "Grid", value:"grid_widget"},
                      {label: "Text", value:"text_widget"},
                      {label: "Other", value:"other"},
                    ].find(x => x.value == eventContent)?.label, value:eventContent} : {} }
                    onChange={e => {
                    if(e.value == "other") {
                      setOtherContent(true)
                      setEventContent("")
                    } else {
                      setOtherContent(false)
                      setEventContent(e.value)
                    }
                    }}
                    />
                </div>
                </div>
                )}
                {(eventType == "Code" || eventType == "Alert" || eventType == "Message" || (eventType == "Inject" && otherContent)) ? <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"50px", width:"100%"}}>
                  
                  <div style={{fontWeight:600, width:"100%"}}>{eventType} {eventType == "Inject" ? "Element " : "Content"} <span style={{color:"red"}}>{codeError}</span></div>
                  <div style={{width:"100%"}}>
                 {allNodes?.length ? eventType == "Code" ? (
                   <Editor 
                   height="300px"
                   defaultLanguage="javascript"
                   defaultValue={getCode(activeEvent?.replaceAll(' ',''))}
                   onValidate={handleEditorValidation}
                   onChange={(e) => setEventContent(e)}
                   beforeMount={handleEditorWillMount}
                   onMount={handleEditorDidMount}
                   />
                 ) : (
                  <TextInput 
                      type={`${eventType} Content`}
                      label={"You can type a content to be fired once the event applied"}
                      value={eventContent}
                      area={true}
                      setValue={setEventContent}
                      placeHolder={`Enter ${eventType} content`}
                    />
                 ) : null} 
                </div>
                </div> : eventType == "Filter" ? (
                  <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"50px", width:"100%"}}>

                  <div style={{fontWeight:600, width:"100%"}}>Table key</div>
                  <div style={{width:"100%"}}>
                  <Select
                  isSearchable
                  isClearable
                  options={allKeys.find(x => x.widget == selectedNode?.widget)?.keys || []}
                  value={tableKey}
                  onChange={e => setTableKey(e)}
                  />
                  </div>
                  </div>
                ) : eventType == "Navigate" ? (
                  <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"50px", width:"100%"}}>

                  <div style={{fontWeight:600, width:"100%"}}>View</div>
                  <div style={{width:"100%"}}>
                  <Select
                  isSearchable
                  isClearable
                  options={views.map(x => {
                    return {label: x.name, value:x._id}
                  }) || []}
                  value={selectedToNavigate}
                  onChange={e => setSelectedToNavigate(e)}
                  />
                  </div>
                  </div>
                ) : null }
                { !updateMode ? <div
                  className={`${!codeError ? "auth-button addUser": "auth-button addUser disable-button"}`}
                  style={{maxWidth:"200px"}}
                  onClick={!codeError ?  () => addEventFunc(
                    selectedEvent,
                    selectedNode,
                    eventType,
                    eventContent,
                    eventType == "Navigate" ? selectedToNavigate : tableKey
                  ) : null }
                > 
            {`Save`}
                </div> : null}
                
                </> : (
                  <div>
                    <div onClick={() => setAddActionActive(true)} style={{cursor:"pointer", border:"2px solid green", width:"100%", height:"50px", margin:"30px 0", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"18px", fontWeight:"bold", borderRadius:"8px"}}>
                    <div>Add New Action +</div>
                  </div>
                  {eventsDataArr?.filter(x => x.widget == widgetSettings && x?.event?.eventName == activeEvent?.replaceAll(' ','')).map((x,i) => (
                    <div onClick={() => editAction(x)} key={i} style={{cursor:"pointer", border:"2px solid gray", width:"100%", height:"50px", margin:"30px 0", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"18px", fontWeight:"bold", borderRadius:"8px"}}>
                    <div>{x.event.eventType} {x.node} {x.event.key}</div>
                  </div>
                  ))}
                  </div>
                )}
                </>
                
                </>
              ) : (
              <><h1 className="header noBold" style={{width:"100%", justifyContent:"space-between", display:"flex", marginTop:"2rem"}}>
                
                Events
                {(<div className="rightActions" style={{display:"flex", height:"40px", }}>
                <div className={`tableHeaderAdd viewContainers ${activeEventView == "grid" && "viewActiveContainer"}`}>
                <FontAwesomeIcon onClick={() => setActiveEventView("grid")} style={{fontSize: activeEventView == "grid" ? "1.7rem" : "1.3rem", cursor:"pointer", color:activeEventView == "grid" ? "white" : "#5C92BF"}}  icon={faThLarge} />
                </div>
                <div className={`tableHeaderAdd viewContainers ${activeEventView == "list" && "viewActiveContainer"}`}>
                <FontAwesomeIcon onClick={() => setActiveEventView("list")} style={{fontSize: activeEventView == "list" ? "1.7rem" : "1.3rem", cursor:"pointer",  color:activeEventView == "list" ? "white" : "#5C92BF"}}   icon={faTh} />
                </div>
                </div> 
                )}
                </h1>
          <div className="form-group-container task-form" style={{display:"flex", flexDirection:"column", flexWrap:"wrap"}}>
                {false ? <div></div> : (
                  <>
                  <TextInput 
                      type="addUsers"
                      label="Events Search"
                      nextLabel={null}
                      value={eventsSearch ? eventsSearch : ""}
                      setValue={setEventsSearch}
                      placeHolder={`Events Search`}
                    />
                  <div style={{height:"100%", width:"100%", display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
             { filterEventsList?.length ? filterEventsList.map((x, i) => (
              activeEventView == "grid" ? <div onClick={() => {
                setActiveEvent(x.label)
                setSelectedEvent(x)
              }
                } key={x} style={{display:"flex", justifyContent:"flex-start" , alignItems:"center", width:"100%", height:"25px"}}>
              <div style={{width:"50%", border:"2px solid gray", height:"100%"}}>{x}</div>
              <div style={{width:"50%", border:"2px solid gray", height:"100%"}}>add new event</div>
            </div> : (
               <div onClick={() => {
                setActiveEvent(x.label)
                setSelectedEvent(x)
               }
                } key={x} className="eventBox" title={x.label} style={{position:"relative", margin:"10px",display:"flex", textAlign:"center", justifyContent:"center" , flexDirection:"column", alignItems:"center", width:"90px", height:"90px", borderRadius:"8px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", border: eventsDataArr.find(y => y.event.eventName == x.value && y.widget == widgetSettings) ? "2px solid green" : "0px"}}>
             {x.label}
                {eventsDataArr.find(y => y.event.eventName == x.value && y.widget == widgetSettings) && <div style={{position:"absolute", fontSize:"12px", bottom:"3px", right:"5px", color:"green", zIndex:"3"}}>{eventsDataArr?.filter(y => y.widget == widgetSettings && y?.event?.eventName == x.value)?.length} actions</div>}
            </div>
            )
                
                )) : eventsList.map((x, i) => (
                activeEventView == "grid" ? <div onClick={() => {
                  setActiveEvent(x.label)
                  setSelectedEvent(x)
                }
                  } key={x.value} style={{display:"flex", justifyContent:"flex-start" , alignItems:"center", width:"100%", height:"25px"}}>
                  <div style={{width:"50%", border:"2px solid gray", height:"100%"}}>{x.label}</div>
                  <div style={{width:"50%", border:"2px solid gray", height:"100%"}}>add new event</div>
                </div> : (
                   <div onClick={() => {
                    setActiveEvent(x.label)
                    setSelectedEvent(x)
                   }
                    } key={x.value} className="eventBox" title={x.label} style={{position:"relative", margin:"10px",display:"flex", textAlign:"center", justifyContent:"center" , flexDirection:"column", alignItems:"center", padding:"20px", borderRadius:"8px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", border: eventsDataArr?.find(y => y.event.eventName == x.value && y.widget == widgetSettings) ? "2px solid green" : "0px"}}>
                 {x.label}
                 {eventsDataArr?.find(y => y.event.eventName == x.value && y.widget == widgetSettings) && <div style={{position:"absolute", fontSize:"12px", bottom:"3px", right:"5px", color:"green", zIndex:"3"}}>{eventsDataArr?.filter(y => y.widget == widgetSettings && y?.event?.eventName == x.value)?.length} actions</div>}

                </div>
                )
                ))} 
              </div>
              </> 
                )} 
                
          </div>
            </> )
            ) : activeView == "appearance" ? (
              <><h1 className="header noBold" style={{width:"100%", justifyContent:"center", display:"flex",  marginTop:"2rem"}}>Appearance</h1>
          <div className="form-group-container task-form">
          <Sidebar />
          </div>
           {false && <div
              className={`${true ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={true ?  () => null : null }
            >
              
            {`Next`}
            </div>}
            </> 
            ) : activeView == "Widgets" ? (
              <><h1 className="header noBold" style={{width:"100%", justifyContent:"center", display:"flex",  marginTop:"2rem"}}>Widgets Connections</h1>
          <div className="form-group-container task-form">
          {allNodes?.length ? <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"50px", width:"100%"}}>

                <div style={{fontWeight:600, width:"100%"}}>Connect to widget</div>
                <div style={{width:"100%"}}>
                <Select
                isSearchable
                isClearable
                options={allNodes}
                value={selectedNode}
                onChange={e => setSelectedNode(e)}
                />
                </div>
                </div> : null}

                {selectedNodes?.length ? selectedNodes.map(x => (
                  <div style={{border:"2px solid gray", width:"100%", height:"100px", margin:"30px 0", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"30px", fontWeight:"bold", borderRadius:"8px"}}>
                  <div>{x?.label}</div>
                  </div>
                )) : null}                
          </div>
           {false && <div
              className={`${true ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={true ?  () => null : null }
            >
              
            {`Next`}
            </div>}
            </> 
            ) : null}
        </div> 
      )
    }
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%", width:"45vw"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(false)
                    updateMode&&updateMode(false)
                    setEventType && setEventType(null)
                    dispatch(ACTION_VIEW_SETTINGS_RESET())
                }}
              />  
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>Connect a database</h1>
          <div className="form-group-container task-form">
          <div>
          
          <div className="auth-group">
                    <TextInput 
                      type={`${"DataBaseName"}`}
                      label={`${"Database URL"}`}
                      value={dataBaseUrl}
                      setValue={setDataBaseUrl}
                      placeHolder={`Enter Your Database Connect URL`}
                    />
                    </div>  
          </div>
          </div>
            <div
              className={`${dataBaseUrl ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={dataBaseUrl ?  addDataBase : null }
            >
              
            {`Connect`}
            </div>
        </div> : (<DataSpinner />)}
      </Modal>
  )}

  export default ViewSettings
       