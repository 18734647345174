import {AccordionSettings} from "./AccordionSettings"
import { useEditor, useNode } from "@craftjs/core";
import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export const AccordionN = (props) => {
    const {
      connectors: { connect },
    } = useNode((node) => ({
      selected: node.events.selected,
    }));
  
    const {
      setActiveView,
dataBaseDataArr,
      eventsDataArr,
      applyFilter
      } = props

      const [resultsArr, setResultsArr] = useState(null)
      useEffect(() => {
        if(dataBaseDataArr) {
          console.log(dataBaseDataArr)
          let newArr = dataBaseDataArr?.results
          let db = dataBaseDataArr.db
          setResultsArr(newArr)
        }
      },[dataBaseDataArr])
    const { text, textComponent, color, ...otherProps } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [activeSelect, setActiveSelect] = useState("Organizations")

  const {
    enabled,
    connectors, 
    actions,
    json,
    nodes
  } = useEditor((state, query) => ({
    enabled: state.options.enabled || false,
    json: query.serialize() ,
    nodes: state.nodes
  }));

  const changeView = (id) => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = {_id: id}
    })
  }
  const changeSelect = (ob) => {
    setActiveSelect(ob.name)
    changeView(ob._id)
  }
  
  const resetView = () => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = null
    })
    setActiveSelect("Organizations")
  }

  useEffect(() => {
    if(eventsDataArr) {

      console.log(eventsDataArr)
    }
  },[eventsDataArr])
    return (        
    <div ref={connect}>       <div className="d-flex p-5">

        <Dropdown isOpen={dropdownOpen} style={{width:"100%"}} toggle={toggle}>
          <DropdownToggle style={{width:"100%"}}>{activeSelect}</DropdownToggle>
          <DropdownMenu style={{width:"100%"}}>
            {resultsArr ? resultsArr.length ? (
              resultsArr.map((x,i) => (
            <DropdownItem key={(i)} onClick={() => changeSelect(x)}>{x.name || x._id}</DropdownItem>
              ))
            ) : (
            <DropdownItem disabled>Empty</DropdownItem>
            ) : (
            <DropdownItem disabled>Loading</DropdownItem>
            )}
            {activeSelect !== "Organizations" && (
            <DropdownItem onClick={() => {
              resetView()
            }}
            style={{color:"red"}}
            >Reset</DropdownItem>

            )}
          </DropdownMenu>
        </Dropdown>      </div>      

      </div>

    );
  };
  
  AccordionN.craft = {
    displayName: 'Accordion',
    props: {
      background: { r: 255, g: 255, b: 255, a: 0.5 },
      color: { r: 92, g: 90, b: 90, a: 1 }, 
      buttonStyle: 'full',
      text: 'Button',
      margin: ['5', '0', '5', '0'],   
      applyFilter: null
    },
    related: {
      toolbar: AccordionSettings,
    },
  };
  