import * as React from 'react';
import './index.scss';
import { SampleBase } from './sample-base';

import { DialogComponent } from '@syncfusion/ej2-react-popups';

class Component extends SampleBase {
    buttons;
    animationSettings;
    dialogInstance;
    buttonEle;
    buttonRef;
    constructor(props) {
        super(props);
        this.state = {
            hideDialog: true
        };
        this.buttonRef = element => {
            this.buttonEle = element;
        };
        this.buttons = [{
                click: this.dlgButtonClick,
                buttonModel: {
                    content: 'Learn More',
                    isPrimary: true
                }
            }];
        this.animationSettings = { effect: 'None' };
    }
    buttonClick() {
        this.setState({ hideDialog: true });
    }
    dlgButtonClick() {
        window.open('https://www.syncfusion.com/company/about-us');
    }
    dialogClose() {
        this.setState({ hideDialog: false });
        this.buttonEle.style.display = "block";
    }
    dialogOpen() {
        this.buttonEle.style.display = "none";
    }
    render() {
        return (<div className='control-pane'>
                <div id='targetElement' className='control-section defaultDialog dialog-target'>
                    {/* <button className="e-control e-btn dlgbtn" ref={this.buttonRef} onClick={this.buttonClick.bind(this)} id="dialogBtn"> Open</button> */}
                    <DialogComponent {...this.props.allEvents} id="defaultdialog" animationSettings={this.animationSettings} visible={this.state.hideDialog} width={'500px'} ref={dialog => this.dialogInstance = dialog} target={'#targetElement'} header={`${this.props.header}`}>
                        <div>
                            <div>
                                {this.props.content}
                </div>
                        </div>
                    </DialogComponent>
                </div>
            </div>
      );
    } 
}

export default Component