import { gql } from "apollo-boost";

const addAppMutation = gql`
  mutation(
    $workspace: ID,
    $organization: ID,
    $name: String,   
    $user: ID,
    $users: [String],
    $views: [String],
    $editors: [String]
  ) {
    addApp(
    workspace:$workspace,
    organization:$organization,
    name:$name,    
    user: $user
    users:$users     
    views:$views    
    editors: $editors

    ) {
    _id
    workspace,
    organization,
    name
    user,
    users    
    views    
    editors

    }
  }
`;

export default addAppMutation;
