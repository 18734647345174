const TRIGGER_VIEW = "TRIGGER_VIEW";
const TRIGGER_VIEW_RESET = "TRIGGER_VIEW_RESET";

const triggerViewReducer = (
  state = { view: null },
  action
) => {
  switch (action.type) {
    case TRIGGER_VIEW:
      return { ...state, view: action.payload };
    case TRIGGER_VIEW_RESET:
      return { ...state, view: null};
    default:
      return { ...state };
  }
};

export default triggerViewReducer;
