import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Navigation14 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <header class="border-b border-gray-100">
  <div class="mx-auto flex h-16 max-w-screen-2xl items-center justify-between sm:px-6 lg:px-8">
    <div class="flex items-center">
      <Element canvas style={{ width: "100%" }} id={"div421848"} is="div">
            <Button classn={"p-2 sm:mr-4 lg:hidden cursor-pointer"}>
              
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      
            </Button>
          </Element>

      <Element canvas style={{ width: "100%" }} id={"div883932"} is="div">
            <Button href={""} classn={"flex cursor-pointer"}>
              
        <span class="inline-block h-10 w-32 rounded-lg bg-gray-200"></span>
      
            </Button>
          </Element>
    </div>

    <div class="flex flex-1 items-center justify-end">
      <nav class="hidden lg:flex lg:gap-4 lg:text-xs lg:font-bold lg:uppercase lg:tracking-wide lg:text-gray-500">
        <Element canvas style={{ width: "100%" }} id={"div214758"} is="div">
            <Button href={"/about"} classn={"block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current hover:text-red-700 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="813487" is="div"><Text text="About" /></Element>} 
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div952006"} is="div">
            <Button href={"/news"} classn={"block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current hover:text-red-700 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="785338" is="div"><Text text="News" /></Element>} 
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div630462"} is="div">
            <Button href={"/products"} classn={"block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current hover:text-red-700 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="907884" is="div"><Text text="Products" /></Element>} 
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div368797"} is="div">
            <Button href={"/contact"} classn={"block h-16 border-b-4 border-transparent leading-[4rem] hover:border-current hover:text-red-700 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="260812" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
      </nav>

      <div class="ml-8 flex items-center">
        <div class="flex items-center divide-x divide-gray-100 border-x border-gray-100">
          <span>
            <Element canvas style={{ width: "100%" }} id={"div916546"} is="div">
            <Button href={"/cart"} classn={"block border-b-4 border-transparent p-6 hover:border-red-700 cursor-pointer"}>
              
              <svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
              </svg>

              <span class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="470122" is="div"><Text text="Cart" /></Element>} </span>
            
            </Button>
          </Element>
          </span>

          <span>
            <Element canvas style={{ width: "100%" }} id={"div457130"} is="div">
            <Button href={"/account"} classn={"block border-b-4 border-transparent p-6 hover:border-red-700 cursor-pointer"}>
              
              <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>

              <span class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="315080" is="div"><Text text="Account" /></Element>} </span>
            
            </Button>
          </Element>
          </span>

          <span class="hidden sm:block">
            <Element canvas style={{ width: "100%" }} id={"div398702"} is="div">
            <Button href={"/search"} classn={"block border-b-4 border-transparent p-6 hover:border-red-700 cursor-pointer"}>
              
              <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>

              <span class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="273784" is="div"><Text text="Search" /></Element>} </span>
            
            </Button>
          </Element>
          </span>
        </div>
      </div>
    </div>
  </div>
    </header>

      </div>;
};
Navigation14.craft = {
  displayName: "Navigation 14"
};