import { gql } from "apollo-boost";

const addDataBaseMutation = gql`
  mutation(
    $view: ID,
    $url: String,
    $db: String,
    $table: String,
    $widget: String,
    
  ) {
    addDataBase(        
    view:$view,
    url:$url,
    db:$db,
    table:$table,
    widget:$widget,
    ) {
    _id
    view,
    url,
    db,
    table,
    widget,
    done
    }
  }
`;

export default addDataBaseMutation;
