import { gql } from "apollo-boost";

const allRecordsQuery = gql`
  
    query(
    $collectionId: ID
    $formId: ID
  ) { 
    allRecords (
      collectionId: $collectionId
      formId: $formId
    ) {
    _id
    data
    collectionId,
    formId
    name,
    }
  }
  
`;

export default allRecordsQuery;
