import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { useLocation } from "react-router-dom";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import ACTION_LOGIN_EMAIL_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";

import arrowDownUsers from "../../../assests/images/arrowDownUsers.svg"
import searchInput from "../../../assests/images/searchInput.svg"
import setting from "../../../assests/images/setting.svg"
import arrowRight from "../../../assests/images/arrowRight.svg"
import edit from "../../../assests/images/edit.svg"
import trash from "../../../assests/images/trash.svg"
import add from "../../../assests/images/add.svg"
import {useMutation} from "@apollo/client"
import addIndustryMutation from "../../../graphql/mutations/industry/addIndustryMutation"
import {AccountTable as IndustryTable} from "../../resuable/accountTable"
import AddIndustryModal from "../../resuable/modals/addIndustryModal";
import { DataSpinner } from "../../resuable/spinner/dataSpinner";
const SuperIndustries = ({industrys, industrysData, setIndustrysData, industrysRefetch}) => {
    let location = useLocation()

    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const dispatch = useDispatch()



    const industryColumn = [
        
        {
            name: 'name',
            selector: row => row.name,
           
        },
    ];
    

    // add user modal


    useEffect(() => {
        if(industrys) {
            setIndustrysData(industrys.allIndustrys.map(x => {
                return {
                    name : x.name,
                }
            }))
        }
    },[industrys])

    
// get started information
const [industryAddModal, setIndustryAddModal] = useState(null)

// add user info

const [industryName, setIndustryName] = useState("")



const [addIndustry, {error: addIndustryError, data: addIndustryData, loading: addIndustryLoading}] = useMutation(addIndustryMutation)



const addIndustryFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    if(industryName) {
    addIndustry({
        variables:{
            name: industryName
        }
    }).then((res) => {
        industrysRefetch()
        setIndustryAddModal(null)   
        setIndustryName("")
        dispatch(ACTION_LOADING_SPINNER_RESET())
    })
}
}

useEffect(() => {
    if(industrys) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
},[industrys])


 return (
        <div className="adminContent column">
            {returnToHomeScreen()}
            <AddIndustryModal 
                open={industryAddModal}
                setOpen={setIndustryAddModal}
                addIndustry={addIndustryFunc}
                industryName={industryName}
                setIndustryName={setIndustryName}
                addIndustryLoading={addIndustryLoading}
            />
            <div className="workspaceHeader">
                <div className="workspaceName" style={{display:"flex", alignItems:"center"}}>
                        Industries
                        <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => setIndustryAddModal("Industry")}
                        
                        />
                </div>
                <div className="workspaceButtons">
                    <div className={`routeContainer sideSearch userSearch`} style={{width:"269px" , margin:"0px", fontWeight:"normal", justifyContent:"flex-start"}}>
                    <img
                                className="routeIcon"
                                src={searchInput}
                    />
                    <div className="searchT" style={{margin:"0 16px", color:"#5E6A7A"}}>
                        Search                        
                    </div>
                    </div>
                    <div className="adminButton" style={{width:"fit-content"}}> 
                    <span style={{margin:"0 16px", color:"#5E6A7A"}} className="buttonIconImage">Newest</span>
                    <img className="buttonIconImage" style={{margin:"0 12px"}} src={arrowDownUsers} />
                    </div>
                    <div className="adminButton" >
                    <img className="buttonIconImage" src={setting} />
                    </div>

                </div>
            </div>
            <div className="workspaceContainer">
            <IndustryTable
                accountColumns={industryColumn}
                accountData={industrysData}
                />
            </div>
        </div>
    )
}

export default SuperIndustries