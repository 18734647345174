import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Navigation8 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <nav class="mx-auto flex max-w-3xl items-center justify-between p-4">
  <Element canvas style={{ width: "100%" }} id={"div392248"} is="div">
            <Button href={"/"} classn={"inline-flex h-10 w-10 items-center justify-center rounded-lg bg-gray-100 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="925357" is="div"><Text text="👋" /></Element>} 
            </Button>
          </Element>

  <ul class="flex items-center gap-2 text-sm font-medium text-gray-500">
    <li class="hidden lg:block">
      <Element canvas style={{ width: "100%" }} id={"div262674"} is="div">
            <Button href={"/"} classn={"rounded-lg px-3 py-2 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="341033" is="div"><Text text="Home" /></Element>} 
            </Button>
          </Element>
    </li>

    <li><Element canvas style={{ width: "100%" }} id={"div882038"} is="div">
            <Button href={""} classn={"rounded-lg px-3 py-2 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="357723" is="div"><Text text="Projects" /></Element>} 
            </Button>
          </Element></li>

    <li>
      <Element canvas style={{ width: "100%" }} id={"div475544"} is="div">
            <Button href={""} classn={"inline-flex items-center rounded-lg px-3 py-2 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="552974" is="div"><Text text="External" /></Element>} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="ml-1.5 h-4 w-4">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
        </svg>
      
            </Button>
          </Element>
    </li>
  </ul>
    </nav>

      </div>;
};
Navigation8.craft = {
  displayName: "Navigation 8"
};