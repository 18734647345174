import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
        <div class="container px-6 py-10 mx-auto">
            <div class="lg:flex lg:-mx-6">
                <div class="lg:w-3/4 lg:px-6">
                    <Element canvas style={{ width: "100%" }} id={"div181180"} is="div">
          <ImageC class="object-cover object-center w-full h-80 xl:h-[28rem] rounded-xl" src="https://images.unsplash.com/photo-1624996379697-f01d168b1a52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                    <div>
                        <p class="mt-6 text-sm text-blue-500 uppercase"> {<Element canvas style={{display:"inline-block"}} id="704397" is="div"><Text text="Want to know" /></Element>} </p>

                        <h1 class="max-w-lg mt-4 text-4xl font-semibold leading-tight text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="844758" is="div"><Text text="What do you want to know about UI" /></Element>} </h1>

                        <div class="flex items-center mt-6">
                            <Element canvas style={{ width: "100%" }} id={"div464253"} is="div">
          <ImageC class="object-cover object-center w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1531590878845-12627191e687?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt=""  />
        </Element>

                            <div class="mx-4">
                                <h1 class="text-sm text-gray-700 dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="296687" is="div"><Text text="Amelia. Anderson" /></Element>} </h1>
                                <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="791943" is="div"><Text text="Lead Developer" /></Element>} </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-8 lg:w-1/4 lg:mt-0 lg:px-6">
                    <div>
                        <h3 class="text-blue-500 capitalize"> {<Element canvas style={{display:"inline-block"}} id="478906" is="div"><Text text="Design instument" /></Element>} </h3>

                        <Element canvas style={{ width: "100%" }} id={"div872915"} is="div">
            <Button href={"#"} classn={"block mt-2 font-medium text-gray-700 hover:underline hover:text-gray-500 dark:text-gray-400 "}>
               {<Element canvas style={{display:"inline-block"}} id="689704" is="div"><Text text="How to raise $100k+ by using blox ui kit on your design" /></Element>} 
            </Button>
          </Element>
                    </div>

                    <hr class="my-6 border-gray-200 dark:border-gray-700" />

                    <div>
                        <h3 class="text-blue-500 capitalize"> {<Element canvas style={{display:"inline-block"}} id="141218" is="div"><Text text="UI Resource" /></Element>} </h3>

                        <Element canvas style={{ width: "100%" }} id={"div565623"} is="div">
            <Button href={"#"} classn={"block mt-2 font-medium text-gray-700 hover:underline hover:text-gray-500 dark:text-gray-400 "}>
               {<Element canvas style={{display:"inline-block"}} id="212693" is="div"><Text text="Should you creat UI Product by using Blox?" /></Element>} 
            </Button>
          </Element>
                    </div>

                    <hr class="my-6 border-gray-200 dark:border-gray-700" />

                    <div>
                        <h3 class="text-blue-500 capitalize"> {<Element canvas style={{display:"inline-block"}} id="527828" is="div"><Text text="Premium Collection" /></Element>} </h3>

                        <Element canvas style={{ width: "100%" }} id={"div343585"} is="div">
            <Button href={"#"} classn={"block mt-2 font-medium text-gray-700 hover:underline hover:text-gray-500 dark:text-gray-400 "}>
               {<Element canvas style={{display:"inline-block"}} id="115957" is="div"><Text text="Top 10 Blocks you can get on Blox's collection." /></Element>} 
            </Button>
          </Element>
                    </div>

                    <hr class="my-6 border-gray-200 dark:border-gray-700" />

                    <div>
                        <h3 class="text-blue-500 capitalize"> {<Element canvas style={{display:"inline-block"}} id="688263" is="div"><Text text="Premium kits" /></Element>} </h3>

                        <Element canvas style={{ width: "100%" }} id={"div103983"} is="div">
            <Button href={"#"} classn={"block mt-2 font-medium text-gray-700 hover:underline hover:text-gray-500 dark:text-gray-400 "}>
               {<Element canvas style={{display:"inline-block"}} id="389934" is="div"><Text text="Top 10 Ui kit you can get on Blox's collection." /></Element>} 
            </Button>
          </Element>
                    </div>
                </div>
            </div>
        </div>
    </section>

      </div>;
};
Blog2.craft = {
  displayName: "Blog 2"
};