import './index.scss';
import * as React from 'react';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { SampleBase } from './sample-base';
import * as data from './dataSource.json';

class Component extends SampleBase {
  render() {
      return (<div className='control-pane'>
              <div className=' control-section'>
                  <div id='listbox-control'>
                      <h4>{this.props.placeholder}</h4>
                      <ListBoxComponent {...this.props.allEvents} dataSource={this.props.items}/>
                  </div>
              </div>
          </div>);
    }
}

export default Component