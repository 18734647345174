import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <header class="bg-white dark:bg-gray-900">
    <nav class="border-t-4 border-blue-500">
        <div class="container flex items-center justify-between px-6 py-3 mx-auto">
            <div>
                <Element canvas style={{ width: "100%" }} id={"div317831"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="837990" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
            </div>

            <Element canvas style={{ width: "100%" }} id={"div499414"} is="div">
            <Button href={"#"} classn={"my-1 text-sm font-medium text-gray-500 rtl:-scale-x-100 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 lg:mx-4 lg:my-0"}>
              
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
            
            </Button>
          </Element>
        </div>
    </nav>

    <div class="container px-6 py-16 mx-auto">
        <div class="items-center lg:flex">
            <div class="w-full lg:w-1/2">
                <div class="lg:max-w-lg">
                    <h1 class="text-2xl font-semibold text-gray-800 dark:text-white lg:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="921003" is="div"><Text text="Subscribe To The" /></Element>} <span class="text-blue-500"> {<Element canvas style={{display:"inline-block"}} id="568848" is="div"><Text text="Newsletter" /></Element>} </span></h1>

                    <p class="mt-4 text-gray-600 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="220822" is="div"><Text text="be the first to knows when our" /></Element>} <span class="font-medium text-blue-500"> {<Element canvas style={{display:"inline-block"}} id="213393" is="div"><Text text="Brand" /></Element>} </span> {<Element canvas style={{display:"inline-block"}} id="121252" is="div"><Text text="is live" /></Element>} </p>

                    <div class="flex flex-col mt-8 space-y-3 lg:space-y-0 lg:flex-row">
                        <input id="email" type="text" class="px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300" placeholder="Email Address" />

                        <Element canvas style={{ width: "100%" }} id={"div963576"} is="div">
            <Button classn={"w-full px-6 py-2.5 text-sm font-medium tracking-wider text-white uppercase transition-colors duration-300 transform bg-blue-600 rounded-lg lg:w-auto lg:mx-4 hover:bg-blue-500 focus:outline-none focus:bg-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="457742" is="div"><Text text="Subscribe" /></Element>} 
            </Button>
          </Element>
                    </div>
                </div>
            </div>

            <div class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
                <Element canvas style={{ width: "100%" }} id={"div805958"} is="div">
          <ImageC class="w-full h-full max-w-md" src="https://images.unsplash.com/photo-1508394522741-82ac9c15ba69?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=748&q=80"  />
        </Element>
            </div>
        </div>
    </div>
    </header>

      </div>;
};
Hero2.craft = {
  displayName: "Hero 2"
};