import './index.scss';
import * as React from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { SampleBase } from './sample-base';

import * as data from './dataSource.json'; 

class Component extends SampleBase {
  listObj;
  //define the filtering data
  temp = 'countries';
  searchData = data[this.temp];
  // maps the appropriate column to fields property
  fields = { text: 'name'};
  // filtering event handler to filter a Country
  onFiltering = (e) => {
      let query = new Query();
      //frame the query based on search string with filter type.
      query = (e.text !== '') ? query.where('name', 'startswith', e.text, true) : query;
      //pass the filter data source, filter query to updateData method.
      e.updateData(this.props.items, query);
  };
  render() {
      return (<div className='control-pane'>
      <div className='control-section'>
        <div id='filtering'>
          <DropDownListComponent {...this.props.allEvents} id="country" ref={(dropdownlist) => { this.listObj = dropdownlist; }} dataSource={this.props.items} filtering={this.onFiltering.bind(this)} filterBarPlaceholder={this.props.placeholder} allowFiltering={true} fields={this.fields} placeholder={this.props.placeholder} popupHeight="220px"/>
        </div>
      </div>
      
    </div>);
    }
}

export default Component