import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero6 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <nav x-data="{ isOpen: false }" class="container p-6 mx-auto lg:flex lg:justify-between lg:items-center">
        <div class="flex items-center justify-between">
            <div>
                <Element canvas style={{ width: "100%" }} id={"div922336"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="216524" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
            </div>
    
            
            <div class="flex lg:hidden">
                <Element canvas style={{ width: "100%" }} id={"div756729"} is="div">
            <Button classn={"text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"}>
              
                    <svg x-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                    </svg>
    
                    <svg x-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                
            </Button>
          </Element>
            </div>
        </div>
    
       
        <div class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white shadow-md lg:bg-transparent lg:dark:bg-transparent lg:shadow-none dark:bg-gray-900 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center">
            <div class="flex flex-col space-y-4 lg:mt-0 lg:flex-row lg:space-y-0">
                <Element canvas style={{ width: "100%" }} id={"div771794"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="834841" is="div"><Text text="Home" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div827295"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="314324" is="div"><Text text="Components" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div256409"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="896040" is="div"><Text text="Pricing" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div273886"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="697079" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div819886"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="997564" is="div"><Text text="FAQ" /></Element>} 
            </Button>
          </Element>
            </div>
    
            <Element canvas style={{ width: "100%" }} id={"div210123"} is="div">
            <Button href={"#"} classn={"block h-10 px-5 py-2 mt-4 text-sm text-center text-gray-700 capitalize transition-colors duration-300 transform border rounded-md dark:hover:bg-gray-700 dark:text-white lg:mt-0 hover:bg-gray-100 lg:w-auto"}>
               {<Element canvas style={{display:"inline-block"}} id="536633" is="div"><Text text="Contact Us" /></Element>} 
            </Button>
          </Element>
        </div>
    </nav>

    <div class="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center">
        <div class="w-full lg:w-1/2">
            <div class="lg:max-w-lg">
                <h1 class="text-3xl font-bold tracking-wide text-gray-800 dark:text-white lg:text-5xl"> {<Element canvas style={{display:"inline-block"}} id="658919" is="div"><Text text="Easiest way to create your website" /></Element>} </h1>

                <div class="mt-8 space-y-5">
                    <p class="flex items-center -mx-2 text-gray-700 dark:text-gray-200">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <span class="mx-2"> {<Element canvas style={{display:"inline-block"}} id="722623" is="div"><Text text="Clean and Simple Layout" /></Element>} </span>
                    </p>

                    <p class="flex items-center -mx-2 text-gray-700 dark:text-gray-200">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <span class="mx-2"> {<Element canvas style={{display:"inline-block"}} id="192538" is="div"><Text text="Just Copy Paste Codeing" /></Element>} </span>
                    </p>

                    <p class="flex items-center -mx-2 text-gray-700 dark:text-gray-200">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <span class="mx-2"> {<Element canvas style={{display:"inline-block"}} id="332489" is="div"><Text text="Easy to Use" /></Element>} </span>
                    </p>
                </div>
            </div>

            <div class="w-full mt-8 bg-transparent border rounded-md lg:max-w-sm dark:border-gray-700 focus-within:border-blue-400 focus-within:ring focus-within:ring-blue-300 dark:focus-within:border-blue-400 focus-within:ring-opacity-40">
                <form class="flex flex-col lg:flex-row">
                    <input type="email" placeholder="Enter your email address" class="flex-1 h-10 px-4 py-2 m-1 text-gray-700 placeholder-gray-400 bg-transparent border-none appearance-none dark:text-gray-200 focus:outline-none focus:placeholder-transparent focus:ring-0" />

                    <Element canvas style={{ width: "100%" }} id={"div987167"} is="div">
            <Button classn={"h-10 px-4 py-2 m-1 text-white transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="608444" is="div"><Text text="Join Us" /></Element>} 
            </Button>
          </Element>
                </form>
            </div>
        </div>

        <div class="flex items-center justify-center w-full h-96 lg:w-1/2">
            <Element canvas style={{ width: "100%" }} id={"div647644"} is="div">
          <ImageC class="object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl" src="https://images.unsplash.com/photo-1543269664-7eef42226a21?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt="glasses photo"  />
        </Element>
        </div>
    </div>
    </section>

      </div>;
};
Hero6.craft = {
  displayName: "Hero 6"
};