import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog6 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="474232" is="div"><Text text="From the blog" /></Element>} </h1>

        <div class="grid grid-cols-1 gap-8 mt-8 md:mt-16 md:grid-cols-2">
            <div class="lg:flex">
                <Element canvas style={{ width: "100%" }} id={"div292803"} is="div">
          <ImageC class="object-cover w-full h-56 rounded-lg lg:w-64" src="https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                <div class="flex flex-col justify-between py-6 lg:mx-6">
                    <Element canvas style={{ width: "100%" }} id={"div774013"} is="div">
            <Button href={"#"} classn={"text-xl font-semibold text-gray-800 hover:underline dark:text-white "}>
               {<Element canvas style={{display:"inline-block"}} id="564598" is="div"><Text text="How to use sticky note for problem solving" /></Element>} 
            </Button>
          </Element>
                    
                    <span class="text-sm text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="290497" is="div"><Text text="On: 20 October 2019" /></Element>} </span>
                </div>
            </div>

            <div class="lg:flex">
                <Element canvas style={{ width: "100%" }} id={"div154755"} is="div">
          <ImageC class="object-cover w-full h-56 rounded-lg lg:w-64" src="https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                <div class="flex flex-col justify-between py-6 lg:mx-6">
                    <Element canvas style={{ width: "100%" }} id={"div157696"} is="div">
            <Button href={"#"} classn={"text-xl font-semibold text-gray-800 hover:underline dark:text-white "}>
               {<Element canvas style={{display:"inline-block"}} id="776133" is="div"><Text text="How to use sticky note for problem solving" /></Element>} 
            </Button>
          </Element>

                    <span class="text-sm text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="878748" is="div"><Text text="On: 20 October 2019" /></Element>} </span>
                </div>
            </div>

            <div class="lg:flex">
                <Element canvas style={{ width: "100%" }} id={"div751477"} is="div">
          <ImageC class="object-cover w-full h-56 rounded-lg lg:w-64" src="https://images.unsplash.com/photo-1544654803-b69140b285a1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                <div class="flex flex-col justify-between py-6 lg:mx-6">
                    <Element canvas style={{ width: "100%" }} id={"div693709"} is="div">
            <Button href={"#"} classn={"text-xl font-semibold text-gray-800 hover:underline dark:text-white "}>
               {<Element canvas style={{display:"inline-block"}} id="880851" is="div"><Text text="Morning routine to boost your mood" /></Element>} 
            </Button>
          </Element>

                    <span class="text-sm text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="625162" is="div"><Text text="On: 25 November 2020" /></Element>} </span>
                </div>
            </div>

            <div class="lg:flex">
                <Element canvas style={{ width: "100%" }} id={"div760689"} is="div">
          <ImageC class="object-cover w-full h-56 rounded-lg lg:w-64" src="https://images.unsplash.com/photo-1530099486328-e021101a494a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1547&q=80" alt=""  />
        </Element>

                <div class="flex flex-col justify-between py-6 lg:mx-6">
                    <Element canvas style={{ width: "100%" }} id={"div755532"} is="div">
            <Button href={"#"} classn={"text-xl font-semibold text-gray-800 hover:underline dark:text-white "}>
               {<Element canvas style={{display:"inline-block"}} id="210198" is="div"><Text text="All the features you want to know" /></Element>} 
            </Button>
          </Element>

                    <span class="text-sm text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="202023" is="div"><Text text="On: 30 September 2020" /></Element>} </span>
                </div>
            </div>

            <div class="lg:flex">
                <Element canvas style={{ width: "100%" }} id={"div633760"} is="div">
          <ImageC class="object-cover w-full h-56 rounded-lg lg:w-64" src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1484&q=80" alt=""  />
        </Element>

                <div class="flex flex-col justify-between py-6 lg:mx-6">
                    <Element canvas style={{ width: "100%" }} id={"div339396"} is="div">
            <Button href={"#"} classn={"text-xl font-semibold text-gray-800 hover:underline dark:text-white "}>
               {<Element canvas style={{display:"inline-block"}} id="360903" is="div"><Text text="Minimal workspace for your inspirations" /></Element>} 
            </Button>
          </Element>

                    <span class="text-sm text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="953608" is="div"><Text text="On: 13 October 2019" /></Element>} </span>
                </div>
            </div>

            <div class="lg:flex">
                <Element canvas style={{ width: "100%" }} id={"div759295"} is="div">
          <ImageC class="object-cover w-full h-56 rounded-lg lg:w-64" src="https://images.unsplash.com/photo-1624996379697-f01d168b1a52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                <div class="flex flex-col justify-between py-6 lg:mx-6">
                    <Element canvas style={{ width: "100%" }} id={"div728245"} is="div">
            <Button href={"#"} classn={"text-xl font-semibold text-gray-800 hover:underline dark:text-white "}>
               {<Element canvas style={{display:"inline-block"}} id="259248" is="div"><Text text="What do you want to know about Blockchane" /></Element>} 
            </Button>
          </Element>
                    
                    <span class="text-sm text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="744786" is="div"><Text text="On: 20 October 2019" /></Element>} </span>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Blog6.craft = {
  displayName: "Blog 6"
};