import { gql } from "apollo-boost";

const allDepartmentsQuery = gql`
  
    query(
    $org: ID
  ) { 
    allDepartments (
      org: $org
    ) {
    _id
    name,
    org
    }
  }
  
`;

export default allDepartmentsQuery;
