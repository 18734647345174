import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Pricing6 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-8 mx-auto">
        <div class="max-w-2xl p-1.5 mx-auto overflow-hidden bg-gray-100 rounded-lg dark:bg-gray-800">
            <div class="grid gap-3 md:grid-cols-3">
                <Element canvas style={{ width: "100%" }} id={"div698367"} is="div">
            <Button classn={"px-3 py-2 font-medium text-gray-800 uppercase transition-colors duration-300 transform bg-transparent rounded-lg focus:outline-none dark:text-gray-200 dark:hover:bg-gray-600 hover:bg-gray-200"}>
               {<Element canvas style={{display:"inline-block"}} id="109757" is="div"><Text text="Monthly" /></Element>} 
            </Button>
          </Element>

                <Element canvas style={{ width: "100%" }} id={"div928151"} is="div">
            <Button classn={"flex items-center justify-center px-3 py-2 font-medium text-gray-800 uppercase transition-colors duration-300 transform bg-gray-200 rounded-lg dark:bg-gray-700 focus:outline-none dark:text-gray-200"}>
              
                    <span class="mx-1"> {<Element canvas style={{display:"inline-block"}} id="225043" is="div"><Text text="Biannually" /></Element>} </span>
                    <span class="text-xs mx-1 font-normal text-white bg-blue-500 rounded-full py-0.5 px-1.5"> {<Element canvas style={{display:"inline-block"}} id="577905" is="div"><Text text="save 10%" /></Element>} </span>
                
            </Button>
          </Element>

                <Element canvas style={{ width: "100%" }} id={"div186760"} is="div">
            <Button classn={"flex items-center justify-center px-3 py-2 font-medium text-gray-800 uppercase transition-colors duration-300 transform bg-transparent rounded-lg focus:outline-none dark:text-gray-200 dark:hover:bg-gray-600 hover:bg-gray-200"}>
              
                    <span class="mx-1"> {<Element canvas style={{display:"inline-block"}} id="122002" is="div"><Text text="Annually" /></Element>} </span>
                    <span class="text-xs mx-1 font-normal text-white bg-blue-500 rounded-full py-0.5 px-1.5"> {<Element canvas style={{display:"inline-block"}} id="160795" is="div"><Text text="save 20%" /></Element>} </span>
                
            </Button>
          </Element>
            </div>
        </div>

        <div class="flex flex-col items-center justify-center mt-16 space-y-6 md:items-end md:-mx-5 md:space-y-0 md:flex-row">
            <div class="w-full px-6 py-4 transition-colors duration-300 transform rounded-lg md:mx-5 md:w-96 bg-gray-50 dark:bg-gray-800">
                <div class="text-center">
                    <p class="text-4xl font-semibold text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="966778" is="div"><Text text="Basic" /></Element>} </p>
                    <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="563860" is="div"><Text text="Essentail Features" /></Element>} </p>
                    <h4 class="mt-2 text-gray-600 line-through dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="948691" is="div"><Text text="$59.99" /></Element>} </h4>
                    <h4 class="mt-2 text-5xl font-semibold text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="461704" is="div"><Text text="$53.99" /></Element>} </h4>
                    <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="396883" is="div"><Text text="/per month" /></Element>} </p>
                    <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="148892" is="div"><Text text="Bill all 6 months" /></Element>} </p>
                </div>

                <div class="mt-8 space-y-8">
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="838466" is="div"><Text text="Access all features" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="497970" is="div"><Text text="5 wathclists included" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="682441" is="div"><Text text="Chat support" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="640874" is="div"><Text text="Optimize hashtags" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="805613" is="div"><Text text="5 exclusive widgets" /></Element>} </span>
                    </div>
                </div>

                <Element canvas style={{ width: "100%" }} id={"div746475"} is="div">
            <Button classn={"w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"}>
               {<Element canvas style={{display:"inline-block"}} id="615047" is="div"><Text text="Choose Basic" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="w-full overflow-hidden transition-colors duration-300 transform rounded-lg md:mx-5 md:w-96 bg-gray-50 dark:bg-gray-800">
                <p class="py-2 text-sm text-center text-white uppercase bg-blue-500"> {<Element canvas style={{display:"inline-block"}} id="791658" is="div"><Text text="Recommended by experts" /></Element>} </p>
                <div class="px-6 py-4">
                    <div class="text-center">
                        <p class="text-4xl font-semibold text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="346268" is="div"><Text text="Pro" /></Element>} </p>
                        <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="237246" is="div"><Text text="Advanced Features" /></Element>} </p>
                        <h4 class="mt-2 text-gray-600 line-through dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="430104" is="div"><Text text="$89.99" /></Element>} </h4>
                        <h4 class="mt-2 text-5xl font-semibold text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="905923" is="div"><Text text="$80.99" /></Element>} </h4>
                        <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="743852" is="div"><Text text="/per month" /></Element>} </p>
                        <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="174734" is="div"><Text text="Bill all 6 months" /></Element>} </p>
                    </div>

                    <div class="mt-8 space-y-8">
                        <div class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>

                            <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="437566" is="div"><Text text="Access all features" /></Element>} </span>
                        </div>

                        <div class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>

                            <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="674704" is="div"><Text text="Unlimited wathclists" /></Element>} </span>
                        </div>

                        <div class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>

                            <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="401523" is="div"><Text text="Chat support" /></Element>} </span>
                        </div>

                        <div class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>

                            <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="443637" is="div"><Text text="Optimize hashtags" /></Element>} </span>
                        </div>

                        <div class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>

                            <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="686948" is="div"><Text text="10+ exclusive pro widgets" /></Element>} </span>
                        </div>
                    </div>

                    <Element canvas style={{ width: "100%" }} id={"div516564"} is="div">
            <Button classn={"w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"}>
               {<Element canvas style={{display:"inline-block"}} id="429870" is="div"><Text text="Choose Pro" /></Element>} 
            </Button>
          </Element>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Pricing6.craft = {
  displayName: "Pricing 6"
};