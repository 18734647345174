import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Content1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1"> {<Element canvas style={{display:"inline-block"}} id="575833" is="div"><Text text="ROOF PARTY POLAROID" /></Element>} </h2>
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="243280" is="div"><Text text="Master Cleanse Reliac Heirloom" /></Element>} </h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="130865" is="div"><Text text="Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag." /></Element>} </p>
    </div>
    <div class="flex flex-wrap">
      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
        <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="643741" is="div"><Text text="Shooting Stars" /></Element>} </h2>
        <p class="leading-relaxed text-base mb-4"> {<Element canvas style={{display:"inline-block"}} id="790220" is="div"><Text text="Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche." /></Element>} </p>
        <a class="text-indigo-500 inline-flex items-center"> {<Element canvas style={{display:"inline-block"}} id="101955" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
        <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="176688" is="div"><Text text="The Catalyzer" /></Element>} </h2>
        <p class="leading-relaxed text-base mb-4"> {<Element canvas style={{display:"inline-block"}} id="429937" is="div"><Text text="Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche." /></Element>} </p>
        <a class="text-indigo-500 inline-flex items-center"> {<Element canvas style={{display:"inline-block"}} id="278574" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
        <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="493502" is="div"><Text text="Neptune" /></Element>} </h2>
        <p class="leading-relaxed text-base mb-4"> {<Element canvas style={{display:"inline-block"}} id="219716" is="div"><Text text="Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche." /></Element>} </p>
        <a class="text-indigo-500 inline-flex items-center"> {<Element canvas style={{display:"inline-block"}} id="661981" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
      <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
        <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="521022" is="div"><Text text="Melanchole" /></Element>} </h2>
        <p class="leading-relaxed text-base mb-4"> {<Element canvas style={{display:"inline-block"}} id="432871" is="div"><Text text="Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche." /></Element>} </p>
        <a class="text-indigo-500 inline-flex items-center"> {<Element canvas style={{display:"inline-block"}} id="637961" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
    </div>
    <Element canvas style={{ width: "100%" }} id={"div147848"} is="div">
            <Button classn={"flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"}>
               {<Element canvas style={{display:"inline-block"}} id="383586" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
  </div>
    </section>

      </div>;
};
Content1.craft = {
  displayName: "Content 1"
};