import { gql } from "apollo-boost";

const deleteDbRecordMutation = gql`
  mutation(
    $url: String,
    $db: String
    $table: String
    $record: String
    ) {
    deleteDbRecord(
      url:$url,
      db:$db,
      table:$table
      record:$record
      ) {
      db
      table
      url,
      record
    }
  }
`;

export default deleteDbRecordMutation;
