import { useNode } from '@craftjs/core';
import { Grid, Slider as Small, RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { ToolbarDropdown } from './ToolbarDropdown';
import { ToolbarSwitch } from './ToolbarSwitch';
import { ToolbarTextInput } from './ToolbarTextInput';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const SliderStyled = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '5px 0',
    width: '100%',
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -7,
    marginLeft: -7,
    '&:focus,&:hover,&$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Small);


export const ToolbarItem = ({
  full = false,
  propKey,
  type,
  onChange,
  index,
  addTo,
  theKey,
  ...props
}) => {
  const {
    actions: { setProp },
    propValue,
  } = useNode((node) => ({
    propValue: node.data.props[propKey],
  }));
  const value = Array.isArray(propValue) ? propValue[index] : propValue;

  /////console.log(value)
  return (
    <Grid item xs={full ? 12 : 6}>
      <div className="mb-2" style={{height:"100%"}}>
        {['text', 'color', 'bg', 'number'].includes(type) ? (
          <ToolbarTextInput
            {...props}
            type={type}
            value={Array.isArray(propValue) ? value? value[theKey] : "" : value}
            onChange={(value) => {
              console.log(Array.isArray(propValue))
              setProp((props) => {
                if (Array.isArray(propValue)) {
                  if(addTo) {
                    console.log(theKey, 232)
                    console.log({[theKey]:onChange ? onChange(value) : value}, 231)
                    props[propKey] = [...props[propKey], {[theKey]:onChange ? onChange(value) : value}]
                  } else {
                    console.log("hi")
                  props[propKey][index] = onChange ? onChange(value) : value;
                }
                } else {
                  
                    console.log(props[propKey], 3)

                    props[propKey] = onChange ? onChange(value) : value;

                  
                }
              }, 500);
            }}
          />
        ) : type === 'vslider' ? (
          <div style={{width:"100%"}}>
            {props.label ? (
              <h4 className="text-sm text-light-gray-2">{props.label}</h4>
            ) : null}
            <Slider
            min={0}
            max={6}
            horizontal
              value={parseInt(value) || 0}
              onChange={
                ((e) => {
                  console.log(e)
                  setProp((props) => {
                      props[propKey] = onChange ? onChange(e) : e;
                  }, 1000);
                })
              }
            />
          </div>
        ) : type === 'slider' ? (
          <>
            {props.label ? (
              <h4 className="text-sm text-light-gray-2">{props.label}</h4>
            ) : null}
            <SliderStyled
              value={parseInt(value) || 0}
              onChange={
                ((_, value) => {
                  setProp((props) => {
                    if (Array.isArray(propValue)) {
                      props[propKey][index] = onChange
                        ? onChange(value)
                        : value;
                    } else {
                      props[propKey] = onChange ? onChange(value) : value;
                    }
                  }, 1000);
                })
              }
            />
          </>
        ) : type === 'radio' ? (
          <>
            {props.label ? (
              <h4 className="text-sm text-light-gray-2">{props.label}</h4>
            ) : null}
            <RadioGroup
            value={Array.isArray(propValue) ? value? value[theKey] : 0 : value}
            onChange={(e) => {
                
                const value = e.target.value;
                setProp((props) => {
                  if (Array.isArray(propValue)) {
                    if(addTo) {
                      console.log({[theKey]:onChange ? onChange(value) : value})
                      props[propKey] = [...props[propKey], {[theKey]:onChange ? onChange(value) : value}]
                    } else {
                      console.log("hi")
                    props[propKey][index] = onChange ? onChange(value) : value;
                  }
                  } else {
                    
                      console.log(props[propKey], 3)
  
                      props[propKey] = onChange ? onChange(value) : value;
  
                    
                  }
                });
              }}
            >
              {props.children}
            </RadioGroup>
          </>
        ) : type === 'select' ? (
          <ToolbarDropdown
            value={value || ''}
            onChange={(value) =>
              setProp(
                (props) =>
                  (props[propKey] = onChange ? onChange(value) : value)
              )
            }
            {...props}
          />
        ) : type === "switch" ? (
        <ToolbarSwitch
            value={value || ''}
            title={props.label}
            onChange={(value) =>
              setProp(
                (props) =>
                  (props[propKey] = onChange ? onChange(value) : value)
              )
            }
            {...props}
          />
        ) : null}
      </div>
    </Grid>
  );
};
