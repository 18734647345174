import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { useLocation } from "react-router-dom";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import "./index.scss"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import {useHistory} from "react-router-dom"
// import noWidget from "../../../assests/images/noWidget.svg"
// import addIcon from "../../../assests/images/addIcon.svg"
// import editIcon from "../../../assests/images/editIcon.svg"
// import shareIcon from "../../../assests/images/shareIcon.svg"
// import deleteIcon from "../../../assests/images/deleteIcon.svg"
import {DataSpinner} from "../../resuable/spinner/dataSpinner/index"
import AddViewModal from "../../resuable/modals/addViewModal";
import addViewMutation from "../../../graphql/mutations/view/addViewMutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import allViewsQuery from "../../../graphql/queries/view/allViewsQuery";
import parse from 'html-react-parser';
import DesignEditor from "../../resuable/designView";
import NewEditor from "../../resuable/editor";
import ViewSettings from "../../resuable/modals/viewSettings";
import addDataBaseMutation from "../../../graphql/mutations/dataBase/addDataBaseMutation";
import allDataBasesQuery from "../../../graphql/queries/dataBase/allDataBaseQuery";
import ACTION_VIEW_SETTINGS_RESET from "../../../actions/viewSettings/ACTION_VIEW_SETTINGS_RESET";
import addViewPink from "../../../assests/images/addViewPink.svg"
import BuilderModal from "../../resuable/modals/builderModal";
import allEventsQuery from "../../../graphql/queries/event/allEventsQuery";
import addAppMutation from "../../../graphql/mutations/app/addAppMutation";
import AddAppModal from "../../resuable/modals/addAppModal";
import allAppsQuery from "../../../graphql/queries/app/allAppsQuery";
import moment from "moment"
const AdminWorkspace = (props) => {
    const activeOrg = useSelector((state) => state.activeOrg.org);

    const {users, user, usersRefetch, shareables, shareableRefetch, appsRefetch, templates, templatesData, setTemplatesData, templatesRefetch} = props

    let location = useLocation()
    let history = useHistory()
    const { pathname } = useLocation();
    const [addView, {error: addViewError, data: addViewData, loading: addViewLoading}] = useMutation(addViewMutation)
    const [addApp, {error: addAppError, data: addAppData, loading: addAppLoading}] = useMutation(addAppMutation)
    const [addDataBase, {error: addDataBaseError, data: addDataBaseData, loading: addDataBaseLoading}] = useMutation(addDataBaseMutation)


   
    useEffect(() => {
        if(templates) {
            setTemplatesData(templates.allTemplates.map(x => {
                return {
                    name : x.name,
                    _id: x._id,
                    html: x.html,
                    createdAt: x.createdAt ? moment.unix(x.createdAt/1000).format("MM/DD/YYYY") : null

                }
            }))
        }
    },[templates])

    const triggerView = useSelector((state) => state.triggerView.view);

    useEffect(() => {
        if(triggerView) {
            console.log(triggerView)
            setActiveView(views.find(x => x._id == triggerView))

        }
    },[triggerView])

    const dispatch = useDispatch()
    const [allViews, { error: viewError, data: viewsData, loading: viewsLoading, refetch: viewsRefetch }] = useLazyQuery(allViewsQuery, {
        fetchPolicy: "no-cache",
      });

      const [allDataBases, { error: dataBaseError, data: dataBasesData, loading: dataBasesLoading, refetch: dataBasesRefetch }] = useLazyQuery(allDataBasesQuery , {
        fetchPolicy: "no-cache",
      });
      const [allEvents, { error: eventError, data: eventsData, loading: eventsLoading, refetch: eventsRefetch }] = useLazyQuery(allEventsQuery , {
        fetchPolicy: "no-cache",
      });
    const [selectedWorkspace, setSelectedWorkspace] = useState(null)

    useEffect(() => {
        if(location.state) {
            setSelectedWorkspace(location.state.workspace)
                setActiveView(location?.state?.view)
            
        }
    },[location])

    useEffect(() => {
        if(selectedWorkspace && activeOrg) {
            setViews(null)
            if(user?.user?._id){
            allViews({
                variables:{
                  org: activeOrg,
                  user: user.user._id
                }
              }) 
            }
        }
    },[selectedWorkspace, activeOrg])
    const [views, setViews] = useState(null)

    useEffect(() => {
        if(viewsData) {
            let nr = [...viewsData.allViews]
            setViews(viewsData.allViews ? viewsData.allViews.map((x,i, a) => {
                x.children = i == 0 ? [
                    {
                      _id: 2,
                      name: "home",
                    },
                    {
                      _id: 3,
                      name: "about us",
                    },
                  ] : []
                return x
            }) : [])
        }
    },[viewsData])

    useEffect(() => {
        if(views) {
            console.log(views, "refetched")
            setActiveView("")
            setActiveView( activeView ? views.find(x => x._id == activeView._id) : views[0])
            dispatch(ACTION_LOADING_SPINNER_RESET())
        }
    },[views])

    const [activeView, setActiveView] = useState("")

    // useEffect(() => {
    //     return () => {
    //         window.history.replaceState({}, document.title)
    //     }
    // })


    const widgetSettings = useSelector((state) => state.viewSettings.viewSettings);
    const widgetName = useSelector((state) => state.viewSettings.viewName);
    const formBuilder = useSelector((state) => state.formBuilder.formBuilder);

const [viewAddModal, setViewAddModal] = useState(null)
const [viewName,setViewName] = useState("")
const [viewNameError,setViewNameError] = useState("")
const [usersSearch,setUsersSearch] = useState("")
const [usersSearchError, setUsersSearchError] = useState("")
const [usersFilter, setUsersFilter] = useState("")
const [userRole,setUserRole] = useState("User")
const [usersAtView, setUsersAtView] = useState(null)


const [appAddModal, setAppAddModal] = useState(null)
const [appName,setAppName] = useState("")
const [appNameError,setAppNameError] = useState("")
const [usersAtApp, setUsersAtApp] = useState(null)
const [viewsAtApp, setViewsAtApp] = useState(null)


const addUserToView = (user) => {
    if(!usersAtView) {
        user.role == "viewer"
        setUsersAtView([user])
    } else {
        setUsersAtView([...usersAtView, user])
    }
}


const addUserToApp = (user) => {
    if(!usersAtApp) {
        user.role == "viewer"
        setUsersAtApp([user])
    } else {
        setUsersAtApp([...usersAtApp, user])
    }
}





useEffect(() => {
    if(viewAddModal) {
        if(usersSearch) {
            /////console.log(usersSearch.length)
            if(usersSearch.length) {
              setUsersFilter([...users.allUsers].filter((x) => {
                return (
                  new RegExp(usersSearch, "gi").test(
                    x.name
                  ) || new RegExp(usersSearch, "gi").test(
                    x.email
                  )
                )
            }).filter(y => !usersAtView ? true : usersAtView.some((user)=>user._id==y._id) ? false : true).map(y => {
                return {
                    _id: y._id,
                    name: y.name,
                    role: "viewer"
                }
            }))
            }   
    }
    } else {
        if(appAddModal) {
            if(usersSearch) {
                /////console.log(usersSearch.length)
                if(usersSearch.length) {
                  setUsersFilter([...users.allUsers].filter((x) => {
                    return (
                      new RegExp(usersSearch, "gi").test(
                        x.name
                      ) || new RegExp(usersSearch, "gi").test(
                        x.email
                      )
                    )
                }).filter(y => !usersAtApp ? true : usersAtApp.some((user)=>user._id==y._id) ? false : true).map(y => {
                    return {
                        _id: y._id,
                        name: y.name,
                        role: "viewer"
                    }
                }))
                }   
        }   
        }
    }
 
},[usersSearch])


// add view func

const [selectedTemplate, setSelectedTemplate] = useState(null)

const [previewModal, setPreviewModal] = useState(null)

const previewFunc = (id, type) => {
  console.log(id)
  // setImportModal(null)
  setPreviewModal({id, type})
}

const addViewFunc = () => {
    addView({
        variables:{
            user: user.user._id,
            org: activeOrg,
            name: viewName,        
            users: usersAtView.filter(x=>x.role == "viewer")?.map(x => x._id),
            editors: usersAtView.filter(x=>x.role == "editor")?.map(x => x._id),
            html: selectedTemplate?.html
        }
    }).then(async res => {
       
            setUsersAtView(null)
            viewsRefetch()
            usersRefetch()        
    })
}


const addAppFunc = () => {
    console.log(viewsAtApp)

    addApp({
        variables:{
            user: user.user._id,
            workspace: selectedWorkspace._id,
            organization: activeOrg,
            name: appName,        
            users: usersAtApp.filter(x=>x.role == "viewer")?.map(x => x._id),
            views: viewsAtApp?.map(x => x.value),
            editors: usersAtApp.filter(x=>x.role == "editor")?.map(x => x._id)
        }
    }).then(async res => {
       
            setUsersAtApp(null)
            appsRefetch()
            usersRefetch()        
    })
}


const [addViewDatas, setAddViewDatas] = useState(null)
const [addAppDatas, setAddAppDatas] = useState(null)
const [editMode, setEditMode] = useState(false)
const [viewSettings, setViewSettings] = useState(false)
const [builderModal, setBuilderModal] = useState(false)
const [dataBaseUrl,setDataBaseUrl] = useState("")    
const [dataBaseDb,setDataBaseDb] = useState("")    
const [dataBaseAllDb,setDataBaseAllDb] = useState("")
const [dataBaseTable,setDataBaseTable] = useState("")
const [dataBaseAllTable,setDataBaseAllTable] = useState("")

const addDataBaseFunc = (dbName, tableName, widgetId) => {
    console.log(dbName,tableName,widgetId)
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    addDataBase({
        variables: {
            url:dataBaseUrl,
            db: dbName,
            table: tableName,
            view: activeView._id,
            widget: widgetId
        }
    }).then(res => {
        console.log(res)
        if(!res.data.addDataBase.done) {
        if(res.data.addDataBase.db && !res.data.addDataBase.table) {
            setDataBaseAllDb(JSON.parse(res.data.addDataBase.db))
        }
        if(res.data.addDataBase.table) {
            setDataBaseAllTable(JSON.parse(res.data.addDataBase.table))
        }
    } else {
        viewsRefetch()
        dispatch(ACTION_VIEW_SETTINGS_RESET())
    }
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }).catch(err => 
        console.log(err)
        )
}
useEffect(() => {
    if(addViewData) { 
        setAddViewDatas(addViewData)
    }
},[addViewData])

useEffect(() => {
    if(addAppData) { 
        setAddAppDatas(addAppData)
    }
},[addAppData])


useEffect(() => {
    if(activeView) {
        allDataBases({
        variables:{
            view: activeView._id
        }
    }) 
    allEvents({
        variables:{
            view: activeView._id
        }
    }) 
    }
    
},[activeView])

if(!Cookies.get("dummy-token")) {
    return <Redirect to="/admin" />
}
if(!location.state) {
    return <Redirect to="/admin/dashboard" />
}
if(!views) {
    return <DataSpinner />
}
console.log(widgetSettings)

 return ( 
        <div className="adminContent column" style={{padding:0}}>

            {/* <ViewSettings 
                open={true}
                setOpen={setViewSettings}
                dataBaseUrl={dataBaseUrl}
                setDataBaseUrl={setDataBaseUrl}
                addDataBase={addDataBaseFunc}
            /> */}

            <BuilderModal 
                open={builderModal}
                setOpen={setBuilderModal}
            />

            <AddViewModal
            previewModal={previewModal}
            setPreviewModal={setPreviewModal}
            previewFunc={previewFunc}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            templatesData={templatesData}
            user={user}
            open={viewAddModal}
            setOpen={setViewAddModal}
            addView={addViewFunc}
            viewName={viewName}
            setViewName={setViewName}
            viewNameError={viewNameError}
            setViewNameError={setViewNameError}
            usersFilter={usersFilter}
            usersSearch={usersSearch}
            setUsersSearch={setUsersSearch}
            usersSearchError={usersSearchError}
            setUsersSearchError={setUsersSearchError}
            addViewLoading={addViewLoading}
            usersAtView={usersAtView}
            setUsersAtView={setUsersAtView}
            addUserToView={addUserToView}
            userRole={userRole}
            setUserRole={setUserRole}
            addViewData={addViewDatas}
            setAddViewData={setAddViewDatas}
            />

        <AddAppModal 
        views={views}
            user={user}
            open={appAddModal}
            setOpen={setAppAddModal}
            addApp={addAppFunc}
            appName={appName}
            setAppName={setAppName}
            appNameError={appNameError}
            setAppNameError={setAppNameError}
            usersFilter={usersFilter}
            usersSearch={usersSearch}
            setUsersSearch={setUsersSearch}
            usersSearchError={usersSearchError}
            setUsersSearchError={setUsersSearchError}
            addAppLoading={addAppLoading}
            usersAtApp={usersAtApp}
            setUsersAtApp={setUsersAtApp}
            addUserToApp={addUserToApp}
            userRole={userRole}
            setUserRole={setUserRole}
            addAppData={addAppDatas}
            setAddAppData={setAddAppDatas}
            viewsAtApp={viewsAtApp}
            setViewsAtApp={setViewsAtApp}
        />

            {views?.length > 0? <div className="viewsHeader" style={{height:"4%",alignItems:"center", justifyContent:"space-between"}}>
          <div style={{display:"flex", alignItems:"flex-end", height:"100%"}}>
                {views? views?.length > 0 ? views.map((x, i) => (
                    <div onClick={() => setActiveView(x)} className={`viewContainer`} style={{fontWeight: activeView?._id == x._id ? "600" : "500", width:"fit-content"}}>
                        <span className="viewWN">{x.name}</span>
                            <div className={`${activeView?._id == x._id ? "activeView" : "hoverView"}`} />
                    </div>
                )) : (
                    <div style={{fontSize:"1.5em", color:"gray"}}>This workspace have no views yet</div>
                ) : null}
                </div>
                </div> : null} 
                {!activeView ? selectedWorkspace ? (
                <>
            <div className="workspaceContainer" style={{height:"96%"}}>
                <div className="workspaceContent"  style={{width:"100%", height:"100%"}}>
                    <div style={{width:"100vw", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}> <div className="addViewButton adminButton" style={{display:"flex", alignItems:"center"}} onClick={() => {
                            let newUser = {}
                            newUser._id = user.user._id
                            newUser.name = user.user.name
                            newUser.role = "editor"
                            addUserToView(newUser)
                           setViewAddModal("View")}}>
                           <img className="softPink" src={addViewPink}/>
                       </div>
                       </div>
                    
                       
                </div>
            </div>
            </>) : (
                <DataSpinner />
            ) : (
                <div className="workspaceContainer" style={{height:"96%"}}>

                <iframe width={`98%`} height={`100%`} allowfullscreen="allowfullscreen" id="i7fk" src={`https://app.allyoucancloud.com/preview/${activeView?._id}`}></iframe>

                </div>
            )}

        </div>
    )
}

export default AdminWorkspace