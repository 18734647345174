import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Faq8 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="flow-root">
  <div class="-my-8 divide-y divide-gray-100">
    <details class="group py-8" open>
      <summary class="flex cursor-pointer items-center justify-between">
        <h5 class="text-lg font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="487312" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing?" /></Element>} </h5>

        <span class="relative ml-1.5 h-5 w-5 flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </span>
      </summary>

      <p class="mt-4 leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="230548" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic
        veritatis molestias culpa in, recusandae laboriosam neque aliquid libero
        nesciunt voluptate dicta quo officiis explicabo consequuntur distinctio
        corporis earum similique!" /></Element>} </p>
    </details>

    <details class="group py-8">
      <summary class="flex cursor-pointer items-center justify-between">
        <h5 class="text-lg font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="176101" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing?" /></Element>} </h5>

        <span class="relative ml-1.5 h-5 w-5 flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </span>
      </summary>

      <p class="mt-4 leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="434251" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic
        veritatis molestias culpa in, recusandae laboriosam neque aliquid libero
        nesciunt voluptate dicta quo officiis explicabo consequuntur distinctio
        corporis earum similique!" /></Element>} </p>
    </details>
  </div>
    </div>

      </div>;
};
Faq8.craft = {
  displayName: "Faq 8"
};