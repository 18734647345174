import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';

import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';

class Component extends SampleBase {
  render() {
    return (<div className='control-pane'>
    <div className='control-section'>
      <div className="content-wrapper format-wrapper sample-numeric">
        <div className="control-label">{this.props.label}
        </div>
        {/* Render the Numeric Textbox */}
        <NumericTextBoxComponent
        {...this.props.allEvents}
        >
        </NumericTextBoxComponent>
      </div>
    </div>
  </div>
      );
    } 
}

export default Component