import React from 'react'
import Modal from "react-modal"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Input} from "reactstrap"
import { DataSpinner } from '../../spinner/dataSpinner';
const AddKeywordModal = (props) => {
    const {
        open,
        setOpen,
        beforeName,
        setBeforeName,
        afterName,
        setAfterName,
        addKeyword,
        updateKeywordFunc,
        addKeywordLoading,
        updateKeywordLoading,
        rowId,
        setRowId,

    } = props

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? !addKeywordLoading ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setBeforeName("")
                    setRowId(null)
                    setAfterName("")
                }}
              />             
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? open : `Add a ${open}`}</h1>
          <div className="form-group-container task-form">
        <div>
          <div className="auth-group">
              <div className='auth-label'>Original keyword</div>
         <Input
        type="text"
        name={"keywordName"}
        disabled={ rowId && rowId.default ? true : false}
        defaultValue={beforeName}
        maxLength={128}
        placeholder={"Enter Keyword name"}
        className={`auth-input`}
        onChange={(e) => setBeforeName(e.target.value)}
      />
          </div> 
          <div className="auth-group">
              <div className='auth-label'>Replaced by</div>
         <Input
        type="text"
        name={"keywordName"}
        defaultValue={afterName}
        maxLength={128}
        placeholder={"Replaced by"}
        className={`auth-input`}
        onChange={(e) => setAfterName(e.target.value)}
      />
          </div> 
        </div>
        
          </div>
            <div
              className={`${beforeName ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={beforeName ?  open.includes("Edit") ? updateKeywordFunc : addKeyword : null }
            >
              
              {open.includes("Edit") ? "Update" : "Add"}

            </div>
        </div> : <DataSpinner /> : null}
      </Modal>
  )}

  export default AddKeywordModal
       