import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Footer31 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <footer class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap md:text-left text-center -mb-10 -mx-4">
      <div class="lg:w-1/6 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="827553" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="530013" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="742969" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="976808" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="856250" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/6 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="547179" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="889471" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="360408" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="835265" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="390692" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/6 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="208389" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="745668" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="322086" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="338353" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="164741" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/6 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="629858" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="166366" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="220812" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="302220" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="745135" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/6 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="467328" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="570687" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="934215" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="366319" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="931077" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/6 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="914734" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="429666" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="639220" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="216034" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="710484" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
    </div>
  </div>
  <div class="border-t border-gray-200">
    <div class="container px-5 py-8 flex flex-wrap mx-auto items-center">
      <form>
        <div class="flex md:flex-nowrap flex-wrap justify-center items-end md:justify-start">
          <div class="relative sm:w-64 w-40 sm:mr-4 mr-2">
            <label for="footer-field" class="leading-7 text-sm text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="894149" is="div"><Text text="Placeholder" /></Element>} </label>
            <input type="text" id="footer-field" name="footer-field" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:bg-transparent focus:ring-indigo-200 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
          </div>
          <Element canvas style={{ width: "100%" }} id={"div658824"} is="div">
            <Button classn={"inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="960360" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
          <p class="text-gray-500 text-sm md:ml-6 md:mt-0 mt-2 sm:text-left text-center"> {<Element canvas style={{display:"inline-block"}} id="447711" is="div"><Text text="Bitters chicharrones fanny pack" /></Element>} <br class="lg:block hidden" /> {<Element canvas style={{display:"inline-block"}} id="359808" is="div"><Text text="waistcoat green juice" /></Element>} </p>
        </div>
      </form>
      <span class="inline-flex lg:ml-auto lg:mt-0 mt-6 w-full justify-center md:justify-start md:w-auto items-end">
        <a class="text-gray-500">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </div>
  <div class="bg-gray-100">
    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
      <p class="text-gray-500 text-sm text-center sm:text-left"> {<Element canvas style={{display:"inline-block"}} id="819439" is="div"><Text text="© 2020 Tailblocks —" /></Element>} <Element canvas style={{ width: "100%" }} id={"div598292"} is="div">
            <Button href={"https://twitter.com/knyttneve"} classn={"text-gray-600 ml-1"}>
               {<Element canvas style={{display:"inline-block"}} id="583824" is="div"><Text text="@knyttneve" /></Element>} 
            </Button>
          </Element>
      </p>
      <span class="sm:ml-auto sm:mt-0 mt-2 sm:w-auto w-full sm:text-left text-center text-gray-500 text-sm"> {<Element canvas style={{display:"inline-block"}} id="365897" is="div"><Text text="Enamel pin tousled raclette tacos irony" /></Element>} </span>
    </div>
  </div>
    </footer>

      </div>;
};
Footer31.craft = {
  displayName: "Footer 31"
};