import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Content8 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col">
      <div class="h-1 bg-gray-200 rounded overflow-hidden">
        <div class="w-24 h-full bg-indigo-500"></div>
      </div>
      <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
        <h1 class="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0"> {<Element canvas style={{display:"inline-block"}} id="996412" is="div"><Text text="Space The Final Frontier" /></Element>} </h1>
        <p class="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0"> {<Element canvas style={{display:"inline-block"}} id="160559" is="div"><Text text="Street art subway tile salvia four dollar toast bitters selfies quinoa yuccie synth meditation iPhone intelligentsia prism tofu. Viral gochujang bitters dreamcatcher." /></Element>} </p>
      </div>
    </div>
    <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
      <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
        <div class="rounded-lg h-64 overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div660932"} is="div">
          <ImageC alt="content" class="object-cover object-center h-full w-full" src="https://dummyimage.com/1203x503"  />
        </Element>
        </div>
        <h2 class="text-xl font-medium title-font text-gray-900 mt-5"> {<Element canvas style={{display:"inline-block"}} id="349369" is="div"><Text text="Shooting Stars" /></Element>} </h2>
        <p class="text-base leading-relaxed mt-2"> {<Element canvas style={{display:"inline-block"}} id="597285" is="div"><Text text="Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual." /></Element>} </p>
        <a class="text-indigo-500 inline-flex items-center mt-3"> {<Element canvas style={{display:"inline-block"}} id="838295" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
      <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
        <div class="rounded-lg h-64 overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div691686"} is="div">
          <ImageC alt="content" class="object-cover object-center h-full w-full" src="https://dummyimage.com/1204x504"  />
        </Element>
        </div>
        <h2 class="text-xl font-medium title-font text-gray-900 mt-5"> {<Element canvas style={{display:"inline-block"}} id="163592" is="div"><Text text="The Catalyzer" /></Element>} </h2>
        <p class="text-base leading-relaxed mt-2"> {<Element canvas style={{display:"inline-block"}} id="648451" is="div"><Text text="Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual." /></Element>} </p>
        <a class="text-indigo-500 inline-flex items-center mt-3"> {<Element canvas style={{display:"inline-block"}} id="234011" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
      <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
        <div class="rounded-lg h-64 overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div133187"} is="div">
          <ImageC alt="content" class="object-cover object-center h-full w-full" src="https://dummyimage.com/1205x505"  />
        </Element>
        </div>
        <h2 class="text-xl font-medium title-font text-gray-900 mt-5"> {<Element canvas style={{display:"inline-block"}} id="537250" is="div"><Text text="The 400 Blows" /></Element>} </h2>
        <p class="text-base leading-relaxed mt-2"> {<Element canvas style={{display:"inline-block"}} id="213010" is="div"><Text text="Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual." /></Element>} </p>
        <a class="text-indigo-500 inline-flex items-center mt-3"> {<Element canvas style={{display:"inline-block"}} id="937088" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Content8.craft = {
  displayName: "Content 8"
};