import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog8 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-wrap -m-4">
        <div class="p-4 md:w-1/3">
          <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <Element canvas style={{ width: "100%" }} id={"div744398"} is="div">
          <ImageC class="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/720x400" alt="blog"  />
        </Element>
            <div class="p-6">
              <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"> {<Element canvas style={{display:"inline-block"}} id="163080" is="div"><Text text="CATEGORY" /></Element>} </h2>
              <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="619786" is="div"><Text text="The Catalyzer" /></Element>} </h1>
              <p class="leading-relaxed mb-3"> {<Element canvas style={{display:"inline-block"}} id="626317" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
              <div class="flex items-center flex-wrap ">
                <a class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"> {<Element canvas style={{display:"inline-block"}} id="296327" is="div"><Text text="Learn More" /></Element>} <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
                <span class="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                  <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg> {<Element canvas style={{display:"inline-block"}} id="839961" is="div"><Text text="1.2K" /></Element>} </span>
                <span class="text-gray-400 inline-flex items-center leading-none text-sm">
                  <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                  </svg> {<Element canvas style={{display:"inline-block"}} id="360632" is="div"><Text text="6" /></Element>} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="p-4 md:w-1/3">
          <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <Element canvas style={{ width: "100%" }} id={"div996033"} is="div">
          <ImageC class="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/721x401" alt="blog"  />
        </Element>
            <div class="p-6">
              <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"> {<Element canvas style={{display:"inline-block"}} id="460080" is="div"><Text text="CATEGORY" /></Element>} </h2>
              <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="808718" is="div"><Text text="The 400 Blows" /></Element>} </h1>
              <p class="leading-relaxed mb-3"> {<Element canvas style={{display:"inline-block"}} id="546604" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
              <div class="flex items-center flex-wrap">
                <a class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"> {<Element canvas style={{display:"inline-block"}} id="865168" is="div"><Text text="Learn More" /></Element>} <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
                <span class="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                  <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg> {<Element canvas style={{display:"inline-block"}} id="727320" is="div"><Text text="1.2K" /></Element>} </span>
                <span class="text-gray-400 inline-flex items-center leading-none text-sm">
                  <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                  </svg> {<Element canvas style={{display:"inline-block"}} id="936836" is="div"><Text text="6" /></Element>} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="p-4 md:w-1/3">
          <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <Element canvas style={{ width: "100%" }} id={"div990428"} is="div">
          <ImageC class="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/722x402" alt="blog"  />
        </Element>
            <div class="p-6">
              <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"> {<Element canvas style={{display:"inline-block"}} id="783940" is="div"><Text text="CATEGORY" /></Element>} </h2>
              <h1 class="title-font text-lg font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="248497" is="div"><Text text="Shooting Stars" /></Element>} </h1>
              <p class="leading-relaxed mb-3"> {<Element canvas style={{display:"inline-block"}} id="158803" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
              <div class="flex items-center flex-wrap ">
                <a class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"> {<Element canvas style={{display:"inline-block"}} id="744742" is="div"><Text text="Learn More" /></Element>} <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
                <span class="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                  <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg> {<Element canvas style={{display:"inline-block"}} id="587827" is="div"><Text text="1.2K" /></Element>} </span>
                <span class="text-gray-400 inline-flex items-center leading-none text-sm">
                  <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                  </svg> {<Element canvas style={{display:"inline-block"}} id="895634" is="div"><Text text="6" /></Element>} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
      </div>;
};
Blog8.craft = {
  displayName: "Blog 8"
};