import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Section2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section>
  <div class="max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
      <div class="mx-auto max-w-lg text-center lg:mx-0 lg:text-left">
        <h2 class="text-3xl font-bold sm:text-4xl"> {<Element canvas style={{display:"inline-block"}} id="694002" is="div"><Text text="Find your career path" /></Element>} </h2>

        <p class="mt-4 text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="699348" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut vero
          aliquid sint distinctio iure ipsum cupiditate? Quis, odit assumenda?
          Deleniti quasi inventore, libero reiciendis minima aliquid tempora.
          Obcaecati, autem." /></Element>} </p>

        <Element canvas style={{ width: "100%" }} id={"div790024"} is="div">
            <Button href={"/get-started"} classn={"mt-8 inline-flex items-center rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500 cursor-pointer"}>
              
          <span class="text-sm font-medium"> {<Element canvas style={{display:"inline-block"}} id="609288" is="div"><Text text="Get Started" /></Element>} </span>

          <svg class="ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        
            </Button>
          </Element>
      </div>

      <div class="grid grid-cols-2 gap-4 sm:grid-cols-3">
        <Element canvas style={{ width: "100%" }} id={"div820954"} is="div">
            <Button href={"/accountant"} classn={"block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"}>
              
          <span class="inline-block rounded-lg bg-gray-50 p-3">
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
            </svg>
          </span>

          <h6 class="mt-2 font-bold"> {<Element canvas style={{display:"inline-block"}} id="869354" is="div"><Text text="Accountant" /></Element>} </h6>

          <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="816281" is="div"><Text text="Lorem ipsum dolor sit amet consectetur." /></Element>} </p>
        
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div401847"} is="div">
            <Button href={"/accountant"} classn={"block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"}>
              
          <span class="inline-block rounded-lg bg-gray-50 p-3">
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
            </svg>
          </span>

          <h6 class="mt-2 font-bold"> {<Element canvas style={{display:"inline-block"}} id="509741" is="div"><Text text="Accountant" /></Element>} </h6>

          <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="249920" is="div"><Text text="Lorem ipsum dolor sit amet consectetur." /></Element>} </p>
        
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div756024"} is="div">
            <Button href={"/accountant"} classn={"block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"}>
              
          <span class="inline-block rounded-lg bg-gray-50 p-3">
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
            </svg>
          </span>

          <h6 class="mt-2 font-bold"> {<Element canvas style={{display:"inline-block"}} id="574582" is="div"><Text text="Accountant" /></Element>} </h6>

          <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="349898" is="div"><Text text="Lorem ipsum dolor sit amet consectetur." /></Element>} </p>
        
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div477541"} is="div">
            <Button href={"/accountant"} classn={"block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"}>
              
          <span class="inline-block rounded-lg bg-gray-50 p-3">
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
            </svg>
          </span>

          <h6 class="mt-2 font-bold"> {<Element canvas style={{display:"inline-block"}} id="730198" is="div"><Text text="Accountant" /></Element>} </h6>

          <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="650816" is="div"><Text text="Lorem ipsum dolor sit amet consectetur." /></Element>} </p>
        
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div750810"} is="div">
            <Button href={"/accountant"} classn={"block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"}>
              
          <span class="inline-block rounded-lg bg-gray-50 p-3">
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
            </svg>
          </span>

          <h6 class="mt-2 font-bold"> {<Element canvas style={{display:"inline-block"}} id="243299" is="div"><Text text="Accountant" /></Element>} </h6>

          <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="143793" is="div"><Text text="Lorem ipsum dolor sit amet consectetur." /></Element>} </p>
        
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div744048"} is="div">
            <Button href={"/accountant"} classn={"block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"}>
              
          <span class="inline-block rounded-lg bg-gray-50 p-3">
            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
            </svg>
          </span>

          <h6 class="mt-2 font-bold"> {<Element canvas style={{display:"inline-block"}} id="182230" is="div"><Text text="Accountant" /></Element>} </h6>

          <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="784253" is="div"><Text text="Lorem ipsum dolor sit amet consectetur." /></Element>} </p>
        
            </Button>
          </Element>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Section2.craft = {
  displayName: "Section 2"
};