import { gql } from "apollo-boost";

const addIndustryMutation = gql`
  mutation(
    $name: String,
    $org: ID,
  ) {
    addIndustry(
    name:$name,
    org:$org,
    ) {
    _id
    name,
    org
    }
  }
`;

export default addIndustryMutation;
