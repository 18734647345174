import React, { useEffect, useState } from 'react';
import {
 Anchor,
 Box,
 Button,
 Card,
 CardBody,
 CardFooter,
  Collapsible,
  Heading,
  Grommet,
  Image,
  Paragraph,
} from 'grommet';

import { FormDown, FormUp, Favorite, ShareOption } from 'grommet-icons';
import { useNode } from '@craftjs/core';
import {CardSettings} from "./CardSettings"
import { DataSpinner } from '../../../../spinner/dataSpinner';
const theme = {
  global: {
    font: {
      family: `Comic Sans MS, -apple-system,
         BlinkMacSystemFont, 
         "Segoe UI", 
         Roboto`,
    },
  },
  card: {
    elevation: 'none',
    background: 'light-2',
    footer: {
      pad: 'medium',
    },
  },
};

export const CardWidget = (props) => {

    const {
      activeImage,
      source,
      activeHeader,
header,
activeContent,
content,
activeFooter,
footer,
dataBaseDataArr
    } = props

    const {
        connectors: { connect },
      } = useNode((node) => ({
        selected: node.events.selected,
      }));

/////console.log(source)
  const [open, setOpen] = React.useState(false);
  const [favorite, setFavorite] = React.useState(false);

  const ExpandButton = ({ ...rest }) => {
    const Icon = open ? FormUp : FormDown;
    return (
      <Button
        hoverIndicator="light-4"
        icon={<Icon color="brand" />}
        {...rest}
      />
    );
  };

  const [activeUser, setActiveUser] = useState(null)
  useEffect(() => {
    if(dataBaseDataArr?.length) {
      console.log(dataBaseDataArr)
      let dataArr = dataBaseDataArr[0]
      setActiveUser(dataArr.filter(x => x.active)?.length)
    }
  },[dataBaseDataArr])
  return (
    <Grommet style={{width:"fit-content"}} theme={theme} ref={connect}>
      {activeUser ? <Box pad="medium" align="start">
        <Card elevation="large" width="medium">
          {activeImage && <CardBody height="small">
            <Image
              fit="cover"
              src={source}
              a11yTitle="bridge"
            />
          </CardBody>}
          <Box pad={{ horizontal: 'medium' }} responsive={false}>
            {activeHeader && <Heading level="3" margin={{ vertical: 'medium' }}>
              {header} {activeUser}
            </Heading>}
            {activeContent && <Paragraph margin={{ top: 'none' }}>
             {content}
            </Paragraph>}
          </Box>
          {activeFooter && <CardFooter>
            <Box direction="row" align="center" gap="small">
              <Button
                icon={<Favorite color={favorite ? 'red' : undefined} />}
                hoverIndicator
                onClick={() => {
                  setFavorite(!favorite);
                }}
              />
              <Button icon={<ShareOption color="plain" />} hoverIndicator />
              <Anchor
                href="https://www.collinsdictionary.com/us/dictionary/english/bridge"
                label="Learn More"
              />
            </Box>
            <ExpandButton onClick={() => setOpen(!open)} />
          </CardFooter>}
          <Collapsible open={open}>
            <Paragraph margin="medium" color="dark-3">
              {footer}
            </Paragraph>
          </Collapsible>
        </Card>
      </Box> : <DataSpinner />}
    </Grommet>
  );
};


CardWidget.craft = {
  displayName: 'Card',
  props: {
  activeImage: true,
  source: "//v2.grommet.io/assets/IMG_4245.jpg",
  activeHeader: true,
  header: "Active user is:",
  activeContent: false,
  content: "A structure carrying a road, path, railroad, or canal across a river, ravine, road, railroad, or other obstacle.",
  activeFooter: false,
  footer: `The greatest bridge builders of antiquity were the ancient Romans.
  The Romans built arch bridges and aqueducts that could stand in
  conditions that would damage or destroy earlier designs. Some
  stand today.`,
  },
  related: {
    toolbar: CardSettings,
  },
};