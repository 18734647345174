import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';

class Component extends SampleBase {
    render() {
        return (<div className='control-pane'>
                <div className='control-section default badge-samples'>
                    <div className="sample_container badge-default">
                        <div className="e-btn-group e-custom-button">
                            <button id="update" className="e-btn">Updates
                                {/* Notification Badge */}
                                <span className="e-badge e-badge-info e-badge-notification e-badge-overlap">14</span>
                            </button>
                            <button id="task" className="e-btn">Tasks
                                {/* Notification Badge */}
                                <span className="e-badge e-badge-success e-badge-notification e-badge-overlap">48</span>
                            </button>
                            <button id="notify" className="e-btn">Notifications
                                {/* Notification Badge */}
                                <span className="e-badge e-badge-secondary e-badge-notification e-badge-overlap">99</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
      );
    }
}

export default Component