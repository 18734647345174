import React from "react";

// const StyledButton = styled.button`
//   padding: 0.5rem 1rem;
//   border: 1px solid rgb(var(--primary-color)); 
//   color: rgb(var(--primary-color));
//   border-radius: 5rem;
//   transition: 0.15s;
//   &:active {
//     transform: scale(0.95);
//   }
// `;

const GotIt = (props) => {
  const options = [
    {
      name: "Hello!",
      handler: props.actionProvider.handleGotIt,
      id: 1,
    },
  ];
  return (
    <div className="options-container">
      {options.map((option) => {
        return (
          <div
          style={{
            padding: "0.5rem 1rem",
            border: "1px solid grey", 
            color: "grey",
            borderRadius: "5rem",
            transition: "0.15s",
            cursor:"pointer",
          }}
            key={option.id}
            className="option-button"
            onClick={option.handler}
          >
            {option.name}
          </div>
        );
      })}
    </div>
  );
};

export default GotIt;
