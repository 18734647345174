import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Section5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section>
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
    <div class="max-w-3xl">
      <h2 class="text-3xl font-bold sm:text-4xl"> {<Element canvas style={{display:"inline-block"}} id="806110" is="div"><Text text="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod alias
        doloribus impedit." /></Element>} </h2>
    </div>

    <div class="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div class="relative h-64 overflow-hidden sm:h-80 lg:h-full">
        <Element canvas style={{ width: "100%" }} id={"div804216"} is="div">
          <ImageC alt="Party" src="https://images.unsplash.com/photo-1496843916299-590492c751f4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1771&q=80" class="absolute inset-0 h-full w-full object-cover"  />
        </Element>
      </div>

      <div class="lg:py-16">
        <article class="space-y-4 text-gray-600">
          <p> {<Element canvas style={{display:"inline-block"}} id="344981" is="div"><Text text="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut qui
            hic atque tenetur quis eius quos ea neque sunt, accusantium soluta
            minus veniam tempora deserunt? Molestiae eius quidem quam repellat." /></Element>} </p>

          <p> {<Element canvas style={{display:"inline-block"}} id="610767" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum
            explicabo quidem voluptatum voluptas illo accusantium ipsam quis,
            vel mollitia? Vel provident culpa dignissimos possimus, perferendis
            consectetur odit accusantium dolorem amet voluptates aliquid,
            ducimus tempore incidunt quas. Veritatis molestias tempora
            distinctio voluptates sint! Itaque quasi corrupti, sequi quo odit
            illum impedit!" /></Element>} </p>
        </article>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Section5.craft = {
  displayName: "Section 5"
};