import { gql } from "apollo-boost";

const allThemesQuery = gql`
  
    query(
    $org: String
  ) { 
    allThemes (
      org: $org
    ) {
      _id
    name,
    org
    default
    createdAt
    }
  }
  
`;

export default allThemesQuery;

