import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero11 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
    <header class="bg-gray-900 pattern">
    <div class="container px-6 mx-auto">
        <nav class="flex flex-col py-2 sm:flex-row sm:justify-between sm:items-center">
            <div>
                <Element canvas style={{ width: "100%" }} id={"div783536"} is="div">
            <Button href={"#"} classn={"text-2xl font-semibold text-white hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="808569" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="flex items-center mt-2 -mx-2 sm:mt-0">
                <Element canvas style={{ width: "100%" }} id={"div152813"} is="div">
            <Button href={"#"} classn={"px-3 py-1 text-sm font-semibold text-white transition-colors duration-300 transform border-2 rounded-md hover:bg-gray-700"}>
               {<Element canvas style={{display:"inline-block"}} id="341358" is="div"><Text text="Sign In" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div524901"} is="div">
            <Button href={"#"} classn={"px-3 py-2 mx-2 text-sm font-semibold text-white transition-colors duration-300 transform bg-black rounded-md hover:bg-gray-800"}>
               {<Element canvas style={{display:"inline-block"}} id="398522" is="div"><Text text="Sign Up" /></Element>} 
            </Button>
          </Element>
            </div>
        </nav>

        <div class="flex flex-col items-center py-6 lg:h-[32rem] lg:flex-row">
            <div class="lg:w-1/2">
                <h2 class="text-4xl font-semibold text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="948086" is="div"><Text text="Brand" /></Element>} </h2>

                <h3 class="text-2xl font-semibold text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="796433" is="div"><Text text="Hello" /></Element>} <span class="text-blue-400"> {<Element canvas style={{display:"inline-block"}} id="988632" is="div"><Text text="Guest" /></Element>} </span>
                </h3>

                <p class="mt-3 text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="940916" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipiscing." /></Element>} </p>
            </div>

            <div class="flex mt-8 lg:w-1/2 lg:justify-end lg:mt-0">
                <div class="max-w-sm bg-white rounded-lg dark:bg-gray-800">
                    <div class="p-5 text-center">
                        <h2 class="text-2xl font-semibold text-gray-700 dark:text-white fo"> {<Element canvas style={{display:"inline-block"}} id="883861" is="div"><Text text="Sign In" /></Element>} </h2>

                            <div class="mt-4">
                                <input class="block w-full px-4 py-2 text-gray-700 placeholder-gray-400 bg-white border rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-500 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:ring-blue-300 focus:outline-none focus:ring" type="email" placeholder="Email address" aria-label="Email address" />
                                <input class="block w-full px-4 py-2 mt-4 text-gray-700 placeholder-gray-400 bg-white border rounded-md dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-500 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:ring-blue-300 focus:outline-none focus:ring" type="password" placeholder="Password" aria-label="Password" />
                            </div>

                            <div class="flex items-center justify-between mt-4">
                                <Element canvas style={{ width: "100%" }} id={"div826954"} is="div">
            <Button href={"#"} classn={"text-sm text-gray-600 dark:text-gray-200 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="872798" is="div"><Text text="Forget Password?" /></Element>} 
            </Button>
          </Element>

                                <Element canvas style={{ width: "100%" }} id={"div321245"} is="div">
            <Button classn={"px-4 py-2 font-semibold text-white transition-colors duration-300 transform bg-gray-900 rounded-md hover:bg-gray-800 dark:hover:bg-gray-700 focus:outline-none focus:bg-gray-800 dark:focus:bg-gray-700"}>
               {<Element canvas style={{display:"inline-block"}} id="530307" is="div"><Text text="Login" /></Element>} 
            </Button>
          </Element>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </header>

      </div>;
};
Hero11.craft = {
  displayName: "Hero 11"
};