import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Contact7 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font relative">
  <div class="container px-5 py-24 mx-auto flex">
    <div class="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
      <form>
        <h2 class="text-gray-900 text-lg mb-1 font-medium title-font"> {<Element canvas style={{display:"inline-block"}} id="620972" is="div"><Text text="Feedback" /></Element>} </h2>
        <p class="leading-relaxed mb-5 text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="859705" is="div"><Text text="Post-ironic portland shabby chic echo park, banjo fashion axe" /></Element>} </p>
        <div class="relative mb-4">
          <label for="email" class="leading-7 text-sm text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="629317" is="div"><Text text="Email" /></Element>} </label>
          <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
        </div>
        <div class="relative mb-4">
          <label for="message" class="leading-7 text-sm text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="830385" is="div"><Text text="Message" /></Element>} </label>
          <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" required></textarea>
        </div>
        <Button type="submit" class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"> {<Element canvas style={{display:"inline-block"}} id="398782" is="div"><Text text="Button" /></Element>} </Button>
        <p class="text-xs text-gray-500 mt-3"> {<Element canvas style={{display:"inline-block"}} id="438642" is="div"><Text text="Chicharrones blog helvetica normcore iceland tousled brook viral artisan." /></Element>} </p>
      </form>
    </div>
  </div>
    </section>

      </div>;
};
Contact7.craft = {
  displayName: "Contact 7"
};