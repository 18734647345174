import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero9 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <header class="bg-white dark:bg-gray-900">
    <nav x-data="{ isOpen: false }" class="px-6 py-4 shadow">
        <div class="lg:items-center lg:justify-between lg:flex">
            <div class="flex items-center justify-between">
                <div>
                    <Element canvas style={{ width: "100%" }} id={"div248718"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="298453" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
                </div>

                
                <div class="lg:hidden">
                    <Element canvas style={{ width: "100%" }} id={"div569154"} is="div">
            <Button classn={"text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"}>
              
                        <svg x-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                        </svg>
                
                        <svg x-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    
            </Button>
          </Element>
                </div>
            </div>

           
            <div class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white shadow-md lg:bg-transparent lg:dark:bg-transparent lg:shadow-none dark:bg-gray-900 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center">
                <Element canvas style={{ width: "100%" }} id={"div718783"} is="div">
            <Button href={"#"} classn={"px-3 block py-2.5 text-gray-600 rounded-lg dark:text-gray-200 hover:bg-gray-100 lg:mx-2"}>
               {<Element canvas style={{display:"inline-block"}} id="811526" is="div"><Text text="Home" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div136843"} is="div">
            <Button href={"#"} classn={"px-3 block py-2.5 text-gray-600 rounded-lg dark:text-gray-200 hover:bg-gray-100 lg:mx-2"}>
               {<Element canvas style={{display:"inline-block"}} id="115864" is="div"><Text text="About" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div114273"} is="div">
            <Button href={"#"} classn={"px-3 block py-2.5 text-gray-600 rounded-lg dark:text-gray-200 hover:bg-gray-100 lg:mx-2"}>
               {<Element canvas style={{display:"inline-block"}} id="604212" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>
    </nav>

    <div class="lg:flex">
        <div class="flex items-center justify-center w-full px-6 py-8 lg:h-[32rem] lg:w-1/2">
            <div class="max-w-xl">
                <h2 class="text-2xl font-semibold text-gray-800 dark:text-white lg:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="934563" is="div"><Text text="Build Your New" /></Element>} <span class="text-blue-600 dark:text-blue-400"> {<Element canvas style={{display:"inline-block"}} id="356825" is="div"><Text text="Idea" /></Element>} </span></h2>

                <p class="mt-2 text-sm text-gray-500 dark:text-gray-400 lg:text-base"> {<Element canvas style={{display:"inline-block"}} id="996090" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis commodi cum cupiditate ducimus, fugit harum id necessitatibus odio quam quasi, quibusdam rem tempora voluptates." /></Element>} </p>

                <div class="flex flex-col mt-6 space-y-3 lg:space-y-0 lg:flex-row">
                    <Element canvas style={{ width: "100%" }} id={"div797548"} is="div">
            <Button href={"#"} classn={"block px-6 py-2.5 text-sm font-medium tracking-wider text-center text-white uppercase transition-colors duration-300 transform bg-gray-900 rounded-md hover:bg-gray-700"}>
               {<Element canvas style={{display:"inline-block"}} id="711257" is="div"><Text text="Get Started" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div834838"} is="div">
            <Button href={"#"} classn={"block px-6 py-2.5 text-sm font-medium tracking-wider text-center text-gray-700 uppercase transition-colors duration-300 transform bg-gray-200 rounded-md lg:mx-4 hover:bg-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="835441" is="div"><Text text="Learn More" /></Element>} 
            </Button>
          </Element>
                </div>
            </div>
        </div>

        <div class="w-full h-64 lg:w-1/2 lg:h-auto">
            <Element canvas style={{ width: "100%" }} id={"div742252"} is="div">
          <ImageC class="w-full h-full object-cover" src="https://images.unsplash.com/photo-1508394522741-82ac9c15ba69?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=748&q=80"  />
        </Element>
        </div>
    </div>
    </header>

      </div>;
};
Hero9.craft = {
  displayName: "Hero 9"
};