import { Element, useNode } from '@craftjs/core';
import React from 'react';
import Word from '../../../../widgets/word';

import { Button } from '../Button';
import { Container } from '../Container';

export const OnlyButtons = ({ children, ...props }) => {
  const {
    connectors: { connect },
  } = useNode();
  return (
    <div title="only-buttons" ref={connect} className="w-full mt-5" {...props}>
      {children}
    </div>
  );
};

OnlyButtons.craft = {
  rules: {
    canMoveIn: (nodes) => nodes.every((node) => node.data.type === Button),
  },
};

export const Custom1 = (props) => {
  return ( 
    <Container {...props}>
      <div style={{width:"100%"}}>
      <Word />
      </div>
    </Container>
  );
};
 
Custom1.craft = {
  ...Container.craft,
  displayName: 'Custom 1',
};
