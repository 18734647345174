import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { useLocation } from "react-router-dom";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import noWidget from "../../../assests/images/noWidget.svg"
import addIcon from "../../../assests/images/addIcon.svg"
import addViewPink from "../../../assests/images/addViewPink.svg"
import editIcon from "../../../assests/images/editIcon.svg"
import shareIcon from "../../../assests/images/shareIcon.svg"
import deleteIcon from "../../../assests/images/deleteIcon.svg"
import {DataSpinner} from "../../resuable/spinner/dataSpinner/index"
import resetOrganizationMutation from "../../../graphql/mutations/organization/resetOrganizationMutation"
import updateOrganizationMutation from "../../../graphql/mutations/organization/updateOrganizationMutation"
import Multiselect from 'multiselect-react-dropdown'; 
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import TextInput from "../adminSignin/TextInput";
import Dropdown from "react-dropdown";
import dot from "../../../assests/images/dot.svg"
import arrowDownDark from "../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../assests/images/arrowUp.svg"
import add from "../../../assests/images/add.svg"
import edit from "../../../assests/images/edit.svg"
import trash from "../../../assests/images/trash.svg"
import threedots from "../../../assests/images/threedots.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AccountTable } from "../../resuable/accountTable";
import AddKeywordModal from "../../resuable/modals/addKeywordModal";
import addKeywordMutation from "../../../graphql/mutations/keyword/addKeywordMutation";
import updateKeywordMutation from "../../../graphql/mutations/keyword/updateKeywordMutation";
import allThemesQuery from "../../../graphql/queries/theme/allThemesQuery";
import moment from "moment";
import updateThemeStatusMutation from "../../../graphql/mutations/theme/updateThemeStatusMutation";
const AdminSettings = ({teamsRefetch, usersRefetch, organizationsRefetch,themesData, themesRefetch, keywordsData, keywordsRefetch, industrys}) => {


    const [industries, setIndustries] = useState([])


    useEffect(() => {
        if(industrys) {
          setIndustries(industrys.allIndustrys.map(x => x.name))
        }
    },[industrys])

    let location = useLocation()
    const { pathname } = useLocation();

    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }
    const activeOrg = useSelector((state) => state.activeOrg.org);
    const activeOrgName = useSelector((state) => state.activeOrg.orgName);
    const activeOrgLangauge = useSelector((state) => state.activeOrg.orgLanguage);
    const activeOrgCurrency = useSelector((state) => state.activeOrg.orgCurrency);
    const activeOrgTimezone = useSelector((state) => state.activeOrg.orgTimezone);
    const activeOrgIndustry = useSelector((state) => state.activeOrg.orgIndustry);

    const [resetOrganization, {error: resetOrganizationError, data: resetOrganizationData, loading: resetOrganizationLoading}] = useMutation(resetOrganizationMutation)

    const [selectedWorkspace, setSelectedWorkspace] = useState(null)

    useEffect(() => {
             setActiveView(views[0].name)
    },[])

    const [views, setViews] = useState([
        {name:"General"},
        {name: "labeling"},
        {name: "Privacy"},
        {name: "backup"},
        {name: "theme"},
    ])

    const [activeView, setActiveView] = useState("")
    useEffect(() => {
        return () => {
            window.history.replaceState({}, document.title)
        }
    })
const dispatch = useDispatch()
    const resetOrg = () => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        resetOrganization({
            variables:{
                _id: activeOrg
            }
        }).then(() => {
            teamsRefetch().then(() => {
                organizationsRefetch().then(() => {
                    usersRefetch().then(() => {
                        dispatch(ACTION_LOADING_SPINNER_RESET())
                    })
                })
            })
        })
    }

    const [updateOrganization, {error: updateOrganizationError, data: updateOrganizationData, loading: updateOrganizationLoading}] = useMutation(updateOrganizationMutation)

    // org settings
const [orgName, setOrgName] = useState("")
const [orgError, setOrgError] = useState("")
const [industry, setIndustry] = useState("")

let timeZones = [
    { label: <div>-12:00 International Date Line West (Etc/GMT+12)</div>,
     value: "-12:00 International Date Line West (Etc/GMT+12)" },
    { label: <div>-11:00 Midway Island, Samoa (Pacific/Midway)</div>,
     value: "-11:00 Midway Island, Samoa (Pacific/Midway)" },
    { label: <div>-10:00 Hawaii (Pacific/Honolulu)</div>,
     value: "-10:00 Hawaii (Pacific/Honolulu)" },
    { label: <div>-09:00 Alaska (US/Alaska)</div>,
     value: "-09:00 Alaska (US/Alaska)" },
    { label: <div>-08:00 Pacific Time (US & Canada) (America/Los_Angeles)</div>,
     value: "-08:00 Pacific Time (US & Canada) (America/Los_Angeles)" },
    { label: <div>-08:00 Tijuana, Baja California (America/Tijuana)</div>,
     value: "-08:00 Tijuana, Baja California (America/Tijuana)" },
    { label: <div>-07:00 Arizona (US/Arizona)</div>,
     value: "-07:00 Arizona (US/Arizona)" },
    { label: <div>-07:00 Chihuahua, La Paz, Mazatlan (America/Chihuahua)</div>,
     value: "-07:00 Chihuahua, La Paz, Mazatlan (America/Chihuahua)" },
    { label: <div>-07:00 Mountain Time (US & Canada) (US/Mountain)</div>,
     value: "-07:00 Mountain Time (US & Canada) (US/Mountain)" },
    { label: <div>-06:00 Central America (America/Managua)</div>,
     value: "-06:00 Central America (America/Managua)" },
    { label: <div>-06:00 Central Time (US & Canada) (US/Central)</div>,
     value: "-06:00 Central Time (US & Canada) (US/Central)" },
    { label: <div>-06:00 Guadalajara, Mexico City, Monterrey (America/Mexico_City)</div>,
     value: "-06:00 Guadalajara, Mexico City, Monterrey (America/Mexico_City)" },
    { label: <div>-06:00 Saskatchewan (Canada/Saskatchewan)</div>,
     value: "-06:00 Saskatchewan (Canada/Saskatchewan)" },
    { label: <div>-05:00 Bogota, Lima, Quito, Rio Branco (America/Bogota)</div>,
     value: "-05:00 Bogota, Lima, Quito, Rio Branco (America/Bogota)" },
    { label: <div>-05:00 Eastern Time (US & Canada) (US/Eastern)</div>,
     value: "-05:00 Eastern Time (US & Canada) (US/Eastern)" },
    { label: <div>-05:00 Indiana (East) (US/East-Indiana)</div>,
     value: "-05:00 Indiana (East) (US/East-Indiana)" },
    { label: <div>-04:00 Atlantic Time (Canada) (Canada/Atlantic)</div>,
     value: "-04:00 Atlantic Time (Canada) (Canada/Atlantic)" },
    { label: <div>-04:00 Caracas, La Paz (America/Caracas)</div>,
     value: "-04:00 Caracas, La Paz (America/Caracas)" },
    { label: <div>-04:00 Manaus (America/Manaus)</div>,
     value: "-04:00 Manaus (America/Manaus)" },
    { label: <div>-04:00 Santiago (America/Santiago)</div>,
     value: "-04:00 Santiago (America/Santiago)" },
    { label: <div>-03:30 Newfoundland (Canada/Newfoundland)</div>,
     value: "-03:30 Newfoundland (Canada/Newfoundland)" },
    { label: <div>-03:00 Brasilia (America/Sao_Paulo)</div>,
     value: "-03:00 Brasilia (America/Sao_Paulo)" },
    { label: <div>-03:00 Buenos Aires, Georgetown (America/Argentina/Buenos_Aires)</div>,
     value: "-03:00 Buenos Aires, Georgetown (America/Argentina/Buenos_Aires)" },
    { label: <div>-03:00 Greenland (America/Godthab)</div>,
     value: "-03:00 Greenland (America/Godthab)" },
    { label: <div>-03:00 Montevideo (America/Montevideo)</div>,
     value: "-03:00 Montevideo (America/Montevideo)" },
    { label: <div>-02:00 Mid-Atlantic (America/Noronha)</div>,
     value: "-02:00 Mid-Atlantic (America/Noronha)" },
    { label: <div>-01:00 Cape Verde Is. (Atlantic/Cape_Verde)</div>,
     value: "-01:00 Cape Verde Is. (Atlantic/Cape_Verde)" },
    { label: <div>-01:00 Azores (Atlantic/Azores)</div>,
     value: "-01:00 Azores (Atlantic/Azores)" },
    { label: <div>+00:00 Casablanca, Monrovia, Reykjavik (Africa/Casablanca)</div>,
     value: "+00:00 Casablanca, Monrovia, Reykjavik (Africa/Casablanca)" },
    { label: <div>+00:00 Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (Etc/Greenwich)</div>,
     value: "+00:00 Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (Etc/Greenwich)" },
    { label: <div>+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm,Vienna (Europe/Amsterdam)</div>,
     value: "+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm,Vienna (Europe/Amsterdam)" },
    { label: <div>+01:00 Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Belgrade)</div>,
     value: "+01:00 Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Belgrade)" },
    { label: <div>+01:00 Brussels, Copenhagen, Madrid, Paris (Europe/Brussels)</div>,
     value: "+01:00 Brussels, Copenhagen, Madrid, Paris (Europe/Brussels)" },
    { label: <div>+01:00 Sarajevo, Skopje, Warsaw, Zagreb (Europe/Sarajevo)</div>,
     value: "+01:00 Sarajevo, Skopje, Warsaw, Zagreb (Europe/Sarajevo)" },
    { label: <div>+01:00 West Central Africa (Africa/Lagos)</div>,
     value: "+01:00 West Central Africa (Africa/Lagos)" },
    { label: <div>+02:00 Amman (Asia/Amman)</div>,
     value: "+02:00 Amman (Asia/Amman)" },
    { label: <div>+02:00 Athens, Bucharest, Istanbul (Europe/Athens)</div>,
     value: "+02:00 Athens, Bucharest, Istanbul (Europe/Athens)" },
    { label: <div>+02:00 Beirut (Asia/Beirut)</div>,
     value: "+02:00 Beirut (Asia/Beirut)" },
    { label: <div>+02:00 Cairo (Africa/Cairo)</div>,
     value: "+02:00 Cairo (Africa/Cairo)" },
    { label: <div>+02:00 Harare, Pretoria (Africa/Harare)</div>,
     value: "+02:00 Harare, Pretoria (Africa/Harare)" },
    { label: <div>+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Helsinki)</div>,
     value: "+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Helsinki)" },
    { label: <div>+02:00 Jerusalem (Asia/Jerusalem)</div>,
     value: "+02:00 Jerusalem (Asia/Jerusalem)" },
    { label: <div>+02:00 Minsk (Europe/Minsk)</div>,
     value: "+02:00 Minsk (Europe/Minsk)" },
    { label: <div>+02:00 Windhoek (Africa/Windhoek)</div>,
     value: "+02:00 Windhoek (Africa/Windhoek)" },
    { label: <div>+03:00 Kuwait, Riyadh, Baghdad (Asia/Kuwait)</div>,
     value: "+03:00 Kuwait, Riyadh, Baghdad (Asia/Kuwait)" },
    { label: <div>+03:00 Moscow, St. Petersburg, Volgograd (Europe/Moscow)</div>,
     value: "+03:00 Moscow, St. Petersburg, Volgograd (Europe/Moscow)" },
    { label: <div>+03:00 Nairobi (Africa/Nairobi)</div>,
     value: "+03:00 Nairobi (Africa/Nairobi)" },
    { label: <div>+03:00 Tbilisi (Asia/Tbilisi)</div>,
     value: "+03:00 Tbilisi (Asia/Tbilisi)" },
    { label: <div>+03:30 Tehran (Asia/Tehran)</div>,
     value: "+03:30 Tehran (Asia/Tehran)" },
    { label: <div>+04:00 Abu Dhabi, Muscat (Asia/Muscat)</div>,
     value: "+04:00 Abu Dhabi, Muscat (Asia/Muscat)" },
    { label: <div>+04:00 Baku (Asia/Baku)</div>,
     value: "+04:00 Baku (Asia/Baku)" },
    { label: <div>+04:00 Yerevan (Asia/Yerevan)</div>,
     value: "+04:00 Yerevan (Asia/Yerevan)" },
    { label: <div>+04:30 Kabul (Asia/Kabul)</div>,
     value: "+04:30 Kabul (Asia/Kabul)" },
    { label: <div>+05:00 Yekaterinburg (Asia/Yekaterinburg)</div>,
     value: "+05:00 Yekaterinburg (Asia/Yekaterinburg)" },
    { label: <div>+05:00 Islamabad, Karachi, Tashkent (Asia/Karachi)</div>,
     value: "+05:00 Islamabad, Karachi, Tashkent (Asia/Karachi)" },
    { label: <div>+05:30 Chennai, Kolkata, Mumbai, New Delhi (Asia/Calcutta)</div>,
     value: "+05:30 Chennai, Kolkata, Mumbai, New Delhi (Asia/Calcutta)" },
    { label: <div>+05:30 Sri Jayawardenapura (Asia/Calcutta)</div>,
     value: "+05:30 Sri Jayawardenapura (Asia/Calcutta)" },
    { label: <div>+05:45 Kathmandu (Asia/Katmandu)</div>,
     value: "+05:45 Kathmandu (Asia/Katmandu)" },
    { label: <div>+06:00 Almaty, Novosibirsk (Asia/Almaty)</div>,
     value: "+06:00 Almaty, Novosibirsk (Asia/Almaty)" },
    { label: <div>+06:00 Astana, Dhaka (Asia/Dhaka)</div>,
     value: "+06:00 Astana, Dhaka (Asia/Dhaka)" },
    { label: <div>+06:30 Yangon (Rangoon) (Asia/Rangoon)</div>,
     value: "+06:30 Yangon (Rangoon) (Asia/Rangoon)" },
    { label: <div>+07:00 Bangkok, Hanoi, Jakarta (Asia/Bangkok)</div>,
     value: "+07:00 Bangkok, Hanoi, Jakarta (Asia/Bangkok)" },
    { label: <div>+07:00 Krasnoyarsk (Asia/Krasnoyarsk)</div>,
     value: "+07:00 Krasnoyarsk (Asia/Krasnoyarsk)" },
    { label: <div>+08:00 Beijing, Chongqing, Hong Kong, Urumqi (Asia/Hong_Kong)</div>,
     value: "+08:00 Beijing, Chongqing, Hong Kong, Urumqi (Asia/Hong_Kong)" },
    { label: <div>+08:00 Kuala Lumpur, Singapore (Asia/Kuala_Lumpur)</div>,
     value: "+08:00 Kuala Lumpur, Singapore (Asia/Kuala_Lumpur)" },
    { label: <div>+08:00 Irkutsk, Ulaan Bataar (Asia/Irkutsk)</div>,
     value: "+08:00 Irkutsk, Ulaan Bataar (Asia/Irkutsk)" },
    { label: <div>+08:00 Perth (Australia/Perth)</div>,
     value: "+08:00 Perth (Australia/Perth)" },
    { label: <div>+08:00 Taipei (Asia/Taipei)</div>,
     value: "+08:00 Taipei (Asia/Taipei)" },
    { label: <div>+09:00 Osaka, Sapporo, Tokyo (Asia/Tokyo)</div>,
     value: "+09:00 Osaka, Sapporo, Tokyo (Asia/Tokyo)" },
    { label: <div>+09:00 Seoul (Asia/Seoul)</div>,
     value: "+09:00 Seoul (Asia/Seoul)" },
    { label: <div>+09:00 Yakutsk (Asia/Yakutsk)</div>,
     value: "+09:00 Yakutsk (Asia/Yakutsk)" },
    { label: <div>+09:30 Adelaide (Australia/Adelaide)</div>,
     value: "+09:30 Adelaide (Australia/Adelaide)" },
    { label: <div>+09:30 Darwin (Australia/Darwin)</div>,
     value: "+09:30 Darwin (Australia/Darwin)" },
    { label: <div>+10:00 Brisbane (Australia/Brisbane)</div>,
     value: "+10:00 Brisbane (Australia/Brisbane)" },
    { label: <div>+10:00 Canberra, Melbourne, Sydney (Australia/Canberra)</div>,
     value: "+10:00 Canberra, Melbourne, Sydney (Australia/Canberra)" },
    { label: <div>+10:00 Hobart (Australia/Hobart)</div>,
     value: "+10:00 Hobart (Australia/Hobart)" },
    { label: <div>+10:00 Guam, Port Moresby (Pacific/Guam)</div>,
     value: "+10:00 Guam, Port Moresby (Pacific/Guam)" },
    { label: <div>+10:00 Vladivostok (Asia/Vladivostok)</div>,
     value: "+10:00 Vladivostok (Asia/Vladivostok)" },
    { label: <div>+11:00 Magadan, Solomon Is., New Caledonia (Asia/Magadan)</div>,
     value: "+11:00 Magadan, Solomon Is., New Caledonia (Asia/Magadan)" },
    { label: <div>+12:00 Auckland, Wellington (Pacific/Auckland)</div>,
     value: "+12:00 Auckland, Wellington (Pacific/Auckland)" },
    { label: <div>+12:00 Fiji, Kamchatka, Marshall Is. (Pacific/Fiji)</div>,
     value: "+12:00 Fiji, Kamchatka, Marshall Is. (Pacific/Fiji)" },
    { label: <div>+13:00 Nuku'alofa (Pacific/Tongatapu)</div>,
     value: "+13:00 Nuku'alofa (Pacific/Tongatapu)" }
  ]
const [timeZone, setTimeZone] = useState("-03:00 Greenland (America/Godthab)")
const [allLan, setAllLan] = useState([])
// const addLanguage = (lan) => {
//     /////console.log(lan)
//     /////console.log(allLan)
//     if(allLan.length > 0 && allLan.some(x=> x== lan)) {
//         /////console.log("here")
//         setAllLan(allLan.filter(x => x !== lan))
//     } else {
//         let newA = [...allLan.push(lan)]
//         setAllLan(newA)
//     }
// }

const onSelectFunc = (selectedList, selectedItem) => {
/////console.log(selectedList, selectedItem)
setAllLan(selectedList)
}

const onRemoveFunc = (selectedList, removedItem) => {
    /////console.log(selectedList, removedItem)
    setAllLan(selectedList)
}
const languages = [
    {name:"English", id:1},
    {name:"Italian", id:2},
    {name:"Spanish", id:3},
    {name:"German", id:4},
    {name:"Arabic", id:5}
]
const [language, setLanguage] = useState("")
const currencies = ['USD', "EUR", "GBP", "CHF", "EG"] 
const [currency, setCurrency] = useState("")


useEffect(() => {
    if(activeOrgName) {
        setOrgName(activeOrgName.substring(activeOrgName[0], activeOrgName.indexOf("-")))
    }
},[activeOrgName])

useEffect(() => {
    if(activeOrgLangauge) {
        let allLang = JSON.parse(activeOrgLangauge)
        setLanguage(allLang[0].name)
        setAllLan(JSON.parse(activeOrgLangauge))
    }
},[activeOrgLangauge])
useEffect(() => {
    if(activeOrgCurrency) {
        setCurrency(activeOrgCurrency)
    }
},[activeOrgCurrency])
useEffect(() => {
    if(activeOrgTimezone) {
        setTimeZone(activeOrgTimezone)
    }
},[activeOrgTimezone])
useEffect(() => {
    if(activeOrgIndustry) {
        setIndustry(activeOrgIndustry)
    }
},[activeOrgIndustry])


const updateOrganizationFunc = () => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        updateOrganization({
            variables:{
                _id: activeOrg,
                name: orgName,
                industry,
                language: JSON.stringify(allLan),
                currency,
                timeZone
            }
        }).then(() => {
            organizationsRefetch()
        })
}

const [labelingData, setLabelingData] = useState([])
const labelingColumns = [
        
    {
        name: 'before',
        cell: row => (
            <div id="beforeDiv" style={{color:"red"}}>
                {row.before} <span style={{color:"grey"}}>{row.default && "default"}</span>
            </div>),
    },        
    {
        name: 'after',
        cell: row => (
            row.after ? <div style={{color:"green"}}>
               {row.after}
           </div> : (
               <div
               className="auth-button noneD"
               style={{width:"100px"}}
               onClick={() => {
                setBeforeName(row.before)
                setRowId(row)
                setLabelingAddModal("Edit a Replacement")
               }}
             >
                Change
            </div> 
           )),
    },
    // {
    //     name: 'State',
    //     filter:["none"],
    //     cell: row => (
    //         <div className="print-status">
    //         <span>{row.role}</span>
    //     </div>
    //     ),
        
    // },
    // {
    //     name: 'Actions',
    //     filter:["none"],
    //     ignoreRowClick: true,
    //     allowOverflow: false,
    //     cell: row => (
    //         <div className="actions-buttons">
    //             <img style={{marginRight:".4rem"}} className="actionHover" src={edit}/>
    //             <img style={{marginLeft:".4rem"}} className="actionHover" src={trash}/>
    //         </div>
    //     ),
        
    // },
    
];


useEffect(() => {
    if(keywordsData && keywordsData.allKeywords) {
        setLabelingData(keywordsData.allKeywords.map(keyword => {
            return {
                before: keyword.before,
                after: keyword.after,
                org: keyword.org,
                language: keyword.language,
                id: keyword._id,
                default: keyword.default
            }
        }))
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
},[keywordsData])

const [allThemesData, setAllThemesData] = useState(null)
useEffect(() => {
    if(themesData && themesData.allThemes) {
        setAllThemesData(themesData.allThemes.map(theme => {
            return {
                createdAt: theme.createdAt,
                org: theme.org,
                name: theme.name,
                id: theme._id,
                default: theme.default
            }
        }))
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
},[themesData])


const [addKeyword, {error: addKeywordError, data: addKeywordData, loading: addKeywordLoading}] = useMutation(addKeywordMutation)
const [updateKeyword, {error: updateKeywordError, data: updateKeywordData, loading: updateKeywordLoading}] = useMutation(updateKeywordMutation)
const [updateThemeStatus, {error: updateThemeStatusError, data: updateThemeStatusData, loading: updateThemeStatusLoading}] = useMutation(updateThemeStatusMutation)


const updateDefaultTheme = (x) => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    let {id} = x
    updateThemeStatus({
        variables: {
            _id: id
        }
    }).then(res => {
        themesRefetch()
        dispatch(ACTION_LOADING_SPINNER_RESET())
    })
}

const [labelingAddModal ,setLabelingAddModal] = useState(null)

const [beforeName, setBeforeName] = useState("")
const [rowId, setRowId] = useState(null)

const [afterName, setAfterName] = useState("")

const addKeywordFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    addKeyword({
        variables:{
            org: activeOrg,
            before: beforeName,
            after: afterName
        }
    }).then((res) => {
        setBeforeName("")
        setAfterName("")
        setLabelingAddModal(null)
        keywordsRefetch()
    })
}

const updateKeywordFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setLabelingAddModal(null)
    /////console.log(afterName)
    updateKeyword({
        variables:{
            _id: rowId.id,
            after: afterName
        }
    }).then((res) => {
        setRowId(null)
        setBeforeName("")
        setAfterName("")
        keywordsRefetch()
    })
}

 return (
        <div style={{padding:"0 100px"}} className="adminContent column">
            {returnToHomeScreen()}
                <>
            <div className="workspaceHeader">
                <div className="workspaceName">
                    settings
                </div>
                <div className="workspaceButtons">
                    <div className="adminButton">
                    <img className="buttonIconImage" src={editIcon} />
                    </div>
                    <div className="adminButton">
                    <img className="buttonIconImage" src={shareIcon} />
                    </div>
                    <div className="adminButton">
                    <img className="buttonIconImage" src={deleteIcon} />
                    </div>

                </div>

            </div>
            <div className="workspaceContainer">
                <div className="viewsHeader">
                {views.map((view, i) => (
                    <div onClick={() => setActiveView(view.name)} className={`viewContainer`} style={{fontWeight: activeView == view.name ? "600" : "500"}}>
                        <span className="viewWN">{view.name}</span>
                            <div className={`${activeView == view.name ? "activeView" : "hoverView"}`} />
                    </div>
                ))}
                </div>
                <div className="workspaceContent" style={{height:"100%", width:"100%", display:"flex", alignItems:"flex-end", justifyContent:"space-around"}}>
                        <div style={{display: activeView == "General" ? "flex" : "none", width:"100%", height:"100%"}}>
                         <div style={{width:"50%", height:"100%", padding:"3rem 0"}}>
                         <div className="auth-group">
                         <TextInput 
                           type="orgName"
                           label="Name of your ORG"
                           value={orgName}
                           setValue={setOrgName}
                           placeHolder="Enter org name"
                           error={orgError}
                           setError={setOrgError} 
                         />
                         </div>
               
                         <div className="auth-group">
                           <div className="auth-label">Industry</div>
                         <Dropdown
                                 options={industries}
                                 controlClassName="auth-input" 
                                 arrowClosed={<span className="arrow-closed arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowDownDark}
                     /></span>}
                                 arrowOpen={<span className="arrow-open arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowUp}
                     /></span>}
                                 placeholder={"Select industry of your ORG"}
                                 value={industry}
                                 onChange={(e) => {
                                    setIndustry(e.value)
                                  }}
                               />
                         </div>
               
                         <div className="auth-group">
                         <div className="auth-label">timeZone</div>
                         <Dropdown
                                 options={timeZones}
                                 controlClassName="auth-input"
                                 arrowClosed={<span className="arrow-closed arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowDownDark}
                     /></span>}
                                 arrowOpen={<span className="arrow-open arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowUp}
                     /></span>}
                                 placeholder={"Select your timezone"}
                                 value={timeZone}
                                 onChange={(e) => {
                                   setTimeZone(e.value)
                                 }}
                                 
                               />
                         </div>
                         <div className="auth-group">
                         <div className="auth-label">language</div>
                         <Multiselect
                                options={languages} // Options to display in the dropdown
                                selectedValues={allLan} // Preselected value to persist in dropdown
                                onSelect={onSelectFunc} // Function will trigger on select event
                                onRemove={onRemoveFunc} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                         />
                         </div>
                         <div className="auth-group">
                         <div className="auth-label">currency</div>
                         <Dropdown
                                 options={currencies}
                                 controlClassName="auth-input" 
                                 menuClassName="underMenu"
                                 arrowClosed={<span className="arrow-closed arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowDownDark}
                     /></span>}
                                 arrowOpen={<span className="arrow-open arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowUp}
                     /></span>}
                                 placeholder={"Select your currency"}
                                 value={currency}
                                 onChange={(e) => {
                                   setCurrency(e.value)
                                 }}
                                 
                               />
                         </div>        
                       </div>
                       <div style={{width:"100%", display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                       <div
                       style={{width:"120px"}}
              className={`${orgName && industry && timeZone && allLan && currency ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={orgName && industry && timeZone && allLan && currency ? updateOrganizationFunc : null }
            >
              
              {"Update"}

            </div> 
            </div> 
                        </div>
                        <div className="workspaceContainer" style={{marginTop:"4rem" , display: activeView == "labeling" ? "flex" : "none"}}>
                               <AddKeywordModal 
                               open={labelingAddModal}
                               setOpen={setLabelingAddModal}
                               beforeName={beforeName}
                               setBeforeName={setBeforeName}
                               afterName={afterName}
                               setAfterName={setAfterName}
                               addKeyword={addKeywordFunc}
                               updateKeywordFunc={updateKeywordFunc}
                               addKeywordLoading={addKeywordLoading}
                               updateKeywordLoading={updateKeywordLoading}
                               rowId={rowId}
                               setRowId={setRowId}
                               />
            <div className="workspaceHeader">
                <div className="workspaceName" style={{display:"flex", alignItems:"center", fontSize:"20px"}}>
                    Replacements
                        <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => setLabelingAddModal("Replacement")}
                        
                        />
                </div>
                <div className="workspaceButtons">
                    
                            <Dropdown
                                 options={allLan && allLan.map(x=> {
                                     return x.name
                                })}
                                 controlClassName="auth-input" 
                                 arrowClosed={<span className="arrow-closed arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowDownDark}
                     /></span>}
                                 arrowOpen={<span className="arrow-open arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowUp}
                     /></span>}
                                 placeholder={"Select language"}
                                 value={language}
                                 onChange={(e) => {
                                   setLanguage(e.value)
                                 }}
                                 
                               />                
                </div>
            </div>

                           <AccountTable
                            accountColumns={labelingColumns}
                            accountData={labelingData.filter(x => language ? x.language == language : x.language == "English")}
                            labeling={true}
                            />
                        </div>     

                         <div className="workspaceContainer" style={{marginTop:"4rem" , display: activeView == "theme" ? "flex" : "none"}}>
                          
                    

                         <div className="workspaceHeader" style={{ padding:"0px", marginTop:"100px", flexWrap:"wrap", justifyContent:"flex-start", height:"fit-content !important"}}>
                         {/* <div className="workspaceName" style={{width:"200px"}}>
                    Auth
                </div> */}
                {allThemesData?.map((x) => (
                <div style={{position:"relative", border:x.default ? "2px solid green":"0px", minWidth:"200px"}} className="workspaceCard">
                    {!x.default ? <div onClick={() => updateDefaultTheme(x)} style={{display:"none", position:"absolute", bottom:"5px", right:"5px"}} className="workspaceName">
                           Set Default
                        </div> : null}
                    <div className="workspaceThumbnail">
                        {x.name}
                       
                        </div>
                    <div className="workspaceHeader">
                      
                            {/* <img className="buttonIconImage" src={threedots} /> */}
                    </div>
                    <div className="workspacefooter">
                        <div className="footerContent">
                            Created {moment.unix(x.createdAt/1000).format("MM/DD/YYYY")}
                        </div>
                        <img className="dot" src={dot} />
                        <div style={{color:"green"}} className="footerContent">
                            {x.default ? "Default" : ""}
                        </div>
                    </div>

                </div>
            ))}
            </div>
            </div>  

                </div> 
            </div>
            </>

        </div>
    )
}

export default AdminSettings