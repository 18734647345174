import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog10 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="-my-8 divide-y-2 divide-gray-100">
      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="937239" is="div"><Text text="CATEGORY" /></Element>} </span>
          <span class="mt-1 text-gray-500 text-sm"> {<Element canvas style={{display:"inline-block"}} id="620002" is="div"><Text text="12 Jun 2019" /></Element>} </span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl font-medium text-gray-900 title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="210903" is="div"><Text text="Bitters hashtag waistcoat fashion axe chia unicorn" /></Element>} </h2>
          <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="607000" is="div"><Text text="Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer." /></Element>} </p>
          <a class="text-indigo-500 inline-flex items-center mt-4"> {<Element canvas style={{display:"inline-block"}} id="856470" is="div"><Text text="Learn More" /></Element>} <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="712719" is="div"><Text text="CATEGORY" /></Element>} </span>
          <span class="mt-1 text-gray-500 text-sm"> {<Element canvas style={{display:"inline-block"}} id="282884" is="div"><Text text="12 Jun 2019" /></Element>} </span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl font-medium text-gray-900 title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="741047" is="div"><Text text="Meditation bushwick direct trade taxidermy shaman" /></Element>} </h2>
          <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="130583" is="div"><Text text="Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer." /></Element>} </p>
          <a class="text-indigo-500 inline-flex items-center mt-4"> {<Element canvas style={{display:"inline-block"}} id="948151" is="div"><Text text="Learn More" /></Element>} <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="116638" is="div"><Text text="CATEGORY" /></Element>} </span>
          <span class="text-sm text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="815406" is="div"><Text text="12 Jun 2019" /></Element>} </span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl font-medium text-gray-900 title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="860294" is="div"><Text text="Woke master cleanse drinking vinegar salvia" /></Element>} </h2>
          <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="811362" is="div"><Text text="Glossier echo park pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf moon party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie portland craft beer." /></Element>} </p>
          <a class="text-indigo-500 inline-flex items-center mt-4"> {<Element canvas style={{display:"inline-block"}} id="412616" is="div"><Text text="Learn More" /></Element>} <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
    </section>
  
      </div>;
};
Blog10.craft = {
  displayName: "Blog 10"
};