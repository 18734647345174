import * as React from 'react';
import './index.scss';
import { closest, isNullOrUndefined } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject,Group, VirtualScroll, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { updateSampleSection } from './sample-base';
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';

import { orderDetails } from './data-source';


function Component(props) {
    React.useEffect(() => {
        updateSampleSection();
    }, []);
    const groupOptions = { showGroupedColumn: true};
    const sortSettings= { columns: [{ field: 'date', direction: 'Descending' }] }
    const select = {
        persistSelection: true,
    };
    const gridFilter = {
        type: 'Menu'
    }; 
    return (<div className='control-pane'>
      <div className='control-section'>
      {props?.resultsArr?.length ? 
      <GridComponent
      rowSelected={(row) => props.setSelectedRow(row.data)}
      {...props.allEvents}
      dataSource={props.resultsArr || orderDetails} height='350'
      allowGrouping={props.allowGrouping} 
      groupSettings={groupOptions} 
      enableHover={props.enableHover} 
      enableVirtualization={true} 
      rowHeight={props.rowHeight} 
      filterSettings={gridFilter} 
      allowFiltering={props.allowFiltering} 
      allowSorting={props.allowSorting} 
      sortSettings= {sortSettings} 
      allowSelection={props.allowSelection} 
      selectionSettings={select}
      >
        <ColumnsDirective >
        {props?.resultsArr?.length ? Object.keys(props.resultsArr[0]).map(x => (
          <ColumnDirective field={x} headerText={x} clipMode='ellipsis' width='80' ></ColumnDirective>
        )): null}
            {/* <ColumnDirective field='OrderID' headerText='Order ID' width='120' textAlign='Right'></ColumnDirective>
            <ColumnDirective field='CustomerName' headerText='Customer Name' width='150'></ColumnDirective>
            <ColumnDirective field='OrderDate' headerText='Order Date' width='130' format='yMd' textAlign='Right'/>
            <ColumnDirective field='Freight' headerText='Freight' width='120' format='C2' textAlign='Right'/>
            <ColumnDirective field='ShippedDate' headerText='Shipped Date' width='130' format='yMd' textAlign='Right'></ColumnDirective>
            <ColumnDirective field='ShipCountry' headerText='Ship Country' width='150'></ColumnDirective> */}
          </ColumnsDirective>
          <Inject services={[Group,Filter, VirtualScroll, Sort]}/>

        </GridComponent> : <div style={{border:"2px solid gray", width:"900px", height:"400px", display:"flex", justifyContent:"center", alignItems:"center"}}> 
        No Records or datasource founded
        
        </div>}
      </div>
    </div>);
}
export default Component;