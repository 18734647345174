import './index.scss';
import * as React from 'react';
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
   
    render() {
        return (<div className='control-pane'>
                <div className='control-section'>
                    <div className='calendar-control-section' style={{ overflow: 'auto' }}>
                        <CalendarComponent
                        {...this.props.allEvents}
                        change={this.onchange}></CalendarComponent>
                    </div>
                </div>
            </div>);
    }
}

export default Component