import React, { useState } from 'react';

const Banner_1 = (props) => {

    const [selected, setSelected] = useState(0)
    // const styles = [
    //   // <div className="RightSideImageBannerStyle1Desktop lg:w-[1440px] md:w-[1024px] w-[390px] h-[780px] relative bg-white">
    //   //     <div className="UnderstandUserFlow lg:left-[124px] md:left-[60px] left-[37px] lg:top-[140px] md:top-[180px] top-[74px] absolute text-stone-950 lg:text-[52px] md:text-[38px] text-[28px] font-bold lg:leading-[72.80px] md:leading-[53.20px] leading-[39.20px]">Understand User Flow.</div>
    //   //     <div className="IncreaseConversion lg:left-[124px] md:left-[60px] left-[54px] lg:top-[223px] md:top-[234px] top-[120px] absolute text-red-700 lg:text-[52px] md:text-[38px] text-[28px] font-bold lg:leading-[72.80px] md:leading-[53.20px] leading-[39.20px]">Increase Conversion.</div>
    //   //     <div className="LoremIpsumDolorSitAmetConsecteturAdipisicingElitNesciuntIlloTeneturFugaDucimusNumquamEa lg:w-[585px] md:w-[465px] w-[342px] lg:left-[124px] md:left-[60px] left-[24px] lg:top-[316px] md:top-[316px] top-[487px] absolute text-stone-950 lg:text-[28px] md:text-[22px] text-lg lg:text-left md:text-left sm:text-center font-normal lg:leading-[44.80px] md:leading-[33px] leading-[25.20px]">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!</div>
    //   // <div className="Button w-[260px] cursor-pointer h-[70px] lg:left-[124px] md:left-[60px] left-[65px] lg:top-[491px] md:top-[455px] top-[582px] absolute">
    //   //     <div className="Rectangle1440 w-[260px] h-[70px] left-0 top-0 absolute bg-red-700 shadow" />
    //   //     <div className="GetStarted left-[56px] top-[19px] absolute text-center text-white text-[22px] font-semibold uppercase leading-[30.80px]">Get started</div>
    //   // </div>
    //   // <div className="Button w-[260px] cursor-pointer h-[70px] lg:left-[424px] md:left-[60px] left-[65px] lg:top-[491px] md:top-[545px] top-[672px] absolute">
    //   //     <div className="Rectangle1440 w-[260px] h-[70px] left-0 top-0 absolute bg-white shadow" />
    //   //     <div className="LearmMore left-[58px] top-[19px] absolute text-center text-black text-[22px] font-semibold uppercase leading-[30.80px]">Learm more</div>
    //   // </div>
    //   // {/* has no responsive yet */}
    //   //     <img alt='style2' className="w-[737px] h-[780px] absolute left-[703px] top-0 object-cover" src="https://i.ibb.co/kBMrkD8/banner2-style1.png"/>
    //   // </div>,

    //   // <div className="RightSideImageBannerStyle2Desktop lg:w-[1440px] md:w-[1024px] w-[390px] h-[780px] relative bg-gray-900">
    //   //     <div className="UnderstandUserFlow2 lg:left-[124px] md:left-[60px] left-[37px] lg:top-[160px] md:top-[190px] top-[100px] absolute text-emerald-400 lg:text-[52px] md:text-[38px] text-[28px] font-bold lg:leading-[72.80px] md:leading-[53.20px] leading-[39.20px]">Understand User Flow.</div>
    //   //     <div className="IncreaseConversion2 lg:left-[124px] md:left-[60px] left-[54px] lg:top-[243px] md:top-[253px] top-[146px] absolute text-emerald-400 lg:text-[52px] md:text-[38px] text-[28px] font-bold lg:leading-[72.80px] md:leading-[53.20px] leading-[39.20px]">Increase Conversion.</div>
    //   //     <div className="LoremIpsumDolorSitAmetConsecteturAdipisicingElitNesciuntIlloTeneturFugaDucimusNumquamEa lg:w-[545px] md:w-[480px] w-[342px] lg:left-[124px] md:left-[60px] left-[24px] lg:top-[346px] md:top-[326px] top-[445px] absolute text-white lg:text-[28px] md:text-[22px] text-lg lg:text-left md:text-left sm:text-center font-normal lg:leading-[42px] md:leading-[30.80px] leading-[25.20px]">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!</div>
    //   // <div className="Button w-[260px] cursor-pointer h-[70px] lg:left-[124px] md:left-[60px] left-[65px] lg:top-[512px] md:top-[459px] top-[560px] absolute">
    //   //     <div className="Rectangle1440 w-[260px] h-[70px] left-0 top-0 absolute bg-blue-600" style={{boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)'}} />
    //   //     <div className="GetStarted left-[56px] top-[19px] absolute text-center text-white text-[22px] font-semibold uppercase leading-[30.80px]">Get started</div>
    //   // </div>
    //   // <div className="Button w-[260px] cursor-pointer h-[70px] lg:left-[424px] md:left-[60px] left-[65px] lg:top-[512px] md:top-[549px] top-[650px] absolute" style={{boxShadow: "0px 10px 10px 0px rgba(255, 255, 255, 0.05)"}}>
    //   //     <div className="Rectangle1440 w-[260px] h-[70px] left-0 top-0 absolute bg-gray-900 shadow" style={{border: '1px solid white', opacity:'10%'}} />
    //   //     <div className="LearmMore left-[58px] top-[19px] absolute text-center text-white text-[22px] font-semibold uppercase leading-[30.80px]">Learm more</div>
    //   // </div>
    //   // {/* has no responsive yet */}
    //   //     <img className="w-[737px] h-[780px] absolute left-[703px] top-0 object-cover" alt='banner2-style2' src="https://i.ibb.co/8XYZ5GB/bannerstyle.png"/>
    //   // </div>,

    //   // <div className="RightSideImageBannerStyle2Desktop lg:w-[1440px] md:w-[1024px] w-[390px] h-[780px] relative bg-[#213156]">
    //   //     <div className="UnderstandUserFlow3 lg:left-[124px] md:left-[60px] left-[58px] lg:top-[180px] md:top-[200px] top-[100px] absolute text-emerald-400 lg:text-[62px] md:text-[42px] text-[28px] font-bold text-center lg:leading-[78.40px] md:leading-[58.80px] leading-[39.20px]">Understand User Flow</div>
    //   //     <div className="IncreaseConversion3 lg:left-[124px] md:left-[60px] left-[68px] lg:top-[268px] md:top-[269px] top-[149px] absolute text-emerald-400 lg:text-[46px] md:text-[32px] text-[20px] font-bold text-center lg:leading-[64.40px] md: leading-[58.80px] leading-[33.60px]">Increase Conversion</div>
    //   //     <div className="LoremIpsumDolorSitAmetConsecteturAdipisicingElitNesciuntIlloTeneturFugaDucimusNumquamEa lg:w-[628px] md:w-[493px] w-[324px] lg:left-[124px] md:left-[60px] left-[24px] lg:top-[362px] md:top-[334px] top-[463px] absolute text-white lg:text-[28px] md:text-[22px] text-2xl lg:text-left md:text-left sm:text-center font-normal lg:leading-[39.20px] md:leading-[33px] leading-snug">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!</div>
      
    //   // <div className="Button w-[260px] cursor-pointer lg:h-[70px] h-[60px] lg:left-[124px] md:left-[60px] left-[65px] lg:top-[519px] md:top-[473px] top-[569px] absolute">
    //   //     <div className="Rectangle1440 w-[260px] h-[70px] left-0 top-0 absolute bg-fuchsia-400 rounded-[40px] bg-[#EA6EF4]" style={{border:'1px solid #EA6EF4'}} />
    //   //     <div className="GetStarted lg:left-[58px] lg:top-[19px] md:left-[58px] md:top-[20px] left-[58px] top-[20px] absolute text-center text-white text-[22px] font-semibold uppercase leading-[30.80px]">Get started</div>
    //   // </div>
    //   // <div className="Button w-[260px] cursor-pointer h-[70px] lg:left-[424px] md:left-[60px] left-[65px] lg:top-[519px] md:top-[553px] top-[649px] absolute">
    //   //     <div className="Rectangle1440 w-[260px] lg:h-[70px] h-[60px] left-0 top-0 absolute rounded-[40px] border border-fuchsia-400" />
    //   //     <div className="LearmMore lg:left-[58px] lg:top-[19px] md:left-[55px] md:top-[15px] left-[58px] top-[16px] absolute text-center text-white text-[22px] font-semibold uppercase leading-[30.80px]">Learm more</div>
    //   // </div>
    //   // {/* has no responsive yet */}
    //   //     <img alt='style3' className="Rectangle1525 lg:w-[580px] lg:h-[780px] lg:left-[860px] md:w-[421px] md:h-[780px] md:left-[603px] w-[370px] h-[220px] left-[10px] top-[203px] lg:top-0 md:top-0 absolute lg:rounded-tl-[180px] md:rounded-tl-[180px] sm:rounded-[20px] md:rounded-none" src="https://i.ibb.co/x6CPpPq/banner2-style3.jpg" />
    //   // </div>,

    //   <div className="RightSideImageBannerStyle4Desktop lg:w-[1440px] md:w-[1024px] w-[390px] h-[780px] relative bg-[#dffff9] overflow-hidden">
    //       <div className="UnderstandUserFlow lg:left-[124px] md:left-[60px] left-[50px] top-[70px] md:top-[180px] lg:top-[180px] absolute text-gray-950 lg:text-[66px] md:text-[56px] text-[32px] lg:leading-[92.40px] md:leading-[78.40px] leading-[50.40px]" style={{fontFamily :'Zing Script Rust SemiBold Demo'}}>Understand User Flow</div>
    //       <div className="IncreaseConversion lg:left-[124px] md:left-[60px] left-[81px] lg:top-[268px] md:top-[254px] top-[119px] absolute text-[#31A993] lg:text-[56px] md:text-[46px] text-[28px] lg:leading-[78.40px] md:leading-[64.40px] leading-[39.20px]" style={{fontFamily :'Zing Script Rust SemiBold Demo'}}>Increase Conversion</div>
    //       <div className="LoremIpsumDolorSitAmetConsecteturAdipisicingElitNesciuntIlloTeneturFugaDucimusNumquamEa w-[332px] md:w-[414px] lg:w-[839px] left-[29px] md:left-[60px] lg:left-[124px] lg:top-[376px] md:top-[339px] top-[497px] sm:text-center lg:text-left md:text-left absolute text-gray-950 lg:text-[28px] md:text-3xl text-xl font-normal lg:leading-[39.20px] md:leading-[27px] leading-snug" style={{fontFamily:'Montserrat'}}>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!</div>
    //   <div className="Button w-[260px] cursor-pointer lg:h-[70px] h-[60px] lg:left-[124px] md:left-[60px] left-[65px] lg:top-[519px] md:top-[473px] top-[569px] absolute">
    //       <div className="Rectangle1440 w-[260px] lg:h-[70px] h-[60px] left-0 top-0 absolute rounded-[40px] bg-[#31A993]" style={{border:'1px solid #31A993'}} />
    //       <div className="GetStarted  lg:left-[58px] lg:top-[19px] md:left-[58px] md:top-[20px] left-[58px] top-[20px] absolute text-center text-white text-[22px] font-semibold uppercase leading-[30.80px]">Get started</div>
    //   </div>
    //   <div className="Button w-[260px] cursor-pointer h-[70px] lg:left-[424px] md:left-[60px] left-[65px] lg:top-[519px] md:top-[553px] top-[649px] absolute">
    //       <div className="Rectangle1440 w-[260px] lg:h-[70px] h-[60px] left-0 top-0 absolute rounded-[40px]" style={{border:'1px solid #31A993'}}/>
    //       <div className="LearmMore lg:left-[58px] lg:top-[19px] md:left-[55px] md:top-[15px] left-[58px] top-[16px] absolute text-center text-[#31A993] text-[22px] font-semibold uppercase leading-[30.80px]">Learm more</div>
    //   </div>
    //   <img alt='banner2-4' className="Ellipse2 lg:w-[673px] md:w-[673px] w-[300.07px] lg:h-[673px] md:h-[673px] h-[299px] lg:left-[953px] md:left-[557px] left-[45px] lg:top-[54px] md:top-[54px] top-[178px] absolute rounded-full object-cover" src="https://i.ibb.co/Sw406Hw/banner2-4.jpg" />
    // </div>,

    //   <div className="RightSideImageBannerStyle5Desktop lg:w-[1440px] md:w-[1024px] w-[390px] h-[780px] relative bg-white">
    //       <div className="UnderstandUserFlow md:left-[60px] lg:left-[124px] md:top-[190px] lg:top-[180px] left-[58px] top-[60px] absolute text-slate-950 md:text-[46px] lg:text-[56px] text-[28px] font-bold md:leading-[64.40px] lg:leading-[78.40px] leading-[39.20px]" style={{fontFamily:'Inter'}}>Understand User Flow</div>
    //       <div className="IncreaseConversion md:left-[60px] lg:left-[124px] md:top-[264px] lg:top-[268px] left-[70px] top-[106px] absolute text-sky-600 md:text-[46px] lg:text-[56px] text-[28px] font-bold md:leading-[64.40px] lg:leading-[78.40px] leading-[39.20px] text-[#2563eb]" style={{fontFamily:'Inter'}}>Increase Conversion</div>
    //       <div className="LoremIpsumDolorSitAmetConsecteturAdipisicingElitNesciuntIlloTeneturFugaDucimusNumquamEa md:w-[463px] lg:w-[616px] w-[342px] left-[24px] top-[455px] md:left-[60px] lg:left-[124px] md:top-[348px] lg:top-[366px] absolute sm:text-center lg:text-left md:text-left text-slate-950 md:text-[21px] lg:text-[32px] text-[16px] font-normal leading-[25.20px] md:leading-[27px] lg:leading-[39.20px]" style={{fontFamily:'Inter'}}>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!</div>
    //   <div className="Button w-[260px] cursor-pointer h-[70px] lg:left-[124px] md:left-[60px] left-[65px] lg:top-[512px] md:top-[459px] top-[560px] absolute">
    //       <div className="Rectangle1440 w-[260px] h-[70px] left-0 top-0 absolute bg-blue-600" style={{boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', border:'1px solid blue-600', borderRadius:'10px'}} />
    //       <div className="GetStarted left-[55px] top-[19px] absolute text-center text-white text-[22px] font-semibold uppercase leading-[30.80px]" style={{fontFamily:'Inter'}}>Get started</div>
    //   </div>
    //   <div className="Button w-[260px] cursor-pointer h-[70px] lg:left-[424px] md:left-[60px] left-[65px] lg:top-[512px] md:top-[549px] top-[650px] absolute" style={{boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)', border:'1px solid #2563eb', borderRadius:'10px'}} >
    //       <div className="Rectangle1440 w-[270px] h-[70px] left-0 top-0 absolute " />
    //       <div className="LearmMore left-[55px] top-[17px] absolute text-center text-blue-600 text-[22px] font-semibold uppercase leading-[30.80px]" style={{fontFamily:'Inter'}}>Learm more</div>
    //   </div>
    //       <img className="Rectangle1526 lg:w-[580px] lg:h-[720px] lg:left-[800px] lg:top-[30px] md:w-[524px] md:h-[660px] md:left-[600px] md:top-[60px] w-[342px] h-[260px] left-[24px] top-[175px] absolute rounded-lg object-cover" alt='banner2-5' src="https://i.ibb.co/029nnS9/banner2-5.jpg" />
    //   </div>,

    //   <div className="RightSideImageBannerStyle6Desktop lg:w-[1440px] md:w-[1024px] w-[390px] h-[780px] relative bg-white">
    //       <div className="Rectangle1542 lg:w-[740px] md:w-[548px] lg:h-[780px] md:h-[780px] w-[390px] h-[311px] left-0 top-0 absolute bg-[#020212]" />
    //       <div className="UnderstandUserFlow lg:left-[124px] md:left-[60px] left-[30px] lg:top-[180px] md:top-[180px] top-[60px] absolute text-white lg:text-[48px] md:text-[38px] text-[32px] font-normal md:leading-[53.20px] lg:leading-[67.20px] leading-[44.80px]">Understand User Flow</div>
    //       <div className="SedUtPerspiciatisUndeOmnisIsteNatusErrorSitVoluptatemAccusantiumDoloremqueLaudantiumTotamRemAperiamEaqueIpsaQuaeAbIll w-[354px] left-[18px] top-[135px] lg:w-[496px] md:w-[427px] lg:left-[124px] md:left-[60px] lg:top-[287px] md:top-[273px] absolute lg:text-left sm:text-center md:text-left text-[#c0c0c4] lg:text-[28px] md:text-[24px] text-3xl font-normal md:leading-[33px] lg:leading-[38.40px] leading-[28.80px]" style={{wordWrap: 'break-word'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill.</div>
    //   <div className="Button Button lg:w-[280px] md:w-[280px] h-[60px] md:left-[60px] lg:left-[124px] left-[-500px] md:top-[463px] lg:top-[499px]  absolute">
    //       <div className="LearnMore top-[15px] left-[68px] absolute text-center text-white text-[22px] font-normal uppercase leading-[30.80px]">Learn more</div>
    //       <div className="Rectangle1568 w-[280px] h-[60px] left-0 top-0 absolute rounded-[10px] border border-white" />
    //   </div>
    //       <img className="Rectangle1545 lg:w-[680px] md:w-[456px] md:h-[740px] lg:h-[740px] md:left-[568px] lg:left-[760px] md:top-[20px] lg:top-[20px] w-[578px] h-[409px] left-0 top-[331px] absolute object-cover" alt='banner2-6' src="https://i.ibb.co/xsY4WGZ/banner2-6.jpg" />
    //   </div>,

    //   <div className="RightSideImageBannerStyle7Desktop lg:w-[1440px] md:w-[1024px] w-[390px] lg:h-[780px] lg:h-[780px] h-[757px] relative bg-white">
    //       <div className="Rectangle1569 lg:w-[720px] md:w-[620px] w-[358px] lg:h-[740px] md:h-[740px] w-[358px] h-[125px] left-[16px] top-[20px] lg:left-[20px] lg:top-[20px] md:left-[20px] md:top-[20px] absolute bg-[#6750a4] rounded-[30px] lg:rounded-[40px]" />
    //       <img alt='banner2-7' className="Rectangle1570 md:w-[344px] lg:w-[660px] lg:h-[740px] md:h-[740px] md:left-[660px] lg:left-[760px] lg:top-[20px] md:top-[20px] w-[358px] h-[440px] left-[16px] top-[155px] absolute rounded-[40px] object-cover" src="https://i.ibb.co/wsGFH9v/banner2-7.jpg" />
    //       <div className="UnderstandUserFlow md:left-[60px] lg:left-[60px] md:top-[230px] lg:top-[170px] left-[33px] top-[62px] absolute text-white md:text-[42px] lg:text-[60px] text-[30px] font-semibold md:leading-[67.20px] lg:leading-[89.60px] leading-[44.80px] ">Understand User Flow</div>
    //       <div className='md:w-[550px] h-[226px] lg:w-[632px] w-[358px] md:left-[60px] lg:left-[60px] left-[16px] rounded-[40px] md:top-[327px] lg:top-[300px] top-[605px] absolute  bg-[#6750a4]'>
    //           <div className="SedUtPerspiciatisUndeOmnisIsteNatusErrorSitVoluptatemAccusantiumDoloremqueLaudantiumTotamRemAperiamEaqueIpsaQuaeAbIll lg:text-left sm:text-center md:text-left text-white md:text-4xl lg:text-[30px] text-2xl lg:p-0 md:p-0 p-10 font-normal md:leading-[38.40px] lg:leading-[51.20px] leading-[28.80px]">
    //           Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill.
    //           </div>            
    //           <div className="StateLayer self-stretch pl-4 pr-6 py-2.5 items-center w-[220px] h-[50px] lg:left-[3px] md:left-[3px] left-[65px] md:top-[151px] lg:top-[245px] top-[151px] absolute bg-purple-300 rounded-[100px]">
    //               <div className="LabelText text-[#381e72] md:text-[17px] lg:text-[18px] text-center py-2.5 text-[15px] font-semibold uppercase tracking-tight">Learn more</div>
    //           </div>
    //       </div>
    //   </div> ] 
  return (
   
    <div style={{display:"flex", width:"100%", height:"100%", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <div style={{height:"7%", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            {styles.map((x,i) => (
                <div style={{cursor:"pointer", width:"50px", display:"flex", justifyContent:"center", alignItems:"center",  borderRadius:"4px", border:"1px solid red", height:"50px", margin:"10px",}} onClick={() => {
                    setSelected(i)
                }}>{i+1}</div>
            ))}
        </div>
        <div style={{height:"90%", width:"80%"}}>
        {styles[selected]}
        </div> 
    </div>
  );
};


export default Banner_1