import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  return (
    <>
      <ToolbarSection
      title="Label"
      props={['Label', "Mask"]}
      >
        <ToolbarItem
          full={true}
          propKey="label"
          type="text"
          label="Label"
        />
      </ToolbarSection>
      <ToolbarSection
            title="Mask"
            props={['label']}
      >
        <ToolbarItem
          full={true}
          propKey="mask"
          type="text"
          label="Mask"
        />
      </ToolbarSection>
      </>
  );
};
