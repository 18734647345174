import { gql } from "apollo-boost";

const allCollectionsQuery = gql`
  
    query(
    $org: ID
    $group: ID
  ) { 
    allCollections(
      org: $org
      group: $group
    ) {
    _id
    type
    org,
    group,
    forms,
    name,
  }
}
`;

export default allCollectionsQuery;
