import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
        <div class="container px-6 py-10 mx-auto">
            <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="522213" is="div"><Text text="From the blog" /></Element>} </h1>

            <div class="mt-8 lg:-mx-6 lg:flex lg:items-center">
                <Element canvas style={{ width: "100%" }} id={"div925195"} is="div">
          <ImageC class="object-cover w-full lg:mx-6 lg:w-1/2 rounded-xl h-72 lg:h-96" src="https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                <div class="mt-6 lg:w-1/2 lg:mt-0 lg:mx-6 ">
                    <p class="text-sm text-blue-500 uppercase"> {<Element canvas style={{display:"inline-block"}} id="422889" is="div"><Text text="category" /></Element>} </p>

                    <Element canvas style={{ width: "100%" }} id={"div835827"} is="div">
            <Button href={"#"} classn={"block mt-4 text-2xl font-semibold text-gray-800 hover:underline dark:text-white md:text-3xl"}>
               {<Element canvas style={{display:"inline-block"}} id="516965" is="div"><Text text="All the features you want to know" /></Element>} 
            </Button>
          </Element>

                    <p class="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm"> {<Element canvas style={{display:"inline-block"}} id="256120" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure veritatis sint autem nesciunt,
                        laudantium quia tempore delect" /></Element>} </p>

                    <Element canvas style={{ width: "100%" }} id={"div261136"} is="div">
            <Button href={"#"} classn={"inline-block mt-2 text-blue-500 underline hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="227819" is="div"><Text text="Read more" /></Element>} 
            </Button>
          </Element>

                    <div class="flex items-center mt-6">
                        <Element canvas style={{ width: "100%" }} id={"div968703"} is="div">
          <ImageC class="object-cover object-center w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1531590878845-12627191e687?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt=""  />
        </Element>

                        <div class="mx-4">
                            <h1 class="text-sm text-gray-700 dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="967290" is="div"><Text text="Amelia. Anderson" /></Element>} </h1>
                            <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="948885" is="div"><Text text="Lead Developer" /></Element>} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

      </div>;
};
Blog1.craft = {
  displayName: "Blog 1"
};