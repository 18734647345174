import { gql } from "apollo-boost";

const allKeywordsQuery = gql`
  
    query(
    $org: ID
  ) { 
    allKeywords (
      org: $org
    ) {
      _id
    after,
    before,
    language,
    org
    default
    }
  }
  
`;

export default allKeywordsQuery;

