import React from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import Dropdown from 'react-dropdown';
import arrowDownDark from "../../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../../assests/images/arrowUp.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const AddOrganizationModal = (props) => {
    const {
        open,
        setOpen,
        orgName,
        setOrgName,
        orgError,
        setOrgError,
        industries,
        industry,
        setIndustry,
        timeZones,
        timeZone,
        setTimeZone,
        languages,
        language,
        setLanguage,
        currencies,
        currency,
        setCurrency,
        addOrganization

    } = props

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open && <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setOrgName("")
                    setOrgError("")
                    setIndustry("")
                    setTimeZone("")
                    setLanguage("")
                    setCurrency("")
                }}
              />             
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? open : `Add a ${open}`}</h1>
          <div className="form-group-container task-form">
        <div>
          <div className="auth-group">
          <TextInput 
            type="orgName"
            label="Name of your ORG"
            value={orgName}
            setValue={setOrgName}
            placeHolder="Enter org name"
            error={orgError}
            setError={setOrgError} 
          />
          </div>

          <div className="auth-group">
            <div className="auth-label">Industry</div>
          <Dropdown
                  options={industries}
                  controlClassName="auth-input" 
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select industry of your ORG"}
                  value={industry}
                  onChange={(e) => {
                   setIndustry(e.value)
                  }}
                />
          </div>

          <div className="auth-group">
          <div className="auth-label">timeZone</div>
          <Dropdown
                  options={timeZones}
                  controlClassName="auth-input"
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select your timezone"}
                  value={timeZone}
                  onChange={(e) => {
                    setTimeZone(e.value)
                  }}
                  
                />
          </div>
          <div className="auth-group">
          <div className="auth-label">language</div>
          <Dropdown
                  options={languages}
                  controlClassName="auth-input" 
                  menuClassName="underMenu"
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select your default language"}
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.value)
                  }}
                  
                />
          </div>
          <div className="auth-group">
          <div className="auth-label">currency</div>
          <Dropdown
                  options={currencies}
                  controlClassName="auth-input" 
                  menuClassName="underMenu"
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select your currency"}
                  value={currency}
                  onChange={(e) => {
                    setCurrency(e.value)
                  }}
                  
                />
          </div>        
        </div>
       
          </div>
            <div
              className={`${orgName && industry && timeZone && language && currency ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={orgName && industry && timeZone && language && currency ?  open.includes("Edit") ? null : addOrganization : null }
            >
              
              {open.includes("Edit") ? "Update" : "Let's go!"}

            </div>
        </div>}
      </Modal>
  )}

  export default AddOrganizationModal
       