import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Contact8 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font relative">
  <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
    <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
      <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
        <div class="lg:w-1/2 px-6">
          <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs"> {<Element canvas style={{display:"inline-block"}} id="299061" is="div"><Text text="ADDRESS" /></Element>} </h2>
          <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="565363" is="div"><Text text="Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter" /></Element>} </p>
        </div>
        <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
          <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs"> {<Element canvas style={{display:"inline-block"}} id="571045" is="div"><Text text="EMAIL" /></Element>} </h2>
          <a class="text-indigo-500 leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="260535" is="div"><Text text="example@email.com" /></Element>} </a>
          <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4"> {<Element canvas style={{display:"inline-block"}} id="327249" is="div"><Text text="PHONE" /></Element>} </h2>
          <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="755901" is="div"><Text text="123-456-7890" /></Element>} </p>
        </div>
      </div>
    </div>
    <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
      <form>
        <h2 class="text-gray-900 text-lg mb-1 font-medium title-font"> {<Element canvas style={{display:"inline-block"}} id="944092" is="div"><Text text="Feedback" /></Element>} </h2>
        <p class="leading-relaxed mb-5 text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="470834" is="div"><Text text="Post-ironic portland shabby chic echo park, banjo fashion axe" /></Element>} </p>
        <div class="relative mb-4">
          <label for="name" class="leading-7 text-sm text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="942340" is="div"><Text text="Name" /></Element>} </label>
          <input type="text" id="name" name="name" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
        </div>
        <div class="relative mb-4">
          <label for="email" class="leading-7 text-sm text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="471971" is="div"><Text text="Email" /></Element>} </label>
          <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
        </div>
        <div class="relative mb-4">
          <label for="message" class="leading-7 text-sm text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="903845" is="div"><Text text="Message" /></Element>} </label>
          <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" required></textarea>
        </div>
        <Button type="submit" class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"> {<Element canvas style={{display:"inline-block"}} id="911399" is="div"><Text text="Button" /></Element>} </Button>
        <p class="text-xs text-gray-500 mt-3"> {<Element canvas style={{display:"inline-block"}} id="892593" is="div"><Text text="Chicharrones blog helvetica normcore iceland tousled brook viral artisan." /></Element>} </p>
      </form>
    </div>
  </div>
    </section>

      </div>;
};
Contact8.craft = {
  displayName: "Contact 8"
};