import { gql } from "apollo-boost";

const viewQuery = gql`
  query(
    $_id: ID
  ) {
    view(
      _id: $_id
     
    ) {
      _id
      org,
      name
      user
      users
      html
      css
      editors
    }
  }
`;

export default viewQuery;
