import { WidgetSettings } from "./WidgetSettings"
import { useEditor, useNode } from "@craftjs/core";
import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'; 
import Component from "./Component";
import Draggable from 'react-draggable';
import ACTION_TRIGGER_VIEW from "../../../../../../actions/TriggerView/ACTION_TRIGGER_VIEW";
import {useDispatch} from "react-redux";

export const TreeGrid = (props) => {
    const {
                 actions: {setProp},
 connectors: { connect },
    } = useNode((node) => ({
      selected: node.events.selected,
    }));
  
    const {
      setActiveView,
dataBaseDataArr,
      eventsDataArr, x,
      y,
      
      applyFilter
      } = props

      const [resultsArr, setResultsArr] = useState(null)
      useEffect(() => {
        if(dataBaseDataArr) {
          console.log(dataBaseDataArr)
          let newArr = dataBaseDataArr?.results
          let db = dataBaseDataArr.db
          setResultsArr(newArr)
        }
      },[dataBaseDataArr])
    const { text, textComponent, color, ...otherProps } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [activeSelect, setActiveSelect] = useState("Organizations")

  const {
    enabled,
    connectors, 
    actions,
    json,
    nodes
  } = useEditor((state, query) => ({
    enabled: state.options.enabled || false,
    json: query.serialize() ,
    nodes: state.nodes
  }));

  const changeView = (id) => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = {_id: id}
    })
  }
  const changeSelect = (ob) => {
    setActiveSelect(ob.name)
    changeView(ob._id)
  }
  
  const resetView = () => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = null
    })
    setActiveSelect("Organizations")
  }

  const { query } = useEditor((state, query) => ({
    hoveredNodeId: state.events.hovered
  }));

  
const addNode = (el) => {
//     console.log(el)
//     var tempDiv = document.createElement('div')
//     tempDiv.innerHTML = el
let convertString = (el) => {
if(el.includes("grid_widget")) {
  return <GridWidget />
} else if(el.includes("text_widget")) {
  return <Text />
} else if(el.includes("card_widget")) {
  return <Card />
} else {
   const reactElement = parse(el);
   return reactElement;
}
}

  const nodeTree = query.parseReactElement(convertString(el)).toNodeTree()
  actions.addNodeTree(nodeTree, "ROOT")

}
const dispatch = useDispatch()
const applyTrigger = (xs) => {
  console.log(xs)
  xs.map(x => {
    let type = x.event.eventType
  if(type == "Alert") {
    alert(x.event.eventContent)
  } else if(type == "Navigate") {
    dispatch(ACTION_TRIGGER_VIEW(x.event.key))
} else if (type == "Filter") {
    applyFilter && applyFilter(allData, x.node, x.event.key, inputRef?.current?.value)
    console.log("filters", inputRef?.current?.value, x)
  } else if(type == "Code") {
    console.log(x)
    let fun = new Function("inputValue", "applyFilter", "addNode", x.event.eventContent)
    fun(inputRef?.current?.value, applyFilter, addNode)
  } else if(type == "Inject") {
    addNode(x.event.eventContent)
  }
  })
}

const [allEvents, setAllEvents] = useState({})
function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property.replaceAll(' ','')];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

useEffect(() => {
  if(eventsDataArr && applyFilter) {
    console.log(eventsDataArr)
    let newEventsData = groupBy(eventsDataArr, "eventName")

    let allv = Object.assign({}, ...Object.keys(newEventsData).map((key) => {
      console.log(newEventsData[key])
      let arr = [...newEventsData[key]]
      newEventsData[key] = () => applyTrigger(arr);
      return {[key]: newEventsData[key]}
    }))
    // console.log(allv)
    // let ob =  ...(allv.map(item => ({ [Object.keys(item)[0]] : () => applyTrigger(item) }) )))
    
    console.log(allv,111111111111111)
    setAllEvents(allv)

  }
},[eventsDataArr, applyFilter])
const [startingPos, setStartingPos] = useState({ x: x, y: y })
const handleStop = (e, data) => {
    console.log(data, 123)
    setStartingPos( { x: data.x, y: data.y })
    setProp(prop => {
      prop.x = data.x;
      prop.y = data.y;
    }, 500)}
    return (        
     <Draggable defaultClassName="dgs" onStop={handleStop} position={startingPos}>
 <div  ref={connect}>  
      <Component
      allEvents={allEvents}
      allowReordering={props.allowReordering}
      allowFiltering={props.allowFiltering}
      allowSorting={props.allowSorting}
      />
    </div>
</Draggable> 

    );
  };
  
   TreeGrid.craft = {
    displayName: 'TreeGrid',
    props: {
      allowReordering: false,
allowFiltering: false,x:0,
      y:0, 
      
allowSorting: false,
    },
    related: {
      toolbar: WidgetSettings,
    },
  };
  