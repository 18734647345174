import { gql } from "apollo-boost";

const organizationQuery = gql`
  query(
      $_id: ID
  ) {
    organization(
      _id: $_id
     
    ) {
    _id
    domain
    name
    owner
    industry
    language
    currency
    timeZone
    }
  }
`;

export default organizationQuery;
