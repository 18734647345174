import { Element, useEditor } from '@craftjs/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCog, faPaperPlane} from "@fortawesome/free-solid-svg-icons"
import logo from "./Logo.png"
import Dropdown from "react-dropdown";

import ButtonWithPopup from './ButtonComponent';
import { Input } from 'reactstrap';
import { Toolbar } from '../Toolbar';
import { useDispatch } from 'react-redux';
import ACTION_VIEW_SETTINGS_RESET from '../../../../../../actions/viewSettings/ACTION_VIEW_SETTINGS_RESET';
const ToolboxDiv = styled.div`
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  ${(props) => (!props.enabled ? `width: 0;` : '')}
  ${(props) => (!props.enabled ? `opacity: 0;` : '')}
`;

export const Toolbox = ({
setAddWidgetActive,
setAddPagesActive,
addWidgetActive,
addPagesActive,
settings
}) => {
  const {
    enabled,
    connectors: { create },
  } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const dispatch = useDispatch()
 
if(settings && enabled) {
    return (
      <ToolboxDiv
        enabled={true}
        className="toolbox transition w-12 h-full flex flex-col"
        style={{width:'300px', paddingTop:"10px", backgroundColor:"white"}}
      >

      {/* <div style={{width:"100%", height:"1.2rem", display:"flex", cursor:"pointer", justifyContent:"flex-end", background:"#959dad14", borderRadius:"3px"}} onClick={() => dispatch(ACTION_VIEW_SETTINGS_RESET())}>
          {
          <svg style={{transform: "rotate(45deg)",}} xmlns="http://www.w3.org/2000/svg" width="20%" height="100%" viewBox="0 0 24 24" class="eva eva-plus-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="plus"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect><path d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"></path></g></g></svg>
          }
      </div> */}

  
  
  
  <Toolbar />


    </ToolboxDiv>
    )
}
if(enabled && !settings) {
  return (
    <ToolboxDiv
    enabled={true}
    className="toolbox transition w-12 h-full flex flex-col spaceBetween"
    style={{width:'80px', paddingTop:"2.5rem", backgroundColor:"white"}}
    >
  <div className="flex flex-1 flex-col items-center pt-3">
  <div className="flex flex-col w-20 items-start" id="NewRootRoot">
  <div className="shadow-[2px_0px_10px_0px_rgba(164,_164,_164,_0.1)] bg-white flex flex-col justify-between w-20 h-[1000px] shrink-0 items-center pt-[437px] pb-2">
    <div className="flex flex-col gap-8 w-8 items-center">
      <div onClick={() => {
          setAddPagesActive(false)
          setAddWidgetActive(!addWidgetActive)}} style={{cursor:"pointer"}} className="flex flex-col gap-1 w-8 items-center">
        <img
          src="https://file.rendit.io/n/1egkBUzI1nM7khwESNuu.svg"
          className="w-6"
        />
        <div className="text-xs font-['Inter'] leading-[16.8px] text-[#989191] self-start">
          Editor
        </div>
      </div>
      <div onClick={() => {
          setAddWidgetActive(false)
          setAddPagesActive(!addPagesActive)}} style={{cursor:"pointer"}} className="self-start flex flex-col gap-1 w-8 items-center">
        <img
          src="https://file.rendit.io/n/BONSTiysrvL3wD6f8GKt.svg"
          className="w-6"
        />
        <div className="text-xs font-['Inter'] leading-[16.8px] text-[#989191] self-start">
          Pages
        </div>
      </div>
    </div>
    <div className="border-solid border-[#e9e9ea] bg-white flex flex-row gap-3 w-16 items-center pl-1 pr-px border rounded-[30px]">
      <img
        src="https://file.rendit.io/n/IB7VkJkTvegplAGYxe72.svg"
        className="w-5 shrink-0"
        id="Phmoonlight"
      />
      <div
        className="bg-[url(https://file.rendit.io/n/TYX0b7ZnALzqbuNbr7w1.svg)] bg-cover bg-50%_50% bg-blend-normal bg-no-repeat flex flex-col w-6 shrink-0 items-center my-px p-1"
        id="Ellipse"
      >
        <img
          src="https://file.rendit.io/n/rX4bdmnSurZ1gzS2Ztcs.svg"
          className="w-5"
          id="Phsunlight"
        />
      </div>
    </div>
  </div>
</div>

  </div>
  </ToolboxDiv>
  )
} else {
    return <div></div>
  }
};
