import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Testimonials12 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <script src="https://unpkg.com/swiper/swiper-bundle.min.js"></script>

    <link href="https://unpkg.com/swiper/swiper-bundle.min.css" rel="stylesheet" />

    <section>
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="swiper-container !overflow-hidden">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <blockquote class="bg-gray-100 p-8">
            <div class="flex items-center">
              <Element canvas style={{ width: "100%" }} id={"div507806"} is="div">
          <ImageC alt="Dog" src="https://images.unsplash.com/photo-1508280756091-9bdd7ef1f463?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1832&q=80" class="h-16 w-16 rounded-full object-cover"  />
        </Element>

              <div class="ml-4 text-sm">
                <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="299412" is="div"><Text text="Bark Dagleish" /></Element>} </p>
                <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="609164" is="div"><Text text="Head of Barketing" /></Element>} </p>
              </div>
            </div>

            <p class="relative mt-4 text-gray-500">
              <span class="text-xl"> {<Element canvas style={{display:"inline-block"}} id="482029" is="div"><Text text="“" /></Element>} </span> {<Element canvas style={{display:"inline-block"}} id="238364" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
              assumenda officiis sit amet itaque eveniet accusantium corporis
              tempora, soluta perspiciatis rerum, ratione animi nemo inventore
              repellat, commodi in esse quisquam." /></Element>} <span class="text-xl"> {<Element canvas style={{display:"inline-block"}} id="808286" is="div"><Text text="”" /></Element>} </span>
            </p>
          </blockquote>
        </div>

        <div class="swiper-slide">
          <blockquote class="bg-gray-100 p-8">
            <div class="flex items-center">
              <Element canvas style={{ width: "100%" }} id={"div649210"} is="div">
          <ImageC alt="Dog" src="https://images.unsplash.com/photo-1508280756091-9bdd7ef1f463?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1832&q=80" class="h-16 w-16 rounded-full object-cover"  />
        </Element>

              <div class="ml-4 text-sm">
                <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="613832" is="div"><Text text="Bark Dagleish" /></Element>} </p>
                <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="163102" is="div"><Text text="Head of Barketing" /></Element>} </p>
              </div>
            </div>

            <p class="relative mt-4 text-gray-500">
              <span class="text-xl"> {<Element canvas style={{display:"inline-block"}} id="897664" is="div"><Text text="“" /></Element>} </span> {<Element canvas style={{display:"inline-block"}} id="347803" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
              assumenda officiis sit amet itaque eveniet accusantium corporis
              tempora, soluta perspiciatis rerum, ratione animi nemo inventore
              repellat, commodi in esse quisquam." /></Element>} <span class="text-xl"> {<Element canvas style={{display:"inline-block"}} id="455260" is="div"><Text text="”" /></Element>} </span>
            </p>
          </blockquote>
        </div>

        <div class="swiper-slide">
          <blockquote class="bg-gray-100 p-8">
            <div class="flex items-center">
              <Element canvas style={{ width: "100%" }} id={"div253740"} is="div">
          <ImageC alt="Dog" src="https://images.unsplash.com/photo-1508280756091-9bdd7ef1f463?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1832&q=80" class="h-16 w-16 rounded-full object-cover"  />
        </Element>

              <div class="ml-4 text-sm">
                <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="921505" is="div"><Text text="Bark Dagleish" /></Element>} </p>
                <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="952947" is="div"><Text text="Head of Barketing" /></Element>} </p>
              </div>
            </div>

            <p class="relative mt-4 text-gray-500">
              <span class="text-xl"> {<Element canvas style={{display:"inline-block"}} id="243757" is="div"><Text text="“" /></Element>} </span> {<Element canvas style={{display:"inline-block"}} id="767755" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
              assumenda officiis sit amet itaque eveniet accusantium corporis
              tempora, soluta perspiciatis rerum, ratione animi nemo inventore
              repellat, commodi in esse quisquam." /></Element>} <span class="text-xl"> {<Element canvas style={{display:"inline-block"}} id="558538" is="div"><Text text="”" /></Element>} </span>
            </p>
          </blockquote>
        </div>

        <div class="swiper-slide">
          <blockquote class="bg-gray-100 p-8">
            <div class="flex items-center">
              <Element canvas style={{ width: "100%" }} id={"div676565"} is="div">
          <ImageC alt="Dog" src="https://images.unsplash.com/photo-1508280756091-9bdd7ef1f463?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1832&q=80" class="h-16 w-16 rounded-full object-cover"  />
        </Element>

              <div class="ml-4 text-sm">
                <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="128372" is="div"><Text text="Bark Dagleish" /></Element>} </p>
                <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="387035" is="div"><Text text="Head of Barketing" /></Element>} </p>
              </div>
            </div>

            <p class="relative mt-4 text-gray-500">
              <span class="text-xl"> {<Element canvas style={{display:"inline-block"}} id="954323" is="div"><Text text="“" /></Element>} </span> {<Element canvas style={{display:"inline-block"}} id="298688" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
              assumenda officiis sit amet itaque eveniet accusantium corporis
              tempora, soluta perspiciatis rerum, ratione animi nemo inventore
              repellat, commodi in esse quisquam." /></Element>} <span class="text-xl"> {<Element canvas style={{display:"inline-block"}} id="363220" is="div"><Text text="”" /></Element>} </span>
            </p>
          </blockquote>
        </div>
      </div>

      <div class="swiper-pagination !relative !bottom-0 mt-12"></div>
    </div>
  </div>
    </section>

    <script> {<Element canvas style={{display:"inline-block"}} id="295659" is="div"><Text text="document.addEventListener('DOMContentLoaded', function ()" /></Element>} {new Swiper('.swiper-container', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 8,
        autoplay: {
          delay: 8000
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          640: {
            slidesPerView: 1.5
          },
          1024: {
            slidesPerView: 3
          }
        }
      })} {<Element canvas style={{display:"inline-block"}} id="265068" is="div"><Text text=")" /></Element>} </script>

      </div>;
};
Testimonials12.craft = {
  displayName: "Testimonials 12"
};