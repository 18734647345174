import { useNode, useEditor } from '@craftjs/core';
import { ROOT_NODE } from '@craftjs/utils';
import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {ArrowUp} from '../../icons/arrow-up.js';
import {Delete} from '../../icons/delete.js';
import {Edit} from '../../icons/edit.js';
import {Move} from '../../icons/move.js';
import ACTION_VIEW_SETTINGS from "../../../../actions/viewSettings/ACTION_VIEW_SETTINGS"
import ACTION_FORMBUILDER from "../../../../actions/formBuilder/ACTION_FORMBUILDER"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

const IndicatorDiv = styled.div`
  height: 30px;
  margin-top: -29px;
  font-size: 12px;
  line-height: 12px;

  svg {
    fill: #808080;
    width: 15px;
    height: 15px;
  }
`;

const Btn = styled.a`
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  > div {
    position: relative;
    top: -50%;
    left: -50%;
  }
`;

export const RenderNode = ({ render }) => {
  const { id } = useNode();
  const { actions, query, isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
  }));

  const {
    isHover,
    dom,
    name,
    node,
    originalName,
    moveable,
    deletable,
    connectors: { drag },
    parent,
    props 
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    node: node,
    originalName: node.data.displayName,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
  }));

  const currentRef = useRef();
  const dispatch = useDispatch()

  useEffect(() => {
    if (dom) {
      if (isActive || isHover) dom.classList.add('component-selected');
      else dom.classList.remove('component-selected');
    }
  }, [dom, isActive, isHover]);

  const getPos = useCallback((dom) => {
    const { top, left, bottom } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0 };
    return {
      top: `${top > 0 ? top : bottom}px`,
      left: `${left}px`,
    };
  }, []);

  // const scroll = useCallback(() => {
  //   const { current: currentDOM } = currentRef;

  //   if (!currentDOM) return;
  //   const { top, left } = getPos(dom);
  //   currentDOM.style.top = top;
  //   currentDOM.style.left = left;
  // }, [dom, getPos]);

  // useEffect(() => {
  //   document
  //     .querySelector('.craftjs-renderer')?.addEventListener('scroll', scroll)

  //   return () => {
  //     document
  //       .querySelector('.craftjs-renderer')?.removeEventListener('scroll', scroll)
  //   };
  // }, [scroll]);

  return (
    <>
      {isActive
        ? ReactDOM.createPortal(
            <IndicatorDiv
              ref={currentRef}
              className="px-2 py-2 text-white bg-primary fixed flex items-center itemBox"
              style={{
                left: getPos(dom).left,
                top: getPos(dom).top,
                zIndex: 9999,
              }}
            >
              <div className="flex-1 mr-4 itemBoxName" style={{padding: "5px 30px"}}>{name}</div>
              {moveable ? (
                <Btn className="mr-2 cursor-move itemBoxName" ref={drag}>
                  <Move />
                </Btn>
              ) : null}
              {id !== ROOT_NODE && (
                <Btn
                  className="mr-2 cursor-pointer itemBoxName"
                  onClick={() => {
                    actions.selectNode(parent);
                  }}
                >
                  <ArrowUp />
                </Btn>
              )}
              {deletable ? (
                <Btn
                  className="mr-2 cursor-pointer itemBoxName"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    actions.delete(id);
                  }}
                >
                  <Delete />
                </Btn>
              ) : null}
              <Btn
                  className="cursor-pointer itemBoxName"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    // actions.delete(id);
                    console.log(originalName)
                    if(originalName == "formBuilder") {
                        dispatch(ACTION_FORMBUILDER(id)) 
                      } else {
                       dispatch(ACTION_VIEW_SETTINGS({id, name: originalName}))
                      }
                  }}
                >
                  <Edit />
                </Btn>
                <Btn
                style={{marginLeft:"10px", color:"#ff4d2e"}}
                  className="mr-2 cursor-pointer itemBoxName"
                >
                 <FontAwesomeIcon
                className="activeSideBarX"
                icon={faShare}
                style={{cursor:"pointer"}}        
                onClick={() =>  {
                  dispatch(ACTION_FORMBUILDER(JSON.stringify({[id]: query.node(id).toSerializedNode()})))
                }}
              />
                </Btn>
            </IndicatorDiv>,
            document.querySelector('.page-container')
          )
        : null}
      {render}
    </>
  );
};
