const FORMBUILDER = "FORMBUILDER";

const ACTION_FORMBUILDER = (payload) => {
  return {
    type: FORMBUILDER,
    payload
  };
};

export default ACTION_FORMBUILDER;
