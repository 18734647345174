import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { useHistory, useLocation } from "react-router-dom";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import ACTION_LOGIN_EMAIL_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";

import arrowDownUsers from "../../../assests/images/arrowDownUsers.svg"
import searchInput from "../../../assests/images/searchInput.svg"
import setting from "../../../assests/images/setting.svg"
import arrowRight from "../../../assests/images/arrowRight.svg"
import edit from "../../../assests/images/edit.svg"
import trash from "../../../assests/images/trash.svg"
import add from "../../../assests/images/add.svg"
import {useMutation} from "@apollo/client"
import addTemplateMutation from "../../../graphql/mutations/template/addTemplateMutation"
import {AccountTable as TemplateTable} from "../../resuable/accountTable"
import AddTemplateModal from "../../resuable/modals/addTemplateModal";
import moment from "moment"
import { DataSpinner } from "../../resuable/spinner/dataSpinner";
const SuperTemplates = ({templates, templatesData, setTemplatesData, templatesRefetch}) => {
    let location = useLocation()

    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const dispatch = useDispatch()
    const history = useHistory()


    const templateColumn = [
        
        {
            name: 'name',
            selector: row => row.name,
        },
    ];
    

    // add user modal


    useEffect(() => {
        if(templates) {
            setTemplatesData(templates.allTemplates.map(x => {
                return {
                    name : x.name,
                    _id: x._id,
                    html: x.html,
                    createdAt: x.createdAt ? moment.unix(x.createdAt/1000).format("MM/DD/YYYY") : null
                }
            }))
        }
    },[templates])

    
// get started information
const [templateAddModal, setTemplateAddModal] = useState(null)

// add user info

const [templateName, setTemplateName] = useState("")



const [addTemplate, {error: addTemplateError, data: addTemplateData, loading: addTemplateLoading}] = useMutation(addTemplateMutation)



const addTemplateFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setTemplateAddModal(null)
    if(templateName) {
    addTemplate({
        variables:{
            name: templateName
        }
    }).then((res) => {
        console.log(res)
        setTemplateAddModal(null)   
        setTemplateName("") 
        templatesRefetch().then(x => {
            history.push(`/admin/template/${res._id}`)
        })
        dispatch(ACTION_LOADING_SPINNER_RESET())
    })
}
}

useEffect(() => {
    if(templates) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
},[templates])


 return (
        <div className="adminContent column">
            {returnToHomeScreen()}
            <AddTemplateModal 
                addTemplateModal={templateAddModal}
                setAddTemplateModal={setTemplateAddModal}
                addTemplateFunc={addTemplateFunc}
                // updateTemplateFunc={updateTemplateFunc}
                templateName={templateName}
                setTemplateName={setTemplateName}
            />
            <div className="workspaceHeader">
                <div className="workspaceName" style={{display:"flex", alignItems:"center"}}>
                        Templates
                        <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => setTemplateAddModal("Template")}
                        />
                </div>
                <div className="workspaceButtons">
                    <div className={`routeContainer sideSearch userSearch`} style={{width:"269px" , margin:"0px", fontWeight:"normal", justifyContent:"flex-start"}}>
                    <img
                                className="routeIcon"
                                src={searchInput}
                    />
                    <div className="searchT" style={{margin:"0 16px", color:"#5E6A7A"}}>
                        Search                        
                    </div>
                    </div>
                    <div className="adminButton" style={{width:"fit-content"}}> 
                    <span style={{margin:"0 16px", color:"#5E6A7A"}} className="buttonIconImage">Newest</span>
                    <img className="buttonIconImage" style={{margin:"0 12px"}} src={arrowDownUsers} />
                    </div>
                    <div className="adminButton" >
                    <img className="buttonIconImage" src={setting} />
                    </div>

                </div>
            </div>
            <div className="workspaceContainer">
                <TemplateTable
                    accountColumns={templateColumn}
                    accountData={templatesData}
                    subScreen={(row) => history.push({
                        pathname:`/admin/template`,
                        state:{
                            id: row._id,
                            html: row.html
                        }
                    })}
                />
            </div>
        </div>
    )
}

export default SuperTemplates