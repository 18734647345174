import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';

class Component extends SampleBase {
     // Uploader component
    checkboxObj;
    checkboxObj1;
    uploadObj;
    asyncSettings;
    dropContainerRef;
    dropContainerEle;
    constructor(props) {
        super(props);
        this.dropContainerEle = null;
        this.dropContainerRef = element => {
            this.dropContainerEle = element;
        }; 
        this.asyncSettings = { 
            saveUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Save',
            removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
        };
    }
    rendereComplete() {
        this.uploadObj.dropArea = this.dropContainerEle;
        this.uploadObj.element.setAttribute('name', 'UploadFiles');
        this.uploadObj.dataBind();
    }
    onChange(args) {
        this.uploadObj.autoUpload = args.checked;
        this.uploadObj.clearAll();
    }
    onChanged(args) {
        this.uploadObj.sequentialUpload = args.checked;
        this.uploadObj.clearAll();
    }
    onRemoveFile(args) {
        args.postRawFile = false;
    }
    render() {
        return (<div className='control-pane' ref={this.dropContainerRef}>
        <div className='control-section row uploadpreview'>
         <div className='col-lg-9'>
          <div className='upload_wrapper'>
            {/* Render Uploader */}
            <UploaderComponent {...this.props.allEvents} id='fileUpload' type='file' ref={(scope) => { this.uploadObj = scope; }} asyncSettings={this.asyncSettings} removing={this.onRemoveFile.bind(this)}></UploaderComponent>
        </div>
        </div>
        </div>
      </div>
      );
    } 
}

export default Component