import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Testimonials1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <div class="lg:-mx-6 lg:flex lg:items-center">
            <Element canvas style={{ width: "100%" }} id={"div716578"} is="div">
          <ImageC class="object-cover object-center lg:w-1/2 lg:mx-6 w-full h-96 rounded-lg lg:h-[36rem]" src="https://images.unsplash.com/photo-1499470932971-a90681ce8530?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

            <div class="mt-8 lg:w-1/2 lg:px-6 lg:mt-0">
                <p class="text-5xl font-semibold text-blue-500 "> {<Element canvas style={{display:"inline-block"}} id="158346" is="div"><Text text="“" /></Element>} </p>

                <h1 class="text-2xl font-semibold text-gray-800 dark:text-white xl:text-4xl lg:w-96"> {<Element canvas style={{display:"inline-block"}} id="675736" is="div"><Text text="Help us improve our productivity" /></Element>} </h1>

                <p class="max-w-lg mt-6 text-gray-500 dark:text-gray-400 "> {<Element canvas style={{display:"inline-block"}} id="204032" is="div"><Text text="“ Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad
                    tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda rerum, culpa
                    aperiam dolorum, obcaecati corrupti aspernatur a. ”" /></Element>} </p>

                <h3 class="mt-6 text-lg font-medium text-blue-500"> {<Element canvas style={{display:"inline-block"}} id="863197" is="div"><Text text="Mia Brown" /></Element>} </h3>
                <p class="text-gray-600 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="775512" is="div"><Text text="Marketing Manager at Stech" /></Element>} </p>

                <div class="flex items-center justify-between mt-12 lg:justify-start">
                    <Element canvas style={{ width: "100%" }} id={"div677069"} is="div">
            <Button classn={"p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 hover:bg-gray-100"}>
              
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                    
            </Button>
          </Element>

                    <Element canvas style={{ width: "100%" }} id={"div527884"} is="div">
            <Button classn={"p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 lg:mx-6 hover:bg-gray-100"}>
              
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                    
            </Button>
          </Element>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Testimonials1.craft = {
  displayName: "Testimonials 1"
};