import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Faq9 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="divide-y divide-gray-200 rounded-xl border border-gray-200 bg-white">
  <details class="group p-6" open>
    <summary class="flex cursor-pointer items-center justify-between">
      <h5 class="text-lg font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="707320" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing?" /></Element>} </h5>

      <span class="relative ml-1.5 h-5 w-5 flex-shrink-0">
        <svg xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </span>
    </summary>

    <p class="mt-4 leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="307183" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic veritatis
      molestias culpa in, recusandae laboriosam neque aliquid libero nesciunt
      voluptate dicta quo officiis explicabo consequuntur distinctio corporis
      earum similique!" /></Element>} </p>
  </details>

  <details class="group p-6">
    <summary class="flex cursor-pointer items-center justify-between">
      <h5 class="text-lg font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="818572" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing?" /></Element>} </h5>

      <span class="relative ml-1.5 h-5 w-5 flex-shrink-0">
        <svg xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </span>
    </summary>

    <p class="mt-4 leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="721390" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic veritatis
      molestias culpa in, recusandae laboriosam neque aliquid libero nesciunt
      voluptate dicta quo officiis explicabo consequuntur distinctio corporis
      earum similique!" /></Element>} </p>
  </details>
    </div>

      </div>;
};
Faq9.craft = {
  displayName: "Faq 9"
};