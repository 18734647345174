const VIEW_SETTINGS = "VIEW_SETTINGS";
const VIEW_SETTINGS_RESET = "VIEW_SETTINGS_RESET";

const viewSettingsReducer = (
  state = { viewSettings: null, viewName: null },
  action
) => {
  switch (action.type) {
    case VIEW_SETTINGS:
      return { ...state, viewSettings: action.payload.id || action.payload, viewName: action.payload.name  };
    case VIEW_SETTINGS_RESET:
      return { ...state, viewSettings: null, viewName: null };
    default:
      return { ...state };
  }
};

export default viewSettingsReducer;
