import { gql } from "apollo-boost";

const updateDepartmentMutation = gql`
  mutation(
    $_id: ID, 
    $name: String,
    $org: ID,
  ) {
    updateDepartment(
    _id: $_id,
    name:$name,
    org:$org,
    ) {
    _id
    name,
    org
    }
  }
`;

export default updateDepartmentMutation;
