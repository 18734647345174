import { gql } from "apollo-boost";

const allEventsQuery = gql`
  
    query(
    $view: ID
  ) { 
    allEvents (
    view: $view
    ) { 
      _id
    view
    widgetId
    eventName
    nodeId
    eventType 
    eventContent
    key
    }
  }
  
`;

export default allEventsQuery;
