import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <header class="bg-white dark:bg-gray-900">
    <nav x-data="{ isOpen: false }" class="relative bg-white dark:bg-gray-900">
        <div class="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
            <div class="flex items-center justify-between">
                <div>
                    <Element canvas style={{ width: "100%" }} id={"div207794"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 transition-colors duration-300 transform dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="961041" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
                </div>
    
                
                <div class="flex md:hidden">
                    <Element canvas style={{ width: "100%" }} id={"div575206"} is="div">
            <Button classn={"text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"}>
              
                        <svg x-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                        </svg>

                        <svg x-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    
            </Button>
          </Element>
                </div>
            </div>
    
           
            <div class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white dark:bg-gray-900 md:bg-transparent md:dark:bg-transparent md:mt-0 md:p-0 md:top-0 md:relative md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center">
                <div class="flex flex-col md:flex-row md:mx-6">
                    <Element canvas style={{ width: "100%" }} id={"div827934"} is="div">
            <Button href={"#"} classn={"my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="403578" is="div"><Text text="Home" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div826604"} is="div">
            <Button href={"#"} classn={"my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="538011" is="div"><Text text="Shop" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div638606"} is="div">
            <Button href={"#"} classn={"my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="736305" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div406530"} is="div">
            <Button href={"#"} classn={"my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="924941" is="div"><Text text="About" /></Element>} 
            </Button>
          </Element>
                </div>
    
                <div class="flex justify-center md:block">
                    <Element canvas style={{ width: "100%" }} id={"div116897"} is="div">
            <Button href={"#"} classn={"relative text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-300"}>
              
                        <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3H5L5.4 5M7 13H17L21 5H5.4M7 13L5.4 5M7 13L4.70711 15.2929C4.07714 15.9229 4.52331 17 5.41421 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM9 19C9 20.1046 8.10457 21 7 21C5.89543 21 5 20.1046 5 19C5 17.8954 5.89543 17 7 17C8.10457 17 9 17.8954 9 19Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
    
                        <span class="absolute top-0 left-0 p-1 text-xs text-white bg-blue-500 rounded-full"></span>
                    
            </Button>
          </Element>
                </div>
            </div>
        </div>
    </nav>

    <div class="container px-6 py-16 mx-auto">
        <div class="items-center lg:flex">
            <div class="w-full lg:w-1/2">
                <div class="lg:max-w-lg">
                    <h1 class="text-2xl font-semibold text-gray-800 uppercase dark:text-white lg:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="436047" is="div"><Text text="Best Place To Choose Your Clothes" /></Element>} </h1>
                    
                    <p class="mt-2 text-gray-600 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="961567" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro beatae error laborum ab amet sunt recusandae? Reiciendis natus perspiciatis optio." /></Element>} </p>
                    
                    <Element canvas style={{ width: "100%" }} id={"div609430"} is="div">
            <Button classn={"w-full tracking-wider px-6 py-2.5 mt-6 text-sm text-white uppercase transition-colors duration-300 transform bg-blue-600 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="820172" is="div"><Text text="Shop Now" /></Element>} 
            </Button>
          </Element>
                </div>
            </div>

            <div class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
            <Element canvas style={{ width: "100%" }} id={"div514572"} is="div">
          <ImageC class="w-full h-full max-w-md" src="https://images.unsplash.com/photo-1508394522741-82ac9c15ba69?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=748&q=80"  />
        </Element>
            </div>
        </div>
    </div>
    </header>

      </div>;
};
Hero1.craft = {
  displayName: "Hero 1"
};