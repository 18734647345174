import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Testimonials16 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap -m-4">
      <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
        <div class="h-full text-center">
          <Element canvas style={{ width: "100%" }} id={"div523108"} is="div">
          <ImageC alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src="https://dummyimage.com/302x302"  />
        </Element>
          <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="680673" is="div"><Text text="Edison bulb retro cloud bread echo park, helvetica stumptown taiyaki taxidermy 90's cronut +1 kinfolk. Single-origin coffee ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware." /></Element>} </p>
          <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
          <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm"> {<Element canvas style={{display:"inline-block"}} id="578357" is="div"><Text text="HOLDEN CAULFIELD" /></Element>} </h2>
          <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="649437" is="div"><Text text="Senior Product Designer" /></Element>} </p>
        </div>
      </div>
      <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
        <div class="h-full text-center">
          <Element canvas style={{ width: "100%" }} id={"div899099"} is="div">
          <ImageC alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src="https://dummyimage.com/300x300"  />
        </Element>
          <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="994043" is="div"><Text text="Edison bulb retro cloud bread echo park, helvetica stumptown taiyaki taxidermy 90's cronut +1 kinfolk. Single-origin coffee ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware." /></Element>} </p>
          <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
          <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm"> {<Element canvas style={{display:"inline-block"}} id="267545" is="div"><Text text="ALPER KAMU" /></Element>} </h2>
          <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="705547" is="div"><Text text="UI Develeoper" /></Element>} </p>
        </div>
      </div>
      <div class="lg:w-1/3 lg:mb-0 p-4">
        <div class="h-full text-center">
          <Element canvas style={{ width: "100%" }} id={"div955866"} is="div">
          <ImageC alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src="https://dummyimage.com/305x305"  />
        </Element>
          <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="199627" is="div"><Text text="Edison bulb retro cloud bread echo park, helvetica stumptown taiyaki taxidermy 90's cronut +1 kinfolk. Single-origin coffee ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware." /></Element>} </p>
          <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
          <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm"> {<Element canvas style={{display:"inline-block"}} id="669300" is="div"><Text text="HENRY LETHAM" /></Element>} </h2>
          <p class="text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="276359" is="div"><Text text="CTO" /></Element>} </p>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Testimonials16.craft = {
  displayName: "Testimonials 16"
};