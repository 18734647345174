import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import Dropdown from 'react-dropdown';
import arrowDownDark from "../../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../../assests/images/arrowUp.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Multiselect from 'multiselect-react-dropdown';
import moment from 'moment';
import PreviewModal from '../PreviewModal';


const ImportModal = (props) => {
    const {
        open,
        setOpen,
        shareables,
        imported,
        setImported,
        importFunc,
        user,
        previewFunc,
        previewModal,
        setImportModal,
        setPreviewModal,
        html,
        dataBasesData,
        eventsData,

    } = props

    const [allShared, setAllShared] = useState(null)

    useEffect(() => {
      if(shareables) {
        setAllShared(shareables.allShareable)
      }
    },[shareables])

    const [boxSearch, setboxSearch] = useState("")

useEffect(() => {
  if(boxSearch) {
      /////console.log(boxSearch.length) 
      if(boxSearch.length) {
        setAllShared([...shareables.allShareable].filter((x) => {
          return (
            new RegExp(boxSearch, "gi").test(
              x.name
            ) || new RegExp(boxSearch, "gi").test(
              moment.unix(x.createdAt/1000).format("MM/DD/YYYY")
            ) || new RegExp(boxSearch, "gi").test(
            x.userName
            ) || new RegExp(boxSearch, "gi").test(
              x.userEmail
            )
          )
      }))
      }   
} else {
  setAllShared(shareables.allShareable)
}
},[boxSearch])
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"9999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 100000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <div style={{ width:"100%", height:"100%", borderRadius:"10px", background: previewModal ? "white" : "transparent"}}>
        { open && <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%", width:"35%", border:"2px solid gray"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                   setImported()
                }}
              />             
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>Import to view</h1>
          <div className="form-group-container task-form" style={{padding:"10px 30px" ,height:"70%", overflow:"auto", position:"relative"}}>
       <div style={{position:"sticky", top:-10, background:"white"}}> <TextInput 
                      type="search expories"
                      label="Search"
                      nextLabel={allShared ? allShared.length : 0}
                      value={boxSearch ? boxSearch : ""}
                      setValue={setboxSearch}
                      placeHolder={`You can serach by name, date, user information`}
                    />
        </div> 
          <div>
       

         {allShared?.map((x, i) => (
          <div onClick={() =>{
             previewFunc(x.id, x.type)
             setImported(x)
             }} 
             key={i} style={{height:"100%", width:"90%", margin:"20px auto"}} className={`dbSelector auth-group ${x._id == imported?._id ? "imported" : ""}`}>
          <div className="auth-label" style={{fontWeight:"bold", fontSize:"24px", color:"#080f0f"}}>{x.name} <div className="adminButton noBorder" onClick={(e) => {
            e.stopPropagation()
            
          } } style={{fontWeight:"bold", fontSize:"16px", padding:"0 80px", color:!x.market ? "#6064D2" : "#B20312", margin:0}}>Preview
          </div>
          </div>
          <div className="auth-label"  style={{fontWeight:"bold", fontSize:"16px", color:"#03c04a"}}>{x.rules.join(" - ")}</div>
          <div className="auth-label"  style={{fontWeight:"bold", fontSize:"16px", color:"#3D3B3C"}}>{x.type} - {moment.unix(x.createdAt/1000).format("MM/DD/YYYY")}</div>
          <div className="auth-label"  style={{fontWeight:"bold", fontSize:"16px", color:"#3D3B3C"}}>{user?.user?._id == x.user ? "You" : x.userName} - {x.userEmail}</div>
          <div className="auth-label"  style={{fontWeight:"bold", fontSize:"16px", color:!x.market ? "#B20312" : "#6064D2"}}>{!x.market ? "not listed on market" : "listed on market"}</div>
          {user?.user?._id == x.user || user?.user?.role == "admin" ? <div className="adminButton" onClick={(e) => {
            e.stopPropagation()
          } } style={{fontWeight:"bold", fontSize:"16px", padding:"0 80px", color:!x.market ? "#6064D2" : "#B20312", margin:0}}>{x.market ? "unlist" : "list"}
          </div> : null}
          </div>
         ))}
              
        </div>
       
          </div>
            <div
              className={`${imported ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={imported ? !open ? null : importFunc : null }
            >
              
            Import

            </div>
        </div>}
        {previewModal && (  <div style={{height:"100%", position:"absolute", right:"0px", top:0, width:"66%",borderRadius:"10px"}}>
       
          <div style={{height:"100%", width:"100%", padding:"40px"}}>
            
            <PreviewModal
            open={previewModal}
            setImportModal={setImportModal}
            setOpen={setPreviewModal}
            imported={imported}
            setImported={setImported}
            importFunc={importFunc}
            html={html}
            dataBasesData={dataBasesData}
            eventsData={eventsData}
            />
          </div>
        
        </div>)}
        </div>
      </Modal>
  )}

  export default ImportModal
       