import React, { useEffect } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { DataSpinner } from '../../spinner/dataSpinner';
import {Form, FormBuilder} from "@tsed/react-formio";




const AddFormModal = (props) => {

  const dispatch = useDispatch()
  
    const {
        open,
        setOpen,
        formName,
        setFormName,
        addForm,
        addFormLoading,
        display,
setDisplay,
formComponents,
setFormComponents,
type,
        
    } = props
    
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"9999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? !addFormLoading ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%", width:"100vw"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setFormName("")
                }}
              />  
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? "Edit Form" : `Add Form`}</h1>
          <div className="form-group-container task-form">
          {type == "formBuilder" ? (
                <FormBuilder display={display} onChange={(e) => setFormComponents(e)} components={formComponents || []} />
          ) : <div>
          
          <div className="auth-group">
                    <TextInput 
                      type={`formName`}
                      label={`form Name*`}
                      value={formName}
                      setValue={setFormName}
                      placeHolder={`Enter name of new form`}
                    />
                    </div>  
          </div>}
          </div>
            <div
              className={`${formName ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={formName ? addForm : null }
            >
              
            {open.includes("Edit") ? "Update" :  "Add a form"}
            </div>
        </div> : (<DataSpinner />) : (<></>)}
      </Modal>
  )}

  export default AddFormModal
       