import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Portfolio5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-12 mx-auto">
        <h1 class="text-2xl font-semibold text-gray-800 lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="811934" is="div"><Text text="Portfolio" /></Element>} </h1>

        <div class="mt-8 xl:mt-16 lg:flex lg:-mx-12">
            <div class="lg:mx-12">
                <h1 class="text-xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="443525" is="div"><Text text="Table of Content" /></Element>} </h1>

                <div class="mt-4 space-y-4 lg:mt-8">
                    <Element canvas style={{ width: "100%" }} id={"div372003"} is="div">
            <Button href={"#"} classn={"block text-blue-500 dark:text-blue-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="899019" is="div"><Text text="Web design" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div532075"} is="div">
            <Button href={"#"} classn={"block text-gray-500 dark:text-gray-300 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="865577" is="div"><Text text="App design" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div777846"} is="div">
            <Button href={"#"} classn={"block text-gray-500 dark:text-gray-300 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="551469" is="div"><Text text="Branding" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div995335"} is="div">
            <Button href={"#"} classn={"block text-gray-500 dark:text-gray-300 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="704518" is="div"><Text text="Animation" /></Element>} 
            </Button>
          </Element>
                </div>
            </div>

            <div class="flex-1 mt-8 lg:mx-12 lg:mt-0">
                <div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3 ">
                    <div>
                        <Element canvas style={{ width: "100%" }} id={"div494707"} is="div">
          <ImageC class="object-cover w-full rounded-lg h-96 " src="https://images.unsplash.com/photo-1621111848501-8d3634f82336?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1565&q=80" alt=""  />
        </Element>
                        <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="482438" is="div"><Text text="Best website
                            collections" /></Element>} </h2>
                        <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="271492" is="div"><Text text="Website" /></Element>} </p>
                    </div>

                    <div>
                        <Element canvas style={{ width: "100%" }} id={"div243968"} is="div">
          <ImageC class="object-cover w-full rounded-lg h-96 " src="https://images.unsplash.com/photo-1621609764180-2ca554a9d6f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt=""  />
        </Element>
                        <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="759425" is="div"><Text text="Block of Ui kit
                            collections" /></Element>} </h2>
                        <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="620166" is="div"><Text text="Ui kit" /></Element>} </p>
                    </div>

                    <div>
                        <Element canvas style={{ width: "100%" }} id={"div889424"} is="div">
          <ImageC class="object-cover w-full rounded-lg h-96 " src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>
                        <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="652764" is="div"><Text text="Ton’s of mobile
                            mockup" /></Element>} </h2>
                        <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="255258" is="div"><Text text="Mockups" /></Element>} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Portfolio5.craft = {
  displayName: "Portfolio 5"
};