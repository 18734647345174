import { Editor, Frame, Element, useEditor } from '@craftjs/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';

import { Viewport, RenderNode } from '../../editor/editor';
import { Container, Text } from '../../editor/selectors';
import { Button } from '../../editor/selectors/others/Button';
import { Custom1, OnlyButtons } from '../../editor/selectors/others/Custom1';
import { Custom2, Custom2VideoDrop } from '../../editor/selectors/others/Custom2';
import { Custom3, Custom3BtnDrop } from '../../editor/selectors/others/Custom3';
import { Video } from '../../editor/selectors/others/Video';
import {AccordionN} from "../../editor/selectors/others/Accordion"
import {CardWidget} from "../../editor/selectors/others/Card"
import {GridWidget} from "../../editor/selectors/others/Grid"
import {FormBuilderWidget} from "../../editor/selectors/others/FormBuilder"
import {Scheduler} from "../../editor/selectors/calendars/Scheduler"
import {Calendar} from "../../editor/selectors/calendars/Calendar"
import {DatePicker} from "../../editor/selectors/calendars/DatePicker"
import {DateRangePicker} from "../../editor/selectors/calendars/DateRangePicker"
import {DateTimePicker} from "../../editor/selectors/calendars/DateTimePicker"
import {GantChart} from "../../editor/selectors/calendars/GantChart"
import {AutoComplete} from "../../editor/selectors/dropdowns/AutoComplete"
import {CompoBox} from "../../editor/selectors/dropdowns/CompoBox"
import {DropDownList} from "../../editor/selectors/dropdowns/DropDownList"
import {DropDownTree} from "../../editor/selectors/dropdowns/DropDownTree"
import {DropDownMulti} from "../../editor/selectors/dropdowns/DropDownMulti"
import {Mention} from "../../editor/selectors/dropdowns/Mention"
import {ListBox} from "../../editor/selectors/dropdowns/ListBox"
import {Accordion} from "../../editor/selectors/navigation/Accordion"
import {AppBar} from "../../editor/selectors/navigation/AppBar"
import {Carousel} from "../../editor/selectors/navigation/Carousel"
import {Context} from "../../editor/selectors/navigation/Context"
import {SideBar} from "../../editor/selectors/navigation/SideBar"
import {Wizard} from "../../editor/selectors/navigation/Wizard"
import {FileManager} from "../../editor/selectors/navigation/FileManager"
import {Message} from "../../editor/selectors/notifications/Message"
import {Badge} from "../../editor/selectors/notifications/Badge"
import {Toast} from "../../editor/selectors/notifications/Toast"
import {Input} from "../../editor/selectors/inputs/Input"
import {Mask} from "../../editor/selectors/inputs/Mask"
import {Numeric} from "../../editor/selectors/inputs/Numeric"
import {Signture} from "../../editor/selectors/inputs/Signture"
import {Button as ButtonOne} from "../../editor/selectors/inputs/Button"
import {Slider} from "../../editor/selectors/inputs/Slider"
import {FileUploader} from "../../editor/selectors/inputs/FileUploader"
import {QueryForm} from "../../editor/selectors/forms/QueryForm"
import {DropButton} from "../../editor/selectors/buttons/DropButton"
import {SpeedDial} from "../../editor/selectors/buttons/SpeedDial"
import {FloatingButton} from "../../editor/selectors/buttons/FloatingButton"
import {Dashboard} from "../../editor/selectors/layout/Dashboard"
import {Splitter} from "../../editor/selectors/layout/Splitter"
import {ListView} from "../../editor/selectors/layout/ListView"
import {Dialoag} from "../../editor/selectors/layout/Dialoag"
import {FlippedCard} from "../../editor/selectors/layout/FlippedCard"
import {AvatarCard} from "../../editor/selectors/layout/AvatarCard"
import {PDF} from "../../editor/selectors/editors/PDF"
import {ImageEditor} from "../../editor/selectors/editors/ImageEditor"
import {WordProcessor} from "../../editor/selectors/editors/WordProcessor"
import {TreeGrid} from "../../editor/selectors/grids/TreeGrid"
import {PivotTable} from "../../editor/selectors/grids/PivotTable"
import {DataGrid} from "../../editor/selectors/grids/DataGrid"


import {Code39} from "../../editor/selectors/dataVisualization/barcode/code39"
import {Eancode} from "../../editor/selectors/dataVisualization/barcode/ean"
import {Qrcode} from "../../editor/selectors/dataVisualization/barcode/qrcode"

import {BulletChart} from "../../editor/selectors/dataVisualization/charts/bullet"
import {Map} from "../../editor/selectors/dataVisualization/charts/map"
import {SmithChart} from "../../editor/selectors/dataVisualization/charts/smith"
import {MixedCharts} from "../../editor/selectors/dataVisualization/charts/mixedChart"
import {Treemap} from "../../editor/selectors/dataVisualization/charts/treemap"

import {Kanban} from "../../editor/selectors/dataVisualization/kanban/"
import { InstanceView } from '../../editor/editor/Viewport/InstanceView';

 
const theme = createTheme({
  typography: {
    fontFamily: [
      'acumin-pro',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

function InstancePreview({
html,
dataBasesData,
eventsData,
open
}) {

  return (
    <ThemeProvider theme={theme}>
        {<Editor
        enabled={false}
          resolver={{
            Container,
            Text,
            Custom1,
            Custom2,
            Custom2VideoDrop,
            Custom3,
            Custom3BtnDrop,
            OnlyButtons,
            Button,
            Video,
            CardWidget,
            AccordionN,
            GridWidget,
            FormBuilderWidget,
            Scheduler,
            Calendar,
            DatePicker,
            DateRangePicker,
            DateTimePicker,
            GantChart,
            AutoComplete,
            CompoBox,
            DropDownList,
            DropDownTree,
            DropDownMulti,
            Mention,
            ListBox,
            Accordion,
            AppBar,
            Carousel,
            Context,
            SideBar,
            Wizard,
            FileManager,
            Message,
            Badge,
            Toast,
            Input,
            Mask,
            Numeric,
            Signture,
            Button: ButtonOne,
            Slider,
            FileUploader,
            QueryForm,
            DropButton,
            SpeedDial,
            Dashboard,
            Splitter,
            ListView,
            Dialoag,
            FlippedCard,
            AvatarCard,
            PDF,
            ImageEditor,
            WordProcessor,
            TreeGrid,
            PivotTable,
            DataGrid,
            Code39,
            Eancode,
            Qrcode,
            BulletChart,
            Map,
            SmithChart,
            MixedCharts,
            Treemap,
            Kanban,
            
          }}
          onRender={RenderNode}
        >
          <InstanceView
            html={html}
            dataBasesData={dataBasesData}
            eventsData={eventsData}
            open={open}
          >
            <Frame>
            </Frame>
          </InstanceView> 
        </Editor>
        }
    </ThemeProvider>
  );
}

export default InstancePreview;