import './index.scss';
import * as React from 'react';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
    dateValue = new Date();

    render() {
        return (<div className='control-pane'>
        <div className='control-section'>
            <div className='daterangepicker-control-section'>
                <DateRangePickerComponent
                {...this.props.allEvents}
                ></DateRangePickerComponent>
            </div>
        </div>
    </div>);
    }
}

export default Component