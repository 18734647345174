import Dropdown from "react-dropdown";
import React from 'react';
import { useSelector } from "react-redux";

export const ToolbarDropdown = ({ title, value, onChange, children }) => {

  const options = useSelector((state) => state.options.options);

  console.log(options)

  return (
    <Dropdown                                 
    placeholder="Select Page"

    arrowOpen={<div></div>}
    arrowClosed={<div></div>}
    value={value ? {label:value, value} : null}
    options={options?.map(x => {
      return {label:x.name, value:x.name}
    }) || []}
    onChange={(e) => onChange(e.value)}
  />
  );
};
