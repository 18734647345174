import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Form7 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    {/* <!--
      This component uses @tailwindcss/forms
       yarn add @tailwindcss/forms
      npm install @tailwindcss/forms
       plugins: [require('@tailwindcss/forms')]
     --> */}

    <section class="relative flex flex-wrap lg:h-screen lg:items-center">
  <div class="w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2 lg:px-8 lg:py-24">
    <div class="mx-auto max-w-lg text-center">
      <h1 class="text-2xl font-bold sm:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="775199" is="div"><Text text="Get started today!" /></Element>} </h1>

      <p class="mt-4 text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="924006" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Et libero nulla
        eaque error neque ipsa culpa autem, at itaque nostrum!" /></Element>} </p>
    </div>

    <form action="" class="mx-auto mt-8 mb-0 max-w-md space-y-4">
      <div>
        <label for="email" class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="662607" is="div"><Text text="Email" /></Element>} </label>

        <div class="relative">
          <input type="email" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Enter email" />

          <span class="absolute inset-y-0 right-4 inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
            </svg>
          </span>
        </div>
      </div>

      <div>
        <label for="password" class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="910222" is="div"><Text text="Password" /></Element>} </label>
        <div class="relative">
          <input type="password" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Enter password" />

          <span class="absolute inset-y-0 right-4 inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
          </span>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <p class="text-sm text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="932236" is="div"><Text text="No account?" /></Element>} <Element canvas style={{ width: "100%" }} id={"div610493"} is="div">
            <Button href={"#"} classn={"underline"}>
               {<Element canvas style={{display:"inline-block"}} id="966712" is="div"><Text text="Sign up" /></Element>} 
            </Button>
          </Element>
        </p>

        <Element canvas style={{ width: "100%" }} id={"div236476"} is="div">
            <Button classn={"ml-3 inline-block rounded-lg bg-blue-500 px-5 py-3 text-sm font-medium text-white"}>
               {<Element canvas style={{display:"inline-block"}} id="968044" is="div"><Text text="Sign in" /></Element>} 
            </Button>
          </Element>
      </div>
    </form>
  </div>

  <div class="relative h-64 w-full sm:h-96 lg:h-full lg:w-1/2">
    <Element canvas style={{ width: "100%" }} id={"div236927"} is="div">
          <ImageC alt="Welcome" src="https://images.unsplash.com/photo-1630450202872-e0829c9d6172?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" class="inset-0 h-full w-full object-cover"  />
        </Element>
  </div>
    </section>

      </div>;
};
Form7.craft = {
  displayName: "Form 7"
};