import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Gallery2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <div class="lg:w-2/3 mx-auto">
      <div class="flex flex-wrap w-full bg-gray-100 py-32 px-10 relative mb-4">
        <Element canvas style={{ width: "100%" }} id={"div634857"} is="div">
          <ImageC alt="gallery" class="w-full object-cover h-full object-center block opacity-25 inset-0" src="https://dummyimage.com/820x340"  />
        </Element>
        <div class="text-center relative z-10 w-full">
          <h2 class="text-2xl text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="112964" is="div"><Text text="Shooting Stars" /></Element>} </h2>
          <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="930100" is="div"><Text text="Skateboard +1 mustache fixie paleo lumbersexual." /></Element>} </p>
          <a class="mt-3 text-indigo-500 inline-flex items-center"> {<Element canvas style={{display:"inline-block"}} id="642121" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
      <div class="flex flex-wrap -mx-2">
        <div class="px-2 w-1/2">
          <div class="flex flex-wrap w-full bg-gray-100 sm:py-24 py-16 sm:px-10 px-6 relative">
            <Element canvas style={{ width: "100%" }} id={"div951670"} is="div">
          <ImageC alt="gallery" class="w-full object-cover h-full object-center block opacity-25 inset-0" src="https://dummyimage.com/542x460"  />
        </Element>
            <div class="text-center relative z-10 w-full">
              <h2 class="text-xl text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="129632" is="div"><Text text="Shooting Stars" /></Element>} </h2>
              <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="889353" is="div"><Text text="Skateboard +1 mustache fixie paleo lumbersexual." /></Element>} </p>
              <a class="mt-3 text-indigo-500 inline-flex items-center"> {<Element canvas style={{display:"inline-block"}} id="569282" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="px-2 w-1/2">
          <div class="flex flex-wrap w-full bg-gray-100 sm:py-24 py-16 sm:px-10 px-6 relative">
            <Element canvas style={{ width: "100%" }} id={"div532322"} is="div">
          <ImageC alt="gallery" class="w-full object-cover h-full object-center block opacity-25 inset-0" src="https://dummyimage.com/542x420"  />
        </Element>
            <div class="text-center relative z-10 w-full">
              <h2 class="text-xl text-gray-900 font-medium title-font mb-2"> {<Element canvas style={{display:"inline-block"}} id="678728" is="div"><Text text="Shooting Stars" /></Element>} </h2>
              <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="136959" is="div"><Text text="Skateboard +1 mustache fixie paleo lumbersexual." /></Element>} </p>
              <a class="mt-3 text-indigo-500 inline-flex items-center"> {<Element canvas style={{display:"inline-block"}} id="906895" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Gallery2.craft = {
  displayName: "Gallery 2"
};