import './index.scss';
import * as React from 'react';
import { SampleBase } from './sample-base';

import { GanttComponent, Inject, Selection, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-gantt';
import { projectNewData } from './data';

class Component extends SampleBase {
    taskFields = {
        id: 'TaskID',
        name: 'TaskName',
        startDate: 'StartDate',
        endDate: 'EndDate',
        duration: 'Duration',
        progress: 'Progress',
        dependency: 'Predecessor',
        child: 'subtasks'
    };
    labelSettings = {
        leftLabel: 'TaskName'
    };
    projectStartDate = new Date('03/24/2019');
    projectEndDate = new Date('07/06/2019');
    render() {
        return (<div className='control-pane'>
        <div className='control-section'>
          <GanttComponent {...this.props.allEvents} id='Default' dataSource={projectNewData} treeColumnIndex={1} taskFields={this.taskFields} labelSettings={this.labelSettings} height='410px' width={"700px"} projectStartDate={this.projectStartDate} projectEndDate={this.projectEndDate}>
              <ColumnsDirective>
              <ColumnDirective field='TaskID' width='80'></ColumnDirective>
              <ColumnDirective field='TaskName' headerText='Job Name' width='250' clipMode='EllipsisWithTooltip'></ColumnDirective>
              <ColumnDirective field='StartDate'></ColumnDirective>
              <ColumnDirective field='Duration'></ColumnDirective>
              <ColumnDirective field='Progress'></ColumnDirective>
              <ColumnDirective field='Predecessor'></ColumnDirective>
              </ColumnsDirective>
            <Inject services={[Selection]}/>
          </GanttComponent>
        </div>

      </div>);
    }
}

export default Component