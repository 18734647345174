import React, { useEffect, useState } from "react"
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom"
import AdminSideBar from "./adminSideBar"
import AdminSignin from "./adminSignin"
import AdminSignup from "./adminSignin/Signup"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons"
import ACTION_ACTIVE_SIDE_BAR from "../../actions/ActiveSideBar/ACTION_ACTIVE_SIDE_BAR"
import AdminDashboard from "./adminDashboard"
import AdminWorkspace from "./adminworkspace"
import allOrganizationsQuery from "../../graphql/queries/organization/allOrganizationsQuery"
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";

import config from "./bot/config";
import ActionProvider from "./bot/ActionProvider";
import MessageParser from "./bot/MessageParser";

import { useLazyQuery } from "@apollo/client";
import AdminUsers from "./adminUsers"
import AdminDepartments from "./adminDepartments"
import AdminTeams from "./adminTeams"
import ACTION_ACTIVE_ORG from "../../actions/ActiveOrg/ACTION_ACTIVE_ORG"
import AdminSettings from "./adminSettings"
import SuperIndustries from "./superIndustries"
import SuperAdmins from "./superAdmins"
import AdminForms from "./adminForms"
import AdminDatabase from "./adminDatabase"

import ACTION_LOGIN_EMAIL_RESET from "../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET"
import allWorkspacesQuery from "../../graphql/queries/workSpace/allWorkspaceQuery"
import allViewsQuery from "../../graphql/queries/view/allViewsQuery"
import DesignEditor from "../resuable/designView"
import organizationQuery from "../../graphql/queries/organization/organizationQuery"
import NewEditor from "../resuable/editor"
import allAppsQuery from "../../graphql/queries/app/allAppsQuery"
import AdminApp from "./adminApp"
import SuperTemplates from "./superTemplates"
import InstancePreview from "../resuable/modals/PreviewModal/InstancePreview"
import OneEditor from "../resuable/modals/OneEditor"
import Builder from "../resuable/builder"
import Banner_1 from "../resuable/builder/editor/selectors/basic/Text/file"


const AdminRouter = (props) => {
  
    const dispatch = useDispatch()
    const userAuthenticated = useSelector((state) => state.userAuthenticated.user_authenticated);


    const {
      shareableRefetch,
        path ,
        user,
        keywordsData,
        themesData,
        themesRefetch,
        keywordsRefetch,
        users,
        admins,
        adminsRefetch,
        usersRefetch,
        usersData, 
        adminsData,
        setUsersData,
        setAdminsData,
        teams,
        teamsRefetch,
        departments,
        departmentsRefetch,
        tables,
        tablesRefetch,
        handleLogout,
        industrys,
        industrysData,
        setIndustrysData,
        industrysRefetch,
        
        templates,
        templatesData,
        setTemplatesData,
        templatesRefetch,
        shareables
    } = props
    

    const [teamsData, setTeamsData] = useState(null)

    useEffect(() => {
        if(teams) {
            setTeamsData(teams.allGroups)
        }
    },[teams])

    const activeSideBar = useSelector((state) => state.activeSideBar.active);

    const history = useHistory()
    const location = useLocation()

    const [organizations, setOrganizations] = useState(null)
    const [allOrganizations, { error: organizationError, data: organizationsData, loading: organizationsLoading, refetch: organizationsRefetch }] = useLazyQuery(allOrganizationsQuery, {
      fetchPolicy: "no-cache",
    });
    const [oneOrganizations, { error: oneOrganizationError, data: oneOrganizationsData, loading: oneOrganizationsLoading, refetch: oneOrganizationsRefetch }] = useLazyQuery(organizationQuery, {
      fetchPolicy: "no-cache",
    });

    const [allWorkspaces, { error: workspaceError, data: workspacesData, loading: workspacesLoading, refetch: workspacesRefetch }] = useLazyQuery(allWorkspacesQuery, {
      fetchPolicy: "no-cache",
    });

    const [allApps, { error: appError, data: appsData, loading: appsLoading, refetch: appsRefetch }] = useLazyQuery(allAppsQuery, {
      fetchPolicy: "no-cache",
    });

    const [allViews, { error: viewError, data: viewsData, loading: viewsLoading, refetch: viewsRefetch }] = useLazyQuery(allViewsQuery, {
      fetchPolicy: "no-cache",
    });

    useEffect(() => {
      if(user) {
        if(user.user.role == "admin") {
          allOrganizations({
            variables:{
              owner:user.user._id
            }
          })
        }

        allWorkspaces({
          variables:{
            user: user.user._id
          }
        })

        allApps({
          variables:{
            user: user.user._id
          }
        })

      }
    },[user])

    useEffect(() => {
      if(organizationsData) {
        setOrganizations(organizationsData?.allOrganizations)
        organizationsData?.allOrganizations?.length > 0 && dispatch(ACTION_ACTIVE_ORG(organizationsData.allOrganizations[0]))
      }
    },[organizationsData])

    const [activeDepartment, setActiveDepartment] = useState(null)
    const [activeTeam, setActiveTeam] = useState(null)
    const [activeTable, setActiveTable] = useState(null)

    const changeActiveRoute = (type, item) => {
      if(type == "Departments") {
        setActiveDepartment(item)
      } else if(type == "Teams") {
        setActiveTeam(item)
      } else if(type == "Database") {
        setActiveTable(item)
      }
    }

    useEffect(() => {
      if(teams && activeTeam) {
        teams.allGroups && teams.allGroups.length > 0 && teams.allGroups.filter(x => x._id == activeTeam._id).length > 0 ? setActiveTeam(teams.allGroups.filter(x => x._id == activeTeam._id)[0]) : null
      }
    },[teams])
    

    const [isOpened, setIsOpened] = useState(false);

    return (
        <>

{/* {!isOpened && (
            <div style={{
              position:"absolute",
              zIndex:"99999",
              right:10,
              bottom:10,
            }}>
            <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            />
            </div>
        )} */}

      {userAuthenticated && (
        <>
      <AdminSideBar

      tables={tables}
      tablesRefetch={tablesRefetch}
      industrys={industrys}
      activeTable={activeTable}
      changeActiveRoute={changeActiveRoute}
      handleLogout={handleLogout}
      departments={departments}
      departmentsRefetch={departmentsRefetch}
      workspaces={workspacesData}
      workspacesRefetch={workspacesRefetch}
      apps={appsData}
      appsRefetch={appsRefetch}
      teams={teams}
      teamsRefetch={teamsRefetch}
      usersRefetch={usersRefetch}
      users={users}
      setUsersData={setUsersData}
      organizationsRefetch={organizationsRefetch}
      organizations={organizations}
      user={user}
      path={path}
      />

      {/* { !activeSideBar && !location.pathname.includes("app") ? <div className="toggleMenuSideBarContainer">
        <div className="toggleMenuSideBar">
          <FontAwesomeIcon onClick={() => dispatch(ACTION_ACTIVE_SIDE_BAR())} style={{fontSize:"30px", color:"white", marginLeft:".2rem"}} className="toggleMenuSideBarIcon" icon={faArrowCircleRight} />
        </div>
      </div> : null } */}
      </>
      )}
      <div className="adminContainer">
      <Switch>
        <Route
          path={"/admin"}
          exact
          render={({match}) => (
            <AdminSignin industrys={industrys} directEmail={match.params.email} directPassword={match.params.password} user={user} />
          )}
        />
        <Route
          path={"/admin/:email/:password"}
          exact
          render={({match}) => (
            <AdminSignin industrys={industrys} directEmail={match.params.email} directPassword={match.params.password} user={user} />
          )}
        />
        <Route
          exact
          path={"/admin/signup"}
          render={() => (
            <AdminSignup />
          )}
        />

        <Route
          exact
          path={"/admin/dashboard"}
          render={() => (
            <AdminDashboard />
          )}
        />

        <Route
          exact
          path={"/admin/workspaces"}
          render={() => (
            <AdminWorkspace templates={templates} templatesData={templatesData} setTemplatesData={setTemplatesData} templatesRefetch={templatesRefetch}  appsRefetch={appsRefetch} shareableRefetch={shareableRefetch} shareables={shareables} usersRefetch={usersRefetch} viewsData={viewsData} viewsRefetch={viewsRefetch} user={user} users={users} />
          )}
        />

        {/* <Route
          exact
          path={"/admin/workspace"}
          render={() => (
            <Adminarea />
          )}
        /> */}

        <Route
          exact
          path={"/admin/app/:workspaceId/:appId"}
          render={() => (
            <AdminApp appsRefetch={appsRefetch} shareableRefetch={shareableRefetch} shareables={shareables} usersRefetch={usersRefetch} viewsData={viewsData} viewsRefetch={viewsRefetch} user={user} users={users} />
          )}
        />

        <Route
          exact
          path={"/admin/editor"}
          render={() => (
            <DesignEditor
            user={user}
            />
          )}
        />

<Route
          exact
          path={"/admin/builder"}
          render={() => (
            <Builder
            />
          )}
        />

      <Route
          exact
          path={"/admin/newEditor"}
          render={() => (
            <NewEditor
            />
          )}
        />
         <Route
          exact
          path={"/admin/forms"}
          render={() => (
            <AdminForms />
          )}
        />
        <Route
          exact
          path={"/admin/teams"}
          render={() => (
            <AdminTeams teamsData={teamsData} teamsRefetch={teamsRefetch} user={user} activeTeam={activeTeam} setActiveTeam={setActiveTeam} usersData={usersData} setUsersData={setUsersData} users={users} usersRefetch={usersRefetch} />
          )}
        />
        <Route
          exact
          path={"/admin/database"}
          render={() => (
            <AdminDatabase tables={tables} tablesRefetch={tablesRefetch} activeTable={activeTable} setActiveTable={setActiveTable} />
          )}
        />
        <Route
          exact
          path={"/admin/users"}
          render={() => (
            <AdminUsers user={user} usersData={usersData} setUsersData={setUsersData} users={users} usersRefetch={usersRefetch} />
          )}
        />
        <Route
          exact
          path={"/admin/admins"}
          render={() => (
            <SuperAdmins user={user} usersData={adminsData} setUsersData={setAdminsData} users={admins} usersRefetch={adminsRefetch} />
          )}
        />
        <Route
          exact
          path={"/admin/industries"}
          render={() => (
            <SuperIndustries industrys={industrys} industrysData={industrysData} setIndustrysData={setIndustrysData} industrysRefetch={industrysRefetch} />
          )}
        />

        <Route
          exact
          path={"/admin/templates"}
          render={() => (
            <SuperTemplates templates={templates} templatesData={templatesData} setTemplatesData={setTemplatesData} templatesRefetch={templatesRefetch} />
          )}
        />

        <Route
          exact
          path={"/admin/template"}
          render={() => (
            <OneEditor/>
          )}
        />

        <Route
          exact
          path={"/admin/departments"}
          render={() => (
            <AdminDepartments departmentsRefetch={departmentsRefetch} user={user} activeDepartment={activeDepartment} setActiveDepartment={setActiveDepartment} usersData={usersData} setUsersData={setUsersData} users={users} usersRefetch={usersRefetch} />
          )}
        />       

        <Route
          exact
          path={"/admin/settings"}
          render={() => (
            <AdminSettings industrys={industrys} themesData={themesData} themesRefetch={themesRefetch} keywordsData={keywordsData} keywordsRefetch={keywordsRefetch} teamsRefetch={teamsRefetch} usersRefetch={usersRefetch} organizationsRefetch={organizationsRefetch} />
          )}
        />
        
        {/* If no path matches, redirect to home */}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      </div>

      </>
    )
}

export default AdminRouter