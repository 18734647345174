import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero12 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <Element canvas style={{ width: "100%" }} id={"div496098"} is="div">
          <ImageC class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600"  />
        </Element>
    </div>
    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="885184" is="div"><Text text="Before they sold out" /></Element>} <br class="hidden lg:inline-block" /> {<Element canvas style={{display:"inline-block"}} id="993281" is="div"><Text text="readymade gluten" /></Element>} </h1>
      <p class="mb-8 leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="206476" is="div"><Text text="Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray." /></Element>} </p>
      <div class="flex justify-center">
        <Element canvas style={{ width: "100%" }} id={"div750884"} is="div">
            <Button classn={"inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"}>
               {<Element canvas style={{display:"inline-block"}} id="926191" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
        <Element canvas style={{ width: "100%" }} id={"div478084"} is="div">
            <Button classn={"ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg"}>
               {<Element canvas style={{display:"inline-block"}} id="704609" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Hero12.craft = {
  displayName: "Hero 12"
};