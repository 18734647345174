import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Pricing9 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="957108" is="div"><Text text="Pricing" /></Element>} </h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="635368" is="div"><Text text="Banh mi cornhole echo park skateboard authentic crucifix neutra tilde lyft biodiesel artisan direct trade mumblecore 3 wolf moon twee" /></Element>} </p>
    </div>
    <div class="lg:w-2/3 w-full mx-auto overflow-auto">
      <table class="table-auto w-full text-left whitespace-no-wrap">
        <thead>
          <tr>
            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl"><span> {<Element canvas style={{display:"inline-block"}} id="747402" is="div"><Text text="Plan" /></Element>} </span></th>
            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100"><span> {<Element canvas style={{display:"inline-block"}} id="977026" is="div"><Text text="Speed" /></Element>} </span></th>
            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100"><span> {<Element canvas style={{display:"inline-block"}} id="546111" is="div"><Text text="Storage" /></Element>} </span></th>
            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100"><span> {<Element canvas style={{display:"inline-block"}} id="104463" is="div"><Text text="Price" /></Element>} </span></th>
            <th class="w-10 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="542726" is="div"><Text text="Start" /></Element>} </span></td>
            <td class="px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="799200" is="div"><Text text="5 Mb/s" /></Element>} </span></td>
            <td class="px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="433919" is="div"><Text text="15 GB" /></Element>} </span></td>
            <td class="px-4 py-3 text-lg text-gray-900"><span> {<Element canvas style={{display:"inline-block"}} id="592251" is="div"><Text text="Free" /></Element>} </span></td>
            <td class="w-10 text-center">
              <input name="plan" type="radio" />
            </td>
          </tr>
          <tr>
            <td class="border-t-2 border-gray-200 px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="369314" is="div"><Text text="Pro" /></Element>} </span></td>
            <td class="border-t-2 border-gray-200 px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="690860" is="div"><Text text="25 Mb/s" /></Element>} </span></td>
            <td class="border-t-2 border-gray-200 px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="564339" is="div"><Text text="25 GB" /></Element>} </span></td>
            <td class="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900"><span> {<Element canvas style={{display:"inline-block"}} id="331372" is="div"><Text text="$24" /></Element>} </span></td>
            <td class="border-t-2 border-gray-200 w-10 text-center">
              <input name="plan" type="radio" />
            </td>
          </tr>
          <tr>
            <td class="border-t-2 border-gray-200 px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="161647" is="div"><Text text="Business" /></Element>} </span></td>
            <td class="border-t-2 border-gray-200 px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="734058" is="div"><Text text="36 Mb/s" /></Element>} </span></td>
            <td class="border-t-2 border-gray-200 px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="391058" is="div"><Text text="40 GB" /></Element>} </span></td>
            <td class="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900"><span> {<Element canvas style={{display:"inline-block"}} id="177744" is="div"><Text text="$50" /></Element>} </span></td>
            <td class="border-t-2 border-gray-200 w-10 text-center">
              <input name="plan" type="radio" />
            </td>
          </tr>
          <tr>
            <td class="border-t-2 border-b-2 border-gray-200 px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="114124" is="div"><Text text="Exclusive" /></Element>} </span></td>
            <td class="border-t-2 border-b-2 border-gray-200 px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="393356" is="div"><Text text="48 Mb/s" /></Element>} </span></td>
            <td class="border-t-2 border-b-2 border-gray-200 px-4 py-3"><span> {<Element canvas style={{display:"inline-block"}} id="577278" is="div"><Text text="120 GB" /></Element>} </span></td>
            <td class="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-lg text-gray-900"><span> {<Element canvas style={{display:"inline-block"}} id="893808" is="div"><Text text="$72" /></Element>} </span></td>
            <td class="border-t-2 border-b-2 border-gray-200 w-10 text-center">
              <input name="plan" type="radio" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex pl-4 mt-4 lg:w-2/3 w-full mx-auto">
      <a class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"> {<Element canvas style={{display:"inline-block"}} id="243553" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </a>
      <Element canvas style={{ width: "100%" }} id={"div859105"} is="div">
            <Button classn={"flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="437423" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
    </div>
  </div>
    </section>

      </div>;
};
Pricing9.craft = {
  displayName: "Pricing 9"
};