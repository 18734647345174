import { combineReducers } from "redux";
import loadingSpinnerReducer from "./reducers/LoadingSpinner/loadingSpinnerReducer";
import createAccountEmailReducer from "./reducers/user/CreateAccount/CreateAccountEmail/createAccountEmailReducer";
import createAccountFirstNameReducer from "./reducers/user/CreateAccount/CreateAccountFirstName/createAccountFirstNameReducer";
import createAccountLastNameReducer from "./reducers/user/CreateAccount/CreateAccountLastName/createAccountLastNameReducer";
import createAccountPasswordReducer from "./reducers/user/CreateAccount/CreateAccountPassword/createAccountPasswordReducer";
import createAccountConfirmPasswordReducer from "./reducers/user/CreateAccount/CreateAccountConfirmPassword/createAccountConfirmPasswordReducer";
import createAccountEmailValidReducer from "./reducers/user/CreateAccount/CreateAccountEmail/Validation/Valid/createAccountEmailValidReducer";
import createAccountEmailInvalidReducer from "./reducers/user/CreateAccount/CreateAccountEmail/Validation/Invalid/createAccountEmailInvalidReducer";
import createAccountPasswordValidReducer from "./reducers/user/CreateAccount/CreateAccountPassword/Validation/Valid/createAccountPasswordValidReducer";
import createAccountPasswordInvalidReducer from "./reducers/user/CreateAccount/CreateAccountPassword/Validation/Invalid/createAccountPasswordInvalidReducer";
import createAccountConfirmPasswordValidReducer from "./reducers/user/CreateAccount/CreateAccountConfirmPassword/Validation/Valid/createAccountConfirmPasswordValidReducer";
import createAccountConfirmPasswordInvalidReducer from "./reducers/user/CreateAccount/CreateAccountConfirmPassword/Validation/Invalid/createAccountConfirmPasswordInvalidReducer";
import loginEmailReducer from "./reducers/user/Login/LoginEmail/loginEmailReducer";
import loginPasswordReducer from "./reducers/user/Login/LoginPassword/loginPasswordReducer";
import loginEmailInvalidReducer from "./reducers/user/Login/LoginEmail/Invalid/loginEmailInvalidReducer";
import loginTempoInvalidReducer from "./reducers/user/Login/LoginEmail/Tempo/loginEmailInvalidReducer";
import loginPasswordInvalidReducer from "./reducers/user/Login/LoginPassword/Invalid/loginPasswordInvalidReducer";
import registeredUserFoundReducer from "./reducers/user/Login/RegisteredUserFound/registeredUserFoundReducer";
import userAuthenticatedReducer from "./reducers/user/Authenticated/userAuthenticatedReducer";
import logoutClickedReducer from "./reducers/user/LogOut/logoutClickedReducer";
import dummyTokenReducer from "./reducers/user/Login/DummyToken/dummyTokenReducer";
import temporaryDummyTokenReducer from "./reducers/user/Login/TemporaryDummyToken/temporaryDummyTokenReducer"
import newPasswordReducer from "./reducers/user/Login/NewPassword/newPasswordReducer"
import newPasswordInvalidReducer from "./reducers/user/Login/NewPassword/Invalid/newPasswordInvalidReducer"
import newPasswordValidReducer from "./reducers/user/Login/NewPassword/Valid/newPasswordValidReducer"
import confirmNewPasswordReducer from "./reducers/user/Login/ConfirmNewPassword/confirmNewPasswordReducer"
import confirmNewPasswordInvalidReducer from "./reducers/user/Login/ConfirmNewPassword/Invalid/confirmNewPasswordInvalidReducer"
import confirmNewPasswordValidReducer from "./reducers/user/Login/ConfirmNewPassword/Valid/confirmNewPasswordValidReducer"
import viewSettingsReducer from "./reducers/viewSettings/activeOrgReducer";


// header reducers

import backRouteReducer from "./reducers/route/backRouteReducer";

// active org
import activeOrgReducer from "./reducers/ActiveOrg/activeOrgReducer"

// sidebar
import activeSideBarReducer from "./reducers/ActiveSideBar/activeSideBarReducer";
import formBuilderReducer from "./reducers/formBuilder/formBuilderReducer";
import triggerViewReducer from "./reducers/TriggerView/triggerViewReducer";
import activeIdReducer from "./reducers/ActiveId/activeId";
import optionsReducer from "./reducers/Options/optionsReducer";

const RootReducer = combineReducers({

  options: optionsReducer,
  formBuilder: formBuilderReducer,
  activeId: activeIdReducer,
  triggerView: triggerViewReducer,
  //sidebar
  viewSettings: viewSettingsReducer,
  activeSideBar: activeSideBarReducer,
  //sidebar
  activeOrg: activeOrgReducer,

  // menu clicked reducers
  // spinner Reducers
  loadingSpinnerActive: loadingSpinnerReducer,

  // Create Account Reducers
  createAccountEmail: createAccountEmailReducer,
  createAccountEmailValid: createAccountEmailValidReducer,
  createAccountEmailInvalid: createAccountEmailInvalidReducer,
  createAccountFirstName: createAccountFirstNameReducer,
  createAccountLastName: createAccountLastNameReducer,
  createAccountPassword: createAccountPasswordReducer,
  createAccountPasswordValid: createAccountPasswordValidReducer,
  createAccountPasswordInvalid: createAccountPasswordInvalidReducer,
  createAccountConfirmPassword: createAccountConfirmPasswordReducer,
  createAccountConfirmPasswordValid: createAccountConfirmPasswordValidReducer,
  createAccountConfirmPasswordInvalid: createAccountConfirmPasswordInvalidReducer,

  // Login Form Reducers
  loginEmail: loginEmailReducer,
  loginEmailInvalid: loginEmailInvalidReducer,
  loginTempoInvalid: loginTempoInvalidReducer,
  loginPassword: loginPasswordReducer,
  loginPasswordInvalid: loginPasswordInvalidReducer,
  registeredUserFound: registeredUserFoundReducer,
  userAuthenticated: userAuthenticatedReducer,
  logoutClicked: logoutClickedReducer,
  dummyToken: dummyTokenReducer,

  // New Password Reducers

  temporaryDummyToken: temporaryDummyTokenReducer,
  newPassword: newPasswordReducer,
  newPasswordInvalid: newPasswordInvalidReducer,
  newPasswordValid: newPasswordValidReducer,
  confirmNewPassword: confirmNewPasswordReducer,
  confirmNewPasswordInvalid: confirmNewPasswordInvalidReducer,
  confirmNewPasswordValid: confirmNewPasswordValidReducer,
  
  // back route reducers

  backRoute: backRouteReducer

    
});

export default RootReducer;
