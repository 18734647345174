import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Testimonials4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize xl:text-5xl lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="282953" is="div"><Text text="What clients saying" /></Element>} </h1>

        <div class="flex justify-center mx-auto mt-6">
            <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
            <span class="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full"></span>
            <span class="inline-block w-1 h-1 bg-blue-500 rounded-full"></span>
        </div>

        <div class="flex items-start max-w-6xl mx-auto mt-16">
            <Element canvas style={{ width: "100%" }} id={"div979811"} is="div">
            <Button classn={"hidden p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 lg:block hover:bg-gray-100"}>
              
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                </svg>
            
            </Button>
          </Element>

            <div>
                <p class="flex items-center text-center text-gray-500 lg:mx-8"> {<Element canvas style={{display:"inline-block"}} id="869658" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam, quam. Odio voluptatem officiis
                    eos illo! Pariatur, totam alias. Beatae accusamus earum quos obcaecati minima molestias. Possimus
                    minima dolores itaque! Esse! Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea voluptates
                    fugiat corrupti laudantium dolores reiciendis pariatur esse quod nihil quia cupiditate debitis
                    quisquam nemo, accusamus animi explicabo? Architecto, unde laboriosam?" /></Element>} </p>

                <div class="flex flex-col items-center justify-center mt-8">
                    <Element canvas style={{ width: "100%" }} id={"div286847"} is="div">
          <ImageC class="object-cover rounded-full w-14 h-14" src="https://images.unsplash.com/photo-1499470932971-a90681ce8530?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                    <div class="mt-4 text-center">
                        <h1 class="font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="877574" is="div"><Text text="Mia Brown" /></Element>} </h1>
                        <span class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="314624" is="div"><Text text="Marketer" /></Element>} </span>
                    </div>
                </div>
            </div>

            <Element canvas style={{ width: "100%" }} id={"div542427"} is="div">
            <Button classn={"hidden p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 lg:block hover:bg-gray-100"}>
              
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                </svg>
            
            </Button>
          </Element>
        </div>
    </div>
    </section>

      </div>;
};
Testimonials4.craft = {
  displayName: "Testimonials 4"
};