import { gql } from "apollo-boost";

const allShareableQuery = gql`
  
    query(
    $org: String
  ) { 
    allShareable (
      org: $org
    ) {
      _id 
    user
    name
    org
    id
    type
    rules
    name
    createdAt
    userName
    userEmail
    }
  }
  
`;

export default allShareableQuery;
