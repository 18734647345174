import { gql } from "apollo-boost";

const updateUserPasswordMutation = gql`
  mutation(
    $password: String
    $temporaryToken:String
    $accessToken: String
  ) {
    updateUserPassword(
      password: $password
      temporaryToken: $temporaryToken
      accessToken: $accessToken
    ) {
      password
      email
      _id
      temporaryToken
      accessToken
    }
  }
`;

export default updateUserPasswordMutation;
