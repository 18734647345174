import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Cta4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container grid grid-cols-1 gap-8 px-4 py-12 mx-auto lg:grid-cols-2">
        <div class="flex flex-col items-center max-w-lg mx-auto text-center">
            <h2 class="text-3xl font-semibold tracking-tight text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="874549" is="div"><Text text="Web development" /></Element>} </h2>

            <p class="mt-3 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="215422" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure ex cupiditate corrupti aliquam eum vel consequuntur hic culpa unde natus officia enim est impedit consequatur aut, voluptatem minima repellat non!" /></Element>} </p>

            <Element canvas style={{ width: "100%" }} id={"div317910"} is="div">
            <Button href={"#"} classn={"inline-flex items-center justify-center w-full px-5 py-2 mt-6 text-white bg-blue-600 rounded-lg sm:w-auto hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="624907" is="div"><Text text="Start now" /></Element>} 
            </Button>
          </Element>
        </div>

        <div class="flex flex-col items-center max-w-lg mx-auto text-center">
            <h2 class="text-3xl font-semibold tracking-tight text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="315981" is="div"><Text text="App development" /></Element>} </h2>

            <p class="mt-3 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="675025" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure ex cupiditate corrupti aliquam eum vel consequuntur hic culpa unde natus officia enim est impedit consequatur aut, voluptatem minima repellat non!" /></Element>} </p>

            <Element canvas style={{ width: "100%" }} id={"div983609"} is="div">
            <Button href={"#"} classn={"inline-flex items-center justify-center w-full px-5 py-2 mt-6 text-gray-700 transition-colors duration-150 transform bg-white border border-gray-200 rounded-lg dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-100 dark:text-white sm:w-auto dark:hover:bg-gray-800 dark:ring-gray-700 focus:ring focus:ring-gray-200 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="108825" is="div"><Text text="Start now" /></Element>} 
            </Button>
          </Element>
        </div>
    </div>
    </section>

      </div>;
};
Cta4.craft = {
  displayName: "Cta 4"
};