import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Testimonials3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize xl:text-5xl lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="952700" is="div"><Text text="What our" /></Element>} <span class="text-blue-500 "> {<Element canvas style={{display:"inline-block"}} id="223456" is="div"><Text text="clients" /></Element>} </span> {<Element canvas style={{display:"inline-block"}} id="674056" is="div"><Text text="say" /></Element>} </h1>

        <p class="max-w-2xl mx-auto mt-6 text-center text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="657192" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo incidunt ex placeat modi magni quia error
            alias, adipisci rem similique, at omnis eligendi optio eos harum." /></Element>} </p>

        <section class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 lg:grid-cols-2 xl:grid-cols-3">
            <div class="p-8 border rounded-lg dark:border-gray-700">
                <p class="leading-loose text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="891434" is="div"><Text text="“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad
                    tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda rerum, culpa
                    aperiam dolorum, obcaecati corrupti aspernatur a.”." /></Element>} </p>

                <div class="flex items-center mt-8 -mx-2">
                    <Element canvas style={{ width: "100%" }} id={"div154673"} is="div">
          <ImageC class="object-cover mx-2 rounded-full w-14 shrink-0 h-14 ring-4 ring-gray-300 dark:ring-gray-700" src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt=""  />
        </Element>

                    <div class="mx-2">
                        <h1 class="font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="918759" is="div"><Text text="Robert" /></Element>} </h1>
                        <span class="text-sm text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="338746" is="div"><Text text="CTO, Robert Consultency" /></Element>} </span>
                    </div>
                </div>
            </div>

            <div class="p-8 border rounded-lg dark:border-gray-700">
                <p class="leading-loose text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="708158" is="div"><Text text="“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad
                    tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda rerum, culpa
                    aperiam dolorum, obcaecati corrupti aspernatur a.”." /></Element>} </p>

                <div class="flex items-center mt-8 -mx-2">
                    <Element canvas style={{ width: "100%" }} id={"div643099"} is="div">
          <ImageC class="object-cover mx-2 rounded-full w-14 shrink-0 h-14 ring-4 ring-gray-300 dark:ring-gray-700" src="https://images.unsplash.com/photo-1531590878845-12627191e687?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt=""  />
        </Element>

                    <div class="mx-2">
                        <h1 class="font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="783456" is="div"><Text text="Jeny Doe" /></Element>} </h1>
                        <span class="text-sm text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="525567" is="div"><Text text="CEO, Jeny Consultency" /></Element>} </span>
                    </div>
                </div>
            </div>

            <div class="p-8 border rounded-lg dark:border-gray-700">
                <p class="leading-loose text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="326403" is="div"><Text text="“Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad
                    tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda rerum, culpa
                    aperiam dolorum, obcaecati corrupti aspernatur a.”." /></Element>} </p>

                <div class="flex items-center mt-8 -mx-2">
                    <Element canvas style={{ width: "100%" }} id={"div817481"} is="div">
          <ImageC class="object-cover mx-2 rounded-full w-14 shrink-0 h-14 ring-4 ring-gray-300 dark:ring-gray-700" src="https://images.unsplash.com/photo-1488508872907-592763824245?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                    <div class="mx-2">
                        <h1 class="font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="906582" is="div"><Text text="Ema Watson" /></Element>} </h1>
                        <span class="text-sm text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="461277" is="div"><Text text="Marketing Manager at Stech" /></Element>} </span>
                    </div>
                </div>
            </div>
        </section>
    </div>
    </section>

      </div>;
};
Testimonials3.craft = {
  displayName: "Testimonials 3"
};