const OPTIONS = "OPTIONS";
const OPTIONS_RESET = "OPTIONS_RESET";

const optionsReducer = (
  state = {options: [{_id: 1,name:"home",  children:[]}]},
  action
) => {
  switch (action.type) {
    case OPTIONS:
      return { ...state, options: action.payload };
    case OPTIONS_RESET:
      return { ...state, options: [{_id: 1,name:"home",  children:[]}] };
    default:
      return { ...state };
  }
};

export default optionsReducer;
