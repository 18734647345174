import React, { useEffect } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch,  } from 'react-redux';
import deleteImg from "../../../../assests/images/deleteConfirm.svg"



const DeleteTeamModal = (props) => {

  const dispatch = useDispatch()
  
    const {
        open,
        setOpen,
        deleteItem
    } = props

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"90%", width:"45vw", alignItems:"center"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                }}
              />  
                <img src={deleteImg} style={{marginTop:"3rem"}}/>
                <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>Are you sure you want to delete this {open}? </h1>
          <h1 className="subHeader" style={{width:"100%", justifyContent:"center", display:"flex"}}>You cannot undo this action. You will need to create
          the {open} again.</h1>
          <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
            <div
              className={`${"auth-button addUser"}`}
              onClick={ deleteItem }
              style={{marginBottom:"0px"}}
            >
              
            Delete
            </div>
            <div
              className={`${"auth-button addUser"}`}
              onClick={ () => setOpen(null) }
              style={{background:"transparent", color:"#222831", border: "1px solid #ABB0B9"}}
            >
              
            Cancel
            </div>
            </div>
        </div> : (<></>)}
      </Modal>
  )}

  export default DeleteTeamModal
       