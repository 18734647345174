import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Content7 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap -mx-4 -mb-10 text-center">
      <div class="sm:w-1/2 mb-10 px-4">
        <div class="rounded-lg h-64 overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div426804"} is="div">
          <ImageC alt="content" class="object-cover object-center h-full w-full" src="https://dummyimage.com/1201x501"  />
        </Element>
        </div>
        <h2 class="title-font text-2xl font-medium text-gray-900 mt-6 mb-3"> {<Element canvas style={{display:"inline-block"}} id="713018" is="div"><Text text="Buy YouTube Videos" /></Element>} </h2>
        <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="779911" is="div"><Text text="Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra irony." /></Element>} </p>
        <Element canvas style={{ width: "100%" }} id={"div121404"} is="div">
            <Button classn={"flex mx-auto mt-6 text-white bg-indigo-500 border-0 py-2 px-5 focus:outline-none hover:bg-indigo-600 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="569536" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
      </div>
      <div class="sm:w-1/2 mb-10 px-4">
        <div class="rounded-lg h-64 overflow-hidden">
          <Element canvas style={{ width: "100%" }} id={"div268999"} is="div">
          <ImageC alt="content" class="object-cover object-center h-full w-full" src="https://dummyimage.com/1202x502"  />
        </Element>
        </div>
        <h2 class="title-font text-2xl font-medium text-gray-900 mt-6 mb-3"> {<Element canvas style={{display:"inline-block"}} id="339668" is="div"><Text text="The Catalyzer" /></Element>} </h2>
        <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="833389" is="div"><Text text="Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra irony." /></Element>} </p>
        <Element canvas style={{ width: "100%" }} id={"div567918"} is="div">
            <Button classn={"flex mx-auto mt-6 text-white bg-indigo-500 border-0 py-2 px-5 focus:outline-none hover:bg-indigo-600 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="803126" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Content7.craft = {
  displayName: "Content 7"
};