import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Form3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="flex flex-col max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 md:flex-row md:h-48">
    <div class="md:flex md:items-center md:justify-center md:w-1/2 md:bg-gray-700 md:dark:bg-gray-800">
        <div class="px-6 py-6 md:px-8 md:py-0">
            <h2 class="text-lg font-bold text-gray-700 dark:text-white md:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="545699" is="div"><Text text="Sign Up For" /></Element>} <span class="text-blue-600 dark:text-blue-400 md:text-blue-300"> {<Element canvas style={{display:"inline-block"}} id="830161" is="div"><Text text="Project" /></Element>} </span> {<Element canvas style={{display:"inline-block"}} id="642408" is="div"><Text text="Updates" /></Element>} </h2>

            <p class="mt-2 text-sm text-gray-600 dark:text-gray-400 md:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="781783" is="div"><Text text="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur obcaecati odio" /></Element>} </p>
        </div>
    </div>

    <div class="flex items-center justify-center pb-6 md:py-0 md:w-1/2">
        <form>
            <div class="flex flex-col p-1.5 overflow-hidden border rounded-lg dark:border-gray-600 lg:flex-row dark:focus-within:border-blue-300 focus-within:ring focus-within:ring-opacity-40 focus-within:border-blue-400 focus-within:ring-blue-300">
                <input class="px-6 py-2 text-gray-700 placeholder-gray-500 bg-white outline-none dark:bg-gray-800 dark:placeholder-gray-400 focus:placeholder-transparent dark:focus:placeholder-transparent" type="text" name="email" placeholder="Enter your email" aria-label="Enter your email" />

                <Element canvas style={{ width: "100%" }} id={"div523014"} is="div">
            <Button classn={"px-4 py-3 text-sm font-medium tracking-wider text-gray-100 uppercase transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:bg-gray-600 focus:outline-none"}>
               {<Element canvas style={{display:"inline-block"}} id="186012" is="div"><Text text="subscribe" /></Element>} 
            </Button>
          </Element>
            </div>
        </form>
    </div>
    </section>

      </div>;
};
Form3.craft = {
  displayName: "Form 3"
};