import React, { useEffect } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { DataSpinner } from '../../spinner/dataSpinner';
import { Form } from '@tsed/react-formio';




const AddDbRecordModal = (props) => {

  const dispatch = useDispatch()
  
    const {
        open,
        setOpen,
        state,
        setState,
        accountColumns,
        addRecordFunc,
        updateRecordFunc
        
    } = props
    
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100vh", width:"45vw"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                }}
              />  
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? "Edit Record" : `Add New Record`}</h1>
          <div className="form-group-container task-form">
          <div>
              {accountColumns.filter(x => x?.name !== "id" && x?.name !== "_id").map(x => (
                <div className="auth-group">
                <TextInput 
                  type={`${x.name}`}
                  label={`${x.name}`}
                  value={state[x.name]?.value}
                  setValue={(value) => setState({...state, [x.name]: {name: x.name, value: value} })}
                  placeHolder={`Enter new ${x.name}`}
                  // error={workspaceNameError}
                  // setError={setWorkspaceNameError} 
                />
                </div>
              ))}
          </div>
          </div>
            <div
              className={`${Object.values(state)?.some((v) => v?.value) ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={Object.values(state)?.some((v) => v?.value) ? open.includes("Edit") ? updateRecordFunc : addRecordFunc : null }
            >
              
            {open.includes("Edit") ? "Update" :  "Add"}
            </div>
        </div> : (<></>)}
      </Modal>
  )}

  export default AddDbRecordModal
       