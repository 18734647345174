import {SchedulerSettings} from "./SchedulerSettings"
import { useEditor, useNode } from "@craftjs/core";
import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Component from "./Component";
import AddDbRecordModal from '../../../../modals/AddDbRecordModal';
import addDbRecordMutation from '../../../../../../graphql/mutations/addRecord/addDbRecordMutation';
import updateDbRecordMutation from '../../../../../../graphql/mutations/addRecord/updateDbRecordMutation';
import deleteDbRecordMutation from '../../../../../../graphql/mutations/addRecord/deleteDbRecordMutation';
import { useMutation } from '@apollo/client';
import ACTION_LOADING_SPINNER_RESET from '../../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET';
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE';
import { useDispatch } from 'react-redux';
import add from "../../../../../../assests/images/add.svg"
import edit from "../../../../../../assests/images/edit.svg"
import trash from "../../../../../../assests/images/trash.svg"
import _ from "lodash"
import Draggable from 'react-draggable';
import ACTION_TRIGGER_VIEW from "../../../../../../actions/TriggerView/ACTION_TRIGGER_VIEW";

export const Scheduler = (props) => {
     const {
      actions: {setProp},
      connectors: { connect },
    } = useNode((node) => ({
      selected: node.events.selected, 
    })); 
   
    const {
      setActiveView,
dataBaseDataArr,
      eventsDataArr, 
      applyFilter,
      schema,
      x,
      y
      } = props
      const [accountColumns, setAccountColumns] = useState([])
      const [resultsArr, setResultsArr] = useState(null)
      const renameKey = (obj, keysMap) => { 
        return _.transform(obj, function(result, value, key) { 
          const currentKey = keysMap[key] || key;
          if([...Object.keys(keysMap), ...Object.values(keysMap)].some(x => x == currentKey)) {
            result[currentKey] = _.isObject(value) ? renameKey(value, keysMap) : value; 
        }
        })
      }

      useEffect(() => {
        if(dataBaseDataArr) {
          if(schema) {
            let newArr = dataBaseDataArr?.results
            let db = dataBaseDataArr.db
            setActiveDb(db)
            setResultsArr(newArr)
            setAccountColumns(Object.keys(newArr[0]).map(x => {
              return {name: x}
            }))

            let newSchema = schema.map(x => {
              return {
                  [x.value]: x.label
            }})?.reduce((a, b) => Object.assign(a, b), {})
            console.log(newArr.map((x) => renameKey(x, newSchema)))
            setResultsArr(newArr.map((x) => renameKey(x, newSchema)))
            

          } else {
            let newArr = dataBaseDataArr?.results
            let db = dataBaseDataArr.db
            setActiveDb(db)
            console.log(newArr, 231)
            setResultsArr(newArr)
            setAccountColumns(Object.keys(newArr[0]).map(x => {
              return {name: x}
            }))
          }
          
        }
      },[dataBaseDataArr, schema])
    const { text, textComponent, color, ...otherProps } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [activeSelect, setActiveSelect] = useState("Organizations")

  const {
    enabled,
    connectors, 
    actions,
    json,
    nodes
  } = useEditor((state, query) => ({
    enabled: state.options.enabled || false,
    json: query.serialize() ,
    nodes: state.nodes
  }));

  const changeView = (id) => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = {_id: id}
    })
  }
  const changeSelect = (ob) => {
    setActiveSelect(ob.name)
    changeView(ob._id)
  }
  
  const resetView = () => {
    let widgetId = "ybSvUzFXrK"
    actions.setProp(widgetId, props => {
      props.filter = null
    })
    setActiveSelect("Organizations")
  }

  useEffect(() => {
    if(eventsDataArr) {

      console.log(eventsDataArr)
    }
  },[eventsDataArr])

  const [addDbRecord, {error: addDbRecordError, data: addDbRecordData, loading: addDbRecordLoading}] = useMutation(addDbRecordMutation)
  const [updateDbRecord, {error: updateDbRecordError, data: updateDbRecordData, loading: updateDbRecordLoading}] = useMutation(updateDbRecordMutation)
  const [deleteDbRecord, {error: deleteDbRecordError, data: deleteDbRecordData, loading: deleteDbRecordLoading}] = useMutation(deleteDbRecordMutation)



  const [selectedRow, setSelectedRow] = useState(null)
    
    const [addRecordModal, setAddRecordModal] = useState(false)
    
    const [state, setState] = useState(null)
    const [activeDb, setActiveDb] = useState(null)

  
    
  const addRecordFunc = (s) => {
    let stat = state || s
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setSelectedRow(null)
    setAddRecordModal(false)
    addDbRecord({
      variables:{
        url: activeDb.url,
        db: activeDb.db,
        table: activeDb.table,
        record: JSON.stringify(stat)
      }
    }).then(res => {
      if(res?.data?.addDbRecord?.url == "done") {
        setState(null)
        dispatch(ACTION_LOADING_SPINNER_RESET())
                allEvents.onCreateRecord ? allEvents.onCreateRecord() : null

      }
    })
    }

    const updateRecordFunc = (s) => {
      let stat = state || s
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setSelectedRow(null)
      setAddRecordModal(false)
      updateDbRecord({
        variables:{
          url: activeDb.url,
          db: activeDb.db,
          table: activeDb.table,
          record: JSON.stringify(stat)
        }
      }).then(res => {
        if(res?.data?.updateDbRecord?.url == "done") {
          setState(null)

          dispatch(ACTION_LOADING_SPINNER_RESET())
                  allEvents.onUpdateRecord ? allEvents.onUpdateRecord() : null

        }
      })
      .catch(err => {
        if(err) {
          dispatch(ACTION_LOADING_SPINNER_RESET())
        }
      })
      }


      

      const deleteRecordFunc = (s) => {
        let stat = state || s
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        // setSelectedRow(null)
        // setAddRecordModal(false)
        deleteDbRecord({
          variables:{
            url: activeDb.url,
            db: activeDb.db,
            table: activeDb.table,
            record: JSON.stringify(stat)
          }
        }).then(res => {
          if(res?.data?.deleteDbRecord?.url == "done") {
            setState(null)
            dispatch(ACTION_LOADING_SPINNER_RESET())
                        allEvents.onUpdateRecord ? allEvents.onUpdateRecord() : null

          }
        })
        .catch(err => {
          if(err) {
            console.log(err)
            dispatch(ACTION_LOADING_SPINNER_RESET())
          }
        })
        }


        const { query } = useEditor((state, query) => ({
          hoveredNodeId: state.events.hovered
        }));

        
  const addNode = (el) => {
    //     console.log(el)
    //     var tempDiv = document.createElement('div')
    //     tempDiv.innerHTML = el
    let convertString = (el) => {
      if(el.includes("grid_widget")) {
        return <GridWidget />
      } else if(el.includes("text_widget")) {
        return <Text />
      } else if(el.includes("card_widget")) {
        return <Card />
      } else {
         const reactElement = parse(el);
         return reactElement;
      }
    }
    
        const nodeTree = query.parseReactElement(convertString(el)).toNodeTree()
        actions.addNodeTree(nodeTree, "ROOT")
    
      }
      const dispatch = useDispatch()
const applyTrigger = (xs) => {
        console.log(xs)
        xs.map(x => {
          let type = x.event.eventType
        if(type == "Alert") {
          alert(x.event.eventContent)
        } else if(type == "Navigate") {
    dispatch(ACTION_TRIGGER_VIEW(x.event.key))
} else if (type == "Filter") {
          applyFilter && applyFilter(allData, x.node, x.event.key, inputRef?.current?.value)
          console.log("filters", inputRef?.current?.value, x)
        } else if(type == "Code") {
          console.log(x)
          let fun = new Function("inputValue", "applyFilter", "addNode", x.event.eventContent)
          fun(inputRef?.current?.value, applyFilter, addNode)
        } else if(type == "Inject") {
          addNode(x.event.eventContent)
        }
        })
      }
      // useEffect(() => {
      //   if(eventsDataArr && inputRef?.current) {
      //     console.log(eventsDataArr)
      //     eventsDataArr.map(x => inputRef.current.addEventListener(x.event.eventName.slice(2, x.event.eventName.length).toLowerCase(), 
      //     () => applyTrigger(x)
      //     )) 
      //   }
      // },[eventsDataArr, inputRef?.current])
    
      
      const [allEvents, setAllEvents] = useState({})
      function groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
          var key = obj[property.replaceAll(' ','')];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});
      }
      
      useEffect(() => {
        if(eventsDataArr && applyFilter) {
          console.log(eventsDataArr)
          let newEventsData = groupBy(eventsDataArr, "eventName")
    
          let allv = Object.assign({}, ...Object.keys(newEventsData).map((key) => {
            console.log(newEventsData[key])
            let arr = [...newEventsData[key]]
            newEventsData[key] = () => applyTrigger(arr);
            return {[key]: newEventsData[key]}
          }))

          console.log(allv,111111111111111)
          setAllEvents(allv)
    
        }
      },[eventsDataArr, applyFilter])
    
      useEffect(() => {
        if(allEvents) {
          console.log(allEvents)
        }
      },[allEvents])

      const [startingPos, setStartingPos] = useState({ x: x, y: y })
     
  const handleStop = (e, data) => {
    console.log(data, 123)
    setStartingPos( { x: data.x, y: data.y })
    setProp(prop => {
      prop.x = data.x;
      prop.y = data.y;
    }, 500)
  }
    return ( 
      <Draggable defaultClassName="dgs" onStop={handleStop} position={startingPos}>
       
    <div ref={connect}>  
    <AddDbRecordModal 
          open={addRecordModal}
          setOpen={setAddRecordModal}
          state={state}
          setState={setState}
          addRecordFunc={addRecordFunc}
          updateRecordFunc={updateRecordFunc}
          accountColumns={accountColumns}
        />
      <Component
      allEvents={allEvents}
      addRecordFunc={addRecordFunc}
      updateRecordFunc={updateRecordFunc}
      deleteRecordFunc={deleteRecordFunc}
      setSelectedRow={setSelectedRow}
      resultsArr={resultsArr?.length ? resultsArr : []}
      />
    </div>
      </Draggable>

    );
  };
  
  Scheduler.craft = {
    displayName: 'Scheduler',
    props: {
      background: { r: 255, g: 255, b: 255, a: 0.5 },
      color: { r: 92, g: 90, b: 90, a: 1 }, 
      buttonStyle: 'full',
      text: 'Button',
      margin: ['5', '0', '5', '0'],   
      applyFilter: null,
      schema: null,
      x: 0,
      y: 0,
    },
    rules: {
      canDrag: () => false,
      canDrop: () => false,
    },
    related: {
      toolbar: SchedulerSettings,
    },
  };
  