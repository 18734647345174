import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';


import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

class Component extends SampleBase {
    toastObj;
    position = { X: 'Right' };
    toastBtnShow;
    toastBtnHide;
    create() {
        setTimeout(function () {
            this.toastObj.show({
                title: 'Adaptive Tiles Meeting', content: 'Conference Room 01 / Building 135 10:00 AM',
                icon: 'e-meeting',
            });
        }.bind(this), 200);
    }
    hideBtnClick() {
        this.toastObj.hide('All');
    }
    showBtnClick() {
        this.toastObj.show();
    }
    onclose(e) {
        if (e.toastContainer.childElementCount === 0) {
            this.toastBtnHide.element.style.display = 'none';
        }
    }
    onbeforeOpen() {
        this.toastBtnHide.element.style.display = 'inline-block';
    }
    rendereComplete() {
        document.addEventListener('click', function (e) {
            if (!isNullOrUndefined(this.toastObj) && e.target !== this.toastBtnShow.element) {
                this.toastObj.hide('All');
            }
        }.bind(this));
    }
    render() {
        return (<div className='control-pane'>
                <div className='control-section  toast-default-section'>
                    <div className=" col-sm-12 col-md-12 center">
                        <ToastComponent {...this.props.allEvents} ref={(toast) => { this.toastObj = toast; }} id='toast_default' position={this.position} created={this.create.bind(this)} close={this.onclose.bind(this)} beforeOpen={this.onbeforeOpen.bind(this)}></ToastComponent>
                        <div id="toastBtnDefault" style={{ margin: 'auto', textAlign: 'center' }}>
                            <ButtonComponent id='toastBtnShow' ref={(btn) => { this.toastBtnShow = btn; }} className='e-btn' onClick={this.showBtnClick.bind(this)}>Show Toasts</ButtonComponent>
                            <ButtonComponent id='toastBtnHide' ref={(btn) => { this.toastBtnHide = btn; }} className='e-btn' onClick={this.hideBtnClick.bind(this)}>Hide All</ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
      );
    }
}

export default Component