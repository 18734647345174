import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Cta2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-gray-100 dark:bg-gray-900 lg:py-12 lg:flex lg:justify-center">
    <div class="bg-white dark:bg-gray-800 lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg">
        <div class="lg:w-1/2 flex mt-4 mb-4">
            <Element canvas style={{ width: "100%" }} id={"div915265"} is="div">
          <ImageC class="h-64 object-cover lg:rounded-lg lg:h-full" src="https://images.unsplash.com/photo-1593642532400-2682810df593?ixlib=rb-1.2.1&ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=750&q=80"  />
        </Element>
        </div>

        <div class="max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/2">
            <h2 class="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="570710" is="div"><Text text="Build Your New" /></Element>} <span class="text-blue-600 dark:text-blue-400"> {<Element canvas style={{display:"inline-block"}} id="961542" is="div"><Text text="Idea" /></Element>} </span></h2>
            <p class="mt-4 text-gray-600 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="233657" is="div"><Text text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem modi reprehenderit vitae exercitationem aliquid dolores ullam temporibus enim expedita aperiam mollitia iure consectetur dicta tenetur, porro consequuntur saepe accusantium consequatur." /></Element>} </p>

            <div class="mt-8">
                <Element canvas style={{ width: "100%" }} id={"div384043"} is="div">
            <Button href={"#"} classn={"px-5 py-2 font-semibold text-gray-100 transition-colors duration-300 transform bg-gray-900 rounded-md hover:bg-gray-700"}>
               {<Element canvas style={{display:"inline-block"}} id="285315" is="div"><Text text="Start Now" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Cta2.craft = {
  displayName: "Cta 2"
};