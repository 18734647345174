import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Pricing1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-8 mx-auto">
        <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="266379" is="div"><Text text="Pricing Plan" /></Element>} </h1>

        <p class="max-w-2xl mx-auto mt-4 text-center text-gray-500 xl:mt-6 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="706140" is="div"><Text text="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias quas magni libero consequuntur voluptatum velit amet id repudiandae ea, deleniti laborum in neque eveniet." /></Element>} </p>

        <div class="grid grid-cols-1 gap-8 mt-6 xl:mt-12 xl:gap-12 md:grid-cols-2 lg:grid-cols-3">
            <div class="w-full p-8 space-y-8 text-center border border-gray-200 rounded-lg dark:border-gray-700">
                <p class="font-medium text-gray-500 uppercase dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="231505" is="div"><Text text="Free" /></Element>} </p>

                <h2 class="text-5xl font-bold text-gray-800 uppercase dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="132039" is="div"><Text text="$0" /></Element>} </h2>

                <p class="font-medium text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="841974" is="div"><Text text="Life time" /></Element>} </p>

                <Element canvas style={{ width: "100%" }} id={"div806893"} is="div">
            <Button classn={"w-full px-4 py-2 mt-10 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="177145" is="div"><Text text="Start Now" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="w-full p-8 space-y-8 text-center bg-blue-600 rounded-lg">
                <p class="font-medium text-gray-200 uppercase"> {<Element canvas style={{display:"inline-block"}} id="667167" is="div"><Text text="Premium" /></Element>} </p>

                <h2 class="text-5xl font-bold text-white uppercase dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="730319" is="div"><Text text="$40" /></Element>} </h2>

                <p class="font-medium text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="807279" is="div"><Text text="Per month" /></Element>} </p>

                <Element canvas style={{ width: "100%" }} id={"div677602"} is="div">
            <Button classn={"w-full px-4 py-2 mt-10 tracking-wide text-blue-500 capitalize transition-colors duration-300 transform bg-white rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:ring focus:ring-gray-200 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="859326" is="div"><Text text="Start Now" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="w-full p-8 space-y-8 text-center border border-gray-200 rounded-lg dark:border-gray-700">
                <p class="font-medium text-gray-500 uppercase dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="276446" is="div"><Text text="Enterprise" /></Element>} </p>

                <h2 class="text-5xl font-bold text-gray-800 uppercase dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="640052" is="div"><Text text="$100" /></Element>} </h2>

                <p class="font-medium text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="343720" is="div"><Text text="Life time" /></Element>} </p>

                <Element canvas style={{ width: "100%" }} id={"div816989"} is="div">
            <Button classn={"w-full px-4 py-2 mt-10 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="116486" is="div"><Text text="Start Now" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </div>

      </div>;
};
Pricing1.craft = {
  displayName: "Pricing 1"
};