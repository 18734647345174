import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Navigation15 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>

    <header class="shadow-sm">
  <div class="mx-auto flex h-16 max-w-screen-xl items-center justify-between px-4">
    <div class="flex w-0 flex-1 lg:hidden">
      <Element canvas style={{ width: "100%" }} id={"div447745"} is="div">
            <Button classn={"rounded-full bg-gray-100 p-2 text-gray-600 cursor-pointer"}>
              
        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
        </svg>
      
            </Button>
          </Element>
    </div>

    <div class="flex items-center gap-4">
      <span class="h-10 w-20 rounded-lg bg-gray-200"></span>

      <form class="mb-0 hidden lg:flex">
        <div class="relative">
          <input class="h-10 rounded-lg border-gray-200 pr-10 text-sm placeholder-gray-300 focus:z-10" placeholder="Search..." type="text" />

          <Element canvas style={{ width: "100%" }} id={"div627614"} is="div">
            <Button classn={"absolute inset-y-0 right-0 mr-px rounded-r-lg p-2 text-gray-600 cursor-pointer"}>
              
            <svg class="h-5 w-5" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" fill-rule="evenodd"></path>
            </svg>
          
            </Button>
          </Element>
        </div>
      </form>
    </div>

    <div class="flex w-0 flex-1 justify-end lg:hidden">
      <Element canvas style={{ width: "100%" }} id={"div661426"} is="div">
            <Button classn={"rounded-full bg-gray-100 p-2 text-gray-500 cursor-pointer"}>
              
        <svg class="h-5 w-5" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" fill-rule="evenodd"></path>
        </svg>
      
            </Button>
          </Element>
    </div>

    <nav class="hidden items-center justify-center gap-8 text-sm font-medium lg:flex lg:w-0 lg:flex-1">
      <Element canvas style={{ width: "100%" }} id={"div467212"} is="div">
            <Button href={""} classn={"text-gray-900 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="902448" is="div"><Text text="About" /></Element>} 
            </Button>
          </Element>
      <Element canvas style={{ width: "100%" }} id={"div848676"} is="div">
            <Button href={""} classn={"text-gray-900 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="522719" is="div"><Text text="Blog" /></Element>} 
            </Button>
          </Element>
      <Element canvas style={{ width: "100%" }} id={"div336535"} is="div">
            <Button href={""} classn={"text-gray-900 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="294236" is="div"><Text text="Projects" /></Element>} 
            </Button>
          </Element>
      <Element canvas style={{ width: "100%" }} id={"div293111"} is="div">
            <Button href={""} classn={"text-gray-900 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="419856" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
    </nav>

    <div class="hidden items-center gap-4 lg:flex">
      <Element canvas style={{ width: "100%" }} id={"div707683"} is="div">
            <Button href={""} classn={"block rounded-lg bg-gray-100 px-5 py-2 text-sm font-medium text-gray-600 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="407741" is="div"><Text text="Log in" /></Element>} 
            </Button>
          </Element>
      <Element canvas style={{ width: "100%" }} id={"div696928"} is="div">
            <Button href={""} classn={"block rounded-lg bg-blue-600 px-5 py-2 text-sm font-medium text-white cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="473081" is="div"><Text text="Sign up" /></Element>} 
            </Button>
          </Element>
    </div>
  </div>

  <div class="border-t border-gray-100 lg:hidden">
    <nav class="flex items-center justify-center overflow-x-auto p-4 text-sm font-medium">
      <Element canvas style={{ width: "100%" }} id={"div886818"} is="div">
            <Button href={""} classn={"flex-shrink-0 pl-4 text-gray-900 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="896931" is="div"><Text text="About" /></Element>} 
            </Button>
          </Element>
      <Element canvas style={{ width: "100%" }} id={"div380745"} is="div">
            <Button href={""} classn={"flex-shrink-0 pl-4 text-gray-900 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="356030" is="div"><Text text="Blog" /></Element>} 
            </Button>
          </Element>
      <Element canvas style={{ width: "100%" }} id={"div834894"} is="div">
            <Button href={""} classn={"flex-shrink-0 pl-4 text-gray-900 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="412697" is="div"><Text text="Projects" /></Element>} 
            </Button>
          </Element>
      <Element canvas style={{ width: "100%" }} id={"div618974"} is="div">
            <Button href={""} classn={"flex-shrink-0 pl-4 text-gray-900 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="184028" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
    </nav>
  </div>
    </header>

      </div>;
};
Navigation15.craft = {
  displayName: "Navigation 15"
};