import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Cta5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-4 py-16 mx-auto lg:flex lg:items-center lg:justify-between">
        <h2 class="text-3xl font-semibold tracking-tight text-gray-800 xl:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="193867" is="div"><Text text="Join us and get the update from anywhere" /></Element>} </h2>

        <div class="mt-8 lg:mt-0">
            <div class="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:-mx-2">
                <input id="email" type="text" class="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md sm:mx-2 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" placeholder="Email Address" />

                <Element canvas style={{ width: "100%" }} id={"div141244"} is="div">
            <Button classn={"px-4 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-700 rounded-md focus:ring focus:ring-blue-300 focus:ring-opacity-80 fo sm:mx-2 hover:bg-blue-600 focus:outline-none focus:bg-blue-600"}>
               {<Element canvas style={{display:"inline-block"}} id="785642" is="div"><Text text="Get Started" /></Element>} 
            </Button>
          </Element>
            </div>

            <p class="mt-3 text-sm text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="703966" is="div"><Text text="Attention! Offer expires in 30 days. Make sure not to miss this opportunity" /></Element>} </p>
        </div>
    </div>
    </section>

      </div>;
};
Cta5.craft = {
  displayName: "Cta 5"
};