import { useNode, useEditor } from '@craftjs/core';
import { ROOT_NODE } from '@craftjs/utils';
import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {ArrowUp} from '../../../icons/arrow-up.js';
import {Delete} from '../../../icons/delete.js';
import {Edit} from '../../../icons/edit.js';
import {Move} from '../../../icons/move.js';
import ACTION_VIEW_SETTINGS from "../../../../../actions/viewSettings/ACTION_VIEW_SETTINGS"
import ACTION_FORMBUILDER from "../../../../../actions/formBuilder/ACTION_FORMBUILDER"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

const IndicatorDiv = styled.div`
  height: 30px;
  margin-top: -29px;
  font-size: 12px;
  line-height: 12px;

  svg {
    fill: #808080;
    width: 15px;
    height: 15px;
  }
`;

const Btn = styled.a`
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  > div {
    position: relative;
    top: -50%;
    left: -50%;
  }
`;

export const RenderNode = ({ render }) => {
  const { id } = useNode();
  const { actions, query, isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
  }));

  const {
    isHover,
    dom,
    name,
    node,
    originalName,
    moveable,
    deletable,
    connectors: { drag },
    parent,
    props 
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    node: node,
    originalName: node.data.displayName,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
  }));

  const currentRef = useRef();
  const dispatch = useDispatch()

  console.log(node)

  useEffect(() => {
    if (dom) {
      if (isActive || isHover) dom.classList.add('component-selected');
      else dom.classList.remove('component-selected');
    }
  }, [dom, isActive, isHover]);

  const getPos = useCallback((dom) => {
    const { top, left, bottom } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0 };
    return {
      top: `${top > 0 ? top - 20 : bottom}px`,
      left: `${left - 30}px`,
    };
  }, []);



  
  return (
    <>
      {isActive
        ? ReactDOM.createPortal(
            <IndicatorDiv
              ref={currentRef}
              className="px-2 py-2 text-white bg-primary fixed flex items-center itemBox"
              style={{
                left: getPos(dom).left,
                top: getPos(dom).top,
                zIndex: 9999,
              }}
            >


  <div
  className="shadow-[2px_0px_10px_0px_rgba(164,_164,_164,_0.1)] flex flex-col w-full"
  id="EditRoot"
>
  <div
    style={{ padding:"5px 20px"}}

  className="border-solid border-[#e9e9ea] bg-white flex flex-row justify-center gap-2 h-20 shrink-0 items-center border rounded-[30px]">
    <div
      className="border-solid border-[#989191] flex flex-col h-10 justify-center items-center border rounded-[30px]"
      id="Button1"
    >
      <div
        className="text-center font-['Inter'] leading-[20px] text-[#161717] mx-8"
        id="Label"
      >
        {name}
      </div>
    </div>
   {id !== ROOT_NODE && <div
   onClick={() => {
    actions.selectNode(parent);

   }}
   style={{cursor:"pointer"}}
      className="bg-[rgba(8,_103,_252,_0.1)] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]"
      id="IconButton"
    >
      <img
        src="https://file.rendit.io/n/mdW1kcmagl4wSDj50KGA.svg"
        className="w-6"
        id="Icons"
      />
    </div>}
   {  moveable &&  <div
   style={{cursor:"pointer"}}
   ref={drag}
      className="bg-[rgba(8,_103,_252,_0.1)] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]"
      id="IconButton2"
    >
      <img
        src="https://file.rendit.io/n/fakTTDLEhANgdwzPj8EN.svg"
        className="w-6"
        id="Icons2"
      />
    </div>}
   { node?.id !== "ROOT" && <div
   style={{cursor:"pointer"}}
   onMouseDown={(e) => {
    e.stopPropagation();
    // actions.delete(id);
    console.log(originalName)
    
       dispatch(ACTION_VIEW_SETTINGS({id, name: originalName}))

  }}
      className="bg-[#0867fc] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]"
      id="IconButton1"
    >
      <img
        src="https://file.rendit.io/n/7uD7ARGko2mu0LisOT8v.svg"
        className="w-6"
        id="Icons1"
      />
    </div>}
    {deletable &&<div
    style={{cursor:"pointer"}}
    onMouseDown={(e) => {
      e.stopPropagation();
      actions.delete(id);
    }}
      className="bg-[rgba(8,_103,_252,_0.1)] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]"
      id="IconButton3"
    >
      <img
        src="https://file.rendit.io/n/XM9ibrjLzeBNTJtQgfyk.svg"
        className="w-6"
        id="Icons3"
      />
    </div>}

    {node?.id == "ROOT" ? <div
    style={{cursor:"pointer"}}
      className="bg-[rgba(8,_103,_252,_0.1)] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]"
      id="IconButton3"
      
    >
                 <FontAwesomeIcon
                className="activeSideBarX"
                icon={faShare}
                style={{cursor:"pointer", color:"rgba(8,103,252"}}        
                onClick={() =>  {
                  dispatch(ACTION_FORMBUILDER(query.serialize()))
                }}
              />
                </div> : null}

  </div>
</div> 

              
            </IndicatorDiv>,
            document.querySelector('.page-container')
          )
        : null}
      {render}
    </> 
  );
};
