import React, { useState } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import Dropdown from 'react-dropdown';
import arrowDownDark from "../../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../../assests/images/arrowUp.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import LoginEmail from '../../../admin/adminSignin/LoginEmail/LoginEmail';
import { useDispatch, useSelector } from 'react-redux';
import ACTION_LOGIN_EMAIL_RESET from '../../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET';
import ACTION_LOGIN_TEMPO_NOT_INVALID from '../../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID';
import ACTION_LOGIN_EMAIL_NOT_INVALID from '../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID';
import { DataSpinner } from '../../spinner/dataSpinner';
import {useLazyQuery} from "@apollo/client"

import getUserQuery from "../../../../graphql/queries/user/getUserQuery"

const AddUserModal = (props) => {
  const loginEmail = useSelector((state) => state.loginEmail.login_email);
  const loginEmailInvalid = useSelector(
    (state) => state.loginEmailInvalid.login_email_invalid
  );
  const loginTempoInvalid = useSelector(
    state => state.loginTempoInvalid.login_tempo_invalid
  );

  const dispatch = useDispatch()
  
    const {
        open,
        setOpen,
        addUser,
        firstName,
        setFirstName,
        firstNameError,
        setFirstNameError,
        LastName,
        setLastName,
        lastnameError,
        setLastNameError,
        addUserLoading

    } = props


    const [name, setName] = useState(null)
    const [next, setNext] = useState(false)

    const [user, { error: getUserError, data: getUserData, loading: getUserLoading, refetch: getUserRefetch }] = useLazyQuery(getUserQuery, {
      fetchPolicy: "no-cache",
    });

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? !addUserLoading ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setFirstName("")
                    setLastName("")
                    dispatch(ACTION_LOGIN_EMAIL_RESET())
                    dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID())
                    dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID())
                    setNext(false)
                    setName(null)

                }}
              />   
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? open : `Add a ${open}`}</h1>
          <div className="form-group-container task-form">
          <div>
          {!next && <div className="auth-group">
                    <LoginEmail type="user" setName={setName} user={user} getUserData={getUserData} />
          </div> }
         { name == "yes" && next &&
         <>
          <div className="auth-group">
                    <TextInput 
                      type="firstName"
                      label="First Name"
                      value={firstName}
                      setValue={setFirstName}
                      placeHolder="Type employee first name"
                      error={firstNameError}
                      setError={setFirstNameError} 
                    />
          </div>
          <div className="auth-group">
          <TextInput 
            type="lastName"
            label="Last Name"
            value={LastName}
            setValue={setLastName}
            placeHolder="Type employee last name"
            error={lastnameError}
            setError={setLastNameError} 
          />
          </div>  
          </>} 
          </div>
          </div>
            <div
              className={`${name == "yes" ? !firstName && !LastName && loginEmail && !loginEmailInvalid ? "auth-button addUser": firstName && LastName && loginEmail && !loginEmailInvalid && !loginTempoInvalid ? "auth-button addUser": "auth-button addUser disable-button" : name == "no" ?  loginEmail && !loginEmailInvalid && !loginTempoInvalid ? "auth-button addUser": "auth-button addUser disable-button": "auth-button addUser disable-button"}`}
              onClick={ () => {
                if(name == "yes") {
                  if(!firstName && !LastName && loginEmail && !loginEmailInvalid) {
                    setNext(true)
                  } else {
                    if(open.includes("Edit")) {

                    } else {
                      if(setName) {
                        setName(null)
                      }
                      addUser()
                    }
                  }
                } else if(name == "no") {
                  if(loginEmail && !loginEmailInvalid && !loginTempoInvalid) {
                    if(open.includes("Edit")) {

                    } else {
                      /////console.log("start")
                      addUser()
                    }
                  }
                }
              }
            }
            >
              
            {open.includes("Edit") ? "Update" : name == "yes" ? "Next" : "Invite"}
            </div>
        </div> : (<DataSpinner />) : (<></>)}
      </Modal>
  )}

  export default AddUserModal
       