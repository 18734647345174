import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { DataSpinner } from '../../spinner/dataSpinner';
import ViewSettings from '../viewSettings';
import arrowDownDark from "../../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../../assests/images/arrowUp.svg"
import Dropdown from "react-dropdown";



const AddTableModal = (props) => {

  const dispatch = useDispatch()
  
    const {
        open,
        setOpen,
        tableName,
        setTableName,
        addTable,
        addTableLoading
        
    } = props
    

  
    const [viewSettings, setViewSettings] = useState(false)
    const [dataBaseUrl,setDataBaseUrl] = useState("")    
    const [dataBaseAllDb,setDataBaseAllDb] = useState("")
    const [dataBaseAllTable,setDataBaseAllTable] = useState("")
    

const addDataBaseFunc = (dbName, tableName, widgetId) => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  addDataBase({
      variables: {
          url:dataBaseUrl, 
          db: dbName,
          table: tableName,
      }
  }).then(res => {
      if(!res.data.addDataBase.done) {
      if(res.data.addDataBase.db && !res.data.addDataBase.table) {
          setDataBaseAllDb(JSON.parse(res.data.addDataBase.db))
      }
      if(res.data.addDataBase.table) {
          setDataBaseAllTable(JSON.parse(res.data.addDataBase.table))
      }
  } else {
      viewsRefetch()
      dispatch(ACTION_VIEW_SETTINGS_RESET())
  }
      dispatch(ACTION_LOADING_SPINNER_RESET())
  }).catch(err => 
      console.log(err)
      )
}


// const databasesType = ["Mongodb", "MySQL", "PostgreSQL", "SQLite", "SQL Server", "MariaDB", "Oracle", "Cassandra", "Firebase", "IBM", "ElasticSearch", "ClickHouse", "Google", "Redis", "Neo4j"]
// const [databaseType, setDatabaseType] = useState("")


  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? !addTableLoading ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%", width:"45vw"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setTableName("")
                }}
              />  
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? open : `New connection`}</h1>
          <div className="form-group-container task-form">
          <div>
          


          <ViewSettings
                dbonly
                open={viewSettings}
                setOpen={setViewSettings}
                dataBaseUrl={dataBaseUrl}
                setDataBaseUrl={setDataBaseUrl}
                addDataBase={addDataBaseFunc}
            />


{/* 
<div className="auth-group">
                           <div className="auth-label">Select database type</div>
                         <Dropdown
                                 options={databasesType}
                                 controlClassName="auth-input" 
                                 arrowClosed={<span className="arrow-closed arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowDownDark}
                     /></span>}
                                 arrowOpen={<span className="arrow-open arrowI" ><img
                                 className="routeIcon forceRight"
                                 src={arrowUp}
                     /></span>}
                                 placeholder={"Select industry of your ORG"}
                                 value={databaseType}
                                 onChange={(e) => {
                                    setDatabaseType(e.value)
                                  }}
                               />
                         </div> */}

          {/* <div className="auth-group">
                    <TextInput 
                      type={`tableName`}
                      label={`table Name*`}
                      value={tableName}
                      setValue={setTableName}
                      placeHolder={`Enter name of new table`}
                    />
                    </div>   */}
          </div>
          </div>
            <div
              className={`${tableName ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={tableName ? addTable : null }
            >
              
            {open.includes("Edit") ? "Update" :  "Connect"}
            </div>
        </div> : (<DataSpinner />) : (<></>)}
      </Modal>
  )}

  export default AddTableModal
       