import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Cta13 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
      <h1 class="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="595715" is="div"><Text text="Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage." /></Element>} </h1>
      <Element canvas style={{ width: "100%" }} id={"div233355"} is="div">
            <Button classn={"flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"}>
               {<Element canvas style={{display:"inline-block"}} id="369801" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
    </div>
  </div>
    </section>

      </div>;
};
Cta13.craft = {
  displayName: "Cta 13"
};