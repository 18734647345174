import { UserComponent, useEditor, useNode } from '@craftjs/core';
import cx from 'classnames';
import React from 'react';

import { ImageCSettings } from './ImageSettings';
import { useDispatch } from 'react-redux';
import ACTION_ACTIVE_ID from '../../../../../../../actions/ActiveId/ACTION_ACTIVE_ID';


export const ImageC = (props) => {
  const dispatch = useDispatch()
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));


  const { 
    background,
color,
margin,
href,
src,
    ...otherProps } = props;
console.log(margin)
  return (
    <img
    onClick={() => !enabled ? dispatch(ACTION_ACTIVE_ID(href)) : null}

      className={props.className}
      ref={connect}
      src={src || "https://fakeimg.pl/350x200/?text=Hello"}
      style={{
        background,
        color,
        margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : 0,
      }}
      {...otherProps}
    />
  );
};

ImageC.craft = {
  displayName: 'Image',
  props: {
    background: { r: 255, g: 255, b: 255, a: 0.5 },
    color: { r: 92, g: 90, b: 90, a: 1 },
    ImageCStyle: 'full',
    text: 'ImageC',
    margin: ['5', '0', '5', '0'],
  },
  related: {
    toolbar: ImageCSettings,
  },
};
