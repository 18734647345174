import { gql } from "apollo-boost";

const allDataBasesQuery = gql`
  
    query(
    $view: ID
  ) { 
    allDataBases( 
      view: $view
    ) {
    _id
    view,
    url,
    db,
    table,
    results
    done
    widget
  }
}
`;

export default allDataBasesQuery;
