import * as React from 'react';
import './index.scss';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { Browser } from '@syncfusion/ej2-base';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
  imgObj;
  imageEditorCreated() {
      if (Browser.isDevice) {
          this.imgObj.open('https://ej2.syncfusion.com/react/demos/src/image-editor/images/flower.png');
      }
      else {
          this.imgObj.open('https://ej2.syncfusion.com/react/demos/src/image-editor/images/bridge.png');
      }
  }
  render() {
      return (<div className='control-pane'>
              <div className='control-section'>
                  <div className='row'> 
                      <div className=' control-section'>
            <div className='e-img-editor-sample'  style={{ height:"100%"}}>
              <ImageEditorComponent {...this.props.allEvents} ref={(img) => { this.imgObj = img; }} created={this.imageEditorCreated.bind(this)}>
              </ImageEditorComponent>
            </div>
                      </div>
                  </div>
              </div>
          </div>
      ); 
    } 
}

export default Component