import React from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import Dropdown from 'react-dropdown';
import arrowDownDark from "../../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../../assests/images/arrowUp.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Multiselect from 'multiselect-react-dropdown';


const ExportModal = (props) => {
    const {
        open,
        setOpen,
        rules,
        setRules,
        exportFunc

    } = props

    const onSelectFunc = (selectedList, selectedItem) => {
      /////console.log(selectedList, selectedItem)
      setRules(selectedList)
      }
      
      const onRemoveFunc = (selectedList, removedItem) => {
          /////console.log(selectedList, removedItem)
          setRules(selectedList)
      }

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open && <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                   setRules()
                }}
              />             
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? open : `Export a ${open}`}</h1>
          <div className="form-group-container task-form">
        <div>
         
          <div className="auth-group">
          <div className="auth-label">select exporties</div>

          <Multiselect
                                options={[
                                  {name: "data", value:"data"},
                                  {name: "events", value:"events"},
                                  {name: "layout", value:"layout"},
                                  {name: "props", value:"props"},
                                ]
                              } // Options to display in the dropdown
                                selectedValues={rules} // Preselected value to persist in dropdown
                                onSelect={onSelectFunc} // Function will trigger on select event
                                onRemove={onRemoveFunc} // Function will trigger on remove event                                displayValue="name" // Property name to display in the dropdown options
                                displayValue="name" // Property name to display in the dropdown options

                          />

          {/* <Dropdown
                  options={["data", "events", "layout", "props"]}
                  controlClassName="auth-input" 
                  menuClassName="underMenu"
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select your default language"}
                  value={rules}
                  onChange={(e) => {
                    setRules(e.value)
                  }}
                  
                /> */}
          </div>     
        </div>
       
          </div>
            <div
              className={`${rules?.length ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={rules?.length ?  open.includes("Edit") ? null : () => exportFunc(open) : null }
            >
              
            Export

            </div>
        </div>}
      </Modal>
  )}

  export default ExportModal
       