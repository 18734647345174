import { useEditor } from '@craftjs/core';
import { Tooltip } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { RedoSvg } from '../../../../icons/toolbox/redo.js';
import { UndoSvg } from '../../../../icons/toolbox/undo.js';

const HeaderDiv = styled.div`
  width: 100%;
  height: 45px;
  z-index: 99999;
  position: relative;
  padding: 0px 10px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
`;

const Btn = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  svg {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    fill: #fff;
    opacity: 0.9;
  }
`;

const Item = styled.a`
  margin-right: 10px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    fill: #707070;
  }
  ${(props) =>
    props.disabled &&
    `
    opacity:0.5;
    cursor: not-allowed;
  `}
`;



export const Header = ({

  canvasView,
  setCanvasView, 
  setExportModal
}) => {

  const { enabled, canUndo, canRedo, actions } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
  }));

  
  return (
    <HeaderDiv style={{zIndex:0}} className="header text-white transition w-full">
      {setCanvasView && <div style={{ color: "black", width: "fit-content", display: "flex", alignItems: "center" }}>
        <div style={{ margin: "0 5px", padding: "7px 3px", cursor: "pointer", borderRadius: "4px", backgroundColor: canvasView == "pc" ? "#E8F1FF" : "#ffffff" }} onClick={() => setCanvasView("pc")}><svg width="24" height="18" viewBox="0 0 24 18" class="symbol symbol-topBarDesktop_NewWorkspace btn-symbol"><path fill={canvasView == "pc" ? "#4C91FF" : "#17191C"} d="M19 0H4a2 2 0 00-2 2v9a2 2 0 002 2h7v3H7v1h9v-1h-4v-3h7a2 2 0 002-2V2a2 2 0 00-2-2zm1 11a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1h15a1 1 0 011 1v9z"></path></svg></div>
        <div style={{ margin: "0 5px", padding: "7px 3px", cursor: "pointer", borderRadius: "4px", backgroundColor: canvasView == "mob" ? "#E8F1FF" : "#ffffff" }} onClick={() => setCanvasView("mob")}><svg width="24" height="18" viewBox="0 0 24 18" class="symbol symbol-topBarMobile_NewWorkspace btn-symbol"><path fill={canvasView == "mob" ? "#4C91FF" : "#17191C"} d="M15 0a2 2 0 012 2v14a2 2 0 01-2 2H8a2 2 0 01-2-2V2a2 2 0 012-2h7zm0 1H8a1 1 0 00-1 1v14a1 1 0 001 1h7a1 1 0 001-1V2a1 1 0 00-1-1zm-2 13v1h-3v-1h3z"></path></svg></div>
        <div style={{ margin: "0 5px", padding: "7px 3px", cursor: "pointer", borderRadius: "4px", backgroundColor: canvasView == "tablet" ? "#E8F1FF" : "#ffffff" }} onClick={() => setCanvasView("tablet")}><svg width="24" height="18" viewBox="0 0 24 18" class="symbol symbol-topBarMobile_NewWorkspace btn-symbol"><path fill={canvasView == "tablet" ? "#4C91FF" : "#17191C"} d="M 15 0 a 2 2 0 0 1 2 2 v 18.302 a 2 2 0 0 1 -2 2 H 2.062 a 2 2 0 0 1 -2 -2 V 2 a 2 2 0 0 1 2 -2 h 7 z m 0 1 H 2 a 1 1 0 0 0 -1 1 v 18 a 1 1 0 0 0 1 1 h 13 a 1 1 0 0 0 1 -1 V 2 a 1 1 0 0 0 -1 -1 z m -1 13 v 3 h -11 v -3 h 3 z"></path></svg></div>
      </div>}
      <div className="items-center flex w-full px-4 justify-end" style={{ width: "fit-content" }}>
        {true && (
          <div className="flex-1 flex">

            {setExportModal &&<div onClick={() => {
                // setExportModal("view")
              }} 
              style={{cursor:"pointer", width:"100px", display:"flex", justifyContent:"center", alignItems:"center", color:"#116dff",padding:"0 5px"}}>
              Export
            </div>}

            <Tooltip title="Undo" placement="bottom">
              <Item disabled={!canUndo} onClick={() => actions.history.undo()}>
                <UndoSvg />
              </Item>
            </Tooltip>
            <Tooltip title="Redo" placement="bottom">
              <Item disabled={!canRedo} onClick={() => actions.history.redo()}>
                <RedoSvg />
              </Item>
            </Tooltip>
          </div>
        )}
      </div>
    </HeaderDiv>
  );
};
