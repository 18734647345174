import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  return (
    <div style={{height:"100%", overflow:"auto"}}>
      <ToolbarSection
        title="Header"
        props={['activeHeader', 'header']}
      >
        <ToolbarItem full={true} propKey="activeHeader" type="switch" label="Active" />
        <ToolbarItem
          full={true}
          propKey="header"
          type="text"
          label="Header"
        />
      </ToolbarSection>
      <ToolbarSection
        title="Content"
        props={['activeContent', 'content']}
      >
        <ToolbarItem full={true} propKey="activeContent" type="switch" label="Active" />
        <ToolbarItem
          full={true}
          propKey="content"
          type="text"
          label="Content"
        />
      </ToolbarSection>
      <ToolbarSection
        title="Confirm Button"
        props={["confirm",'confirmTitle']}
      >
        <ToolbarItem full={true} propKey="confirm" type="switch" label="Active" />
        <ToolbarItem
          full={true}
          propKey="confirmTitle"
          type="text"
          label="Button title"
        />
      </ToolbarSection>
      <ToolbarSection
        title="Dismiss Button"
        props={["dismiss",'dismissTitle']}
      >
        <ToolbarItem full={true} propKey="dismiss" type="switch" label="Active" />
        <ToolbarItem
          full={true}
          propKey="dismissTitle"
          type="text"
          label="Button title"
        />
      </ToolbarSection>
    </div>
  );
};
