import * as React from 'react';
    
    export class SampleBase extends React.PureComponent {
        rendereComplete() {
            /**custom render complete function */
        }
        componentDidMount() {
            setTimeout(() => {
                this.rendereComplete();
            });
        }
    }
    export function updateSampleSection(){}
    