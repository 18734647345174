import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Faq4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-4xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="443778" is="div"><Text text="FAQ's" /></Element>} </h1>

        <hr class="my-6 border-gray-200 dark:border-gray-700" />

        <div>
            <div>
                <Element canvas style={{ width: "100%" }} id={"div726985"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                    <svg class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path></svg>

                    <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="810241" is="div"><Text text="How can I pay for my appointment ?" /></Element>} </h1>
                
            </Button>
          </Element>

                <div class="flex mt-8 md:mx-10">
                    <span class="border border-blue-500"></span>

                    <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="290853" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, eum quae. Harum officiis reprehenderit ex quia ducimus minima id provident molestias optio nam vel, quidem iure voluptatem, repellat et ipsa." /></Element>} </p>
                </div>
            </div>

            <hr class="my-8 border-gray-200 dark:border-gray-700" />

            <div>
                <Element canvas style={{ width: "100%" }} id={"div200689"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>

                    <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="291778" is="div"><Text text="What can I expect at my first consultation ?" /></Element>} </h1>
                
            </Button>
          </Element>
            </div>

            <hr class="my-8 border-gray-200 dark:border-gray-700" />

            <div>
                <Element canvas style={{ width: "100%" }} id={"div616446"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>

                    <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="955267" is="div"><Text text="What are your opening hours ?" /></Element>} </h1>
                
            </Button>
          </Element>
            </div>

            <hr class="my-8 border-gray-200 dark:border-gray-700" />

            <div>
                <Element canvas style={{ width: "100%" }} id={"div333721"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>

                    <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="986737" is="div"><Text text="Do I need a referral ?" /></Element>} </h1>
                
            </Button>
          </Element>
            </div>

            <hr class="my-8 border-gray-200 dark:border-gray-700" />

            <div>
                <Element canvas style={{ width: "100%" }} id={"div874671"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>

                    <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="691185" is="div"><Text text="Is the cost of the appointment covered by private health insurance ?" /></Element>} </h1>
                
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Faq4.craft = {
  displayName: "Faq 4"
};