import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Gallery1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <div class="flex w-full mb-20 flex-wrap">
      <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4"> {<Element canvas style={{display:"inline-block"}} id="842839" is="div"><Text text="Master Cleanse Reliac Heirloom" /></Element>} </h1>
      <p class="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="930804" is="div"><Text text="Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom." /></Element>} </p>
    </div>
    <div class="flex flex-wrap md:-m-2 -m-1">
      <div class="flex flex-wrap w-1/2">
        <div class="md:p-2 p-1 w-1/2">
          <Element canvas style={{ width: "100%" }} id={"div846472"} is="div">
          <ImageC alt="gallery" class="w-full object-cover h-full object-center block" src="https://dummyimage.com/500x300"  />
        </Element>
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <Element canvas style={{ width: "100%" }} id={"div270109"} is="div">
          <ImageC alt="gallery" class="w-full object-cover h-full object-center block" src="https://dummyimage.com/501x301"  />
        </Element>
        </div>
        <div class="md:p-2 p-1 w-full">
          <Element canvas style={{ width: "100%" }} id={"div838106"} is="div">
          <ImageC alt="gallery" class="w-full h-full object-cover object-center block" src="https://dummyimage.com/600x360"  />
        </Element>
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="md:p-2 p-1 w-full">
          <Element canvas style={{ width: "100%" }} id={"div629153"} is="div">
          <ImageC alt="gallery" class="w-full h-full object-cover object-center block" src="https://dummyimage.com/601x361"  />
        </Element>
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <Element canvas style={{ width: "100%" }} id={"div608209"} is="div">
          <ImageC alt="gallery" class="w-full object-cover h-full object-center block" src="https://dummyimage.com/502x302"  />
        </Element>
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <Element canvas style={{ width: "100%" }} id={"div659350"} is="div">
          <ImageC alt="gallery" class="w-full object-cover h-full object-center block" src="https://dummyimage.com/503x303"  />
        </Element>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Gallery1.craft = {
  displayName: "Gallery 1"
};