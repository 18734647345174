import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  return (
    <ToolbarSection
        title="Label"
        props={['label']}
      >
        <ToolbarItem
          full={true}
          propKey="label"
          type="text"
          label="label"
        />
      </ToolbarSection>
  );
};
