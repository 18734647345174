import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Footer12 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <footer class="text-center bg-gray-900">
  <div class="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-3xl space-y-6">
      <div class="flex justify-center gap-6">
        <Element canvas style={{ width: "100%" }} id={"div626003"} is="div">
            <Button href={"/"} classn={"text-blue-500 hover:text-blue-500/75 cursor-pointer"}>
              
          <svg class="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
          </svg>
        
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div577996"} is="div">
            <Button href={"/"} classn={"text-gray-300 hover:text-gray-300/75 cursor-pointer"}>
              
          <svg class="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd"></path>
          </svg>
        
            </Button>
          </Element>

      </div>

      <nav class="rounded-3xl border-4 p-6 border-gray-700">
        <ul class="flex flex-wrap justify-center gap-6 text-sm font-bold">
          <li>
            <Element canvas style={{ width: "100%" }} id={"div212262"} is="div">
            <Button href={"/"} classn={"transition text-white hover:text-white/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="815025" is="div"><Text text="Website" /></Element>} 
            </Button>
          </Element>
          </li>

          <li>
            <Element canvas style={{ width: "100%" }} id={"div103226"} is="div">
            <Button href={"/"} classn={"transition text-white hover:text-white/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="876963" is="div"><Text text="Uses" /></Element>} 
            </Button>
          </Element>
          </li>

          <li>
            <Element canvas style={{ width: "100%" }} id={"div112108"} is="div">
            <Button href={"/"} classn={"transition text-white hover:text-white/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="697578" is="div"><Text text="Blog" /></Element>} 
            </Button>
          </Element>
          </li>

          <li>
            <Element canvas style={{ width: "100%" }} id={"div449537"} is="div">
            <Button href={"/"} classn={"transition text-white hover:text-white/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="298533" is="div"><Text text="Portfolio" /></Element>} 
            </Button>
          </Element>
          </li>
        </ul>
      </nav>

      <p class="mx-auto max-w-lg text-xs text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="759632" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, natus
        tempore illo laborum nam, modi quam sequi amet quo quasi impedit iure
        eum similique pariatur alias exercitationem, porro perspiciatis esse.
        Corporis odit consequatur sint sequi." /></Element>} <span class="mt-4 block"> {<Element canvas style={{display:"inline-block"}} id="188437" is="div"><Text text="© 2022 Saul Goodman" /></Element>} </span>
      </p>
    </div>
  </div>
    </footer>


      </div>;
};
Footer12.craft = {
  displayName: "Footer 12"
};