import { gql } from "apollo-boost";

const allAppsQuery = gql`
  
    query(
    $workspace: ID 
    $organization: ID 
    $user: ID
  ) { 
    allApps (
    workspace: $workspace
    organization: $organization
    user: $user 
    ) {
    _id
    workspace,
    organization,
    name
    user
    users
    views
    editors
    }
  }
  
`;

export default allAppsQuery;
