import { useNode } from '@craftjs/core';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
const usePanelStyles = makeStyles((_) => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-expanded': {
      margin: '0 0',
      minHeight: '40px',
      '&:before': {
        opacity: '1',
      },
      '& + .MuiExpansionPanel-root:before ': {
        display: 'block',
      },
    },
  },
}));

const useSummaryStyles = makeStyles((_) => ({
  root: {
    'min-height': '36px',
    padding: 0,
  },
  content: {
    margin: '0px',
  },
}));

export const ToolbarSection = ({ title, props, summary, children, onClick }) => {
  const [expanded, setExpanded] = useState(false)

  const panelClasses = usePanelStyles({});
  const summaryClasses = useSummaryStyles({});
  const { nodeProps } = useNode((node) => ({
    nodeProps:
      props &&
      props.reduce((res, key) => {
        res[key] = node.data.props[key] || null;
        return res;
      }, {}),
  }));
  return (
    <ExpansionPanel style={{width:"100%"}} expanded={expanded} onClick={() => setExpanded(!expanded)} classes={panelClasses}>
      <ExpansionPanelSummary classes={summaryClasses}>
      <div
  className="flex flex-row p-4 justify-between w-full items-center"
  id="ProjectNameRoot"
>
  <div
    className="text-xl font-['Inter'] font-medium leading-[30.8px] text-[#161717]"
    id="ProjectName"
  >
    {title}
  </div>
  {expanded ? <img
    src="https://file.rendit.io/n/WUI94XW6GdagO7rgpLBW.svg"
    className="self-start w-8 shrink-0"
    id="Icons"
  /> : <img
  src="https://file.rendit.io/n/8GtxjpnCronKw4gV0s6e.svg"
  className="w-8"
  id="IconsRoot"
/>
}
</div>

      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: '0px 24px 20px' }}>
        <Divider />
        <Grid container spacing={1}>
          {children}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
