import React, { useEffect } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import ACTION_LOGIN_EMAIL_RESET from '../../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET';
import ACTION_LOGIN_TEMPO_NOT_INVALID from '../../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID';
import ACTION_LOGIN_EMAIL_NOT_INVALID from '../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID';
import { DataSpinner } from '../../spinner/dataSpinner';
import sideLogo from "../../../../assests/images/sideLogo.svg"
import Dropdown from 'react-dropdown';
import arrowDownDark from "../../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../../assests/images/arrowUp.svg"
import addViewImg from "../../../../assests/images/addTeamImg.svg"
import addDepartmentImg from "../../../../assests/images/addDepartmentImg.svg"
import updated from "../../../../assests/images/updated.svg"
import PreviewModal from '../PreviewModal';



const AddViewModal = (props) => {

  const dispatch = useDispatch()
  
    const {
      previewFunc,
        user,
        open,
        setOpen,
        addView,
        updateView,
        updateDepartment,
        viewName,
        setViewName,
        viewNameError,
        setViewNameError,
        usersFilter,
        usersSearch,
        setUsersSearch,
        userSearchError,
        setUsersSearchError,
        addUserToView,
        usersAtView,
        addDepartment,
        setUsersAtView,
        addViewLoading,
        addViewData,
        deleteUserFromView,
        setAddViewData ,
        templatesData,
        setSelectedTemplate,
        selectedTemplate,
        previewModal,
        setPreviewModal

    } = props

    useEffect(() => {
      if(addViewData) {
        /////console.log(addViewData)
      }
    },[addViewData])

    useEffect(() => {
      if(usersFilter) {
        /////console.log(usersFilter)
      }
    },[usersFilter])

    const deleteUser = (_id) => {
      setUsersAtView([...usersAtView.filter(x => x._id !== _id)])
      deleteUserFromView(_id)
    }
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            overflow:"hidden",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? !addViewLoading ? addViewData ? (
          <div className="modal task-modal" style={{paddingLeft: "5rem", width:"100%", paddingRight:"5rem", height:"70%", minWidth:"fit-content", alignItems:"center"}}>
            {
              !open.includes("Edit") ? (
                <img src={addViewImg} style={{marginTop:"3rem"}}/>
              ) : (
                <img src={updated} style={{marginTop:"3rem"}}/>
              )
            }
            <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setViewName("")
                    setAddViewData && setAddViewData(null)

                }}
              />   
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{viewName} {open.includes("Edit") ? "updated" : "added"} successfully</h1>
          <h1 className="subHeader" style={{width:"100%", justifyContent:"center", display:"flex"}}>Design your {"view"} , fill it with widgets, invite users on the {viewName} view.</h1>
        <div
              className="auth-button addUser"
              onClick={() => {
                setOpen(null)
                setViewName("")
                setAddViewData && setAddViewData(null)
              }}
            >
              
            Continue
        </div>  
          </div>
        ) :           <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%", minWidth:"100%", justifyContent:"center", alignItems:"center"}}>

        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setViewNameError("")
                    setUsersSearch("")
                    setUsersSearchError("")
                   !open.includes("Edit") && setUsersAtView(null)
                    setViewName("")
                }}
              />  
     

          <div className="form-group-container task-form">
                      
          <div className="auth-group">
                    <TextInput 
                      type={`${"viewName"}`}
                      label={`${"view Name*"}`}
                      value={viewName}
                      setValue={setViewName}
                      placeHolder={`Enter name of new ${"view"}`}
                      error={viewNameError}
                      setError={setViewNameError} 
                    />
                    </div>
                    <div className="auth-group" style={{position:"relative"}}>
                    <TextInput 
                      type="addUsers"
                      label="Add Users"
                      nextLabel={usersAtView ? usersAtView.length : 0}
                      value={usersSearch ? usersSearch : ""}
                      setValue={setUsersSearch}
                      placeHolder={`Enter the names of the groups or users you want to add to the ${"view"}`}
                      error={userSearchError}
                      setError={setUsersSearchError} 
                    />
                     { usersSearch && usersFilter && usersFilter.length > 0 &&<div className="orgsC" style={{position:"absolute", maxHeight:"200px", width:"100%", top:"110%", borderRadius:"8px", backgroundColor:"white", zIndex:"2"}}>
                        <div className="orgsContainers">
                        {usersFilter.map(y => (
                          <div onClick={() => {
                              setUsersSearch("")
                              addUserToView(y)
                          }} className="oneOrg">
                        <img height="45px" src={sideLogo} style={{marginRight:"12px"}} title={y.name} />
                        <div className="oneOrgDetails">
                            <div className="orgTitle">{y.name}</div>
                            <div className="userTitle">{y.role}</div>
                        </div>

                        </div>  
                        ))}
                        </div>
                    </div>}
                    </div>
                    <div className="auth-group">
                    {usersAtView && <div className="orgsContainers"  style={{overflowY:"visible"}}>
                        {usersAtView.map(y => (
                          <div style={{display:"flex", justifyContent:"space-between", width:"100%", alignItems:"center"}}>
                          <div style={{minWidth:"30%"}}>
                          <div onClick={() => {
                          }} className="disableHoverOneOrg">
                        <img height="45px" src={sideLogo} style={{marginRight:"12px"}} title={y.name} />
                        <div className="oneOrgDetails">
                            <div className="orgTitle">{y.name} {user.user._id == y._id && "(You)"} </div>
                            <div className="userTitle">{open.includes("Edit") ? y.groupRole :  y.role}</div>
                        </div>
                        </div> 
                        </div> 
                        <div>
                        {user.user._id == y._id ? (
                          <div className='ownerTitle'>Owner</div>
                        ) : (
                          <Dropdown
                            options={["Admin", "User", { value: 'Delete', label: 'Delete', className: 'DeleteHoverButton' }]}
                            controlClassName="adminButton moreWidth" 
                            menuClassName='underMenuu'
                            arrowClosed={<span className="arrow-closed arrowI" ><img
                            className="routeIcon forceRight"
                            src={arrowDownDark}
                            /></span>}
                            arrowOpen={<span className="arrow-open arrowI" ><img
                            className="routeIcon forceRight"
                            src={arrowUp}
                            /></span>}
                            placeholder={"Role"}
                            value={open.includes("Edit") ? y.groupRole :  y.role}
                            onChange={(e) => {
                              e.value !== "Delete" ? setUsersAtView(usersAtView.map(t => {
                                if(t._id === y._id) {
                                  !open.includes("Edit") ? y.role = e.value : y.groupRole = e.value
                                  return y
                                } else {
                                  return t
                                }
                              })) : (
                               !usersAtView ? setUsersAtView(null) :  open.includes("Edit") ? deleteUser(y._id) : setUsersAtView([...usersAtView.filter(x => x._id !== y._id)])
                              ) 
                            }}
                          />
                        )}
                        </div>
                        </div>
                        ))}
                        </div>}
                    </div>    
          </div>
            <div
              className={`${viewName ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={viewName ?  open.includes("Edit") ? updateView : addView : null }
            >
              
            {open.includes("Edit") ? "Update" : `Add a ${"View"}`}
            </div>
        </div> : (<DataSpinner />) : (<></>)}
        {/* <div style={{ height:"100%", width:"63%", background:"white", position:"absolute", right:0}}>
         <PreviewModal
            open={previewModal}
            setOpen={setPreviewModal}
            /> 
        </div> */}
      </Modal>
  )}

  export default AddViewModal
       





//   <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? open : `Add a ${open}`}</h1>
//   <div className="auth-label header" style={{justifyContent:"space-between", display:"flex", width:"100%"}}><div>Templates</div></div>
// <div className="auth-group" style={{display:"flex", flexDirection:"row", width:"100%", overflowX:"auto"}}>
//         {templatesData?.map(x => (
//           <div style={{cursor:"pointer", minWidth:"200px", border:selectedTemplate?._id == x?._id ? "2px solid green" : null}} onClick={() => {
            
//             setSelectedTemplate(x)
//             previewFunc(x.html)
//             }} className="workspaceCard">
//           <div className="workspaceThumbnail">thumbnail</div>
//           <div className="workspaceHeader">
//               <div className="workspaceName">
//                   {x.name}
//               </div>
//                   {/* <img className="buttonIconImage" src={threedots} /> */}
//           </div>
//           <div className="workspacefooter">
//               <div className="footerContent">
//                   Created {x.createdAt}
//               </div>
//               {/* <img className="dot" src={dot} /> */}
//               {/* <div className="footerContent">
//                   {x.createdBy}
//               </div> */}
//           </div>

//       </div>
//         ))}
//         </div>