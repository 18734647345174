import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../../editor';
import { ToolbarRadio } from '../../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  return (
    <ToolbarSection
     title="8 code digits"
   >
    <ToolbarItem
      full={true}
      propKey="barcode"
      type="text"
      label="Bar code"
    />
    </ToolbarSection>
  );
};
