import { gql } from "apollo-boost";

const updateOrganizationMutation = gql`
  mutation(
    $_id: ID, 
    $name: String,
    $owner: ID,
    $domain: ID,
    $industry: String
    $language: String
    $currency: String
    $timeZone: String
  ) {
    updateOrganization(
    _id: $_id,
    name:$name,
    domain:$domain
    owner:$owner,
    industry:$industry,
    language:$language,
    currency:$currency,
    timeZone:$timeZone,
    ) {
    _id
    domain
    name,
    owner,
        industry,
    language,
    currency,
    timeZone,
    }
  }
`;

export default updateOrganizationMutation;
