import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Content2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap w-full mb-20">
      <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="112562" is="div"><Text text="Pitchfork Kickstarter Taxidermy" /></Element>} </h1>
        <div class="h-1 w-20 bg-indigo-500 rounded"></div>
      </div>
      <p class="lg:w-1/2 w-full leading-relaxed text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="235373" is="div"><Text text="Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag." /></Element>} </p>
    </div>
    <div class="flex flex-wrap -m-4">
      <div class="xl:w-1/4 md:w-1/2 p-4">
        <div class="bg-gray-100 p-6 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div522597"} is="div">
          <ImageC class="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/720x400" alt="content"  />
        </Element>
          <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font"> {<Element canvas style={{display:"inline-block"}} id="635958" is="div"><Text text="SUBTITLE" /></Element>} </h3>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-4"> {<Element canvas style={{display:"inline-block"}} id="857771" is="div"><Text text="Chichen Itza" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="891289" is="div"><Text text="Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche." /></Element>} </p>
        </div>
      </div>
      <div class="xl:w-1/4 md:w-1/2 p-4">
        <div class="bg-gray-100 p-6 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div281908"} is="div">
          <ImageC class="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/721x401" alt="content"  />
        </Element>
          <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font"> {<Element canvas style={{display:"inline-block"}} id="375732" is="div"><Text text="SUBTITLE" /></Element>} </h3>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-4"> {<Element canvas style={{display:"inline-block"}} id="269433" is="div"><Text text="Colosseum Roma" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="737204" is="div"><Text text="Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche." /></Element>} </p>
        </div>
      </div>
      <div class="xl:w-1/4 md:w-1/2 p-4">
        <div class="bg-gray-100 p-6 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div668226"} is="div">
          <ImageC class="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/722x402" alt="content"  />
        </Element>
          <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font"> {<Element canvas style={{display:"inline-block"}} id="300489" is="div"><Text text="SUBTITLE" /></Element>} </h3>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-4"> {<Element canvas style={{display:"inline-block"}} id="199449" is="div"><Text text="Great Pyramid of Giza" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="820011" is="div"><Text text="Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche." /></Element>} </p>
        </div>
      </div>
      <div class="xl:w-1/4 md:w-1/2 p-4">
        <div class="bg-gray-100 p-6 rounded-lg">
          <Element canvas style={{ width: "100%" }} id={"div171140"} is="div">
          <ImageC class="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/723x403" alt="content"  />
        </Element>
          <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font"> {<Element canvas style={{display:"inline-block"}} id="703506" is="div"><Text text="SUBTITLE" /></Element>} </h3>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-4"> {<Element canvas style={{display:"inline-block"}} id="627710" is="div"><Text text="San Francisco" /></Element>} </h2>
          <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="480611" is="div"><Text text="Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche." /></Element>} </p>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Content2.craft = {
  displayName: "Content 2"
};