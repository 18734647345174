import { gql } from "apollo-boost";

const addEventMutation = gql`
  mutation(
    $view: ID,
    $widgetId: String,
    $eventName: String,
    $nodeId: String,
    $eventType: String
    $eventContent: String
    $key: String
  ) {
    addEvent(
      view: $view
      widgetId: $widgetId 
      eventName: $eventName
      nodeId: $nodeId
      eventType: $eventType
      eventContent: $eventContent
      key: $key
    ) {
    _id
    view
    widgetId
    eventName
    nodeId
    eventType
    eventContent
    key
    }
  }
`;

export default addEventMutation;
