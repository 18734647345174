import { Button } from '../Button';


import { Element, useNode } from '@craftjs/core';
import React from 'react';
import Text from '../Text';
import { Parent } from '../Parent';
export const Column1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div style={{display:"flex", width:"100%"}} ref={connect}>
    
    <Element canvas style={{width:"100%", minHeight:"100px"}} id="Container1" is="div">

    </Element>

      </div>;
};
Column1.craft = {
  displayName: "Column 1"
};