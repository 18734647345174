import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';


import { MessageComponent } from '@syncfusion/ej2-react-notifications';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

const Component = (props) => {
    const renderComponent = () => {
        return (
            <div>{props.activeHeader&&<h1>{props.header}</h1>}
                {props.activeContent && <p>{props.content}</p>}
                {props.confirm && <ButtonComponent id='commitBtn' cssClass='e-link' content={props.confirmTitle}></ButtonComponent>}
                {props.dismiss &&<ButtonComponent id='closeBtn' cssClass='e-link' content={props.dismissTitle}></ButtonComponent>}
            </div>
        )
    }
    // msgTemplate;
    // showBtn;
    // showClick() {
    //     this.msgTemplate.visible = true;
    //     this.showBtn.element.classList.add('msg-hidden');
    // }
    // dismissClick() {
    //     this.msgTemplate.visible = false;
    // }
    // closed() {
    //     this.showBtn.element.classList.remove('msg-hidden');
    // }
  const [key, setKey] = React.useState(1)
    React.useEffect(() => {
        setKey(key+1)
    },[
        
        props.activeHeader,
        props.header,
        props.activeContent,
        props.content,
        props.confirm,
        props.confirmTitle,
        props.dismiss,
        props.dismissTitle    ,
    ])
    

        return (
        <div key={key} style={{height:"200px"}}>
        <div className='control-pane' >
                <div className=" control-section msg-template-section">
                    <div className="content-section">
                        <ButtonComponent id='showBtn' content='Show pull request' cssClass="e-outline e-primary e-success msg-hidden"></ButtonComponent>
                        <MessageComponent style={{width:"500px"}} {...props.allEvents} id="msg_template" content={renderComponent} severity="Success"></MessageComponent>
                    </div>
                </div>
            </div>
            </div>
      );
}

export default Component