import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Form1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
    <h2 class="text-lg font-semibold text-gray-700 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="487059" is="div"><Text text="Account settings" /></Element>} </h2>

    <form>
        <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
                <label class="text-gray-700 dark:text-gray-200" for="username"> {<Element canvas style={{display:"inline-block"}} id="634761" is="div"><Text text="Username" /></Element>} </label>
                <input id="username" type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
            </div>

            <div>
                <label class="text-gray-700 dark:text-gray-200" for="emailAddress"> {<Element canvas style={{display:"inline-block"}} id="245304" is="div"><Text text="Email Address" /></Element>} </label>
                <input id="emailAddress" type="email" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
            </div>

            <div>
                <label class="text-gray-700 dark:text-gray-200" for="password"> {<Element canvas style={{display:"inline-block"}} id="157955" is="div"><Text text="Password" /></Element>} </label>
                <input id="password" type="password" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
            </div>

            <div>
                <label class="text-gray-700 dark:text-gray-200" for="passwordConfirmation"> {<Element canvas style={{display:"inline-block"}} id="500723" is="div"><Text text="Password Confirmation" /></Element>} </label>
                <input id="passwordConfirmation" type="password" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
            </div>
        </div>

        <div class="flex justify-end mt-6">
            <Element canvas style={{ width: "100%" }} id={"div567458"} is="div">
            <Button classn={"px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"}>
               {<Element canvas style={{display:"inline-block"}} id="495680" is="div"><Text text="Save" /></Element>} 
            </Button>
          </Element>
        </div>
    </form>
    </section>

      </div>;
};
Form1.craft = {
  displayName: "Form 1"
};