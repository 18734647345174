import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Pricing7 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-8 mx-auto">
        <div class="sm:flex sm:items-center sm:justify-between">
            <div>
                <h2 class="text-3xl font-bold text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="400413" is="div"><Text text="Simple, transparent pricing" /></Element>} </h2>
                <p class="mt-4 text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="210349" is="div"><Text text="No Contracts. No surorise fees." /></Element>} </p>
            </div>

            <div class="overflow-hidden p-0.5 mt-6 border rounded-lg dark:border-gray-700">
                <div class="sm:-mx-0.5 flex">
                    <Element canvas style={{ width: "100%" }} id={"div863463"} is="div">
            <Button classn={" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-white bg-blue-500 rounded-lg"}>
               {<Element canvas style={{display:"inline-block"}} id="492761" is="div"><Text text="Monthly" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div633181"} is="div">
            <Button classn={" focus:outline-none px-3 w-1/2 sm:w-auto py-1 sm:mx-0.5 text-gray-800 dark:text-gray-200 dark:hover:bg-gray-800 bg-transparent rounded-lg hover:bg-gray-200"}>
               {<Element canvas style={{display:"inline-block"}} id="399906" is="div"><Text text="Yearly" /></Element>} 
            </Button>
          </Element>
                </div>
            </div>
        </div>

        <div class="grid gap-6 mt-16 -mx-6 sm:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div class="px-6 py-4 transition-colors duration-300 transform rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800">
                <p class="text-lg font-medium text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="165590" is="div"><Text text="Intro" /></Element>} </p>

                <h4 class="mt-2 text-4xl font-semibold text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="580323" is="div"><Text text="$19" /></Element>} <span class="text-base font-normal text-gray-600 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="399318" is="div"><Text text="/ Month" /></Element>} </span></h4>
                
                <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="449737" is="div"><Text text="For most businesses that want to optimaize web queries." /></Element>} </p>

                <div class="mt-8 space-y-8">
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="215780" is="div"><Text text="All limited links" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="353535" is="div"><Text text="Own analytics platform" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="445546" is="div"><Text text="Chat support" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="367596" is="div"><Text text="Optimize hashtags" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="836705" is="div"><Text text="Unlimited users" /></Element>} </span>
                    </div>
                </div>

                <Element canvas style={{ width: "100%" }} id={"div281893"} is="div">
            <Button classn={"w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"}>
               {<Element canvas style={{display:"inline-block"}} id="746325" is="div"><Text text="Choose plan" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="px-6 py-4 transition-colors duration-300 transform rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800">
                <p class="text-lg font-medium text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="446298" is="div"><Text text="Base" /></Element>} </p>
                
                <h4 class="mt-2 text-4xl font-semibold text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="432783" is="div"><Text text="$39" /></Element>} <span class="text-base font-normal text-gray-600 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="161870" is="div"><Text text="/ Month" /></Element>} </span></h4>
                
                <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="201623" is="div"><Text text="For most businesses that want to optimaize web queries." /></Element>} </p>

                <div class="mt-8 space-y-8">
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="580098" is="div"><Text text="All limited links" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="356493" is="div"><Text text="Own analytics platform" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="684927" is="div"><Text text="Chat support" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="606401" is="div"><Text text="Optimize hashtags" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="851633" is="div"><Text text="Unlimited users" /></Element>} </span>
                    </div>
                </div>

                <Element canvas style={{ width: "100%" }} id={"div838816"} is="div">
            <Button classn={"w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"}>
               {<Element canvas style={{display:"inline-block"}} id="323921" is="div"><Text text="Choose plan" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="px-6 py-4 transition-colors duration-300 transform bg-gray-700 rounded-lg dark:bg-gray-800">
                <p class="text-lg font-medium text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="394462" is="div"><Text text="Popular" /></Element>} </p>
                
                <h4 class="mt-2 text-4xl font-semibold text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="242438" is="div"><Text text="$99" /></Element>} <span class="text-base font-normal text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="652293" is="div"><Text text="/ Month" /></Element>} </span></h4>
                
                <p class="mt-4 text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="999876" is="div"><Text text="For most businesses that want to optimaize web queries." /></Element>} </p>

                <div class="mt-8 space-y-8">
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="249367" is="div"><Text text="All limited links" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="142775" is="div"><Text text="Own analytics platform" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="145813" is="div"><Text text="Chat support" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="741149" is="div"><Text text="Optimize hashtags" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="208539" is="div"><Text text="Unlimited users" /></Element>} </span>
                    </div>
                </div>

                <Element canvas style={{ width: "100%" }} id={"div191964"} is="div">
            <Button classn={"w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"}>
               {<Element canvas style={{display:"inline-block"}} id="204843" is="div"><Text text="Choose plan" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="px-6 py-4 transition-colors duration-300 transform rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800">
                <p class="text-lg font-medium text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="204850" is="div"><Text text="Exterprise" /></Element>} </p>
                
                <h4 class="mt-2 text-4xl font-semibold text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="341441" is="div"><Text text="$199" /></Element>} <span class="text-base font-normal text-gray-600 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="526861" is="div"><Text text="/ Month" /></Element>} </span></h4>
                
                <p class="mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="238933" is="div"><Text text="For most businesses that want to optimaize web queries." /></Element>} </p>

                <div class="mt-8 space-y-8">
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="894446" is="div"><Text text="All limited links" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="892785" is="div"><Text text="Own analytics platform" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="674562" is="div"><Text text="Chat support" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="823557" is="div"><Text text="Optimize hashtags" /></Element>} </span>
                    </div>

                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>

                        <span class="mx-4 text-gray-700 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="341261" is="div"><Text text="Unlimited users" /></Element>} </span>
                    </div>
                </div>

                <Element canvas style={{ width: "100%" }} id={"div243079"} is="div">
            <Button classn={"w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"}>
               {<Element canvas style={{display:"inline-block"}} id="312645" is="div"><Text text="Choose plan" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Pricing7.craft = {
  displayName: "Pricing 7"
};