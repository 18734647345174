import React, { useState } from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
      const [items, setItems] = useState([])
return (
<div>
    <ToolbarSection
         title="new item"
         onClick={() => setItems([...items, {}])}
        >
        </ToolbarSection>
       
        {items.map((x,i) => (<><ToolbarSection
          title={`item ${i+1}`}
          props={['items']}
        >
         <ToolbarItem
          full={true}
          propKey="items"
          type="text"
          label="Item name"
          addTo="items"
          theKey="header"
          index={i}
         />
          {/* <ToolbarItem
          full={true}
          propKey="items"
          type="text"
          label="Item name"
          addTo="items"
          theKey="content"
          index={i}
         /> */}
       </ToolbarSection>
       </>
       ))}
</div>
  )
};
