import * as React from 'react';
import { AppBarComponent } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SampleBase } from './sample-base';
import './index.scss';

class Component extends SampleBase {
    regularBtn;
    primaryBtn;
    denseBtn;
    regularBtnCreated() {
        this.regularBtn.element.setAttribute('aria-label', 'menu');
    }
    primaryBtnCreated() {
        this.primaryBtn.element.setAttribute('aria-label', 'menu');
    }
    denseBtnCreated() {
        this.denseBtn.element.setAttribute('aria-label', 'menu');
    }
    render() {
        return (<div className='control-pane' style={{height:"100px"}}>
        <div className=' control-section default-appbar-section'  style={{height:"100%"}}>
          <div className='control appbar-sample'>
            <div className="default-appbar-container">
              <div className="row">
                <div className="col-md-12">
                  <h5>All you can cloud</h5>
                </div>
              </div>
              <div style={{width:"100%"}} className="row">
                  <AppBarComponent style={{width:"800px"}} {...this.props.allEvents} colorMode="Primary">
                    <ButtonComponent ref={regularBtn => (this.regularBtn = regularBtn)} created={this.regularBtnCreated.bind(this)} cssClass='e-inherit menu' iconCss='e-icons e-menu'></ButtonComponent>
                    <span className="regular">AppBar</span>
                    <div className="e-appbar-spacer"></div>
                    <ButtonComponent cssClass='e-inherit login'>FREE TRIAL</ButtonComponent>
                  </AppBarComponent>
              </div>
            </div>
          </div></div>
      </div>);
    }
}

export default Component