import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Cta11 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <aside>
  <div class="mx-auto max-w-screen-2xl px-4 py-8 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <div class="bg-blue-600 p-8 md:p-12 lg:px-16 lg:py-24">
        <div class="mx-auto max-w-xl text-center">
          <h2 class="text-2xl font-bold text-white md:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="810734" is="div"><Text text="Lorem, ipsum dolor sit amet consectetur adipisicing elit" /></Element>} </h2>

          <p class="hidden text-white/90 sm:mt-4 sm:block"> {<Element canvas style={{display:"inline-block"}} id="125963" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas
            tempus tellus etiam sed. Quam a scelerisque amet ullamcorper eu enim
            et fermentum, augue. Aliquet amet volutpat quisque ut interdum
            tincidunt duis." /></Element>} </p>

          <div class="mt-4 md:mt-8">
            <Element canvas style={{ width: "100%" }} id={"div841609"} is="div">
            <Button href={"#"} classn={"inline-block rounded border border-white bg-white px-12 py-3 text-sm font-medium text-blue-500 transition hover:bg-transparent hover:text-white focus:outline-none focus:ring focus:ring-yellow-400 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="374083" is="div"><Text text="Get Started Today" /></Element>} 
            </Button>
          </Element>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 md:grid-cols-1 lg:grid-cols-2">
        <Element canvas style={{ width: "100%" }} id={"div266623"} is="div">
          <ImageC alt="Student" src="https://images.unsplash.com/photo-1621274790572-7c32596bc67f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654&q=80" class="h-40 w-full object-cover sm:h-56 md:h-full"  />
        </Element>

        <Element canvas style={{ width: "100%" }} id={"div555117"} is="div">
          <ImageC alt="Student" src="https://images.unsplash.com/photo-1567168544813-cc03465b4fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" class="h-40 w-full object-cover sm:h-56 md:h-full"  />
        </Element>
      </div>
    </div>
  </div>
    </aside>

      </div>;
};
Cta11.craft = {
  displayName: "Cta 11"
};