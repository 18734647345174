import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Form4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="max-w-3xl px-6 py-16 mx-auto text-center">
        <h1 class="text-3xl font-semibold text-gray-800 dark:text-gray-100"> {<Element canvas style={{display:"inline-block"}} id="635631" is="div"><Text text="Want to see Back in action?" /></Element>} </h1>
        <p class="max-w-md mx-auto mt-5 text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="475844" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, minus tempora nemo quos" /></Element>} </p>

        <div class="flex flex-col mt-8 space-y-3 sm:space-y-0 sm:flex-row sm:justify-center sm:-mx-2">
            <input id="email" type="text" class="px-4 py-2 text-gray-700 bg-white border rounded-md sm:mx-2 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" placeholder="Email Address" />

            <Element canvas style={{ width: "100%" }} id={"div762451"} is="div">
            <Button classn={"px-4 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-700 rounded-md sm:mx-2 hover:bg-blue-600 focus:outline-none focus:bg-blue-600"}>
               {<Element canvas style={{display:"inline-block"}} id="784575" is="div"><Text text="Get Started" /></Element>} 
            </Button>
          </Element>
        </div>
    </div>
    </section>

      </div>;
};
Form4.craft = {
  displayName: "Form 4"
};