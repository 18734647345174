import * as React from 'react';
import './index.scss';
import { SampleBase } from './sample-base';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import { TitleBar } from './title-bar';

DocumentEditorContainerComponent.Inject(Toolbar);

class Component extends SampleBase {
    hostUrl = 'https://ej2services.syncfusion.com/production/web-services/';
    container;
    titleBar;
    rendereComplete() {
        window.onbeforeunload = function () {
            return 'Want to save your changes?';
        };
        this.container.serviceUrl = this.hostUrl + 'api/documenteditor/';
        this.container.documentEditor.pageOutline = '#E0E0E0';
        this.container.documentEditor.acceptTab = true;
        this.container.documentEditor.resize();
        this.titleBar = new TitleBar(document.getElementById('documenteditor_titlebar'), this.container.documentEditor, true);
    }
    render() {
        return (<div className='control-pane'>
            <div className='control-section'  style={{ height:"100%"}}>
                <div id='documenteditor_titlebar' className="e-de-ctn-title"></div>
                <div id="documenteditor_container_body">
                    <DocumentEditorContainerComponent {...this.props.allEvents} id="container" ref={(scope) => { this.container = scope; }} style={{ 'display': 'block' }} height={'700px'} width={"700px"} enableToolbar={true} locale='en-US'/>
                </div>
            </div>
        </div>
      );
    } 
}

export default Component