import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-dropdown';

import { DataSpinner } from '../../spinner/dataSpinner';
import { useEditor } from '@craftjs/core';


const EditDbModal = (props) => {

    const {
       actions,
      } = useEditor();


    const {
        open,
        setOpen,
        widgetId,
        schema

    } = props

   let [keysArr, setKeysArr] = useState([
        {label:"Id", example:"87837ND67B6376BND7"},
        {label:"Subject", example:"Meteor Showers in 202"},
        {label:"Location", example:"Space Center US"},
        {label:"StartTime", example:"2021-01-13T07:30:00.000"},
        {label:"EndTime", example:"2021-01-13T09:00:00.000"},
        {label:"CategoryColor", example:"#ea7a57"},
    ])

    // useEffect(() => {
    //     if(widgetId){
    //         actions.setProp(widgetId, props => {
    //             console.log(props.schema)
    //         })
    //     }
    // },[widgetId])
    
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? true ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%", width:"45vw"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                }}
              />  
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{`Edit Database schema`}</h1>
          <div className="form-group-container task-form">
          <div>
          <div className="auth-group">
                  <div className="auth-label" style={{fontWeight:"bold", fontSize:"18px"}}>Data source example</div>
                  {JSON.stringify(open.results[0])}
                   </div>
            {keysArr.map(x => (
                <div className="auth-group">
                  <div className="auth-label" style={{display:"flex", justifyContent:"space-between"}}><span>{x.label}</span><span>Example: {x.example}</span></div>
                  <Dropdown                                 
                  controlClassName="auth-input" 
                  options={Object.keys(open.results[0])}
                  //   menuClassName='underMenuu'
                  placeholder={`Key Assigned to ${x.label}`}
                  value={x.value || ""}
                  onChange={(e) => {
                  x.value = e.value
                  }} 
                />
                   </div>  
            ))}
       
          </div>
          </div>
            <div
            onClick={() => {
                    actions.setProp(widgetId, props => {
                    props.schema = keysArr
                    })
                  setOpen(null)
            }}
            className={"auth-button addUser"}
            >
            Save
            </div>
        </div> : (<DataSpinner />) : (<></>)}
      </Modal>
  )}

  export default EditDbModal
       