import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero13 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
    <Element canvas style={{ width: "100%" }} id={"div323193"} is="div">
          <ImageC class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 ml-auto mr-auto object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600"  />
        </Element>
    <div class="text-center lg:w-2/3 w-full">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="546813" is="div"><Text text="Microdosing synth tattooed vexillologist" /></Element>} </h1>
      <p class="mb-8 leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="833345" is="div"><Text text="Meggings kinfolk echo park stumptown DIY, kale chips beard jianbing tousled. Chambray dreamcatcher trust fund, kitsch vice godard disrupt ramps hexagon mustache umami snackwave tilde chillwave ugh. Pour-over meditation PBR&B pickled ennui celiac mlkshk freegan photo booth af fingerstache pitchfork." /></Element>} </p>
      <div class="flex justify-center">
        <Element canvas style={{ width: "100%" }} id={"div487547"} is="div">
            <Button classn={"inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"}>
               {<Element canvas style={{display:"inline-block"}} id="146426" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
        <Element canvas style={{ width: "100%" }} id={"div361304"} is="div">
            <Button classn={"ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg"}>
               {<Element canvas style={{display:"inline-block"}} id="841280" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Hero13.craft = {
  displayName: "Hero 13"
};