import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Banner2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-gray-900 text-white">
    <div class="mx-auto max-w-3xl text-center">
      <h1 class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl caret-white"> {<Element canvas style={{display:"inline-block"}} id="827008" is="div"><Text text="Understand User Flow." /></Element>} <br /> {<Element canvas style={{display:"inline-block"}} id="136957" is="div"><Text text="Increase Conversion." /></Element>} </h1>

      <p class="mx-auto mt-4 max-w-xl sm:text-xl sm:leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="294324" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo
        tenetur fuga ducimus numquam ea!" /></Element>} </p>

      <div class="mt-8 flex flex-wrap justify-center gap-4">
        <Element canvas style={{ width: "100%" }} id={"div165614"} is="div">
            <Button href={"/get-started"} classn={"block w-full rounded border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="983030" is="div"><Text text="Get Started" /></Element>} 
            </Button>
          </Element> 

        <Element canvas style={{ width: "100%" }} id={"div502602"} is="div">
            <Button href={"/about"} classn={"block w-full rounded border border-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="858096" is="div"><Text text="Learn More" /></Element>} 
            </Button>
          </Element>
      </div>
      </div>
    </section>

      </div>;
};
Banner2.craft = {
  displayName: "Banner 2"
};