import React, { useCallback } from 'react';
import { NodeEditor, useRootEngine } from "flume";
import config from "./config";
import engine from "./engine";

const FlowBuilder = () => {

  const [nodes, setNodes] = React.useState({});
  const {
    title,
    subtitle,
    background,
    flumeWidth,
    flumeHeight
  } = useRootEngine(nodes, engine);
  
  return (
    <div
        style={{
          width: flumeWidth || "100%",
          height: flumeHeight || "100%",
          borderRadius: 20,
          overflow: "hidden"
        }}
      >
        <NodeEditor
          nodeTypes={config.nodeTypes}
          portTypes={config.portTypes}
          nodes={nodes}
          onChange={nodes => setNodes(nodes)}
          defaultNodes={[{ type: "output", x: 200, y: -130 }]}
        />
  </div>    
  );
};

export default FlowBuilder;
