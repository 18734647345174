import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog9 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap -m-12">
      <div class="p-12 md:w-1/2 flex flex-col items-start">
        <span class="inline-block py-1 px-2 rounded bg-indigo-50 text-indigo-500 text-xs font-medium tracking-widest"> {<Element canvas style={{display:"inline-block"}} id="218051" is="div"><Text text="CATEGORY" /></Element>} </span>
        <h2 class="sm:text-3xl text-2xl title-font font-medium text-gray-900 mt-4 mb-4"> {<Element canvas style={{display:"inline-block"}} id="202057" is="div"><Text text="Roof party normcore before they sold out, cornhole vape" /></Element>} </h2>
        <p class="leading-relaxed mb-8"> {<Element canvas style={{display:"inline-block"}} id="816416" is="div"><Text text="Live-edge letterpress cliche, salvia fanny pack humblebrag narwhal portland. VHS man braid palo santo hoodie brunch trust fund. Bitters hashtag waistcoat fashion axe chia unicorn. Plaid fixie chambray 90's, slow-carb etsy tumeric. Cray pug you probably haven't heard of them hexagon kickstarter craft beer pork chic." /></Element>} </p>
        <div class="flex items-center flex-wrap pb-4 mb-4 border-b-2 border-gray-100 mt-auto w-full">
          <a class="text-indigo-500 inline-flex items-center"> {<Element canvas style={{display:"inline-block"}} id="252955" is="div"><Text text="Learn More" /></Element>} <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
          <span class="text-gray-400 mr-3 inline-flex items-center ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
            <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg> {<Element canvas style={{display:"inline-block"}} id="460846" is="div"><Text text="1.2K" /></Element>} </span>
          <span class="text-gray-400 inline-flex items-center leading-none text-sm">
            <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
            </svg> {<Element canvas style={{display:"inline-block"}} id="124298" is="div"><Text text="6" /></Element>} </span>
        </div>
        <a class="inline-flex items-center">
          <Element canvas style={{ width: "100%" }} id={"div916388"} is="div">
          <ImageC alt="blog" src="https://dummyimage.com/104x104" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"  />
        </Element>
          <span class="flex-grow flex flex-col pl-4">
            <span class="title-font font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="725426" is="div"><Text text="Holden Caulfield" /></Element>} </span>
            <span class="text-gray-400 text-xs tracking-widest mt-0.5"> {<Element canvas style={{display:"inline-block"}} id="988740" is="div"><Text text="UI DEVELOPER" /></Element>} </span>
          </span>
        </a>
      </div>
      <div class="p-12 md:w-1/2 flex flex-col items-start">
        <span class="inline-block py-1 px-2 rounded bg-indigo-50 text-indigo-500 text-xs font-medium tracking-widest"> {<Element canvas style={{display:"inline-block"}} id="990600" is="div"><Text text="CATEGORY" /></Element>} </span>
        <h2 class="sm:text-3xl text-2xl title-font font-medium text-gray-900 mt-4 mb-4"> {<Element canvas style={{display:"inline-block"}} id="169388" is="div"><Text text="Pinterest DIY dreamcatcher gentrify single-origin coffee" /></Element>} </h2>
        <p class="leading-relaxed mb-8"> {<Element canvas style={{display:"inline-block"}} id="889772" is="div"><Text text="Live-edge letterpress cliche, salvia fanny pack humblebrag narwhal portland. VHS man braid palo santo hoodie brunch trust fund. Bitters hashtag waistcoat fashion axe chia unicorn. Plaid fixie chambray 90's, slow-carb etsy tumeric." /></Element>} </p>
        <div class="flex items-center flex-wrap pb-4 mb-4 border-b-2 border-gray-100 mt-auto w-full">
          <a class="text-indigo-500 inline-flex items-center"> {<Element canvas style={{display:"inline-block"}} id="956340" is="div"><Text text="Learn More" /></Element>} <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
          <span class="text-gray-400 mr-3 inline-flex items-center ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
            <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg> {<Element canvas style={{display:"inline-block"}} id="702893" is="div"><Text text="1.2K" /></Element>} </span>
          <span class="text-gray-400 inline-flex items-center leading-none text-sm">
            <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
              <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
            </svg> {<Element canvas style={{display:"inline-block"}} id="476458" is="div"><Text text="6" /></Element>} </span>
        </div>
        <a class="inline-flex items-center">
          <Element canvas style={{ width: "100%" }} id={"div275489"} is="div">
          <ImageC alt="blog" src="https://dummyimage.com/103x103" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"  />
        </Element>
          <span class="flex-grow flex flex-col pl-4">
            <span class="title-font font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="955669" is="div"><Text text="Alper Kamu" /></Element>} </span>
            <span class="text-gray-400 text-xs tracking-widest mt-0.5"> {<Element canvas style={{display:"inline-block"}} id="120376" is="div"><Text text="DESIGNER" /></Element>} </span>
          </span>
        </a>
      </div>
    </div>
  </div>
    </section>
  
      </div>;
};
Blog9.craft = {
  displayName: "Blog 9"
};