import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Form2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="relative w-full max-w-md px-5 py-4 mx-auto rounded-md">
    <div class="relative">
        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg class="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        </span>

        <input type="text" class="w-full py-3 pl-10 pr-4 text-gray-700 bg-white border rounded-md dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" placeholder="Search" />
    </div>

    <div class="absolute inset-x-0 px-6 py-3 mx-5 mt-4 overflow-y-auto bg-white border rounded-md max-h-72 dark:bg-gray-900 dark:border-gray-700">
        <Element canvas style={{ width: "100%" }} id={"div667744"} is="div">
            <Button href={"#"} classn={"block py-1"}>
              
            <h3 class="font-medium text-gray-700 dark:text-gray-100 hover:underline"> {<Element canvas style={{display:"inline-block"}} id="799403" is="div"><Text text="Software engineer" /></Element>} </h3>
            <p class="mt-2 text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="480879" is="div"><Text text="02/04/2020" /></Element>} </p>
        
            </Button>
          </Element>
    </div>
    </section>

      </div>;
};
Form2.craft = {
  displayName: "Form 2"
};