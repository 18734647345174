import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, ColumnMenu, Inject,Group, Sort, DetailRow, Page, ExcelExport, ContextMenu, Resize, Toolbar, Edit, CommandColumn } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './SampleBase';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DataSpinner } from '../../spinner/dataSpinner';
import edit from "../../../../assests/images/edit.svg"
import trash from "../../../../assests/images/trash.svg"


export default class DataGrid extends SampleBase {
    
    constructor() {
        super(...arguments);
        this.state = {
            data: null
        }
        this.groupOptions = { showGroupedColumn: true};
        this.dReady = false;
        this.dtTime = false;
        this.isDataBound = false;
        this.isDataChanged = true;
        this.dropSlectedIndex = null;
        this.ddlData = [
            { text: '1,000 Rows and 11 Columns', value: '1000' },
            { text: '10,000 Rows and 11 Columns', value: '10000' },
            { text: '1,00,000 Rows and 11 Columns', value: '100000' }
        ];
        this.fields = { text: 'text', value: 'value' };
        this.getTradeData = this.props.data;
        this.check = {
            type: 'CheckBox'
        };
        this.select = {
            persistSelection: true,
            type: "Multiple",
        };
        this.Filter = {
            type: 'Menu'
        };
        this.status = {
            type: 'CheckBox',
            
        };
        this.trust = {
            type: 'CheckBox',
            
        };
        this.rating = {
            type: 'CheckBox',
            
        };
        this.excel = {
          type:"Excel"
      }

      this.commands = [
        { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
        { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
        { type: 'Save', buttonOption: { cssClass: 'e-flat', iconCss: 'e-update e-icons' } },
        { type: 'Cancel', buttonOption: { cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons' } }
    ];

    }

    editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true};
    editparams = { params: { popupHeight: '300px' } };
   

    commandClick(args) {
        console.log(args)
        if (this.gridInstance) {
            console.log(args.rowData);
        }
    }

   render() {

        return (<div className='control-pane'>
        {this.props.data ? ( 
        <>
        <div className='control-section'>
          <GridComponent id="overviewgrid" 
          showColumnMenu={true} 
          allowTextWrap={true} 
          
          allowResizing={true} 
          textWrapSettings={{wrapMode: 'Content' }} 
          allowGrouping={true} 
          groupSettings={this.groupOptions} 
          dataSource={this.props.data} 
          enableHover={true} 
          rowHeight={50} 
          ref={grid => this.gridInstance = grid} 
          filterSettings={this.Filter} 
          allowFiltering={true} 
          allowSorting={true} 
          allowSelection={true} 
          selectionSettings={this.select} 
          allowPaging={true}  
          allowExcelExport={true} 
          contextMenuItems={this.contextMenuItems} 
          editSettings={this.editSettings} 
          pageSettings={this.pageSettings} 
          commandClick={this.commandClick}
          >
            <ColumnsDirective>
              { this.props.columns.map( (x, i) => {
              return <ColumnDirective isPrimaryKey={x.name == "id" || x.name == "_id"} field={x.name} headerText={x.name} filter={this.check} width={400}></ColumnDirective> 
              }
              )}
                          <ColumnDirective headerText='Commands' width='300' commands={this.commands}/>

            </ColumnsDirective>
            <Inject services={[CommandColumn, Toolbar,Edit, DetailRow,Resize, Group,Filter,ColumnMenu, Sort, Page, ExcelExport, ContextMenu]}/>
          </GridComponent>
        </div>     
      </>

        ) : (
            <DataSpinner /> 
        )  }
        

</div>);
    }
}

