import { gql } from "apollo-boost";

const resetOrganizationMutation = gql`
  mutation($_id: ID) {
    resetOrganization(_id: $_id) {
      _id
    }
  }
`;

export default resetOrganizationMutation;
