import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Cta14 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap items-center">
    <div class="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
      <h1 class="title-font font-medium text-3xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="244959" is="div"><Text text="Slow-carb next level shoindcgoitch ethical authentic, poko scenester" /></Element>} </h1>
      <p class="leading-relaxed mt-4"> {<Element canvas style={{display:"inline-block"}} id="139113" is="div"><Text text="Poke slow-carb mixtape knausgaard, typewriter street art gentrify hammock starladder roathse. Craies vegan tousled etsy austin." /></Element>} </p>
    </div>
    <div class="lg:w-2/6 md:w-1/2 bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
      <form>
        <h2 class="text-gray-900 text-lg font-medium title-font mb-5"> {<Element canvas style={{display:"inline-block"}} id="991636" is="div"><Text text="Sign Up" /></Element>} </h2>
        <div class="relative mb-4">
          <label for="full-name" class="leading-7 text-sm text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="353990" is="div"><Text text="Full Name" /></Element>} </label>
          <input type="text" id="full-name" name="full-name" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
        </div>
        <div class="relative mb-4">
          <label for="email" class="leading-7 text-sm text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="573500" is="div"><Text text="Email" /></Element>} </label>
          <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
        </div>
        <Element canvas style={{ width: "100%" }} id={"div249981"} is="div">
            <Button classn={"text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"}>
               {<Element canvas style={{display:"inline-block"}} id="713295" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
        <p class="text-xs text-gray-500 mt-3"> {<Element canvas style={{display:"inline-block"}} id="465785" is="div"><Text text="Literally you probably haven't heard of them jean shorts." /></Element>} </p>
      </form>
    </div>
  </div>
    </section>

      </div>;
};
Cta14.craft = {
  displayName: "Cta 14"
};