import React, { useEffect } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import ACTION_LOGIN_EMAIL_RESET from '../../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET';
import ACTION_LOGIN_TEMPO_NOT_INVALID from '../../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID';
import ACTION_LOGIN_EMAIL_NOT_INVALID from '../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID';
import { DataSpinner } from '../../spinner/dataSpinner';
import sideLogo from "../../../../assests/images/sideLogo.svg"
import Dropdown from 'react-dropdown';
import arrowDownDark from "../../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../../assests/images/arrowUp.svg"
import addWorkspaceImg from "../../../../assests/images/addTeamImg.svg"
import addDepartmentImg from "../../../../assests/images/addDepartmentImg.svg"
import updated from "../../../../assests/images/updated.svg"



const AddWorkspaceModal = (props) => {

  const dispatch = useDispatch()
  
    const {
        user,
        open,
        setOpen,
        addWorkspace,
        updateWorkspace,
        updateDepartment,
        workspaceName,
        setWorkspaceName,
        workspaceNameError,
        setWorkspaceNameError,
        usersFilter,
        usersSearchWork,
        setUsersSearchWork,
        userSearchError,
        setUsersSearchWorkError,
        addUserToWorkspace,
        usersAtWorkspace,
        addDepartment,
        setUsersAtWorkspace,
        addWorkspaceLoading,
        addWorkspaceData,
        deleteUserFromWorkspace,
        setAddWorkspaceData 

    } = props

    useEffect(() => {
      if(addWorkspaceData) {
        /////console.log(addWorkspaceData)
      }
    },[addWorkspaceData])

    useEffect(() => {
      if(usersFilter) {
        /////console.log(usersFilter)
      }
    },[usersFilter])

    const deleteUser = (_id) => {
      setUsersAtWorkspace([...usersAtWorkspace.filter(x => x._id !== _id)])
      deleteUserFromWorkspace(_id)
    }
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? !addWorkspaceLoading ? addWorkspaceData ? (
          <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"70%", minWidth:"fit-content", alignItems:"center"}}>
            {
              !open.includes("Edit") ? (
                <img src={addWorkspaceImg} style={{marginTop:"3rem"}}/>
              ) : (
                <img src={updated} style={{marginTop:"3rem"}}/>
              )
            }
            <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setWorkspaceName("")
                    setAddWorkspaceData && setAddWorkspaceData(null)

                }}
              />   
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{workspaceName} {open.includes("Edit") ? "updated" : "added"} successfully</h1>
          <h1 className="subHeader" style={{width:"100%", justifyContent:"center", display:"flex"}}>View {"workspace"} workspaces, add users and assign an admin
        on the {workspaceName} page.</h1>
        <div
              className="auth-button addUser"
              onClick={() => {
                setOpen(null)
                setWorkspaceName("")
                setAddWorkspaceData && setAddWorkspaceData(null)
              }}
            >
              
            Continue
        </div>  
          </div>
        ) : <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%", width:"45vw"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setWorkspaceNameError("")
                    setUsersSearchWork("")
                    setUsersSearchWorkError("")
                   !open.includes("Edit") && setUsersAtWorkspace(null)
                    setWorkspaceName("")
                }}
              />  
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? open : `Add a ${open}`}</h1>
          <div className="form-group-container task-form">
          <div>
          
          <div className="auth-group">
                    <TextInput 
                      type={`${"workspaceName"}`}
                      label={`${"workspace Name*"}`}
                      value={workspaceName}
                      setValue={setWorkspaceName}
                      placeHolder={`Enter name of new ${"workspace"}`}
                      error={workspaceNameError}
                      setError={setWorkspaceNameError} 
                    />
                    </div>
                    <div className="auth-group" style={{position:"relative"}}>
                    <TextInput 
                      type="addUsers"
                      label="Add Users"
                      nextLabel={usersAtWorkspace ? usersAtWorkspace.length : 0}
                      value={usersSearchWork ? usersSearchWork : ""}
                      setValue={setUsersSearchWork}
                      placeHolder={`Enter the names of the groups or users you want to add to the ${"workspace"}`}
                      error={userSearchError}
                      setError={setUsersSearchWorkError} 
                    />
                     { usersSearchWork && usersFilter && usersFilter.length > 0 &&<div className="orgsC" style={{position:"absolute", maxHeight:"200px", width:"100%", top:"110%", borderRadius:"8px", backgroundColor:"white", zIndex:"2"}}>
                        <div className="orgsContainers">
                        {usersFilter.map(y => (
                          <div onClick={() => {
                              setUsersSearchWork("")
                              addUserToWorkspace(y)
                          }} className="oneOrg">
                        <img height="45px" src={sideLogo} style={{marginRight:"12px"}} title={y.name} />
                        <div className="oneOrgDetails">
                            <div className="orgTitle">{y.name}</div>
                            <div className="userTitle">{y.role}</div>
                        </div>

                        </div>  
                        ))}
                        </div>
                    </div>}
                    </div>
                    <div className="auth-group">
                    {usersAtWorkspace && <div className="orgsContainers"  style={{overflowY:"visible"}}>
                        {usersAtWorkspace.map(y => (
                          <div style={{display:"flex", justifyContent:"space-between", width:"100%", alignItems:"center"}}>
                          <div style={{minWidth:"30%"}}>
                          <div onClick={() => {
                          }} className="disableHoverOneOrg">
                        <img height="45px" src={sideLogo} style={{marginRight:"12px"}} title={y.name} />
                        <div className="oneOrgDetails">
                            <div className="orgTitle">{y.name} {user.user._id == y._id && "(You)"} </div>
                        </div>
                        </div> 
                        </div> 
                        <div>
                        {user.user._id == y._id ? (
                          <div className='ownerTitle'>Editor</div>
                        ) : (
                          <Dropdown
                            options={["editor", "viewer", { value: 'Delete', label: 'Delete', className: 'DeleteHoverButton' }]}
                            controlClassName="adminButton moreWidth" 
                            menuClassName='underMenuu'
                            arrowClosed={<span className="arrow-closed arrowI" ><img
                            className="routeIcon forceRight"
                            src={arrowDownDark}
                            /></span>}
                            arrowOpen={<span className="arrow-open arrowI" ><img
                            className="routeIcon forceRight"
                            src={arrowUp}
                            /></span>}
                            placeholder={"Role"}
                            value={y.role }
                            onChange={(e) => {
                              e.value !== "Delete" ? setUsersAtWorkspace(usersAtWorkspace.map(t => {
                                if(t._id === y._id) {
                                  !open.includes("Edit") ? y.role = e.value : y.groupRole = e.value
                                  return y
                                } else {
                                  return t
                                }
                              })) : (
                               !usersAtWorkspace ? setUsersAtWorkspace(null) : open.includes("Edit") ? deleteUser(y._id) : setUsersAtWorkspace([...usersAtWorkspace.filter(x => x._id !== y._id)])
                              ) 
                            }}
                          />
                        )}
                        </div>
                        </div>
                        ))}
                        </div>}
                    </div>    
          </div>
          </div>
            <div
              className={`${workspaceName ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={workspaceName ?  open.includes("Edit") ? updateWorkspace : addWorkspace : null }
            >
              
            {open.includes("Edit") ? "Update" : `Add a ${"Workspace"}`}
            </div>
        </div> : (<DataSpinner />) : (<></>)}
      </Modal>
  )}

  export default AddWorkspaceModal
       