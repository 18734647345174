import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Statistics2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <div class="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
      <div class="w-full sm:p-4 px-4 mb-6">
        <h1 class="title-font font-medium text-xl mb-2 text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="446703" is="div"><Text text="Moon hashtag pop-up try-hard offal truffaut" /></Element>} </h1>
        <div class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="837571" is="div"><Text text="Pour-over craft beer pug drinking vinegar live-edge gastropub, keytar neutra sustainable fingerstache kickstarter." /></Element>} </div>
      </div>
      <div class="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
        <h2 class="title-font font-medium text-3xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="821129" is="div"><Text text="2.7K" /></Element>} </h2>
        <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="957286" is="div"><Text text="Users" /></Element>} </p>
      </div>
      <div class="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
        <h2 class="title-font font-medium text-3xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="523049" is="div"><Text text="1.8K" /></Element>} </h2>
        <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="117828" is="div"><Text text="Subscribes" /></Element>} </p>
      </div>
      <div class="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
        <h2 class="title-font font-medium text-3xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="289249" is="div"><Text text="35" /></Element>} </h2>
        <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="404318" is="div"><Text text="Downloads" /></Element>} </p>
      </div>
      <div class="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
        <h2 class="title-font font-medium text-3xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="370152" is="div"><Text text="4" /></Element>} </h2>
        <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="631873" is="div"><Text text="Products" /></Element>} </p>
      </div>
    </div>
    <div class="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
      <Element canvas style={{ width: "100%" }} id={"div484548"} is="div">
          <ImageC class="object-cover object-center w-full h-full" src="https://dummyimage.com/600x300" alt="stats"  />
        </Element>
    </div>
  </div>
    </section>

      </div>;
};
Statistics2.craft = {
  displayName: "Statistics 2"
};