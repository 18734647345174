import { gql } from "apollo-boost";

const updateViewMutation = gql`
  mutation(
    $_id: ID, 
    $org: ID,
    $name: String,
    $html: String,
    $css: String,
    $user: ID,
    $users: [String],
$editors: [String]
  ) {
    updateView( 
    _id: $_id,
    org:$org,
    name:$name,
    html:$html,
    css:$css,
    user: $user
    users:$users
    editors: $editors

    ) {
    _id
    org,
    name,
    user,
    users
    html
    css
        editors

    }
  }
`;

export default updateViewMutation;
