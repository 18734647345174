import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Portfolio1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="354414" is="div"><Text text="Our
            Portfolio" /></Element>} </h1>

        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 lg:grid-cols-2">
            <div class="flex items-end overflow-hidden bg-cover">
                <div class="w-full px-8 py-4 overflow-hidden backdrop-blur-sm bg-white/60 dark:bg-gray-800/60 mb-auto">
                    <Element canvas style={{ width: "100%" }} id={"div785192"} is="div">
          <ImageC class="rounded-lg h-36 object-cover" src="https://images.unsplash.com/photo-1621111848501-8d3634f82336?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1565&q=80"  />
        </Element>
                    <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="387594" is="div"><Text text="Best website collections" /></Element>} </h2>
                    <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="705021" is="div"><Text text="Website" /></Element>} </p>
                </div>
            </div>

            <div class="flex items-end overflow-hidden bg-cover">
                <div class="w-full px-8 py-4 overflow-hidden backdrop-blur-sm bg-white/60 dark:bg-gray-800/60 mb-auto">
                    <Element canvas style={{ width: "100%" }} id={"div155292"} is="div">
          <ImageC class="rounded-lg h-36 object-cover" src="https://images.unsplash.com/photo-1621609764180-2ca554a9d6f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80"  />
        </Element>
                    <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="861496" is="div"><Text text="Block of Ui kit collections" /></Element>} </h2>
                    <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="701189" is="div"><Text text="Ui kit" /></Element>} </p>
                </div>
            </div>

            <div class="flex items-end overflow-hidden bg-cover">
                <div class="w-full px-8 py-4 overflow-hidden backdrop-blur-sm bg-white/60 dark:bg-gray-800/60 mb-auto">
                    <Element canvas style={{ width: "100%" }} id={"div663052"} is="div">
          <ImageC class="rounded-lg h-36 object-cover" src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"  />
        </Element>
                    <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="104479" is="div"><Text text="Ton's of mobile mockup" /></Element>} </h2>
                    <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="528677" is="div"><Text text="Mockups" /></Element>} </p>
                </div>
            </div>

            <div class="flex items-end overflow-hidden bg-cover">
                <div class="w-full px-8 py-4 overflow-hidden backdrop-blur-sm bg-white/60 dark:bg-gray-800/60 mb-auto">
                    <Element canvas style={{ width: "100%" }} id={"div939063"} is="div">
          <ImageC class="rounded-lg h-36 object-cover" src="https://images.unsplash.com/photo-1603380353725-f8a4d39cc41e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"  />
        </Element>
                    <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="734990" is="div"><Text text="Huge collection of animation" /></Element>} </h2>
                    <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="701259" is="div"><Text text="Animation" /></Element>} </p>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Portfolio1.craft = {
  displayName: "Portfolio 1"
};