import { FormControl, InputLabel, Select } from '@material-ui/core';
import React from 'react';
import Switch from "react-switch"

export const ToolbarSwitch = ({ title, value, onChange, children }) => {
  return (
    <div style={{display:"flex", justifyContent:"space-between"}}>
      <div style={{fontWeight:"bold", fontSize:"16px"}}>{title}</div>
      <Switch height={20} width={45} onChange={(e) => onChange(!value)} checked={value} />
    </div>
  );
};
