import React, { useEffect, useState } from "react";
import {  Link, useLocation, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";

import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

import loginQuery from "../../../graphql/queries/user/loginQuery";

import ACTION_LOGIN_IS_NOT_ACTIVE from "../../../actions/user/Login/ACTION_LOGIN_IS_NOT_ACTIVE";
import ACTION_LOGIN_IS_ACTIVE from "../../../actions/user/Login/ACTION_LOGIN_IS_ACTIVE";
import ACTION_LOGIN_EMAIL_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_TEMPO_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_INVALID";
import ACTION_LOGIN_PASSWORD_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_INVALID";
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import ACTION_LOGIN_TEMPO_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_TEMPORARY_DUMMY_TOKEN_RESET from "../../../actions/user/Login/TemporaryDummyToken/ACTION_TEMPORARY_DUMMY_TOKEN_RESET";


import LoginEmail from "./LoginEmail/LoginEmail";
import LoginPassword from "./LoginPassword/LoginPassword";
import UserNewPassword from "./NewPassword/UserNewPassword";
import UserConfirmNewPassword from "./NewPassword/UserConfirmNewPassword";
import updateUserPasswordMutation from "../../../graphql/mutations/user/updateUserPasswordMutation"
import addOrganizationMutation from "../../../graphql/mutations/organization/addOrganizationMutation"


import "./Auth.scss"
import 'react-dropdown/style.css';

import ForgetPassword from "./ForgetPassword";
import resetPasswordMutation from "../../../graphql/mutations/user/resetPasswordMutation";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";


import illustration from "../../../assests/images/illustration.png"
import logo from "../../../assests/images/Logo.png"
import google from "../../../assests/icons/google.png"
import ACTION_LOGIN_EMAIL from "../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL";
import TextInput from "./TextInput";
import Dropdown from "react-dropdown";

import arrowUp from "../../../assests/images/arrowUp.svg"
import arrowDownDark from "../../../assests/images/arrowDownDark.svg"
import ACTION_LOGIN_EMAIL_RESET from "../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET";
import resetOrganizationMutation from "../../../graphql/mutations/organization/resetOrganizationMutation";
import Multiselect from "multiselect-react-dropdown";

const Login = ({user,directEmail,directPassword, industrys}) => {

  console.log(Cookies.get("temporary-dummy-token"), "from direct")

  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  const loginEmail = useSelector((state) => state.loginEmail.login_email);
  const loginEmailInvalid = useSelector(
    (state) => state.loginEmailInvalid.login_email_invalid
  );
  const loginTempoInvalid = useSelector(
    state => state.loginTempoInvalid.login_tempo_invalid
  );
  const loginPassword = useSelector(
    (state) => state.loginPassword.login_password
  );
  const loginPasswordInvalid = useSelector(
    (state) => state.loginPasswordInvalid.login_password_invalid
  );
  const registeredUserFound = useSelector(
    (state) => state.registeredUserFound.registered_user_found
  );

  const confirmNewPassword = useSelector(
    (state) => state.confirmNewPassword.confirm_new_password
  );
  const newPasswordValid = useSelector(
    (state) => state.newPasswordValid.new_password_valid
  );
  const confirmNewPasswordValid = useSelector(
    (state) =>
      state.confirmNewPasswordValid.confirm_new_password_valid
  );
  const userAuthenticated = useSelector(
    (state) => state.userAuthenticated.user_authenticated
  );

  const temporaryDummyToken = useSelector((state) => state.temporaryDummyToken.temporary_dummy_token);
  const [resetOrganization, {error: resetOrganizationError, data: resetOrganizationData, loading: resetOrganizationLoading}] = useMutation(resetOrganizationMutation)

  const resetEveryThing = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    resetOrganization().then(() => {
      dispatch(ACTION_LOADING_SPINNER_RESET())
    })
  }
  const resetUser = () => { 
    Cookies.remove("temporary-dummy-token")
    dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET())

  }
  const handleUserLogin = (user) => {
    
if(user.login.temporaryToken) {
  Cookies.set("temporary-dummy-token", user.login.temporaryToken)
}
if(user.login.dummyToken) {
  Cookies.set("dummy-token", user.login.dummyToken)
}

if(user.login.accessToken) {
  Cookies.set("access-token", user.login.accessToken)
}


    if (registeredUserFound) {
      if (loginEmailInvalid) {
        dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
      }

      if (loginPasswordInvalid) {
        dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
      } 
      if(loginTempoInvalid) {
        dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID())
      }
    }

  };

  const handleUserLoginError = () => {
    if (error) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
      /////console.log(error.message)
      if (error.message) {
        if (error.message.includes("email")) {
          dispatch(ACTION_LOGIN_EMAIL_INVALID());
          dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
          dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID());
        } else if (error.message.includes("temporarily")) {
          dispatch(ACTION_LOGIN_TEMPO_INVALID());
          dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
          dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
        } else {
          dispatch(ACTION_LOGIN_PASSWORD_INVALID());
          dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
          dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID());

        }
      }
    }
  };

  const [loginUser, { error }] = useLazyQuery(loginQuery, {
    fetchPolicy: "no-cache",
    onCompleted: handleUserLogin,
    onError: handleUserLoginError,
  });


  const handleLoginClick = (e) => {
    e && e.preventDefault()
    loginUser({
      variables: {
        email: loginEmail,
        password: loginPassword,
      },
    });
  };




  useEffect(() => {
    if (location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);


  useEffect(() => {
    dispatch(ACTION_LOGIN_IS_ACTIVE());
    return () => {
      dispatch(ACTION_LOGIN_IS_NOT_ACTIVE());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
      dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
      dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID());
    };
  }, [dispatch]);
 

const [
  updateUserPassword,
  { loading: updateUserPasswordLoading, data: updateUserPasswordData },
] = useMutation(updateUserPasswordMutation)

const [
  addOrganization,
  { loading: addOrganizationLoading, data: addOrganizationData },
] = useMutation(addOrganizationMutation)


const handleChangePasswordClick = (e) => {
  e && e.preventDefault()
if(onBoardingActive) {
   if( orgName && industry && timeZone && language && currency) {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  console.log(Cookies.get("temporary-dummy-token"), 10910919)
  updateUserPassword({
    variables: { password: confirmNewPassword, temporaryToken:Cookies.get("temporary-dummy-token") },
  })
}
} else {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  updateUserPassword({
    variables: { password: confirmNewPassword, temporaryToken:Cookies.get("temporary-token") },
  })
}
};

useEffect(() => {
if(directEmail&&directPassword) {
  /////console.log(directEmail)
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  dispatch(ACTION_LOGIN_EMAIL(directEmail.trim().toLowerCase()))
  loginUser({
    variables: {
      email: directEmail,
      password: directPassword,
    },
  });
}
},[directEmail,directPassword])

useEffect(() => {
  if (!updateUserPasswordLoading) {
    if (updateUserPasswordData) {
      /////console.log(updateUserPasswordData)
      if(user.user.role == "superAdmin") { 
        dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET())
        loginUser({
          variables: {
            email: loginEmail,
            password: confirmNewPassword,
          },
        });  
        dispatch(ACTION_LOGIN_EMAIL_RESET())  
      } else {
        addOrganization({
        variables:{
          name:orgName,
          owner: updateUserPasswordData.updateUserPassword._id,
          email: loginEmail,
          password: confirmNewPassword,
          industry,
          language: JSON.stringify(allLan),
          currency,
          timeZone,

        }
      })
      }
      
    }
  }
}, [
  updateUserPasswordLoading,
  confirmNewPassword,
  loginUser,
  updateUserPasswordData,
  dispatch
]);


useEffect(() => {
  if (!addOrganizationLoading) {
    if (addOrganizationData) {
      /////console.log("token reset")
      dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET())
      loginUser({
        variables: {
          email: loginEmail,
          password: confirmNewPassword,
        },
      });  
      dispatch(ACTION_LOGIN_EMAIL_RESET())   
    }
  }
}, [
  addOrganizationLoading,
  loginUser,
  addOrganizationData,
  dispatch
]);



// reset password

const [
  resetPassword,
  { data: resetPasswordData },
] = useMutation(resetPasswordMutation)
 

const [resetPasswordModal, setResetPasswordModal] = useState(false)
const [userEmail, setUserEmail] = useState("")

const forgetPassword = () => {
  /////console.log(loginEmail)
if(loginEmail) {
   dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  resetPassword({
    variables:{
      email: loginEmail
    }
  })
}
 
}

useEffect(() => {
  if(resetPasswordData) {
    setForgotPasswordDone(true)
    dispatch(ACTION_LOADING_SPINNER_RESET())
  }
},[resetPasswordData, dispatch])

useEffect(() => {
if(userAuthenticated) {
  history.push("/admin/dashboard")
  dispatch(ACTION_LOADING_SPINNER_RESET())
} else {
  dispatch(ACTION_LOADING_SPINNER_RESET())
}
},[userAuthenticated])

const [checked, setChecked] = useState(false)

const [forgotPassword, setForgotPassword] = useState(false)
const [forgotPasswordDone, setForgotPasswordDone] = useState(false)
const [onBoardingActive, setOnBoardingActive] = useState(false)

const handleOnBoargingFunc = () => {
  setOnBoardingActive(true)
}

// get started information

const [orgName, setOrgName] = useState("")

const [orgError, setOrgError] = useState("")

const [industries, setIndustries] = useState([])
const [industry, setIndustry] = useState("")

let timeZones = [
  { label: <div>-12:00 International Date Line West (Etc/GMT+12)</div>,
   value: "-12:00 International Date Line West (Etc/GMT+12)" },
  { label: <div>-11:00 Midway Island, Samoa (Pacific/Midway)</div>,
   value: "-11:00 Midway Island, Samoa (Pacific/Midway)" },
  { label: <div>-10:00 Hawaii (Pacific/Honolulu)</div>,
   value: "-10:00 Hawaii (Pacific/Honolulu)" },
  { label: <div>-09:00 Alaska (US/Alaska)</div>,
   value: "-09:00 Alaska (US/Alaska)" },
  { label: <div>-08:00 Pacific Time (US & Canada) (America/Los_Angeles)</div>,
   value: "-08:00 Pacific Time (US & Canada) (America/Los_Angeles)" },
  { label: <div>-08:00 Tijuana, Baja California (America/Tijuana)</div>,
   value: "-08:00 Tijuana, Baja California (America/Tijuana)" },
  { label: <div>-07:00 Arizona (US/Arizona)</div>,
   value: "-07:00 Arizona (US/Arizona)" },
  { label: <div>-07:00 Chihuahua, La Paz, Mazatlan (America/Chihuahua)</div>,
   value: "-07:00 Chihuahua, La Paz, Mazatlan (America/Chihuahua)" },
  { label: <div>-07:00 Mountain Time (US & Canada) (US/Mountain)</div>,
   value: "-07:00 Mountain Time (US & Canada) (US/Mountain)" },
  { label: <div>-06:00 Central America (America/Managua)</div>,
   value: "-06:00 Central America (America/Managua)" },
  { label: <div>-06:00 Central Time (US & Canada) (US/Central)</div>,
   value: "-06:00 Central Time (US & Canada) (US/Central)" },
  { label: <div>-06:00 Guadalajara, Mexico City, Monterrey (America/Mexico_City)</div>,
   value: "-06:00 Guadalajara, Mexico City, Monterrey (America/Mexico_City)" },
  { label: <div>-06:00 Saskatchewan (Canada/Saskatchewan)</div>,
   value: "-06:00 Saskatchewan (Canada/Saskatchewan)" },
  { label: <div>-05:00 Bogota, Lima, Quito, Rio Branco (America/Bogota)</div>,
   value: "-05:00 Bogota, Lima, Quito, Rio Branco (America/Bogota)" },
  { label: <div>-05:00 Eastern Time (US & Canada) (US/Eastern)</div>,
   value: "-05:00 Eastern Time (US & Canada) (US/Eastern)" },
  { label: <div>-05:00 Indiana (East) (US/East-Indiana)</div>,
   value: "-05:00 Indiana (East) (US/East-Indiana)" },
  { label: <div>-04:00 Atlantic Time (Canada) (Canada/Atlantic)</div>,
   value: "-04:00 Atlantic Time (Canada) (Canada/Atlantic)" },
  { label: <div>-04:00 Caracas, La Paz (America/Caracas)</div>,
   value: "-04:00 Caracas, La Paz (America/Caracas)" },
  { label: <div>-04:00 Manaus (America/Manaus)</div>,
   value: "-04:00 Manaus (America/Manaus)" },
  { label: <div>-04:00 Santiago (America/Santiago)</div>,
   value: "-04:00 Santiago (America/Santiago)" },
  { label: <div>-03:30 Newfoundland (Canada/Newfoundland)</div>,
   value: "-03:30 Newfoundland (Canada/Newfoundland)" },
  { label: <div>-03:00 Brasilia (America/Sao_Paulo)</div>,
   value: "-03:00 Brasilia (America/Sao_Paulo)" },
  { label: <div>-03:00 Buenos Aires, Georgetown (America/Argentina/Buenos_Aires)</div>,
   value: "-03:00 Buenos Aires, Georgetown (America/Argentina/Buenos_Aires)" },
  { label: <div>-03:00 Greenland (America/Godthab)</div>,
   value: "-03:00 Greenland (America/Godthab)" },
  { label: <div>-03:00 Montevideo (America/Montevideo)</div>,
   value: "-03:00 Montevideo (America/Montevideo)" },
  { label: <div>-02:00 Mid-Atlantic (America/Noronha)</div>,
   value: "-02:00 Mid-Atlantic (America/Noronha)" },
  { label: <div>-01:00 Cape Verde Is. (Atlantic/Cape_Verde)</div>,
   value: "-01:00 Cape Verde Is. (Atlantic/Cape_Verde)" },
  { label: <div>-01:00 Azores (Atlantic/Azores)</div>,
   value: "-01:00 Azores (Atlantic/Azores)" },
  { label: <div>+00:00 Casablanca, Monrovia, Reykjavik (Africa/Casablanca)</div>,
   value: "+00:00 Casablanca, Monrovia, Reykjavik (Africa/Casablanca)" },
  { label: <div>+00:00 Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (Etc/Greenwich)</div>,
   value: "+00:00 Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (Etc/Greenwich)" },
  { label: <div>+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm,Vienna (Europe/Amsterdam)</div>,
   value: "+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm,Vienna (Europe/Amsterdam)" },
  { label: <div>+01:00 Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Belgrade)</div>,
   value: "+01:00 Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Belgrade)" },
  { label: <div>+01:00 Brussels, Copenhagen, Madrid, Paris (Europe/Brussels)</div>,
   value: "+01:00 Brussels, Copenhagen, Madrid, Paris (Europe/Brussels)" },
  { label: <div>+01:00 Sarajevo, Skopje, Warsaw, Zagreb (Europe/Sarajevo)</div>,
   value: "+01:00 Sarajevo, Skopje, Warsaw, Zagreb (Europe/Sarajevo)" },
  { label: <div>+01:00 West Central Africa (Africa/Lagos)</div>,
   value: "+01:00 West Central Africa (Africa/Lagos)" },
  { label: <div>+02:00 Amman (Asia/Amman)</div>,
   value: "+02:00 Amman (Asia/Amman)" },
  { label: <div>+02:00 Athens, Bucharest, Istanbul (Europe/Athens)</div>,
   value: "+02:00 Athens, Bucharest, Istanbul (Europe/Athens)" },
  { label: <div>+02:00 Beirut (Asia/Beirut)</div>,
   value: "+02:00 Beirut (Asia/Beirut)" },
  { label: <div>+02:00 Cairo (Africa/Cairo)</div>,
   value: "+02:00 Cairo (Africa/Cairo)" },
  { label: <div>+02:00 Harare, Pretoria (Africa/Harare)</div>,
   value: "+02:00 Harare, Pretoria (Africa/Harare)" },
  { label: <div>+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Helsinki)</div>,
   value: "+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Helsinki)" },
  { label: <div>+02:00 Jerusalem (Asia/Jerusalem)</div>,
   value: "+02:00 Jerusalem (Asia/Jerusalem)" },
  { label: <div>+02:00 Minsk (Europe/Minsk)</div>,
   value: "+02:00 Minsk (Europe/Minsk)" },
  { label: <div>+02:00 Windhoek (Africa/Windhoek)</div>,
   value: "+02:00 Windhoek (Africa/Windhoek)" },
  { label: <div>+03:00 Kuwait, Riyadh, Baghdad (Asia/Kuwait)</div>,
   value: "+03:00 Kuwait, Riyadh, Baghdad (Asia/Kuwait)" },
  { label: <div>+03:00 Moscow, St. Petersburg, Volgograd (Europe/Moscow)</div>,
   value: "+03:00 Moscow, St. Petersburg, Volgograd (Europe/Moscow)" },
  { label: <div>+03:00 Nairobi (Africa/Nairobi)</div>,
   value: "+03:00 Nairobi (Africa/Nairobi)" },
  { label: <div>+03:00 Tbilisi (Asia/Tbilisi)</div>,
   value: "+03:00 Tbilisi (Asia/Tbilisi)" },
  { label: <div>+03:30 Tehran (Asia/Tehran)</div>,
   value: "+03:30 Tehran (Asia/Tehran)" },
  { label: <div>+04:00 Abu Dhabi, Muscat (Asia/Muscat)</div>,
   value: "+04:00 Abu Dhabi, Muscat (Asia/Muscat)" },
  { label: <div>+04:00 Baku (Asia/Baku)</div>,
   value: "+04:00 Baku (Asia/Baku)" },
  { label: <div>+04:00 Yerevan (Asia/Yerevan)</div>,
   value: "+04:00 Yerevan (Asia/Yerevan)" },
  { label: <div>+04:30 Kabul (Asia/Kabul)</div>,
   value: "+04:30 Kabul (Asia/Kabul)" },
  { label: <div>+05:00 Yekaterinburg (Asia/Yekaterinburg)</div>,
   value: "+05:00 Yekaterinburg (Asia/Yekaterinburg)" },
  { label: <div>+05:00 Islamabad, Karachi, Tashkent (Asia/Karachi)</div>,
   value: "+05:00 Islamabad, Karachi, Tashkent (Asia/Karachi)" },
  { label: <div>+05:30 Chennai, Kolkata, Mumbai, New Delhi (Asia/Calcutta)</div>,
   value: "+05:30 Chennai, Kolkata, Mumbai, New Delhi (Asia/Calcutta)" },
  { label: <div>+05:30 Sri Jayawardenapura (Asia/Calcutta)</div>,
   value: "+05:30 Sri Jayawardenapura (Asia/Calcutta)" },
  { label: <div>+05:45 Kathmandu (Asia/Katmandu)</div>,
   value: "+05:45 Kathmandu (Asia/Katmandu)" },
  { label: <div>+06:00 Almaty, Novosibirsk (Asia/Almaty)</div>,
   value: "+06:00 Almaty, Novosibirsk (Asia/Almaty)" },
  { label: <div>+06:00 Astana, Dhaka (Asia/Dhaka)</div>,
   value: "+06:00 Astana, Dhaka (Asia/Dhaka)" },
  { label: <div>+06:30 Yangon (Rangoon) (Asia/Rangoon)</div>,
   value: "+06:30 Yangon (Rangoon) (Asia/Rangoon)" },
  { label: <div>+07:00 Bangkok, Hanoi, Jakarta (Asia/Bangkok)</div>,
   value: "+07:00 Bangkok, Hanoi, Jakarta (Asia/Bangkok)" },
  { label: <div>+07:00 Krasnoyarsk (Asia/Krasnoyarsk)</div>,
   value: "+07:00 Krasnoyarsk (Asia/Krasnoyarsk)" },
  { label: <div>+08:00 Beijing, Chongqing, Hong Kong, Urumqi (Asia/Hong_Kong)</div>,
   value: "+08:00 Beijing, Chongqing, Hong Kong, Urumqi (Asia/Hong_Kong)" },
  { label: <div>+08:00 Kuala Lumpur, Singapore (Asia/Kuala_Lumpur)</div>,
   value: "+08:00 Kuala Lumpur, Singapore (Asia/Kuala_Lumpur)" },
  { label: <div>+08:00 Irkutsk, Ulaan Bataar (Asia/Irkutsk)</div>,
   value: "+08:00 Irkutsk, Ulaan Bataar (Asia/Irkutsk)" },
  { label: <div>+08:00 Perth (Australia/Perth)</div>,
   value: "+08:00 Perth (Australia/Perth)" },
  { label: <div>+08:00 Taipei (Asia/Taipei)</div>,
   value: "+08:00 Taipei (Asia/Taipei)" },
  { label: <div>+09:00 Osaka, Sapporo, Tokyo (Asia/Tokyo)</div>,
   value: "+09:00 Osaka, Sapporo, Tokyo (Asia/Tokyo)" },
  { label: <div>+09:00 Seoul (Asia/Seoul)</div>,
   value: "+09:00 Seoul (Asia/Seoul)" },
  { label: <div>+09:00 Yakutsk (Asia/Yakutsk)</div>,
   value: "+09:00 Yakutsk (Asia/Yakutsk)" },
  { label: <div>+09:30 Adelaide (Australia/Adelaide)</div>,
   value: "+09:30 Adelaide (Australia/Adelaide)" },
  { label: <div>+09:30 Darwin (Australia/Darwin)</div>,
   value: "+09:30 Darwin (Australia/Darwin)" },
  { label: <div>+10:00 Brisbane (Australia/Brisbane)</div>,
   value: "+10:00 Brisbane (Australia/Brisbane)" },
  { label: <div>+10:00 Canberra, Melbourne, Sydney (Australia/Canberra)</div>,
   value: "+10:00 Canberra, Melbourne, Sydney (Australia/Canberra)" },
  { label: <div>+10:00 Hobart (Australia/Hobart)</div>,
   value: "+10:00 Hobart (Australia/Hobart)" },
  { label: <div>+10:00 Guam, Port Moresby (Pacific/Guam)</div>,
   value: "+10:00 Guam, Port Moresby (Pacific/Guam)" },
  { label: <div>+10:00 Vladivostok (Asia/Vladivostok)</div>,
   value: "+10:00 Vladivostok (Asia/Vladivostok)" },
  { label: <div>+11:00 Magadan, Solomon Is., New Caledonia (Asia/Magadan)</div>,
   value: "+11:00 Magadan, Solomon Is., New Caledonia (Asia/Magadan)" },
  { label: <div>+12:00 Auckland, Wellington (Pacific/Auckland)</div>,
   value: "+12:00 Auckland, Wellington (Pacific/Auckland)" },
  { label: <div>+12:00 Fiji, Kamchatka, Marshall Is. (Pacific/Fiji)</div>,
   value: "+12:00 Fiji, Kamchatka, Marshall Is. (Pacific/Fiji)" },
  { label: <div>+13:00 Nuku'alofa (Pacific/Tongatapu)</div>,
   value: "+13:00 Nuku'alofa (Pacific/Tongatapu)" }
]
const [timeZone, setTimeZone] = useState("+00:00")
const languages = [
  {name:"English", id:1},
  {name:"Italian", id:2},
  {name:"Spanish", id:3},
  {name:"German", id:4},
  {name:"Arabic", id:5}
]

const [allLan, setAllLan] = useState([])
// const addLanguage = (lan) => {
//     /////console.log(lan)
//     /////console.log(allLan)
//     if(allLan.length > 0 && allLan.some(x=> x== lan)) {
//         /////console.log("here")
//         setAllLan(allLan.filter(x => x !== lan))
//     } else {
//         let newA = [...allLan.push(lan)]
//         setAllLan(newA)
//     }
// }

const onSelectFunc = (selectedList, selectedItem) => {
/////console.log(selectedList, selectedItem)
setAllLan(selectedList)
}

const onRemoveFunc = (selectedList, removedItem) => {
    /////console.log(selectedList, removedItem)
    setAllLan(selectedList)
}

const [language, setLanguage] = useState("English")
const currencies = ['USD', "EUR", "GBP", "CHF", "EG"] 
const [currency, setCurrency] = useState("USD")

useEffect(() => {
    if(industrys) {
      setIndustries(industrys.allIndustrys.map(x => x.name))
    }
},[industrys])
  return (
    <div className="signinPageContainer">
      <div className=" signinPageContainer singinPageContent">
      <ForgetPassword 
        resetPasswordModal={resetPasswordModal}
        setResetPasswordModal={setResetPasswordModal} 
        setUserEmail={setUserEmail}
        userEmail={userEmail}
        forgetPassword={forgetPassword}
      />
      <div className="signinContainer">
        <div className="logoContainer">
          <img src={logo} width={48} />
          {!temporaryDummyToken && !onBoardingActive ? <div onClick={() => {
            history.push("/admin/signup")
          }} style={{width:"125px", marginTop:"0px", marginBottom:"0px", background:"transparent", border:"1px solid #ABB0B9", color:"black", fontWeight:"600"}} className="auth-button hoverS">
            sign up
          </div> : null}
        </div>
      {forgotPassword ? 
      forgotPasswordDone ? 
        <div className="signinContent">
                  <div className="signinBoxContainer">

        <div className="auth-header">
            Check your email
        </div>
        <div>
                        <div className="doneText">
                        We’ve sent password reset instruction to
                        <span style={{fontWeight:"700"}}>{loginEmail ? loginEmail : "Your email"}</span>
                        </div>
                <div className="doneText">
                If it doesn’t arrive soon, check your spam folder or
    send the email again.
                </div>
                <div className="doneText">Need help? <span style={{fontWeight:"700"}}>Contact support.</span></div>
                <div className="auth-button hoverButton" onClick={() => {
                  setForgotPassword(false)
                  setForgotPasswordDone(false)
                  dispatch(ACTION_LOGIN_EMAIL(""))
                }}>Back to Home</div>
                </div>
          </div>
            </div> : (
              <div className="signinContent">
                <div className="signinBoxContainer" >
              <div className="auth-header">
                  Forgot Password
              </div>
              <div>
                              <div className="doneText">
                              Enter the email associated with your account and we'll send instructions to reset your password.
                              </div>
                      <div className="doneText"><LoginEmail /></div>
                      <div className="auth-button hoverButton"
                      style={{
                        color: temporaryDummyToken
                        ? newPasswordValid && confirmNewPasswordValid
                          ? "rgb(255, 255, 255)"
                          : "white"
                        : loginEmail
                          ? "rgb(255, 255, 255)"
                          : "white",
                        pointerEvents: temporaryDummyToken
                          ? newPasswordValid && confirmNewPasswordValid
                            ? "auto"
                            : "none"
                          : loginEmail
                          ? "auto"
                          : "none",
                        background: temporaryDummyToken
                          ? newPasswordValid && confirmNewPasswordValid
                            ? "#B20312"
                            : "#b2031280"
                          : loginEmail
                          ? "#B20312"
                          : "#b2031280",
                        transition: "background 0.5s ease, color 0.5s ease",
                        width:"100%",
                        minWidth:"140px",
                        marginTop:"1.8rem"
                    }} 
                      onClick={forgetPassword}>Submit</div>
                      </div>
                      </div>
                  </div>
            )
      : ( <> <div className="signinContent">
        <div className="signinBoxContainer">
        {onBoardingActive ? (
        <>
        <div className="auth-header">
        Get started
        </div>
        <div className="doneText" style={{margin:"0px 0px 24px 0px"}}>
        Enter information about your organization so that we can personalize our platform for you.
        </div>
        <div>
          <div className="auth-group">
          <TextInput 
            type="orgName"
            label="Name of your ORG"
            value={orgName}
            setValue={setOrgName}
            placeHolder="Enter org name"
            error={orgError}
            setError={setOrgError} 
          />
          </div>

          <div className="auth-group">
            <div className="auth-label">Industry</div>
          <Dropdown
                  options={industries}
                  controlClassName="auth-input" 
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select industry of your ORG"}
                  value={industry}
                  onChange={(e) => {
                   setIndustry(e.value)
                  }}
                />
          </div>
          <div className="auth-group">
          <div className="auth-label">timeZone</div>
          <Dropdown
                  options={timeZones}
                  menuClassName="underMenu"
                  controlClassName="auth-input"
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select your timezone"}
                  value={timeZone}
                  onChange={(e) => {
                    setTimeZone(e.value)
                  }}
                  
                />
          </div>
          <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
          <div className="auth-group" style={{width:"48%"}}>
          <div className="auth-label">language</div>
          <Multiselect
            options={languages} // Options to display in the dropdown
            selectedValues={allLan} // Preselected value to persist in dropdown
            onSelect={onSelectFunc} // Function will trigger on select event
            onRemove={onRemoveFunc} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
          />
          </div>

          <div className="auth-group" style={{width:"48%"}}>
          <div className="auth-label">currency</div>
          <Dropdown
                  options={currencies}
                  controlClassName="auth-input" 
                  menuClassName="underMenu"
                  arrowClosed={<span className="arrow-closed arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowDownDark}
      /></span>}
                  arrowOpen={<span className="arrow-open arrowI" ><img
                  className="routeIcon forceRight"
                  src={arrowUp}
      /></span>}
                  placeholder={"Select your currency"}
                  value={currency}
                  onChange={(e) => {
                    setCurrency(e.value)
                  }}
                  
                />
          </div>
          </div>
          <div>
            <div
             onClick={
              handleChangePasswordClick
          } 
            style={{
              color: onBoardingActive
              ? orgName && industry && timeZone && language && currency
                ? "rgb(255, 255, 255)"
                : "white"
              : loginEmail && loginPassword
                ? "rgb(255, 255, 255)"
                : "white",
              pointerEvents: onBoardingActive
                ? orgName && industry && timeZone && language && currency
                  ? "auto"
                  : "none"
                : loginEmail && loginPassword
                ? "auto"
                : "none",
              background: onBoardingActive
                ? orgName && industry && timeZone && language && currency
                  ? "#B20312"
                  : "#b2031280"
                : loginEmail && loginPassword
                ? "#B20312"
                : "#b2031280",
              transition: "background 0.5s ease, color 0.5s ease",
              width:"100%",
              minWidth:"140px",
              marginTop:"1.8rem"
          }} 
          className="auth-button hoverButton">
            Let's go!
            </div>          
          </div>
        </div>
        </>
        ) : (
          <>
        <div className="auth-header">
        {temporaryDummyToken ? "Create new password" : "Sign In"}
        </div>
        {temporaryDummyToken && <div className="doneText" style={{margin:"0px 0px 24px 0px"}}>
        Your new password must be different from previous used passwords.
        </div>}
        <div>
          <div className="auth-group">
          { temporaryDummyToken ? (
            <UserNewPassword
            handleChangePasswordClick={handleChangePasswordClick}
          /> ) : (
          <LoginEmail /> ) }
          </div>

          <div className="auth-group">
          { temporaryDummyToken ? (
            <UserConfirmNewPassword
            handleChangePasswordClick={handleChangePasswordClick}
          /> 
          ) : (
          <LoginPassword handleLoginClick={handleLoginClick } />
          )}
          
          </div>
          { !temporaryDummyToken ? ( <div className="bottom-group">
          {/* <div className="rememberMeContainer"><label>
            <input type='checkbox' onChange={() => setChecked(!checked)} checked={checked}/>
            <span className={`${checked && "spanChecked"}`}></span>
            Remember me
          </label></div> */}
            <div className="link" onClick={() => setForgotPassword(true)}>Forgot password?</div>
          </div> ) : (
            <div className="bottom-group"> 
            <div onClick={resetUser} className="link">
            Back
          </div>
          </div>
          )}

          <div>
            <div
             onClick={
              temporaryDummyToken ? 
              user ?
              user.user.firstTime || user.user.role == "user" ? handleChangePasswordClick : handleOnBoargingFunc
              : null
                //? handleChangePasswordClick
                : handleLoginClick
          } 
            style={{
              color: temporaryDummyToken
              ? newPasswordValid && confirmNewPasswordValid
                ? "rgb(255, 255, 255)"
                : "white"
              : loginEmail && loginPassword
                ? "rgb(255, 255, 255)"
                : "white",
              pointerEvents: temporaryDummyToken
                ? newPasswordValid && confirmNewPasswordValid
                  ? "auto"
                  : "none"
                : loginEmail && loginPassword
                ? "auto"
                : "none",
              background: temporaryDummyToken
                ? newPasswordValid && confirmNewPasswordValid
                  ? "#B20312"
                  : "#b2031280"
                : loginEmail && loginPassword
                ? "#B20312"
                : "#b2031280",
              transition: "background 0.5s ease, color 0.5s ease",
              width:"100%",
              minWidth:"140px",
              marginTop:"1.8rem"
          }} 
          className="auth-button hoverButton">
    {temporaryDummyToken ? "Change Password" : "Sign in"}
            </div>
            {/* {
              !temporaryDummyToken && (
              <><div className="orContainer">
            <div className="or"></div>
            <div style={{color:"#ABB0B9", fontSize:"14px"}}> or </div>
            <div className="or"></div>
            </div>
            
                <div onClick={resetEveryThing} onDoubleClick={resetEveryThing} className="auth-button hoverS" style={{background:"transparent", color:"black", border:"1px solid #ABB0B9", fontSize:"12px"}}>
              <img src={google} style={{margin:"0 .5rem"}} /> Reset every thing
            </div>
            </>
              )
            } */}
            
          </div>
        </div> </>)}
        </div>
        </div>
        </>
        )}
        {!onBoardingActive && (
                  <div className="privacy">
                  <span style={{textDecorationLine:"underline"}}>Privacy policy</span> &amp; <span style={{textDecorationLine:"underline"}}>Terms and Conditions</span>
                </div>
        )}
        </div>
        <div className="rightSignInContainer">
        <div className="loginRightContainer">
          <img src={illustration} width={560} />
      <div className="loginHeader">
      Bring your Organization <br/>
      into the <span style={{fontWeight:"bold"}}>Digital Era.</span>
          </div>
          </div>
      </div>
        </div>
  </div>
  );
};

export default Login;
