import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

class Component extends SampleBase {
    floatFocus(args) {
        args.target.parentElement.classList.add('e-input-focus');
    }
    floatBlur(args) {
        args.target.parentElement.classList.remove('e-input-focus');
    }
    onIconClick(args) {
        args.persist();
        setTimeout(() => {
            args.target.classList.add('e-input-btn-ripple');
        }, 500);
    }
    onIconUnClick(args) {
        args.target.classList.remove('e-input-btn-ripple');
    }
    render() {
        return (<div className='control-pane'>
        <div className='control-section input-content-wrapper'>
            <div className="row custom-margin custom-padding-5 material">
                <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                    <TextBoxComponent {...this.props.events} placeholder={this.props.label} cssClass="e-filled" floatLabelType="Auto"/>
                </div>
            </div>
        </div>
      </div>
      );
    }
}

export default Component