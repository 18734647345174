import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Testimonials5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="relative flex">
        <div class="min-h-screen lg:w-1/3"></div>
        <div class="hidden w-3/4 min-h-screen bg-gray-100 dark:bg-gray-800 lg:block"></div>

        <div class="container flex flex-col justify-center w-full min-h-screen px-6 py-10 mx-auto lg:absolute lg:inset-x-0">
            <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl xl:text-5xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="185048" is="div"><Text text="What our" /></Element>} <span class="text-blue-500"> {<Element canvas style={{display:"inline-block"}} id="101434" is="div"><Text text="customers" /></Element>} </span> <br /> {<Element canvas style={{display:"inline-block"}} id="351741" is="div"><Text text="are saying" /></Element>} </h1>

            <div class="mt-10 lg:mt-20 lg:flex lg:items-center">
                <Element canvas style={{ width: "100%" }} id={"div947173"} is="div">
          <ImageC class="object-cover object-center w-full lg:w-[32rem] rounded-lg h-96" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt=""  />
        </Element>

                <div class="mt-8 lg:px-10 lg:mt-0">
                    <h1 class="text-2xl font-semibold text-gray-800 dark:text-white lg:w-72"> {<Element canvas style={{display:"inline-block"}} id="176586" is="div"><Text text="Help us improve our productivity" /></Element>} </h1>

                    <p class="max-w-lg mt-6 text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="777610" is="div"><Text text="“ Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quibusdam ducimus libero ad
                        tempora doloribus expedita laborum saepe voluptas perferendis delectus assumenda rerum, culpa
                        aperiam dolorum, obcaecati corrupti aspernatur a. ”" /></Element>} </p>

                    <h3 class="mt-6 text-lg font-medium text-blue-500"> {<Element canvas style={{display:"inline-block"}} id="146177" is="div"><Text text="Ronik Ederson" /></Element>} </h3>
                    <p class="text-gray-600 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="123665" is="div"><Text text="Marketing Manager at Stech" /></Element>} </p>
                </div>
            </div>

            <div class="flex items-center justify-between mt-12 lg:justify-start">
                <Element canvas style={{ width: "100%" }} id={"div858530"} is="div">
            <Button classn={"p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 hover:bg-gray-100"}>
              
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
                    </svg>
                
            </Button>
          </Element>

                <Element canvas style={{ width: "100%" }} id={"div176207"} is="div">
            <Button classn={"p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 lg:mx-6 hover:bg-gray-100"}>
              
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Testimonials5.craft = {
  displayName: "Testimonials 5"
};