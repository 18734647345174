import React, { useEffect } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import ACTION_LOGIN_EMAIL_RESET from '../../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET';
import ACTION_LOGIN_TEMPO_NOT_INVALID from '../../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID';
import ACTION_LOGIN_EMAIL_NOT_INVALID from '../../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID';
import { DataSpinner } from '../../spinner/dataSpinner';
import sideLogo from "../../../../assests/images/sideLogo.svg"
import Dropdown from 'react-dropdown';
import arrowDownDark from "../../../../assests/images/arrowDownDark.svg"
import arrowUp from "../../../../assests/images/arrowUp.svg"
import addViewImg from "../../../../assests/images/addTeamImg.svg"
import addDepartmentImg from "../../../../assests/images/addDepartmentImg.svg"
import updated from "../../../../assests/images/updated.svg"
import Multiselect from 'multiselect-react-dropdown';



const AddAppModal = (props) => {

  const dispatch = useDispatch()
  
    const {
      views,
        user,
        open,
        setOpen,
        addApp,
        updateApp,
        updateDepartment,
        appName,
        setAppName,
        appNameError,
        setAppNameError,
        usersFilter,
        usersSearch,
        setUsersSearch,
        userSearchError,
        setUsersSearchError,
        addUserToApp,
        usersAtApp,
        addDepartment,
        setUsersAtApp,
        addAppLoading,
        addAppData,
        deleteUserFromApp,
        setAddAppData ,
        viewsAtApp,
setViewsAtApp,

    } = props

    useEffect(() => {
      if(addAppData) {
        /////console.log(addAppData)
      }
    },[addAppData])

    useEffect(() => {
      if(usersFilter) {
        /////console.log(usersFilter)
      }
    },[usersFilter])

    const deleteUser = (_id) => {
      setUsersAtApp([...usersAtApp.filter(x => x._id !== _id)])
      deleteUserFromApp(_id)
    }

    const onSelectFunc = (selectedList, selectedItem) => {
      /////console.log(selectedList, selectedItem)
      setViewsAtApp(selectedList)
      }
      
      const onRemoveFunc = (selectedList, removedItem) => {
          /////console.log(selectedList, removedItem)
          setViewsAtApp(selectedList)
      }

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? !addAppLoading ? addAppData ? (
          <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"70%", minWidth:"fit-content", alignItems:"center"}}>
            {
              !open.includes("Edit") ? (
                <img src={addViewImg} style={{marginTop:"3rem"}}/>
              ) : (
                <img src={updated} style={{marginTop:"3rem"}}/>
              )
            }
            <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setAppName("")
                    setAddAppData && setAddAppData(null)

                }}
              />   
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{appName} {open.includes("Edit") ? "updated" : "added"} successfully</h1>
          <h1 className="subHeader" style={{width:"100%", justifyContent:"center", display:"flex"}}>Design your {"app"} , fill it with widgets, invite users on the {appName} app.</h1>
        <div
              className="auth-button addUser"
              onClick={() => {
                setOpen(null)
                setAppName("")
                setAddAppData && setAddAppData(null)
              }}
            >
              
            Continue
        </div>  
          </div>
        ) : <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%", width:"45vw"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                    setAppNameError("")
                    setUsersSearch("")
                    setUsersSearchError("")
                   !open.includes("Edit") && setUsersAtApp(null)
                    setAppName("")
                }}
              />  
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? open : `Add a ${open}`}</h1>
          <div className="form-group-container task-form">
          <div>

          <div className="auth-group">
                    <TextInput 
                      type={`${"appName"}`}
                      label={`${"app Name*"}`}
                      value={appName}
                      setValue={setAppName}
                      placeHolder={`Enter name of new ${"app"}`}
                      error={appNameError}
                      setError={setAppNameError} 
                    />
                    </div>

                       
          <div className="auth-group">
                    <div className="auth-label">select Views</div>
                      <Multiselect
                                options={views.map(x => {
                                  return {name: x.name, value:x._id}
                                })} // Options to display in the dropdown
                                selectedValues={viewsAtApp} // Preselected value to persist in dropdown
                                onSelect={onSelectFunc} // Function will trigger on select event
                                onRemove={onRemoveFunc} // Function will trigger on remove event                                displayValue="name" // Property name to display in the dropdown options
                                displayValue="name" // Property name to display in the dropdown options

                          />                      
                    </div>  
                    
                    <div className="auth-group" style={{position:"relative"}}>
                    <TextInput 
                      type="addUsers"
                      label="Add Users"
                      nextLabel={usersAtApp ? usersAtApp.length : 0}
                      value={usersSearch ? usersSearch : ""}
                      setValue={setUsersSearch}
                      placeHolder={`Enter the names of the groups or users you want to add to the ${"app"}`}
                      error={userSearchError}
                      setError={setUsersSearchError} 
                    />
                     { usersSearch && usersFilter && usersFilter.length > 0 &&<div className="orgsC" style={{position:"absolute", maxHeight:"200px", width:"100%", top:"110%", borderRadius:"8px", backgroundColor:"white", zIndex:"2"}}>
                        <div className="orgsContainers">
                        {usersFilter.map(y => (
                          <div onClick={() => {
                              setUsersSearch("")
                              addUserToApp(y)
                          }} className="oneOrg">
                        <img height="45px" src={sideLogo} style={{marginRight:"12px"}} title={y.name} />
                        <div className="oneOrgDetails">
                            <div className="orgTitle">{y.name}</div>
                            <div className="userTitle">{y.role}</div>
                        </div>

                        </div>  
                        ))}
                        </div>
                    </div>}
                    </div>
                    <div className="auth-group">
                    {usersAtApp && <div className="orgsContainers"  style={{overflowY:"visible"}}>
                        {usersAtApp.map(y => (
                          <div style={{display:"flex", justifyContent:"space-between", width:"100%", alignItems:"center"}}>
                          <div style={{minWidth:"30%"}}>
                          <div onClick={() => {
                          }} className="disableHoverOneOrg">
                        <img height="45px" src={sideLogo} style={{marginRight:"12px"}} title={y.name} />
                        <div className="oneOrgDetails">
                            <div className="orgTitle">{y.name} {user.user._id == y._id && "(You)"} </div>
                            <div className="userTitle">{open.includes("Edit") ? y.groupRole :  y.role}</div>
                        </div>
                        </div> 
                        </div> 
                        <div>
                        {user.user._id == y._id ? (
                          <div className='ownerTitle'>Owner</div>
                        ) : (
                          <Dropdown
                            options={["Admin", "User", { value: 'Delete', label: 'Delete', className: 'DeleteHoverButton' }]}
                            controlClassName="adminButton moreWidth" 
                            menuClassName='underMenuu'
                            arrowClosed={<span className="arrow-closed arrowI" ><img
                            className="routeIcon forceRight"
                            src={arrowDownDark}
                            /></span>}
                            arrowOpen={<span className="arrow-open arrowI" ><img
                            className="routeIcon forceRight"
                            src={arrowUp}
                            /></span>}
                            placeholder={"Role"}
                            value={open.includes("Edit") ? y.groupRole :  y.role}
                            onChange={(e) => {
                              e.value !== "Delete" ? setUsersAtApp(usersAtApp.map(t => {
                                if(t._id === y._id) {
                                  !open.includes("Edit") ? y.role = e.value : y.groupRole = e.value
                                  return y
                                } else {
                                  return t
                                }
                              })) : (
                               !usersAtApp ? setUsersAtApp(null) :  open.includes("Edit") ? deleteUser(y._id) : setUsersAtApp([...usersAtApp.filter(x => x._id !== y._id)])
                              ) 
                            }}
                          />
                        )}
                        </div>
                        </div>
                        ))}
                        </div>}
                    </div>  
          </div>
          </div>
            <div
              className={`${appName ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={appName ?  open.includes("Edit") ? updateApp : addApp : null }
            >
              
            {open.includes("Edit") ? "Update" : `Add a ${"App"}`}
            </div>
        </div> : (<DataSpinner />) : (<></>)}
      </Modal>
  )}

  export default AddAppModal
       