import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';
import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { employeeData } from './data-source';
import { Browser } from '@syncfusion/ej2-base';

class Component extends SampleBase {
    qbObj;
    createdControl() {
        if (Browser.isDevice) {
            this.qbObj.summaryView = true;
        }
    }
    columnData = [
        {
            field: 'EmployeeID', label: 'EmployeeID', type: 'number', operators: [{ key: 'Equal', value: 'equal' },
                { key: 'Greater than', value: 'greaterthan' }, { key: 'Less than', value: 'lessthan' }]
        },
        { field: 'FirstName', label: 'FirstName', type: 'string' },
        { field: 'TitleOfCourtesy', label: 'Title Of Courtesy', type: 'boolean', values: ['Mr.', 'Mrs.'] },
        { field: 'Title', label: 'Title', type: 'string' },
        { field: 'HireDate', label: 'HireDate', type: 'date', format: 'dd/MM/yyyy' },
        { field: 'Country', label: 'Country', type: 'string' },
        { field: 'City', label: 'City', type: 'string' }
    ];
    importRules = {
        'condition': 'and',
        'rules': [{
                'label': 'EmployeeID',
                'field': 'EmployeeID',
                'type': 'number',
                'operator': 'equal',
                'value': 1
            },
            {
                'label': 'Title',
                'field': 'Title',
                'type': 'string',
                'operator': 'equal',
                'value': 'Sales Manager'
            }]
    };
    render() {
        return (<div className='control-pane'>
                <div className='control-section'>
                    <div className='row'>
                        <div className=' control-section'>
                            <QueryBuilderComponent {...this.props.allEvents} dataSource={employeeData} columns={this.columnData} rule={this.importRules} created={this.createdControl.bind(this)} ref={(scope) => { this.qbObj = scope; }}>
                            </QueryBuilderComponent>
                        </div>
                    </div>
                </div>
            </div>
      );
    } 
}

export default Component