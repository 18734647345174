import { gql } from "apollo-boost";

const addViewMutation = gql`
  mutation(
    $org: ID,
    $name: String,   
    $html: String,   
    $user: ID,
    $users: [String],
    $editors: [String]
  ) {
    addView(
    org:$org,
    name:$name,    
    html:$html,    
    user: $user
    users:$users    
    editors: $editors

    ) {
    _id
    org,
    name
    html
    user,
    users    
    editors

    }
  }
`;

export default addViewMutation;
