import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <div class="text-center">
            <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="187510" is="div"><Text text="From the blog" /></Element>} </h1>

            <p class="max-w-lg mx-auto mt-4 text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="177385" is="div"><Text text="Salami mustard spice tea fridge authentic Chinese food dish salt tasty liquor. Sweet savory foodtruck
                pie." /></Element>} </p>
        </div>

        <div class="grid grid-cols-1 gap-8 mt-8 md:mt-16 md:grid-cols-2 xl:grid-cols-3">
            <div>
                <div class="relative">
                    <Element canvas style={{ width: "100%" }} id={"div953396"} is="div">
          <ImageC class="object-cover object-center w-full h-64 rounded-lg lg:h-80" src="https://images.unsplash.com/photo-1624996379697-f01d168b1a52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                    <div class="absolute bottom-0 flex p-3 bg-white dark:bg-gray-900 ">
                        <Element canvas style={{ width: "100%" }} id={"div154115"} is="div">
          <ImageC class="object-cover object-center w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                        <div class="mx-4">
                            <h1 class="text-sm text-gray-700 dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="162400" is="div"><Text text="Tom Hank" /></Element>} </h1>
                            <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="996382" is="div"><Text text="Creative Director" /></Element>} </p>
                        </div>
                    </div>
                </div>

                <h1 class="mt-6 text-xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="498075" is="div"><Text text="What do you want to know about UI" /></Element>} </h1>

                <hr class="w-32 my-6 text-blue-500" />

                <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="630226" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis fugit dolorum amet dolores
                    praesentium, alias nam? Tempore" /></Element>} </p>

                <Element canvas style={{ width: "100%" }} id={"div619644"} is="div">
            <Button href={"#"} classn={"inline-block mt-4 text-blue-500 underline hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="234255" is="div"><Text text="Read more" /></Element>} 
            </Button>
          </Element>
            </div>

            <div>
                <div class="relative">
                    <Element canvas style={{ width: "100%" }} id={"div891314"} is="div">
          <ImageC class="object-cover object-center w-full h-64 rounded-lg lg:h-80" src="https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                    <div class="absolute bottom-0 flex p-3 bg-white dark:bg-gray-900 ">
                        <Element canvas style={{ width: "100%" }} id={"div229387"} is="div">
          <ImageC class="object-cover object-center w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt=""  />
        </Element>

                        <div class="mx-4">
                            <h1 class="text-sm text-gray-700 dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="634786" is="div"><Text text="arthur melo" /></Element>} </h1>
                            <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="751234" is="div"><Text text="Creative Director" /></Element>} </p>
                        </div>
                    </div>
                </div>

                <h1 class="mt-6 text-xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="162159" is="div"><Text text="All the features you want to know" /></Element>} </h1>

                <hr class="w-32 my-6 text-blue-500" />

                <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="963489" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis fugit dolorum amet dolores
                    praesentium, alias nam? Tempore" /></Element>} </p>

                <Element canvas style={{ width: "100%" }} id={"div914100"} is="div">
            <Button href={"#"} classn={"inline-block mt-4 text-blue-500 underline hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="799942" is="div"><Text text="Read more" /></Element>} 
            </Button>
          </Element>
            </div>

            <div>
                <div class="relative">
                    <Element canvas style={{ width: "100%" }} id={"div944322"} is="div">
          <ImageC class="object-cover object-center w-full h-64 rounded-lg lg:h-80" src="https://images.unsplash.com/photo-1597534458220-9fb4969f2df5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80" alt=""  />
        </Element>

                    <div class="absolute bottom-0 flex p-3 bg-white dark:bg-gray-900 ">
                        <Element canvas style={{ width: "100%" }} id={"div380234"} is="div">
          <ImageC class="object-cover object-center w-10 h-10 rounded-full" src="https://images.unsplash.com/photo-1531590878845-12627191e687?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt=""  />
        </Element>

                        <div class="mx-4">
                            <h1 class="text-sm text-gray-700 dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="325540" is="div"><Text text="Amelia. Anderson" /></Element>} </h1>
                            <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="338672" is="div"><Text text="Lead Developer" /></Element>} </p>
                        </div>
                    </div>
                </div>

                <h1 class="mt-6 text-xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="988985" is="div"><Text text="Which services you get from Meraki UI" /></Element>} </h1>

                <hr class="w-32 my-6 text-blue-500" />

                <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="315068" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis fugit dolorum amet dolores
                    praesentium, alias nam? Tempore" /></Element>} </p>

                <Element canvas style={{ width: "100%" }} id={"div388068"} is="div">
            <Button href={"#"} classn={"inline-block mt-4 text-blue-500 underline hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="159610" is="div"><Text text="Read more" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Blog5.craft = {
  displayName: "Blog 5"
};