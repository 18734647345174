import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  return (
    <div style={{height:"100%", overflow:"auto"}}>
      <ToolbarSection
        title="Grouping"
        props={['allowGrouping']}
      >
        <ToolbarItem full={true} propKey="allowGrouping" type="switch" label="Active" />
      </ToolbarSection>
      <ToolbarSection
        title="Hovering"
        props={['enableHover']}
      >
        <ToolbarItem full={true} propKey="enableHover" type="switch" label="Active" />
      </ToolbarSection>
     
      <ToolbarSection
        title="Row Height"
        props={['rowHeight']}
      >
        <ToolbarItem
          full={true}
          propKey="rowHeight"
          type="text"
          label="Row Height"
        />
      </ToolbarSection>
      <ToolbarSection
        title="Filtering"
        props={['allowFiltering']}
      >
        <ToolbarItem full={true} propKey="allowFiltering" type="switch" label="Active" />
      </ToolbarSection>
      <ToolbarSection
        title="sorting"
        props={['allowSorting']}
      >
        <ToolbarItem full={true} propKey="allowSorting" type="switch" label="Active" />
      </ToolbarSection>
      <ToolbarSection
        title="selection"
        props={['allowSelection']}
      >
        <ToolbarItem full={true} propKey="allowSelection" type="switch" label="Active" />
      </ToolbarSection>
    </div>
  );
};
