import { gql } from "apollo-boost";

const allGroupsQuery = gql`
  
    query(
    $org: ID
  ) { 
    allGroups (
      org: $org
    ) {
    _id
    name,
    org,
    createdAt
    }
  }
  
`;

export default allGroupsQuery;
