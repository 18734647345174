import { gql } from "apollo-boost";

const allUsersQuery = gql`
  query(
    $org: ID
    $role: String
  ) {
    allUsers(
    org: $org
    role: $role
     
    ) {
      _id
      name
      email
      phoneNumber
      active
      firstTime
      role
      image
      org
      orgs
      group
      createdAt
      department
      departmentAdmin
      groupAdmin
    }
  }
`;

export default allUsersQuery;