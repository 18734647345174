import { gql } from "apollo-boost";

const addDepartmentMutation = gql`
  mutation(
    $name: String,
    $org: ID,
  ) {
    addDepartment(
    name:$name,
    org:$org,
    ) {
    _id
    name,
    org
    }
  }
`;

export default addDepartmentMutation;
