const FORMBUILDER = "FORMBUILDER";
const FORMBUILDER_RESET = "FORMBUILDER_RESET";

const formBuilderReducer = (
  state = { formBuilder: null },
  action
) => {
  switch (action.type) {
    case FORMBUILDER:
      return { ...state, formBuilder: action.payload };
    case FORMBUILDER_RESET:
      return { ...state, formBuilder: null };
    default:
      return { ...state };
  }
};

export default formBuilderReducer;
