import { gql } from "apollo-boost";

const addRecordMutation = gql`
  mutation(
    $collectionId: ID,
    $formId: ID,
    $name: String,
    $data: String
  ) {
    addRecord(
    collectionId:$collectionId,
    formId:$formId,
    data:$data
    name:$name,
    ) {
    _id
    formId
    data
    collectionId,
    name,
    }
  }
`;

export default addRecordMutation;
