const ACTIVE_ORG = "ACTIVE_ORG";
const ACTIVE_ORG_RESET = "ACTIVE_ORG_RESET";

const activeOrgReducer = (
  state = { org: null, orgName: null, orgLanguage: null, orgTimezone: null, orgCurrency: null, orgIndustry: null, },
  action
) => {
  switch (action.type) {
    case ACTIVE_ORG:
      return { ...state, org: action.payload._id, orgName: `${action.payload.name} - ${action.payload.industry}`, orgLanguage:action.payload.language, orgTimezone:action.payload.timeZone, orgCurrency:action.payload.currency, orgIndustry:action.payload.industry };
    case ACTIVE_ORG_RESET:
      return { ...state, org: null, orgName: null, orgLanguage: null, orgTimezone: null, orgCurrency: null, orgIndustry: null };
    default:
      return { ...state };
  }
};

export default activeOrgReducer;
