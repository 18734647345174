import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  
  return (
    
    <React.Fragment>
      <ToolbarSection
        title="Features"
        props={[
          "toolbar",
          "magnification",
          "navigation",
          "linkAnnotation",
          "bookmarkView",
          "thumbnailView",
          "print",
          "textSelection",
          "textSearch",
          "annotation",
          "formFields",
          "formDesigner",
        ]}
      >
        <ToolbarItem full={true} propKey="toolbar" type="switch" label="Toolbar" />
        <ToolbarItem full={true} propKey="magnification" type="switch" label="Magnification" />
        <ToolbarItem full={true} propKey="navigation" type="switch" label="Navigation" />
        <ToolbarItem full={true} propKey="linkAnnotation" type="switch" label="LinkAnnotation" />
        <ToolbarItem full={true} propKey="bookmarkView" type="switch" label="BookmarkView" />
        <ToolbarItem full={true} propKey="thumbnailView" type="switch" label="ThumbnailView" />
        <ToolbarItem full={true} propKey="print" type="switch" label="Print" />
        <ToolbarItem full={true} propKey="textSelection" type="switch" label="TextSelection" />
        <ToolbarItem full={true} propKey="textSearch" type="switch" label="TextSearch" />
        <ToolbarItem full={true} propKey="annotation" type="switch" label="Annotation" />
        <ToolbarItem full={true} propKey="formFields" type="switch" label="FormFields" />
        <ToolbarItem full={true} propKey="formDesigner" type="switch" label="FormDesigner" />
       
      </ToolbarSection>
    </React.Fragment>
  );
};
