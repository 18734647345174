import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Faq7 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="space-y-4">
  <details class="group border-l-4 border-green-500 bg-gray-50 p-6" open>
    <summary class="flex cursor-pointer items-center justify-between">
      <h5 class="text-lg font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="203487" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing?" /></Element>} </h5>

      <span class="ml-1.5 flex-shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-45" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
        </svg>
      </span>
    </summary>

    <p class="mt-4 leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="848085" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic veritatis
      molestias culpa in, recusandae laboriosam neque aliquid libero nesciunt
      voluptate dicta quo officiis explicabo consequuntur distinctio corporis
      earum similique!" /></Element>} </p>
  </details>

  <details class="group border-l-4 border-green-500 bg-gray-50 p-6">
    <summary class="flex cursor-pointer items-center justify-between">
      <h5 class="text-lg font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="662893" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing?" /></Element>} </h5>

      <span class="ml-1.5 flex-shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-45" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
        </svg>
      </span>
    </summary>

    <p class="mt-4 leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="219785" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic veritatis
      molestias culpa in, recusandae laboriosam neque aliquid libero nesciunt
      voluptate dicta quo officiis explicabo consequuntur distinctio corporis
      earum similique!" /></Element>} </p>
  </details>
    </div>

      </div>;
};
Faq7.craft = {
  displayName: "Faq 7"
};