import React, { useState, useRef, useEffect } from 'react';

export const PopupModal = ({ onClick,onButtonclicked, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef(null);

  const handleClick = () => {
    setIsVisible(true);
    // onButtonclicked()
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const modalStyle = {
    position: 'absolute',
    top: 'calc(100% + 10px)',
    left: '50%',
    transform: 'translateX(-50%)',
    height:"fit-content",
    width:"150px",
    background:"rgb(22, 23, 28)",
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
    padding: '10px',
    zIndex: '1',
  };

  return (
    <div style={{ position: 'relative' }} onClick={onClick}>
      <div style={{width:"1.5rem", height:"1.2rem",  background:"#959dad14", borderRadius:"3px"}} onClick={handleClick}>
                 {
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" class="eva eva-plus-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="plus"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect><path d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"></path></g></g></svg>
                  }
                  </div>
      {isVisible && (
        <div ref={modalRef} style={modalStyle}>
          {children}
        </div>
      )}
    </div>
  );
};
