import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Navigation4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <nav x-data="{ isOpen: false }" class="relative bg-white shadow dark:bg-gray-800">
    <div class="container px-6 py-4 mx-auto">
        <div class="lg:flex lg:items-center lg:justify-between">
            <div class="flex items-center justify-between">
                <div class="text-xl font-semibold text-gray-700">
                    <Element canvas style={{ width: "100%" }} id={"div232790"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 transition-colors duration-300 transform dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="304019" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
                </div>

                
                <div class="flex lg:hidden">
                    <Element canvas style={{ width: "100%" }} id={"div539510"} is="div">
            <Button classn={"text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"}>
              
                        <svg x-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                        </svg>
                
                        <svg x-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    
            </Button>
          </Element>
                </div>
            </div>

           
            <div class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white dark:bg-gray-800 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center">
                <div class="flex flex-col -mx-6 lg:flex-row lg:items-center lg:mx-8">
                    <Element canvas style={{ width: "100%" }} id={"div390060"} is="div">
            <Button href={"#"} classn={"px-3 py-2 mx-3 mt-2 text-gray-700 transition-colors duration-300 transform rounded-md lg:mt-0 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"}>
               {<Element canvas style={{display:"inline-block"}} id="110442" is="div"><Text text="Join Slack" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div352058"} is="div">
            <Button href={"#"} classn={"px-3 py-2 mx-3 mt-2 text-gray-700 transition-colors duration-300 transform rounded-md lg:mt-0 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"}>
               {<Element canvas style={{display:"inline-block"}} id="762696" is="div"><Text text="Browse Topics" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div771793"} is="div">
            <Button href={"#"} classn={"px-3 py-2 mx-3 mt-2 text-gray-700 transition-colors duration-300 transform rounded-md lg:mt-0 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"}>
               {<Element canvas style={{display:"inline-block"}} id="108042" is="div"><Text text="Random Item" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div258184"} is="div">
            <Button href={"#"} classn={"px-3 py-2 mx-3 mt-2 text-gray-700 transition-colors duration-300 transform rounded-md lg:mt-0 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"}>
               {<Element canvas style={{display:"inline-block"}} id="352653" is="div"><Text text="Experts" /></Element>} 
            </Button>
          </Element>
                </div>

                <div class="flex items-center mt-4 lg:mt-0">
                    <Element canvas style={{ width: "100%" }} id={"div790967"} is="div">
            <Button classn={"hidden mx-4 text-gray-600 transition-colors duration-300 transform lg:block dark:text-gray-200 hover:text-gray-700 dark:hover:text-gray-400 focus:text-gray-700 dark:focus:text-gray-400 focus:outline-none"}>
              
                        <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    
            </Button>
          </Element>

                    <Element canvas style={{ width: "100%" }} id={"div852241"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                        <div class="w-8 h-8 overflow-hidden border-2 border-gray-400 rounded-full">
                            <Element canvas style={{ width: "100%" }} id={"div249859"} is="div">
          <ImageC src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" class="object-cover w-full h-full" alt="avatar"  />
        </Element>
                        </div>

                        <h3 class="mx-2 text-gray-700 dark:text-gray-200 lg:hidden"> {<Element canvas style={{display:"inline-block"}} id="528779" is="div"><Text text="Khatab wedaa" /></Element>} </h3>
                    
            </Button>
          </Element>
                </div>
            </div>
        </div>
    </div>
    </nav>

      </div>;
};
Navigation4.craft = {
  displayName: "Navigation 4"
};