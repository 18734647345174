import * as React from 'react';
import './index.scss';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
    rendereComplete() {
        /* On click event for flip the card*/
        if(document.getElementById('card_flip')){document.getElementById('card_flip').onclick = (e) => {
            let cardEle = (e.currentTarget);
            if (cardEle.classList.contains('e-flipped')) {
                cardEle.classList.remove('e-flipped');
            }
            else {
                cardEle.classList.add('e-flipped');
            } 
        };
        /* On blur event for flip the card*/
        document.getElementById('card_flip').onblur = (e) => {
            let cardEle = (e.currentTarget);
            cardEle.classList.remove('e-flipped');
        };
    }
        /* On click event for flip the card*/
        if(document.getElementById('card_flip_profile')){ document.getElementById('card_flip_profile').onclick = (e) => {
            let cardEle = (e.currentTarget);
            if (cardEle.classList.contains('e-flipped')) {
                cardEle.classList.remove('e-flipped');
            }
            else {
                cardEle.classList.add('e-flipped');
            }
        };
        /* On blur event for flip the card*/
        document.getElementById('card_flip_profile').onblur = (e) => {
            let cardEle = (e.currentTarget);
            cardEle.classList.remove('e-flipped');
        };
    }
    }
    render() {
        return (<div className='control-pane'>
                <div className='control-section card-control-section flip_card_layout'>
                    <div className="e-card-resize-container">
                        <div className='row'>
                            <div className="row card-layout">
                                    <div className="e-card e-business e-flip" id="card_flip" title="Click to flip the Card">
                                        <div className="e-card-header e-front">
                                            <div className="e-card-header-caption">
                                                <div className="e-card-header-title">{this.props.cardName}</div>
                                                <div className="e-card-sub-title">{this.props.title}</div>
                                            </div>
                                        </div>
                                        <div className="e-card-actions e-front">
                                            <button className="e-card-btn">
                                                <div className="e-email e-card-btn-txt">{this.props.email}</div>
                                            </button>
                                            <button className="e-card-btn">
                                                <div className="e-email e-card-btn-txt">{this.props.phone}</div>
                                            </button>
                                            <button className="e-card-btn">
                                                <div className="e-email e-card-btn-txt">{this.props.website}</div>
                                            </button>
                                        </div>
                                        <div className="e-card-header e-back">
                                            <div className="e-card-header-caption">
                                                <div className="e-card-header-title">Address</div>
                                                <div className="e-card-sub-title">
                                                {this.props.address}
                                            </div></div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      );
    } 
}

export default Component