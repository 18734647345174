import * as React from 'react';
import './index.scss';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent, FormValidator } from '@syncfusion/ej2-react-inputs';
import { BarcodeGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import { SampleBase } from './sample-base';
import { NumericTextBoxComponent, ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";

let canShowError = false;
let customFn = (args) => {
    if (canShowError) {
        return false;
    }
    return true;
};
let options = {
    rules: {
        'textbox_0': { minLength: [customFn, 'Invalid input'] },
    }
};

class Component extends SampleBase {
    formObject;
    render() {
        return (<div className="control-pane">
        {/* <style>{sample_css}</style> */}
        <div className="col-lg-8 control-section" style={{ width: "64%" }}>
        <div className="content-wrapper" style={{ width: "100%", height: "590px" }}>
            <div className='center'>
              <div className='centercontrol'>
                <BarcodeGeneratorComponent id="barcode" ref={barcode =>barcode} width={"200px"} height={"150px"} invalid={(arg) => {
                canShowError = true;
                this.formObject.validate();
            }} mode='SVG' type='Ean8' value='11223344'></BarcodeGeneratorComponent>
              </div>
            </div>
            <div className='allowedText'>
              <span style={{ fontWeight: 600 }}> Allowed Input characters :</span> This barocode supports 8 numbers as
        </div>
          </div>
        </div>
        </div>
      ); 
    } 
}

export default Component