import { Element, useEditor } from '@craftjs/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCog, faPaperPlane} from "@fortawesome/free-solid-svg-icons"
import logo from "./Logo.png"
import Dropdown from "react-dropdown";

import ButtonWithPopup from './ButtonComponent';
import { Input } from 'reactstrap';
const ToolboxDiv = styled.div`
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  ${(props) => (!props.enabled ? `width: 0;` : '')}
  ${(props) => (!props.enabled ? `opacity: 0;` : '')}
`;

export const Toolbox = ({
  layers,
setLayers,
setAddWidgetActive,
addWidgetActive,
setViewSettings,
viewSettings,
setBuilderModal,
builderModal,
setActiveView,
activeView,
views,
children, 
settings
}) => {
  const {
    enabled,
    connectors: { create },
  } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  
function TreeView({ data }) {
  console.log(data)
  const [expanded, setExpanded] = useState([]);

  const handleToggle = (nodeId) => {
    setExpanded((prevState) =>
      prevState.includes(nodeId)
        ? prevState.filter((id) => id !== nodeId)
        : [...prevState, nodeId]
    );
  };

  const renderTree = (nodes, child) => {
    return (
      <ul style={{padding:0, width:"100%"}}>
        {nodes.map((node, i) => {
          const isExpanded = expanded.includes(node._id);
          console.log(node)
          return (
            <li key={node._id}>
              <div 
                  style={{width:"100%", borderTop: i == 0 ? "1px solid #2d3038" : "0px", cursor:"pointer", background: activeView?._id == node._id ? "#959dad29" : "#959dad14"}}
                  className='viewNC'
                  onClick={() => {
                    handleToggle(node._id)
                    setActiveView(node)
                  }}
            >
              <div style={{width:"1.9rem", height:"1.6rem", background: !child ? "rgba(171, 176, 185, .6)" : "transparent", borderRadius:"3px"}}>{!child ? <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.794 12.104a.644.644 0 00.393-.524 4.904 4.904 0 014.884-4.372 4.91 4.91 0 014.834 4.026.643.643 0 00.626.527 4.53 4.53 0 014.47 4.515 4.521 4.521 0 01-4.517 4.516H9.516a4.514 4.514 0 01-1.722-8.689z" fill="#959dad"></path></svg>: null}</div> 
              {/* {node.children && (
                  <span style={{ marginRight: "0.5rem" }}>
                    {isExpanded ? "▼" : "►"}
                  </span>)} */}

                <span className="viewWN" style={{width:"100%", padding:".125rem .625rem"}}>{node.name}</span>
               {activeView?._id == node._id  ? <div style={{width:"1.9rem", height:"1.6rem",  background:"#959dad14", borderRadius:"3px"}}>
               {<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" class="eva eva-more-vertical-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="more-vertical"><rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"></rect><circle cx="12" cy="12" r="2"></circle><circle cx="12" cy="5" r="2"></circle><circle cx="12" cy="19" r="2"></circle></g></g></svg> }
                </div> : null}
               </div>

               {node.children && isExpanded && renderTree(node.children, true)}
            </li>
          );
        })}
      </ul>
    );
  };

  return <div style={{width:"100%"}}>{renderTree(data)}</div>;
}
 
if(settings && enabled) {
    return (
      <ToolboxDiv
        enabled={true}
        className="toolbox transition w-12 h-full flex flex-col"
        style={{width:'240px', paddingTop:"10px", backgroundColor:"#16171c"}}
      >
    
    <div 
          className='widgetItemIn dm topheader'
        >
          <div className='widgetItemName'>
            App Settings
          </div>

          
        </div>

        <div 
          className='widgetItemIn dm'
          style={{flexDirection:"column", alignItems:"flex-start"}}
        >
          <div className='widgetItemName'>
            Page title
          </div>

          <textarea className='textareaDm' value={`{{app.name}} - {{activeRoute.name}}`}></textarea>
          
          
        </div>

        <div className='hori'  />




        <div 
          className='widgetItemIn dm topheader'
        >
        <div className='widgetItemName'>
          Page triggers
        </div>
        </div>

        <Dropdown                                 
          controlClassName="auth-input-dm-top"
          placeholder="select trigger"
          arrowOpen={<div></div>}
          arrowClosed={<div></div>}
          options={[]}
           menuClassName="menu-dm-top" 
        />
        <Dropdown                                 
          controlClassName="auth-input-dm-bottom"
          placeholder="select action"

          arrowOpen={<div></div>}
          arrowClosed={<div></div>}
          options={[]}
           menuClassName="menu-dm-top"
        />

      <div className='viewNC buttonNC dmColor'>+ add trigger</div>
        <div className='hori'  />




        <div 
          className='widgetItemIn dm topheader'
        >
          <div className='widgetItemName'>
            App triggers
          </div>

          
        </div>

        <Dropdown                                 
          controlClassName="auth-input-dm-top"
          placeholder="select trigger"
          arrowOpen={<div></div>}
          arrowClosed={<div></div>}
          options={[]}
           menuClassName="menu-dm-top" 
        />
        <Dropdown                                 
          controlClassName="auth-input-dm-bottom"
          placeholder="select action"

          arrowOpen={<div></div>}
          arrowClosed={<div></div>}
          options={[]}
           menuClassName="menu-dm-top"
        />

      <div className='viewNC buttonNC dmColor'>+ add trigger</div>

        <div className='hori'  />



        <div 
          className='widgetItemIn dm topheader'
        >
          <div className='widgetItemName'>
            styling
          </div>
        </div>


        <div className='widgetItemNameDes'>
        Use custom CSS classes to style components, add styles in app settings Code tab.
          </div>

        <Input
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="css-class"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

        <div className='hori'  />

  
    </ToolboxDiv>
    )
}
if(enabled && !settings) {
  return (
    <ToolboxDiv
    enabled={true}
    className="toolbox transition w-12 h-full flex flex-col spaceBetween"
    style={{width:'2.5rem', paddingTop:"2.5rem", backgroundColor:"#16171c"}}
  >
   {/* <div style={{height:"92px", display:"flex", padding:"5px", justifyContent:"center", alignItems:"center"}}>
    <img height={"100%"} src={logo} />
  </div> */}
  <div className="flex flex-1 flex-col items-center pt-3" style={{borderRight:"1px solid #313642"}}>
    <div         
      style={{
        height:"100%",
        display:"flex",
        flexDirection:"column",
        width:"100%",
        alignItems:"center",
        justifyContent:"space-between"
      }}
    >
    <div>
    <div>
      <div onClick={() => setAddWidgetActive(!addWidgetActive)} className='addWidget'>
      <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 24 24" class="eva eva-plus-square" fill="#959dad"><g data-name="Layer 2"><g data-name="plus-square"><rect width="24" height="24" opacity="0"></rect><path d="M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zm-3 10h-2v2a1 1 0 0 1-2 0v-2H9a1 1 0 0 1 0-2h2V9a1 1 0 0 1 2 0v2h2a1 1 0 0 1 0 2z"></path></g></g></svg>
      </div>
    </div>


    <div>
      <div className='addWidget'>
      <svg width="50%" height="50%" viewBox="0 0 16 16" fill="#959dad" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.833 2.622c0-.399.324-.722.723-.722h2.888c.4 0 .723.323.723.722v2.89a.722.722 0 01-.723.721h-.88v1.17H10.6A2.297 2.297 0 0112.897 9.7H11.77a1.17 1.17 0 00-1.17-1.17H5.4A1.17 1.17 0 004.23 9.7H3.103A2.297 2.297 0 015.4 7.403h2.037v-1.17h-.881a.722.722 0 01-.723-.722V2.622zm-4.333 7.8c0-.399.323-.722.722-.722h2.89c.398 0 .721.323.721.722v2.89a.722.722 0 01-.722.721H2.222a.722.722 0 01-.722-.722v-2.889zm9.389-.722a.722.722 0 00-.722.722v2.89c0 .398.323.721.722.721h2.889a.722.722 0 00.722-.722v-2.889a.722.722 0 00-.722-.722h-2.89z" fill="#959dad"></path></svg>      </div>
    </div>


    <div>
      <div className='addWidget'>
      <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 24 24" class="eva eva-file" fill="#959dad"><g data-name="Layer 2"><g data-name="file"><rect width="24" height="24" opacity="0"></rect><path d="M19.74 7.33l-4.44-5a1 1 0 0 0-.74-.33h-8A2.53 2.53 0 0 0 4 4.5v15A2.53 2.53 0 0 0 6.56 22h10.88A2.53 2.53 0 0 0 20 19.5V8a1 1 0 0 0-.26-.67zM14 4l3.74 4h-3a.79.79 0 0 1-.74-.85z"></path></g></g></svg>    </div></div>


      <div>
      <div className='addWidget'>
      <svg width="50%" height="50%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20 16.568V19.5C20 21.218 15.853 22 12 22c-3.853 0-8-.782-8-2.5v-2.932c1.851.945 5.685 1.23 8 1.23 2.315 0 6.149-.285 8-1.23zm0-4.901v3.33c0 .436-2.804 1.501-8 1.501s-8-1.065-8-1.5v-3.331c1.851.944 5.685 1.23 8 1.23 2.315 0 6.149-.286 8-1.23zm0-5.094v3.423c0 .435-2.804 1.5-8 1.5s-8-1.065-8-1.5V6.573c2.03 1.477 6.39 1.622 8 1.622 1.61 0 5.973-.145 8-1.622zM12.533 2C16.226 2.066 20 2.82 20 4.394c0 1.65-4.147 2.4-8 2.4-3.853 0-8-.75-8-2.4C4 2.82 7.774 2.066 11.467 2z" fill="#959dad" fill-rule="evenodd"></path></svg>
      
      </div></div>


    {/* <div>
      <div onClick={() => setViewSettings(!viewSettings)} className='addWidget'>
        <FontAwesomeIcon 
        icon={faCog} 
        />
      </div>
    </div> */}

    {setBuilderModal && <div>
      <div onClick={() => setBuilderModal(!builderModal)} className='addWidget'>
        <FontAwesomeIcon 
        icon={faPaperPlane}
        />
      </div>
    </div>}
    {/* <div>
      <div onClick={() => setLayers(!layers)} style={{cursor:"pointer"}}>
      <svg width="29" height="29" viewBox="0 0 29 29" class="symbol symbol-leftBarLayers"><path d="M22.55 18.39l.4.92-8.16 3.51A2 2 0 0114 23a2 2 0 01-.79-.16l-8.16-3.53.4-.92 8.15 3.51a1 1 0 00.8 0l8.15-3.51zm0-4.31L23 15l-8.16 3.51a2.14 2.14 0 01-.84.19 2 2 0 01-.79-.16L5.05 15l.4-.92 8.15 3.51a1 1 0 00.8 0l8.15-3.51zm-7.76-8.87l7.29 3.14a1.49 1.49 0 010 2.75l-7.29 3.14a2 2 0 01-.79.16 2 2 0 01-.79-.16L5.92 11.1a1.49 1.49 0 010-2.75l7.29-3.14a2 2 0 011.58 0zm-.79.84a1 1 0 00-.4.08L6.31 9.26a.5.5 0 00-.3.46.49.49 0 00.3.46l7.29 3.14a1 1 0 00.8 0l7.29-3.14a.49.49 0 00.3-.46.5.5 0 00-.3-.46L14.4 6.13a1 1 0 00-.4-.08z"></path></svg>
      </div>
    </div> */}
    </div>
    
  </div>
  </div>
  </ToolboxDiv>
  )
}

if(!settings) {
  return (
    <ToolboxDiv
      enabled={true}
      className="toolbox transition w-12 h-full flex flex-col spaceBetween"
      style={{width:'240px', paddingTop:"50px", backgroundColor:"#16171c"}}
    >

        <div className='sidebarContent' style={{width:"100%", display:"flex", flexDirection:"column", alignItems:"flex-start"}}>

        <TreeView data={views} />

                {/* {views?.map((x, i) => (
                        <div 
                        style={{width:"100%", borderTop: i == 0 ? "1px solid #2d3038" : "0px", cursor:"pointer", background: activeView?._id == x._id ? "#959dad29" : "#959dad14"}}
                        className='viewNC'
                        onClick={() => setActiveView(x)}
            >
              <div style={{width:"1.9rem", height:"1.6rem", background:" rgba(171, 176, 185, .6)", borderRadius:"3px"}}><svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.794 12.104a.644.644 0 00.393-.524 4.904 4.904 0 014.884-4.372 4.91 4.91 0 014.834 4.026.643.643 0 00.626.527 4.53 4.53 0 014.47 4.515 4.521 4.521 0 01-4.517 4.516H9.516a4.514 4.514 0 01-1.722-8.689z" fill="#959dad"></path></svg></div>
                <span className="viewWN" style={{width:"100%", padding:".125rem .625rem"}}>{x.name}</span>
               {activeView?._id == x._id  ? <div style={{width:"1.9rem", height:"1.6rem",  background:"#959dad14", borderRadius:"3px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" class="eva eva-more-vertical-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="more-vertical"><rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"></rect><circle cx="12" cy="12" r="2"></circle><circle cx="12" cy="5" r="2"></circle><circle cx="12" cy="19" r="2"></circle></g></g></svg>                
                </div> : null}
               </div>
        ))} */}
</div>
<div className='sidebarContent' style={{height:"fit-content", overflow:"visible", background:"rgba(149, 157, 173, 0.08)"}}>
      
            <div 
              style={{width:"100%", borderTop: "1px solid #2d3038", cursor:"pointer", background: "#959dad14", position:"relative"}}
              className='viewNC'
              // onClick={() => setActiveView(x)}
            >
              <div style={{width:"1rem", height:"1.2rem"}}>
                <svg width="15" height="15" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M17 13.984v1.813C17 16.696 14.273 18 10 18c-4.274 0-7-1.304-7-2.203v-1.813c1.575 1.016 4.293 1.542 7 1.542 2.706 0 5.425-.526 7-1.542zm0-3.864v1.813c0 .216-.162.455-.461.695-.945.755-3.295 1.507-6.54 1.507-3.244 0-5.593-.752-6.538-1.507-.3-.24-.461-.479-.461-.695V10.12c1.575 1.015 4.293 1.541 7 1.541 2.706 0 5.425-.526 7-1.541zm0-3.865v1.812c0 .217-.162.457-.461.696-.945.755-3.295 1.508-6.54 1.508-3.244 0-5.593-.753-6.538-1.508-.3-.24-.461-.48-.461-.696V6.255c1.575 1.016 4.293 1.542 7 1.542 2.706 0 5.425-.526 7-1.542zM10 2c4.273 0 7 1.304 7 2.203 0 .216-.163.456-.461.695-.946.756-3.295 1.507-6.539 1.507-3.245 0-5.594-.751-6.539-1.507C3.161 4.66 3 4.42 3 4.203 3 3.304 5.727 2 10 2z" fill="#959dad"></path></g></svg></div>
                <span className="viewWN" style={{width:"100%", padding:".125rem .625rem", color:"#959dad"}}>Data Sources</span>

                <div style={{width:"1.9rem", height:"1.6rem"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" class="eva eva-chevron-right-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="chevron-right"><rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"></rect><path d="M10.5 17a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42L13.1 12 9.92 8.69a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l3.86 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-.7.32z"></path></g></g></svg>
                </div>
            </div>

            <div 
              style={{width:"100%", borderTop: "0px", cursor:"pointer", background: "#959dad14", position:"relative"}}
              className='viewNC'
              // onClick={() => setActiveView(x)}
            >
              <div style={{width:"1rem", height:"1.2rem"}}>
                
              <svg width="20" height="20" viewBox="0 0 20 20" fill="#959dad" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.408 15.333a.665.665 0 01-.663-.738l.512-4.728H3.334a.666.666 0 01-.554-1.038L8.038.963a.665.665 0 011.217.442l-.512 4.728h3.924a.666.666 0 01.554 1.038l-5.26 7.866a.666.666 0 01-.553.296z" fill="#959dad"></path></svg>                
                </div>
                <span className="viewWN" style={{width:"100%", padding:".125rem .625rem", color:"#959dad"}}>Actions Library</span>

                <div style={{width:"1.9rem", height:"1.6rem"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" class="eva eva-chevron-right-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="chevron-right"><rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"></rect><path d="M10.5 17a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42L13.1 12 9.92 8.69a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l3.86 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-.7.32z"></path></g></g></svg>
                </div>
            </div>
            
            <div 
              style={{width:"100%", borderTop: "0px", cursor:"pointer", background: "#959dad14", position:"relative"}}
              className='viewNC'
              // onClick={() => setActiveView(x)}
            >
              <div style={{width:"1rem", height:"1.2rem"}}>
              <svg width="15" height="15" viewBox="0 0 20 20" version="1.1" fill="#959dad" xmlns="http://www.w3.org/2000/svg"><path d="M6 2a4.002 4.002 0 0 1 3.874 3h4.882l2.537-2.536a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L19 9.242v4.884a4.002 4.002 0 0 1 2.995 3.674L22 18a4 4 0 1 1-5-3.874V9.243L14.757 7H9.874A4.007 4.007 0 0 1 7 9.874L7 14h2a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1l2-.001V9.874A4.002 4.002 0 0 1 2.005 6.2L2 6a4 4 0 0 1 4-4z" stroke="none" stroke-width="1" fill-rule="evenodd"></path></svg>                
              </div>
              <span className="viewWN" style={{width:"100%", padding:".125rem .625rem", color:"#959dad"}}>Automations</span>

                <div style={{width:"1.9rem", height:"1.6rem"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" class="eva eva-chevron-right-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="chevron-right"><rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"></rect><path d="M10.5 17a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42L13.1 12 9.92 8.69a1 1 0 0 1 0-1.41 1 1 0 0 1 1.42 0l3.86 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-.7.32z"></path></g></g></svg>
                </div>
            </div>

            <ButtonWithPopup text="Create App" option1="Option 1" option2="Option 2" />

</div>
    </ToolboxDiv>
  )} else {
    return <div></div>
  }
};
