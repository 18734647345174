import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { useLocation } from "react-router-dom";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import ACTION_LOGIN_EMAIL_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";

import arrowDownUsers from "../../../assests/images/arrowDownUsers.svg"
import searchInput from "../../../assests/images/searchInput.svg"
import setting from "../../../assests/images/setting.svg"
import arrowRight from "../../../assests/images/arrowRight.svg"
import edit from "../../../assests/images/edit.svg"
import trash from "../../../assests/images/trash.svg"
import add from "../../../assests/images/add.svg"
import {useMutation} from "@apollo/client"
import addUserMutation from "../../../graphql/mutations/user/addUserMutation"
import {AccountTable as UsersTable} from "../../resuable/accountTable"
import AddUserModal from "../../resuable/modals/addUserModal";
import { DataSpinner } from "../../resuable/spinner/dataSpinner";
import ACTION_LOGIN_EMAIL_RESET from "../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET";
import ACTION_LOGIN_TEMPO_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID";
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import moment from "moment"
import ACTION_LOGIN_TEMPO_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_INVALID";
const SuperAdmins = ({user, users, usersData, setUsersData, usersRefetch}) => {
    let location = useLocation()

    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const dispatch = useDispatch()

    const loginEmail = useSelector((state) => state.loginEmail.login_email);
    const activeOrg = useSelector((state) => state.activeOrg.org);

    const loginEmailInvalid = useSelector(
      (state) => state.loginEmailInvalid.login_email_invalid
    );
    


    const usersColumn = [
        
        {
            name: 'user name',
            filter:["none"],
            selector: row => row.userName,
            filterable: true,
            sortable: true
        },
        {
            name: 'Email',
            filter:["none"],
            selector: row => row.email,
            filterable: true,
            sortable: true
        },
        {
            name: 'date',
            filter:["none"],
            selector: row => row.date,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Active Workspace',
            filter:["none"],
            ignoreRowClick: true,
            allowOverflow: false,
            filterable: true,
            sortable: true,
            cell: row => (
                <div style={{ display:"flex", alignItems:"center"}}>
                    <div style={{color:"#3C3C3C", fontSize:"15px", marginRight:".8rem"}}>{row.workspaces}</div>
                    <div className="moreButton">More <img style={{marginLeft:".5rem"}} src={arrowRight}/></div>
                </div>
            ),
        },
        {
            name: 'Active Forms',
            filter:["none"],
            ignoreRowClick: true,
            allowOverflow: false,
            filterable: true,
            sortable: true,
            cell: row => (
                <div style={{ display:"flex", alignItems:"center"}}>
                    <div style={{color:"#3C3C3C", fontSize:"15px", marginRight:".8rem"}}>{row.forms}</div>
                    <div className="moreButton">More <img style={{marginLeft:".5rem"}} src={arrowRight}/></div>
                </div>
            ),
        },
        {
            name: 'Role',
            filter:["none"],
            filterable: true,
            sortable: true,
            cell: row => (
                <div className="print-status">
                <span>{row.state}</span>
            </div>
            ),
            
        },
        {
            name: 'State',
            filter:["none"],
            filterable: true,
            sortable: true,
            cell: row => (
                <div className={`print-status ${row.active ? "greenActive" : "redDeactive"}`}>
                <span>{row.active ? "Active" : "Deactive"}</span>
            </div>
            ),
            
        },{
            name: 'Actions',
            filter:["none"],
            ignoreRowClick: true,
            allowOverflow: false,
            filterable: true,
            sortable: true,
            cell: row => (
                <div className="actions-buttons">
                    <img style={{marginRight:".4rem"}} className="actionHover" src={edit}/>
                    <img style={{marginLeft:".4rem"}} className="actionHover" src={trash}/>
                </div>
            ),
            
        },
        
    ];
    

    // add user modal


    useEffect(() => {
        if(users) {
            setUsersData(users.allUsers.map(x => {
                return {
                    userName : x.name,
                    email: x.email,
                    date: moment.unix(x.createdAt/1000).format("MM/DD/YYYY"),
                    state: x.role,
                    id: x._id,
                    workspaces: 0,
                    forms: 0,
                    active: x.active
                }
            }))
        }
    },[users])
    
// get started information
const [userAddModal, setUserAddModal] = useState(null)

// add user info

const [firstName, setFirstName] = useState("")
const [firstNameError, setFirstNameError] = useState("")

const [LastName, setLastName] = useState("")
const [lastnameError, setLastNameError] = useState("")



const [addUser, {error: addUserError, data: addUserData, loading: addUserLoading}] = useMutation(addUserMutation)



const addUserFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    if(loginEmail && !loginEmailInvalid) {
    addUser({
        variables:{
            name: firstName + " " + LastName,
            email: loginEmail,
            role: "superAdmin",
            firstTime: true
        }
    }).then((res) => {
        usersRefetch()
        setUserAddModal(null)   
        setFirstName("")
        setLastName("")
        dispatch(ACTION_LOGIN_EMAIL_RESET())
        dispatch(ACTION_LOADING_SPINNER_RESET())
        dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID())
        dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID())
    }).catch((error) => {
        if (error) {
            /////console.log(error.message)
            if (error.message) {
              if (error.message.includes("email")) {
                dispatch(ACTION_LOGIN_TEMPO_INVALID());
                dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
                dispatch(ACTION_LOADING_SPINNER_RESET())
              } 

            }
          }
    })
}
}

useEffect(() => {
    if(users) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
},[users])




// if(!usersData) {
//     return (
//         <DataSpinner />
//     )
// }




 return (
        <div className="adminContent column">
            {returnToHomeScreen()}
            <AddUserModal 
                open={userAddModal}
                setOpen={setUserAddModal}
                addUser={addUserFunc}
                firstName={firstName}
                setFirstName={setFirstName}
                firstNameError={firstNameError}
                setFirstNameError={setFirstNameError}
                LastName={LastName}
                setLastName={setLastName}
                lastnameError={lastnameError}
                setLastNameError={setLastNameError}
                addUserLoading={addUserLoading}
            />
            <div className="workspaceHeader">
                <div className="workspaceName" style={{display:"flex", alignItems:"center"}}>
                        Super admins
                        <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => setUserAddModal("User")}
                        
                        />
                </div>
                <div className="workspaceButtons">
                    <div className={`routeContainer sideSearch userSearch`} style={{width:"269px" , margin:"0px", fontWeight:"normal", justifyContent:"flex-start"}}>
                    <img
                                className="routeIcon"
                                src={searchInput}
                    />
                    <div className="searchT" style={{margin:"0 16px", color:"#5E6A7A"}}>
                        Search                        
                    </div>
                    </div>
                    <div className="adminButton" style={{width:"fit-content"}}> 
                    <span style={{margin:"0 16px", color:"#5E6A7A"}} className="buttonIconImage">Newest</span>
                    <img className="buttonIconImage" style={{margin:"0 12px"}} src={arrowDownUsers} />
                    </div>
                    <div className="adminButton" >
                    <img className="buttonIconImage" src={setting} />
                    </div>

                </div>
            </div>
            <div className="workspaceContainer">
            <UsersTable
                accountColumns={usersColumn}
                accountData={usersData}
                />
            </div>
        </div>
    )
}

export default SuperAdmins