import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Section6 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section>
  <div class="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 lg:h-screen lg:grid-cols-2">
      <div class="relative z-10 lg:py-16">
        <div class="relative h-64 sm:h-80 lg:h-full">
          <Element canvas style={{ width: "100%" }} id={"div308186"} is="div">
          <ImageC alt="House" src="https://images.unsplash.com/photo-1583608205776-bfd35f0d9f83?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" class="absolute inset-0 h-full w-full object-cover"  />
        </Element>
        </div>
      </div>

      <div class="relative flex items-center bg-gray-100">
        <span class="hidden lg:absolute lg:inset-y-0 lg:-left-16 lg:block lg:w-16 lg:bg-gray-100"></span>

        <div class="p-8 sm:p-16 lg:p-24">
          <h2 class="text-2xl font-bold sm:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="832167" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore,
            debitis." /></Element>} </h2>

          <p class="mt-4 text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="571157" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid,
            molestiae! Quidem est esse numquam odio deleniti, beatae, magni
            dolores provident quaerat totam eos, aperiam architecto eius quis
            quibusdam fugiat dicta." /></Element>} </p>

          <Element canvas style={{ width: "100%" }} id={"div401283"} is="div">
            <Button href={"#"} classn={"mt-8 inline-block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="443212" is="div"><Text text="Get in Touch" /></Element>} 
            </Button>
          </Element>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Section6.craft = {
  displayName: "Section 6"
};