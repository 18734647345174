import * as React from 'react';
import './index.scss';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
    render() {
        return (<div className='control-pane'>
                <div className="sample_container card_sample">
                    {/* <!-- Card Component --> */}
                    <div className="e-card e-custom-card">
                        <div className="e-card-header">
                            <div className="e-card-header-caption center">
                                <div className="e-card-header-title name">Laura Callahan</div>
                                <div className="e-card-sub-title">Sales Coordinator</div>
                            </div>
                        </div>
                        <div className="e-card-content">
                                <p className="avatar-content"> Laura received a BA in psychology from the University of Washington. She has also completed a course in business French. She reads and writes French.</p>
                        </div>
                    </div>
                </div>


            </div>
      );
    } 
}

export default Component