import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Navigation16 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <header class="shadow-sm">
  <div class="mx-auto max-w-screen-xl p-4">
    <div class="flex items-center justify-between gap-4 lg:gap-10">
      <div class="flex lg:w-0 lg:flex-1">
        <span class="h-10 w-20 rounded-lg bg-gray-200"></span>
      </div>

      <nav class="hidden gap-8 text-sm font-medium md:flex">
        <Element canvas style={{ width: "100%" }} id={"div311500"} is="div">
            <Button href={""} classn={"text-gray-500 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="707259" is="div"><Text text="About" /></Element>} 
            </Button>
          </Element>
        <Element canvas style={{ width: "100%" }} id={"div534581"} is="div">
            <Button href={""} classn={"text-gray-500 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="566520" is="div"><Text text="Blog" /></Element>} 
            </Button>
          </Element>
        <Element canvas style={{ width: "100%" }} id={"div387883"} is="div">
            <Button href={""} classn={"text-gray-500 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="640254" is="div"><Text text="Projects" /></Element>} 
            </Button>
          </Element>
        <Element canvas style={{ width: "100%" }} id={"div170225"} is="div">
            <Button href={""} classn={"text-gray-500 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="638514" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
      </nav>

      <div class="hidden flex-1 items-center justify-end gap-4 sm:flex">
        <Element canvas style={{ width: "100%" }} id={"div237587"} is="div">
            <Button href={""} classn={"block rounded-lg bg-gray-100 px-5 py-2 text-sm font-medium text-gray-500 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="145303" is="div"><Text text="Log in" /></Element>} 
            </Button>
          </Element>

        <Element canvas style={{ width: "100%" }} id={"div796199"} is="div">
            <Button href={""} classn={"block rounded-lg bg-blue-600 px-5 py-2 text-sm font-medium text-white cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="288659" is="div"><Text text="Sign up" /></Element>} 
            </Button>
          </Element>
      </div>

      <div class="lg:hidden">
        <Element canvas style={{ width: "100%" }} id={"div165702"} is="div">
            <Button classn={"rounded-lg bg-gray-100 p-2 text-gray-600 cursor-pointer"}>
              
          <span class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="417650" is="div"><Text text="Open menu" /></Element>} </span>
          <svg aria-hidden="true" class="h-5 w-5" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6h16M4 12h16M4 18h16" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          </svg>
        
            </Button>
          </Element>
      </div>
    </div>
  </div>
    </header>

      </div>;
};
Navigation16.craft = {
  displayName: "Navigation 16"
};