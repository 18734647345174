import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
  <section class="bg-white dark:bg-gray-900">
      <div class="container px-6 py-10 mx-auto">
          <div class="flex items-center justify-between">
              <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="204232" is="div"><Text text="recent posts" /></Element>} </h1>

              <Element canvas style={{ width: "100%" }} id={"div479561"} is="div">
            <Button classn={"focus:outline-none"}>
              
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-600 transition-colors duration-300 transform dark:text-gray-400 hover:text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
              
            </Button>
          </Element>
          </div>

          <hr class="my-8 border-gray-200 dark:border-gray-700" />

          <div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
              <div>
                  <Element canvas style={{ width: "100%" }} id={"div429034"} is="div">
          <ImageC class="object-cover object-center w-full h-64 rounded-lg lg:h-80" src="https://images.unsplash.com/photo-1624996379697-f01d168b1a52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                  <div class="mt-8">
                      <span class="text-blue-500 uppercase"> {<Element canvas style={{display:"inline-block"}} id="660869" is="div"><Text text="category" /></Element>} </span>

                      <h1 class="mt-4 text-xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="933136" is="div"><Text text="What do you want to know about UI" /></Element>} </h1>

                      <p class="mt-2 text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="893111" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam est asperiores vel, ab animi
                          recusandae nulla veritatis id tempore sapiente" /></Element>} </p>

                      <div class="flex items-center justify-between mt-4">
                          <div>
                              <Element canvas style={{ width: "100%" }} id={"div429961"} is="div">
            <Button href={"#"} classn={"text-lg font-medium text-gray-700 dark:text-gray-300 hover:underline hover:text-gray-500"}>
               {<Element canvas style={{display:"inline-block"}} id="925140" is="div"><Text text="John snow" /></Element>} 
            </Button>
          </Element>

                              <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="692483" is="div"><Text text="February 1, 2022" /></Element>} </p>
                          </div>

                          <Element canvas style={{ width: "100%" }} id={"div131834"} is="div">
            <Button href={"#"} classn={"inline-block text-blue-500 underline hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="963437" is="div"><Text text="Read more" /></Element>} 
            </Button>
          </Element>
                      </div>

                  </div>
              </div>

              <div>
                  <Element canvas style={{ width: "100%" }} id={"div848808"} is="div">
          <ImageC class="object-cover object-center w-full h-64 rounded-lg lg:h-80" src="https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                  <div class="mt-8">
                      <span class="text-blue-500 uppercase"> {<Element canvas style={{display:"inline-block"}} id="262401" is="div"><Text text="category" /></Element>} </span>

                      <h1 class="mt-4 text-xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="660389" is="div"><Text text="All the features you want to know" /></Element>} </h1>

                      <p class="mt-2 text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="105185" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam est asperiores vel, ab animi
                          recusandae nulla veritatis id tempore sapiente" /></Element>} </p>

                      <div class="flex items-center justify-between mt-4">
                          <div>
                              <Element canvas style={{ width: "100%" }} id={"div380322"} is="div">
            <Button href={"#"} classn={"text-lg font-medium text-gray-700 dark:text-gray-300 hover:underline hover:text-gray-500"}>
               {<Element canvas style={{display:"inline-block"}} id="661328" is="div"><Text text="Arthur Melo" /></Element>} 
            </Button>
          </Element>

                              <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="520425" is="div"><Text text="February 6, 2022" /></Element>} </p>
                          </div>

                          <Element canvas style={{ width: "100%" }} id={"div548858"} is="div">
            <Button href={"#"} classn={"inline-block text-blue-500 underline hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="422839" is="div"><Text text="Read more" /></Element>} 
            </Button>
          </Element>
                      </div>

                  </div>
              </div>

              <div>
                  <Element canvas style={{ width: "100%" }} id={"div237772"} is="div">
          <ImageC class="object-cover object-center w-full h-64 rounded-lg lg:h-80" src="https://images.unsplash.com/photo-1597534458220-9fb4969f2df5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80" alt=""  />
        </Element>

                  <div class="mt-8">
                      <span class="text-blue-500 uppercase"> {<Element canvas style={{display:"inline-block"}} id="478038" is="div"><Text text="category" /></Element>} </span>

                      <h1 class="mt-4 text-xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="464892" is="div"><Text text="Which services you get from Meraki UI" /></Element>} </h1>

                      <p class="mt-2 text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="358292" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam est asperiores vel, ab animi
                          recusandae nulla veritatis id tempore sapiente" /></Element>} </p>

                      <div class="flex items-center justify-between mt-4">
                          <div>
                              <Element canvas style={{ width: "100%" }} id={"div647234"} is="div">
            <Button href={"#"} classn={"text-lg font-medium text-gray-700 dark:text-gray-300 hover:underline hover:text-gray-500"}>
               {<Element canvas style={{display:"inline-block"}} id="268561" is="div"><Text text="Tom Hank" /></Element>} 
            </Button>
          </Element>

                              <p class="text-sm text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="972211" is="div"><Text text="February 19, 2022" /></Element>} </p>
                          </div>

                          <Element canvas style={{ width: "100%" }} id={"div273659"} is="div">
            <Button href={"#"} classn={"inline-block text-blue-500 underline hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="341335" is="div"><Text text="Read more" /></Element>} 
            </Button>
          </Element>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

      </div>;
};
Blog4.craft = {
  displayName: "Blog 4"
};