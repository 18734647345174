const VIEW_SETTINGS = "VIEW_SETTINGS";

const ACTION_VIEW_SETTINGS = (payload) => {
  return {
    type: VIEW_SETTINGS,
    payload
  };
};

export default ACTION_VIEW_SETTINGS;
