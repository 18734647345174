import './index.scss';
import * as React from 'react';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
    render() {
        return (<div className='control-pane'>
        <div className='control-section accordion-control-section'>
          <div className='control Accordion-sample' style={{ margin: '25px 0' }}>
            {/* Render the Accoridon Component */}
            <AccordionComponent {...this.props.allEvents} width={"500px"}>
              <AccordionItemsDirective>
                {this.props.items?.length ? this.props.items?.map(x => (
                      <AccordionItemDirective header={x.header} content={x.content}/>
                )) : (
                  <div> click edit to begin </div>
                )}
              </AccordionItemsDirective>
            </AccordionComponent>
          </div></div>
      </div>);
    }
}

export default Component