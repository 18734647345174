import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Footer14 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
    {/*         
     <!--
      This component uses @tailwindcss/forms
       yarn add @tailwindcss/forms
      npm install @tailwindcss/forms
       plugins: [require('@tailwindcss/forms')]
     --> */}

    <footer class="bg-white">
  <div class="mx-auto max-w-screen-xl px-4 pt-16 pb-8 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-md">
      <strong class="block text-center text-xl font-medium text-gray-900 sm:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="164971" is="div"><Text text="Want us to email you with the latest blockbuster news?" /></Element>} </strong>

      <form class="mt-6">
        <div class="relative max-w-lg">
          <label class="sr-only" for="email"> {<Element canvas style={{display:"inline-block"}} id="963007" is="div"><Text text="Email" /></Element>} </label>

          <input class="w-full rounded-full border-gray-200 bg-gray-100 p-4 pr-32 text-sm font-medium" id="email" type="email" placeholder="john@doe.com" />

          <Element canvas style={{ width: "100%" }} id={"div834502"} is="div">
            <Button classn={"absolute top-1 right-1 -translate-y-1/2 rounded-full bg-blue-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-blue-700 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="243746" is="div"><Text text="Subscribe" /></Element>} 
            </Button>
          </Element>
        </div>
      </form>
    </div>

    <div class="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-32">
      <div class="mx-auto max-w-sm lg:max-w-none">
        <p class="mt-4 text-center text-gray-500 lg:text-left lg:text-lg"> {<Element canvas style={{display:"inline-block"}} id="613698" is="div"><Text text="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Praesentium
          natus quod eveniet aut perferendis distinctio iusto repudiandae,
          provident velit earum?" /></Element>} </p>

        <div class="mt-6 flex justify-center gap-4 lg:justify-start">
          <Element canvas style={{ width: "100%" }} id={"div512174"} is="div">
            <Button href={""} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
              
            <span class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="516131" is="div"><Text text="Facebook" /></Element>} </span>

            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
            </svg>
          
            </Button>
          </Element>

          <Element canvas style={{ width: "100%" }} id={"div280756"} is="div">
            <Button href={""} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
              
            <span class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="476708" is="div"><Text text="Instagram" /></Element>} </span>

            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
            </svg>
          
            </Button>
          </Element>

          <Element canvas style={{ width: "100%" }} id={"div842835"} is="div">
            <Button href={""} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
              
            <span class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="922050" is="div"><Text text="Twitter" /></Element>} </span>

            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          
            </Button>
          </Element>

          <Element canvas style={{ width: "100%" }} id={"div500276"} is="div">
            <Button href={""} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
              
            <span class="sr-only"> {<Element canvas style={{display:"inline-block"}} id="908251" is="div"><Text text="GitHub" /></Element>} </span>

            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
            </svg>
          
            </Button>
          </Element>

        </div>
      </div>

      <div class="grid grid-cols-1 gap-8 text-center lg:grid-cols-3 lg:text-left">
        <div>
          <strong class="font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="289215" is="div"><Text text="Services" /></Element>} </strong>

          <nav class="mt-6 flex flex-col space-y-1">
            <Element canvas style={{ width: "100%" }} id={"div178345"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="848122" is="div"><Text text="Marketing" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div256844"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="326303" is="div"><Text text="Graphic Design" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div101204"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="939441" is="div"><Text text="App Development" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div865247"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="128431" is="div"><Text text="Web Development" /></Element>} 
            </Button>
          </Element>
          </nav>
        </div>

        <div>
          <strong class="font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="187867" is="div"><Text text="About" /></Element>} </strong>

          <nav class="mt-6 flex flex-col space-y-1">
            <Element canvas style={{ width: "100%" }} id={"div202736"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="944556" is="div"><Text text="About" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div603111"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="570001" is="div"><Text text="Careers" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div521669"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="837021" is="div"><Text text="History" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div258688"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="589505" is="div"><Text text="Our Team" /></Element>} 
            </Button>
          </Element>
          </nav>
        </div>

        <div>
          <strong class="font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="474248" is="div"><Text text="Support" /></Element>} </strong>

          <nav class="mt-6 flex flex-col space-y-1">
            <Element canvas style={{ width: "100%" }} id={"div734627"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="617770" is="div"><Text text="FAQs" /></Element>} 
            </Button>
          </Element>

            <Element canvas style={{ width: "100%" }} id={"div831653"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="447093" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>

            <Element canvas style={{ width: "100%" }} id={"div757545"} is="div">
            <Button href={"/"} classn={"text-gray-700 transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="254307" is="div"><Text text="Live Chat" /></Element>} 
            </Button>
          </Element>
          </nav>
        </div>
      </div>
    </div>

    <div class="mt-16 border-t border-gray-100 pt-8">
      <p class="text-center text-xs leading-relaxed text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="729559" is="div"><Text text="© Company 2022. All rights reserved." /></Element>} <br /> {<Element canvas style={{display:"inline-block"}} id="189815" is="div"><Text text="Created with" /></Element>} <Element canvas style={{ width: "100%" }} id={"div247361"} is="div">
            <Button href={""} classn={"text-gray-700 underline transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="725599" is="div"><Text text="Laravel" /></Element>} 
            </Button>
          </Element> {<Element canvas style={{display:"inline-block"}} id="632791" is="div"><Text text="and" /></Element>} <Element canvas style={{ width: "100%" }} id={"div609431"} is="div">
            <Button href={""} classn={"text-gray-700 underline transition hover:text-gray-700/75 cursor-pointer"}>
               {<Element canvas style={{display:"inline-block"}} id="990912" is="div"><Text text="Laravel Livewire" /></Element>} 
            </Button>
          </Element> {<Element canvas style={{display:"inline-block"}} id="619770" is="div"><Text text="." /></Element>} </p>
    </div>
  </div>
    </footer>

      </div>;
};
Footer14.craft = {
  displayName: "Footer 14"
};