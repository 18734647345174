import * as React from 'react';
import './index.scss';

import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { Browser } from '@syncfusion/ej2-base';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
  menuObj;
  animationSettings = {
      effect: Browser.isDevice ? 'ZoomIn' : 'SlideDown'
  };
  content = Browser.isDevice ? 'Touch hold to open the ContextMenu' :
      'Right click/Touch hold to open the ContextMenu';
  //ContextMenu items definition
  menuItems = [
      {
          text: 'Cut',
          iconCss: 'e-cm-icons e-cut'
      },
      {
          text: 'Copy',
          iconCss: 'e-cm-icons e-cm-copy'
      },
      {
          text: 'Paste',
          iconCss: 'e-cm-icons e-paste',
          items: [
              {
                  text: 'Paste Text',
                  iconCss: 'e-cm-icons e-pastetext'
              },
              {
                  text: 'Paste Special',
                  iconCss: 'e-cm-icons e-pastespecial'
              }
          ]
      },
      {
          separator: true
      },
      {
          text: 'Link',
          iconCss: 'e-cm-icons e-link'
      },
      {
          text: 'New Comment',
          iconCss: 'e-cm-icons e-comment'
      }
  ];
  // Event triggers while rendering each menu item where “Link” menu item is disabled
  addDisabled = (args) => {
      if (args.item.text === 'Link') {
          args.element.classList.add('e-disabled');
      }
  };
  render() {
      return (<div className='control-pane'>
              <div className='control-section'>
                  <div className='contextmenu-section'>
                      <div id='contextmenu-control'>
                          <div id="contextmenutarget">{this.content}</div>
                          <ContextMenuComponent {...this.props.allEvents} target='#contextmenutarget' items={this.menuItems} animationSettings={this.animationSettings} beforeItemRender={this.addDisabled}/>
                      </div>
                  </div>
              </div>
          </div>);
    }
}

export default Component