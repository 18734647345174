import { useNode, useEditor } from '@craftjs/core';
import React, { useEffect, useState ,useRef} from 'react';
import ContentEditable from 'react-contenteditable';
import { DataSpinner } from '../../../../spinner/dataSpinner';
import parse from 'html-react-parser';
import { TextSettings } from './TextSettings';
import {GridWidget} from "../Grid"
import {CardWidget} from "../Card"

export const Text = ({
  fontSize,
  textAlign, 
  fontWeight,
  color,
  shadow,
  text,
  margin,
  eventsDataArr,
  setActiveView,
dataBaseDataArr,
  applyFilter,
  allData,
}) => {

  const inputRef = useRef()
  const {
    connectors: { connect },
    setProp,
  } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { query, actions } = useEditor((state, query) => ({
    hoveredNodeId: state.events.hovered
  }));

  const [value, setValue] = useState("")


  const addNode = (el) => {
//     console.log(el)
//     var tempDiv = document.createElement('div')
//     tempDiv.innerHTML = el
let convertString = (el) => {
  if(el.includes("grid_widget")) {
    return <GridWidget />
  } else if(el.includes("text_widget")) {
    return <Text />
  } else if(el.includes("card_widget")) {
    return <Card />
  } else {
     const reactElement = parse(el);
     return reactElement;
  }
}

    const nodeTree = query.parseReactElement(convertString(el)).toNodeTree()
    actions.addNodeTree(nodeTree, "ROOT")

  }
  const dispatch = useDispatch()
const applyTrigger = (xs) => {
    console.log(xs)
    xs.map(x => {
      let type = x.event.eventType
    if(type == "Alert") {
      alert(x.event.eventContent)
    } else if(type == "Navigate") {
    dispatch(ACTION_TRIGGER_VIEW(x.event.key))
} else if (type == "Filter") {
      applyFilter && applyFilter(allData, x.node, x.event.key, inputRef?.current?.value)
      console.log("filters", inputRef?.current?.value, x)
    } else if(type == "Code") {
      console.log(x)
      let fun = new Function("inputValue", "applyFilter", "addNode", x.event.eventContent)
      fun(inputRef?.current?.value, applyFilter, addNode)
    } else if(type == "Inject") {
      addNode(x.event.eventContent)
    }

    })
  }
  // useEffect(() => {
  //   if(eventsDataArr && inputRef?.current) {
  //     console.log(eventsDataArr)
  //     eventsDataArr.map(x => inputRef.current.addEventListener(x.event.eventName.slice(2, x.event.eventName.length).toLowerCase(), 
  //     () => applyTrigger(x)
  //     )) 
  //   }
  // },[eventsDataArr, inputRef?.current])

  
  const [allEvents, setAllEvents] = useState({})
  function groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
      var key = obj[property.replaceAll(' ','')];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  
  useEffect(() => {
    if(eventsDataArr && applyFilter) {
      console.log(eventsDataArr)
      let newEventsData = groupBy(eventsDataArr, "eventName")

      let allv = Object.assign({}, ...Object.keys(newEventsData).map((key) => {
        console.log(newEventsData[key])
        let arr = [...newEventsData[key]]
        newEventsData[key] = () => applyTrigger(arr);
        return {[key]: newEventsData[key]}
      }))
      // console.log(allv)
      // let ob =  ...(allv.map(item => ({ [Object.keys(item)[0]] : () => applyTrigger(item) }) )))
      
      console.log(allv,111111111111111)
      setAllEvents(allv)

    }
  },[eventsDataArr, applyFilter])

  useEffect(() => {
    if(allEvents) {
      console.log(allEvents)
    }
  },[allEvents])

  return (
    // <ContentEditable
    //   innerRef={connect}
    //   html={text} // innerHTML of the editable div
    //   disabled={!enabled}
    //   onChange={(e) => {
    //     setProp((prop) => (prop.text = e.target.value), 500);
    //   }} // use true to disable editing
    //   tagName="h2" // Use a custom HTML tag (uses a div by default)
    //   style={{
    //     width: '100%',
    //     margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
    //     color: `rgba(${Object.values(color)})`,
    //     fontSize: `${fontSize}px`,
    //     textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
    //     fontWeight,
    //     textAlign,
    //   }}
    // />
    
<div ref={connect}>       
  <input ref={inputRef} {...allEvents} disabled={allData?.length ? false : true} placeholder="Filter data" className={`routeContainer sideSearch userSearch`} style={{width:"269px" , margin:"0px", fontWeight:"normal", justifyContent:"flex-start"}} />
</div>
        
  );
};

Text.craft = {
  displayName: 'Text',
  props: {
    fontSize: '15',
    textAlign: 'left',
    fontWeight: '500',
    color: { r: 92, g: 90, b: 90, a: 1 },
    margin: [0, 0, 0, 0],
    shadow: 0,
    text: 'Text',
    dataBaseDataArr: null,
    eventsDataArr: null,
    applyFilter: null,
    allData: []
  },
  related: {
    toolbar: TextSettings,
  },
};
