import React from 'react';

import { capitalize, weightDescription } from './text';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const TextSettings = () => {

  return (
    <div style={{display:"flex", height:"100%"}}>
      <div style={{width:"0px", }}>
      <div style={{ contain:"content", position:"fixed", bottom:"10px", height:"90%", padding:"15px", width:"80px", display:"flex", justifyContent:"center"}}>

        {/* <ToolbarItem
          full={true}
          propKey="classInt"
          type="vslider"
          label="Styling"
        /> */}

      </div>
      </div>
      <div style={{width:"100%"}}>
    
      
      <ToolbarSection title="Style">

      <div className="flex flex-col justify-between gap-2 w-full" id="StylingRoot">
  <div
    className="text-xs font-['Rubik'] leading-[16.8px] text-[#989191] self-start w-full"
    id="InputText"
  >
    Use custom CSS classes to style components, add styles in app settings Code
    tab.
  </div>
  <div className="flex flex-row gap-2 items-center">
    <div
      className="border-solid border-[#e9e9ea] self-start flex flex-row justify-center pt-1 gap-24 w-64 h-10 items-center border rounded-[30px]"
      id="BasicInput"
    >
      <div
        className="font-['Inter'] leading-[24px] text-[#989191] self-start mt-1"
        id="InputText1"
      >
        Placeholder
      </div>
      <img
        src="https://file.rendit.io/n/Z6SOpACpT5sj0s41cT0m.svg"
        className="w-5 shrink-0"
        id="Icons2"
      />
    </div>
    <img
      src="https://file.rendit.io/n/iPfKNNBDECUMzAKP08lZ.svg"
      className="w-6 shrink-0"
      id="Icons1"
    />
  </div>
  <button
    className="bg-[#0867fc] flex flex-col h-10 shrink-0 items-center py-2 rounded-[30px]"
    id="Button1"
  >
    <div
      className="text-center font-['Inter'] leading-[20px] text-white"
      id="Label"
    >
      Add CSS-class
    </div>
  </button>
</div>

{/* 
        <ToolbarItem
          full={true}
          propKey="class"
          type="text"
          label="Css Classes"
        /> */}
      </ToolbarSection>
      <ToolbarSection
        title="Typography"
        props={['fontSize', 'fontWeight', 'textAlign']}
        summary={({ fontSize, fontWeight, textAlign }) => {
          return `${fontSize || ''}, ${fontWeight ? weightDescription(
            fontWeight
          ) : ""}, ${textAlign ? capitalize(textAlign) : ""}`;
        }}
      >
        <ToolbarItem
          full={true}
          propKey="fontSize"
          type="slider"
          label="Font Size"
        />
        <ToolbarItem propKey="textAlign" type="radio" label="Align">
          <ToolbarRadio value="left" label="Left" />
          <ToolbarRadio value="center" label="Center" />
          <ToolbarRadio value="right" label="Right" />
        </ToolbarItem>
        <ToolbarItem propKey="fontWeight" type="radio" label="Weight">
          <ToolbarRadio value="400" label="Regular" />
          <ToolbarRadio value="500" label="Medium" />
          <ToolbarRadio value="700" label="Bold" />
        </ToolbarItem>
      </ToolbarSection>
      <ToolbarSection
        title="Margin"
        props={['margin']}
        summary={({ margin }) => {
          return `${margin[0] || 0}px ${margin[1] || 0}px ${margin[2] || 0}px ${
            margin[3] || 0
          }px`;
        }}
      >
        <ToolbarItem propKey="margin" index={0} type="slider" label="Top" />
        <ToolbarItem propKey="margin" index={1} type="slider" label="Right" />
        <ToolbarItem propKey="margin" index={2} type="slider" label="Bottom" />
        <ToolbarItem propKey="margin" index={3} type="slider" label="Left" />
      </ToolbarSection>
      <ToolbarSection
        title="Appearance"
        props={['color', 'shadow']}
        summary={({ color, shadow }) => {
          return (
            <div className="fletext-right">
              <p
                style={{
                  color: color && `rgba(${Object.values(color)})`,
                  textShadow: shadow && `0px 0px 2px rgba(0, 0, 0, ${shadow / 100})`,
                }}
                className="text-white text-right"
              >
                T
              </p>
            </div>
          );
        }}
      >
        <ToolbarItem full={true} propKey="color" type="color" label="Text" />
        <ToolbarItem
          full={true}
          propKey="shadow"
          type="slider"
          label="Shadow"
        />
      </ToolbarSection>
      </div>
    </div>
  );
};