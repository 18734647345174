import React, { useEffect } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { DataSpinner } from '../../spinner/dataSpinner';
import addViewImg from "../../../../assests/images/addTeamImg.svg"
import updated from "../../../../assests/images/updated.svg"
import ACTION_VIEW_SETTINGS_RESET from '../../../../actions/viewSettings/ACTION_VIEW_SETTINGS_RESET';
import FlowBuilder from '../../flowBuilder';



const BuilderModal = (props) => {

  const dispatch = useDispatch()
  
    const {
        open,
        setOpen,
        

    } = props

  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"100%", width:"100vw"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(false)
                }}
              />  
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem", fontSize:"10px"}}>Flow Builder</h1>
          <div style={{height:"100%", width:"100%"}}>
         
                <FlowBuilder />
          </div>
            {/* <div
              className={`${true ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={true ?  null : null }
            >
              
            {`Save`}
            </div> */}
        </div> : (<DataSpinner />)}
      </Modal>
  )}

  export default BuilderModal
       