import { createChatBotMessage } from "react-chatbot-kit";
import GotIt from "./widgets/options/GotIt";
import AgeDropdown from "./widgets/options/AgeDropdown";

const config = {
  botName: "All you can cloud",
  initialMessages: [
    createChatBotMessage(`Hello, Welcome to all you can cloud page builder!`, {
      widget: "Hello",
    }),
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#376B7E",
    },
  },
  customComponents: {
    botAvatar: (props) => <div style={{height:"40px"}}><img src={"https://cdn-icons-png.flaticon.com/128/7979/7979868.png"} alt="bot" width={"40px"} height={"40px"} /></div>,
  },
  widgets: [
    {
      widgetName: "Hello",
      widgetFunc: (props) => <GotIt {...props} />,
    },
    {
      widgetName: "ageDropdown",
      widgetFunc: (props) => <AgeDropdown {...props} />,
    },
  ],
};

export default config;
