import { gql } from "apollo-boost";

const addShareableMutation = gql`
  mutation(
    $user: ID,
    $org: String,
    $id: String,
    $type: String,
    $name: String,
    $market: Boolean
    $rules: [String]

  ) {
    addShareable(
      user: $user
      org: $org
      id: $id
      type: $type
      name: $name
      market: $market
      rules: $rules
    ) {
    _id
    user
    org
    id
    type
    market
    rules
    }
  }
`;

export default addShareableMutation;
