import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Footer5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <footer class="flex justify-center px-4 text-gray-800 bg-white dark:text-white dark:bg-gray-900">
    <div class="container py-6">
        <h1 class="text-lg font-bold text-center lg:text-2xl"> {<Element canvas style={{display:"inline-block"}} id="427863" is="div"><Text text="Join 31,000+ other and never miss" /></Element>} <br /> {<Element canvas style={{display:"inline-block"}} id="276867" is="div"><Text text="out on new tips, tutorials, and more." /></Element>} </h1>

        <div class="flex justify-center mt-6">
            <div class="bg-white border rounded-md focus-within:ring dark:bg-gray-900 dark:border-gray-700 focus-within:border-blue-400 focus-within:ring-blue-300 focus-within:ring-opacity-40 dark:focus-within:border-blue-300">
                <div class="flex flex-wrap justify-between md:flex-row">
                    <input type="email" class="p-2 m-1 text-sm text-gray-700 bg-transparent appearance-none focus:outline-none focus:placeholder-transparent" placeholder="Enter your email" aria-label="Enter your email" />
                    <Element canvas style={{ width: "100%" }} id={"div871559"} is="div">
            <Button classn={"w-full px-3 py-2 m-1 text-sm font-medium tracking-wider text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded-md dark:hover:bg-gray-600 dark:bg-gray-800 lg:w-auto hover:bg-gray-700"}>
               {<Element canvas style={{display:"inline-block"}} id="561458" is="div"><Text text="subscribe" /></Element>} 
            </Button>
          </Element>
                </div>
            </div>
        </div>

        <hr class="h-px mt-6 bg-gray-200 border-none dark:bg-gray-700" />

        <div class="flex flex-col items-center justify-between mt-6 md:flex-row">
            <div>
                <Element canvas style={{ width: "100%" }} id={"div722249"} is="div">
            <Button href={"#"} classn={"text-xl font-bold text-gray-800 transition-colors duration-300 dark:text-white hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="558580" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="flex mt-4 md:m-0">
                <div class="-mx-4 flex">
                    <Element canvas style={{ width: "100%" }} id={"div181316"} is="div">
            <Button href={"#"} classn={"px-4 text-sm font-medium text-gray-600 transition-colors duration-300 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="859238" is="div"><Text text="About" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div900586"} is="div">
            <Button href={"#"} classn={"px-4 text-sm font-medium text-gray-600 transition-colors duration-300 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="514219" is="div"><Text text="Blog" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div532513"} is="div">
            <Button href={"#"} classn={"px-4 text-sm font-medium text-gray-600 transition-colors duration-300 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="308323" is="div"><Text text="News" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div276471"} is="div">
            <Button href={"#"} classn={"px-4 text-sm font-medium text-gray-600 transition-colors duration-300 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="742003" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
                </div>
            </div>
        </div>
    </div>
    </footer>

      </div>;
};
Footer5.craft = {
  displayName: "Footer 5"
};