import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero10 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <header>
    <nav x-data="{ isOpen: false }" class="bg-white shadow dark:bg-gray-900">
        <div class="container px-6 py-4 mx-auto">
            <div class="lg:flex lg:items-center lg:justify-between">
                <div class="flex items-center justify-between">
                    <div class="text-xl font-semibold text-gray-700">
                        <Element canvas style={{ width: "100%" }} id={"div479689"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="881838" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
                    </div>

                    
                    <div class="flex lg:hidden">
                       <Element canvas style={{ width: "100%" }} id={"div274510"} is="div">
            <Button classn={"text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"}>
              
                            <svg x-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                            </svg>
                    
                            <svg x-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        
            </Button>
          </Element>
                    </div>
                </div>

               
                <div class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white shadow-md lg:bg-transparent lg:dark:bg-transparent lg:shadow-none dark:bg-gray-900 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0">
                    <div class="-mx-4 lg:flex lg:items-center">
                        <Element canvas style={{ width: "100%" }} id={"div534384"} is="div">
            <Button href={"#"} classn={"block mx-4 text-gray-700 capitalize dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="492211" is="div"><Text text="Web developers" /></Element>} 
            </Button>
          </Element>
                        <Element canvas style={{ width: "100%" }} id={"div799478"} is="div">
            <Button href={"#"} classn={"block mx-4 mt-4 text-gray-700 capitalize lg:mt-0 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="392487" is="div"><Text text="Web Designers" /></Element>} 
            </Button>
          </Element>
                        <Element canvas style={{ width: "100%" }} id={"div201782"} is="div">
            <Button href={"#"} classn={"block mx-4 mt-4 text-gray-700 capitalize lg:mt-0 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="533586" is="div"><Text text="UI/UX Designers" /></Element>} 
            </Button>
          </Element>
                        <Element canvas style={{ width: "100%" }} id={"div606279"} is="div">
            <Button href={"#"} classn={"block mx-4 mt-4 text-gray-700 capitalize lg:mt-0 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="546396" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <div class="w-full bg-center bg-cover h-[32rem] relative">
        <Element canvas style={{ width: "100%" }} id={"div609489"} is="div">
          <ImageC class="w-full h-full absolute" src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"  />
        </Element>
        <div class="flex items-center relative justify-center w-full h-full bg-gray-900 bg-opacity-50">
            <div class="text-center">
                <h1 class="text-2xl font-semibold text-white uppercase lg:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="655297" is="div"><Text text="Build Your new" /></Element>} <span class="text-blue-400 underline"> {<Element canvas style={{display:"inline-block"}} id="995486" is="div"><Text text="Saas" /></Element>} </span></h1>
                <Element canvas style={{ width: "100%" }} id={"div164585"} is="div">
            <Button classn={"w-full px-4 py-2 mt-4 text-sm font-medium text-white uppercase transition-colors duration-300 transform bg-blue-600 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="909744" is="div"><Text text="Start project" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </header>

      </div>;
};
Hero10.craft = {
  displayName: "Hero 10"
};