import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Footer33 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <footer class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap md:text-left text-center order-first">
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="984815" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="181562" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="565308" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="322767" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="342935" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="954155" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="627728" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="696129" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="102205" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="918674" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="658110" is="div"><Text text="CATEGORIES" /></Element>} </h2>
        <nav class="list-none mb-10">
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="248334" is="div"><Text text="First Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="188738" is="div"><Text text="Second Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="580325" is="div"><Text text="Third Link" /></Element>} </a>
          </li>
          <li>
            <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="848221" is="div"><Text text="Fourth Link" /></Element>} </a>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <form>
          <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="461589" is="div"><Text text="SUBSCRIBE" /></Element>} </h2>
          <div class="flex xl:flex-nowrap md:flex-nowrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">
            <div class="relative w-40 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2">
              <label for="footer-field" class="leading-7 text-sm text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="854794" is="div"><Text text="Placeholder" /></Element>} </label>
              <input type="text" id="footer-field" name="footer-field" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required />
            </div>
            <Element canvas style={{ width: "100%" }} id={"div674338"} is="div">
            <Button classn={"lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="794386" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
          </div>
          <p class="text-gray-500 text-sm mt-2 md:text-left text-center"> {<Element canvas style={{display:"inline-block"}} id="895903" is="div"><Text text="Bitters chicharrones fanny pack" /></Element>} <br class="lg:block hidden" /> {<Element canvas style={{display:"inline-block"}} id="205764" is="div"><Text text="waistcoat green juice" /></Element>} </p>
        </form>
      </div>
    </div>
  </div>
  <div class="bg-gray-100">
    <div class="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
      <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </svg>
        <span class="ml-3 text-xl"> {<Element canvas style={{display:"inline-block"}} id="861444" is="div"><Text text="Tailblocks" /></Element>} </span>
      </a>
      <p class="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4"> {<Element canvas style={{display:"inline-block"}} id="308804" is="div"><Text text="© 2020 Tailblocks —" /></Element>} <Element canvas style={{ width: "100%" }} id={"div925544"} is="div">
            <Button href={"https://twitter.com/knyttneve"} classn={"text-gray-600 ml-1"}>
               {<Element canvas style={{display:"inline-block"}} id="702462" is="div"><Text text="@knyttneve" /></Element>} 
            </Button>
          </Element>
      </p>
      <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start items-end">
        <a class="text-gray-500">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </div>
    </footer>

      </div>;
};
Footer33.craft = {
  displayName: "Footer 33"
};