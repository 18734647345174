import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <nav x-data="{ isOpen: false }" class="container p-6 mx-auto lg:flex lg:justify-between lg:items-center">
        <div class="flex items-center justify-between">
            <div>
                <Element canvas style={{ width: "100%" }} id={"div152314"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="988844" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
            </div>

            
            <div class="flex lg:hidden">
                <Element canvas style={{ width: "100%" }} id={"div415816"} is="div">
            <Button classn={"text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"}>
              
                    <svg x-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                    </svg>
            
                    <svg x-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                
            </Button>
          </Element>
            </div>
        </div>

       
        <div class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white shadow-md lg:bg-transparent lg:dark:bg-transparent lg:shadow-none dark:bg-gray-900 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center">
            <div class="flex flex-col space-y-4 lg:mt-0 lg:flex-row lg:-px-8 lg:space-y-0">
                <Element canvas style={{ width: "100%" }} id={"div579898"} is="div">
            <Button href={"#"} classn={"text-gray-700 transition-colors duration-300 transform lg:mx-8 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="790876" is="div"><Text text="Home" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div336661"} is="div">
            <Button href={"#"} classn={"text-gray-700 transition-colors duration-300 transform lg:mx-8 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="424855" is="div"><Text text="Components" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div150184"} is="div">
            <Button href={"#"} classn={"text-gray-700 transition-colors duration-300 transform lg:mx-8 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="618412" is="div"><Text text="Pricing" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div416926"} is="div">
            <Button href={"#"} classn={"text-gray-700 transition-colors duration-300 transform lg:mx-8 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="662878" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
            </div>

            <Element canvas style={{ width: "100%" }} id={"div671121"} is="div">
            <Button href={"#"} classn={"block px-6 py-2.5 mt-4 font-medium leading-5 text-center text-white capitalize bg-blue-600 rounded-lg lg:mt-0 hover:bg-blue-500 lg:w-auto"}>
               {<Element canvas style={{display:"inline-block"}} id="800525" is="div"><Text text="Get started" /></Element>} 
            </Button>
          </Element>
        </div>
    </nav>

    <div class="container px-6 py-16 mx-auto text-center">
        <div class="max-w-lg mx-auto">
            <h1 class="text-3xl font-bold text-gray-800 dark:text-white lg:text-4xl"> {<Element canvas style={{display:"inline-block"}} id="124003" is="div"><Text text="Building Your Next App with our Awesome components" /></Element>} </h1>
            <p class="mt-6 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="753295" is="div"><Text text="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Libero similique
                obcaecati illum mollitia." /></Element>} </p>
            <Element canvas style={{ width: "100%" }} id={"div909839"} is="div">
            <Button classn={"px-6 py-2.5 mt-6 text-sm font-medium leading-5 text-center text-white capitalize bg-blue-600 rounded-lg hover:bg-blue-500 lg:mx-0 lg:w-auto focus:outline-none"}>
               {<Element canvas style={{display:"inline-block"}} id="209665" is="div"><Text text="Start 14-Day free trial" /></Element>} 
            </Button>
          </Element>
            <p class="mt-3 text-sm text-gray-400 "> {<Element canvas style={{display:"inline-block"}} id="743692" is="div"><Text text="No credit card required" /></Element>} </p>
        </div>

        <div class="flex justify-center mt-10">
            <Element canvas style={{ width: "100%" }} id={"div214103"} is="div">
          <ImageC class="object-cover w-full h-96 rounded-xl lg:w-4/5" src="https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"  />
        </Element>
        </div>
    </div>
    </section>

      </div>;
};
Hero3.craft = {
  displayName: "Hero 3"
};