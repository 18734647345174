import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Footer2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <footer class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-8 mx-auto">
        <div class="text-center">
            <Element canvas style={{ width: "100%" }} id={"div748217"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 dark:text-white hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="768598" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>

            <p class="max-w-md mx-auto mt-2 text-gray-500 dark:text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="779638" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit." /></Element>} </p>

            <div class="flex flex-col mt-4 sm:flex-row sm:items-center sm:justify-center">
                <Element canvas style={{ width: "100%" }} id={"div131115"} is="div">
            <Button classn={"flex items-center justify-center order-1 w-full px-2 py-2 mt-3 text-sm tracking-wide text-gray-600 capitalize transition-colors duration-300 transform border rounded-md sm:mx-2 dark:border-gray-400 dark:text-gray-300 sm:mt-0 sm:w-auto hover:bg-gray-50 focus:outline-none focus:ring dark:hover:bg-gray-800 focus:ring-gray-300 focus:ring-opacity-40"}>
              
                    <svg class="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM4 12.172C4.04732 16.5732 7.64111 20.1095 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.68451 16.444 4.10977 12.0425 4.086C7.64111 4.06246 4.04732 7.59876 4 12V12.172ZM10 16.5V7.5L16 12L10 16.5Z" fill="currentColor"></path>
            </svg>

                    <span class="mx-1"> {<Element canvas style={{display:"inline-block"}} id="541201" is="div"><Text text="View Demo" /></Element>} </span>
                
            </Button>
          </Element>

                <Element canvas style={{ width: "100%" }} id={"div856866"} is="div">
            <Button classn={"w-full px-5 py-2 text-sm tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md sm:mx-2 sm:order-2 sm:w-auto hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="191846" is="div"><Text text="Get started" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>

        <hr class="my-10 border-gray-200 dark:border-gray-700" />

        <div class="flex flex-col items-center sm:flex-row sm:justify-between">
            <p class="text-sm text-gray-400"> {<Element canvas style={{display:"inline-block"}} id="480613" is="div"><Text text="© Copyright 2021. All Rights Reserved." /></Element>} </p>

            <div class="flex mt-3 -mx-2 sm:mt-0">
                <Element canvas style={{ width: "100%" }} id={"div713100"} is="div">
            <Button href={"#"} classn={"mx-2 text-sm text-gray-400 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="603471" is="div"><Text text="Teams" /></Element>} 
            </Button>
          </Element>

                <Element canvas style={{ width: "100%" }} id={"div440250"} is="div">
            <Button href={"#"} classn={"mx-2 text-sm text-gray-400 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="187879" is="div"><Text text="Privacy" /></Element>} 
            </Button>
          </Element>

                <Element canvas style={{ width: "100%" }} id={"div567750"} is="div">
            <Button href={"#"} classn={"mx-2 text-sm text-gray-400 transition-colors duration-300 hover:text-gray-500 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="451222" is="div"><Text text="Cookies" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </footer>

      </div>;
};
Footer2.craft = {
  displayName: "Footer 2"
};