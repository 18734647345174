import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog11 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap -mx-4 -my-8">
      <div class="py-8 px-4 lg:w-1/3">
        <div class="h-full flex items-start">
          <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
            <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200"> {<Element canvas style={{display:"inline-block"}} id="251633" is="div"><Text text="Jul" /></Element>} </span>
            <span class="font-medium text-lg text-gray-800 title-font leading-none"> {<Element canvas style={{display:"inline-block"}} id="374025" is="div"><Text text="18" /></Element>} </span>
          </div>
          <div class="flex-grow pl-6">
            <h2 class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1"> {<Element canvas style={{display:"inline-block"}} id="481076" is="div"><Text text="CATEGORY" /></Element>} </h2>
            <h1 class="title-font text-xl font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="304663" is="div"><Text text="The 400 Blows" /></Element>} </h1>
            <p class="leading-relaxed mb-5"> {<Element canvas style={{display:"inline-block"}} id="290267" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
            <a class="inline-flex items-center">
              <Element canvas style={{ width: "100%" }} id={"div250863"} is="div">
          <ImageC alt="blog" src="https://dummyimage.com/103x103" class="w-8 h-8 rounded-full flex-shrink-0 object-cover object-center"  />
        </Element>
              <span class="flex-grow flex flex-col pl-3">
                <span class="title-font font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="460125" is="div"><Text text="Alper Kamu" /></Element>} </span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="py-8 px-4 lg:w-1/3">
        <div class="h-full flex items-start">
          <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
            <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200"> {<Element canvas style={{display:"inline-block"}} id="996454" is="div"><Text text="Jul" /></Element>} </span>
            <span class="font-medium text-lg text-gray-800 title-font leading-none"> {<Element canvas style={{display:"inline-block"}} id="655757" is="div"><Text text="18" /></Element>} </span>
          </div>
          <div class="flex-grow pl-6">
            <h2 class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1"> {<Element canvas style={{display:"inline-block"}} id="387359" is="div"><Text text="CATEGORY" /></Element>} </h2>
            <h1 class="title-font text-xl font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="293093" is="div"><Text text="Shooting Stars" /></Element>} </h1>
            <p class="leading-relaxed mb-5"> {<Element canvas style={{display:"inline-block"}} id="918730" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
            <a class="inline-flex items-center">
              <Element canvas style={{ width: "100%" }} id={"div965596"} is="div">
          <ImageC alt="blog" src="https://dummyimage.com/102x102" class="w-8 h-8 rounded-full flex-shrink-0 object-cover object-center"  />
        </Element>
              <span class="flex-grow flex flex-col pl-3">
                <span class="title-font font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="477860" is="div"><Text text="Holden Caulfield" /></Element>} </span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="py-8 px-4 lg:w-1/3">
        <div class="h-full flex items-start">
          <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
            <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200"> {<Element canvas style={{display:"inline-block"}} id="147953" is="div"><Text text="Jul" /></Element>} </span>
            <span class="font-medium text-lg text-gray-800 title-font leading-none"> {<Element canvas style={{display:"inline-block"}} id="296155" is="div"><Text text="18" /></Element>} </span>
          </div>
          <div class="flex-grow pl-6">
            <h2 class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1"> {<Element canvas style={{display:"inline-block"}} id="338451" is="div"><Text text="CATEGORY" /></Element>} </h2>
            <h1 class="title-font text-xl font-medium text-gray-900 mb-3"> {<Element canvas style={{display:"inline-block"}} id="664915" is="div"><Text text="Neptune" /></Element>} </h1>
            <p class="leading-relaxed mb-5"> {<Element canvas style={{display:"inline-block"}} id="996748" is="div"><Text text="Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat." /></Element>} </p>
            <a class="inline-flex items-center">
              <Element canvas style={{ width: "100%" }} id={"div967298"} is="div">
          <ImageC alt="blog" src="https://dummyimage.com/101x101" class="w-8 h-8 rounded-full flex-shrink-0 object-cover object-center"  />
        </Element>
              <span class="flex-grow flex flex-col pl-3">
                <span class="title-font font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="260130" is="div"><Text text="Henry Letham" /></Element>} </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
    </section>
  
      </div>;
};
Blog11.craft = {
  displayName: "Blog 11"
};