import React, { useEffect, useState } from "react";
import {useDispatch, } from "react-redux"
import { useLocation, useHistory } from "react-router-dom";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import arrowDownUsers from "../../../assests/images/arrowDownUsers.svg"
import searchInput from "../../../assests/images/searchInput.svg"
import setting from "../../../assests/images/setting.svg"
import arrowRight from "../../../assests/images/arrowRight.svg"

import add from "../../../assests/images/add.svg"
import {useLazyQuery, useMutation} from "@apollo/client"
import {AccountTable as RecordsTable} from "../../resuable/accountTable"
import {Form, FormBuilder} from "@tsed/react-formio";
import moment from "moment"
import editIcon from "../../../assests/images/editIcon.svg"
import deleteIcon from "../../../assests/images/deleteIcon.svg"
import xI from "../../../assests/images/x.svg"
import dot from "../../../assests/images/dot.svg"
import threedots from "../../../assests/images/threedots.svg"
import 'formiojs/dist/formio.full.css';
import AddFormModal from "../../resuable/modals/addFormModal";
import addFormMutation from "../../../graphql/mutations/form/addFormMutation";
import AddRecordModal from "../../resuable/modals/addRecordModal";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import addRecordMutation from "../../../graphql/mutations/record/addRecordMutation";
import allRecordsQuery from "../../../graphql/queries/record/allRecordsQuery";
import { DataSpinner } from "../../resuable/spinner/dataSpinner";

const AdminDatabase = ({records, setRecordsData, activeTable, user, teamsRefetch, usersRefetch , tables, tablesRefetch}) => {
    
    let location = useLocation()
    let history = useHistory()
    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    const [addForm, {error: addFormError, data: addFormData, loading: addFormLoading}] = useMutation(addFormMutation)
    const [addRecord, {error: addRecordError, data: addRecordData, loading: addRecordLoading}] = useMutation(addRecordMutation)
    const [allRecords, { error: recordsError, data: recordsData, loading: recordsLoading, refetch: recordsRefetch }] = useLazyQuery(allRecordsQuery, {
        fetchPolicy: "no-cache",
      });

    useEffect(() => {
        if(!activeTable) {
            history.push("/admin/dashboard")
        }
    },[])


    const dispatch = useDispatch()


    const [activeForm, setActiveform] = useState(null)
    const [addRecordModal, setAddRecordModal] = useState(null)
    
    const [designView, setDesignView] = useState(false)

    const [display, setDisplay] = useState("form");
    const [formComponents, setFormComponents] = useState()
    const [addFormCollectionModal, setAddFormCollectionModal] = useState(null)
    const [formName, setFormName] = useState(null)
 
    const addFormFunc = () => {
        // dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        // /////console.log(activeTable)
        // addForm({
        //     variables:{
        //         collectionId: activeTable._id,
        //         name: formName,
        //         form: JSON.stringify(formComponents)
        //     }
        // }).then((X) => {
        //     tablesRefetch()        
        //     setDesignView(false)
        //     setAddFormCollectionModal(null)
        //     setFormName("")
            
        // })
    }
    const [formsTest, setFormsTest] = useState([
        {name: "form1", createdAt:"05.01.22", createdBy:"user2", _id: 1232},
        {name: "form2", createdAt:"05.01.22", createdBy:"user2", _id: 1232},
        {name: "form3", createdAt:"05.01.22", createdBy:"user2", _id: 1232},
        {name: "form4", createdAt:"05.01.22", createdBy:"user2", _id: 1232},
    
    ])

    const [formColumn, setFormColumn] = useState(null)
    const [formData, setFormData] = useState(null)
    const [recordData, setRecordData] = useState(null)

  useEffect(() => {
    if(activeTable) {
        setActiveform(activeTable?.forms[0])
       }
  },[activeTable])
  useEffect(() => {
    if(activeForm) {
        setFormData(null)
        allRecords({
            variables:{
                collectionId: activeTable._id,
                formId: activeForm._id
            }
        })
        let form = JSON.parse(activeForm.form)
        setFormColumn(form.filter((y) => y.label !== "Submit").map(x => {
            return {
                name: x.label,
                cell: row => <div>{row[`${x.key}`]}</div>
            }                 
        }))
        return () => {
            setFormColumn(null)
        }
    }
  },[activeForm])



  const addRecordFunc = (e) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setAddRecordModal(null)
      addRecord({
          variables:{
              formId: activeForm._id,
              collectionId: activeTable._id,
              data: JSON.stringify(e.data)
          }
      }).then(() => {
          recordsRefetch()      
      })
  }

  useEffect(() => {
    if(recordsData) {
        setFormData(recordsData.allRecords?.map(x => {
            let formRecord = JSON.parse(x.data)
            return formRecord
        }))
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
    return () => {
        setFormData(null)
    }
  },[recordsData])

  const changeView = () => {
      
  }
 return (
        <div className="adminContent column" style={{justifyContent:"flex-start"}}>
            <AddRecordModal 
                open={addRecordModal}
                setOpen={setAddRecordModal}
                activeForm={activeForm}
                setActiveform={setActiveform}
                addRecord={addRecordFunc}
                recordData={recordData}
                setRecordData={setRecordData}
                recordsRefetch={recordsRefetch}
            />
            {returnToHomeScreen()}
            <div className="workspaceHeader">
                <div onClick={() => changeView()} className="workspaceName">
                    {activeTable?.name}
                </div>
                <div className="workspaceButtons">                    
                    <div  
                    onClick={() => setDesignView(!designView)}
                    // onClick={() => { 
                    //     setTeamName(activeTable.name)
                    //     setTeamAddModal("Edit a Team")
                    // }} 
                    className="adminButton">
                    <img className="buttonIconImage" src={!designView? editIcon : xI} />
                    </div>
                    {/* <div 
                    // onClick={() => setDeleteModal("Team")} 
                    className="adminButton">
                    <img className="buttonIconImage" src={deleteIcon} />
                    </div> */}

                </div>

            </div>
            {!designView ? <>
            {activeTable?.forms?.length > 0 && <><div className="workspaceHeader">
                <div className="workspaceName" style={{fontSize:"18px"}}>
                The most recent forms:
                </div>
                <div className="workspaceButtons">                    
                <div className="moreButton">More <img style={{marginLeft:".5rem"}} src={arrowRight}/></div>
                </div>

            </div>
            <div className="workspaceHeader cardsContainer">
            {activeTable?.forms?.map((x, i) => (
                <div key={i} onClick={() => setActiveform(x)} style={{border: activeForm?._id == x._id ? "2px solid #6064D2" : "0px", cursor:"pointer", minWidth:"200px"}} className="workspaceCard">
                    <div className="workspaceThumbnail">{x.name}</div>
                    <div className="workspaceHeader">
                        <div className="workspaceName">
                            
                        </div>
                            <img className="buttonIconImage" src={threedots} />
                    </div>
                    <div className="workspacefooter">
                        <div className="footerContent">
                            Created {moment(x.createdAt).format("MM/DD/YYYY")} 
                        </div>
                        {/* <img className="dot" src={dot} />
                        <div className="footerContent">
                            {x.createdBy}
                        </div> */}
                    </div>

                </div>
            ))}
            </div></>}

            <div className="workspaceContainer" style={{marginTop:"4rem"}}>
            <div className="workspaceHeader">
                <div className="workspaceName" style={{display:"flex", alignItems:"center"}}>
                        Records
                        { activeForm && <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => setAddRecordModal("Record")}
                        
                        />}
                </div>
                <div className="workspaceButtons">
                    <div className={`routeContainer sideSearch userSearch`} style={{width:"269px" , margin:"0px", fontWeight:"normal", justifyContent:"flex-start"}}>
                    <img
                                className="routeIcon"
                                src={searchInput} 
                    />
                    <div className="searchT" style={{margin:"0 16px", color:"#5E6A7A"}}>
                        Search                        
                    </div>
                    </div>
                    <div className="adminButton" style={{width:"fit-content"}}> 
                    <span style={{margin:"0 16px", color:"#5E6A7A"}} className="buttonIconImage">Newest</span>
                    <img className="buttonIconImage" style={{margin:"0 12px"}} src={arrowDownUsers} />
                    </div>
                    <div className="adminButton" >
                    <img className="buttonIconImage" src={setting} />
                    </div>

                </div>
            </div>

                {formData ?<RecordsTable
                    accountColumns={formColumn || []}
                    accountData={formData || []}
                    labeling={true}
                    loading={!recordsData}
                /> : <DataSpinner /> }
            </div>
            </> : (
                <>
                <div className="App container py-3">
                    <AddFormModal 
                        open={addFormCollectionModal}
                        setOpen={setAddFormCollectionModal}
                        formName={formName}
                        setFormName={setFormName}
                        addForm={addFormFunc}
                        addFormLoading={addFormLoading}
                    />
      <div className="row">
      </div>
      <FormBuilder display={display} onChange={(e) => setFormComponents(e)} components={formComponents || []} />
    </div>                
    <div style={{position:"fixed", bottom:10, right:10, width:"100px"}} className={`auth-button`} onClick={() => setAddFormCollectionModal("Form")}>Save view</div>
                </>
                // form is here
            )}
        </div>
    )
}

export default AdminDatabase