import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Portfolio4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="903872" is="div"><Text text="Latest Work" /></Element>} </h1>


        <div class="flex py-4 mt-4 overflow-x-auto overflow-y-hidden md:justify-center dark:border-gray-700">
            <Element canvas style={{ width: "100%" }} id={"div111245"} is="div">
            <Button classn={"h-12 px-8 py-2 -mb-px text-sm text-center text-blue-600 bg-transparent border-b-2 border-blue-500 sm:text-base dark:border-blue-400 dark:text-blue-300 whitespace-nowrap focus:outline-none"}>
               {<Element canvas style={{display:"inline-block"}} id="747189" is="div"><Text text="Animation" /></Element>} 
            </Button>
          </Element>

            <Element canvas style={{ width: "100%" }} id={"div226158"} is="div">
            <Button classn={"h-12 px-8 py-2 -mb-px text-sm text-center text-gray-700 bg-transparent border-b-2 border-gray-200 sm:text-base dark:text-white whitespace-nowrap cursor-base focus:outline-none dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400"}>
               {<Element canvas style={{display:"inline-block"}} id="307127" is="div"><Text text="Web design" /></Element>} 
            </Button>
          </Element>

            <Element canvas style={{ width: "100%" }} id={"div629397"} is="div">
            <Button classn={"h-12 px-8 py-2 -mb-px text-sm text-center text-gray-700 bg-transparent border-b-2 border-gray-200 sm:text-base dark:text-white whitespace-nowrap cursor-base focus:outline-none dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400"}>
               {<Element canvas style={{display:"inline-block"}} id="501834" is="div"><Text text="App design" /></Element>} 
            </Button>
          </Element>

            <Element canvas style={{ width: "100%" }} id={"div833807"} is="div">
            <Button classn={"h-12 px-8 py-2 -mb-px text-sm text-center text-gray-700 bg-transparent border-b-2 border-gray-200 sm:text-base dark:text-white whitespace-nowrap cursor-base focus:outline-none dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400"}>
               {<Element canvas style={{display:"inline-block"}} id="142362" is="div"><Text text="Branding" /></Element>} 
            </Button>
          </Element>
        </div>

        <section class="mt-8 space-y-8 lg:mt-12">
            <section class="lg:flex lg:items-center">
                <div class="lg:w-1/2 ">
                    <p class="text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="208058" is="div"><Text text="Ui kit" /></Element>} </p>
                    <h2 class="mt-2 text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="122325" is="div"><Text text="Block
                        of Ui kit collections" /></Element>} </h2>
                </div>

                <div class="mt-4 lg:w-1/2 lg:mt-0">
                    <Element canvas style={{ width: "100%" }} id={"div367268"} is="div">
          <ImageC class="object-cover w-full h-64 rounded-lg md:h-96" src="https://images.unsplash.com/photo-1621609764180-2ca554a9d6f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt=""  />
        </Element>
                </div>
            </section>

            <section class="lg:flex lg:items-center">
                <div class="lg:w-1/2 ">
                    <p class="text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="920652" is="div"><Text text="Wareframe" /></Element>} </p>
                    <h2 class="mt-2 text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="919568" is="div"><Text text="Best
                        free website layout" /></Element>} </h2>
                </div>

                <div class="mt-4 lg:w-1/2 lg:mt-0">
                    <Element canvas style={{ width: "100%" }} id={"div464417"} is="div">
          <ImageC class="object-cover w-full h-64 rounded-lg md:h-96" src="https://images.unsplash.com/photo-1600132806608-231446b2e7af?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80" alt=""  />
        </Element>
                </div>
            </section>
        </section>
    </div>
    </section>

      </div>;
};
Portfolio4.craft = {
  displayName: "Portfolio 4"
};