   import React from 'react'
   import Modal from "react-modal"
   import { useSelector } from 'react-redux';
   import BounceLoader from "react-spinners/BounceLoader";
   import { css } from "@emotion/css";
   import "../../../assests/styles/Modal.scss";

   const Spinner = (props) => {
    const {
        isOpen,
    } = props

    const loadingSpinnerActive = useSelector(
        (state) => state.loadingSpinnerActive.loading_spinner
      );

    const override = css`
    display: block;
    position: absolute;
    left: 25%;
    right: 25%;
  `;
       return (
        <Modal
        isOpen={false && isOpen || loadingSpinnerActive}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999"
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            paddingBottom: "10%",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        {isOpen && (
          <BounceLoader
            size={100}
            css={override}
            color={"rgb(44, 44, 52)"}
            loading={isOpen || loadingSpinnerActive}
          />
        )}
      </Modal>
        
       ) 
   }
  

   export default Spinner