import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Team1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    
    <div class="container px-6 py-8 mx-auto">
        <h2 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="571460" is="div"><Text text="Our Team" /></Element>} </h2>

        <div class="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div class="w-full max-w-xs text-center">
                <Element canvas style={{ width: "100%" }} id={"div848499"} is="div">
          <ImageC class="object-cover object-center w-full h-48 mx-auto rounded-lg" src="https://images.unsplash.com/photo-1493863641943-9b68992a8d07?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=739&q=80" alt="avatar"  />
        </Element>

                <div class="mt-2">
                    <h3 class="text-lg font-medium text-gray-700 dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="438203" is="div"><Text text="Ahmed Omer" /></Element>} </h3>
                    <span class="mt-1 font-medium text-gray-600 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="800880" is="div"><Text text="CEO" /></Element>} </span>
                </div>
            </div>

            <div class="w-full max-w-xs text-center">
                <Element canvas style={{ width: "100%" }} id={"div825200"} is="div">
          <ImageC class="object-cover object-center w-full h-48 mx-auto rounded-lg" src="https://images.unsplash.com/photo-1516756587022-7891ad56a8cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" alt="avatar"  />
        </Element>

                <div class="mt-2">
                    <h3 class="text-lg font-medium text-gray-700 dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="485447" is="div"><Text text="Jane Doe" /></Element>} </h3>
                    <span class="mt-1 font-medium text-gray-600 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="706810" is="div"><Text text="Co-founder" /></Element>} </span>
                </div>
            </div>

            <div class="w-full max-w-xs text-center">
                <Element canvas style={{ width: "100%" }} id={"div804701"} is="div">
          <ImageC class="object-cover object-center w-full h-48 mx-auto rounded-lg" src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=731&q=80" alt="avatar"  />
        </Element>

                <div class="mt-2">
                    <h3 class="text-lg font-medium text-gray-700 dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="264698" is="div"><Text text="Steve Ben" /></Element>} </h3>
                    <span class="mt-1 font-medium text-gray-600 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="385786" is="div"><Text text="UI/UX" /></Element>} </span>
                </div>
            </div>

            <div class="w-full max-w-xs text-center">
                <Element canvas style={{ width: "100%" }} id={"div927165"} is="div">
          <ImageC class="object-cover object-center w-full h-48 mx-auto rounded-lg" src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" alt="avatar"  />
        </Element>

                <div class="mt-2">
                    <h3 class="text-lg font-medium text-gray-700 dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="977923" is="div"><Text text="Patterson Johnson" /></Element>} </h3>
                    <span class="mt-1 font-medium text-gray-600 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="743899" is="div"><Text text="Software Engineer" /></Element>} </span>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Team1.craft = {
  displayName: "Team 1"
};