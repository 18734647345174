import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Cta7 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <aside class="overflow-hidden bg-gray-900 sm:grid sm:grid-cols-2">
  <div class="p-8 md:p-12 lg:px-16 lg:py-24">
    <div class="mx-auto max-w-xl text-center sm:text-left">
      <h2 class="text-2xl font-bold text-white md:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="889799" is="div"><Text text="Lorem, ipsum dolor sit amet consectetur adipisicing elit" /></Element>} </h2>

      <p class="hidden text-gray-300 md:mt-4 md:block"> {<Element canvas style={{display:"inline-block"}} id="832549" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas
        tempus tellus etiam sed. Quam a scelerisque amet ullamcorper eu enim et
        fermentum, augue. Aliquet amet volutpat quisque ut interdum tincidunt
        duis." /></Element>} </p>

      <div class="mt-4 md:mt-8">
        <Element canvas style={{ width: "100%" }} id={"div178565"} is="div">
            <Button href={"#"} classn={"inline-block rounded bg-emerald-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-yellow-400"}>
               {<Element canvas style={{display:"inline-block"}} id="956671" is="div"><Text text="Get Started Today" /></Element>} 
            </Button>
          </Element>
      </div>
    </div>
  </div>

  <Element canvas style={{ width: "100%" }} id={"div151122"} is="div">
          <ImageC alt="Student" src="https://images.unsplash.com/photo-1464582883107-8adf2dca8a9f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" class="h-56 w-full object-cover sm:h-full"  />
        </Element>
    </aside>

      </div>;
};
Cta7.craft = {
  displayName: "Cta 7"
};