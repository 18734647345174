import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';

export const CardSettings = () => {
  return (
    <div style={{height:"100%", overflow:"auto"}}>
      <ToolbarSection
        title="Image"
        props={['activeImage', 'source']}
      >
        <ToolbarItem full={true} propKey="activeImage" type="switch" label="Active" />
        <ToolbarItem
          full={true}
          propKey="source"
          type="text"
          label="source"
        />
      </ToolbarSection>
      <ToolbarSection
        title="Header"
        props={['activeHeader', 'header']}
      >
        <ToolbarItem full={true} propKey="activeHeader" type="switch" label="Active" />
        <ToolbarItem
          full={true}
          propKey="header"
          type="text"
          label="Header"
        />
      </ToolbarSection>
      <ToolbarSection
        title="Content"
        props={['activeContent', 'content']}
      >
        <ToolbarItem full={true} propKey="activeContent" type="switch" label="Active" />
        <ToolbarItem
          full={true}
          propKey="content"
          type="text"
          label="Content"
        />
      </ToolbarSection>
      <ToolbarSection
        title="Footer"
        props={['activeFooter', 'footer']}
      >
        <ToolbarItem full={true} propKey="activeFooter" type="switch" label="Active" />
        <ToolbarItem
          full={true}
          propKey="footer"
          type="text"
          label="Footer"
        />
      </ToolbarSection>
    </div>
  );
};
