import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Portfolio2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="603938" is="div"><Text text="Portfolio" /></Element>} </h1>

        <p class="mt-4 text-center text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="265097" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quam voluptatibus" /></Element>} </p>


        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            <div>
                <Element canvas style={{ width: "100%" }} id={"div768145"} is="div">
          <ImageC class="object-cover w-full rounded-lg h-96 " src="https://images.unsplash.com/photo-1621111848501-8d3634f82336?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1565&q=80" alt=""  />
        </Element>
                <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="945971" is="div"><Text text="Best website
                    collections" /></Element>} </h2>
                <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="936521" is="div"><Text text="Website" /></Element>} </p>
            </div>

            <div>
                <Element canvas style={{ width: "100%" }} id={"div710631"} is="div">
          <ImageC class="object-cover w-full rounded-lg h-96 " src="https://images.unsplash.com/photo-1621609764180-2ca554a9d6f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt=""  />
        </Element>
                <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="309975" is="div"><Text text="Block of Ui kit
                    collections" /></Element>} </h2>
                <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="837574" is="div"><Text text="Ui kit" /></Element>} </p>
            </div>

            <div>
                <Element canvas style={{ width: "100%" }} id={"div255788"} is="div">
          <ImageC class="object-cover w-full rounded-lg h-96 " src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>
                <h2 class="mt-4 text-2xl font-semibold text-gray-800 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="447849" is="div"><Text text="Ton’s of mobile mockup" /></Element>} </h2>
                <p class="mt-2 text-lg tracking-wider text-blue-500 uppercase dark:text-blue-400 "> {<Element canvas style={{display:"inline-block"}} id="234653" is="div"><Text text="Mockups" /></Element>} </p>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Portfolio2.craft = {
  displayName: "Portfolio 2"
};