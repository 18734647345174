import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Faq6 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="space-y-4">
  <details class="group" open>
    <summary class="flex cursor-pointer items-center justify-between rounded-lg bg-gray-50 p-4">
      <h5 class="font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="856617" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing?" /></Element>} </h5>

      <svg class="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
      </svg>
    </summary>

    <p class="mt-4 px-4 leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="322427" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic veritatis
      molestias culpa in, recusandae laboriosam neque aliquid libero nesciunt
      voluptate dicta quo officiis explicabo consequuntur distinctio corporis
      earum similique!" /></Element>} </p>
  </details>

  <details class="group">
    <summary class="flex cursor-pointer items-center justify-between rounded-lg bg-gray-50 p-4">
      <h5 class="font-medium text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="856269" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing?" /></Element>} </h5>

      <svg class="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
      </svg>
    </summary>

    <p class="mt-4 px-4 leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="191388" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic veritatis
      molestias culpa in, recusandae laboriosam neque aliquid libero nesciunt
      voluptate dicta quo officiis explicabo consequuntur distinctio corporis
      earum similique!" /></Element>} </p>
  </details>
    </div>

      </div>;
};
Faq6.craft = {
  displayName: "Faq 6"
};