import * as React from 'react';
import './index.scss';

import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { QRCodeGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import { SampleBase } from './sample-base';
import { FormValidator } from '@syncfusion/ej2-inputs';
//import { DropDownList, ChangeEventArgs } from '@syncfusion/ej2-dropdowns';
import { NumericTextBoxComponent, 
//ChangeEventArgs as NumericChangeEventArgs,
ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
//import { ChangeEventArgs } from "@syncfusion/ej2-calendars";

let canShowError = false;
let customFn = (args) => {
    if (canShowError) {
        return false;
    }
    return true;
};
let options = {
    rules: {
        'textbox_0': { minLength: [customFn, 'Invalid input'] },
    }
};


class Component extends SampleBase {
  formObject;
  render() {
      return (<div className="control-pane">
      {/* <style>{sample_css}</style> */}
      <div className="col-lg-8 control-section" style={{ width: "64%" }}>
      <div className="content-wrapper" style={{ width: "100%", height: "590px" }}>
          <div className='center'>
            <div className='centercontrol'>
              <QRCodeGeneratorComponent id="barcode" ref={barcode => barcode} width={"200px"} displayText={{ visibility: false }} invalid={(arg) => {
              canShowError = true;
              this.formObject.validate();
          }} height={"150px"} mode='SVG' type='QRCode' value='Syncfusion'></QRCodeGeneratorComponent>
            </div>
          </div>
        </div>
      </div>
        </div>
      ); 
    } 
}

export default Component