import { gql } from "apollo-boost";

const addDbRecordMutation = gql`
  mutation(
    $url: String,
    $db: String
    $table: String
    $record: String
  ) {
    addDbRecord(
    url:$url,
    db:$db,
    table:$table
    record:$record,
    ) {
    url
    }
  }
`;

export default addDbRecordMutation;
