import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Form8 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>

    <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
  <div class="mx-auto max-w-lg">
    <h1 class="text-center text-2xl font-bold text-indigo-600 sm:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="520544" is="div"><Text text="Get started today" /></Element>} </h1>

    <p class="mx-auto mt-4 max-w-md text-center text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="548126" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati sunt
      dolores deleniti inventore quaerat mollitia?" /></Element>} </p>

    <form action="" class="mt-6 mb-0 space-y-4 rounded-lg p-8 shadow-2xl">
      <p class="text-lg font-medium"> {<Element canvas style={{display:"inline-block"}} id="386460" is="div"><Text text="Sign in to your account" /></Element>} </p>

      <div>
        <label for="email" class="text-sm font-medium"> {<Element canvas style={{display:"inline-block"}} id="860899" is="div"><Text text="Email" /></Element>} </label>

        <div class="relative mt-1">
          <input type="email" id="email" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Enter email" />

          <span class="absolute inset-y-0 right-4 inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
            </svg>
          </span>
        </div>
      </div>

      <div>
        <label for="password" class="text-sm font-medium"> {<Element canvas style={{display:"inline-block"}} id="638780" is="div"><Text text="Password" /></Element>} </label>

        <div class="relative mt-1">
          <input type="password" id="password" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Enter password" />

          <span class="absolute inset-y-0 right-4 inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
          </span>
        </div>
      </div>

      <Element canvas style={{ width: "100%" }} id={"div404998"} is="div">
            <Button classn={"block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"}>
               {<Element canvas style={{display:"inline-block"}} id="670249" is="div"><Text text="Sign in" /></Element>} 
            </Button>
          </Element>

      <p class="text-center text-sm text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="473758" is="div"><Text text="No account?" /></Element>} <Element canvas style={{ width: "100%" }} id={"div458461"} is="div">
            <Button href={""} classn={"underline"}>
               {<Element canvas style={{display:"inline-block"}} id="469851" is="div"><Text text="Sign up" /></Element>} 
            </Button>
          </Element>
      </p>
    </form>
  </div>
    </div>

      </div>;
};
Form8.craft = {
  displayName: "Form 8"
};