import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { useLocation, useHistory } from "react-router-dom";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import ACTION_LOGIN_EMAIL_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_INVALID";
import ACTION_LOGIN_PASSWORD_NOT_INVALID from "../../../actions/user/Login/LoginPassword/Invalid/ACTION_LOGIN_PASSWORD_NOT_INVALID";

import arrowDownUsers from "../../../assests/images/arrowDownUsers.svg"
import searchInput from "../../../assests/images/searchInput.svg"
import setting from "../../../assests/images/setting.svg"
import arrowRight from "../../../assests/images/arrowRight.svg"
import edit from "../../../assests/images/edit.svg"
import trash from "../../../assests/images/trash.svg"
import add from "../../../assests/images/add.svg"
import {useMutation} from "@apollo/client"
import addUserMutation from "../../../graphql/mutations/user/addUserMutation"
import updateUserMutation from "../../../graphql/mutations/user/updateUserMutation"
import updateGroupMutation from "../../../graphql/mutations/group/updateGroupMutation"
import {AccountTable as UsersTable} from "../../resuable/accountTable"
import AddUserModal from "../../resuable/modals/addUserModal";
import { DataSpinner } from "../../resuable/spinner/dataSpinner";
import ACTION_LOGIN_EMAIL_RESET from "../../../actions/user/Login/LoginEmail/ACTION_LOGIN_EMAIL_RESET";
import ACTION_LOGIN_TEMPO_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_NOT_INVALID";
import ACTION_LOGIN_EMAIL_NOT_INVALID from "../../../actions/user/Login/LoginEmail/Invalid/ACTION_LOGIN_EMAIL_NOT_INVALID";
import moment from "moment"
import deleteGroupMutation from "../../../graphql/mutations/group/deleteGroupMutation"

import editIcon from "../../../assests/images/editIcon.svg"
import deleteIcon from "../../../assests/images/deleteIcon.svg"
import X from "../../../assests/images/x.svg"
import dot from "../../../assests/images/dot.svg"
import threedots from "../../../assests/images/threedots.svg"
import AddTeamModal from "../../resuable/modals/addTeamModal";
import DeleteTeamModal from "../../resuable/modals/deleteTeamModal";
import ACTION_LOGIN_TEMPO_INVALID from "../../../actions/user/Login/LoginEmail/Tempo/ACTION_LOGIN_TEMPO_INVALID";
import addGroupMutation from "../../../graphql/mutations/group/addGroupMutation";


const AdminTeams = ({teamsData, users, usersData, setUsersData, activeTeam, setActiveTeam, user, teamsRefetch, usersRefetch }) => {
    let location = useLocation()
    let history = useHistory()
    // const returnToHomeScreen = () => {
    //     if(!Cookies.get("dummy-token")) {
    //         return <Redirect to="/admin" />
    //     }
    // }

    // useEffect(() => {
    //     if(!activeTeam) {
    //         history.push("/admin/dashboard")
    //     }
    // },[])

    const [addGroup, {error: addGroupError, data: addGroupData, loading: addGroupLoading}] = useMutation(addGroupMutation)

    const dispatch = useDispatch()

    const loginEmail = useSelector((state) => state.loginEmail.login_email);

    const activeOrg = useSelector((state) => state.activeOrg.org);

    const loginEmailInvalid = useSelector((state) => state.loginEmailInvalid.login_email_invalid);
    
    const [updateGroup, {error: updateGroupError, data: updateGroupData, loading: updateGroupLoading}] = useMutation(updateGroupMutation)

    const [updateUser, {error: updateUserError, data: updateUserData, loading: updateUserLoading}] = useMutation(updateUserMutation)

    const [deleteGroup, {error: deleteGroupError, data: deleteGroupData, loading: deleteGroupLoading}] = useMutation(deleteGroupMutation)

    const usersColumn = [
        {
            name: 'user name',
            filter:["none"],
            selector: row => row.name,
            filterable: true,
        },
        {
            name: 'date',
            filter:["none"],
            selector: row => row.date,
        },
        {
            name: 'Active Workspace',
            filter:["none"],
            ignoreRowClick: true,
            allowOverflow: false,
            cell: row => (
                <div style={{ display:"flex", alignItems:"center"}}>
                    <div style={{color:"#3C3C3C", fontSize:"15px", marginRight:".8rem"}}>{row.workspaces}</div>
                    <div className="moreButton">Show all </div>
                </div>
            ),
        },
        {
            name: 'Active Forms',
            filter:["none"],
            ignoreRowClick: true,
            allowOverflow: false,
            cell: row => (
                <div style={{ display:"flex", alignItems:"center"}}>
                    <div style={{color:"#3C3C3C", fontSize:"15px", marginRight:".8rem"}}>{row.forms}</div>
                    <div className="moreButton">More <img style={{marginLeft:".5rem"}} src={arrowRight}/></div>
                </div>
            ),
        },
        {
            name: 'State',
            filter:["none"],
            cell: row => (
                <div className="print-status">
                <span>{row.role}</span>
            </div>
            ),
            
        },{
            name: 'Actions',
            filter:["none"],
            ignoreRowClick: true,
            allowOverflow: false,
            cell: row => (
                <div className="actions-buttons">
                    <img style={{marginRight:".4rem"}} className="actionHover" src={edit}/>
                    <img style={{marginLeft:".4rem"}} className="actionHover" src={trash}/>
                </div>
            ),
            
        },
        
    ];
    

    // add user modal


    useEffect(() => {
        if(users) {
            if(activeTeam) {
             setUsersData(users.allUsers.filter(x=>x.group == activeTeam._id).map(x => {
                return {
                    name : x.name,
                    date: moment.unix(x.createdAt/1000).format("MM/DD/YYYY"),
                    role: x.role,
                    _id: x._id,
                    workspaces: 0,
                    forms: 0,
                    groupRole: x.groupAdmin ? "Admin" : "user",
                    email: x.email
                }
            }))   
            } else {
                setUsersData(users.allUsers.map(x => {
                    return {
                        name : x.name,
                        date: moment.unix(x.createdAt/1000).format("MM/DD/YYYY"),
                        role: x.role,
                        _id: x._id,
                        workspaces: 0,
                        forms: 0,
                        groupRole: x.groupAdmin ? "Admin" : "user",
                        email: x.email
                    }
                }))   
            } 
        } 
    },[users, activeTeam])


    
// get started information
const [userAddModal, setUserAddModal] = useState(null)

// add user info

const [firstName, setFirstName] = useState("")
const [usersDataToTeam, setUsersDataToTeam] = useState(null)
const [firstNameError, setFirstNameError] = useState("")

const [LastName, setLastName] = useState("")
const [lastnameError, setLastNameError] = useState("")



const [addUser, {error: addUserError, data: addUserData, loading: addUserLoading}] = useMutation(addUserMutation)


const addUserFunc = () => {
    // dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    if(loginEmail && !loginEmailInvalid) {
    addUser({
        variables:{
            org: activeOrg,
            name: firstName + " " + LastName,
            email: loginEmail,
            role: "user",
            group: activeTeam._id,

        }
    })
    .then((res) => {
        usersRefetch()
        setUserAddModal(null)   
        setFirstName("")
        setLastName("")
        dispatch(ACTION_LOGIN_EMAIL_RESET())
        dispatch(ACTION_LOADING_SPINNER_RESET())
        dispatch(ACTION_LOGIN_TEMPO_NOT_INVALID())
        dispatch(ACTION_LOGIN_EMAIL_NOT_INVALID())
    }).catch((error) => {
        if (error) {
            /////console.log(error.message)
            if (error.message) {
              if (error.message.includes("email")) {
                dispatch(ACTION_LOGIN_TEMPO_INVALID());
                dispatch(ACTION_LOGIN_PASSWORD_NOT_INVALID());
              }
            }
          }
    })
}
}

useEffect(() => {
    if(users) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
    }
},[users])


const [workspaces, setWorkspaces] = useState([
    {name: "workspace1", createdAt:"05.01.22", createdBy:"user2", _id: 1232},
    {name: "workspace2", createdAt:"05.01.22", createdBy:"user2", _id: 1232},
    {name: "workspace3", createdAt:"05.01.22", createdBy:"user2", _id: 1232},
    {name: "workspace4", createdAt:"05.01.22", createdBy:"user2", _id: 1232},

])



// update team


const [teamAddModal, setTeamAddModal] = useState(null)
const [teamName,setTeamName] = useState("")
const [teamNameError,setTeamNameError] = useState("")
const [usersSearch,setUsersSearch] = useState("")
const [usersSearchError, setUsersSearchError] = useState("")
const [usersFilter, setUsersFilter] = useState("")

const [usersAtTeam, setUsersAtTeam] = useState(null)

const addUserToTeam = (user) => {
    if(!usersData) {
        setUsersData([user])
    } else {
        setUsersData([...usersData, user])
    }
}


const deleteUserFromGroup = (_id) => {
    updateUser({
        variables:{
            _id,
            group: null,
            groupAdmin: false,
        }
    }).then(() => {
        usersRefetch()
    }) 
}


useEffect(() => {
        if(usersSearch) {
        /////console.log(usersSearch.length) 
        if(usersSearch.length) {
          setUsersFilter([...users.allUsers].filter((x) => {
            return (
              new RegExp(usersSearch, "gi").test(
                x.name
              ) || new RegExp(usersSearch, "gi").test(
                x.email
              )
            )
        }).map(j => {
            return {
                name : j.name,
                date: moment.unix(j.createdAt/1000).format("MM/DD/YYYY"),
                role: j.role,
                _id: j._id,
                workspaces: 0,
                forms: 0,
                groupRole: j.groupAdmin ? "Admin" : "user",
                email: j.email
            }
        }).filter(y => !usersData ? true : usersData.some((user)=>user._id==y._id) ? false : true))
        }   
}
},[usersSearch])


// add team func

const updateTeamFunc = () => {
    updateGroup({
        variables:{
            _id: activeTeam._id,
            name: teamName,
        }
    }).then(async res => {
        const resAll =  await Promise.all(usersData.map(async (x) => {
            const update = await updateUser({
                variables:{
                    _id:x._id,
                    group: activeTeam._id,
                    groupAdmin: x.groupRole == "Admin" ? true : false
                }
            }) 
        }))
        if(resAll) {
            setTeamAddModal(null)
            teamsRefetch()
            usersRefetch()
        }
    })
}
const [updateGroupDatas, setUpdateGroupDatas] = useState(null)
useEffect(() => {
    if(updateGroupData) {
        setUpdateGroupDatas(updateGroupData)
    }
},[updateGroupData])

// delete team

const [deleteModal, setDeleteModal] = useState(null)

const deleteTeamFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setDeleteModal(null)
    deleteGroup({
        variables:{
            _id: activeTeam._id
        }
    }).then(x => {
        teamsRefetch().then(x => {
        history.push("/admin/dashboard")
        dispatch(ACTION_LOADING_SPINNER_RESET())
        })
       
    })
}
// if(!usersData) {
//     return (
//         <DataSpinner />
//     )
// }

const [userRole,setUserRole] = useState("User")
const [addGroupDatas, setAddGroupDatas] = useState(null)

const addTeamFunc = () => {
    alert("hi")
    addGroup({
        variables:{
            org: activeOrg,
            name: teamName,
        }
    }).then(async res => {
        if(usersAtTeam?.length) {
        const resAll =  await Promise.all(usersAtTeam.map(async (x) => {
            const update = await updateUser({
                variables:{
                    _id:x._id,
                    group: res.data.addGroup._id,
                    groupAdmin: x.role == "Admin" ? true : false
                }
            }) 
        }))
        if(resAll) {
            setUsersAtTeam(null)
            teamsRefetch()
            usersRefetch()
        }
    } else {
        teamsRefetch()
        setUsersAtTeam(null)
    }
    })
}


 return (
        <div className="adminContent column">
{console.log(usersData,888)}

<AddTeamModal 
            user={user}
            open={teamAddModal}
            setOpen={setTeamAddModal}
            addTeam={addTeamFunc}
            updateTeam={updateTeamFunc}
            addDepartment={() => null}
            teamName={teamName}
            setTeamName={setTeamName}
            teamNameError={teamNameError}
            setTeamNameError={setTeamNameError}
            usersFilter={usersFilter}
            usersSearch={usersSearch}
            setUsersSearch={setUsersSearch}
            usersSearchError={usersSearchError}
            setUsersSearchError={setUsersSearchError}
            addTeamLoading={addGroupLoading}
            usersAtTeam={usersAtTeam}
            setUsersAtTeam={setUsersAtTeam}
            addUserToTeam={addUserToTeam}
            userRole={userRole}
            setUserRole={setUserRole}
            addGroupData={addGroupDatas}
            setAddGroupData={setAddGroupDatas}
            deleteUserFromGroup={deleteUserFromGroup}
            />

            {/* {returnToHomeScreen()} */}
            <div className="workspaceHeader">
                <div className="workspaceName">
                    {activeTeam?.name}
                </div>
                <div className="workspaceButtons">    

                     {activeTeam ? <div onClick={() => setActiveTeam(null)} className="adminButton">
                    <img className="buttonIconImage" src={X} />
                    </div> : null}

                    <div onClick={() => {
                        setTeamName(activeTeam.name)
                        setTeamAddModal("Edit a Team")
                    }} className="adminButton">
                    <img className="buttonIconImage" src={editIcon} />
                    </div>
                    <div onClick={() => setDeleteModal("Team")} className="adminButton">
                    <img className="buttonIconImage" src={deleteIcon} />
                    </div>
                    

                </div>

            </div>
            <div className="workspaceHeader">
            <div className="workspaceName" style={{display:"flex", alignItems:"center"}}>
                Teams
                <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() =>  setTeamAddModal("Team")}
                        
                        />

                </div>
                <div className="workspaceButtons">                    
                {activeTeam ? <div onClick={() => window.location.href = `https://app.allyoucancloud.com/auth/${activeOrg}`} className="moreButton" style={{cursor:"pointer", color:"red"}}>Authentication Page setup</div> : null}
                </div>

            </div>
            <div className="workspaceHeader cardsContainer">
            {teamsData?.map((x) => (
                <div className="workspaceCard teamCardHover"  onClick={() => {
                    setActiveTeam(x) }} style={{border:x._id == activeTeam?._id ? "2px solid darkblue" : "2px solid lightblue"}}>
                    <div className="workspaceThumbnail">
                    {x.name}
                    </div>
                    <div className="workspaceHeader">
                    <div className="footerContent">
                            Created {moment.unix(x.createdAt/1000).format("MM/DD/YYYY")}
                        </div>
                            <img style={{cursor:"pointer"}} onClick={() => {
                                setActiveTeam(x)
                                setTeamName(x.name)
                                setTeamAddModal("Edit a Team")
                            }} className="buttonIconImage" src={threedots} />
                    </div>
                    {/* <div className="workspacefooter">
                        <div className="footerContent">
                            Created {x.createdAt}
                        </div>
                        <img className="dot" src={dot} />
                        <div className="footerContent">
                            {x.createdBy}
                        </div>
                    </div> */}

                </div>
            ))}
            </div>
            <DeleteTeamModal 
            open={deleteModal}
            setOpen={setDeleteModal}
            deleteItem={deleteTeamFunc}
            />
            {/* <AddTeamModal 
            user={user}
            open={teamAddModal}
            setOpen={setTeamAddModal}
            updateTeam={updateTeamFunc}
            teamName={teamName}
            setTeamName={setTeamName}
            teamNameError={teamNameError}
            setTeamNameError={setTeamNameError}
            usersFilter={usersFilter}
            usersSearch={usersSearch}
            setUsersSearch={setUsersSearch}  
            usersSearchError={usersSearchError} 
            setUsersSearchError={setUsersSearchError}
            addTeamLoading={updateUserLoading || updateGroupLoading}
            usersAtTeam={usersDataToTeam}
            setUsersAtTeam={setUsersDataToTeam}
            addUserToTeam={addUserToTeam}
            addGroupData={updateGroupDatas}
            setAddGroupData={setUpdateGroupDatas}
            deleteUserFromGroup={deleteUserFromGroup}
            /> */}

            <AddUserModal 
                open={userAddModal}
                setOpen={setUserAddModal}
                addUser={addUserFunc}
                firstName={firstName}
                setFirstName={setFirstName}
                firstNameError={firstNameError}
                setFirstNameError={setFirstNameError}
                LastName={LastName}
                setLastName={setLastName}
                lastnameError={lastnameError}
                setLastNameError={setLastNameError}
                addUserLoading={addUserLoading}
            />
            <div className="workspaceContainer" style={{marginTop:"4rem"}}>
            <div className="workspaceHeader">
                <div className="workspaceName" style={{display:"flex", alignItems:"center"}}>
                        Users
                        <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => setUserAddModal("User")}
                        
                        />
                </div>
                <div className="workspaceButtons">
                    <div className={`routeContainer sideSearch userSearch`} style={{width:"269px" , margin:"0px", fontWeight:"normal", justifyContent:"flex-start"}}>
                    <img
                                className="routeIcon"
                                src={searchInput}
                    />
                    <div className="searchT" style={{margin:"0 16px", color:"#5E6A7A"}}>
                        Search                        
                    </div>
                    </div>
                    <div className="adminButton" style={{width:"fit-content"}}> 
                    <span style={{margin:"0 16px", color:"#5E6A7A"}} className="buttonIconImage">Newest</span>
                    <img className="buttonIconImage" style={{margin:"0 12px"}} src={arrowDownUsers} />
                    </div>
                    <div className="adminButton" >
                    <img className="buttonIconImage" src={setting} />
                    </div>

                </div>
            </div>
            <UsersTable
                accountColumns={usersColumn}
                accountData={usersData}
            />
            </div>
        </div>
    )
}

export default AdminTeams