import { gql } from "apollo-boost";

const addTemplateMutation = gql`
mutation(
  $name: String,   
  $user: ID,
   $html: String,
    $css: String,
) {
  addTemplate(
  name:$name,    
  user: $user
  html:$html,
    css:$css,

  ) {
  _id
  name
  user,
   html
    css

  }
}
`;

export default addTemplateMutation;
