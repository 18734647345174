import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Ecommerce3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
        <form>
          <h2 class="text-sm title-font text-gray-500 tracking-widest"> {<Element canvas style={{display:"inline-block"}} id="270677" is="div"><Text text="BRAND NAME" /></Element>} </h2>
          <h1 class="text-gray-900 text-3xl title-font font-medium mb-4"> {<Element canvas style={{display:"inline-block"}} id="517809" is="div"><Text text="Animated Night Hill Illustrations" /></Element>} </h1>
          <p class="leading-relaxed mb-4"> {<Element canvas style={{display:"inline-block"}} id="684592" is="div"><Text text="Fam locavore kickstarter distillery. Mixtape chillwave tumeric sriracha taximy chia microdosing tilde DIY. XOXO fam inxigo juiceramps cornhole raw denim forage brooklyn. Everyday carry +1 seitan poutine tumeric. Gastropub blue bottle austin listicle pour-over, neutra jean." /></Element>} </p>
          <div class="flex border-t border-gray-200 py-2">
            <span class="inline-flex items-center text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="480620" is="div"><Text text="Color" /></Element>} </span>
            <div class="ml-auto relative">
              <select class="rounded border appearance-none border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10" required>
                <option> {<Element canvas style={{display:"inline-block"}} id="838799" is="div"><Text text="⚫️" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="377971" is="div"><Text text="⚪️" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="307377" is="div"><Text text="🔵" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="306523" is="div"><Text text="🟣" /></Element>} </option>
              </select>
              <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </div>
          </div>
          <div class="flex border-t border-gray-200 py-2">
            <span class="inline-flex items-center text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="503732" is="div"><Text text="Size" /></Element>} </span>
            <div class="ml-auto relative">
              <select class="rounded border appearance-none border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10" required>
                <option> {<Element canvas style={{display:"inline-block"}} id="688565" is="div"><Text text="Small" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="721250" is="div"><Text text="Medium" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="279549" is="div"><Text text="Large" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="491291" is="div"><Text text="X-Large" /></Element>} </option>
              </select>
              <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </div>
          </div>
          <div class="flex border-t border-b mb-6 border-gray-200 py-2">
            <span class="inline-flex items-center text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="573355" is="div"><Text text="Quantity" /></Element>} </span>
            <div class="ml-auto relative">
              <select class="rounded border appearance-none border-gray-300 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10" required>
                <option> {<Element canvas style={{display:"inline-block"}} id="629888" is="div"><Text text="1" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="902767" is="div"><Text text="2" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="491128" is="div"><Text text="3" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="160136" is="div"><Text text="4" /></Element>} </option>
                <option> {<Element canvas style={{display:"inline-block"}} id="403459" is="div"><Text text="5" /></Element>} </option>
              </select>
              <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </div>
          </div>
          <div class="flex">
            <span class="title-font font-medium text-2xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="418627" is="div"><Text text="$58.00" /></Element>} </span>
            <Element canvas style={{ width: "100%" }} id={"div655307"} is="div">
            <Button classn={"flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="454532" is="div"><Text text="Button" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div361016"} is="div">
            <Button classn={"rounded-full w-10 h-10 bg-gray-200 p-0 border-0 inline-flex items-center justify-center text-gray-500 ml-4"}>
              
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"></path>
              </svg>
            
            </Button>
          </Element>
          </div>
        </form>
      </div>
      <Element canvas style={{ width: "100%" }} id={"div943916"} is="div">
          <ImageC alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="https://dummyimage.com/400x400"  />
        </Element>
    </div>
  </div>
    </section>

      </div>;
};
Ecommerce3.craft = {
  displayName: "Ecommerce 3"
};