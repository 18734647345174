import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Form5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    {/* <!--
      This component uses @tailwindcss/forms
       yarn add @tailwindcss/forms
      npm install @tailwindcss/forms
       plugins: [require('@tailwindcss/forms')]
     --> */}

    <section class="bg-gray-100">
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
      <div class="lg:col-span-2 lg:py-12">
        <p class="max-w-xl text-lg"> {<Element canvas style={{display:"inline-block"}} id="238468" is="div"><Text text="At the same time, the fact that we are wholly owned and totally
          independent from manufacturer and other group control gives you
          confidence that we will only recommend what is right for you." /></Element>} </p>

        <div class="mt-8">
          <Element canvas style={{ width: "100%" }} id={"div918485"} is="div">
            <Button href={""} classn={"text-2xl font-bold text-pink-600"}>
               {<Element canvas style={{display:"inline-block"}} id="166836" is="div"><Text text="0151 475 4450" /></Element>} 
            </Button>
          </Element>

          <address class="mt-2 not-italic"> {<Element canvas style={{display:"inline-block"}} id="138394" is="div"><Text text="282 Kevin Brook, Imogeneborough, CA 58517" /></Element>} </address>
        </div>
      </div>

      <div class="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
        <form action="" class="space-y-4">
          <div>
            <label class="sr-only" for="name"> {<Element canvas style={{display:"inline-block"}} id="402635" is="div"><Text text="Name" /></Element>} </label>
            <input class="w-full rounded-lg border-gray-200 p-3 text-sm" placeholder="Name" type="text" id="name" />
          </div>

          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label class="sr-only" for="email"> {<Element canvas style={{display:"inline-block"}} id="290997" is="div"><Text text="Email" /></Element>} </label>
              <input class="w-full rounded-lg border-gray-200 p-3 text-sm" placeholder="Email address" type="email" id="email" />
            </div>

            <div>
              <label class="sr-only" for="phone"> {<Element canvas style={{display:"inline-block"}} id="367192" is="div"><Text text="Phone" /></Element>} </label>
              <input class="w-full rounded-lg border-gray-200 p-3 text-sm" placeholder="Phone Number" type="tel" id="phone" />
            </div>
          </div>

          <div class="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
            <div>
              <input class="sr-only" id="option1" type="radio" tabindex="-1" />
              <label for="option1" class="block w-full rounded-lg border border-gray-200 p-3" tabindex="0">
                <span class="text-sm font-medium"> {<Element canvas style={{display:"inline-block"}} id="273818" is="div"><Text text="Option 1" /></Element>} </span>
              </label>
            </div>

            <div>
              <input class="sr-only" id="option2" type="radio" tabindex="-1" />
              <label for="option2" class="block w-full rounded-lg border border-gray-200 p-3" tabindex="0">
                <span class="text-sm font-medium"> {<Element canvas style={{display:"inline-block"}} id="367251" is="div"><Text text="Option 2" /></Element>} </span>
              </label>
            </div>

            <div>
              <input class="sr-only" id="option3" type="radio" tabindex="-1" />
              <label for="option3" class="block w-full rounded-lg border border-gray-200 p-3" tabindex="0">
                <span class="text-sm font-medium"> {<Element canvas style={{display:"inline-block"}} id="124803" is="div"><Text text="Option 3" /></Element>} </span>
              </label>
            </div>
          </div>

          <div>
            <label class="sr-only" for="message"> {<Element canvas style={{display:"inline-block"}} id="902761" is="div"><Text text="Message" /></Element>} </label>
            <textarea class="w-full rounded-lg border-gray-200 p-3 text-sm" placeholder="Message" rows="8" id="message"></textarea>
          </div>

          <div class="mt-4">
            <Element canvas style={{ width: "100%" }} id={"div144042"} is="div">
            <Button classn={"inline-flex w-full items-center justify-center rounded-lg bg-black px-5 py-3 text-white sm:w-auto"}>
              
              <span class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="103116" is="div"><Text text="Send Enquiry" /></Element>} </span>

              <svg xmlns="http://www.w3.org/2000/svg" class="ml-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            
            </Button>
          </Element>
          </div>
        </form>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Form5.craft = {
  displayName: "Form 5"
};