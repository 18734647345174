import { useEditor, Element } from '@craftjs/core';
import cx from 'classnames';
import lz from 'lzutf8';
import React, { useCallback, useEffect, useState } from 'react';
import {Checkmark} from '../../../icons/check.js';
import {CustomizeIcon} from '../../../icons/customize.js';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Toolbox } from './Toolbox';
import addViewPink from "../../../../../assests/images/addViewPink.svg"
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE.js';
import ACTION_LOADING_SPINNER_RESET from '../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET.js';
import styled from 'styled-components';
import {ButtonSvg} from '../../../icons/toolbox/button.js';
import {SquareSvg} from '../../../icons/toolbox/rectangle.js';
import {TypeSvg} from '../../../icons/toolbox/Text.js';
import {YoutubeSvg} from '../../../icons/toolbox/video-line.js';
import AddFormModal from "../../../../resuable/modals/addFormModal";
import { Button } from '../../selectors/inputs/Button';
import { Container } from '../../selectors/others/Container';
import { Text } from '../../selectors/others/Text';
import { Video } from '../../selectors/others/Video';
import { CardWidget } from '../../selectors/others/Card';
import { AccordionN } from '../../selectors/others/Accordion';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { GridWidget } from '../../selectors/others/Grid/index.js';
import ViewSettings from '../../../modals/viewSettings/index.js';
import { useDispatch, useSelector } from 'react-redux';
import updateViewMutation from '../../../../../graphql/mutations/view/updateViewMutation.js';
import { useLazyQuery, useMutation } from '@apollo/client';
import ACTION_FORMBUILDER_RESET from '../../../../../actions/formBuilder/ACTION_FORMBUILDER_RESET.js';
import addFormMutation from '../../../../../graphql/mutations/form/addFormMutation.js';
import { FormBuilderWidget } from '../../selectors/others/FormBuilder/index.js';
import { Scheduler } from '../../selectors/calendars/Scheduler/index.js';
import { Calendar } from '../../selectors/calendars/Calendar/index.js';
import { DatePicker } from '../../selectors/calendars/DatePicker/index.js';
import { DateRangePicker } from '../../selectors/calendars/DateRangePicker';
import { DateTimePicker } from '../../selectors/calendars/DateTimePicker';
import { GantChart } from '../../selectors/calendars/GantChart';
import { AutoComplete } from '../../selectors/dropdowns/AutoComplete/index.js';
import { CompoBox } from '../../selectors/dropdowns/CompoBox/index.js';
import { DropDownList } from '../../selectors/dropdowns/DropDownList/index.js';
import { DropDownTree } from '../../selectors/dropdowns/DropDownTree/index.js';
import { DropDownMulti } from '../../selectors/dropdowns/DropDownMulti/index.js';
import { Mention } from '../../selectors/dropdowns/Mention/index.js';
import { ListBox } from '../../selectors/dropdowns/ListBox/index.js';
import { Accordion } from '../../selectors/navigation/Accordion'
import { AppBar } from '../../selectors/navigation/AppBar/index.js';
import { Carousel } from '../../selectors/navigation/Carousel/index.js';
import { Context } from '../../selectors/navigation/Context/index.js';
import { SideBar } from '../../selectors/navigation/SideBar/index.js';
import { Wizard } from '../../selectors/navigation/Wizard/index.js';
import { FileManager } from '../../selectors/navigation/FileManager/index.js';
import { Message } from '../../selectors/notifications/Message/index.js';
import { Badge } from '../../selectors/notifications/Badge/index.js';
import { Toast } from '../../selectors/notifications/Toast/index.js';
import { Input } from '../../selectors/inputs/Input/index.js';
import { Mask } from '../../selectors/inputs/Mask/index.js';
import { Numeric } from '../../selectors/inputs/Numeric/index.js';
import { Signture } from '../../selectors/inputs/Signture/index.js';
import { FileUploader } from '../../selectors/inputs/FileUploader/index.js';
import { QueryForm } from '../../selectors/forms/QueryForm/index.js';
import { DropButton } from '../../selectors/buttons/DropButton/index.js';
import { SpeedDial } from '../../selectors/buttons/SpeedDial/index.js';
import { FloatingButton } from '../../selectors/buttons/FloatingButton/index.js';
import { Dashboard } from '../../selectors/layout/Dashboard/index.js';
import { Splitter } from '../../selectors/layout/Splitter/index';
import { ListView } from '../../selectors/layout/ListView/index';
import { Dialoag } from '../../selectors/layout/Dialoag/index.js';
import { FlippedCard } from '../../selectors/layout/FlippedCard/index.js';
import { AvatarCard } from '../../selectors/layout/AvatarCard/index.js';
import { PDF } from '../../selectors/editors/PDF/index.js';
import { WordProcessor } from '../../selectors/editors/WordProcessor/index.js';
import { TreeGrid } from '../../selectors/grids/TreeGrid/index.js';
import { PivotTable } from '../../selectors/grids/PivotTable/index.js';
import { DataGrid } from '../../selectors/grids/DataGrid/index.js';
import { ImageEditor } from '../../selectors/editors/ImageEditor/index.js';


import {Code39} from "../../selectors/dataVisualization/barcode/code39"
import {Eancode} from "../../selectors/dataVisualization/barcode/ean"
import {Qrcode} from "../../selectors/dataVisualization/barcode/qrcode"

import {BulletChart} from "../../selectors/dataVisualization/charts/bullet"
import {Map} from "../../selectors/dataVisualization/charts/map"
import {SmithChart} from "../../selectors/dataVisualization/charts/smith"
import {MixedCharts} from "../../selectors/dataVisualization/charts/mixedChart"
import {Treemap} from "../../selectors/dataVisualization/charts/treemap"

import {Kanban} from "../../selectors/dataVisualization/kanban/"
import ExportModal from '../../../modals/exportModal/index.js';
import addShareableMutation from '../../../../../graphql/mutations/shareable/addShareableMutation.js';
import ImportModal from '../../../modals/importModal/index.js';
import PreviewModal from '../../../modals/PreviewModal/index.js';
import viewQuery from '../../../../../graphql/queries/view/viewQuery.js';
import { ToolboxDown } from './ToolboxDown.js';




const Item = styled.a`
svg {
  width: 22px;
  height: 22px;
  fill: #707070;
}
`;

export const Viewport = ({ 
  isApp,
  children,
  expanded,
setExpanded,
  user,
html,
type,
shareableRefetch,
workspace,
shareables,
_id,
view,
viewsRefetch,
editMode,
setEditMode,
views,
setActiveView,
addUserToView,
setViewAddModal,
addUserToApp,
setAppAddModal,
setViewSettings,
viewSettings,
setBuilderModal,
builderModal,
dataBasesData,
dataBasesLoading,
widgetSettings,
widgetName,
formBuilder,
dataBaseUrl,
setDataBaseUrl,
addDataBase,
dataBaseDb,
setDataBaseDb,
dataBaseAllDb,
setDataBaseAllDb,
dataBaseTable,
setDataBaseTable,
dataBaseAllTable,
setDataBaseAllTable,
eventsData,
eventsLoading,
 }) => {
  const {
    enabled,
    connectors, 
    actions,
    json,
    nodes
  } = useEditor((state, query) => ({
    enabled: state.options.enabled || false,
    json: query.serialize() ,
    nodes: state.nodes
  }));

  const [importModal, setImportModal] = useState(false)
  const [exportModal, setExportModal] = useState(null)
  const [imported, setImported] = useState(null)

  const [previewModal, setPreviewModal] = useState(null)
useEffect(() => {
  if(view) {
    setImportModal(false)
    setImported(null)
  }
},[view])
const importViewFunc = (json) => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    let wrapper = lz.encodeBase64(lz.compress(json))   
    /////console.log(wrapper, _id)         
    updateView({
      variables:{
        _id: _id,
        html: wrapper
      }
    }).then(() => {
      viewsRefetch()
      dispatch(ACTION_LOADING_SPINNER_RESET())
    })
} 

const [getView, { error: viewError, data: viewData, loading: viewLoading, refetch: viewRefetch }] = useLazyQuery(viewQuery, {
  fetchPolicy: "no-cache",
});

const importFunc = () => {

  console.log(imported)
  if(imported.type == "view") {
    
    getView({
      variables:{
        _id: imported.id 
      }
    })

  } else {
    setImportModal(null)
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    let widget = imported.id
    const newJsonObj = JSON.parse(json)
    let newJson = JSON.parse(json)["ROOT"]
    let widgetId = Object.keys(JSON.parse(widget))[0]
    newJson.nodes.push(widgetId)
    let finalJson = {...newJsonObj, "ROOT" :{...newJson}, [widgetId]: {...JSON.parse(widget)[widgetId]}}
    actions.deserialize(finalJson)
    dispatch(ACTION_LOADING_SPINNER_RESET())

  }

  // const node = query.parseFreshNode(freshNode).toNode();

  // addNode(

  // )
}

useEffect(() => {
  if(viewData) {
    
    let html = viewData?.view?.html
    console.log(html, "refetched html")
    if(html) {
      const newJson = lz.decompress(lz.decodeBase64(html));
      let newJsonObj = JSON.parse(newJson)
      let newJsonRootObj = JSON.parse(newJson)["ROOT"]
      let jsonObj = JSON.parse(json)
      let objKeys = Object.keys(jsonObj).filter(x => x !== "ROOT")
      newJsonRootObj.nodes = [...newJsonRootObj.nodes, ...objKeys].filter((item, index, a) => a.indexOf(item) === index);
      let newJsonFinal = {...newJsonObj, ...jsonObj, "ROOT": {...newJsonRootObj}}
      importViewFunc(JSON.stringify(newJsonFinal))
    } else {
      // actions.deserialize({"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true, "props":{"flexDirection":"column","alignItems":"flex-start","justifyContent":"flex-start","fillSpace":"no","padding":["40","40","40","40"],"margin":["0","0","0","0"],"background":{"r":255,"g":255,"b":255,"a":1},"color":{"r":0,"g":0,"b":0,"a":1},"shadow":0,"radius":0,"width":"100%","height":"518px"},"displayName":"Container","custom":{"displayName":"App"},"hidden":false,"nodes":[],"linkedNodes":{}}})
    }
  }
},[viewData])

const previewFunc = (id, type) => {
  console.log(id)
  // setImportModal(null)
  setPreviewModal({id, type})
}

  const [rules, setRules] = useState([])
  const [addShareable, {error: addShareableError, data: addShareableData, loading: addShareableLoading}] = useMutation(addShareableMutation)
  const activeOrg = useSelector((state) => state.activeOrg.org);

  const [over, setOver] = useState(false)
  const dispatch = useDispatch()
 const [dataBaseDataArr, setDataBaseDataArr] = useState(null)
 const [eventsDataArr, setEventsDataArr] = useState(null)
 const [addForm, {error: addFormError, data: addFormData, loading: addFormLoading}] = useMutation(addFormMutation)

 useEffect(() => {

    if(html) {
      console.log(html, 121)
      const json = lz.decompress(lz.decodeBase64(html));
      actions.deserialize(json)
      console.log(json, 122)
    } else {
      actions.deserialize({"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true, "props":{ "flexDirection":"column","alignItems":"flex-start","justifyContent":"flex-start","fillSpace":"no","padding":["40","40","40","40"],"margin":["0","0","0","0"],"background":{"r":255,"g":255,"b":255,"a":1},"color":{"r":0,"g":0,"b":0,"a":1},"shadow":0,"radius":0,"width":"100%%","height":"518px"},"displayName":"Container","custom":{"displayName":"App"},"hidden":false,"nodes":[],"linkedNodes":{}}})
    }

 },[html])


 useEffect(() => {
  if(dataBasesData) {
    console.log(dataBasesData)
    let dataBaseResults = dataBasesData.allDataBases.map(x => {
      return {
        widget: x.widget,
        db: {
          url: x.url,
          db: x.db,
          table: x.table
        },
        results: JSON.parse(x.results)
      }
    })
    setDataBaseDataArr(dataBaseResults)
  }
 },[dataBasesData])

 useEffect(() => {
  if(eventsData) {
    console.log(eventsData)
    let eventsResults = eventsData.allEvents?.map(x => {
      return {
        widget: x.widgetId,
        node: x.nodeId,
        eventName: x.eventName,
        event: {
          eventName: x.eventName,
          eventType: x.eventType,
          eventContent: x.eventContent,
          key: x.key
        },
      }
    })
    setEventsDataArr(eventsResults)
  }
 },[eventsData])

 const applyFilter = (allData, collection, key, value) => {
  console.log(allData, collection, key, value)
  let collectionData = allData?.find(x => x.db.table == collection)
  value ? Object.keys(nodes)?.some(y => y == collectionData.widget) && actions.setProp(collectionData.widget, props => {
    props.dataBaseDataArr = {db: collectionData.db, results: collectionData.results.filter(y => {
      return y[key].includes(value)
    })}
    props.applyFilter = applyFilter

  }) : Object.keys(nodes)?.some(y => y == collectionData.widget) && actions.setProp(collectionData.widget, props => {
    props.dataBaseDataArr = {db: collectionData.db, results: collectionData.results}
    props.applyFilter = applyFilter

  })
 }

 useEffect(() => {
  if(dataBaseDataArr) {
    dataBaseDataArr.map(x => {
      console.log(x)
      Object.keys(nodes)?.some(y => y == x.widget) && actions.setProp(x.widget, props => {
        props.dataBaseDataArr = {db: x.db, results: x.results}

      })
    })
  }
 },[dataBaseDataArr])

 useEffect(() => {
  if(dataBaseDataArr) {
    Object.keys(nodes).map(x => {
      actions.setProp(x, props => {
        props.allData = dataBaseDataArr
        props.applyFilter = applyFilter

      })
    })
  }
 },[dataBaseDataArr])
 
 useEffect(() => {
  if(eventsDataArr && eventsDataArr?.length) {
    eventsDataArr?.map((x, i ,a) => {
      Object.keys(nodes)?.some(y => y == x.widget) && actions.setProp(x.widget, props => {
        props.eventsDataArr = a.filter(y => y.widget == x.widget)
      })
    })
  }
 },[eventsDataArr])

  const [updateView, {error: updateViewError, data: updateViewData, loading: updateViewLoading}] = useMutation(updateViewMutation)

  const updateViewFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      let wrapper = lz.encodeBase64(lz.compress(json))   
      /////console.log(wrapper, _id)         
        updateView({
          variables:{
            _id: _id,
            html: wrapper
          }
      }).then(() => {
        viewsRefetch()
        dispatch(ACTION_LOADING_SPINNER_RESET())
      })
  } 

  const [canvasView, setCanvasView] = useState("pc")
  const [layers, setLayers] = useState(true)
  const [addWidgetActive, setAddWidgetActive] = useState(false)
  const [groupActive, setGroupActive] = useState(null)

  const [previewMode, setPreviewMode] = useState(false)
  
  const [formName, setFormName] = useState("")

  const addFormFunc = () => {
    // dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    // /////console.log(activeTable)
    // addForm({
    //     variables:{
    //         collectionId: activeTable._id,
    //         name: formName,
    //         form: JSON.stringify(formComponents)
    //     }
    // }).then((X) => {
    //     tablesRefetch()        
    //     setDesignView(false)
    //     setAddFormCollectionModal(null)
    //     setFormName("")
        
    // })
}
    // useEffect(() => {
    //   if(!enabled) {
    //     setAddWidgetActive(false)
    //     setLayers(false)
    //     setCanvasView("pc")
    //     setPreviewMode(false)
    //   }
    // },[enabled])

    useEffect(() => {
      if(formBuilder) {
        console.log(formBuilder)
        setExportModal("widget")
      }
  },[formBuilder])


  useEffect(() => {
    if(!exportModal) {
      dispatch(ACTION_FORMBUILDER_RESET())
    }
  },[exportModal])

    const [display, setDisplay] = useState("form");
    const [formComponents, setFormComponents] = useState()
    const [eventType, setEventType] = useState(null)
    useEffect(() => {
      if(!addWidgetActive) {
        setGroupActive(null)
      }
    },[addWidgetActive])

    const exportFunc = (type) => {
      console.log(type)
      if(type == "view") {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setExportModal(null)
        console.log(rules)
        addShareable({ 
          variables: {
            user: user.user._id,
            org: activeOrg, 
            id: _id,
            type: "view",
            market: false,
            rules: rules.map(x => x.value)
          }
        }).then(res => {
          shareableRefetch()
          dispatch(ACTION_LOADING_SPINNER_RESET())
        })
      } else if(type == "widget") {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setExportModal(null)

        addShareable({ 
          variables: {
            user: user.user._id,
            org: activeOrg, 
            id: formBuilder,
            type: "widget",
            market: false,
            rules: rules.map(x => x.value)
          }
        }).then(res => {
          shareableRefetch()
          dispatch(ACTION_LOADING_SPINNER_RESET())
        })
        console.log(rules)
      } else if(type == "workspace") {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        setExportModal(null)

        addShareable({ 
          variables: {
            user: user.user._id,
            org: activeOrg, 
            id: workspace._id,
            type: "workspace",
            market: false,
            rules: rules.map(x => x.value)
          }
        }).then(res => {
          shareableRefetch()
          dispatch(ACTION_LOADING_SPINNER_RESET())
        })
      }
     
    }



  return (
    <div className="viewport" onDrop={() => setAddWidgetActive(false)}>
      {widgetSettings && <div style={{position:"fixed", top:"2.4rem", right:0, bottom:0, zIndex:100}}>
        <Toolbox settings={true}>
       
        </Toolbox>
      </div>}



      <ViewSettings
    views={views}
    eventType={eventType}
    setEventType={setEventType}
     eventsDataArr={eventsDataArr}
    dataBasesData={dataBasesData}
    dataBaseDataArr={dataBaseDataArr}
    _id={_id}
    nodes={nodes}
    widgetSettings={widgetSettings}
    widgetName={widgetName}
    dataBaseUrl={dataBaseUrl}
    setDataBaseUrl={setDataBaseUrl}
    addDataBase={addDataBase}
    dataBaseDb={dataBaseDb}
    setDataBaseDb={setDataBaseDb}
    dataBaseAllDb={dataBaseAllDb}
    setDataBaseAllDb={setDataBaseAllDb}
    dataBaseTable={dataBaseTable}
    setDataBaseTable={setDataBaseTable}
    dataBaseAllTable={dataBaseAllTable}
    setDataBaseAllTable={setDataBaseAllTable}
    layers={layers}
    setLayers={setLayers}
    />


      <div style={{position:"fixed", right:0, bottom:0, zIndex:100}}>
        <ToolboxDown
        expanded={expanded}
        setExpanded={setExpanded}
        >
       
        </ToolboxDown>
      </div>

      {/* {formBuilder && <div>
        
        <AddFormModal 
          open={false}
          setOpen={() => dispatch(ACTION_FORMBUILDER_RESET())}
          formName={formName}
          setFormName={setFormName}
          addForm={addFormFunc}
          addFormLoading={addFormLoading}
          display={display}
          setDisplay={setDisplay}
          formComponents={formComponents}
          setFormComponents={setFormComponents}
          type="formBuilder"
        /></div>} */}

{/* 
{previewModal && ( 
          <div>
            <PreviewModal
            open={previewModal}
            setImportModal={setImportModal}
            setOpen={setPreviewModal}
            imported={imported}
            setImported={setImported}
            importFunc={importFunc}
            html={html}
            dataBasesData={dataBasesData}
            eventsData={eventsData}
            />
          </div>
        )} */}

        {importModal && (
          <div>
            <ImportModal
            user={user}
            shareables={shareables}
            open={importModal}
            setOpen={setImportModal}
            imported={imported}
            setImported={setImported}
            importFunc = {importFunc}
            previewFunc={previewFunc}

            previewModal={previewModal}
            setImportModal={setImportModal}
            setPreviewModal={setPreviewModal}
            html={html}
            dataBasesData={dataBasesData}
            eventsData={eventsData}

            />
          </div>
        )}

        {exportModal && (
          <div>
            <ExportModal
              widget={formBuilder}
              open={exportModal}
              setOpen={setExportModal}
              rules={rules}
              setRules={setRules}
              exportFunc={exportFunc}
            />
          </div>
        )}  
  
            <div
        className={cx(['flex h-full overflow-hidden flex-row w-full fixed'])}
      >
        { true && <Toolbox
        enabled={enabled}
        setActiveView={setActiveView}
        activeView={view}
        views={views}
        layers={layers}
        setLayers={setLayers}
        setAddWidgetActive={setAddWidgetActive}
        setViewSettings={setViewSettings}
        viewSettings={viewSettings}
        setBuilderModal={setBuilderModal}
        builderModal={builderModal}
        addWidgetActive={addWidgetActive}
        /> }
        <div className="page-container flex flex-1 h-full flex-col"
        style={{alignItems:"center", position:"relative", width:"100%"}}
        >
          {(addWidgetActive || groupActive ) && enabled ? <div onClick={(() => {
            setAddWidgetActive(false)
            setGroupActive(null)
          })} style={{ width:  over ? "100vw" : "0px", height:  over ? "100vh" : "0px", zIndex:"100000", position:"absolute", left:0, right:0}}> { addWidgetActive ? <div onClick={(event) => event.stopPropagation()}> 
          <div style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", borderTop:"1px solid #313642", height:"100vh", paddingBottom:"50px", overflowY:"auto",position:"absolute", left:"0px", top:"2.4rem", width:"18rem", zIndex:99991, background:"#16171c", padding:"20px 15px"}}>          

          <div
          className='widgetItemIn dm active'
          onClick={() => setGroupActive("grids")}
        >
          <div className='widgetItemName'>
            Components
          </div>

          
        </div>


          <div
          className='widgetItemIn dm'
          onClick={() => setGroupActive("grids")}
        >
          <div className='widgetItemName'>
            grids
          </div>

          
        </div>
        <div className='containerList'>
            <>
 <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
          
          ref={(ref) => 
            connectors?.create(
              ref,
             <TreeGrid setActiveView={setActiveView}></TreeGrid>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              TG
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Tree Grid
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <PivotTable  setActiveView={setActiveView}></PivotTable>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              PT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         Pivot Table
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DataGrid  setActiveView={setActiveView}></DataGrid>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         Data Grid
          </div>
        </div>
            </>
          
          </div>
        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Data Visualization")}
        >
          <div className='widgetItemName'>
            Data Visualization
          </div>

          
        </div>
        
        <div className='containerList'>

            <>
             {/* <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Code39 setActiveView={setActiveView}></Code39>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Code39
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Code39
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Eancode setActiveView={setActiveView}></Eancode>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Eancode
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Eancode
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Qrcode setActiveView={setActiveView}></Qrcode>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Qrcode
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Qrcode
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <BulletChart setActiveView={setActiveView}></BulletChart>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              BulletChart
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          BulletChart
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Map setActiveView={setActiveView}></Map>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Map
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Map
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <SmithChart setActiveView={setActiveView}></SmithChart>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              SmithChart
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          SmithChart
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <MixedCharts setActiveView={setActiveView}></MixedCharts>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              MixedCharts
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          MixedCharts
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Treemap setActiveView={setActiveView}></Treemap>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Treemap
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Treemap
          </div>
        </div> */}
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Kanban setActiveView={setActiveView}></Kanban>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Kanban
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Kanban
          </div>
        </div>
            </>

</div>


        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Calendars")}
        >
          <div className='widgetItemName'>
            Calendars
          </div>
          
        </div>
        
        <div className='containerList'>
            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref, 
          <Scheduler setActiveView={setActiveView}></Scheduler>
          )}
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Sch
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Scheduler
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Calendar setActiveView={setActiveView}></Calendar>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Cal
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Calendar
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <DatePicker setActiveView={setActiveView}></DatePicker>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              pick
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Date Picker
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <DateRangePicker setActiveView={setActiveView}></DateRangePicker>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Range
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Range Picker
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <DateTimePicker setActiveView={setActiveView}></DateTimePicker>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          time Picker
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <GantChart setActiveView={setActiveView}></GantChart>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              GC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Gantt Chart
          </div>
        </div>
            </>
</div>
        
        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Dropdowns")}
        >
          <div className='widgetItemName'>
            Dropdowns
          </div>

          
        </div>
        
        
        <div className='containerList'>
            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <AutoComplete setActiveView={setActiveView}></AutoComplete>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              AC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Auto complete
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <CompoBox  setActiveView={setActiveView}></CompoBox>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              CB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Compo Box
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DropDownList setActiveView={setActiveView}></DropDownList>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DW
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Drop Down List
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DropDownTree  setActiveView={setActiveView}></DropDownTree>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DWT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Drop Down Tree
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DropDownMulti  setActiveView={setActiveView}></DropDownMulti>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DWM
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Drop Down Multi Select
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Mention  setActiveView={setActiveView}></Mention>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              @
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Mention
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <ListBox  setActiveView={setActiveView}></ListBox>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              LB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          List Box
          </div>
        </div>
            </>

</div>
        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("File Viewers & Editors")}
        >
          <div className='widgetItemName'>
            File Viewers & Editors
          </div>

          
        </div>
        
        <div className='containerList'>
            <>
         <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <PDF  setActiveView={setActiveView}></PDF>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              PDF
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          PDF Viewer & editor
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <ImageEditor setActiveView={setActiveView}></ImageEditor>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              IE
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Image Editor
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <WordProcessor  setActiveView={setActiveView}></WordProcessor>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              WP
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Word Processor
          </div>
        </div>
            </>
</div>

        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Inputs")}
        >
          <div className='widgetItemName'>
            Inputs
          </div>

          
        </div>
        
        <div className='containerList'>
            <>
                <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Input setActiveView={setActiveView}></Input>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              In
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Input
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Mask  setActiveView={setActiveView}></Mask>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              IM
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Input Mask
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Numeric setActiveView={setActiveView}></Numeric>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              NI
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Numeric Input
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Signture  setActiveView={setActiveView}></Signture>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Si
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Signature
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Button  setActiveView={setActiveView}></Button>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Bu
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Button
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <FileUploader  setActiveView={setActiveView}></FileUploader>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              FU
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          FileUploader
          </div>
        </div>


            </>
  </div>
        
        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Navigation")}
        >
          <div className='widgetItemName'>
            Navigation
          </div>

          
        </div>
        
        
        <div className='containerList'>
            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Accordion setActiveView={setActiveView}></Accordion>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              AC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Accordion
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <AppBar  setActiveView={setActiveView}></AppBar>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              AB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          App Bar
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Carousel  setActiveView={setActiveView}></Carousel>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Ca
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Carousel
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Context  setActiveView={setActiveView}></Context>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              CM
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Context Menu
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <SideBar  setActiveView={setActiveView}></SideBar>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              SB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Side Bar
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Wizard  setActiveView={setActiveView}></Wizard>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              WT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Wizard Tabs
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <FileManager setActiveView={setActiveView}></FileManager>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              FM
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          File Manager
          </div>
        </div>


            </>
</div>
<div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Layout")}
        >
          <div className='widgetItemName'>
            Layout
          </div>

          
        </div>
        
        <div className='containerList'>
            <>
             <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Dashboard  setActiveView={setActiveView}></Dashboard>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DL
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         Dashboard Layout
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Splitter  setActiveView={setActiveView}></Splitter>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              SP
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         Splitter Layout
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <ListView  setActiveView={setActiveView}></ListView>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              LV
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         List View
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Dialoag  setActiveView={setActiveView}></Dialoag>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DI
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Dialoag
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <FlippedCard  setActiveView={setActiveView}></FlippedCard>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              FC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
           Flipped Card
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <AvatarCard  setActiveView={setActiveView}></AvatarCard>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              AC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
           Avatar Card
          </div>
        </div>
            </>

</div>
        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Buttons")}
        >
          <div className='widgetItemName'>
            Buttons
          </div>

          
        </div>
        
        <div className='containerList'>

            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DropButton setActiveView={setActiveView}></DropButton>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DDB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          DropDown Button
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <SpeedDial setActiveView={setActiveView}></SpeedDial>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              SD
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Speed Dial Button
          </div>
        </div>
{/* 
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <FloatingButton  setActiveView={setActiveView}></FloatingButton>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              FB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Floating Button
          </div>
        </div> */}
            </>
</div>
        
        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Forms")}
        >
          <div className='widgetItemName'>
            Forms
          </div>

          
        </div>


        <div className='containerList'>
        
            <>
             <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <QueryForm  setActiveView={setActiveView}></QueryForm>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              QF
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Query Form Builder
          </div>
        </div>
            </>
          
</div>

        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Notifications")}
        >
          <div className='widgetItemName'>
            Notifications
          </div>

          
        </div>

        <div className='containerList'>
            <>
             
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Message setActiveView={setActiveView}></Message>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              M
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Message
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Badge setActiveView={setActiveView}></Badge>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              B
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Badge
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Toast setActiveView={setActiveView}></Toast>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              T
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Toast
          </div>
        </div>

            </>
          
</div>
          <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("other")}
        >
          <div className='widgetItemName'>
            Other
          </div>

          
        </div>

        <div className='containerList'>
            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Element
             setActiveView={setActiveView}
                canvas
                is={Container}
                background={{ r: 78, g: 78, b: 78, a: 1 }}
                color={{ r: 0, g: 0, b: 0, a: 1 }}
                height="300px"
                width="300px"
              ></Element>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              <SquareSvg />
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Container
          </div>
        </div>
        <div 
className='widgetItemIn'
onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(ref, 
           <Text setActiveView={setActiveView} fontSize="12" textAlign="left" text="Hi there"></Text>
           )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Text" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              <TypeSvg />
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Text
          </div>
        </div>
        <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <Button setActiveView={setActiveView}></Button>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Button" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              <ButtonSvg />
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Button
          </div>
        </div>
        <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <Video setActiveView={setActiveView}></Video>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              <YoutubeSvg />
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Video
          </div>        
          </div>

          <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <AccordionN setActiveView={setActiveView}></AccordionN>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              acc
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Accordion
          </div>        
          </div>

          <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <CardWidget setActiveView={setActiveView}></CardWidget>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Card
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Card
          </div>        
          </div>

          <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <GridWidget setViewSettings={setViewSettings}></GridWidget>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Grid
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Grid
          </div>        
          </div>

          <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <FormBuilderWidget setViewSettings={setViewSettings}></FormBuilderWidget>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              forms
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Form Builder
          </div>        
          </div>
            </>
          
</div>

          </div>
          </div>  : null }
        

          </div> : null
          }
        <div className="viewsHeader" style={{alignItems:"center", justifyContent:"space-between"}}>
          <div style={{display:"flex", alignItems:"flex-end", height:"100%"}}>
               
                {/* {views? views?.length > 0 ? views.map((x, i) => (
                    <div onClick={() => setActiveView(x)} className={`viewContainer`} style={{fontWeight: view?._id == x._id ? "600" : "500"}}>
                        <span className="viewWN">{x.name}</span>
                            <div className={`${view?._id == x._id ? "activeView" : "hoverView"}`} />
                    </div>
                )) : (
                    <div style={{fontSize:"1.5em", color:"gray"}}>This workspace have no views yet</div>
                ) : null} */}

                {workspace.type == "editor" || workspace.type == "owner" && <div className="addViewButton adminButton" style={{display:"flex", alignItems:"center"}} 
                onClick={() => {
                     let newUser = {}
                     newUser._id = user.user._id
                     newUser.name = user.user.name
                     newUser.role = "editor"
                     addUserToView(newUser)
                    setViewAddModal("View")}}
                    >
                    <img className="softPink" src={addViewPink}/>
                </div>}
                </div>
          {workspace?.type !== "owner" ||  workspace?.type !== "editor" || isApp ? <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
            {/* {true && <> 
              {!isApp && <div  style={{cursor:"pointer", display:"flex", justifyContent:"center", alignItems:"center", color:"white", backgroundColor:"#116dff",margin:"0 5px", padding:"0 18px", height:"30px", borderRadius:"15px"}}
            onClick={() => {
              let newUser = {}
              newUser._id = user.user._id
              newUser.name = user.user.name
              newUser.role = "editor"
              addUserToApp(newUser)
                    setAppAddModal("App")}}
          >
            Create app
            </div>}

              <div onClick={() => {
                  setImportModal(true)
              }} 
              
              style={{cursor:"pointer", width:"100px", display:"flex", justifyContent:"center", alignItems:"center",color:"#116dff", padding:"0 5px", height:"44px"}}>
              Import
            </div>
            

            {!isApp && <div onClick={() => {
              setExportModal("workspace")
              }} 
              style={{cursor:"pointer", display:"flex", justifyContent:"center", alignItems:"center", color:"#116dff",padding:"0 5px", marginRight:"20px", height:"44px"}}>
              Export Workspace
            </div>}


            { enabled && <div onClick={() => {
                            actions.setOptions((options) => (options.enabled = false));
              if(html) {
                const json = lz.decompress(lz.decodeBase64(html));
                actions.deserialize(json)
              } else {
                actions.deserialize({"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true, "props":{"flexDirection":"column","alignItems":"flex-start","justifyContent":"flex-start","fillSpace":"no","padding":["40","40","40","40"],"margin":["0","0","0","0"],"background":{"r":255,"g":255,"b":255,"a":1},"color":{"r":0,"g":0,"b":0,"a":1},"shadow":0,"radius":0,"width":"100%","height":"518px"},"displayName":"Container","custom":{"displayName":"App"},"hidden":false,"nodes":[],"linkedNodes":{}}})
              }
              }} style={{cursor:"pointer", width:"100px", display:"flex", justifyContent:"center", alignItems:"center", color:"red", padding:"0 5px", height:"44px"}}>
              Discard
            </div>}
           {!isApp && enabled && <div onClick={() => {
              actions.setOptions((options) => (options.enabled = false));
              // setPreviewMode(true)
              }} style={{cursor:"pointer", width:"100px", display:"flex", justifyContent:"center", alignItems:"center", color:"#116dff", padding:"0 5px", height:"44px"}}>
              Preview
            </div>}
            
            </>} */}
            <div  style={{cursor:"pointer", width:"100px", fontSize:"12px", display:"flex", justifyContent:"center", alignItems:"center", color:"white", backgroundColor:"#116dff",margin:"0 5px", padding:"0 18px", height:"25px", borderRadius:"4px"}}
            onClick={() => {
              actions.setOptions((options) => (options.enabled = !enabled));
              enabled && updateViewFunc()
            }}
          >
            {enabled ? 'Publish' : 'Edit App'}
            </div>
          </div> : null }
          </div>
          {workspace?.type == "owner" ||  workspace?.type == "editor" ? true ? <Header
            user={user}
            html={html}
            type={type}
            setImportModal={setImportModal}
            setExportModal={setExportModal}
            workspace={workspace}
            _id={_id}
            view={view}
            viewsRefetch={viewsRefetch}
            editMode={editMode}
            setEditMode={setEditMode}
            canvasView={canvasView}
            setCanvasView={setCanvasView}
          /> : null : null}




          <div
            className={cx([
              `craftjs-renderer flex-1 h-full w-full transition pb-8 overflow-auto ${previewMode && "previewModeCx"}`,
              {
                'bg-renderer-gray': enabled,
              },
            ])}
            ref={(ref) => connectors.select(connectors.hover(ref, null), null)}
            style={{ 
              borderRadius:canvasView == "pc" ? "0px" : "15px",display:"flex", justifyContent:"center", width:canvasView == "pc" ? "100%" : canvasView == "tablet" ? "800px" : "360px"}}
          >
            {previewMode && <div style={{position:"fixed", top:"10px", right:"10px", zIndex:99999, cursor:"pointer"}}>
            <FontAwesomeIcon
          className="modal_x"
          icon={faTimes}
          style={{color:"red"}}
          onClick={() => {
            setPreviewMode(false)
            actions.setOptions((options) => (options.enabled = true));
            
          }}
          /> 
            </div>}
            <div className="relative flex-col flex items-center" style={{paddingRight:"1rem", paddingLeft:"1rem", width:"100%", paddingTop:"20px"}}>
              {children}
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
};
