import { UserComponent, useEditor, useNode } from '@craftjs/core';
import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { ButtonSettings } from './ButtonSettings';
import { useDispatch } from 'react-redux';
import ACTION_ACTIVE_ID from '../../../../../../../actions/ActiveId/ACTION_ACTIVE_ID';
export const Button = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));

  const dispatch = useDispatch()
  const { 
    background,
    buttonStyle,
    text,
    margin,
    href,
    classn,
    color,
    children,
    style,
    ...otherProps 
  } = props;
    console.log(props, 20202)
  return (
    <a
    onClick={() => !enabled ? dispatch(ACTION_ACTIVE_ID(href)) : null}
    href={href && enabled == null ? `/${href}` : null}
    
    ref={connect}
    className={`${classn} ${props.class}`}
    {...otherProps}
    style={{
      ...style,
      background:
        `rgba(${Object.values(props.background)})`,
      color: color ? `rgba(${Object.values(color)})` : "",
      border: "2px solid transparent",
      borderColor:
        props.buttonStyle === 'outline'
          ? `rgba(${Object.values(props.background)})`
          : 'transparent',
      margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : 0
    }}
    >
      {children || "Button"}
    </a>
  );
};

Button.craft = {
  displayName: 'Button',
  props: {
    background: { r: 255, g: 255, b: 255, a:1 },
    color: { r: 92, g: 90, b: 90, a: 1 },
    buttonStyle: 'full',
    text: 'Button',
    margin: ['5', '0', '5', '0'],
  },
  related: {
    toolbar: ButtonSettings,
  },
};
