import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Column3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div style={{display:"flex", width:"100%"}} ref={connect}>
    
  <Element canvas  style={{width:"33%", margin:"10px", minHeight:"100px"}} id="Container1111" is="div">

  </Element>
  
  <Element canvas  style={{width:"33%", margin:"10px", minHeight:"100px"}} id="Container1211" is="div">

  </Element>

  <Element canvas  style={{width:"33%", margin:"10px", minHeight:"100px"}} id="Container1213" is="div">

</Element>

    </div>;
};
Column3.craft = {
  displayName: "Column3"
};