import { gql } from "apollo-boost";

const addFormMutation = gql`
  mutation(
    $collectionId: ID,
    $name: String,
    $form: String,
  ) {
    addForm(
    collectionId:$collectionId,
    name:$name,
    form:$form,
    ) {
    _id
    collectionId,
    name,
    form,
    }
  }
`;

export default addFormMutation;
