import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, ColumnMenu, Inject,Group, Sort, DetailRow, Page, ExcelExport, ContextMenu } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './SampleBase';
import { DataSpinner } from '../../spinner/dataSpinner';
import arrowRight from "../../../../assests/images/arrowRight.svg"
import edit from "../../../../assests/images/edit.svg"
import trash from "../../../../assests/images/trash.svg"


export default class CampaignTabel extends SampleBase {
    
    constructor() {
        super(...arguments);
        this.state = {
            data: null
        }
        this.groupOptions = { showGroupedColumn: true};
        this.dReady = false;
        this.dtTime = false;
        this.isDataBound = false;
        this.isDataChanged = true;
        this.dropSlectedIndex = null;
        this.ddlData = [
            { text: '1,000 Rows and 11 Columns', value: '1000' },
            { text: '10,000 Rows and 11 Columns', value: '10000' },
            { text: '1,00,000 Rows and 11 Columns', value: '100000' }
        ];
        this.fields = { text: 'text', value: 'value' };
        this.getTradeData = this.props.data;
        this.check = {
            type: 'CheckBox'
        };
        this.select = {
            persistSelection: true,
            type: "Multiple",
            checkboxOnly: true
        };
        this.Filter = {
            type: 'Menu'
        };
        this.status = {
            type: 'CheckBox',
            
        };
        this.trust = {
            type: 'CheckBox',
            
        };
        this.rating = {
            type: 'CheckBox',
            
        };
        this.excel = {
          type:"Excel"
      }



    }
  

 statusTemplate = (props) => {
    return (<div className={`print-status ${props.active ? "greenActive" : "redDeactive"}`}>
    <span>{props.active ? "Active" : "Deactive"}</span>
</div>);
}


loc = { width: '31px', height: '24px' };

 empTemplate = (props) => {
    return (<div className="print-status">
    <span>{props.state}</span>
</div>);
}
 coltemplateWorkspace = (props) => {
     return (
        <div style={{ display:"flex", alignItems:"center"}}>
        <div style={{color:"#3C3C3C", fontSize:"15px", marginRight:".8rem"}}>{props.workspaces}</div>
        <div className="moreButton">More <img style={{marginLeft:".5rem"}} src={arrowRight}/></div>
    </div>
     )
}

coltemplateForm = (props) => {
    return (
       <div style={{ display:"flex", alignItems:"center"}}>
       <div style={{color:"#3C3C3C", fontSize:"15px", marginRight:".8rem"}}>{props.form}</div>
       <div className="moreButton">More <img style={{marginLeft:".5rem"}} src={arrowRight}/></div>
   </div>
    )
}

deleteFeedBackTemplate = (props) => {
  /////console.log(props)
  return (
    <div className="actions-buttons">
                    <img style={{marginRight:".4rem"}} className="actionHover" src={edit}/>
                    <img style={{marginLeft:".4rem"}} className="actionHover" src={trash}/>
                </div>)
}

componentDidUpdate() {
  if(this.state.data !== this.props.data) {
    this.setState({data: this.props.data})
    if(this.gridInstance) {
      this.gridInstance.clearGrouping()
     }
  }
}

locationOptions = [
  { value: 'Maadi', label: 'El Maadi'},
  { value: 'Zayd', label: 'Elshiekh zayd' },
  { value: 'tagamoa', label: 'the 5th settlement' },
  { value: 'Heliopolis', label: 'Masr elgdida' },

]
stateOptions = [
  { value: 'boooked', label: 'Booked'},
  { value: 'noShow', label: 'No show' },
  { value: 'followUp', label: 'Follow up' },
  { value: 'noAnswer', label: 'No answer' },
  { value: 'notInterested', label: 'Not interested' },
  { value: 'overPrices', label: 'Over prices' },
  { value: 'fresh', label: 'Fresh' }
]



 feedbackOptions = [
  { value: 'solved', label: 'Solved'},
  { value: 'notSolved', label: 'Not solved' },
  { value: 'noAnswer', label: 'No answer' },
  { value: 'rescheduled', label: 'Rescheduled' },
]
   render() {

//  userName : x.name,
//  email: x.email,
//  date: moment.unix(x.createdAt/1000).format("MM/DD/YYYY"),
//  state: x.role,
//  id: x._id,
//  workspaces: 0,
//  forms: 0,
//  active: x.active
this.contextMenuItems = ['ExcelExport', 'CsvExport'];
/////console.log(this.props.columns)
        return (<div className='control-pane'>
        {this.props.data ? ( <div className='control-section'>
          <GridComponent id="overviewgrid" showColumnMenu={true} allowGrouping={true} groupSettings={this.groupOptions} dataSource={this.props.data} enableHover={true} rowHeight={50} ref={(g) => { this.gridInstance = g; }} filterSettings={this.Filter} allowFiltering={true} allowSorting={true} allowSelection={true} selectionSettings={this.select} allowPaging={true}  allowExcelExport={true} contextMenuItems={this.contextMenuItems}>
            <ColumnsDirective>
              <ColumnDirective field='userName  ' visible={false} headerText='User ID' isPrimaryKey={true} width='auto' clipMode='EllipsisWithTooltip'></ColumnDirective>
              { this.props.columns && 
this.props.columns.some((x) => x.name == "userName") && this.props.columns.filter((x) => x.name == "userName")[0].active &&  <ColumnDirective field='userName' headerText='User Name' filter={this.check} width='auto' clipMode='EllipsisWithTooltip'></ColumnDirective> }
 { this.props.columns && 
this.props.columns.some((x) => x.name == "name") && this.props.columns.filter((x) => x.name == "name")[0].active &&  <ColumnDirective field='name' headerText='Name' filter={this.check} width='auto' clipMode='EllipsisWithTooltip'></ColumnDirective> }
              { this.props.columns && 
this.props.columns.some((x) => x.name == "role") && this.props.columns.filter((x) => x.name == "role")[0].active && <ColumnDirective field='state' headerText='Role' width='auto' clipMode='EllipsisWithTooltip' template={this.empTemplate} filter={this.check}/>}
              { this.props.columns && 
this.props.columns.some((x) => x.name == "state") && this.props.columns.filter((x) => x.name == "state")[0].active && <ColumnDirective field='active' headerText='State' filter={this.status} template={this.statusTemplate} width='auto' clipMode='EllipsisWithTooltip'></ColumnDirective>}
              { this.props.columns && 
this.props.columns.some((x) => x.name == "date") && this.props.columns.filter((x) => x.name == "date")[0].active && <ColumnDirective field='date' headerText='Date' filter={this.excel} width='auto' clipMode='EllipsisWithTooltip'></ColumnDirective>}
              { this.props.columns && 
this.props.columns.some((x) => x.name == "email") && this.props.columns.filter((x) => x.name == "email")[0].active && <ColumnDirective field='email' headerText='Email' filter={this.check} width='auto' clipMode='EllipsisWithTooltip'></ColumnDirective>}
              { this.props.columns && 
this.props.columns.some((x) => x.name == "workspaces") && this.props.columns.filter((x) => x.name == "workspaces")[0].active && <ColumnDirective field='workspaces' headerText='Workspaces' width='auto' filter={this.check}  template={this.coltemplateWorkspace} clipMode='EllipsisWithTooltip'></ColumnDirective>}
              { this.props.columns && 
this.props.columns.some((x) => x.name == "forms") && this.props.columns.filter((x) => x.name == "forms")[0].active && <ColumnDirective field='forms' headerText='Forms' width='auto' filter={this.check} template={this.coltemplateForm} clipMode='EllipsisWithTooltip'></ColumnDirective>}
               <ColumnDirective field='' headerText='Actions' allowFiltering={false} template={this.deleteFeedBackTemplate} width='auto' clipMode='EllipsisWithTooltip'></ColumnDirective>

            </ColumnsDirective>
            <Inject services={[DetailRow, Group,Filter,ColumnMenu, Sort, Page, ExcelExport, ContextMenu]}/>
          </GridComponent>
        </div> ) : (
            <DataSpinner />
        )  }
        

</div>);
    }
}

