import './index.scss';
import * as React from 'react';
import { SampleBase } from './sample-base';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';

import * as data from './dataSource.json';

class Component extends SampleBase {

  listObj;
    
    render() {
      console.log(this.props)

        return (<div id='combodefault' className='control-pane'>
        <div className='control-section'>
          <div className='control-wrappers'>
            <div id='default'>
              {this.props.items?.length ? <AutoCompleteComponent {...this.props.allEvents} id="games" dataSource={this.props.items?.map(x => x.string)} ref={(AutoComplete) => { this.listObj = AutoComplete; }} placeholder={this.props.placeholder}/> : null}
            </div>
          </div>
        </div>
        
      </div>);
    }
}

export default Component