import { gql } from "apollo-boost";

const updateUserMutation = gql`
  mutation(
    $_id: ID
    $name: String,
    $email: String,
    $phoneNumber: String,
    $active: Boolean,    
    $role: String
    $image: String
    $org: ID
    $group: String
    $department: String
    $departmentAdmin: Boolean
    $groupAdmin: Boolean

  ) {
    updateUser(
    _id: $_id
    name: $name
    email: $email
    phoneNumber: $phoneNumber
    active: $active
    role: $role
    image: $image
    org: $org
    group: $group
    department: $department
    departmentAdmin: $departmentAdmin
    groupAdmin: $groupAdmin
    ) {
      _id
      name
      email
      phoneNumber
      active
      role
      image
      org
      group
      department
      departmentAdmin
      groupAdmin
    }
  }
`;

export default updateUserMutation;
