import React, { useState } from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  const [items, setItems] = useState([])

  return (
    <div style={{height:"100%", overflow:"auto"}}>
      <ToolbarSection
     title="Placeholder"
   >
    <ToolbarItem
      full={true}
      propKey="placeholder"
      type="text"
      label="placeholder"
    />
    </ToolbarSection>
    <ToolbarSection
     title="new item"
     onClick={() => setItems([...items, {}])}
    >
    </ToolbarSection>
   
    {items.map((x,i) => (<><ToolbarSection
      title={`item ${i+1}`}
      props={['items']}
    >
     <ToolbarItem
      full={true}
      propKey="items"
      type="text"
      label="Item name"
      addTo="items"
      theKey="string"
      index={i}
     />
   </ToolbarSection>
  </>
   ))}
 </div>
  );
};
