import React, { useEffect, useState } from 'react';

import { useNode } from '@craftjs/core';
import {GridSettings} from "./GridSettings"

import DataTable from 'react-data-table-component';
import DataGrid from '../../../../accountTable/tableContent/DataGrid';
import add from "../../../../../../assests/images/add.svg"
import edit from "../../../../../../assests/images/edit.svg"
import trash from "../../../../../../assests/images/trash.svg"
import AddDbRecordModal from '../../../../modals/AddDbRecordModal';
import addDbRecordMutation from '../../../../../../graphql/mutations/addRecord/addDbRecordMutation';
import updateDbRecordMutation from '../../../../../../graphql/mutations/addRecord/updateDbRecordMutation';
import deleteDbRecordMutation from '../../../../../../graphql/mutations/addRecord/deleteDbRecordMutation';
import { useMutation } from '@apollo/client';
import ACTION_LOADING_SPINNER_RESET from '../../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET';
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE';
import { useDispatch } from 'react-redux';


export const GridWidget = (props) => {

    const {
setActiveView,
dataBaseDataArr,
eventsDataArr,
filter
    } = props

    const { id } = useNode();
    const [addDbRecord, {error: addDbRecordError, data: addDbRecordData, loading: addDbRecordLoading}] = useMutation(addDbRecordMutation)
    const [updateDbRecord, {error: updateDbRecordError, data: updateDbRecordData, loading: updateDbRecordLoading}] = useMutation(updateDbRecordMutation)
    const [deleteDbRecord, {error: deleteDbRecordError, data: deleteDbRecordData, loading: deleteDbRecordLoading}] = useMutation(deleteDbRecordMutation)


    const { 
        name, 
        connectors: { connect },
      } = useNode((node) => ({
        selected: node.events.selected,
        name: node.data.custom.displayName || node.data.displayName
      })); 

/////console.log(source)
const [accountColumns, setAccountColumns] = useState(null)
const [accountData, setAccountData] = useState(null)
const [activeDb, setActiveDb] = useState(null)
  useEffect(() => {
    if(dataBaseDataArr) {
      console.log(dataBaseDataArr)
      let newArr = dataBaseDataArr?.results
      let db = dataBaseDataArr.db
      setActiveDb(db)
      console.log(newArr)
      let dataKeys = newArr?.length ? Object.keys(newArr[0]) : []
      setAccountColumns(dataKeys.filter(x => x !== "password").map(x => {
        console.log(dataKeys)
        return {
          name: x,
          selector: row => row[x] == true ? "yes" : row[x] == false ? "no" : row[x],
      }
      })) 
      let dataArr = newArr
      console.log(dataArr)
      setAccountData(dataArr)
    }
  },[dataBaseDataArr])

  useEffect(() => {
    if(eventsDataArr) {
      console.log(eventsDataArr)
    }
  },[eventsDataArr])

  const [filteredData, setFilteredData] = useState(null)
  useEffect(() => {
    if(filter) {
      console.log(filter)
      let filters = Object.keys(filter)
      for(let a = 0; a < filters.length; a++) {
        setFilteredData(accountData?.filter(x => x["org"] == filter[filters[a]]))
      }
    } else {
      setFilteredData(null)
    }
  },[filter])

  const [selectedRow, setSelectedRow] = useState(null)
const dispatch = useDispatch()
  
  const [addRecordModal, setAddRecordModal] = useState(false)
  
  const [state, setState] = useState(null)
  
  console.log(selectedRow)
  const addRecordFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setSelectedRow(null)
    setAddRecordModal(false)
    addDbRecord({
      variables:{
        url: activeDb.url,
        db: activeDb.db,
        table: activeDb.table,
        record: JSON.stringify(state)
      }
    }).then(res => {
      if(res?.data?.addDbRecord?.url == "done") {
        setState(null)
        dispatch(ACTION_LOADING_SPINNER_RESET())
      }
    })
    }

    const updateRecordFunc = () => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setSelectedRow(null)
      setAddRecordModal(false)
      updateDbRecord({
        variables:{
          url: activeDb.url,
          db: activeDb.db,
          table: activeDb.table,
          record: JSON.stringify(state)
        }
      }).then(res => {
        if(res?.data?.updateDbRecord?.url == "done") {
          setState(null)
          dispatch(ACTION_LOADING_SPINNER_RESET())
        }
      })
      .catch(err => {
        if(err) {
          dispatch(ACTION_LOADING_SPINNER_RESET())
        }
      })
      }



      const deleteRecordFunc = () => {
        dispatch(ACTION_LOADING_SPINNER_ACTIVE())
        // setSelectedRow(null)
        // setAddRecordModal(false)
        deleteDbRecord({
          variables:{
            url: activeDb.url,
            db: activeDb.db,
            table: activeDb.table,
            record: JSON.stringify(state)
          }
        }).then(res => {
          if(res?.data?.deleteDbRecord?.url == "done") {
            setState(null)
            dispatch(ACTION_LOADING_SPINNER_RESET())
          }
        })
        .catch(err => {
          if(err) {
            console.log(err)
            dispatch(ACTION_LOADING_SPINNER_RESET())
          }
        })
        }
  
        useEffect(() => {
          if(filter) {
            console.log(filter, "77")
          }
        },[filter])

  return (
      <div className="contentContainer" ref={connect}>
        <AddDbRecordModal 
          open={addRecordModal}
          setOpen={setAddRecordModal}
          state={state}
          setState={setState}
          addRecordFunc={addRecordFunc}
          updateRecordFunc={updateRecordFunc}
          accountColumns={accountColumns}
        />
        <div style={{ height:"50px", display:"flex", borderBottom:"0.5px solid rgba(171, 176, 185, 0.5)", alignItems:"center"}}>
          <div style={{ width:"50px", display:"flex", justifyContent:"center", borderRadius:"5px", alignItems:"center"}}>
          <img
                        className="routeIcon"
                        src={add}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => {
                          setState(accountColumns.map(x => {
                            return {
                              name: x.name,
                              value: ""
                            }
                          }).reduce((obj, cur) => ({...obj, [cur.name]: cur}), {}))
                          setAddRecordModal("Record")
                        }}
                        
                        />
          </div>
          <div style={{ opacity: selectedRow?.selectedCount == 1 ? 1: .5, width:"50px", display:"flex", justifyContent:"center", borderRadius:"5px", alignItems:"center"}}>
          <img
                        className="routeIcon"
                        src={edit}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => {
                          setState(accountColumns.map(x => {
                            return {
                              name: x.name,
                              value: accountData.find(y => y[Object.keys(y)[0]] == selectedRow?.selectedRows[0][Object.keys(selectedRow?.selectedRows[0])[0]])[x.name]
                            }
                          }).reduce((obj, cur) => ({...obj, [cur.name]: cur}), {}))
                          setAddRecordModal("Edit Record")
                        }}
                        
                        />
          </div> 
          <div style={{ opacity: selectedRow?.selectedCount >= 1 ? 1: .5, width:"50px", display:"flex", justifyContent:"center", borderRadius:"5px", alignItems:"center"}}>
          <img
                        className="routeIcon"
                        src={trash}
                        style={{marginLeft:".8rem", cursor:"pointer"}}
                        onClick={() => {
                          setState(accountColumns.map(x => {
                            return {
                              name: x.name,
                              value: accountData.find(y => y[Object.keys(y)[0]] == selectedRow?.selectedRows[0][Object.keys(selectedRow?.selectedRows[0])[0]])[x.name]
                            }
                          }).reduce((obj, cur) => ({...obj, [cur.name]: cur}), {}))
                          deleteRecordFunc()
                        }}
                        
                        />
          </div>
        </div>
             {eventsDataArr?.some(x => x.event.eventName == "onEmpty" && x.event.eventType == "Message") ? filteredData && !filteredData?.length ? <div style={{height:"100px", display:"flex", justifyContent:"center", alignItems:"center", fontSize:"2vmin"}}>{eventsDataArr.find(x => x.event.eventName == "onEmpty")?.event.eventContent}</div> : <DataTable
                columns={ accountColumns || []}
                data={ filteredData || accountData || []}
                onRowClicked={(row) => setSelectedRow(row)}
                selectableRows
                onSelectedRowsChange={(row) => setSelectedRow(row)}
                
                //progressPending={accountData == null}
                //progressComponent={RenderSitesLoader()}
              /> : <DataTable
              columns={ accountColumns || []}
              data={ filteredData || accountData || []}
              onRowClicked={(row) => setSelectedRow(row)}
              selectableRows
              onSelectedRowsChange={(row) => setSelectedRow(row)}
              
              //progressPending={accountData == null}
              //progressComponent={RenderSitesLoader()}
            />}
        </div>
  );
};

 GridWidget.craft = {
  displayName: 'Grid',
  props: {
    dataBaseDataArr: null,
    filter: null,
    eventsDataArr: null,
  },
  related: {
    toolbar: GridSettings,
  },
};