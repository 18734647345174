const OPTIONS = "OPTIONS";

const ACTION_OPTIONS = (payload) => {
  return {
    type: OPTIONS,
    payload
  };
};

export default ACTION_OPTIONS;
