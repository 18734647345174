import { Button } from '../Button';


import { Element, useNode } from '@craftjs/core';
import React from 'react';
import Text from '../Text';
export const Banner1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-gray-50">
  <div class="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
    <div class="mx-auto max-w-xl text-center">
      <h1 class="text-3xl font-extrabold sm:text-5xl">
       <Element canvas  style={{display:"inline-block"}} id="Text1" is="div"><Text text="Understand User Flow." />
       
        <strong class="font-extrabold text-red-700 sm:block">
         <Element canvas  style={{display:"inline-block"}} id="Text2" is="div"><Text text="Increase Conversion." />
        </Element>
        </strong>
        </Element>
      </h1>

      <p class="mt-4 sm:text-xl sm:leading-relaxed">
       <Element canvas  style={{display:"inline-block"}} id="Text3" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo
        tenetur fuga ducimus numquam ea!" />
        </Element>
      </p>

      <div class="mt-8 flex flex-wrap justify-center gap-4">
        <Element canvas  style={{ width: "100%" }} id={"div720863"} is="div">
            <Button href={"/get-started"} classn={"block w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto cursor-pointer"}>
               {<Element canvas  style={{display:"inline-block"}} id="812573" is="div"><Text text="Get Started" /></Element>} 
            </Button>
          </Element>

        <Element canvas  style={{ width: "100%" }} id={"div313059"} is="div">
            <Button href={"/about"} classn={"block w-full rounded px-12 py-3 text-sm font-medium text-red-600 shadow hover:text-red-700 focus:outline-none focus:ring active:text-red-500 sm:w-auto cursor-pointer"}>
               {<Element canvas  style={{display:"inline-block"}} id="282374" is="div"><Text text="Learn More" /></Element>} 
            </Button>
          </Element>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Banner1.craft = {
  displayName: "Banner 1"
};