import { gql } from "apollo-boost";

const updateKeywordMutation = gql`
  mutation(
    $_id: ID, 
    $before: String,
    $after: String,
    $language: String,
    $org: ID,    
    $default: Boolean

  ) {
    updateKeyword(
    _id: $_id,
    before:$before,
    after:$after,
    language:$language,
    org:$org,
    default: $default
    ) {
    _id
    before,
    after,
    language,
    org,
    default

    }
  }
`;

export default updateKeywordMutation;
