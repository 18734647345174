import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import ContentEditable from 'react-contenteditable';

import { TextSettings } from './TextSettings';
import { useDispatch } from 'react-redux';
import ACTION_ACTIVE_ID from '../../../../../../../actions/ActiveId/ACTION_ACTIVE_ID';


const Text = (props) => {

  const dispatch = useDispatch()
  const {
    tag,
    text,
    style,
    fontSize,
    textAlign,
    fontWeight,
    color,
    shadow,
    margin,
    href,
    classInt
  } = props
  const {
    connectors: { connect },
    actions,
  } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  console.log(props.class, 100000000000)

  return (
    <ContentEditable
      onClick={() => !enabled ? dispatch(ACTION_ACTIVE_ID(href)) : null}
      // href={href && enabled == null ? `/${href}` : null}
      innerRef={connect}
      html={text} // innerHTML of the editable div
      disabled={!enabled}
      className={classInt !== 0 ? `class_${classInt}` : ""}
      onChange={(e) => {
        actions.setProp((prop) => (prop.text = e.target.value), 500);
      }} // use true to disable editing
      tagName={tag} // Use a custom HTML tag (uses a div by default)
      style={
        {width: '100%',
        margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : 0,
        color: color ? `rgba(${Object.values(color)})` : "",
        fontSize: fontSize ? `${fontSize}px` : "",
        textShadow: shadow ? `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})` : "",
        fontWeight,
        textAlign, 
        ...style}
      }
    />
  );
};

Text.craft = {
  displayName: 'Text',
  props: {
    // fontSize: '15',
    // textAlign: 'left',
    // fontWeight: '500',
    // color: { r: 92, g: 90, b: 90, a: 1 },
    margin: [0, 0, 0, 0],
    // shadow: 0,
    text: 'Text',
    classInt: 0,
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: TextSettings,
  },
};

export default Text