import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
class Component extends SampleBase {
  btnobj;
  //Toggle button click event handler
  btnClick() {
      let proxy = this;
      if (proxy.btnobj.element.classList.contains('e-active')) {
          proxy.btnobj.content = 'Pause';
          proxy.btnobj.iconCss = 'e-btn-sb-icons e-pause-icon';
      }
      else {
          proxy.btnobj.content = 'Play';
          proxy.btnobj.iconCss = 'e-btn-sb-icons e-play-icon';
      }
  }
  render() {
      return (<div className='control-pane'>
      <div className='control-section'>
        <div className='button-section'>
          <div id='button-control'>
              <div className='row'>
                  <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                      <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6">
                          <ButtonComponent {...this.props.allEvents} cssClass='e-primary'>Button</ButtonComponent>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
      );
    } 
}

export default Component