import React from 'react';

import { ToolbarItem, ToolbarSection } from '../../../editor';

export const NewBannerSettings = () => {

  return (
    <div style={{display:"flex", height:"100%"}}>
       <ToolbarSection
        title="Appearance"
        props={['color', 'shadow']}
        summary={({ color, shadow }) => {
          return (
            <div className="fletext-right">
              <p
                style={{
                  color: color && `rgba(${Object.values(color)})`,
                  textShadow: shadow && `0px 0px 2px rgba(0, 0, 0, ${shadow / 100})`,
                }}
                className="text-white text-right"
              >
                T
              </p>
            </div>
          );
        }}
      >
       
        <ToolbarItem
          full={true}
          propKey="selected"
          type="vslider"
          label="Styling"
        />

      </ToolbarSection>

     
    </div>
  );
};