import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Section3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section>
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
        <Element canvas style={{ width: "100%" }} id={"div599371"} is="div">
          <ImageC alt="Party" src="https://images.unsplash.com/photo-1527529482837-4698179dc6ce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" class="absolute inset-0 h-full w-full object-cover"  />
        </Element>
      </div>

      <div class="lg:py-24">
        <h2 class="text-3xl font-bold sm:text-4xl"> {<Element canvas style={{display:"inline-block"}} id="613688" is="div"><Text text="Grow your audience" /></Element>} </h2>

        <p class="mt-4 text-gray-600"> {<Element canvas style={{display:"inline-block"}} id="790855" is="div"><Text text="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut qui hic
          atque tenetur quis eius quos ea neque sunt, accusantium soluta minus
          veniam tempora deserunt? Molestiae eius quidem quam repellat." /></Element>} </p>

        <Element canvas style={{ width: "100%" }} id={"div128040"} is="div">
            <Button href={"#"} classn={"mt-8 inline-flex items-center rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500 cursor-pointer"}>
              
          <span class="text-sm font-medium"> {<Element canvas style={{display:"inline-block"}} id="402872" is="div"><Text text="Get Started" /></Element>} </span>

          <svg class="ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        
            </Button>
          </Element>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Section3.craft = {
  displayName: "Section 3"
};