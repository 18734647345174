import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';
import { FileManagerComponent, Inject, NavigationPane, DetailsView, Toolbar, ContextMenu } from '@syncfusion/ej2-react-filemanager';
/** 
 * File Manager sample with Amazon S3 file provider service
 */
class Component extends SampleBase {
  hostUrl = "https://amazons3.azurewebsites.net/api/AmazonS3Provider/";
  render() {
      return (<div>
              <div className="control-section">
                  <FileManagerComponent {...this.props.allEvents} id="filemanager" ajaxSettings={{
              url: this.hostUrl + 'AmazonS3FileOperations',
              getImageUrl: this.hostUrl + 'AmazonS3GetImage',
              uploadUrl: this.hostUrl + 'AmazonS3Upload',
              downloadUrl: this.hostUrl + 'AmazonS3Download'
          }} searchSettings={{ allowSearchOnTyping: false }}>
                  <Inject services={[NavigationPane, DetailsView, Toolbar, ContextMenu]}/>
                  </FileManagerComponent>
              </div>
          </div>
      );
    }
}

export default Component