import React, { useState } from 'react';

import { useNode } from '@craftjs/core';
import { NewBannerSettings } from './newBannerSetting';

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import FitbitIcon from '@mui/icons-material/Fitbit';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

export const Banner_1 = ({selected}) => {

    const {
        connectors: {
          connect
        } 
    } = useNode();

  const styles = [ 

    <div>
      <div className="Header1Mobile w-[390px] h-[81px] mb-10 relative bg-[#FFFDFB] sm:hidden">
        <div className="Logo cursor-pointer w-[34px] h-[34px] left-[24px] top-[23px] absolute">
          <FitbitIcon sx={{fontSize: 40, color:'#510B11'}}/>
        </div>
        <div className="Logo cursor-pointer left-[68px] top-[30px] absolute text-rose-950 text-[18px] font-bold font-['Rubik'] leading-[25.20px]">LOGO</div>
        <div className="w-10 h-10 left-[326px] top-[23px] cursor-pointer absolute">
          <MenuIcon  sx={{fontSize: 40, color:'#510B11'}}/>
        </div>
        <div className="Rectangle1513 w-[370px] h-px left-[10px] top-[80px] absolute bg-red-700 bg-opacity-20" />
      </div>
      <div className="Header1Mobile lg:w-[1440px] lg:h-[81px] md:w-[1024px] md:h-[81px] w-[390px] h-[398px] relative bg-[#FFFDFB]">
      <div className="Rectangle1513 lg:w-[1400px] lg:h-px lg:left-[20px] lg:top-[80px] md:w-[984px] md:h-px md:left-[20px] md:top-[80px] w-[370px] h-px left-[10px] top-[80px] absolute bg-red-700 bg-opacity-20" />
      <div className="Logo cursor-pointer lg:w-10 lg:h-10 md:w-10 md:h-10 w-[34px] h-[34px] lg:left-[124px] lg:top-[20px] md:left-[60px] md:top-[20px] left-[24px] top-[23px] absolute">
      <FitbitIcon sx={{fontSize: 40, color:'#510B11'}}/>
      </div>
      <div className="Logo cursor-pointer lg:left-[174px] lg:top-[25px] md:left-[110px] md:top-[25px] left-[68px] top-[28px] absolute text-rose-950 lg:text-[22px] md:text-[22px] text-[18px] font-bold font-['Rubik'] leading-[30.80px]">LOGO</div>
      <div className="w-10 h-10 left-[326px] top-[23px] cursor-pointer absolute sm:hidden">
          <CloseIcon  sx={{fontSize: 40, color:'#510B11'}}/>
      </div>
      <div style={{height:"fit-content", width:"fit-content"}} className="HeaderMenu lg:w-[470px] lg:left-[485px] lg:top-[27px] md:w-[470px] md:left-[277px] md:top-[29px] w-[103px] h-[195px] left-[144px] top-[111px] lg:flex-row md:flex-row  absolute justify-center lg:items-start md:items-start items-center lg:gap-10 md:gap-[30px] gap-[25px] inline-flex">
        <div className="Home cursor-pointer text-stone-950 text-[16px] font-bold font-['Jost'] cursor-pointer uppercase">Home</div>
        <div className="AboutUs cursor-pointer text-stone-950 text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">About us</div>
        <div className="Pricing cursor-pointer text-stone-950 text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">Pricing</div>
        <div className="Faq cursor-pointer text-stone-950 text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">FAQ</div>
        <div className="ContactUs cursor-pointer text-stone-950 text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">Contact us</div>
      </div>
      <div className="BiFacebook cursor-pointer w-8 h-8 lg:left-[1284px] lg:top-[24px] md:left-[932px] md:top-[24px] left-[210px] top-[336px] absolute">
      <FacebookRoundedIcon alt="FB" sx={{fontSize: 40, color:'#510B11'}}/>
      </div>
      <div className="AntDesignInstagramFilled cursor-pointer w-8 h-8 lg:left-[1222px] lg:top-[24px] md:left-[870px] md:top-[24px] left-[148px] top-[336px] absolute">
      <InstagramIcon alt="IN" sx={{fontSize: 40, color:'#510B11'}}/>
      </div>
    </div>
    </div>,
  
     <div>
     <div className="Header1Mobile w-[390px] h-[81px] mb-10 relative bg-[#121826] sm:hidden">
       <div className="Logo cursor-pointer w-[34px] h-[34px] left-[24px] top-[23px] absolute">
         <FitbitIcon sx={{fontSize: 40, color:'#4BDBA2'}}/>
       </div>
       <div className="Logo cursor-pointer left-[68px] top-[30px] absolute text-[#4BDBA2] text-[18px] font-bold font-['Rubik'] leading-[25.20px]">LOGO</div>
       <div className="w-10 h-10 left-[326px] top-[23px] cursor-pointer absolute">
         <MenuIcon  sx={{fontSize: 40, color:'#4BDBA2'}}/>
       </div>
       <div className="Rectangle1513 w-[370px] h-px left-[10px] top-[80px] absolute bg-[#4BDBA2] bg-opacity-20" />
     </div>
     <div className="Header1Mobile lg:w-[1440px] lg:h-[81px] md:w-[1024px] md:h-[81px] w-[390px] h-[398px] relative bg-[#121826]">
     <div className="Logo cursor-pointer lg:w-10 lg:h-10 md:w-10 md:h-10 w-[34px] h-[34px] lg:left-[124px] lg:top-[20px] md:left-[60px] md:top-[20px] left-[24px] top-[23px] absolute">
     <FitbitIcon sx={{fontSize: 40, color:'#4BDBA2'}}/>
     </div>
     <div className="Logo cursor-pointer lg:left-[174px] lg:top-[25px] md:left-[110px] md:top-[25px] left-[68px] top-[28px] absolute text-[#4BDBA2] lg:text-[22px] md:text-[22px] text-[18px] font-bold font-['Rubik'] leading-[30.80px]">LOGO</div>
     <div className="w-10 h-10 left-[326px] top-[23px] cursor-pointer absolute sm:hidden">
         <CloseIcon  sx={{fontSize: 40, color:'#4BDBA2'}}/>
     </div>
     <div style={{height:"fit-content", width:"fit-content"}} className="HeaderMenu lg:w-[470px] lg:left-[485px] lg:top-[27px] md:w-[470px] md:left-[277px] md:top-[29px] w-[103px] h-[195px] left-[144px] top-[111px] lg:flex-row md:flex-row  absolute justify-center lg:items-start md:items-start items-center lg:gap-10 md:gap-[30px] gap-[25px] inline-flex">
       <div className="Home cursor-pointer text-white text-[16px] font-bold font-['Jost'] cursor-pointer uppercase">Home</div>
       <div className="AboutUs cursor-pointer text-white text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">About us</div>
       <div className="Pricing cursor-pointer text-white text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">Pricing</div>
       <div className="Faq cursor-pointer text-white text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">FAQ</div>
       <div className="ContactUs cursor-pointer text-white text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">Contact us</div>
     </div>
     <div className="BiFacebook cursor-pointer w-8 h-8 lg:left-[1284px] lg:top-[24px] md:left-[932px] md:top-[24px] left-[210px] top-[336px] absolute">
     <FacebookRoundedIcon alt="FB" sx={{fontSize: 40, color:'#4BDBA2'}}/>
     </div>
     <div className="AntDesignInstagramFilled cursor-pointer w-8 h-8 lg:left-[1222px] lg:top-[24px] md:left-[870px] md:top-[24px] left-[148px] top-[336px] absolute">
     <InstagramIcon alt="IN" sx={{fontSize: 40, color:'#4BDBA2'}}/>
     </div>
   </div>
   </div>,
  
  
  
  
  <div>
     <div className="Header1Mobile w-[390px] h-[81px] mb-10 relative bg-white sm:hidden">
       <div className="Logo cursor-pointer w-[34px] h-[34px] left-[24px] top-[23px] absolute">
         <FitbitIcon sx={{fontSize: 40, color:'#0078D3'}}/>
       </div>
       <div className="Logo cursor-pointer left-[68px] top-[30px] absolute text-[#0078D3] text-[18px] font-bold font-['Rubik'] leading-[25.20px]">LOGO</div>
       <div className="w-10 h-10 left-[326px] top-[23px] cursor-pointer absolute">
         <MenuIcon  sx={{fontSize: 40, color:'#0078D3'}}/>
       </div>
       <div className="Rectangle1513 w-[370px] h-px left-[10px] top-[80px] absolute bg-[#0078D3] bg-opacity-20" />
     </div>
     <div className="Header1Mobile lg:w-[1440px] lg:h-[81px] md:w-[1024px] md:h-[81px] w-[390px] h-[398px] relative bg-white">
     <div className="Rectangle1513 lg:w-[1400px] lg:h-px lg:left-[20px] lg:top-[80px] md:w-[984px] md:h-px md:left-[20px] md:top-[80px] w-[370px] h-px left-[10px] top-[80px] absolute bg-[#0078D3] bg-opacity-20" />
     <div className="Logo cursor-pointer lg:w-10 lg:h-10 md:w-10 md:h-10 w-[34px] h-[34px] lg:left-[124px] lg:top-[20px] md:left-[60px] md:top-[20px] left-[24px] top-[23px] absolute">
     <FitbitIcon sx={{fontSize: 40, color:'#0078D3'}}/>
     </div>
     <div className="Logo cursor-pointer lg:left-[174px] lg:top-[25px] md:left-[110px] md:top-[25px] left-[68px] top-[28px] absolute text-[#0078D3] lg:text-[22px] md:text-[22px] text-[18px] font-bold font-['Rubik'] leading-[30.80px]">LOGO</div>
     <div className="w-10 h-10 left-[326px] top-[23px] cursor-pointer absolute sm:hidden">
         <CloseIcon  sx={{fontSize: 40, color:'#0078D3'}}/>
     </div>
     <div style={{height:"fit-content", width:"fit-content"}} className="HeaderMenu lg:w-[470px] lg:left-[485px] lg:top-[27px] md:w-[470px] md:left-[277px] md:top-[29px] w-[103px] h-[195px] left-[144px] top-[111px] lg:flex-row md:flex-row  absolute justify-center lg:items-start md:items-start items-center lg:gap-10 md:gap-[30px] gap-[25px] inline-flex">
       <div className="Home cursor-pointer text-[#0078D3] text-[16px] font-bold font-['Jost'] cursor-pointer uppercase"><span style={{borderBottom:'1px solid #0078D3'}}>Ho</span>me</div>
       <div className="AboutUs cursor-pointer text-[#020E17] text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">About us</div>
       <div className="Pricing cursor-pointer text-[#020E17] text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">Pricing</div>
       <div className="Faq cursor-pointer text-[#020E17] text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">FAQ</div>
       <div className="ContactUs cursor-pointer text-[#020E17] text-[16px] font-normal font-['Jost'] cursor-pointer uppercase">Contact us</div>
     </div>
     <div className="Button lg:w-[260px] lg:h-[50px] lg:left-[1056px] lg:top-[15px] md:w-[140px] md:h-10 w-[260px] h-[50px] left-[68px] top-[345px] md:left-[824px] md:top-[22px] cursor-pointer absolute">
      <div className="Rectangle1440 lg:w-[260px] lg:h-[50px] lg:left-0 lg:top-0 md:w-[140px] md:h-12 md:left-0 md:top-0 w-[260px] h-[50px] left-0 top-0 absolute bg-[#0078D3] rounded-md shadow" />
      <div className="GetStarted lg:left-[68px] lg:top-[13px] md:left-[15px] md:top-[4px] left-[75px] top-[13px] absolute text-white lg:text-[18px] md:text-[16px] text-[16px] font-bold font-['Inter'] uppercase leading-[25.20px]">Get started</div>
    </div>
   </div>
   </div>,
  
  
  
  <div>
  <div className="Header1Mobile w-[390px] h-[61px] mb-10 relative bg-[#404046] sm:hidden">
    <div className="Logo cursor-pointer w-[34px] h-[34px] left-[20px] top-[10px] absolute">
      <FitbitIcon sx={{fontSize: 40, color:'white'}}/>
    </div>
    <div className="w-10 h-10 left-[285px] top-[10px] cursor-pointer absolute">
      <MenuIcon  sx={{fontSize: 40, color:'white'}}/>
    </div>
    <div className="BiFacebook cursor-pointer w-8 h-8 left-[333px] top-[10px] absolute">
     <SearchIcon sx={{fontSize: 40, color:'white'}}/>
  </div>
  </div>
  <div className="Header1Mobile lg:w-[1440px] lg:h-[61px] md:w-[1024px] md:h-[60px] w-[390px] h-[360px] relative bg-[#404046]">
  <div className="Rectangle1513 lg:w-[1400px] lg:h-px lg:left-[20px] lg:top-[80px] md:w-[984px] md:h-px md:left-[20px] md:top-[80px] w-[370px] h-px left-[10px] top-[80px] absolute bg-[white] bg-opacity-20" />
  <div className="Logo cursor-pointer lg:w-10 lg:h-10 md:w-10 md:h-10 w-[34px] h-[34px] lg:left-[60px] lg:top-[10px] md:left-[40px] md:top-[10px] left-[20px] top-[22px]  absolute">
  <FitbitIcon sx={{fontSize: 40, color:'white'}}/>
  </div>
  <div className="w-10 h-10 left-[285px] top-[23px] cursor-pointer absolute sm:hidden">
      <CloseIcon  sx={{fontSize: 40, color:'white'}}/>
  </div>
  <div className="BiFacebook cursor-pointer w-8 h-8 lg:left-[1348px] lg:top-[14px] md:left-[952px] md:top-[10px] left-[333px] top-[23px] absolute">
     <SearchIcon sx={{fontSize: 40, color:'white'}}/>
  </div>
  <div style={{height:"fit-content", width:"fit-content"}} className="HeaderMenu lg:w-[706px] lg:left-[367px] lg:top-[19px] md:w-[466px] md:left-[279px] md:top-[19px] w-[103px] h-[230px] left-[144px] top-[111px] lg:flex-row md:flex-row  absolute justify-center lg:items-start md:items-start items-center lg:gap-[100px] md:gap-10 gap-[25px] inline-flex">
    <div className="Home cursor-pointer text-[white] text-[16px] font-bold cursor-pointer uppercase" style={{fontFamily:'Voces'}}>Home</div>
    <div className="AboutUs cursor-pointer text-white text-[16px] font-normal cursor-pointer uppercase" style={{fontFamily:'Voces'}}>About us</div>
    <div className="Pricing cursor-pointer text-white text-[16px] font-normal cursor-pointer uppercase" style={{fontFamily:'Voces'}}>Pricing</div>
    <div className="Faq cursor-pointer text-white text-[16px] font-normal cursor-pointer uppercase" style={{fontFamily:'Voces'}}>FAQ</div>
    <div className="ContactUs cursor-pointer text-white text-[16px] font-normal cursor-pointer uppercase" style={{fontFamily:'Voces'}}>Contact us</div>
  </div>
  </div>
  </div> ]


  return (<div style={{display:"flex", justifyContent:"center", alignItems:"center"}} ref={connect}>
        
        {styles[selected]}

      </div>
  );
};

Banner_1.craft = {
    displayName: "Header 1",
    props: {
       selected: 0 // here is the default value of the selected style
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewBannerSettings,
      },
  };


