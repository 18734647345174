import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';

import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';

class Component extends SampleBase {
    render() {
        return (<div className='control-pane'>
        <div className='control-section'>
          <div className="content-wrapper sample-mask">
            <div className="control-label">{this.props.label}
            </div>
            <MaskedTextBoxComponent 
            {...this.props.allEvents}
            mask={this.props.mask}></MaskedTextBoxComponent>
          </div>
        </div>
      </div>
      );
    } 
}

export default Component