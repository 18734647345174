import { gql } from "apollo-boost";

const updateThemeStatusMutation = gql`
  mutation(
    $_id: ID, 
  ) {
    updateThemeStatus(
    _id: $_id,
    ) {
    _id
    name,
    org,
    default
    }
  }
`;

export default updateThemeStatusMutation;
