import { gql } from "apollo-boost";

const addCollectionMutation = gql`
  mutation(
    $org: ID,
    $group: ID,
    $name: String,
    $type: String,
    
  ) {
    addCollection(
    org:$org,
    type:$type
    group:$group,
    name:$name,
    ) {
    _id
    type
    org,
    group,
    name,
    }
  }
`;

export default addCollectionMutation;
