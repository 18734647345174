import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Testimonials13 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section>
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="[column-fill:_balance] sm:columns-2 sm:gap-6 lg:columns-3 lg:gap-8">
      <div class="mb-8 sm:break-inside-avoid">
        <blockquote class="rounded-xl bg-gray-50 p-6 shadow">
          <p class="leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="753417" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
            minima dicta amet, molestiae aliquam incidunt suscipit recusandae
            labore ratione doloremque, architecto et illo minus quo tenetur
            ducimus, voluptatibus repellendus fuga aperiam vel ab! Ipsam
            corrupti blanditiis dolorum! Officia assumenda rem nam, eveniet enim
            ad inventore laudantium est illum voluptatem quis." /></Element>} </p>
        </blockquote>

        <div class="mt-4 flex items-center gap-4">
          <Element canvas style={{ width: "100%" }} id={"div562912"} is="div">
          <ImageC alt="Woman" src="https://images.unsplash.com/photo-1603366445787-09714680cbf1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=944&q=80" class="h-12 w-12 rounded-full object-cover"  />
        </Element>

          <div class="text-sm">
            <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="853367" is="div"><Text text="Gladis Lennon" /></Element>} </p>
            <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="852236" is="div"><Text text="Head of SEO" /></Element>} </p>
          </div>
        </div>
      </div>

      <div class="mb-8 sm:break-inside-avoid">
        <blockquote class="rounded-xl bg-gray-50 p-6 shadow">
          <p class="leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="624212" is="div"><Text text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore vel
            quo deserunt quos expedita minima incidunt sed tempora, a architecto
            consectetur reprehenderit, in repellat voluptatum." /></Element>} </p>
        </blockquote>

        <div class="mt-4 flex items-center gap-4">
          <Element canvas style={{ width: "100%" }} id={"div473518"} is="div">
          <ImageC alt="Woman" src="https://images.unsplash.com/photo-1603366445787-09714680cbf1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=944&q=80" class="h-12 w-12 rounded-full object-cover"  />
        </Element>

          <div class="text-sm">
            <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="732521" is="div"><Text text="Gladis Lennon" /></Element>} </p>
            <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="900549" is="div"><Text text="Head of SEO" /></Element>} </p>
          </div>
        </div>
      </div>

      <div class="mb-8 sm:break-inside-avoid">
        <blockquote class="rounded-xl bg-gray-50 p-6 shadow">
          <p class="leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="601459" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio
            beatae incidunt perferendis soluta facilis voluptas dicta
            repudiandae quasi asperiores libero, exercitationem molestiae autem
            sapiente dolore nulla non consequatur. Eaque, dolores." /></Element>} </p>
        </blockquote>

        <div class="mt-4 flex items-center gap-4">
          <Element canvas style={{ width: "100%" }} id={"div645870"} is="div">
          <ImageC alt="Woman" src="https://images.unsplash.com/photo-1603366445787-09714680cbf1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=944&q=80" class="h-12 w-12 rounded-full object-cover"  />
        </Element>

          <div class="text-sm">
            <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="324650" is="div"><Text text="Gladis Lennon" /></Element>} </p>
            <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="766836" is="div"><Text text="Head of SEO" /></Element>} </p>
          </div>
        </div>
      </div>

      <div class="mb-8 sm:break-inside-avoid">
        <blockquote class="rounded-xl bg-gray-50 p-6 shadow">
          <p class="leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="256434" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
            doloribus eius aut unde, dolores accusantium!" /></Element>} </p>
        </blockquote>

        <div class="mt-4 flex items-center gap-4">
          <Element canvas style={{ width: "100%" }} id={"div439475"} is="div">
          <ImageC alt="Woman" src="https://images.unsplash.com/photo-1603366445787-09714680cbf1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=944&q=80" class="h-12 w-12 rounded-full object-cover"  />
        </Element>

          <div class="text-sm">
            <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="940957" is="div"><Text text="Gladis Lennon" /></Element>} </p>
            <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="414463" is="div"><Text text="Head of SEO" /></Element>} </p>
          </div>
        </div>
      </div>

      <div class="mb-8 sm:break-inside-avoid">
        <blockquote class="rounded-xl bg-gray-50 p-6 shadow">
          <p class="leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="242457" is="div"><Text text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi a
            voluptatum quidem nulla quisquam natus velit provident earum esse,
            odio numquam labore recusandae similique sunt." /></Element>} </p>
        </blockquote>

        <div class="mt-4 flex items-center gap-4">
          <Element canvas style={{ width: "100%" }} id={"div487348"} is="div">
          <ImageC alt="Woman" src="https://images.unsplash.com/photo-1603366445787-09714680cbf1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=944&q=80" class="h-12 w-12 rounded-full object-cover"  />
        </Element>

          <div class="text-sm">
            <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="539685" is="div"><Text text="Gladis Lennon" /></Element>} </p>
            <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="571123" is="div"><Text text="Head of SEO" /></Element>} </p>
          </div>
        </div>
      </div>

      <div class="mb-8 sm:break-inside-avoid">
        <blockquote class="rounded-xl bg-gray-50 p-6 shadow">
          <p class="leading-relaxed text-gray-700"> {<Element canvas style={{display:"inline-block"}} id="408101" is="div"><Text text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius ut
            necessitatibus, repudiandae qui dolor minima." /></Element>} </p>
        </blockquote>

        <div class="mt-4 flex items-center gap-4">
          <Element canvas style={{ width: "100%" }} id={"div903149"} is="div">
          <ImageC alt="Woman" src="https://images.unsplash.com/photo-1603366445787-09714680cbf1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=944&q=80" class="h-12 w-12 rounded-full object-cover"  />
        </Element>

          <div class="text-sm">
            <p class="font-medium"> {<Element canvas style={{display:"inline-block"}} id="269932" is="div"><Text text="Gladis Lennon" /></Element>} </p>
            <p class="mt-1"> {<Element canvas style={{display:"inline-block"}} id="731182" is="div"><Text text="Head of SEO" /></Element>} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Testimonials13.craft = {
  displayName: "Testimonials 13"
};