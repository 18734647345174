import * as React from 'react';
import './index.scss';

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { SmithchartComponent, SmithchartSeriesCollectionDirective, SmithchartSeriesDirective, SmithchartLegend, TooltipRender, Inject } from '@syncfusion/ej2-react-charts';
import { PropertyPane } from './property-pane';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
  smithchartInstance;
  dropElement;
  droplist = [
      { text: 'Impedance', value: 'Impedance' },
      { text: 'Admittance', value: 'Admittance' },
  ];
  typeChange() {
      let element = this.dropElement.value;
      this.smithchartInstance.renderType = element;
      this.smithchartInstance.refresh();
  }
  load(args) {
      args.smithchart.title.text = 'Transmission details';
      args.smithchart.title.visible = true;
      let theme = location.hash.split('/')[1];
      theme = theme ? theme : 'Material';
      args.smithchart.theme = (theme.charAt(0).toUpperCase() + theme.slice(1)).
          replace(/-dark/i, "Dark");
  }
  render() {
      return (<div className='control-pane'>
              {/* <style>
                  {SAMPLE_CSS}
              </style> */}
              <div className='col-md-9 control-section'>
                  <SmithchartComponent {...this.props.allEvents} load={this.load.bind(this)} id='container' ref={gauge => this.smithchartInstance = gauge} legendSettings={{ visible: true, shape: 'Circle' }}>
                      <Inject services={[SmithchartLegend, TooltipRender]}/>
                      <SmithchartSeriesCollectionDirective>
                          <SmithchartSeriesDirective points={[
              { resistance: 10, reactance: 25 }, { resistance: 8, reactance: 6 },
              { resistance: 6, reactance: 4.5 }, { resistance: 4.5, reactance: 2 },
              { resistance: 3.5, reactance: 1.6 }, { resistance: 2.5, reactance: 1.3 },
              { resistance: 2, reactance: 1.2 }, { resistance: 1.5, reactance: 1 },
              { resistance: 1, reactance: 0.8 }, { resistance: 0.5, reactance: 0.4 },
              { resistance: 0.3, reactance: 0.2 }, { resistance: 0, reactance: 0.15 },
          ]} name='Transmission1' enableAnimation={true} tooltip={{ visible: true }} marker={{ shape: 'Circle', visible: true, border: { width: 2 } }}>
                          </SmithchartSeriesDirective>
                          <SmithchartSeriesDirective points={[
              { resistance: 20, reactance: -50 }, { resistance: 10, reactance: -10 },
              { resistance: 9, reactance: -4.5 }, { resistance: 8, reactance: -3.5 },
              { resistance: 7, reactance: -2.5 }, { resistance: 6, reactance: -1.5 },
              { resistance: 5, reactance: -1 }, { resistance: 4.5, reactance: -0.5 },
              { resistance: 3.5, reactance: 0 }, { resistance: 2.5, reactance: 0.4 },
              { resistance: 2, reactance: 0.5 }, { resistance: 1.5, reactance: 0.5 },
              { resistance: 1, reactance: 0.4 }, { resistance: 0.5, reactance: 0.2 },
              { resistance: 0.3, reactance: 0.1 }, { resistance: 0, reactance: 0.05 },
          ]} name='Transmission2' enableAnimation={true} tooltip={{ visible: true }} marker={{ shape: 'Circle', visible: true, border: { width: 2 } }}>
                          </SmithchartSeriesDirective>
                      </SmithchartSeriesCollectionDirective>
                  </SmithchartComponent>
              </div>
              {/* Property Panel */}
              <div className='col-md-3 property-section'>
                  <PropertyPane title='Properties'>
                      <table id='property' title='Properties' className='property-panel-table' style={{ width: '100%' }}>
                          <tr style={{ height: '50px' }}>
                              <td style={{ width: '60%' }}>
                                  <div>Render Type</div>
                              </td>
                              <td style={{ width: '40%' }}>
                                  <div>
                                      <DropDownListComponent width="120px" index={0} ref={d => this.dropElement = d} change={this.typeChange.bind(this)} dataSource={this.droplist} fields={{ text: 'text', value: 'value' }}/>
                                  </div>
                              </td>
                          </tr>
                      </table>
                  </PropertyPane>
              </div>
          </div>
      ); 
    } 
}

export default Component