import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Portfolio3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="951092" is="div"><Text text="Portfolio" /></Element>} </h1>

        <p class="mt-4 text-center text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="466585" is="div"><Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quam voluptatibus" /></Element>} </p>


        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            <div class="overflow-hidden bg-cover rounded-lg h-96 group">
                <div class="flex flex-col justify-center w-full h-full px-8 py-4 transition-opacity duration-700 backdrop-blur-sm bg-black group-hover:opacity-90">
                    <h2 class="mt-4 text-2xl font-semibold text-white capitalize"> {<Element canvas style={{display:"inline-block"}} id="178496" is="div"><Text text="Best website collections" /></Element>} </h2>
                    <p class="mt-2 text-lg tracking-wider text-blue-400 uppercase "> {<Element canvas style={{display:"inline-block"}} id="914732" is="div"><Text text="Website" /></Element>} </p>
                </div>
            </div>

            <div class="overflow-hidden bg-cover rounded-lg h-96 group">
                <div class="flex flex-col justify-center w-full h-full px-8 py-4 transition-opacity duration-700 backdrop-blur-sm bg-black group-hover:opacity-90">
                    <h2 class="mt-4 text-2xl font-semibold text-white capitalize"> {<Element canvas style={{display:"inline-block"}} id="780716" is="div"><Text text="Block of Ui kit collections" /></Element>} </h2>
                    <p class="mt-2 text-lg tracking-wider text-blue-400 uppercase "> {<Element canvas style={{display:"inline-block"}} id="338346" is="div"><Text text="Ui kit" /></Element>} </p>
                </div>
            </div>

            <div class="overflow-hidden bg-cover rounded-lg h-96 group">
                <div class="flex flex-col justify-center w-full h-full px-8 py-4 transition-opacity duration-700 backdrop-blur-sm bg-black group-hover:opacity-90">
                    <h2 class="mt-4 text-2xl font-semibold text-white capitalize"> {<Element canvas style={{display:"inline-block"}} id="305451" is="div"><Text text="Ton’s of mobile mockup" /></Element>} </h2>
                    <p class="mt-2 text-lg tracking-wider text-blue-400 uppercase "> {<Element canvas style={{display:"inline-block"}} id="155757" is="div"><Text text="Mockups" /></Element>} </p>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Portfolio3.craft = {
  displayName: "Portfolio 3"
};