import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <nav x-data="{ isOpen: false }" class="container p-6 mx-auto lg:flex lg:justify-between lg:items-center">
        <div class="flex items-center justify-between">
            <div>
                <Element canvas style={{ width: "100%" }} id={"div788495"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="131864" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
            </div>

            <div class="flex lg:hidden">
                <Element canvas style={{ width: "100%" }} id={"div381537"} is="div">
            <Button classn={"text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"}>
              
                    <svg x-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                    </svg>
                
                    <svg x-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                
            </Button>
          </Element>
            </div>
        </div>

        <div
        // here is missing class
        class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white shadow-md lg:shadow-none dark:bg-gray-900 lg:mt-0 lg:p-0 lg:top-0 lg:bg-transparent lg:dark:bg-transparent lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center">
            <div class="flex flex-col space-y-4 lg:mt-0 lg:flex-row lg:space-y-0">
                <Element canvas style={{ width: "100%" }} id={"div621659"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="641448" is="div"><Text text="Home" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div488488"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="840850" is="div"><Text text="Components" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div395020"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="138932" is="div"><Text text="Pricing" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div427191"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="639565" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>
                <Element canvas style={{ width: "100%" }} id={"div383544"} is="div">
            <Button href={"#"} classn={"text-gray-700 lg:mx-6 dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"}>
               {<Element canvas style={{display:"inline-block"}} id="812439" is="div"><Text text="FAQ" /></Element>} 
            </Button>
          </Element>
            </div>

            <Element canvas style={{ width: "100%" }} id={"div855132"} is="div">
            <Button href={"#"} classn={"block h-10 px-5 py-2 mt-4 text-sm text-center text-gray-700 capitalize transition-colors duration-300 transform border rounded-md dark:hover:bg-gray-700 dark:text-white lg:mt-0 hover:bg-gray-100 lg:w-auto"}>
               {<Element canvas style={{display:"inline-block"}} id="655926" is="div"><Text text="Contact Us" /></Element>} 
            </Button>
          </Element>
        </div>
    </nav>

    <div class="container px-6 py-16 mx-auto text-center">
        <div class="max-w-lg mx-auto">
            <h1 class="text-3xl font-bold text-gray-800 dark:text-white md:text-4xl"> {<Element canvas style={{display:"inline-block"}} id="149057" is="div"><Text text="Create beautiful website layout with Meraki UI." /></Element>} </h1>

            <p class="mt-6 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="114606" is="div"><Text text="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Libero similique obcaecati illum mollitia." /></Element>} </p>

            <div class="w-full max-w-sm mx-auto mt-6 bg-transparent border rounded-md dark:border-gray-700 focus-within:border-blue-400 focus-within:ring focus-within:ring-blue-300 dark:focus-within:border-blue-300 focus-within:ring-opacity-40">
                <form class="flex flex-col md:flex-row">
                    <input type="email" placeholder="Enter your email address" class="flex-1 h-10 px-4 py-2 m-1 text-gray-700 placeholder-gray-400 bg-transparent border-none appearance-none dark:text-gray-200 focus:outline-none focus:placeholder-transparent focus:ring-0" />

                    <Element canvas style={{ width: "100%" }} id={"div949142"} is="div">
            <Button classn={"h-10 px-4 py-2 m-1 text-white transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"}>
               {<Element canvas style={{display:"inline-block"}} id="734667" is="div"><Text text="Join Us" /></Element>} 
            </Button>
          </Element>
                </form>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Hero4.craft = {
  displayName: "Hero 4"
};