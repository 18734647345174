import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Section1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-gray-900 text-white">
  <div class="max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="max-w-xl">
      <h2 class="text-3xl font-bold sm:text-4xl"> {<Element canvas style={{display:"inline-block"}} id="217340" is="div"><Text text="What makes us special" /></Element>} </h2>

      <p class="mt-4 text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="853594" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
        dolores iure fugit totam iste obcaecati. Consequatur ipsa quod ipsum
        sequi culpa delectus, cumque id tenetur quibusdam, quos fuga minima." /></Element>} </p>
    </div>

    <div class="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
      <div class="flex items-start">
        <span class="flex-shrink-0 rounded-lg bg-gray-800 p-4">
          <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
          </svg>
        </span>

        <div class="ml-4">
          <h2 class="text-lg font-bold"> {<Element canvas style={{display:"inline-block"}} id="672236" is="div"><Text text="Lorem, ipsum dolor." /></Element>} </h2>

          <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="481423" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?" /></Element>} </p>
        </div>
      </div>

      <div class="flex items-start">
        <span class="flex-shrink-0 rounded-lg bg-gray-800 p-4">
          <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
          </svg>
        </span>

        <div class="ml-4">
          <h2 class="text-lg font-bold"> {<Element canvas style={{display:"inline-block"}} id="854247" is="div"><Text text="Lorem, ipsum dolor." /></Element>} </h2>

          <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="553206" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?" /></Element>} </p>
        </div>
      </div>

      <div class="flex items-start">
        <span class="flex-shrink-0 rounded-lg bg-gray-800 p-4">
          <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
          </svg>
        </span>

        <div class="ml-4">
          <h2 class="text-lg font-bold"> {<Element canvas style={{display:"inline-block"}} id="550342" is="div"><Text text="Lorem, ipsum dolor." /></Element>} </h2>

          <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="529613" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?" /></Element>} </p>
        </div>
      </div>

      <div class="flex items-start">
        <span class="flex-shrink-0 rounded-lg bg-gray-800 p-4">
          <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
          </svg>
        </span>

        <div class="ml-4">
          <h2 class="text-lg font-bold"> {<Element canvas style={{display:"inline-block"}} id="512314" is="div"><Text text="Lorem, ipsum dolor." /></Element>} </h2>

          <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="213604" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?" /></Element>} </p>
        </div>
      </div>

      <div class="flex items-start">
        <span class="flex-shrink-0 rounded-lg bg-gray-800 p-4">
          <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
          </svg>
        </span>

        <div class="ml-4">
          <h2 class="text-lg font-bold"> {<Element canvas style={{display:"inline-block"}} id="856154" is="div"><Text text="Lorem, ipsum dolor." /></Element>} </h2>

          <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="335955" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?" /></Element>} </p>
        </div>
      </div>

      <div class="flex items-start">
        <span class="flex-shrink-0 rounded-lg bg-gray-800 p-4">
          <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
          </svg>
        </span>

        <div class="ml-4">
          <h2 class="text-lg font-bold"> {<Element canvas style={{display:"inline-block"}} id="799587" is="div"><Text text="Lorem, ipsum dolor." /></Element>} </h2>

          <p class="mt-1 text-sm text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="719363" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?" /></Element>} </p>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Section1.craft = {
  displayName: "Section 1"
};