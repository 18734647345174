import * as React from 'react';
import './index.scss';

import { SampleBase } from './sample-base';

import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { getComponent } from '@syncfusion/ej2-base';

class Component extends SampleBase {
  signature;
  constructor(props) {
      super(props);
  }
  clrBtnCreated() {
      document.getElementById('signclear').addEventListener('click', this.clrBtnClick);
  }
  saveBtnCreated() {
      document.getElementById('signsave').addEventListener('click', this.saveBtnClick);
  }
  saveBtnClick() {
      let signature = getComponent(document.getElementById('signature'), 'signature');
      signature.save();
  }
  clrBtnClick() {
      let signature = getComponent(document.getElementById('signature'), 'signature');
      let saveBtn = getComponent(document.getElementById("signsave"), 'btn');
      let clrBtn = getComponent(document.getElementById("signclear"), 'btn');
      signature.clear();
      if (signature.isEmpty()) {
          saveBtn.disabled = true;
          clrBtn.disabled = true;
      }
  }
  change() {
      let signature = getComponent(document.getElementById('signature'), 'signature');
      let saveBtn = getComponent(document.getElementById("signsave"), 'btn');
      let clrBtn = getComponent(document.getElementById("signclear"), 'btn');
      if (!signature.isEmpty()) {
          saveBtn.disabled = false;
          clrBtn.disabled = false;
      }
  }
  render() {
      return (<div className='control-pane'>
      <div className="control-section">
        <div id="signature-control">
          <div className='e-sign-heading'>
            <span id="signdescription">Sign below</span>
            <span className="e-btn-options">
              <ButtonComponent id="signsave" cssClass='e-primary e-sign-save' created={this.saveBtnCreated.bind(this)} disabled={true}>SAVE</ButtonComponent>
              <ButtonComponent id="signclear" cssClass='e-primary e-sign-clear' created={this.clrBtnCreated.bind(this)} disabled={true}>CLEAR</ButtonComponent>
            </span>
          </div>
          <SignatureComponent {...this.props.allEvents} id="signature" change={this.change.bind(this)}></SignatureComponent>
        </div>
      </div>
    </div>
      );
    } 
}

export default Component