import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Content4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container flex flex-wrap px-5 py-24 mx-auto items-center">
    <div class="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-200">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="319032" is="div"><Text text="Pitchfork Kickstarter Taxidermy" /></Element>} </h1>
      <p class="leading-relaxed text-base"> {<Element canvas style={{display:"inline-block"}} id="343309" is="div"><Text text="Locavore cardigan small batch roof party blue bottle blog meggings sartorial jean shorts kickstarter migas sriracha church-key synth succulents. Actually taiyaki neutra, distillery gastropub pok pok ugh." /></Element>} </p>
      <a class="text-indigo-500 inline-flex items-center mt-4"> {<Element canvas style={{display:"inline-block"}} id="291513" is="div"><Text text="Learn More" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </a>
    </div>
    <div class="flex flex-col md:w-1/2 md:pl-12">
      <h2 class="title-font font-semibold text-gray-800 tracking-wider text-sm mb-3"> {<Element canvas style={{display:"inline-block"}} id="822380" is="div"><Text text="CATEGORIES" /></Element>} </h2>
      <nav class="flex flex-wrap list-none -mb-1">
        <li class="lg:w-1/3 mb-1 w-1/2">
          <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="794147" is="div"><Text text="First Link" /></Element>} </a>
        </li>
        <li class="lg:w-1/3 mb-1 w-1/2">
          <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="233798" is="div"><Text text="Second Link" /></Element>} </a>
        </li>
        <li class="lg:w-1/3 mb-1 w-1/2">
          <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="371127" is="div"><Text text="Third Link" /></Element>} </a>
        </li>
        <li class="lg:w-1/3 mb-1 w-1/2">
          <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="946657" is="div"><Text text="Fourth Link" /></Element>} </a>
        </li>
        <li class="lg:w-1/3 mb-1 w-1/2">
          <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="983738" is="div"><Text text="Fifth Link" /></Element>} </a>
        </li>
        <li class="lg:w-1/3 mb-1 w-1/2">
          <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="554656" is="div"><Text text="Sixth Link" /></Element>} </a>
        </li>
        <li class="lg:w-1/3 mb-1 w-1/2">
          <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="845318" is="div"><Text text="Seventh Link" /></Element>} </a>
        </li>
        <li class="lg:w-1/3 mb-1 w-1/2">
          <a class="text-gray-600 hover:text-gray-800"> {<Element canvas style={{display:"inline-block"}} id="274175" is="div"><Text text="Eighth Link" /></Element>} </a>
        </li>
      </nav>
    </div>
  </div>
    </section>

      </div>;
};
Content4.craft = {
  displayName: "Content 4"
};