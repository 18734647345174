"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.basicData = exports.complexData = exports.hardwareData = exports.expenseData = exports.employeeData = void 0;
/**
 * QueryBuilder datasource
 */
exports.employeeData = [{
        'EmployeeID': 1,
        'LastName': 'Davolio',
        'FirstName': 'Nancy',
        'Title': 'Sales Representative',
        'TitleOfCourtesy': 'Ms.',
        'BirthDate': new Date(-664743600000),
        'HireDate': new Date(704692800000),
        'Address': '507 - 20th Ave. E.\r\nApt. 2A',
        'City': 'Seattle',
        'Region': 'WA',
        'PostalCode': '98122',
        'Country': 'USA',
        'HomePhone': '(206) 555-9857',
        'Extension': '5467',
        'Photo': { 'Length': 21626 },
        'ReportsTo': 2,
        'PhotoPath': 'http://accweb/emmployees/davolio.bmp'
    },
    {
        'EmployeeID': 2,
        'LastName': 'Fuller',
        'FirstName': 'Andrew',
        'Title': 'Vice President',
        'TitleOfCourtesy': 'Dr.',
        'BirthDate': new Date(-563828400000),
        'HireDate': new Date(713764800000),
        'Address': '908 W. Capital Way',
        'City': 'Tacoma',
        'Region': 'WA',
        'PostalCode': '98401',
        'Country': 'USA',
        'HomePhone': '(206) 555-9482',
        'Extension': '3457',
        'Photo': { 'Length': 21626 },
        'ReportsTo': 0,
        'PhotoPath': 'http://accweb/emmployees/fuller.bmp'
    },
    {
        'EmployeeID': 3,
        'LastName': 'Leverling',
        'FirstName': 'Janet',
        'Title': 'Sales Representative',
        'TitleOfCourtesy': 'Ms.',
        'BirthDate': new Date(-200088000000),
        'HireDate': new Date(702104400000),
        'Address': '722 Moss Bay Blvd.',
        'City': 'Kirkland',
        'Region': 'WA',
        'PostalCode': '98033',
        'Country': 'USA',
        'HomePhone': '(206) 555-3412',
        'Extension': '3355',
        'Photo': { 'Length': 21722 },
        'ReportsTo': 2,
        'PhotoPath': 'http://accweb/emmployees/leverling.bmp'
    },
    {
        'EmployeeID': 4,
        'LastName': 'Peacock',
        'FirstName': 'Margaret',
        'Title': 'Sales Representative',
        'TitleOfCourtesy': 'Mrs.',
        'BirthDate': new Date(-1018814400000),
        'HireDate': new Date(736401600000),
        'Address': '4110 Old Redmond Rd.',
        'City': 'Redmond',
        'Region': 'WA',
        'PostalCode': '98052',
        'Country': 'USA',
        'HomePhone': '(206) 555-8122',
        'Extension': '5176',
        'Photo': { 'Length': 21626 },
        'ReportsTo': 2,
        'PhotoPath': 'http://accweb/emmployees/peacock.bmp'
    },
    {
        'EmployeeID': 5,
        'LastName': 'Buchanan',
        'FirstName': 'Steven',
        'Title': 'Sales Manager',
        'TitleOfCourtesy': 'Mr.',
        'BirthDate': new Date(-468010800000),
        'HireDate': new Date(750830400000),
        'Address': '14 Garrett Hill',
        'City': 'London',
        'Region': null,
        'PostalCode': 'SW1 8JR',
        'Country': 'UK',
        'HomePhone': '(71) 555-4848',
        'Extension': '3453',
        'Photo': { 'Length': 21626 },
        'ReportsTo': 2,
        'PhotoPath': 'http://accweb/emmployees/buchanan.bmp'
    },
    {
        'EmployeeID': 6,
        'LastName': 'Suyama',
        'FirstName': 'Michael',
        'Title': 'Sales Representative',
        'TitleOfCourtesy': 'Mr.',
        'BirthDate': new Date(-205185600000),
        'HireDate': new Date(750830400000),
        'Address': 'Coventry House\r\nMiner Rd.',
        'City': 'London',
        'Region': null,
        'PostalCode': 'EC2 7JR',
        'Country': 'UK',
        'HomePhone': '(71) 555-7773',
        'Extension': '428',
        'Photo': { 'Length': 21626 },
        'ReportsTo': 5,
        'PhotoPath': 'http://accweb/emmployees/davolio.bmp'
    },
    {
        'EmployeeID': 7,
        'LastName': 'King',
        'FirstName': 'Robert',
        'Title': 'Sales Representative',
        'TitleOfCourtesy': 'Mr.',
        'BirthDate': new Date(-302731200000),
        'HireDate': new Date(757486800000),
        'Address': 'Edgeham Hollow\r\nWinchester Way',
        'City': 'London',
        'Region': null,
        'PostalCode': 'RG1 9SP',
        'Country': 'UK',
        'HomePhone': '(71) 555-5598',
        'Extension': '465',
        'Photo': { 'Length': 21626 },
        'ReportsTo': 5,
        'PhotoPath': 'http://accweb/emmployees/davolio.bmp'
    },
    {
        'EmployeeID': 8,
        'LastName': 'Callahan',
        'FirstName': 'Laura',
        'Title': 'Inside Sales Coordinator',
        'TitleOfCourtesy': 'Ms.',
        'BirthDate': new Date(-377982000000),
        'HireDate': new Date(762843600000),
        'Address': '4726 - 11th Ave. N.E.',
        'City': 'Seattle',
        'Region': 'WA',
        'PostalCode': '98105',
        'Country': 'USA',
        'HomePhone': '(206) 555-1189',
        'Extension': '2344',
        'Photo': { 'Length': 21626 },
        'ReportsTo': 2,
        'PhotoPath': 'http://accweb/emmployees/davolio.bmp'
    },
    {
        'EmployeeID': 9,
        'LastName': 'Dodsworth',
        'FirstName': 'Anne',
        'Title': 'Sales Representative',
        'TitleOfCourtesy': 'Ms.',
        'BirthDate': new Date(-123966000000),
        'HireDate': new Date(784875600000),
        'Address': '7 Houndstooth Rd.',
        'City': 'London',
        'Region': null,
        'PostalCode': 'WG2 7LT',
        'Country': 'UK',
        'HomePhone': '(71) 555-4444',
        'Extension': '452',
        'Photo': { 'Length': 21626 },
        'ReportsTo': 5,
        'PhotoPath': 'http://accweb/emmployees/davolio.bmp'
    }];
exports.expenseData = [{
        'UniqueId': 'T100001',
        'DateTime': 1496288520000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/01/2017 09:12 AM'
    }, {
        'UniqueId': 'T100002',
        'DateTime': 1496308620000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/01/2017 02:47 PM'
    }, {
        'UniqueId': 'T100003',
        'DateTime': 1496330580000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/01/2017 08:53 PM'
    }, {
        'UniqueId': 'T100004',
        'DateTime': 1496294040000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/01/2017 10:44 AM'
    }, {
        'UniqueId': 'T100005',
        'DateTime': 1496310900000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/01/2017 03:25 PM'
    }, {
        'UniqueId': 'T100006',
        'DateTime': 1496310480000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '155',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/01/2017 03:18 PM'
    }, {
        'UniqueId': 'T100007',
        'DateTime': 1496312340000,
        'Category': 'Mortgage / Rent',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Monthly rent',
        'Amount': '1000',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/01/2017 03:49 PM'
    }, {
        'UniqueId': 'T100008',
        'DateTime': 1496311500000,
        'Category': 'Salary',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Monthly Net Salary',
        'Amount': '6000',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/01/2017 03:35 PM'
    }, {
        'UniqueId': 'T100009',
        'DateTime': 1496376540000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/02/2017 09:39 AM'
    }, {
        'UniqueId': 'T100010',
        'DateTime': 1496394900000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/02/2017 02:45 PM'
    }, {
        'UniqueId': 'T100011',
        'DateTime': 1496414460000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/02/2017 08:11 PM'
    }, {
        'UniqueId': 'T100012',
        'DateTime': 1496378460000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/02/2017 10:11 AM'
    }, {
        'UniqueId': 'T100013',
        'DateTime': 1496397300000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/02/2017 03:25 PM'
    }, {
        'UniqueId': 'T100014',
        'DateTime': 1496411340000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '150',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/02/2017 07:19 PM'
    }, {
        'UniqueId': 'T100015',
        'DateTime': 1496411160000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other household products',
        'Amount': '25',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/02/2017 07:16 PM'
    }, {
        'UniqueId': 'T100016',
        'DateTime': 1496461320000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/03/2017 09:12 AM'
    }, {
        'UniqueId': 'T100017',
        'DateTime': 1496480700000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/03/2017 02:35 PM'
    }, {
        'UniqueId': 'T100018',
        'DateTime': 1496502480000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/03/2017 08:38 PM'
    }, {
        'UniqueId': 'T100019',
        'DateTime': 1496465520000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/03/2017 10:22 AM'
    }, {
        'UniqueId': 'T100020',
        'DateTime': 1496483460000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/03/2017 03:21 PM'
    }, {
        'UniqueId': 'T100021',
        'DateTime': 1496481300000,
        'Category': 'Utilities',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '350',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/03/2017 02:45 PM'
    }, {
        'UniqueId': 'T100022',
        'DateTime': 1496480220000,
        'Category': 'Bills',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cellular phone service',
        'Amount': '220',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/03/2017 02:27 PM'
    }, {
        'UniqueId': 'T100023',
        'DateTime': 1496549160000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/04/2017 09:36 AM'
    }, {
        'UniqueId': 'T100024',
        'DateTime': 1496567580000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/04/2017 02:43 PM'
    }, {
        'UniqueId': 'T100025',
        'DateTime': 1496588700000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/04/2017 08:35 PM'
    }, {
        'UniqueId': 'T100026',
        'DateTime': 1496552100000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/04/2017 10:25 AM'
    }, {
        'UniqueId': 'T100027',
        'DateTime': 1496571900000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/04/2017 03:55 PM'
    }, {
        'UniqueId': 'T100028',
        'DateTime': 1496551920000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '160',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/04/2017 10:22 AM'
    }, {
        'UniqueId': 'T100029',
        'DateTime': 1496552700000,
        'Category': 'Personal Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Short visit to private Doctor',
        'Amount': '20',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/04/2017 10:35 AM'
    }, {
        'UniqueId': 'T100030',
        'DateTime': 1496554020000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from returns',
        'Amount': '120',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/04/2017 10:57 AM'
    }, {
        'UniqueId': 'T100031',
        'DateTime': 1496634120000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/05/2017 09:12 AM'
    }, {
        'UniqueId': 'T100032',
        'DateTime': 1496653560000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/05/2017 02:36 PM'
    }, {
        'UniqueId': 'T100033',
        'DateTime': 1496673960000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/05/2017 08:16 PM'
    }, {
        'UniqueId': 'T100034',
        'DateTime': 1496637660000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/05/2017 10:11 AM'
    }, {
        'UniqueId': 'T100035',
        'DateTime': 1496657040000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/05/2017 03:34 PM'
    }, {
        'UniqueId': 'T100036',
        'DateTime': 1496671860000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '30',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/05/2017 07:41 PM'
    }, {
        'UniqueId': 'T100037',
        'DateTime': 1496671620000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Tennis Court Rent',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/05/2017 07:37 PM'
    }, {
        'UniqueId': 'T100038',
        'DateTime': 1496720520000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/06/2017 09:12 AM'
    }, {
        'UniqueId': 'T100039',
        'DateTime': 1496739060000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/06/2017 02:21 PM'
    }, {
        'UniqueId': 'T100040',
        'DateTime': 1496761140000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/06/2017 08:29 PM'
    }, {
        'UniqueId': 'T100041',
        'DateTime': 1496726040000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/06/2017 10:44 AM'
    }, {
        'UniqueId': 'T100042',
        'DateTime': 1496744760000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/06/2017 03:56 PM'
    }, {
        'UniqueId': 'T100043',
        'DateTime': 1496753340000,
        'Category': 'Health Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '70',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/06/2017 06:19 PM'
    }, {
        'UniqueId': 'T100044',
        'DateTime': 1496752980000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from returns',
        'Amount': '120',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/06/2017 06:13 PM'
    }, {
        'UniqueId': 'T100045',
        'DateTime': 1496808120000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/07/2017 09:32 AM'
    }, {
        'UniqueId': 'T100046',
        'DateTime': 1496826300000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/07/2017 02:35 PM'
    }, {
        'UniqueId': 'T100047',
        'DateTime': 1496849100000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/07/2017 08:55 PM'
    }, {
        'UniqueId': 'T100048',
        'DateTime': 1496811840000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/07/2017 10:34 AM'
    }, {
        'UniqueId': 'T100049',
        'DateTime': 1496828700000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/07/2017 03:15 PM'
    }, {
        'UniqueId': 'T100050',
        'DateTime': 1496849340000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Internet use',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/07/2017 08:59 PM'
    }, {
        'UniqueId': 'T100051',
        'DateTime': 1496893320000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/08/2017 09:12 AM'
    }, {
        'UniqueId': 'T100052',
        'DateTime': 1496911560000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/08/2017 02:16 PM'
    }, {
        'UniqueId': 'T100053',
        'DateTime': 1496933700000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/08/2017 08:25 PM'
    }, {
        'UniqueId': 'T100054',
        'DateTime': 1496898660000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/08/2017 10:41 AM'
    }, {
        'UniqueId': 'T100055',
        'DateTime': 1496915520000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/08/2017 03:22 PM'
    }, {
        'UniqueId': 'T100056',
        'DateTime': 1496901540000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '50',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/08/2017 11:29 AM'
    }, {
        'UniqueId': 'T100057',
        'DateTime': 1496900940000,
        'Category': 'Interests',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Interests',
        'Amount': '120',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/08/2017 11:19 AM'
    }, {
        'UniqueId': 'T100058',
        'DateTime': 1496980140000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/09/2017 09:19 AM'
    }, {
        'UniqueId': 'T100059',
        'DateTime': 1496999040000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/09/2017 02:34 PM'
    }, {
        'UniqueId': 'T100060',
        'DateTime': 1497021540000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/09/2017 08:49 PM'
    }, {
        'UniqueId': 'T100061',
        'DateTime': 1496985300000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/09/2017 10:45 AM'
    }, {
        'UniqueId': 'T100062',
        'DateTime': 1497002760000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/09/2017 03:36 PM'
    }, {
        'UniqueId': 'T100063',
        'DateTime': 1496980860000,
        'Category': 'Clothing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '40',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/09/2017 09:31 AM'
    }, {
        'UniqueId': 'T100064',
        'DateTime': 1496979840000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Housekeeping supplies',
        'Amount': '20',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/09/2017 09:14 AM'
    }, {
        'UniqueId': 'T100065',
        'DateTime': 1497066120000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/10/2017 09:12 AM'
    }, {
        'UniqueId': 'T100066',
        'DateTime': 1497084480000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/10/2017 02:18 PM'
    }, {
        'UniqueId': 'T100067',
        'DateTime': 1497108540000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/10/2017 08:59 PM'
    }, {
        'UniqueId': 'T100068',
        'DateTime': 1497070140000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/10/2017 10:19 AM'
    }, {
        'UniqueId': 'T100069',
        'DateTime': 1497087900000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/10/2017 03:15 PM'
    }, {
        'UniqueId': 'T100070',
        'DateTime': 1497090000000,
        'Category': 'Utilities',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '150',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/10/2017 03:50 PM'
    }, {
        'UniqueId': 'T100071',
        'DateTime': 1497088620000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Internet use',
        'Amount': '5',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/10/2017 03:27 PM'
    }, {
        'UniqueId': 'T100072',
        'DateTime': 1497154860000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/11/2017 09:51 AM'
    }, {
        'UniqueId': 'T100073',
        'DateTime': 1497172500000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/11/2017 02:45 PM'
    }, {
        'UniqueId': 'T100074',
        'DateTime': 1497192120000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/11/2017 08:12 PM'
    }, {
        'UniqueId': 'T100075',
        'DateTime': 1497158400000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/11/2017 10:50 AM'
    }, {
        'UniqueId': 'T100076',
        'DateTime': 1497175200000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/11/2017 03:30 PM'
    }, {
        'UniqueId': 'T100077',
        'DateTime': 1497175260000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '65',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/11/2017 03:31 PM'
    }, {
        'UniqueId': 'T100078',
        'DateTime': 1497174540000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Short visit to private Doctor',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/11/2017 03:19 PM'
    }, {
        'UniqueId': 'T100079',
        'DateTime': 1497174120000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '100',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/11/2017 03:12 PM'
    }, {
        'UniqueId': 'T100080',
        'DateTime': 1497239280000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/12/2017 09:18 AM'
    }, {
        'UniqueId': 'T100081',
        'DateTime': 1497258780000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/12/2017 02:43 PM'
    }, {
        'UniqueId': 'T100082',
        'DateTime': 1497280500000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/12/2017 08:45 PM'
    }, {
        'UniqueId': 'T100083',
        'DateTime': 1497243540000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/12/2017 10:29 AM'
    }, {
        'UniqueId': 'T100084',
        'DateTime': 1497263100000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/12/2017 03:55 PM'
    }, {
        'UniqueId': 'T100085',
        'DateTime': 1497256920000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Major appliances, housewares',
        'Amount': '20',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/12/2017 02:12 PM'
    }, {
        'UniqueId': 'T100086',
        'DateTime': 1497257640000,
        'Category': 'Insurance',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle insurance',
        'Amount': '250',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/12/2017 02:24 PM'
    }, {
        'UniqueId': 'T100087',
        'DateTime': 1497327180000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/13/2017 09:43 AM'
    }, {
        'UniqueId': 'T100088',
        'DateTime': 1497345720000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/13/2017 02:52 PM'
    }, {
        'UniqueId': 'T100089',
        'DateTime': 1497367740000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/13/2017 08:59 PM'
    }, {
        'UniqueId': 'T100090',
        'DateTime': 1497330780000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/13/2017 10:43 AM'
    }, {
        'UniqueId': 'T100091',
        'DateTime': 1497346980000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/13/2017 03:13 PM'
    }, {
        'UniqueId': 'T100092',
        'DateTime': 1497354420000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '280',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/13/2017 05:17 PM'
    }, {
        'UniqueId': 'T100093',
        'DateTime': 1497355380000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Taxi Start (Normal Tariff)',
        'Amount': '3',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/13/2017 05:33 PM'
    }, {
        'UniqueId': 'T100094',
        'DateTime': 1497354720000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Interest from Deposit',
        'Amount': '120',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/13/2017 05:22 PM'
    }, {
        'UniqueId': 'T100095',
        'DateTime': 1497411900000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/14/2017 09:15 AM'
    }, {
        'UniqueId': 'T100096',
        'DateTime': 1497432420000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/14/2017 02:57 PM'
    }, {
        'UniqueId': 'T100097',
        'DateTime': 1497452400000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/14/2017 08:30 PM'
    }, {
        'UniqueId': 'T100098',
        'DateTime': 1497417540000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/14/2017 10:49 AM'
    }, {
        'UniqueId': 'T100099',
        'DateTime': 1497435300000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/14/2017 03:45 PM'
    }, {
        'UniqueId': 'T100100',
        'DateTime': 1497427860000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Own Buisness',
        'Amount': '220',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/14/2017 01:41 PM'
    }, {
        'UniqueId': 'T100101',
        'DateTime': 1497498840000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/15/2017 09:24 AM'
    }, {
        'UniqueId': 'T100102',
        'DateTime': 1497517380000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/15/2017 02:33 PM'
    }, {
        'UniqueId': 'T100103',
        'DateTime': 1497540300000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/15/2017 08:55 PM'
    }, {
        'UniqueId': 'T100104',
        'DateTime': 1497502800000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/15/2017 10:30 AM'
    }, {
        'UniqueId': 'T100105',
        'DateTime': 1497519900000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/15/2017 03:15 PM'
    }, {
        'UniqueId': 'T100106',
        'DateTime': 1497537840000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '55',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/15/2017 08:14 PM'
    }, {
        'UniqueId': 'T100107',
        'DateTime': 1497539820000,
        'Category': 'Bills',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Internet bills',
        'Amount': '380',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/15/2017 08:47 PM'
    }, {
        'UniqueId': 'T100108',
        'DateTime': 1497538920000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '110',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/15/2017 08:32 PM'
    }, {
        'UniqueId': 'T100109',
        'DateTime': 1497585480000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/16/2017 09:28 AM'
    }, {
        'UniqueId': 'T100110',
        'DateTime': 1497603960000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/16/2017 02:36 PM'
    }, {
        'UniqueId': 'T100111',
        'DateTime': 1497624360000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/16/2017 08:16 PM'
    }, {
        'UniqueId': 'T100112',
        'DateTime': 1497590340000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/16/2017 10:49 AM'
    }, {
        'UniqueId': 'T100113',
        'DateTime': 1497608580000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/16/2017 03:53 PM'
    }, {
        'UniqueId': 'T100114',
        'DateTime': 1497606960000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '135',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/16/2017 03:26 PM'
    }, {
        'UniqueId': 'T100115',
        'DateTime': 1497607860000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance, repairs',
        'Amount': '15',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/16/2017 03:41 PM'
    }, {
        'UniqueId': 'T100116',
        'DateTime': 1497606540000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema',
        'Amount': '4',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/16/2017 03:19 PM'
    }, {
        'UniqueId': 'T100117',
        'DateTime': 1497671700000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/17/2017 09:25 AM'
    }, {
        'UniqueId': 'T100118',
        'DateTime': 1497689760000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/17/2017 02:26 PM'
    }, {
        'UniqueId': 'T100119',
        'DateTime': 1497711900000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/17/2017 08:35 PM'
    }, {
        'UniqueId': 'T100120',
        'DateTime': 1497675420000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/17/2017 10:27 AM'
    }, {
        'UniqueId': 'T100121',
        'DateTime': 1497695160000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/17/2017 03:56 PM'
    }, {
        'UniqueId': 'T100122',
        'DateTime': 1497692640000,
        'Category': 'Utilities',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '350',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/17/2017 03:14 PM'
    }, {
        'UniqueId': 'T100123',
        'DateTime': 1497758640000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/18/2017 09:34 AM'
    }, {
        'UniqueId': 'T100124',
        'DateTime': 1497776700000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/18/2017 02:35 PM'
    }, {
        'UniqueId': 'T100125',
        'DateTime': 1497796980000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/18/2017 08:13 PM'
    }, {
        'UniqueId': 'T100126',
        'DateTime': 1497760860000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/18/2017 10:11 AM'
    }, {
        'UniqueId': 'T100127',
        'DateTime': 1497781500000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/18/2017 03:55 PM'
    }, {
        'UniqueId': 'T100128',
        'DateTime': 1497767280000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '65',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/18/2017 11:58 AM'
    }, {
        'UniqueId': 'T100129',
        'DateTime': 1497765420000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Deodorant, roll-on',
        'Amount': '15',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/18/2017 11:27 AM'
    }, {
        'UniqueId': 'T100130',
        'DateTime': 1497845700000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/19/2017 09:45 AM'
    }, {
        'UniqueId': 'T100131',
        'DateTime': 1497862860000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/19/2017 02:31 PM'
    }, {
        'UniqueId': 'T100132',
        'DateTime': 1497885660000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/19/2017 08:51 PM'
    }, {
        'UniqueId': 'T100133',
        'DateTime': 1497848460000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/19/2017 10:31 AM'
    }, {
        'UniqueId': 'T100134',
        'DateTime': 1497866700000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/19/2017 03:35 PM'
    }, {
        'UniqueId': 'T100135',
        'DateTime': 1497880200000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Housekeeping supplies',
        'Amount': '30',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/19/2017 07:20 PM'
    }, {
        'UniqueId': 'T100136',
        'DateTime': 1497881280000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Debt repayment',
        'Amount': '3',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/19/2017 07:38 PM'
    }, {
        'UniqueId': 'T100137',
        'DateTime': 1497880860000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Interest from Deposit',
        'Amount': '80',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/19/2017 07:31 PM'
    }, {
        'UniqueId': 'T100138',
        'DateTime': 1497931500000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/20/2017 09:35 AM'
    }, {
        'UniqueId': 'T100139',
        'DateTime': 1497950700000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/20/2017 02:55 PM'
    }, {
        'UniqueId': 'T100140',
        'DateTime': 1497971100000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/20/2017 08:35 PM'
    }, {
        'UniqueId': 'T100141',
        'DateTime': 1497935820000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/20/2017 10:47 AM'
    }, {
        'UniqueId': 'T100142',
        'DateTime': 1497952680000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/20/2017 03:28 PM'
    }, {
        'UniqueId': 'T100143',
        'DateTime': 1497957360000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '70',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/20/2017 04:46 PM'
    }, {
        'UniqueId': 'T100144',
        'DateTime': 1498018140000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/21/2017 09:39 AM'
    }, {
        'UniqueId': 'T100145',
        'DateTime': 1498036500000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/21/2017 02:45 PM'
    }, {
        'UniqueId': 'T100146',
        'DateTime': 1498056480000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/21/2017 08:18 PM'
    }, {
        'UniqueId': 'T100147',
        'DateTime': 1498022640000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/21/2017 10:54 AM'
    }, {
        'UniqueId': 'T100148',
        'DateTime': 1498040940000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/21/2017 03:59 PM'
    }, {
        'UniqueId': 'T100149',
        'DateTime': 1498103220000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/22/2017 09:17 AM'
    }, {
        'UniqueId': 'T100150',
        'DateTime': 1498122720000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/22/2017 02:42 PM'
    }, {
        'UniqueId': 'T100151',
        'DateTime': 1498143060000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/22/2017 08:21 PM'
    }, {
        'UniqueId': 'T100152',
        'DateTime': 1498108680000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/22/2017 10:48 AM'
    }, {
        'UniqueId': 'T100153',
        'DateTime': 1498125540000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/22/2017 03:29 PM'
    }, {
        'UniqueId': 'T100154',
        'DateTime': 1498112160000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '60',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/22/2017 11:46 AM'
    }, {
        'UniqueId': 'T100155',
        'DateTime': 1498110360000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from lottery',
        'Amount': '80',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/22/2017 11:16 AM'
    }, {
        'UniqueId': 'T100156',
        'DateTime': 1498189380000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/23/2017 09:13 AM'
    }, {
        'UniqueId': 'T100157',
        'DateTime': 1498208400000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/23/2017 02:30 PM'
    }, {
        'UniqueId': 'T100158',
        'DateTime': 1498229580000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/23/2017 08:23 PM'
    }, {
        'UniqueId': 'T100159',
        'DateTime': 1498194360000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/23/2017 10:36 AM'
    }, {
        'UniqueId': 'T100160',
        'DateTime': 1498211160000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '9',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/23/2017 03:16 PM'
    }, {
        'UniqueId': 'T100161',
        'DateTime': 1498202280000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '50',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/23/2017 12:48 PM'
    }, {
        'UniqueId': 'T100162',
        'DateTime': 1498200120000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Housekeeping supplies',
        'Amount': '20',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/23/2017 12:12 PM'
    }, {
        'UniqueId': 'T100163',
        'DateTime': 1498202340000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Internet use',
        'Amount': '3',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/23/2017 12:49 PM'
    }, {
        'UniqueId': 'T100164',
        'DateTime': 1498277340000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/24/2017 09:39 AM'
    }, {
        'UniqueId': 'T100165',
        'DateTime': 1498296360000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/24/2017 02:56 PM'
    }, {
        'UniqueId': 'T100166',
        'DateTime': 1498315440000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/24/2017 08:14 PM'
    }, {
        'UniqueId': 'T100167',
        'DateTime': 1498279980000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/24/2017 10:23 AM'
    }, {
        'UniqueId': 'T100168',
        'DateTime': 1498299900000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/24/2017 03:55 PM'
    }, {
        'UniqueId': 'T100169',
        'DateTime': 1498315920000,
        'Category': 'Utilities',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '140',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/24/2017 08:22 PM'
    }, {
        'UniqueId': 'T100170',
        'DateTime': 1498316460000,
        'Category': 'Insurance',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal insurance',
        'Amount': '230',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/24/2017 08:31 PM'
    }, {
        'UniqueId': 'T100171',
        'DateTime': 1498362240000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/25/2017 09:14 AM'
    }, {
        'UniqueId': 'T100172',
        'DateTime': 1498381140000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/25/2017 02:29 PM'
    }, {
        'UniqueId': 'T100173',
        'DateTime': 1498404360000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/25/2017 08:56 PM'
    }, {
        'UniqueId': 'T100174',
        'DateTime': 1498368360000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/25/2017 10:56 AM'
    }, {
        'UniqueId': 'T100175',
        'DateTime': 1498386180000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/25/2017 03:53 PM'
    }, {
        'UniqueId': 'T100176',
        'DateTime': 1498379160000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '55',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/25/2017 01:56 PM'
    }, {
        'UniqueId': 'T100177',
        'DateTime': 1498376520000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cold medicine',
        'Amount': '15',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/25/2017 01:12 PM'
    }, {
        'UniqueId': 'T100178',
        'DateTime': 1498379100000,
        'Category': 'Tax',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Income tax',
        'Amount': '180',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/25/2017 01:55 PM'
    }, {
        'UniqueId': 'T100179',
        'DateTime': 1498450680000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/26/2017 09:48 AM'
    }, {
        'UniqueId': 'T100180',
        'DateTime': 1498466400000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/26/2017 02:10 PM'
    }, {
        'UniqueId': 'T100181',
        'DateTime': 1498490160000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/26/2017 08:46 PM'
    }, {
        'UniqueId': 'T100182',
        'DateTime': 1498453440000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/26/2017 10:34 AM'
    }, {
        'UniqueId': 'T100183',
        'DateTime': 1498472040000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/26/2017 03:44 PM'
    }, {
        'UniqueId': 'T100184',
        'DateTime': 1498485060000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '30',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/26/2017 07:21 PM'
    }, {
        'UniqueId': 'T100185',
        'DateTime': 1498486380000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from returns',
        'Amount': '120',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/26/2017 07:43 PM'
    }, {
        'UniqueId': 'T100186',
        'DateTime': 1498536120000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/27/2017 09:32 AM'
    }, {
        'UniqueId': 'T100187',
        'DateTime': 1498553400000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/27/2017 02:20 PM'
    }, {
        'UniqueId': 'T100188',
        'DateTime': 1498574580000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '12',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/27/2017 08:13 PM'
    }, {
        'UniqueId': 'T100189',
        'DateTime': 1498540080000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/27/2017 10:38 AM'
    }, {
        'UniqueId': 'T100190',
        'DateTime': 1498556640000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/27/2017 03:14 PM'
    }, {
        'UniqueId': 'T100191',
        'DateTime': 1498552140000,
        'Category': 'Health Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '70',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/27/2017 01:59 PM'
    }, {
        'UniqueId': 'T100192',
        'DateTime': 1498623540000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '8',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/28/2017 09:49 AM'
    }, {
        'UniqueId': 'T100193',
        'DateTime': 1498641780000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/28/2017 02:53 PM'
    }, {
        'UniqueId': 'T100194',
        'DateTime': 1498661580000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/28/2017 08:23 PM'
    }, {
        'UniqueId': 'T100195',
        'DateTime': 1498624860000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/28/2017 10:11 AM'
    }, {
        'UniqueId': 'T100196',
        'DateTime': 1498643940000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/28/2017 03:29 PM'
    }, {
        'UniqueId': 'T100197',
        'DateTime': 1498639860000,
        'Category': 'Bills',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Telephone services',
        'Amount': '200',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/28/2017 02:21 PM'
    }, {
        'UniqueId': 'T100198',
        'DateTime': 1498641840000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from investments',
        'Amount': '220',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/28/2017 02:54 PM'
    }, {
        'UniqueId': 'T100199',
        'DateTime': 1498707660000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/29/2017 09:11 AM'
    }, {
        'UniqueId': 'T100200',
        'DateTime': 1498726260000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '6',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/29/2017 02:21 PM'
    }, {
        'UniqueId': 'T100201',
        'DateTime': 1498748460000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/29/2017 08:31 PM'
    }, {
        'UniqueId': 'T100202',
        'DateTime': 1498714080000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/29/2017 10:58 AM'
    }, {
        'UniqueId': 'T100203',
        'DateTime': 1498731180000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/29/2017 03:43 PM'
    }, {
        'UniqueId': 'T100204',
        'DateTime': 1498742820000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '155',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/29/2017 06:57 PM'
    }, {
        'UniqueId': 'T100205',
        'DateTime': 1498741800000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline (1 liter)',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/29/2017 06:40 PM'
    }, {
        'UniqueId': 'T100206',
        'DateTime': 1498796880000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/30/2017 09:58 AM'
    }, {
        'UniqueId': 'T100207',
        'DateTime': 1498812420000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '7',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/30/2017 02:17 PM'
    }, {
        'UniqueId': 'T100208',
        'DateTime': 1498835580000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/30/2017 08:43 PM'
    }, {
        'UniqueId': 'T100209',
        'DateTime': 1498798920000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/30/2017 10:32 AM'
    }, {
        'UniqueId': 'T100210',
        'DateTime': 1498817040000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/30/2017 03:34 PM'
    }, {
        'UniqueId': 'T100211',
        'DateTime': 1498803720000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Summer Dress in Store',
        'Amount': '145',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/30/2017 11:52 AM'
    }, {
        'UniqueId': 'T100212',
        'DateTime': 1498802700000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household textiles',
        'Amount': '20',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/30/2017 11:35 AM'
    }, {
        'UniqueId': 'T100213',
        'DateTime': 1498801560000,
        'Category': 'Education',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Education',
        'Amount': '50',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/30/2017 11:16 AM'
    }, {
        'UniqueId': 'T100214',
        'DateTime': 1498803720000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Sale',
        'Amount': '80',
        'MonthShort': 'Jun',
        'MonthFull': 'June, 2017',
        'FormattedDate': '06/30/2017 11:52 AM'
    }, {
        'UniqueId': 'T100215',
        'DateTime': 1498880400000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/01/2017 09:10 AM'
    }, {
        'UniqueId': 'T100216',
        'DateTime': 1498901160000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/01/2017 02:56 PM'
    }, {
        'UniqueId': 'T100217',
        'DateTime': 1498920780000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/01/2017 08:23 PM'
    }, {
        'UniqueId': 'T100218',
        'DateTime': 1498885320000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/01/2017 10:32 AM'
    }, {
        'UniqueId': 'T100219',
        'DateTime': 1498903320000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/01/2017 03:32 PM'
    }, {
        'UniqueId': 'T100220',
        'DateTime': 1498921860000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '60',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/01/2017 08:41 PM'
    }, {
        'UniqueId': 'T100221',
        'DateTime': 1498921500000,
        'Category': 'Mortgage / Rent',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Monthly rent',
        'Amount': '1000',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/01/2017 08:35 PM'
    }, {
        'UniqueId': 'T100222',
        'DateTime': 1498920420000,
        'Category': 'Salary',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Monthly Net Salary',
        'Amount': '5500',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/01/2017 08:17 PM'
    }, {
        'UniqueId': 'T100223',
        'DateTime': 1498967400000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/02/2017 09:20 AM'
    }, {
        'UniqueId': 'T100224',
        'DateTime': 1498987500000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/02/2017 02:55 PM'
    }, {
        'UniqueId': 'T100225',
        'DateTime': 1499008560000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/02/2017 08:46 PM'
    }, {
        'UniqueId': 'T100226',
        'DateTime': 1498971480000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/02/2017 10:28 AM'
    }, {
        'UniqueId': 'T100227',
        'DateTime': 1498991280000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/02/2017 03:58 PM'
    }, {
        'UniqueId': 'T100228',
        'DateTime': 1498985100000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '40',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/02/2017 02:15 PM'
    }, {
        'UniqueId': 'T100229',
        'DateTime': 1498987320000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other household products',
        'Amount': '30',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/02/2017 02:52 PM'
    }, {
        'UniqueId': 'T100230',
        'DateTime': 1499054220000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/03/2017 09:27 AM'
    }, {
        'UniqueId': 'T100231',
        'DateTime': 1499071440000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/03/2017 02:14 PM'
    }, {
        'UniqueId': 'T100232',
        'DateTime': 1499093040000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/03/2017 08:14 PM'
    }, {
        'UniqueId': 'T100233',
        'DateTime': 1499057460000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/03/2017 10:21 AM'
    }, {
        'UniqueId': 'T100234',
        'DateTime': 1499075520000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/03/2017 03:22 PM'
    }, {
        'UniqueId': 'T100235',
        'DateTime': 1499064660000,
        'Category': 'Utilities',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '140',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/03/2017 12:21 PM'
    }, {
        'UniqueId': 'T100236',
        'DateTime': 1499064420000,
        'Category': 'Bills',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Electricity bill',
        'Amount': '280',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/03/2017 12:17 PM'
    }, {
        'UniqueId': 'T100237',
        'DateTime': 1499142540000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/04/2017 09:59 AM'
    }, {
        'UniqueId': 'T100238',
        'DateTime': 1499159880000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/04/2017 02:48 PM'
    }, {
        'UniqueId': 'T100239',
        'DateTime': 1499181180000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/04/2017 08:43 PM'
    }, {
        'UniqueId': 'T100240',
        'DateTime': 1499143980000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/04/2017 10:23 AM'
    }, {
        'UniqueId': 'T100241',
        'DateTime': 1499161980000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/04/2017 03:23 PM'
    }, {
        'UniqueId': 'T100242',
        'DateTime': 1499177520000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '50',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/04/2017 07:42 PM'
    }, {
        'UniqueId': 'T100243',
        'DateTime': 1499178240000,
        'Category': 'Personal Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cold medicine',
        'Amount': '15',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/04/2017 07:54 PM'
    }, {
        'UniqueId': 'T100244',
        'DateTime': 1499176500000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from casino',
        'Amount': '90',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/04/2017 07:25 PM'
    }, {
        'UniqueId': 'T100245',
        'DateTime': 1499227500000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/05/2017 09:35 AM'
    }, {
        'UniqueId': 'T100246',
        'DateTime': 1499246760000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/05/2017 02:56 PM'
    }, {
        'UniqueId': 'T100247',
        'DateTime': 1499267820000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/05/2017 08:47 PM'
    }, {
        'UniqueId': 'T100248',
        'DateTime': 1499230260000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/05/2017 10:21 AM'
    }, {
        'UniqueId': 'T100249',
        'DateTime': 1499248200000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/05/2017 03:20 PM'
    }, {
        'UniqueId': 'T100250',
        'DateTime': 1499259240000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Housekeeping supplies',
        'Amount': '20',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/05/2017 06:24 PM'
    }, {
        'UniqueId': 'T100251',
        'DateTime': 1499260020000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline (1 liter)',
        'Amount': '3',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/05/2017 06:37 PM'
    }, {
        'UniqueId': 'T100252',
        'DateTime': 1499312760000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/06/2017 09:16 AM'
    }, {
        'UniqueId': 'T100253',
        'DateTime': 1499332200000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/06/2017 02:40 PM'
    }, {
        'UniqueId': 'T100254',
        'DateTime': 1499353680000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/06/2017 08:38 PM'
    }, {
        'UniqueId': 'T100255',
        'DateTime': 1499316600000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/06/2017 10:20 AM'
    }, {
        'UniqueId': 'T100256',
        'DateTime': 1499335080000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/06/2017 03:28 PM'
    }, {
        'UniqueId': 'T100257',
        'DateTime': 1499323980000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '80',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/06/2017 12:23 PM'
    }, {
        'UniqueId': 'T100258',
        'DateTime': 1499325540000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Gits',
        'Amount': '90',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/06/2017 12:49 PM'
    }, {
        'UniqueId': 'T100259',
        'DateTime': 1499401020000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/07/2017 09:47 AM'
    }, {
        'UniqueId': 'T100260',
        'DateTime': 1499417340000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/07/2017 02:19 PM'
    }, {
        'UniqueId': 'T100261',
        'DateTime': 1499440260000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/07/2017 08:41 PM'
    }, {
        'UniqueId': 'T100262',
        'DateTime': 1499403360000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/07/2017 10:26 AM'
    }, {
        'UniqueId': 'T100263',
        'DateTime': 1499422380000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/07/2017 03:43 PM'
    }, {
        'UniqueId': 'T100264',
        'DateTime': 1499413680000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Monthly Pass (Regular Price)',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/07/2017 01:18 PM'
    }, {
        'UniqueId': 'T100265',
        'DateTime': 1499487000000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/08/2017 09:40 AM'
    }, {
        'UniqueId': 'T100266',
        'DateTime': 1499504880000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/08/2017 02:38 PM'
    }, {
        'UniqueId': 'T100267',
        'DateTime': 1499527500000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/08/2017 08:55 PM'
    }, {
        'UniqueId': 'T100268',
        'DateTime': 1499490900000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/08/2017 10:45 AM'
    }, {
        'UniqueId': 'T100269',
        'DateTime': 1499509560000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/08/2017 03:56 PM'
    }, {
        'UniqueId': 'T100270',
        'DateTime': 1499499960000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '55',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/08/2017 01:16 PM'
    }, {
        'UniqueId': 'T100271',
        'DateTime': 1499502480000,
        'Category': 'Interests',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Interests',
        'Amount': '90',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/08/2017 01:58 PM'
    }, {
        'UniqueId': 'T100272',
        'DateTime': 1499573880000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/09/2017 09:48 AM'
    }, {
        'UniqueId': 'T100273',
        'DateTime': 1499591760000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/09/2017 02:46 PM'
    }, {
        'UniqueId': 'T100274',
        'DateTime': 1499613900000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/09/2017 08:55 PM'
    }, {
        'UniqueId': 'T100275',
        'DateTime': 1499576640000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/09/2017 10:34 AM'
    }, {
        'UniqueId': 'T100276',
        'DateTime': 1499594100000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/09/2017 03:25 PM'
    }, {
        'UniqueId': 'T100277',
        'DateTime': 1499601180000,
        'Category': 'Clothing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Summer Dress in Store',
        'Amount': '40',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/09/2017 05:23 PM'
    }, {
        'UniqueId': 'T100278',
        'DateTime': 1499602920000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '30',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/09/2017 05:52 PM'
    }, {
        'UniqueId': 'T100279',
        'DateTime': 1499660640000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/10/2017 09:54 AM'
    }, {
        'UniqueId': 'T100280',
        'DateTime': 1499676480000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/10/2017 02:18 PM'
    }, {
        'UniqueId': 'T100281',
        'DateTime': 1499698680000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/10/2017 08:28 PM'
    }, {
        'UniqueId': 'T100282',
        'DateTime': 1499663040000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/10/2017 10:34 AM'
    }, {
        'UniqueId': 'T100283',
        'DateTime': 1499679720000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/10/2017 03:12 PM'
    }, {
        'UniqueId': 'T100284',
        'DateTime': 1499663880000,
        'Category': 'Utilities',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '360',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/10/2017 10:48 AM'
    }, {
        'UniqueId': 'T100285',
        'DateTime': 1499664240000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Internet use',
        'Amount': '3',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/10/2017 10:54 AM'
    }, {
        'UniqueId': 'T100286',
        'DateTime': 1499745420000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/11/2017 09:27 AM'
    }, {
        'UniqueId': 'T100287',
        'DateTime': 1499763720000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/11/2017 02:32 PM'
    }, {
        'UniqueId': 'T100288',
        'DateTime': 1499785860000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/11/2017 08:41 PM'
    }, {
        'UniqueId': 'T100289',
        'DateTime': 1499750940000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/11/2017 10:59 AM'
    }, {
        'UniqueId': 'T100290',
        'DateTime': 1499766840000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/11/2017 03:24 PM'
    }, {
        'UniqueId': 'T100291',
        'DateTime': 1499774340000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '65',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/11/2017 05:29 PM'
    }, {
        'UniqueId': 'T100292',
        'DateTime': 1499775960000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Standard haircut',
        'Amount': '15',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/11/2017 05:56 PM'
    }, {
        'UniqueId': 'T100293',
        'DateTime': 1499773800000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Gits',
        'Amount': '120',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/11/2017 05:20 PM'
    }, {
        'UniqueId': 'T100294',
        'DateTime': 1499832420000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/12/2017 09:37 AM'
    }, {
        'UniqueId': 'T100295',
        'DateTime': 1499851020000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/12/2017 02:47 PM'
    }, {
        'UniqueId': 'T100296',
        'DateTime': 1499872800000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/12/2017 08:50 PM'
    }, {
        'UniqueId': 'T100297',
        'DateTime': 1499835240000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/12/2017 10:24 AM'
    }, {
        'UniqueId': 'T100298',
        'DateTime': 1499855040000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/12/2017 03:54 PM'
    }, {
        'UniqueId': 'T100299',
        'DateTime': 1499863380000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household textiles',
        'Amount': '30',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/12/2017 06:13 PM'
    }, {
        'UniqueId': 'T100300',
        'DateTime': 1499863260000,
        'Category': 'Insurance',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Life and other personal insurance',
        'Amount': '230',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/12/2017 06:11 PM'
    }, {
        'UniqueId': 'T100301',
        'DateTime': 1499919480000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/13/2017 09:48 AM'
    }, {
        'UniqueId': 'T100302',
        'DateTime': 1499935560000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/13/2017 02:16 PM'
    }, {
        'UniqueId': 'T100303',
        'DateTime': 1499959740000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/13/2017 08:59 PM'
    }, {
        'UniqueId': 'T100304',
        'DateTime': 1499921220000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/13/2017 10:17 AM'
    }, {
        'UniqueId': 'T100305',
        'DateTime': 1499941560000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/13/2017 03:56 PM'
    }, {
        'UniqueId': 'T100306',
        'DateTime': 1499919180000,
        'Category': 'Health Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '60',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/13/2017 09:43 AM'
    }, {
        'UniqueId': 'T100307',
        'DateTime': 1499918820000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gym payment',
        'Amount': '5',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/13/2017 09:37 AM'
    }, {
        'UniqueId': 'T100308',
        'DateTime': 1499918580000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '80',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/13/2017 09:33 AM'
    }, {
        'UniqueId': 'T100309',
        'DateTime': 1500003900000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/14/2017 09:15 AM'
    }, {
        'UniqueId': 'T100310',
        'DateTime': 1500021600000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/14/2017 02:10 PM'
    }, {
        'UniqueId': 'T100311',
        'DateTime': 1500043740000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/14/2017 08:19 PM'
    }, {
        'UniqueId': 'T100312',
        'DateTime': 1500007260000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/14/2017 10:11 AM'
    }, {
        'UniqueId': 'T100313',
        'DateTime': 1500025200000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/14/2017 03:10 PM'
    }, {
        'UniqueId': 'T100314',
        'DateTime': 1500004800000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from investments',
        'Amount': '180',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/14/2017 09:30 AM'
    }, {
        'UniqueId': 'T100315',
        'DateTime': 1500092580000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/15/2017 09:53 AM'
    }, {
        'UniqueId': 'T100316',
        'DateTime': 1500110760000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/15/2017 02:56 PM'
    }, {
        'UniqueId': 'T100317',
        'DateTime': 1500131760000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/15/2017 08:46 PM'
    }, {
        'UniqueId': 'T100318',
        'DateTime': 1500094680000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/15/2017 10:28 AM'
    }, {
        'UniqueId': 'T100319',
        'DateTime': 1500111960000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/15/2017 03:16 PM'
    }, {
        'UniqueId': 'T100320',
        'DateTime': 1500115200000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '55',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/15/2017 04:10 PM'
    }, {
        'UniqueId': 'T100321',
        'DateTime': 1500115440000,
        'Category': 'Bills',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Internet bills',
        'Amount': '320',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/15/2017 04:14 PM'
    }, {
        'UniqueId': 'T100322',
        'DateTime': 1500117660000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Sale',
        'Amount': '100',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/15/2017 04:51 PM'
    }, {
        'UniqueId': 'T100323',
        'DateTime': 1500178140000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/16/2017 09:39 AM'
    }, {
        'UniqueId': 'T100324',
        'DateTime': 1500195660000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/16/2017 02:31 PM'
    }, {
        'UniqueId': 'T100325',
        'DateTime': 1500216360000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/16/2017 08:16 PM'
    }, {
        'UniqueId': 'T100326',
        'DateTime': 1500182640000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/16/2017 10:54 AM'
    }, {
        'UniqueId': 'T100327',
        'DateTime': 1500199980000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/16/2017 03:43 PM'
    }, {
        'UniqueId': 'T100328',
        'DateTime': 1500182580000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '35',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/16/2017 10:53 AM'
    }, {
        'UniqueId': 'T100329',
        'DateTime': 1500182340000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '30',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/16/2017 10:49 AM'
    }, {
        'UniqueId': 'T100330',
        'DateTime': 1500181920000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema, International Release',
        'Amount': '5',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/16/2017 10:42 AM'
    }, {
        'UniqueId': 'T100331',
        'DateTime': 1500264540000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/17/2017 09:39 AM'
    }, {
        'UniqueId': 'T100332',
        'DateTime': 1500282180000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/17/2017 02:33 PM'
    }, {
        'UniqueId': 'T100333',
        'DateTime': 1500305100000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/17/2017 08:55 PM'
    }, {
        'UniqueId': 'T100334',
        'DateTime': 1500268320000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/17/2017 10:42 AM'
    }, {
        'UniqueId': 'T100335',
        'DateTime': 1500285840000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/17/2017 03:34 PM'
    }, {
        'UniqueId': 'T100336',
        'DateTime': 1500284460000,
        'Category': 'Utilities',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '130',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/17/2017 03:11 PM'
    }, {
        'UniqueId': 'T100337',
        'DateTime': 1500351360000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/18/2017 09:46 AM'
    }, {
        'UniqueId': 'T100338',
        'DateTime': 1500369720000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/18/2017 02:52 PM'
    }, {
        'UniqueId': 'T100339',
        'DateTime': 1500391500000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/18/2017 08:55 PM'
    }, {
        'UniqueId': 'T100340',
        'DateTime': 1500355680000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/18/2017 10:58 AM'
    }, {
        'UniqueId': 'T100341',
        'DateTime': 1500372840000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/18/2017 03:44 PM'
    }, {
        'UniqueId': 'T100342',
        'DateTime': 1500368340000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '55',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/18/2017 02:29 PM'
    }, {
        'UniqueId': 'T100343',
        'DateTime': 1500370080000,
        'Category': 'Personal Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Short visit to private Doctor',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/18/2017 02:58 PM'
    }, {
        'UniqueId': 'T100344',
        'DateTime': 1500438120000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/19/2017 09:52 AM'
    }, {
        'UniqueId': 'T100345',
        'DateTime': 1500455700000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/19/2017 02:45 PM'
    }, {
        'UniqueId': 'T100346',
        'DateTime': 1500477120000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/19/2017 08:42 PM'
    }, {
        'UniqueId': 'T100347',
        'DateTime': 1500441000000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/19/2017 10:40 AM'
    }, {
        'UniqueId': 'T100348',
        'DateTime': 1500457380000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/19/2017 03:13 PM'
    }, {
        'UniqueId': 'T100349',
        'DateTime': 1500454620000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '20',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/19/2017 02:27 PM'
    }, {
        'UniqueId': 'T100350',
        'DateTime': 1500453780000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Tennis Court Rent',
        'Amount': '3',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/19/2017 02:13 PM'
    }, {
        'UniqueId': 'T100351',
        'DateTime': 1500456360000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from casino',
        'Amount': '120',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/19/2017 02:56 PM'
    }, {
        'UniqueId': 'T100352',
        'DateTime': 1500524940000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/20/2017 09:59 AM'
    }, {
        'UniqueId': 'T100353',
        'DateTime': 1500540600000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/20/2017 02:20 PM'
    }, {
        'UniqueId': 'T100354',
        'DateTime': 1500563460000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/20/2017 08:41 PM'
    }, {
        'UniqueId': 'T100355',
        'DateTime': 1500527700000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/20/2017 10:45 AM'
    }, {
        'UniqueId': 'T100356',
        'DateTime': 1500545940000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/20/2017 03:49 PM'
    }, {
        'UniqueId': 'T100357',
        'DateTime': 1500543720000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '260',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/20/2017 03:12 PM'
    }, {
        'UniqueId': 'T100358',
        'DateTime': 1500610500000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/21/2017 09:45 AM'
    }, {
        'UniqueId': 'T100359',
        'DateTime': 1500626520000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/21/2017 02:12 PM'
    }, {
        'UniqueId': 'T100360',
        'DateTime': 1500650340000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/21/2017 08:49 PM'
    }, {
        'UniqueId': 'T100361',
        'DateTime': 1500613800000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/21/2017 10:40 AM'
    }, {
        'UniqueId': 'T100362',
        'DateTime': 1500632460000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/21/2017 03:51 PM'
    }, {
        'UniqueId': 'T100363',
        'DateTime': 1500695040000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/22/2017 09:14 AM'
    }, {
        'UniqueId': 'T100364',
        'DateTime': 1500715380000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/22/2017 02:53 PM'
    }, {
        'UniqueId': 'T100365',
        'DateTime': 1500735720000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/22/2017 08:32 PM'
    }, {
        'UniqueId': 'T100366',
        'DateTime': 1500699480000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/22/2017 10:28 AM'
    }, {
        'UniqueId': 'T100367',
        'DateTime': 1500716880000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/22/2017 03:18 PM'
    }, {
        'UniqueId': 'T100368',
        'DateTime': 1500706020000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '50',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/22/2017 12:17 PM'
    }, {
        'UniqueId': 'T100369',
        'DateTime': 1500708060000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from casino',
        'Amount': '100',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/22/2017 12:51 PM'
    }, {
        'UniqueId': 'T100370',
        'DateTime': 1500782820000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/23/2017 09:37 AM'
    }, {
        'UniqueId': 'T100371',
        'DateTime': 1500801240000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/23/2017 02:44 PM'
    }, {
        'UniqueId': 'T100372',
        'DateTime': 1500821340000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/23/2017 08:19 PM'
    }, {
        'UniqueId': 'T100373',
        'DateTime': 1500784980000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/23/2017 10:13 AM'
    }, {
        'UniqueId': 'T100374',
        'DateTime': 1500804660000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/23/2017 03:41 PM'
    }, {
        'UniqueId': 'T100375',
        'DateTime': 1500783120000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '50',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/23/2017 09:42 AM'
    }, {
        'UniqueId': 'T100376',
        'DateTime': 1500781680000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '15',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/23/2017 09:18 AM'
    }, {
        'UniqueId': 'T100377',
        'DateTime': 1500781500000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline (1 liter)',
        'Amount': '4',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/23/2017 09:15 AM'
    }, {
        'UniqueId': 'T100378',
        'DateTime': 1500869520000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/24/2017 09:42 AM'
    }, {
        'UniqueId': 'T100379',
        'DateTime': 1500886020000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/24/2017 02:17 PM'
    }, {
        'UniqueId': 'T100380',
        'DateTime': 1500908160000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/24/2017 08:26 PM'
    }, {
        'UniqueId': 'T100381',
        'DateTime': 1500871860000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/24/2017 10:21 AM'
    }, {
        'UniqueId': 'T100382',
        'DateTime': 1500891360000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/24/2017 03:46 PM'
    }, {
        'UniqueId': 'T100383',
        'DateTime': 1500906000000,
        'Category': 'Utilities',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '140',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/24/2017 07:50 PM'
    }, {
        'UniqueId': 'T100384',
        'DateTime': 1500904080000,
        'Category': 'Insurance',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal insurance',
        'Amount': '230',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/24/2017 07:18 PM'
    }, {
        'UniqueId': 'T100385',
        'DateTime': 1500956220000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/25/2017 09:47 AM'
    }, {
        'UniqueId': 'T100386',
        'DateTime': 1500973500000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/25/2017 02:35 PM'
    }, {
        'UniqueId': 'T100387',
        'DateTime': 1500996000000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/25/2017 08:50 PM'
    }, {
        'UniqueId': 'T100388',
        'DateTime': 1500958620000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/25/2017 10:27 AM'
    }, {
        'UniqueId': 'T100389',
        'DateTime': 1500977940000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/25/2017 03:49 PM'
    }, {
        'UniqueId': 'T100390',
        'DateTime': 1500987120000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '55',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/25/2017 06:22 PM'
    }, {
        'UniqueId': 'T100391',
        'DateTime': 1500988740000,
        'Category': 'Personal Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Deodorant, roll-on',
        'Amount': '15',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/25/2017 06:49 PM'
    }, {
        'UniqueId': 'T100392',
        'DateTime': 1500989040000,
        'Category': 'Tax',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Property tax',
        'Amount': '180',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/25/2017 06:54 PM'
    }, {
        'UniqueId': 'T100393',
        'DateTime': 1501041540000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/26/2017 09:29 AM'
    }, {
        'UniqueId': 'T100394',
        'DateTime': 1501060200000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/26/2017 02:40 PM'
    }, {
        'UniqueId': 'T100395',
        'DateTime': 1501082160000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/26/2017 08:46 PM'
    }, {
        'UniqueId': 'T100396',
        'DateTime': 1501046520000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/26/2017 10:52 AM'
    }, {
        'UniqueId': 'T100397',
        'DateTime': 1501063140000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/26/2017 03:29 PM'
    }, {
        'UniqueId': 'T100398',
        'DateTime': 1501062900000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other household products',
        'Amount': '30',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/26/2017 03:25 PM'
    }, {
        'UniqueId': 'T100399',
        'DateTime': 1501064880000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Funds',
        'Amount': '110',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/26/2017 03:58 PM'
    }, {
        'UniqueId': 'T100400',
        'DateTime': 1501127460000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/27/2017 09:21 AM'
    }, {
        'UniqueId': 'T100401',
        'DateTime': 1501147140000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/27/2017 02:49 PM'
    }, {
        'UniqueId': 'T100402',
        'DateTime': 1501166700000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/27/2017 08:15 PM'
    }, {
        'UniqueId': 'T100403',
        'DateTime': 1501131540000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/27/2017 10:29 AM'
    }, {
        'UniqueId': 'T100404',
        'DateTime': 1501148700000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/27/2017 03:15 PM'
    }, {
        'UniqueId': 'T100405',
        'DateTime': 1501159980000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '70',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/27/2017 06:23 PM'
    }, {
        'UniqueId': 'T100406',
        'DateTime': 1501216020000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/28/2017 09:57 AM'
    }, {
        'UniqueId': 'T100407',
        'DateTime': 1501232640000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/28/2017 02:34 PM'
    }, {
        'UniqueId': 'T100408',
        'DateTime': 1501253760000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/28/2017 08:26 PM'
    }, {
        'UniqueId': 'T100409',
        'DateTime': 1501218360000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/28/2017 10:36 AM'
    }, {
        'UniqueId': 'T100410',
        'DateTime': 1501237740000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/28/2017 03:59 PM'
    }, {
        'UniqueId': 'T100411',
        'DateTime': 1501215600000,
        'Category': 'Bills',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Telephone services',
        'Amount': '180',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/28/2017 09:50 AM'
    }, {
        'UniqueId': 'T100412',
        'DateTime': 1501213200000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Own Buisness',
        'Amount': '180',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/28/2017 09:10 AM'
    }, {
        'UniqueId': 'T100413',
        'DateTime': 1501301340000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/29/2017 09:39 AM'
    }, {
        'UniqueId': 'T100414',
        'DateTime': 1501319880000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/29/2017 02:48 PM'
    }, {
        'UniqueId': 'T100415',
        'DateTime': 1501340580000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/29/2017 08:33 PM'
    }, {
        'UniqueId': 'T100416',
        'DateTime': 1501304220000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/29/2017 10:27 AM'
    }, {
        'UniqueId': 'T100417',
        'DateTime': 1501321980000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/29/2017 03:23 PM'
    }, {
        'UniqueId': 'T100418',
        'DateTime': 1501322220000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '50',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/29/2017 03:27 PM'
    }, {
        'UniqueId': 'T100419',
        'DateTime': 1501322220000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema, International Release',
        'Amount': '4',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/29/2017 03:27 PM'
    }, {
        'UniqueId': 'T100420',
        'DateTime': 1501387380000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/30/2017 09:33 AM'
    }, {
        'UniqueId': 'T100421',
        'DateTime': 1501405680000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '11',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/30/2017 02:38 PM'
    }, {
        'UniqueId': 'T100422',
        'DateTime': 1501427760000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '7',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/30/2017 08:46 PM'
    }, {
        'UniqueId': 'T100423',
        'DateTime': 1501391340000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/30/2017 10:39 AM'
    }, {
        'UniqueId': 'T100424',
        'DateTime': 1501409640000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '9',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/30/2017 03:44 PM'
    }, {
        'UniqueId': 'T100425',
        'DateTime': 1501388820000,
        'Category': 'Clothing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '35',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/30/2017 09:57 AM'
    }, {
        'UniqueId': 'T100426',
        'DateTime': 1501386420000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other household products',
        'Amount': '30',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/30/2017 09:17 AM'
    }, {
        'UniqueId': 'T100427',
        'DateTime': 1501386960000,
        'Category': 'Education',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Education',
        'Amount': '50',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/30/2017 09:26 AM'
    }, {
        'UniqueId': 'T100428',
        'DateTime': 1501386060000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '80',
        'MonthShort': 'Jul',
        'MonthFull': 'July, 2017',
        'FormattedDate': '07/30/2017 09:11 AM'
    }, {
        'UniqueId': 'T100429',
        'DateTime': 1501559400000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/01/2017 09:20 AM'
    }, {
        'UniqueId': 'T100430',
        'DateTime': 1501579680000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/01/2017 02:58 PM'
    }, {
        'UniqueId': 'T100431',
        'DateTime': 1501599840000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/01/2017 08:34 PM'
    }, {
        'UniqueId': 'T100432',
        'DateTime': 1501563840000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/01/2017 10:34 AM'
    }, {
        'UniqueId': 'T100433',
        'DateTime': 1501582680000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/01/2017 03:48 PM'
    }, {
        'UniqueId': 'T100434',
        'DateTime': 1501576140000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '60',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/01/2017 01:59 PM'
    }, {
        'UniqueId': 'T100435',
        'DateTime': 1501573560000,
        'Category': 'Mortgage / Rent',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Monthly rent',
        'Amount': '1000',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/01/2017 01:16 PM'
    }, {
        'UniqueId': 'T100436',
        'DateTime': 1501573860000,
        'Category': 'Salary',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Monthly Net Salary',
        'Amount': '5000',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/01/2017 01:21 PM'
    }, {
        'UniqueId': 'T100437',
        'DateTime': 1501645620000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/02/2017 09:17 AM'
    }, {
        'UniqueId': 'T100438',
        'DateTime': 1501665600000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/02/2017 02:50 PM'
    }, {
        'UniqueId': 'T100439',
        'DateTime': 1501685760000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/02/2017 08:26 PM'
    }, {
        'UniqueId': 'T100440',
        'DateTime': 1501650120000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/02/2017 10:32 AM'
    }, {
        'UniqueId': 'T100441',
        'DateTime': 1501669680000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/02/2017 03:58 PM'
    }, {
        'UniqueId': 'T100442',
        'DateTime': 1501651380000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Summer Dress in Store',
        'Amount': '35',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/02/2017 10:53 AM'
    }, {
        'UniqueId': 'T100443',
        'DateTime': 1501649160000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Major appliances, housewares',
        'Amount': '15',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/02/2017 10:16 AM'
    }, {
        'UniqueId': 'T100444',
        'DateTime': 1501732320000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/03/2017 09:22 AM'
    }, {
        'UniqueId': 'T100445',
        'DateTime': 1501749900000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/03/2017 02:15 PM'
    }, {
        'UniqueId': 'T100446',
        'DateTime': 1501772760000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/03/2017 08:36 PM'
    }, {
        'UniqueId': 'T100447',
        'DateTime': 1501737240000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/03/2017 10:44 AM'
    }, {
        'UniqueId': 'T100448',
        'DateTime': 1501753440000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/03/2017 03:14 PM'
    }, {
        'UniqueId': 'T100449',
        'DateTime': 1501755420000,
        'Category': 'Utilities',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '140',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/03/2017 03:47 PM'
    }, {
        'UniqueId': 'T100450',
        'DateTime': 1501756080000,
        'Category': 'Bills',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Electricity bill',
        'Amount': '200',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/03/2017 03:58 PM'
    }, {
        'UniqueId': 'T100451',
        'DateTime': 1501820100000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/04/2017 09:45 AM'
    }, {
        'UniqueId': 'T100452',
        'DateTime': 1501836060000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/04/2017 02:11 PM'
    }, {
        'UniqueId': 'T100453',
        'DateTime': 1501858260000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/04/2017 08:21 PM'
    }, {
        'UniqueId': 'T100454',
        'DateTime': 1501821840000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/04/2017 10:14 AM'
    }, {
        'UniqueId': 'T100455',
        'DateTime': 1501839720000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/04/2017 03:12 PM'
    }, {
        'UniqueId': 'T100456',
        'DateTime': 1501860360000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '60',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/04/2017 08:56 PM'
    }, {
        'UniqueId': 'T100457',
        'DateTime': 1501857840000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Short visit to private Doctor',
        'Amount': '15',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/04/2017 08:14 PM'
    }, {
        'UniqueId': 'T100458',
        'DateTime': 1501858560000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from casino',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/04/2017 08:26 PM'
    }, {
        'UniqueId': 'T100459',
        'DateTime': 1501906200000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/05/2017 09:40 AM'
    }, {
        'UniqueId': 'T100460',
        'DateTime': 1501924560000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/05/2017 02:46 PM'
    }, {
        'UniqueId': 'T100461',
        'DateTime': 1501946460000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/05/2017 08:51 PM'
    }, {
        'UniqueId': 'T100462',
        'DateTime': 1501908420000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/05/2017 10:17 AM'
    }, {
        'UniqueId': 'T100463',
        'DateTime': 1501926780000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/05/2017 03:23 PM'
    }, {
        'UniqueId': 'T100464',
        'DateTime': 1501908540000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance, repairs',
        'Amount': '15',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/05/2017 10:19 AM'
    }, {
        'UniqueId': 'T100465',
        'DateTime': 1501908780000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fitness Club',
        'Amount': '2',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/05/2017 10:23 AM'
    }, {
        'UniqueId': 'T100466',
        'DateTime': 1501993380000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/06/2017 09:53 AM'
    }, {
        'UniqueId': 'T100467',
        'DateTime': 1502010180000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/06/2017 02:33 PM'
    }, {
        'UniqueId': 'T100468',
        'DateTime': 1502030940000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/06/2017 08:19 PM'
    }, {
        'UniqueId': 'T100469',
        'DateTime': 1501995900000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/06/2017 10:35 AM'
    }, {
        'UniqueId': 'T100470',
        'DateTime': 1502014500000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/06/2017 03:45 PM'
    }, {
        'UniqueId': 'T100471',
        'DateTime': 1502002680000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '60',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/06/2017 12:28 PM'
    }, {
        'UniqueId': 'T100472',
        'DateTime': 1502002140000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Gits',
        'Amount': '120',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/06/2017 12:19 PM'
    }, {
        'UniqueId': 'T100473',
        'DateTime': 1502078640000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/07/2017 09:34 AM'
    }, {
        'UniqueId': 'T100474',
        'DateTime': 1502097720000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/07/2017 02:52 PM'
    }, {
        'UniqueId': 'T100475',
        'DateTime': 1502116920000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/07/2017 08:12 PM'
    }, {
        'UniqueId': 'T100476',
        'DateTime': 1502082000000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/07/2017 10:30 AM'
    }, {
        'UniqueId': 'T100477',
        'DateTime': 1502098980000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/07/2017 03:13 PM'
    }, {
        'UniqueId': 'T100478',
        'DateTime': 1502101500000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cappuccino (regular)',
        'Amount': '5',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/07/2017 03:55 PM'
    }, {
        'UniqueId': 'T100479',
        'DateTime': 1502165400000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/08/2017 09:40 AM'
    }, {
        'UniqueId': 'T100480',
        'DateTime': 1502181900000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/08/2017 02:15 PM'
    }, {
        'UniqueId': 'T100481',
        'DateTime': 1502204580000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/08/2017 08:33 PM'
    }, {
        'UniqueId': 'T100482',
        'DateTime': 1502169360000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/08/2017 10:46 AM'
    }, {
        'UniqueId': 'T100483',
        'DateTime': 1502185380000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/08/2017 03:13 PM'
    }, {
        'UniqueId': 'T100484',
        'DateTime': 1502204340000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '60',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/08/2017 08:29 PM'
    }, {
        'UniqueId': 'T100485',
        'DateTime': 1502205840000,
        'Category': 'Interests',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Interests',
        'Amount': '100',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/08/2017 08:54 PM'
    }, {
        'UniqueId': 'T100486',
        'DateTime': 1502250900000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/09/2017 09:25 AM'
    }, {
        'UniqueId': 'T100487',
        'DateTime': 1502270100000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/09/2017 02:45 PM'
    }, {
        'UniqueId': 'T100488',
        'DateTime': 1502291040000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/09/2017 08:34 PM'
    }, {
        'UniqueId': 'T100489',
        'DateTime': 1502253720000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/09/2017 10:12 AM'
    }, {
        'UniqueId': 'T100490',
        'DateTime': 1502274420000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/09/2017 03:57 PM'
    }, {
        'UniqueId': 'T100491',
        'DateTime': 1502292120000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Summer Dress in Store',
        'Amount': '45',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/09/2017 08:52 PM'
    }, {
        'UniqueId': 'T100492',
        'DateTime': 1502290140000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Major appliances, housewares',
        'Amount': '30',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/09/2017 08:19 PM'
    }, {
        'UniqueId': 'T100493',
        'DateTime': 1502336580000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/10/2017 09:13 AM'
    }, {
        'UniqueId': 'T100494',
        'DateTime': 1502356620000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/10/2017 02:47 PM'
    }, {
        'UniqueId': 'T100495',
        'DateTime': 1502376360000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/10/2017 08:16 PM'
    }, {
        'UniqueId': 'T100496',
        'DateTime': 1502340180000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/10/2017 10:13 AM'
    }, {
        'UniqueId': 'T100497',
        'DateTime': 1502360640000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/10/2017 03:54 PM'
    }, {
        'UniqueId': 'T100498',
        'DateTime': 1502349360000,
        'Category': 'Utilities',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '140',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/10/2017 12:46 PM'
    }, {
        'UniqueId': 'T100499',
        'DateTime': 1502348940000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Fitness Club',
        'Amount': '4',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/10/2017 12:39 PM'
    }, {
        'UniqueId': 'T100500',
        'DateTime': 1502425680000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/11/2017 09:58 AM'
    }, {
        'UniqueId': 'T100501',
        'DateTime': 1502443620000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/11/2017 02:57 PM'
    }, {
        'UniqueId': 'T100502',
        'DateTime': 1502464140000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/11/2017 08:39 PM'
    }, {
        'UniqueId': 'T100503',
        'DateTime': 1502427420000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/11/2017 10:27 AM'
    }, {
        'UniqueId': 'T100504',
        'DateTime': 1502444940000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/11/2017 03:19 PM'
    }, {
        'UniqueId': 'T100505',
        'DateTime': 1502442480000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '50',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/11/2017 02:38 PM'
    }, {
        'UniqueId': 'T100506',
        'DateTime': 1502441100000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Deodorant, roll-on',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/11/2017 02:15 PM'
    }, {
        'UniqueId': 'T100507',
        'DateTime': 1502441400000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Interest from Deposit',
        'Amount': '100',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/11/2017 02:20 PM'
    }, {
        'UniqueId': 'T100508',
        'DateTime': 1502510520000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/12/2017 09:32 AM'
    }, {
        'UniqueId': 'T100509',
        'DateTime': 1502527440000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/12/2017 02:14 PM'
    }, {
        'UniqueId': 'T100510',
        'DateTime': 1502550120000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/12/2017 08:32 PM'
    }, {
        'UniqueId': 'T100511',
        'DateTime': 1502512860000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/12/2017 10:11 AM'
    }, {
        'UniqueId': 'T100512',
        'DateTime': 1502532840000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/12/2017 03:44 PM'
    }, {
        'UniqueId': 'T100513',
        'DateTime': 1502525520000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '15',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/12/2017 01:42 PM'
    }, {
        'UniqueId': 'T100514',
        'DateTime': 1502524260000,
        'Category': 'Insurance',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle insurance',
        'Amount': '230',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/12/2017 01:21 PM'
    }, {
        'UniqueId': 'T100515',
        'DateTime': 1502596560000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/13/2017 09:26 AM'
    }, {
        'UniqueId': 'T100516',
        'DateTime': 1502616180000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/13/2017 02:53 PM'
    }, {
        'UniqueId': 'T100517',
        'DateTime': 1502636700000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/13/2017 08:35 PM'
    }, {
        'UniqueId': 'T100518',
        'DateTime': 1502599500000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/13/2017 10:15 AM'
    }, {
        'UniqueId': 'T100519',
        'DateTime': 1502619600000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/13/2017 03:50 PM'
    }, {
        'UniqueId': 'T100520',
        'DateTime': 1502598180000,
        'Category': 'Health Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '80',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/13/2017 09:53 AM'
    }, {
        'UniqueId': 'T100521',
        'DateTime': 1502596260000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Internet use',
        'Amount': '2',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/13/2017 09:21 AM'
    }, {
        'UniqueId': 'T100522',
        'DateTime': 1502596140000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Sale',
        'Amount': '120',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/13/2017 09:19 AM'
    }, {
        'UniqueId': 'T100523',
        'DateTime': 1502682600000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/14/2017 09:20 AM'
    }, {
        'UniqueId': 'T100524',
        'DateTime': 1502701680000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/14/2017 02:38 PM'
    }, {
        'UniqueId': 'T100525',
        'DateTime': 1502723700000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/14/2017 08:45 PM'
    }, {
        'UniqueId': 'T100526',
        'DateTime': 1502687160000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/14/2017 10:36 AM'
    }, {
        'UniqueId': 'T100527',
        'DateTime': 1502705820000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/14/2017 03:47 PM'
    }, {
        'UniqueId': 'T100528',
        'DateTime': 1502724540000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Own Buisness',
        'Amount': '20',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/14/2017 08:59 PM'
    }, {
        'UniqueId': 'T100529',
        'DateTime': 1502771100000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/15/2017 09:55 AM'
    }, {
        'UniqueId': 'T100530',
        'DateTime': 1502787660000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/15/2017 02:31 PM'
    }, {
        'UniqueId': 'T100531',
        'DateTime': 1502809620000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/15/2017 08:37 PM'
    }, {
        'UniqueId': 'T100532',
        'DateTime': 1502774580000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/15/2017 10:53 AM'
    }, {
        'UniqueId': 'T100533',
        'DateTime': 1502792820000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/15/2017 03:57 PM'
    }, {
        'UniqueId': 'T100534',
        'DateTime': 1502777520000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '65',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/15/2017 11:42 AM'
    }, {
        'UniqueId': 'T100535',
        'DateTime': 1502776920000,
        'Category': 'Bills',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Internet bills',
        'Amount': '200',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/15/2017 11:32 AM'
    }, {
        'UniqueId': 'T100536',
        'DateTime': 1502776260000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '100',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/15/2017 11:21 AM'
    }, {
        'UniqueId': 'T100537',
        'DateTime': 1502856960000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/16/2017 09:46 AM'
    }, {
        'UniqueId': 'T100538',
        'DateTime': 1502873520000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/16/2017 02:22 PM'
    }, {
        'UniqueId': 'T100539',
        'DateTime': 1502896980000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/16/2017 08:53 PM'
    }, {
        'UniqueId': 'T100540',
        'DateTime': 1502859840000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/16/2017 10:34 AM'
    }, {
        'UniqueId': 'T100541',
        'DateTime': 1502879160000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/16/2017 03:56 PM'
    }, {
        'UniqueId': 'T100542',
        'DateTime': 1502856420000,
        'Category': 'Clothing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '35',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/16/2017 09:37 AM'
    }, {
        'UniqueId': 'T100543',
        'DateTime': 1502856900000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Housekeeping supplies',
        'Amount': '25',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/16/2017 09:45 AM'
    }, {
        'UniqueId': 'T100544',
        'DateTime': 1502857140000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cinema',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/16/2017 09:49 AM'
    }, {
        'UniqueId': 'T100545',
        'DateTime': 1502943780000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/17/2017 09:53 AM'
    }, {
        'UniqueId': 'T100546',
        'DateTime': 1502959200000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/17/2017 02:10 PM'
    }, {
        'UniqueId': 'T100547',
        'DateTime': 1502982300000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/17/2017 08:35 PM'
    }, {
        'UniqueId': 'T100548',
        'DateTime': 1502947740000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/17/2017 10:59 AM'
    }, {
        'UniqueId': 'T100549',
        'DateTime': 1502963400000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/17/2017 03:20 PM'
    }, {
        'UniqueId': 'T100550',
        'DateTime': 1502958240000,
        'Category': 'Utilities',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '130',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/17/2017 01:54 PM'
    }, {
        'UniqueId': 'T100551',
        'DateTime': 1503029100000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/18/2017 09:35 AM'
    }, {
        'UniqueId': 'T100552',
        'DateTime': 1503048300000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/18/2017 02:55 PM'
    }, {
        'UniqueId': 'T100553',
        'DateTime': 1503067440000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/18/2017 08:14 PM'
    }, {
        'UniqueId': 'T100554',
        'DateTime': 1503031920000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/18/2017 10:22 AM'
    }, {
        'UniqueId': 'T100555',
        'DateTime': 1503049980000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/18/2017 03:23 PM'
    }, {
        'UniqueId': 'T100556',
        'DateTime': 1503047520000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '55',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/18/2017 02:42 PM'
    }, {
        'UniqueId': 'T100557',
        'DateTime': 1503047460000,
        'Category': 'Personal Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': '1 box of antibiotics (12 doses)',
        'Amount': '15',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/18/2017 02:41 PM'
    }, {
        'UniqueId': 'T100558',
        'DateTime': 1503114840000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/19/2017 09:24 AM'
    }, {
        'UniqueId': 'T100559',
        'DateTime': 1503132480000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/19/2017 02:18 PM'
    }, {
        'UniqueId': 'T100560',
        'DateTime': 1503156300000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/19/2017 08:55 PM'
    }, {
        'UniqueId': 'T100561',
        'DateTime': 1503118020000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/19/2017 10:17 AM'
    }, {
        'UniqueId': 'T100562',
        'DateTime': 1503137940000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/19/2017 03:49 PM'
    }, {
        'UniqueId': 'T100563',
        'DateTime': 1503147840000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Major appliances, housewares',
        'Amount': '20',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/19/2017 06:34 PM'
    }, {
        'UniqueId': 'T100564',
        'DateTime': 1503146520000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Taxi 1hour Waiting',
        'Amount': '5',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/19/2017 06:12 PM'
    }, {
        'UniqueId': 'T100565',
        'DateTime': 1503147360000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from casino',
        'Amount': '120',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/19/2017 06:26 PM'
    }, {
        'UniqueId': 'T100566',
        'DateTime': 1503203280000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/20/2017 09:58 AM'
    }, {
        'UniqueId': 'T100567',
        'DateTime': 1503218760000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/20/2017 02:16 PM'
    }, {
        'UniqueId': 'T100568',
        'DateTime': 1503242640000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/20/2017 08:54 PM'
    }, {
        'UniqueId': 'T100569',
        'DateTime': 1503204120000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/20/2017 10:12 AM'
    }, {
        'UniqueId': 'T100570',
        'DateTime': 1503224040000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/20/2017 03:44 PM'
    }, {
        'UniqueId': 'T100571',
        'DateTime': 1503234780000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '80',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/20/2017 06:43 PM'
    }, {
        'UniqueId': 'T100572',
        'DateTime': 1503287280000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/21/2017 09:18 AM'
    }, {
        'UniqueId': 'T100573',
        'DateTime': 1503306840000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/21/2017 02:44 PM'
    }, {
        'UniqueId': 'T100574',
        'DateTime': 1503329100000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/21/2017 08:55 PM'
    }, {
        'UniqueId': 'T100575',
        'DateTime': 1503290640000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/21/2017 10:14 AM'
    }, {
        'UniqueId': 'T100576',
        'DateTime': 1503309360000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/21/2017 03:26 PM'
    }, {
        'UniqueId': 'T100577',
        'DateTime': 1503376020000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/22/2017 09:57 AM'
    }, {
        'UniqueId': 'T100578',
        'DateTime': 1503392880000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/22/2017 02:38 PM'
    }, {
        'UniqueId': 'T100579',
        'DateTime': 1503415680000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/22/2017 08:58 PM'
    }, {
        'UniqueId': 'T100580',
        'DateTime': 1503378720000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/22/2017 10:42 AM'
    }, {
        'UniqueId': 'T100581',
        'DateTime': 1503395040000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/22/2017 03:14 PM'
    }, {
        'UniqueId': 'T100582',
        'DateTime': 1503398640000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '55',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/22/2017 04:14 PM'
    }, {
        'UniqueId': 'T100583',
        'DateTime': 1503398520000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Sale',
        'Amount': '110',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/22/2017 04:12 PM'
    }, {
        'UniqueId': 'T100584',
        'DateTime': 1503461820000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/23/2017 09:47 AM'
    }, {
        'UniqueId': 'T100585',
        'DateTime': 1503478620000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/23/2017 02:27 PM'
    }, {
        'UniqueId': 'T100586',
        'DateTime': 1503500880000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/23/2017 08:38 PM'
    }, {
        'UniqueId': 'T100587',
        'DateTime': 1503464160000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/23/2017 10:26 AM'
    }, {
        'UniqueId': 'T100588',
        'DateTime': 1503483960000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/23/2017 03:56 PM'
    }, {
        'UniqueId': 'T100589',
        'DateTime': 1503477960000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '40',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/23/2017 02:16 PM'
    }, {
        'UniqueId': 'T100590',
        'DateTime': 1503478560000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other household products',
        'Amount': '25',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/23/2017 02:26 PM'
    }, {
        'UniqueId': 'T100591',
        'DateTime': 1503477840000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema, International Release',
        'Amount': '4',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/23/2017 02:14 PM'
    }, {
        'UniqueId': 'T100592',
        'DateTime': 1503546780000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/24/2017 09:23 AM'
    }, {
        'UniqueId': 'T100593',
        'DateTime': 1503566100000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/24/2017 02:45 PM'
    }, {
        'UniqueId': 'T100594',
        'DateTime': 1503587520000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/24/2017 08:42 PM'
    }, {
        'UniqueId': 'T100595',
        'DateTime': 1503552240000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/24/2017 10:54 AM'
    }, {
        'UniqueId': 'T100596',
        'DateTime': 1503568440000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/24/2017 03:24 PM'
    }, {
        'UniqueId': 'T100597',
        'DateTime': 1503571680000,
        'Category': 'Utilities',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '150',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/24/2017 04:18 PM'
    }, {
        'UniqueId': 'T100598',
        'DateTime': 1503571920000,
        'Category': 'Insurance',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Life and other personal insurance',
        'Amount': '250',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/24/2017 04:22 PM'
    }, {
        'UniqueId': 'T100599',
        'DateTime': 1503634680000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/25/2017 09:48 AM'
    }, {
        'UniqueId': 'T100600',
        'DateTime': 1503651000000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/25/2017 02:20 PM'
    }, {
        'UniqueId': 'T100601',
        'DateTime': 1503672180000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/25/2017 08:13 PM'
    }, {
        'UniqueId': 'T100602',
        'DateTime': 1503638880000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/25/2017 10:58 AM'
    }, {
        'UniqueId': 'T100603',
        'DateTime': 1503656160000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/25/2017 03:46 PM'
    }, {
        'UniqueId': 'T100604',
        'DateTime': 1503669960000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '65',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/25/2017 07:36 PM'
    }, {
        'UniqueId': 'T100605',
        'DateTime': 1503669360000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Short visit to private Doctor',
        'Amount': '20',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/25/2017 07:26 PM'
    }, {
        'UniqueId': 'T100606',
        'DateTime': 1503668760000,
        'Category': 'Tax',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Licenses and occupational taxes',
        'Amount': '180',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/25/2017 07:16 PM'
    }, {
        'UniqueId': 'T100607',
        'DateTime': 1503719040000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/26/2017 09:14 AM'
    }, {
        'UniqueId': 'T100608',
        'DateTime': 1503737880000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/26/2017 02:28 PM'
    }, {
        'UniqueId': 'T100609',
        'DateTime': 1503758400000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/26/2017 08:10 PM'
    }, {
        'UniqueId': 'T100610',
        'DateTime': 1503723480000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/26/2017 10:28 AM'
    }, {
        'UniqueId': 'T100611',
        'DateTime': 1503742440000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/26/2017 03:44 PM'
    }, {
        'UniqueId': 'T100612',
        'DateTime': 1503756540000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Laundry and cleaning supplies',
        'Amount': '20',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/26/2017 07:39 PM'
    }, {
        'UniqueId': 'T100613',
        'DateTime': 1503756720000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Sale',
        'Amount': '90',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/26/2017 07:42 PM'
    }, {
        'UniqueId': 'T100614',
        'DateTime': 1503805920000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/27/2017 09:22 AM'
    }, {
        'UniqueId': 'T100615',
        'DateTime': 1503824280000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/27/2017 02:28 PM'
    }, {
        'UniqueId': 'T100616',
        'DateTime': 1503845940000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '12',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/27/2017 08:29 PM'
    }, {
        'UniqueId': 'T100617',
        'DateTime': 1503810840000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/27/2017 10:44 AM'
    }, {
        'UniqueId': 'T100618',
        'DateTime': 1503828900000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/27/2017 03:45 PM'
    }, {
        'UniqueId': 'T100619',
        'DateTime': 1503836400000,
        'Category': 'Health Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '80',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/27/2017 05:50 PM'
    }, {
        'UniqueId': 'T100620',
        'DateTime': 1503891660000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/28/2017 09:11 AM'
    }, {
        'UniqueId': 'T100621',
        'DateTime': 1503911160000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/28/2017 02:36 PM'
    }, {
        'UniqueId': 'T100622',
        'DateTime': 1503933720000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/28/2017 08:52 PM'
    }, {
        'UniqueId': 'T100623',
        'DateTime': 1503896280000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/28/2017 10:28 AM'
    }, {
        'UniqueId': 'T100624',
        'DateTime': 1503915120000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/28/2017 03:42 PM'
    }, {
        'UniqueId': 'T100625',
        'DateTime': 1503898140000,
        'Category': 'Bills',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cellular phone service',
        'Amount': '220',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/28/2017 10:59 AM'
    }, {
        'UniqueId': 'T100626',
        'DateTime': 1503896580000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Own Buisness',
        'Amount': '110',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/28/2017 10:33 AM'
    }, {
        'UniqueId': 'T100627',
        'DateTime': 1503979440000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '10',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/29/2017 09:34 AM'
    }, {
        'UniqueId': 'T100628',
        'DateTime': 1503997860000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/29/2017 02:41 PM'
    }, {
        'UniqueId': 'T100629',
        'DateTime': 1504019280000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '7',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/29/2017 08:38 PM'
    }, {
        'UniqueId': 'T100630',
        'DateTime': 1503981600000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/29/2017 10:10 AM'
    }, {
        'UniqueId': 'T100631',
        'DateTime': 1504000320000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/29/2017 03:22 PM'
    }, {
        'UniqueId': 'T100632',
        'DateTime': 1504003260000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '50',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/29/2017 04:11 PM'
    }, {
        'UniqueId': 'T100633',
        'DateTime': 1504003680000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fitness Club',
        'Amount': '3',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/29/2017 04:18 PM'
    }, {
        'UniqueId': 'T100634',
        'DateTime': 1504067160000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/30/2017 09:56 AM'
    }, {
        'UniqueId': 'T100635',
        'DateTime': 1504084680000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '6',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/30/2017 02:48 PM'
    }, {
        'UniqueId': 'T100636',
        'DateTime': 1504106280000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/30/2017 08:48 PM'
    }, {
        'UniqueId': 'T100637',
        'DateTime': 1504070700000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/30/2017 10:55 AM'
    }, {
        'UniqueId': 'T100638',
        'DateTime': 1504086540000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/30/2017 03:19 PM'
    }, {
        'UniqueId': 'T100639',
        'DateTime': 1504093740000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Summer Dress in Store',
        'Amount': '40',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/30/2017 05:19 PM'
    }, {
        'UniqueId': 'T100640',
        'DateTime': 1504095540000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Laundry and cleaning supplies',
        'Amount': '15',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/30/2017 05:49 PM'
    }, {
        'UniqueId': 'T100641',
        'DateTime': 1504095540000,
        'Category': 'Education',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Education',
        'Amount': '50',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/30/2017 05:49 PM'
    }, {
        'UniqueId': 'T100642',
        'DateTime': 1504095780000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from lottery',
        'Amount': '80',
        'MonthShort': 'Aug',
        'MonthFull': 'August, 2017',
        'FormattedDate': '08/30/2017 05:53 PM'
    }, {
        'UniqueId': 'T100643',
        'DateTime': 1504239060000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/01/2017 09:41 AM'
    }, {
        'UniqueId': 'T100644',
        'DateTime': 1504255620000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/01/2017 02:17 PM'
    }, {
        'UniqueId': 'T100645',
        'DateTime': 1504277340000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/01/2017 08:19 PM'
    }, {
        'UniqueId': 'T100646',
        'DateTime': 1504242720000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/01/2017 10:42 AM'
    }, {
        'UniqueId': 'T100647',
        'DateTime': 1504259340000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/01/2017 03:19 PM'
    }, {
        'UniqueId': 'T100648',
        'DateTime': 1504261200000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '55',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/01/2017 03:50 PM'
    }, {
        'UniqueId': 'T100649',
        'DateTime': 1504259640000,
        'Category': 'Mortgage / Rent',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Monthly rent',
        'Amount': '1000',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/01/2017 03:24 PM'
    }, {
        'UniqueId': 'T100650',
        'DateTime': 1504259700000,
        'Category': 'Salary',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Monthly Net Salary',
        'Amount': '6000',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/01/2017 03:25 PM'
    }, {
        'UniqueId': 'T100651',
        'DateTime': 1504326180000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/02/2017 09:53 AM'
    }, {
        'UniqueId': 'T100652',
        'DateTime': 1504341840000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/02/2017 02:14 PM'
    }, {
        'UniqueId': 'T100653',
        'DateTime': 1504365300000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/02/2017 08:45 PM'
    }, {
        'UniqueId': 'T100654',
        'DateTime': 1504328460000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/02/2017 10:31 AM'
    }, {
        'UniqueId': 'T100655',
        'DateTime': 1504347540000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/02/2017 03:49 PM'
    }, {
        'UniqueId': 'T100656',
        'DateTime': 1504356060000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '40',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/02/2017 06:11 PM'
    }, {
        'UniqueId': 'T100657',
        'DateTime': 1504357860000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Housekeeping supplies',
        'Amount': '20',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/02/2017 06:41 PM'
    }, {
        'UniqueId': 'T100658',
        'DateTime': 1504410240000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/03/2017 09:14 AM'
    }, {
        'UniqueId': 'T100659',
        'DateTime': 1504430100000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/03/2017 02:45 PM'
    }, {
        'UniqueId': 'T100660',
        'DateTime': 1504450980000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/03/2017 08:33 PM'
    }, {
        'UniqueId': 'T100661',
        'DateTime': 1504414500000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/03/2017 10:25 AM'
    }, {
        'UniqueId': 'T100662',
        'DateTime': 1504433340000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/03/2017 03:39 PM'
    }, {
        'UniqueId': 'T100663',
        'DateTime': 1504433700000,
        'Category': 'Utilities',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '190',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/03/2017 03:45 PM'
    }, {
        'UniqueId': 'T100664',
        'DateTime': 1504433760000,
        'Category': 'Bills',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Telephone services',
        'Amount': '220',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/03/2017 03:46 PM'
    }, {
        'UniqueId': 'T100665',
        'DateTime': 1504496880000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/04/2017 09:18 AM'
    }, {
        'UniqueId': 'T100666',
        'DateTime': 1504515060000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/04/2017 02:21 PM'
    }, {
        'UniqueId': 'T100667',
        'DateTime': 1504537740000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/04/2017 08:39 PM'
    }, {
        'UniqueId': 'T100668',
        'DateTime': 1504500780000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/04/2017 10:23 AM'
    }, {
        'UniqueId': 'T100669',
        'DateTime': 1504519320000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/04/2017 03:32 PM'
    }, {
        'UniqueId': 'T100670',
        'DateTime': 1504537200000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '50',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/04/2017 08:30 PM'
    }, {
        'UniqueId': 'T100671',
        'DateTime': 1504538280000,
        'Category': 'Personal Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Standard haircut',
        'Amount': '20',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/04/2017 08:48 PM'
    }, {
        'UniqueId': 'T100672',
        'DateTime': 1504538400000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Interest from Deposit',
        'Amount': '80',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/04/2017 08:50 PM'
    }, {
        'UniqueId': 'T100673',
        'DateTime': 1504583340000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/05/2017 09:19 AM'
    }, {
        'UniqueId': 'T100674',
        'DateTime': 1504601220000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/05/2017 02:17 PM'
    }, {
        'UniqueId': 'T100675',
        'DateTime': 1504624320000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/05/2017 08:42 PM'
    }, {
        'UniqueId': 'T100676',
        'DateTime': 1504588680000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/05/2017 10:48 AM'
    }, {
        'UniqueId': 'T100677',
        'DateTime': 1504607040000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/05/2017 03:54 PM'
    }, {
        'UniqueId': 'T100678',
        'DateTime': 1504584060000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Housekeeping supplies',
        'Amount': '20',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/05/2017 09:31 AM'
    }, {
        'UniqueId': 'T100679',
        'DateTime': 1504582980000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fitness Club',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/05/2017 09:13 AM'
    }, {
        'UniqueId': 'T100680',
        'DateTime': 1504672140000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/06/2017 09:59 AM'
    }, {
        'UniqueId': 'T100681',
        'DateTime': 1504689720000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/06/2017 02:52 PM'
    }, {
        'UniqueId': 'T100682',
        'DateTime': 1504710600000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/06/2017 08:40 PM'
    }, {
        'UniqueId': 'T100683',
        'DateTime': 1504674720000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/06/2017 10:42 AM'
    }, {
        'UniqueId': 'T100684',
        'DateTime': 1504692840000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/06/2017 03:44 PM'
    }, {
        'UniqueId': 'T100685',
        'DateTime': 1504689240000,
        'Category': 'Health Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '80',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/06/2017 02:44 PM'
    }, {
        'UniqueId': 'T100686',
        'DateTime': 1504687440000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '100',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/06/2017 02:14 PM'
    }, {
        'UniqueId': 'T100687',
        'DateTime': 1504758000000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/07/2017 09:50 AM'
    }, {
        'UniqueId': 'T100688',
        'DateTime': 1504773960000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/07/2017 02:16 PM'
    }, {
        'UniqueId': 'T100689',
        'DateTime': 1504796760000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/07/2017 08:36 PM'
    }, {
        'UniqueId': 'T100690',
        'DateTime': 1504759380000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/07/2017 10:13 AM'
    }, {
        'UniqueId': 'T100691',
        'DateTime': 1504779720000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/07/2017 03:52 PM'
    }, {
        'UniqueId': 'T100692',
        'DateTime': 1504797900000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Tennis Court Rent',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/07/2017 08:55 PM'
    }, {
        'UniqueId': 'T100693',
        'DateTime': 1504843980000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/08/2017 09:43 AM'
    }, {
        'UniqueId': 'T100694',
        'DateTime': 1504860240000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/08/2017 02:14 PM'
    }, {
        'UniqueId': 'T100695',
        'DateTime': 1504883640000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/08/2017 08:44 PM'
    }, {
        'UniqueId': 'T100696',
        'DateTime': 1504847880000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/08/2017 10:48 AM'
    }, {
        'UniqueId': 'T100697',
        'DateTime': 1504864740000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/08/2017 03:29 PM'
    }, {
        'UniqueId': 'T100698',
        'DateTime': 1504862520000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '50',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/08/2017 02:52 PM'
    }, {
        'UniqueId': 'T100699',
        'DateTime': 1504862220000,
        'Category': 'Interests',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Interests',
        'Amount': '560',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/08/2017 02:47 PM'
    }, {
        'UniqueId': 'T100700',
        'DateTime': 1504931340000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/09/2017 09:59 AM'
    }, {
        'UniqueId': 'T100701',
        'DateTime': 1504947360000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/09/2017 02:26 PM'
    }, {
        'UniqueId': 'T100702',
        'DateTime': 1504970520000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/09/2017 08:52 PM'
    }, {
        'UniqueId': 'T100703',
        'DateTime': 1504932420000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/09/2017 10:17 AM'
    }, {
        'UniqueId': 'T100704',
        'DateTime': 1504951080000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/09/2017 03:28 PM'
    }, {
        'UniqueId': 'T100705',
        'DateTime': 1504943400000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Summer Dress in Store',
        'Amount': '35',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/09/2017 01:20 PM'
    }, {
        'UniqueId': 'T100706',
        'DateTime': 1504944960000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance, repairs',
        'Amount': '15',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/09/2017 01:46 PM'
    }, {
        'UniqueId': 'T100707',
        'DateTime': 1505017620000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/10/2017 09:57 AM'
    }, {
        'UniqueId': 'T100708',
        'DateTime': 1505034060000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/10/2017 02:31 PM'
    }, {
        'UniqueId': 'T100709',
        'DateTime': 1505056680000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/10/2017 08:48 PM'
    }, {
        'UniqueId': 'T100710',
        'DateTime': 1505020620000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/10/2017 10:47 AM'
    }, {
        'UniqueId': 'T100711',
        'DateTime': 1505038560000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/10/2017 03:46 PM'
    }, {
        'UniqueId': 'T100712',
        'DateTime': 1505056200000,
        'Category': 'Utilities',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '150',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/10/2017 08:40 PM'
    }, {
        'UniqueId': 'T100713',
        'DateTime': 1505056380000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema, International Release',
        'Amount': '205',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/10/2017 08:43 PM'
    }, {
        'UniqueId': 'T100714',
        'DateTime': 1505102220000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/11/2017 09:27 AM'
    }, {
        'UniqueId': 'T100715',
        'DateTime': 1505121480000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/11/2017 02:48 PM'
    }, {
        'UniqueId': 'T100716',
        'DateTime': 1505140980000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/11/2017 08:13 PM'
    }, {
        'UniqueId': 'T100717',
        'DateTime': 1505107680000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/11/2017 10:58 AM'
    }, {
        'UniqueId': 'T100718',
        'DateTime': 1505123940000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/11/2017 03:29 PM'
    }, {
        'UniqueId': 'T100719',
        'DateTime': 1505101260000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '50',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/11/2017 09:11 AM'
    }, {
        'UniqueId': 'T100720',
        'DateTime': 1505101800000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': '1 box of antibiotics (12 doses)',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/11/2017 09:20 AM'
    }, {
        'UniqueId': 'T100721',
        'DateTime': 1505102460000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Interest from Deposit',
        'Amount': '180',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/11/2017 09:31 AM'
    }, {
        'UniqueId': 'T100722',
        'DateTime': 1505188080000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/12/2017 09:18 AM'
    }, {
        'UniqueId': 'T100723',
        'DateTime': 1505207760000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/12/2017 02:46 PM'
    }, {
        'UniqueId': 'T100724',
        'DateTime': 1505228520000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/12/2017 08:32 PM'
    }, {
        'UniqueId': 'T100725',
        'DateTime': 1505191320000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/12/2017 10:12 AM'
    }, {
        'UniqueId': 'T100726',
        'DateTime': 1505210040000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/12/2017 03:24 PM'
    }, {
        'UniqueId': 'T100727',
        'DateTime': 1505200500000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Major appliances, housewares',
        'Amount': '25',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/12/2017 12:45 PM'
    }, {
        'UniqueId': 'T100728',
        'DateTime': 1505198880000,
        'Category': 'Insurance',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Personal insurance',
        'Amount': '260',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/12/2017 12:18 PM'
    }, {
        'UniqueId': 'T100729',
        'DateTime': 1505275860000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/13/2017 09:41 AM'
    }, {
        'UniqueId': 'T100730',
        'DateTime': 1505293200000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/13/2017 02:30 PM'
    }, {
        'UniqueId': 'T100731',
        'DateTime': 1505315940000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/13/2017 08:49 PM'
    }, {
        'UniqueId': 'T100732',
        'DateTime': 1505279580000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/13/2017 10:43 AM'
    }, {
        'UniqueId': 'T100733',
        'DateTime': 1505296320000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/13/2017 03:22 PM'
    }, {
        'UniqueId': 'T100734',
        'DateTime': 1505285580000,
        'Category': 'Health Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '80',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/13/2017 12:23 PM'
    }, {
        'UniqueId': 'T100735',
        'DateTime': 1505287440000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Monthly Pass (Regular Price)',
        'Amount': '3',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/13/2017 12:54 PM'
    }, {
        'UniqueId': 'T100736',
        'DateTime': 1505286060000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from lottery',
        'Amount': '180',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/13/2017 12:31 PM'
    }, {
        'UniqueId': 'T100737',
        'DateTime': 1505361000000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/14/2017 09:20 AM'
    }, {
        'UniqueId': 'T100738',
        'DateTime': 1505380080000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/14/2017 02:38 PM'
    }, {
        'UniqueId': 'T100739',
        'DateTime': 1505402760000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/14/2017 08:56 PM'
    }, {
        'UniqueId': 'T100740',
        'DateTime': 1505366940000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/14/2017 10:59 AM'
    }, {
        'UniqueId': 'T100741',
        'DateTime': 1505384700000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/14/2017 03:55 PM'
    }, {
        'UniqueId': 'T100742',
        'DateTime': 1505364240000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Own Buisness',
        'Amount': '200',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/14/2017 10:14 AM'
    }, {
        'UniqueId': 'T100743',
        'DateTime': 1505448120000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/15/2017 09:32 AM'
    }, {
        'UniqueId': 'T100744',
        'DateTime': 1505465400000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/15/2017 02:20 PM'
    }, {
        'UniqueId': 'T100745',
        'DateTime': 1505486880000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/15/2017 08:18 PM'
    }, {
        'UniqueId': 'T100746',
        'DateTime': 1505453340000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/15/2017 10:59 AM'
    }, {
        'UniqueId': 'T100747',
        'DateTime': 1505469240000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/15/2017 03:24 PM'
    }, {
        'UniqueId': 'T100748',
        'DateTime': 1505466780000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '65',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/15/2017 02:43 PM'
    }, {
        'UniqueId': 'T100749',
        'DateTime': 1505467320000,
        'Category': 'Bills',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Telephone services',
        'Amount': '180',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/15/2017 02:52 PM'
    }, {
        'UniqueId': 'T100750',
        'DateTime': 1505467740000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Funds',
        'Amount': '80',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/15/2017 02:59 PM'
    }, {
        'UniqueId': 'T100751',
        'DateTime': 1505533680000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/16/2017 09:18 AM'
    }, {
        'UniqueId': 'T100752',
        'DateTime': 1505552760000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/16/2017 02:36 PM'
    }, {
        'UniqueId': 'T100753',
        'DateTime': 1505574900000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/16/2017 08:45 PM'
    }, {
        'UniqueId': 'T100754',
        'DateTime': 1505538600000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/16/2017 10:40 AM'
    }, {
        'UniqueId': 'T100755',
        'DateTime': 1505555820000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/16/2017 03:27 PM'
    }, {
        'UniqueId': 'T100756',
        'DateTime': 1505534100000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '40',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/16/2017 09:25 AM'
    }, {
        'UniqueId': 'T100757',
        'DateTime': 1505535060000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Housekeeping supplies',
        'Amount': '20',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/16/2017 09:41 AM'
    }, {
        'UniqueId': 'T100758',
        'DateTime': 1505533680000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gym payment',
        'Amount': '2',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/16/2017 09:18 AM'
    }, {
        'UniqueId': 'T100759',
        'DateTime': 1505622360000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/17/2017 09:56 AM'
    }, {
        'UniqueId': 'T100760',
        'DateTime': 1505638740000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/17/2017 02:29 PM'
    }, {
        'UniqueId': 'T100761',
        'DateTime': 1505660220000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/17/2017 08:27 PM'
    }, {
        'UniqueId': 'T100762',
        'DateTime': 1505624940000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/17/2017 10:39 AM'
    }, {
        'UniqueId': 'T100763',
        'DateTime': 1505642700000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/17/2017 03:35 PM'
    }, {
        'UniqueId': 'T100764',
        'DateTime': 1505656020000,
        'Category': 'Utilities',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '160',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/17/2017 07:17 PM'
    }, {
        'UniqueId': 'T100765',
        'DateTime': 1505707620000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/18/2017 09:37 AM'
    }, {
        'UniqueId': 'T100766',
        'DateTime': 1505725260000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/18/2017 02:31 PM'
    }, {
        'UniqueId': 'T100767',
        'DateTime': 1505745780000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/18/2017 08:13 PM'
    }, {
        'UniqueId': 'T100768',
        'DateTime': 1505709600000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/18/2017 10:10 AM'
    }, {
        'UniqueId': 'T100769',
        'DateTime': 1505727600000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/18/2017 03:10 PM'
    }, {
        'UniqueId': 'T100770',
        'DateTime': 1505745900000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '65',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/18/2017 08:15 PM'
    }, {
        'UniqueId': 'T100771',
        'DateTime': 1505748120000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Standard haircut',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/18/2017 08:52 PM'
    }, {
        'UniqueId': 'T100772',
        'DateTime': 1505794500000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/19/2017 09:45 AM'
    }, {
        'UniqueId': 'T100773',
        'DateTime': 1505811240000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/19/2017 02:24 PM'
    }, {
        'UniqueId': 'T100774',
        'DateTime': 1505833200000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/19/2017 08:30 PM'
    }, {
        'UniqueId': 'T100775',
        'DateTime': 1505798220000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/19/2017 10:47 AM'
    }, {
        'UniqueId': 'T100776',
        'DateTime': 1505814600000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/19/2017 03:20 PM'
    }, {
        'UniqueId': 'T100777',
        'DateTime': 1505833260000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance, repairs',
        'Amount': '30',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/19/2017 08:31 PM'
    }, {
        'UniqueId': 'T100778',
        'DateTime': 1505832300000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/19/2017 08:15 PM'
    }, {
        'UniqueId': 'T100779',
        'DateTime': 1505832480000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '110',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/19/2017 08:18 PM'
    }, {
        'UniqueId': 'T100780',
        'DateTime': 1505880780000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/20/2017 09:43 AM'
    }, {
        'UniqueId': 'T100781',
        'DateTime': 1505899620000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/20/2017 02:57 PM'
    }, {
        'UniqueId': 'T100782',
        'DateTime': 1505920440000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/20/2017 08:44 PM'
    }, {
        'UniqueId': 'T100783',
        'DateTime': 1505884320000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/20/2017 10:42 AM'
    }, {
        'UniqueId': 'T100784',
        'DateTime': 1505903160000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/20/2017 03:56 PM'
    }, {
        'UniqueId': 'T100785',
        'DateTime': 1505886660000,
        'Category': 'Health Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '90',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/20/2017 11:21 AM'
    }, {
        'UniqueId': 'T100786',
        'DateTime': 1505965320000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/21/2017 09:12 AM'
    }, {
        'UniqueId': 'T100787',
        'DateTime': 1505985420000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/21/2017 02:47 PM'
    }, {
        'UniqueId': 'T100788',
        'DateTime': 1506006240000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/21/2017 08:34 PM'
    }, {
        'UniqueId': 'T100789',
        'DateTime': 1505971680000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/21/2017 10:58 AM'
    }, {
        'UniqueId': 'T100790',
        'DateTime': 1505988300000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/21/2017 03:35 PM'
    }, {
        'UniqueId': 'T100791',
        'DateTime': 1506054540000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/22/2017 09:59 AM'
    }, {
        'UniqueId': 'T100792',
        'DateTime': 1506071880000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/22/2017 02:48 PM'
    }, {
        'UniqueId': 'T100793',
        'DateTime': 1506093960000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/22/2017 08:56 PM'
    }, {
        'UniqueId': 'T100794',
        'DateTime': 1506055260000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/22/2017 10:11 AM'
    }, {
        'UniqueId': 'T100795',
        'DateTime': 1506075600000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/22/2017 03:50 PM'
    }, {
        'UniqueId': 'T100796',
        'DateTime': 1506075540000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '50',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/22/2017 03:49 PM'
    }, {
        'UniqueId': 'T100797',
        'DateTime': 1506075960000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Interest from Deposit',
        'Amount': '110',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/22/2017 03:56 PM'
    }, {
        'UniqueId': 'T100798',
        'DateTime': 1506139140000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/23/2017 09:29 AM'
    }, {
        'UniqueId': 'T100799',
        'DateTime': 1506156780000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/23/2017 02:23 PM'
    }, {
        'UniqueId': 'T100800',
        'DateTime': 1506180360000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/23/2017 08:56 PM'
    }, {
        'UniqueId': 'T100801',
        'DateTime': 1506144300000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/23/2017 10:55 AM'
    }, {
        'UniqueId': 'T100802',
        'DateTime': 1506161940000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/23/2017 03:49 PM'
    }, {
        'UniqueId': 'T100803',
        'DateTime': 1506169020000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '40',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/23/2017 05:47 PM'
    }, {
        'UniqueId': 'T100804',
        'DateTime': 1506168120000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance, repairs',
        'Amount': '20',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/23/2017 05:32 PM'
    }, {
        'UniqueId': 'T100805',
        'DateTime': 1506167220000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cappuccino (regular)',
        'Amount': '5',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/23/2017 05:17 PM'
    }, {
        'UniqueId': 'T100806',
        'DateTime': 1506227340000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/24/2017 09:59 AM'
    }, {
        'UniqueId': 'T100807',
        'DateTime': 1506243480000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '6',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/24/2017 02:28 PM'
    }, {
        'UniqueId': 'T100808',
        'DateTime': 1506264120000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/24/2017 08:12 PM'
    }, {
        'UniqueId': 'T100809',
        'DateTime': 1506229440000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/24/2017 10:34 AM'
    }, {
        'UniqueId': 'T100810',
        'DateTime': 1506248760000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/24/2017 03:56 PM'
    }, {
        'UniqueId': 'T100811',
        'DateTime': 1506251400000,
        'Category': 'Utilities',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '150',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/24/2017 04:40 PM'
    }, {
        'UniqueId': 'T100812',
        'DateTime': 1506251880000,
        'Category': 'Insurance',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle insurance',
        'Amount': '250',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/24/2017 04:48 PM'
    }, {
        'UniqueId': 'T100813',
        'DateTime': 1506311160000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/25/2017 09:16 AM'
    }, {
        'UniqueId': 'T100814',
        'DateTime': 1506330180000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/25/2017 02:33 PM'
    }, {
        'UniqueId': 'T100815',
        'DateTime': 1506350760000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/25/2017 08:16 PM'
    }, {
        'UniqueId': 'T100816',
        'DateTime': 1506314520000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/25/2017 10:12 AM'
    }, {
        'UniqueId': 'T100817',
        'DateTime': 1506334080000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/25/2017 03:38 PM'
    }, {
        'UniqueId': 'T100818',
        'DateTime': 1506323880000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '55',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/25/2017 12:48 PM'
    }, {
        'UniqueId': 'T100819',
        'DateTime': 1506322080000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': '1 box of antibiotics (12 doses)',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/25/2017 12:18 PM'
    }, {
        'UniqueId': 'T100820',
        'DateTime': 1506321780000,
        'Category': 'Tax',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Estate and gift taxes',
        'Amount': '1180',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/25/2017 12:13 PM'
    }, {
        'UniqueId': 'T100821',
        'DateTime': 1506398640000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/26/2017 09:34 AM'
    }, {
        'UniqueId': 'T100822',
        'DateTime': 1506416340000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/26/2017 02:29 PM'
    }, {
        'UniqueId': 'T100823',
        'DateTime': 1506436980000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/26/2017 08:13 PM'
    }, {
        'UniqueId': 'T100824',
        'DateTime': 1506402180000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '9',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/26/2017 10:33 AM'
    }, {
        'UniqueId': 'T100825',
        'DateTime': 1506421200000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/26/2017 03:50 PM'
    }, {
        'UniqueId': 'T100826',
        'DateTime': 1506420780000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Major appliances, housewares',
        'Amount': '30',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/26/2017 03:43 PM'
    }, {
        'UniqueId': 'T100827',
        'DateTime': 1506420300000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Interest from Deposit',
        'Amount': '80',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/26/2017 03:35 PM'
    }, {
        'UniqueId': 'T100828',
        'DateTime': 1506485700000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/27/2017 09:45 AM'
    }, {
        'UniqueId': 'T100829',
        'DateTime': 1506504420000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/27/2017 02:57 PM'
    }, {
        'UniqueId': 'T100830',
        'DateTime': 1506525660000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/27/2017 08:51 PM'
    }, {
        'UniqueId': 'T100831',
        'DateTime': 1506489660000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/27/2017 10:51 AM'
    }, {
        'UniqueId': 'T100832',
        'DateTime': 1506507600000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/27/2017 03:50 PM'
    }, {
        'UniqueId': 'T100833',
        'DateTime': 1506485760000,
        'Category': 'Health Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '80',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/27/2017 09:46 AM'
    }, {
        'UniqueId': 'T100834',
        'DateTime': 1506571920000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/28/2017 09:42 AM'
    }, {
        'UniqueId': 'T100835',
        'DateTime': 1506589920000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/28/2017 02:42 PM'
    }, {
        'UniqueId': 'T100836',
        'DateTime': 1506611040000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/28/2017 08:34 PM'
    }, {
        'UniqueId': 'T100837',
        'DateTime': 1506574620000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/28/2017 10:27 AM'
    }, {
        'UniqueId': 'T100838',
        'DateTime': 1506593880000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/28/2017 03:48 PM'
    }, {
        'UniqueId': 'T100839',
        'DateTime': 1506585840000,
        'Category': 'Bills',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Electricity bill',
        'Amount': '200',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/28/2017 01:34 PM'
    }, {
        'UniqueId': 'T100840',
        'DateTime': 1506585480000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from investments',
        'Amount': '390',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/28/2017 01:28 PM'
    }, {
        'UniqueId': 'T100841',
        'DateTime': 1506656460000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/29/2017 09:11 AM'
    }, {
        'UniqueId': 'T100842',
        'DateTime': 1506676260000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/29/2017 02:41 PM'
    }, {
        'UniqueId': 'T100843',
        'DateTime': 1506698700000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '10',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/29/2017 08:55 PM'
    }, {
        'UniqueId': 'T100844',
        'DateTime': 1506661200000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/29/2017 10:30 AM'
    }, {
        'UniqueId': 'T100845',
        'DateTime': 1506680700000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/29/2017 03:55 PM'
    }, {
        'UniqueId': 'T100846',
        'DateTime': 1506698820000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '55',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/29/2017 08:57 PM'
    }, {
        'UniqueId': 'T100847',
        'DateTime': 1506696900000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fitness Club',
        'Amount': '206',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/29/2017 08:25 PM'
    }, {
        'UniqueId': 'T100848',
        'DateTime': 1506745440000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/30/2017 09:54 AM'
    }, {
        'UniqueId': 'T100849',
        'DateTime': 1506763200000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/30/2017 02:50 PM'
    }, {
        'UniqueId': 'T100850',
        'DateTime': 1506783000000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '12',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/30/2017 08:20 PM'
    }, {
        'UniqueId': 'T100851',
        'DateTime': 1506748740000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/30/2017 10:49 AM'
    }, {
        'UniqueId': 'T100852',
        'DateTime': 1506766080000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '11',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/30/2017 03:38 PM'
    }, {
        'UniqueId': 'T100853',
        'DateTime': 1506784560000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '45',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/30/2017 08:46 PM'
    }, {
        'UniqueId': 'T100854',
        'DateTime': 1506784920000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Major appliances, housewares',
        'Amount': '20',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/30/2017 08:52 PM'
    }, {
        'UniqueId': 'T100855',
        'DateTime': 1506782760000,
        'Category': 'Education',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Education',
        'Amount': '50',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/30/2017 08:16 PM'
    }, {
        'UniqueId': 'T100856',
        'DateTime': 1506782460000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Funds',
        'Amount': '120',
        'MonthShort': 'Sep',
        'MonthFull': 'September, 2017',
        'FormattedDate': '09/30/2017 08:11 PM'
    }, {
        'UniqueId': 'T100857',
        'DateTime': 1506830940000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/01/2017 09:39 AM'
    }, {
        'UniqueId': 'T100858',
        'DateTime': 1506848460000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/01/2017 02:31 PM'
    }, {
        'UniqueId': 'T100859',
        'DateTime': 1506870900000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/01/2017 08:45 PM'
    }, {
        'UniqueId': 'T100860',
        'DateTime': 1506833220000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/01/2017 10:17 AM'
    }, {
        'UniqueId': 'T100861',
        'DateTime': 1506852600000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/01/2017 03:40 PM'
    }, {
        'UniqueId': 'T100862',
        'DateTime': 1506865500000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '50',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/01/2017 07:15 PM'
    }, {
        'UniqueId': 'T100863',
        'DateTime': 1506867000000,
        'Category': 'Mortgage / Rent',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Monthly rent',
        'Amount': '1000',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/01/2017 07:40 PM'
    }, {
        'UniqueId': 'T100864',
        'DateTime': 1506865860000,
        'Category': 'Salary',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Monthly Net Salary',
        'Amount': '6000',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/01/2017 07:21 PM'
    }, {
        'UniqueId': 'T100865',
        'DateTime': 1506916620000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/02/2017 09:27 AM'
    }, {
        'UniqueId': 'T100866',
        'DateTime': 1506936060000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/02/2017 02:51 PM'
    }, {
        'UniqueId': 'T100867',
        'DateTime': 1506956880000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/02/2017 08:38 PM'
    }, {
        'UniqueId': 'T100868',
        'DateTime': 1506921240000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/02/2017 10:44 AM'
    }, {
        'UniqueId': 'T100869',
        'DateTime': 1506937800000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/02/2017 03:20 PM'
    }, {
        'UniqueId': 'T100870',
        'DateTime': 1506944640000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '150',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/02/2017 05:14 PM'
    }, {
        'UniqueId': 'T100871',
        'DateTime': 1506944880000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Laundry and cleaning supplies',
        'Amount': '15',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/02/2017 05:18 PM'
    }, {
        'UniqueId': 'T100872',
        'DateTime': 1507002420000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/03/2017 09:17 AM'
    }, {
        'UniqueId': 'T100873',
        'DateTime': 1507020180000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/03/2017 02:13 PM'
    }, {
        'UniqueId': 'T100874',
        'DateTime': 1507043160000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/03/2017 08:36 PM'
    }, {
        'UniqueId': 'T100875',
        'DateTime': 1507006140000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/03/2017 10:19 AM'
    }, {
        'UniqueId': 'T100876',
        'DateTime': 1507024980000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/03/2017 03:33 PM'
    }, {
        'UniqueId': 'T100877',
        'DateTime': 1507014300000,
        'Category': 'Utilities',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '160',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/03/2017 12:35 PM'
    }, {
        'UniqueId': 'T100878',
        'DateTime': 1507013760000,
        'Category': 'Bills',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cellular phone service',
        'Amount': '180',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/03/2017 12:26 PM'
    }, {
        'UniqueId': 'T100879',
        'DateTime': 1507089420000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/04/2017 09:27 AM'
    }, {
        'UniqueId': 'T100880',
        'DateTime': 1507107360000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/04/2017 02:26 PM'
    }, {
        'UniqueId': 'T100881',
        'DateTime': 1507129680000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/04/2017 08:38 PM'
    }, {
        'UniqueId': 'T100882',
        'DateTime': 1507094760000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/04/2017 10:56 AM'
    }, {
        'UniqueId': 'T100883',
        'DateTime': 1507110840000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/04/2017 03:24 PM'
    }, {
        'UniqueId': 'T100884',
        'DateTime': 1507107120000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '65',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/04/2017 02:22 PM'
    }, {
        'UniqueId': 'T100885',
        'DateTime': 1507109040000,
        'Category': 'Personal Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Short visit to private Doctor',
        'Amount': '20',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/04/2017 02:54 PM'
    }, {
        'UniqueId': 'T100886',
        'DateTime': 1507107540000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Sale',
        'Amount': '80',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/04/2017 02:29 PM'
    }, {
        'UniqueId': 'T100887',
        'DateTime': 1507176000000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/05/2017 09:30 AM'
    }, {
        'UniqueId': 'T100888',
        'DateTime': 1507193340000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/05/2017 02:19 PM'
    }, {
        'UniqueId': 'T100889',
        'DateTime': 1507216260000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/05/2017 08:41 PM'
    }, {
        'UniqueId': 'T100890',
        'DateTime': 1507179060000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/05/2017 10:21 AM'
    }, {
        'UniqueId': 'T100891',
        'DateTime': 1507199340000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/05/2017 03:59 PM'
    }, {
        'UniqueId': 'T100892',
        'DateTime': 1507179960000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '30',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/05/2017 10:36 AM'
    }, {
        'UniqueId': 'T100893',
        'DateTime': 1507181040000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Monthly Pass (Regular Price)',
        'Amount': '3',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/05/2017 10:54 AM'
    }, {
        'UniqueId': 'T100894',
        'DateTime': 1507263420000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/06/2017 09:47 AM'
    }, {
        'UniqueId': 'T100895',
        'DateTime': 1507281300000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/06/2017 02:45 PM'
    }, {
        'UniqueId': 'T100896',
        'DateTime': 1507302780000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/06/2017 08:43 PM'
    }, {
        'UniqueId': 'T100897',
        'DateTime': 1507266600000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/06/2017 10:40 AM'
    }, {
        'UniqueId': 'T100898',
        'DateTime': 1507283940000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/06/2017 03:29 PM'
    }, {
        'UniqueId': 'T100899',
        'DateTime': 1507291500000,
        'Category': 'Health Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '80',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/06/2017 05:35 PM'
    }, {
        'UniqueId': 'T100900',
        'DateTime': 1507291500000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Funds',
        'Amount': '80',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/06/2017 05:35 PM'
    }, {
        'UniqueId': 'T100901',
        'DateTime': 1507347660000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/07/2017 09:11 AM'
    }, {
        'UniqueId': 'T100902',
        'DateTime': 1507368540000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/07/2017 02:59 PM'
    }, {
        'UniqueId': 'T100903',
        'DateTime': 1507387320000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/07/2017 08:12 PM'
    }, {
        'UniqueId': 'T100904',
        'DateTime': 1507352220000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/07/2017 10:27 AM'
    }, {
        'UniqueId': 'T100905',
        'DateTime': 1507370640000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/07/2017 03:34 PM'
    }, {
        'UniqueId': 'T100906',
        'DateTime': 1507376460000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema, International Release',
        'Amount': '105',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/07/2017 05:11 PM'
    }, {
        'UniqueId': 'T100907',
        'DateTime': 1507434960000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/08/2017 09:26 AM'
    }, {
        'UniqueId': 'T100908',
        'DateTime': 1507453380000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/08/2017 02:33 PM'
    }, {
        'UniqueId': 'T100909',
        'DateTime': 1507475400000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/08/2017 08:40 PM'
    }, {
        'UniqueId': 'T100910',
        'DateTime': 1507440300000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/08/2017 10:55 AM'
    }, {
        'UniqueId': 'T100911',
        'DateTime': 1507457400000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/08/2017 03:40 PM'
    }, {
        'UniqueId': 'T100912',
        'DateTime': 1507434600000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '55',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/08/2017 09:20 AM'
    }, {
        'UniqueId': 'T100913',
        'DateTime': 1507436880000,
        'Category': 'Interests',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Interests',
        'Amount': '90',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/08/2017 09:58 AM'
    }, {
        'UniqueId': 'T100914',
        'DateTime': 1507521720000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/09/2017 09:32 AM'
    }, {
        'UniqueId': 'T100915',
        'DateTime': 1507539300000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/09/2017 02:25 PM'
    }, {
        'UniqueId': 'T100916',
        'DateTime': 1507561320000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/09/2017 08:32 PM'
    }, {
        'UniqueId': 'T100917',
        'DateTime': 1507526280000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/09/2017 10:48 AM'
    }, {
        'UniqueId': 'T100918',
        'DateTime': 1507544340000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/09/2017 03:49 PM'
    }, {
        'UniqueId': 'T100919',
        'DateTime': 1507532460000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '145',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/09/2017 12:31 PM'
    }, {
        'UniqueId': 'T100920',
        'DateTime': 1507531740000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other household products',
        'Amount': '15',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/09/2017 12:19 PM'
    }, {
        'UniqueId': 'T100921',
        'DateTime': 1507608600000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/10/2017 09:40 AM'
    }, {
        'UniqueId': 'T100922',
        'DateTime': 1507626600000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/10/2017 02:40 PM'
    }, {
        'UniqueId': 'T100923',
        'DateTime': 1507647780000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/10/2017 08:33 PM'
    }, {
        'UniqueId': 'T100924',
        'DateTime': 1507613220000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/10/2017 10:57 AM'
    }, {
        'UniqueId': 'T100925',
        'DateTime': 1507628940000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/10/2017 03:19 PM'
    }, {
        'UniqueId': 'T100926',
        'DateTime': 1507632360000,
        'Category': 'Utilities',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '140',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/10/2017 04:16 PM'
    }, {
        'UniqueId': 'T100927',
        'DateTime': 1507634040000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cinema',
        'Amount': '4',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/10/2017 04:44 PM'
    }, {
        'UniqueId': 'T100928',
        'DateTime': 1507695060000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/11/2017 09:41 AM'
    }, {
        'UniqueId': 'T100929',
        'DateTime': 1507712760000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/11/2017 02:36 PM'
    }, {
        'UniqueId': 'T100930',
        'DateTime': 1507733880000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/11/2017 08:28 PM'
    }, {
        'UniqueId': 'T100931',
        'DateTime': 1507698960000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/11/2017 10:46 AM'
    }, {
        'UniqueId': 'T100932',
        'DateTime': 1507716660000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/11/2017 03:41 PM'
    }, {
        'UniqueId': 'T100933',
        'DateTime': 1507726260000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '50',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/11/2017 06:21 PM'
    }, {
        'UniqueId': 'T100934',
        'DateTime': 1507728360000,
        'Category': 'Personal Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': '1 box of antibiotics (12 doses)',
        'Amount': '20',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/11/2017 06:56 PM'
    }, {
        'UniqueId': 'T100935',
        'DateTime': 1507727700000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '120',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/11/2017 06:45 PM'
    }, {
        'UniqueId': 'T100936',
        'DateTime': 1507780980000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/12/2017 09:33 AM'
    }, {
        'UniqueId': 'T100937',
        'DateTime': 1507799880000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/12/2017 02:48 PM'
    }, {
        'UniqueId': 'T100938',
        'DateTime': 1507821660000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/12/2017 08:51 PM'
    }, {
        'UniqueId': 'T100939',
        'DateTime': 1507783980000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/12/2017 10:23 AM'
    }, {
        'UniqueId': 'T100940',
        'DateTime': 1507804080000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/12/2017 03:58 PM'
    }, {
        'UniqueId': 'T100941',
        'DateTime': 1507810680000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other household products',
        'Amount': '20',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/12/2017 05:48 PM'
    }, {
        'UniqueId': 'T100942',
        'DateTime': 1507810560000,
        'Category': 'Insurance',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle insurance',
        'Amount': '250',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/12/2017 05:46 PM'
    }, {
        'UniqueId': 'T100943',
        'DateTime': 1507866780000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/13/2017 09:23 AM'
    }, {
        'UniqueId': 'T100944',
        'DateTime': 1507884900000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/13/2017 02:25 PM'
    }, {
        'UniqueId': 'T100945',
        'DateTime': 1507905960000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/13/2017 08:16 PM'
    }, {
        'UniqueId': 'T100946',
        'DateTime': 1507870680000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/13/2017 10:28 AM'
    }, {
        'UniqueId': 'T100947',
        'DateTime': 1507889580000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/13/2017 03:43 PM'
    }, {
        'UniqueId': 'T100948',
        'DateTime': 1507875720000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '60',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/13/2017 11:52 AM'
    }, {
        'UniqueId': 'T100949',
        'DateTime': 1507875960000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Monthly Pass (Regular Price)',
        'Amount': '2',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/13/2017 11:56 AM'
    }, {
        'UniqueId': 'T100950',
        'DateTime': 1507875600000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Gits',
        'Amount': '80',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/13/2017 11:50 AM'
    }, {
        'UniqueId': 'T100951',
        'DateTime': 1507955340000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/14/2017 09:59 AM'
    }, {
        'UniqueId': 'T100952',
        'DateTime': 1507971420000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/14/2017 02:27 PM'
    }, {
        'UniqueId': 'T100953',
        'DateTime': 1507992540000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/14/2017 08:19 PM'
    }, {
        'UniqueId': 'T100954',
        'DateTime': 1507956720000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/14/2017 10:22 AM'
    }, {
        'UniqueId': 'T100955',
        'DateTime': 1507976700000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/14/2017 03:55 PM'
    }, {
        'UniqueId': 'T100956',
        'DateTime': 1507953840000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Own Buisness',
        'Amount': '190',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/14/2017 09:34 AM'
    }, {
        'UniqueId': 'T100957',
        'DateTime': 1508038860000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/15/2017 09:11 AM'
    }, {
        'UniqueId': 'T100958',
        'DateTime': 1508057280000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/15/2017 02:18 PM'
    }, {
        'UniqueId': 'T100959',
        'DateTime': 1508078940000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/15/2017 08:19 PM'
    }, {
        'UniqueId': 'T100960',
        'DateTime': 1508042460000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/15/2017 10:11 AM'
    }, {
        'UniqueId': 'T100961',
        'DateTime': 1508061180000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/15/2017 03:23 PM'
    }, {
        'UniqueId': 'T100962',
        'DateTime': 1508065500000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '65',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/15/2017 04:35 PM'
    }, {
        'UniqueId': 'T100963',
        'DateTime': 1508065380000,
        'Category': 'Bills',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cellular phone service',
        'Amount': '180',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/15/2017 04:33 PM'
    }, {
        'UniqueId': 'T100964',
        'DateTime': 1508064360000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '120',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/15/2017 04:16 PM'
    }, {
        'UniqueId': 'T100965',
        'DateTime': 1508126280000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/16/2017 09:28 AM'
    }, {
        'UniqueId': 'T100966',
        'DateTime': 1508144040000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/16/2017 02:24 PM'
    }, {
        'UniqueId': 'T100967',
        'DateTime': 1508167260000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/16/2017 08:51 PM'
    }, {
        'UniqueId': 'T100968',
        'DateTime': 1508130120000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/16/2017 10:32 AM'
    }, {
        'UniqueId': 'T100969',
        'DateTime': 1508147520000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/16/2017 03:22 PM'
    }, {
        'UniqueId': 'T100970',
        'DateTime': 1508148420000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '150',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/16/2017 03:37 PM'
    }, {
        'UniqueId': 'T100971',
        'DateTime': 1508148780000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Household textiles',
        'Amount': '15',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/16/2017 03:43 PM'
    }, {
        'UniqueId': 'T100972',
        'DateTime': 1508149560000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema, International Release',
        'Amount': '104',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/16/2017 03:56 PM'
    }, {
        'UniqueId': 'T100973',
        'DateTime': 1508211900000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/17/2017 09:15 AM'
    }, {
        'UniqueId': 'T100974',
        'DateTime': 1508229600000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/17/2017 02:10 PM'
    }, {
        'UniqueId': 'T100975',
        'DateTime': 1508251920000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/17/2017 08:22 PM'
    }, {
        'UniqueId': 'T100976',
        'DateTime': 1508216640000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/17/2017 10:34 AM'
    }, {
        'UniqueId': 'T100977',
        'DateTime': 1508234820000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/17/2017 03:37 PM'
    }, {
        'UniqueId': 'T100978',
        'DateTime': 1508242140000,
        'Category': 'Utilities',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '150',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/17/2017 05:39 PM'
    }, {
        'UniqueId': 'T100979',
        'DateTime': 1508299980000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/18/2017 09:43 AM'
    }, {
        'UniqueId': 'T100980',
        'DateTime': 1508317920000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/18/2017 02:42 PM'
    }, {
        'UniqueId': 'T100981',
        'DateTime': 1508339820000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/18/2017 08:47 PM'
    }, {
        'UniqueId': 'T100982',
        'DateTime': 1508302200000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/18/2017 10:20 AM'
    }, {
        'UniqueId': 'T100983',
        'DateTime': 1508319600000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/18/2017 03:10 PM'
    }, {
        'UniqueId': 'T100984',
        'DateTime': 1508316540000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '65',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/18/2017 02:19 PM'
    }, {
        'UniqueId': 'T100985',
        'DateTime': 1508316000000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Standard haircut',
        'Amount': '15',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/18/2017 02:10 PM'
    }, {
        'UniqueId': 'T100986',
        'DateTime': 1508384880000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/19/2017 09:18 AM'
    }, {
        'UniqueId': 'T100987',
        'DateTime': 1508404860000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/19/2017 02:51 PM'
    }, {
        'UniqueId': 'T100988',
        'DateTime': 1508426280000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/19/2017 08:48 PM'
    }, {
        'UniqueId': 'T100989',
        'DateTime': 1508389500000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/19/2017 10:35 AM'
    }, {
        'UniqueId': 'T100990',
        'DateTime': 1508408400000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/19/2017 03:50 PM'
    }, {
        'UniqueId': 'T100991',
        'DateTime': 1508424000000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other household products',
        'Amount': '20',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/19/2017 08:10 PM'
    }, {
        'UniqueId': 'T100992',
        'DateTime': 1508425560000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema, International Release',
        'Amount': '106',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/19/2017 08:36 PM'
    }, {
        'UniqueId': 'T100993',
        'DateTime': 1508424840000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Funds',
        'Amount': '80',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/19/2017 08:24 PM'
    }, {
        'UniqueId': 'T100994',
        'DateTime': 1508473140000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/20/2017 09:49 AM'
    }, {
        'UniqueId': 'T100995',
        'DateTime': 1508491500000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/20/2017 02:55 PM'
    }, {
        'UniqueId': 'T100996',
        'DateTime': 1508512620000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/20/2017 08:47 PM'
    }, {
        'UniqueId': 'T100997',
        'DateTime': 1508475540000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/20/2017 10:29 AM'
    }, {
        'UniqueId': 'T100998',
        'DateTime': 1508493060000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/20/2017 03:21 PM'
    }, {
        'UniqueId': 'T100999',
        'DateTime': 1508488080000,
        'Category': 'Health Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '80',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/20/2017 01:58 PM'
    }, {
        'UniqueId': 'T101000',
        'DateTime': 1508559240000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/21/2017 09:44 AM'
    }, {
        'UniqueId': 'T101001',
        'DateTime': 1508576100000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/21/2017 02:25 PM'
    }, {
        'UniqueId': 'T101002',
        'DateTime': 1508597220000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/21/2017 08:17 PM'
    }, {
        'UniqueId': 'T101003',
        'DateTime': 1508563620000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/21/2017 10:57 AM'
    }, {
        'UniqueId': 'T101004',
        'DateTime': 1508579820000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/21/2017 03:27 PM'
    }, {
        'UniqueId': 'T101005',
        'DateTime': 1508644440000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/22/2017 09:24 AM'
    }, {
        'UniqueId': 'T101006',
        'DateTime': 1508661660000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/22/2017 02:11 PM'
    }, {
        'UniqueId': 'T101007',
        'DateTime': 1508684700000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/22/2017 08:35 PM'
    }, {
        'UniqueId': 'T101008',
        'DateTime': 1508649480000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/22/2017 10:48 AM'
    }, {
        'UniqueId': 'T101009',
        'DateTime': 1508667360000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/22/2017 03:46 PM'
    }, {
        'UniqueId': 'T101010',
        'DateTime': 1508654580000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '55',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/22/2017 12:13 PM'
    }, {
        'UniqueId': 'T101011',
        'DateTime': 1508656320000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from returns',
        'Amount': '80',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/22/2017 12:42 PM'
    }, {
        'UniqueId': 'T101012',
        'DateTime': 1508730900000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/23/2017 09:25 AM'
    }, {
        'UniqueId': 'T101013',
        'DateTime': 1508749020000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/23/2017 02:27 PM'
    }, {
        'UniqueId': 'T101014',
        'DateTime': 1508772480000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/23/2017 08:58 PM'
    }, {
        'UniqueId': 'T101015',
        'DateTime': 1508736240000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/23/2017 10:54 AM'
    }, {
        'UniqueId': 'T101016',
        'DateTime': 1508754180000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/23/2017 03:53 PM'
    }, {
        'UniqueId': 'T101017',
        'DateTime': 1508769600000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '140',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/23/2017 08:10 PM'
    }, {
        'UniqueId': 'T101018',
        'DateTime': 1508772000000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Housekeeping supplies',
        'Amount': '20',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/23/2017 08:50 PM'
    }, {
        'UniqueId': 'T101019',
        'DateTime': 1508772360000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cappuccino (regular)',
        'Amount': '4',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/23/2017 08:56 PM'
    }, {
        'UniqueId': 'T101020',
        'DateTime': 1508817840000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/24/2017 09:34 AM'
    }, {
        'UniqueId': 'T101021',
        'DateTime': 1508836740000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/24/2017 02:49 PM'
    }, {
        'UniqueId': 'T101022',
        'DateTime': 1508856060000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/24/2017 08:11 PM'
    }, {
        'UniqueId': 'T101023',
        'DateTime': 1508821860000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/24/2017 10:41 AM'
    }, {
        'UniqueId': 'T101024',
        'DateTime': 1508838480000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/24/2017 03:18 PM'
    }, {
        'UniqueId': 'T101025',
        'DateTime': 1508846340000,
        'Category': 'Utilities',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '140',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/24/2017 05:29 PM'
    }, {
        'UniqueId': 'T101026',
        'DateTime': 1508845860000,
        'Category': 'Insurance',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Health insurance',
        'Amount': '230',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/24/2017 05:21 PM'
    }, {
        'UniqueId': 'T101027',
        'DateTime': 1508903400000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/25/2017 09:20 AM'
    }, {
        'UniqueId': 'T101028',
        'DateTime': 1508921220000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/25/2017 02:17 PM'
    }, {
        'UniqueId': 'T101029',
        'DateTime': 1508943060000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/25/2017 08:21 PM'
    }, {
        'UniqueId': 'T101030',
        'DateTime': 1508908560000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/25/2017 10:46 AM'
    }, {
        'UniqueId': 'T101031',
        'DateTime': 1508927220000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/25/2017 03:57 PM'
    }, {
        'UniqueId': 'T101032',
        'DateTime': 1508915100000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '55',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/25/2017 12:35 PM'
    }, {
        'UniqueId': 'T101033',
        'DateTime': 1508915580000,
        'Category': 'Personal Care',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Deodorant, roll-on',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/25/2017 12:43 PM'
    }, {
        'UniqueId': 'T101034',
        'DateTime': 1508913600000,
        'Category': 'Tax',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Sales and use tax',
        'Amount': '180',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/25/2017 12:10 PM'
    }, {
        'UniqueId': 'T101035',
        'DateTime': 1508989980000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/26/2017 09:23 AM'
    }, {
        'UniqueId': 'T101036',
        'DateTime': 1509008760000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/26/2017 02:36 PM'
    }, {
        'UniqueId': 'T101037',
        'DateTime': 1509029160000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/26/2017 08:16 PM'
    }, {
        'UniqueId': 'T101038',
        'DateTime': 1508995140000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/26/2017 10:49 AM'
    }, {
        'UniqueId': 'T101039',
        'DateTime': 1509012000000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/26/2017 03:30 PM'
    }, {
        'UniqueId': 'T101040',
        'DateTime': 1509022860000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household textiles',
        'Amount': '20',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/26/2017 06:31 PM'
    }, {
        'UniqueId': 'T101041',
        'DateTime': 1509021960000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Gits',
        'Amount': '90',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/26/2017 06:16 PM'
    }, {
        'UniqueId': 'T101042',
        'DateTime': 1509078240000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/27/2017 09:54 AM'
    }, {
        'UniqueId': 'T101043',
        'DateTime': 1509094380000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/27/2017 02:23 PM'
    }, {
        'UniqueId': 'T101044',
        'DateTime': 1509117120000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '8',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/27/2017 08:42 PM'
    }, {
        'UniqueId': 'T101045',
        'DateTime': 1509081120000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/27/2017 10:42 AM'
    }, {
        'UniqueId': 'T101046',
        'DateTime': 1509098940000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/27/2017 03:39 PM'
    }, {
        'UniqueId': 'T101047',
        'DateTime': 1509090600000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '70',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/27/2017 01:20 PM'
    }, {
        'UniqueId': 'T101048',
        'DateTime': 1509164100000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/28/2017 09:45 AM'
    }, {
        'UniqueId': 'T101049',
        'DateTime': 1509182160000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/28/2017 02:46 PM'
    }, {
        'UniqueId': 'T101050',
        'DateTime': 1509203460000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/28/2017 08:41 PM'
    }, {
        'UniqueId': 'T101051',
        'DateTime': 1509166020000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/28/2017 10:17 AM'
    }, {
        'UniqueId': 'T101052',
        'DateTime': 1509184620000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/28/2017 03:27 PM'
    }, {
        'UniqueId': 'T101053',
        'DateTime': 1509187620000,
        'Category': 'Bills',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cellular phone service',
        'Amount': '180',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/28/2017 04:17 PM'
    }, {
        'UniqueId': 'T101054',
        'DateTime': 1509187200000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from investments',
        'Amount': '220',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/28/2017 04:10 PM'
    }, {
        'UniqueId': 'T101055',
        'DateTime': 1509250140000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/29/2017 09:39 AM'
    }, {
        'UniqueId': 'T101056',
        'DateTime': 1509268800000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/29/2017 02:50 PM'
    }, {
        'UniqueId': 'T101057',
        'DateTime': 1509289080000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '7',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/29/2017 08:28 PM'
    }, {
        'UniqueId': 'T101058',
        'DateTime': 1509254580000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/29/2017 10:53 AM'
    }, {
        'UniqueId': 'T101059',
        'DateTime': 1509272340000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/29/2017 03:49 PM'
    }, {
        'UniqueId': 'T101060',
        'DateTime': 1509272100000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '60',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/29/2017 03:45 PM'
    }, {
        'UniqueId': 'T101061',
        'DateTime': 1509272460000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Debt repayment',
        'Amount': '2',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/29/2017 03:51 PM'
    }, {
        'UniqueId': 'T101062',
        'DateTime': 1509337380000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/30/2017 09:53 AM'
    }, {
        'UniqueId': 'T101063',
        'DateTime': 1509354240000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '12',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/30/2017 02:34 PM'
    }, {
        'UniqueId': 'T101064',
        'DateTime': 1509376140000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '6',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/30/2017 08:39 PM'
    }, {
        'UniqueId': 'T101065',
        'DateTime': 1509339120000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '11',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/30/2017 10:22 AM'
    }, {
        'UniqueId': 'T101066',
        'DateTime': 1509358140000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/30/2017 03:39 PM'
    }, {
        'UniqueId': 'T101067',
        'DateTime': 1509355260000,
        'Category': 'Clothing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Summer Dress in Store',
        'Amount': '150',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/30/2017 02:51 PM'
    }, {
        'UniqueId': 'T101068',
        'DateTime': 1509355380000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Laundry and cleaning supplies',
        'Amount': '25',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/30/2017 02:53 PM'
    }, {
        'UniqueId': 'T101069',
        'DateTime': 1509355380000,
        'Category': 'Education',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Education',
        'Amount': '50',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/30/2017 02:53 PM'
    }, {
        'UniqueId': 'T101070',
        'DateTime': 1509354900000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Gits',
        'Amount': '90',
        'MonthShort': 'Oct',
        'MonthFull': 'October, 2017',
        'FormattedDate': '10/30/2017 02:45 PM'
    }, {
        'UniqueId': 'T101071',
        'DateTime': 1509508980000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/01/2017 09:33 AM'
    }, {
        'UniqueId': 'T101072',
        'DateTime': 1509525660000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/01/2017 02:11 PM'
    }, {
        'UniqueId': 'T101073',
        'DateTime': 1509547980000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/01/2017 08:23 PM'
    }, {
        'UniqueId': 'T101074',
        'DateTime': 1509512040000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/01/2017 10:24 AM'
    }, {
        'UniqueId': 'T101075',
        'DateTime': 1509530760000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/01/2017 03:36 PM'
    }, {
        'UniqueId': 'T101076',
        'DateTime': 1509512520000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '50',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/01/2017 10:32 AM'
    }, {
        'UniqueId': 'T101077',
        'DateTime': 1509512820000,
        'Category': 'Mortgage / Rent',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Monthly rent',
        'Amount': '1000',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/01/2017 10:37 AM'
    }, {
        'UniqueId': 'T101078',
        'DateTime': 1509511800000,
        'Category': 'Salary',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Monthly Net Salary',
        'Amount': '6000',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/01/2017 10:20 AM'
    }, {
        'UniqueId': 'T101079',
        'DateTime': 1509596940000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/02/2017 09:59 AM'
    }, {
        'UniqueId': 'T101080',
        'DateTime': 1509613500000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/02/2017 02:35 PM'
    }, {
        'UniqueId': 'T101081',
        'DateTime': 1509636240000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/02/2017 08:54 PM'
    }, {
        'UniqueId': 'T101082',
        'DateTime': 1509598860000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/02/2017 10:31 AM'
    }, {
        'UniqueId': 'T101083',
        'DateTime': 1509616260000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/02/2017 03:21 PM'
    }, {
        'UniqueId': 'T101084',
        'DateTime': 1509624360000,
        'Category': 'Clothing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '40',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/02/2017 05:36 PM'
    }, {
        'UniqueId': 'T101085',
        'DateTime': 1509624840000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Laundry and cleaning supplies',
        'Amount': '20',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/02/2017 05:44 PM'
    }, {
        'UniqueId': 'T101086',
        'DateTime': 1509681000000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/03/2017 09:20 AM'
    }, {
        'UniqueId': 'T101087',
        'DateTime': 1509699420000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/03/2017 02:27 PM'
    }, {
        'UniqueId': 'T101088',
        'DateTime': 1509721380000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/03/2017 08:33 PM'
    }, {
        'UniqueId': 'T101089',
        'DateTime': 1509685320000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/03/2017 10:32 AM'
    }, {
        'UniqueId': 'T101090',
        'DateTime': 1509702060000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/03/2017 03:11 PM'
    }, {
        'UniqueId': 'T101091',
        'DateTime': 1509688800000,
        'Category': 'Utilities',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '150',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/03/2017 11:30 AM'
    }, {
        'UniqueId': 'T101092',
        'DateTime': 1509689220000,
        'Category': 'Bills',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Electricity bill',
        'Amount': '220',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/03/2017 11:37 AM'
    }, {
        'UniqueId': 'T101093',
        'DateTime': 1509769320000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/04/2017 09:52 AM'
    }, {
        'UniqueId': 'T101094',
        'DateTime': 1509785340000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/04/2017 02:19 PM'
    }, {
        'UniqueId': 'T101095',
        'DateTime': 1509809040000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/04/2017 08:54 PM'
    }, {
        'UniqueId': 'T101096',
        'DateTime': 1509770700000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/04/2017 10:15 AM'
    }, {
        'UniqueId': 'T101097',
        'DateTime': 1509788820000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/04/2017 03:17 PM'
    }, {
        'UniqueId': 'T101098',
        'DateTime': 1509767100000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '60',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/04/2017 09:15 AM'
    }, {
        'UniqueId': 'T101099',
        'DateTime': 1509768420000,
        'Category': 'Personal Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Standard haircut',
        'Amount': '15',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/04/2017 09:37 AM'
    }, {
        'UniqueId': 'T101100',
        'DateTime': 1509767880000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Sale',
        'Amount': '120',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/04/2017 09:28 AM'
    }, {
        'UniqueId': 'T101101',
        'DateTime': 1509854880000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/05/2017 09:38 AM'
    }, {
        'UniqueId': 'T101102',
        'DateTime': 1509872760000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/05/2017 02:36 PM'
    }, {
        'UniqueId': 'T101103',
        'DateTime': 1509895680000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/05/2017 08:58 PM'
    }, {
        'UniqueId': 'T101104',
        'DateTime': 1509858000000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/05/2017 10:30 AM'
    }, {
        'UniqueId': 'T101105',
        'DateTime': 1509877140000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/05/2017 03:49 PM'
    }, {
        'UniqueId': 'T101106',
        'DateTime': 1509887280000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household textiles',
        'Amount': '25',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/05/2017 06:38 PM'
    }, {
        'UniqueId': 'T101107',
        'DateTime': 1509886020000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fitness Club',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/05/2017 06:17 PM'
    }, {
        'UniqueId': 'T101108',
        'DateTime': 1509939780000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/06/2017 09:13 AM'
    }, {
        'UniqueId': 'T101109',
        'DateTime': 1509959400000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/06/2017 02:40 PM'
    }, {
        'UniqueId': 'T101110',
        'DateTime': 1509980700000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/06/2017 08:35 PM'
    }, {
        'UniqueId': 'T101111',
        'DateTime': 1509945660000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/06/2017 10:51 AM'
    }, {
        'UniqueId': 'T101112',
        'DateTime': 1509962340000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/06/2017 03:29 PM'
    }, {
        'UniqueId': 'T101113',
        'DateTime': 1509970620000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '180',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/06/2017 05:47 PM'
    }, {
        'UniqueId': 'T101114',
        'DateTime': 1509969780000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Funds',
        'Amount': '110',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/06/2017 05:33 PM'
    }, {
        'UniqueId': 'T101115',
        'DateTime': 1510026420000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/07/2017 09:17 AM'
    }, {
        'UniqueId': 'T101116',
        'DateTime': 1510044840000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/07/2017 02:24 PM'
    }, {
        'UniqueId': 'T101117',
        'DateTime': 1510065840000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/07/2017 08:14 PM'
    }, {
        'UniqueId': 'T101118',
        'DateTime': 1510032060000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/07/2017 10:51 AM'
    }, {
        'UniqueId': 'T101119',
        'DateTime': 1510047600000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/07/2017 03:10 PM'
    }, {
        'UniqueId': 'T101120',
        'DateTime': 1510055880000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Taxi Start (Normal Tariff)',
        'Amount': '5',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/07/2017 05:28 PM'
    }, {
        'UniqueId': 'T101121',
        'DateTime': 1510112460000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/08/2017 09:11 AM'
    }, {
        'UniqueId': 'T101122',
        'DateTime': 1510132440000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/08/2017 02:44 PM'
    }, {
        'UniqueId': 'T101123',
        'DateTime': 1510154520000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/08/2017 08:52 PM'
    }, {
        'UniqueId': 'T101124',
        'DateTime': 1510117500000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/08/2017 10:35 AM'
    }, {
        'UniqueId': 'T101125',
        'DateTime': 1510134480000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/08/2017 03:18 PM'
    }, {
        'UniqueId': 'T101126',
        'DateTime': 1510124700000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '50',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/08/2017 12:35 PM'
    }, {
        'UniqueId': 'T101127',
        'DateTime': 1510125780000,
        'Category': 'Interests',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Interests',
        'Amount': '90',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/08/2017 12:53 PM'
    }, {
        'UniqueId': 'T101128',
        'DateTime': 1510199640000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/09/2017 09:24 AM'
    }, {
        'UniqueId': 'T101129',
        'DateTime': 1510219080000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/09/2017 02:48 PM'
    }, {
        'UniqueId': 'T101130',
        'DateTime': 1510239780000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/09/2017 08:33 PM'
    }, {
        'UniqueId': 'T101131',
        'DateTime': 1510202580000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/09/2017 10:13 AM'
    }, {
        'UniqueId': 'T101132',
        'DateTime': 1510221000000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/09/2017 03:20 PM'
    }, {
        'UniqueId': 'T101133',
        'DateTime': 1510217160000,
        'Category': 'Clothing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '45',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/09/2017 02:16 PM'
    }, {
        'UniqueId': 'T101134',
        'DateTime': 1510219020000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household textiles',
        'Amount': '15',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/09/2017 02:47 PM'
    }, {
        'UniqueId': 'T101135',
        'DateTime': 1510286100000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/10/2017 09:25 AM'
    }, {
        'UniqueId': 'T101136',
        'DateTime': 1510305720000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/10/2017 02:52 PM'
    }, {
        'UniqueId': 'T101137',
        'DateTime': 1510325580000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/10/2017 08:23 PM'
    }, {
        'UniqueId': 'T101138',
        'DateTime': 1510290540000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/10/2017 10:39 AM'
    }, {
        'UniqueId': 'T101139',
        'DateTime': 1510307520000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/10/2017 03:22 PM'
    }, {
        'UniqueId': 'T101140',
        'DateTime': 1510298640000,
        'Category': 'Utilities',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '160',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/10/2017 12:54 PM'
    }, {
        'UniqueId': 'T101141',
        'DateTime': 1510298160000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Movie Outing',
        'Amount': '5',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/10/2017 12:46 PM'
    }, {
        'UniqueId': 'T101142',
        'DateTime': 1510374000000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/11/2017 09:50 AM'
    }, {
        'UniqueId': 'T101143',
        'DateTime': 1510390320000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/11/2017 02:22 PM'
    }, {
        'UniqueId': 'T101144',
        'DateTime': 1510411260000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/11/2017 08:11 PM'
    }, {
        'UniqueId': 'T101145',
        'DateTime': 1510375980000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/11/2017 10:23 AM'
    }, {
        'UniqueId': 'T101146',
        'DateTime': 1510395540000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/11/2017 03:49 PM'
    }, {
        'UniqueId': 'T101147',
        'DateTime': 1510406880000,
        'Category': 'Shopping',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '65',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/11/2017 06:58 PM'
    }, {
        'UniqueId': 'T101148',
        'DateTime': 1510405500000,
        'Category': 'Personal Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Deodorant, roll-on',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/11/2017 06:35 PM'
    }, {
        'UniqueId': 'T101149',
        'DateTime': 1510405980000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '120',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/11/2017 06:43 PM'
    }, {
        'UniqueId': 'T101150',
        'DateTime': 1510459080000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/12/2017 09:28 AM'
    }, {
        'UniqueId': 'T101151',
        'DateTime': 1510476960000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/12/2017 02:26 PM'
    }, {
        'UniqueId': 'T101152',
        'DateTime': 1510498560000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/12/2017 08:26 PM'
    }, {
        'UniqueId': 'T101153',
        'DateTime': 1510464540000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/12/2017 10:59 AM'
    }, {
        'UniqueId': 'T101154',
        'DateTime': 1510481760000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/12/2017 03:46 PM'
    }, {
        'UniqueId': 'T101155',
        'DateTime': 1510499640000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '25',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/12/2017 08:44 PM'
    }, {
        'UniqueId': 'T101156',
        'DateTime': 1510498020000,
        'Category': 'Insurance',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle insurance',
        'Amount': '230',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/12/2017 08:17 PM'
    }, {
        'UniqueId': 'T101157',
        'DateTime': 1510544400000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/13/2017 09:10 AM'
    }, {
        'UniqueId': 'T101158',
        'DateTime': 1510562400000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/13/2017 02:10 PM'
    }, {
        'UniqueId': 'T101159',
        'DateTime': 1510585620000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/13/2017 08:37 PM'
    }, {
        'UniqueId': 'T101160',
        'DateTime': 1510550940000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/13/2017 10:59 AM'
    }, {
        'UniqueId': 'T101161',
        'DateTime': 1510566240000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '9',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/13/2017 03:14 PM'
    }, {
        'UniqueId': 'T101162',
        'DateTime': 1510580640000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '180',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/13/2017 07:14 PM'
    }, {
        'UniqueId': 'T101163',
        'DateTime': 1510582860000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Tennis Court Rent',
        'Amount': '2',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/13/2017 07:51 PM'
    }, {
        'UniqueId': 'T101164',
        'DateTime': 1510581840000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Gits',
        'Amount': '90',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/13/2017 07:34 PM'
    }, {
        'UniqueId': 'T101165',
        'DateTime': 1510632960000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/14/2017 09:46 AM'
    }, {
        'UniqueId': 'T101166',
        'DateTime': 1510651740000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/14/2017 02:59 PM'
    }, {
        'UniqueId': 'T101167',
        'DateTime': 1510671480000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/14/2017 08:28 PM'
    }, {
        'UniqueId': 'T101168',
        'DateTime': 1510636980000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/14/2017 10:53 AM'
    }, {
        'UniqueId': 'T101169',
        'DateTime': 1510652520000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/14/2017 03:12 PM'
    }, {
        'UniqueId': 'T101170',
        'DateTime': 1510642980000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Own Buisness',
        'Amount': '190',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/14/2017 12:33 PM'
    }, {
        'UniqueId': 'T101171',
        'DateTime': 1510719960000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/15/2017 09:56 AM'
    }, {
        'UniqueId': 'T101172',
        'DateTime': 1510735980000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/15/2017 02:23 PM'
    }, {
        'UniqueId': 'T101173',
        'DateTime': 1510757460000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/15/2017 08:21 PM'
    }, {
        'UniqueId': 'T101174',
        'DateTime': 1510723320000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/15/2017 10:52 AM'
    }, {
        'UniqueId': 'T101175',
        'DateTime': 1510738980000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/15/2017 03:13 PM'
    }, {
        'UniqueId': 'T101176',
        'DateTime': 1510719060000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Personal things',
        'Amount': '65',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/15/2017 09:41 AM'
    }, {
        'UniqueId': 'T101177',
        'DateTime': 1510718820000,
        'Category': 'Bills',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cellular phone service',
        'Amount': '220',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/15/2017 09:37 AM'
    }, {
        'UniqueId': 'T101178',
        'DateTime': 1510719600000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Interest from Deposit',
        'Amount': '100',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/15/2017 09:50 AM'
    }, {
        'UniqueId': 'T101179',
        'DateTime': 1510804920000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/16/2017 09:32 AM'
    }, {
        'UniqueId': 'T101180',
        'DateTime': 1510823340000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/16/2017 02:39 PM'
    }, {
        'UniqueId': 'T101181',
        'DateTime': 1510845660000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/16/2017 08:51 PM'
    }, {
        'UniqueId': 'T101182',
        'DateTime': 1510807920000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/16/2017 10:22 AM'
    }, {
        'UniqueId': 'T101183',
        'DateTime': 1510826940000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/16/2017 03:39 PM'
    }, {
        'UniqueId': 'T101184',
        'DateTime': 1510816260000,
        'Category': 'Clothing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '135',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/16/2017 12:41 PM'
    }, {
        'UniqueId': 'T101185',
        'DateTime': 1510816320000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Household furnishings',
        'Amount': '20',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/16/2017 12:42 PM'
    }, {
        'UniqueId': 'T101186',
        'DateTime': 1510814640000,
        'Category': 'Others',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema',
        'Amount': '3',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/16/2017 12:14 PM'
    }, {
        'UniqueId': 'T101187',
        'DateTime': 1510891800000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/17/2017 09:40 AM'
    }, {
        'UniqueId': 'T101188',
        'DateTime': 1510909320000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/17/2017 02:32 PM'
    }, {
        'UniqueId': 'T101189',
        'DateTime': 1510931400000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/17/2017 08:40 PM'
    }, {
        'UniqueId': 'T101190',
        'DateTime': 1510893960000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/17/2017 10:16 AM'
    }, {
        'UniqueId': 'T101191',
        'DateTime': 1510913700000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/17/2017 03:45 PM'
    }, {
        'UniqueId': 'T101192',
        'DateTime': 1510890420000,
        'Category': 'Utilities',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '160',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/17/2017 09:17 AM'
    }, {
        'UniqueId': 'T101193',
        'DateTime': 1510977480000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/18/2017 09:28 AM'
    }, {
        'UniqueId': 'T101194',
        'DateTime': 1510994580000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/18/2017 02:13 PM'
    }, {
        'UniqueId': 'T101195',
        'DateTime': 1511017740000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/18/2017 08:39 PM'
    }, {
        'UniqueId': 'T101196',
        'DateTime': 1510981620000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/18/2017 10:37 AM'
    }, {
        'UniqueId': 'T101197',
        'DateTime': 1510998960000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/18/2017 03:26 PM'
    }, {
        'UniqueId': 'T101198',
        'DateTime': 1510993320000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '60',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/18/2017 01:52 PM'
    }, {
        'UniqueId': 'T101199',
        'DateTime': 1510992960000,
        'Category': 'Personal Care',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': '1 box of antibiotics (12 doses)',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/18/2017 01:46 PM'
    }, {
        'UniqueId': 'T101200',
        'DateTime': 1511064780000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/19/2017 09:43 AM'
    }, {
        'UniqueId': 'T101201',
        'DateTime': 1511082840000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/19/2017 02:44 PM'
    }, {
        'UniqueId': 'T101202',
        'DateTime': 1511104500000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/19/2017 08:45 PM'
    }, {
        'UniqueId': 'T101203',
        'DateTime': 1511066400000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/19/2017 10:10 AM'
    }, {
        'UniqueId': 'T101204',
        'DateTime': 1511086260000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/19/2017 03:41 PM'
    }, {
        'UniqueId': 'T101205',
        'DateTime': 1511095680000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Major appliances, housewares',
        'Amount': '30',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/19/2017 06:18 PM'
    }, {
        'UniqueId': 'T101206',
        'DateTime': 1511096820000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cappuccino (regular)',
        'Amount': '3',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/19/2017 06:37 PM'
    }, {
        'UniqueId': 'T101207',
        'DateTime': 1511098140000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Gits',
        'Amount': '90',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/19/2017 06:59 PM'
    }, {
        'UniqueId': 'T101208',
        'DateTime': 1511151240000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Boiled peanuts',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/20/2017 09:44 AM'
    }, {
        'UniqueId': 'T101209',
        'DateTime': 1511169600000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/20/2017 02:50 PM'
    }, {
        'UniqueId': 'T101210',
        'DateTime': 1511190960000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Creole cream cheese',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/20/2017 08:46 PM'
    }, {
        'UniqueId': 'T101211',
        'DateTime': 1511152920000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/20/2017 10:12 AM'
    }, {
        'UniqueId': 'T101212',
        'DateTime': 1511173680000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/20/2017 03:58 PM'
    }, {
        'UniqueId': 'T101213',
        'DateTime': 1511171760000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '170',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/20/2017 03:26 PM'
    }, {
        'UniqueId': 'T101214',
        'DateTime': 1511238120000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/21/2017 09:52 AM'
    }, {
        'UniqueId': 'T101215',
        'DateTime': 1511253960000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Frito pie, Muscadine wine and juice',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/21/2017 02:16 PM'
    }, {
        'UniqueId': 'T101216',
        'DateTime': 1511276400000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/21/2017 08:30 PM'
    }, {
        'UniqueId': 'T101217',
        'DateTime': 1511241540000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/21/2017 10:49 AM'
    }, {
        'UniqueId': 'T101218',
        'DateTime': 1511259960000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Gasoline and motor oil',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/21/2017 03:56 PM'
    }, {
        'UniqueId': 'T101219',
        'DateTime': 1511324220000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/22/2017 09:47 AM'
    }, {
        'UniqueId': 'T101220',
        'DateTime': 1511342520000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/22/2017 02:52 PM'
    }, {
        'UniqueId': 'T101221',
        'DateTime': 1511361720000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/22/2017 08:12 PM'
    }, {
        'UniqueId': 'T101222',
        'DateTime': 1511326020000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/22/2017 10:17 AM'
    }, {
        'UniqueId': 'T101223',
        'DateTime': 1511345640000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '9',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/22/2017 03:44 PM'
    }, {
        'UniqueId': 'T101224',
        'DateTime': 1511322780000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '50',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/22/2017 09:23 AM'
    }, {
        'UniqueId': 'T101225',
        'DateTime': 1511322720000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '80',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/22/2017 09:22 AM'
    }, {
        'UniqueId': 'T101226',
        'DateTime': 1511408940000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/23/2017 09:19 AM'
    }, {
        'UniqueId': 'T101227',
        'DateTime': 1511426640000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/23/2017 02:14 PM'
    }, {
        'UniqueId': 'T101228',
        'DateTime': 1511448720000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/23/2017 08:22 PM'
    }, {
        'UniqueId': 'T101229',
        'DateTime': 1511414520000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/23/2017 10:52 AM'
    }, {
        'UniqueId': 'T101230',
        'DateTime': 1511430780000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/23/2017 03:23 PM'
    }, {
        'UniqueId': 'T101231',
        'DateTime': 1511427480000,
        'Category': 'Clothing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Jeans',
        'Amount': '140',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/23/2017 02:28 PM'
    }, {
        'UniqueId': 'T101232',
        'DateTime': 1511426880000,
        'Category': 'Housing',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Major appliances, housewares',
        'Amount': '30',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/23/2017 02:18 PM'
    }, {
        'UniqueId': 'T101233',
        'DateTime': 1511427060000,
        'Category': 'Others',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Taxi Start (Normal Tariff)',
        'Amount': '4',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/23/2017 02:21 PM'
    }, {
        'UniqueId': 'T101234',
        'DateTime': 1511497140000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Chicken salad, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/24/2017 09:49 AM'
    }, {
        'UniqueId': 'T101235',
        'DateTime': 1511514540000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Pimento cheese sandwich',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/24/2017 02:39 PM'
    }, {
        'UniqueId': 'T101236',
        'DateTime': 1511534940000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Confederate cush',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/24/2017 08:19 PM'
    }, {
        'UniqueId': 'T101237',
        'DateTime': 1511498460000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '9',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/24/2017 10:11 AM'
    }, {
        'UniqueId': 'T101238',
        'DateTime': 1511517900000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/24/2017 03:35 PM'
    }, {
        'UniqueId': 'T101239',
        'DateTime': 1511514060000,
        'Category': 'Utilities',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Expense for Utilities',
        'Amount': '130',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/24/2017 02:31 PM'
    }, {
        'UniqueId': 'T101240',
        'DateTime': 1511515740000,
        'Category': 'Insurance',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle insurance',
        'Amount': '250',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/24/2017 02:59 PM'
    }, {
        'UniqueId': 'T101241',
        'DateTime': 1511582580000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vienna sausages, Mello Yello',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/25/2017 09:33 AM'
    }, {
        'UniqueId': 'T101242',
        'DateTime': 1511602140000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/25/2017 02:59 PM'
    }, {
        'UniqueId': 'T101243',
        'DateTime': 1511622240000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/25/2017 08:34 PM'
    }, {
        'UniqueId': 'T101244',
        'DateTime': 1511586120000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/25/2017 10:32 AM'
    }, {
        'UniqueId': 'T101245',
        'DateTime': 1511604720000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/25/2017 03:42 PM'
    }, {
        'UniqueId': 'T101246',
        'DateTime': 1511608140000,
        'Category': 'Shopping',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Household things \u0026 Utilities',
        'Amount': '60',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/25/2017 04:39 PM'
    }, {
        'UniqueId': 'T101247',
        'DateTime': 1511608560000,
        'Category': 'Personal Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Cold medicine',
        'Amount': '15',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/25/2017 04:46 PM'
    }, {
        'UniqueId': 'T101248',
        'DateTime': 1511608620000,
        'Category': 'Tax',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Social Security and Medicare taxes',
        'Amount': '180',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/25/2017 04:47 PM'
    }, {
        'UniqueId': 'T101249',
        'DateTime': 1511668380000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/26/2017 09:23 AM'
    }, {
        'UniqueId': 'T101250',
        'DateTime': 1511688360000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanut butter and banana sandwich',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/26/2017 02:56 PM'
    }, {
        'UniqueId': 'T101251',
        'DateTime': 1511707800000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Beer cheese',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/26/2017 08:20 PM'
    }, {
        'UniqueId': 'T101252',
        'DateTime': 1511673060000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/26/2017 10:41 AM'
    }, {
        'UniqueId': 'T101253',
        'DateTime': 1511691600000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/26/2017 03:50 PM'
    }, {
        'UniqueId': 'T101254',
        'DateTime': 1511673300000,
        'Category': 'Housing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Laundry and cleaning supplies',
        'Amount': '25',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/26/2017 10:45 AM'
    }, {
        'UniqueId': 'T101255',
        'DateTime': 1511672400000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from refund',
        'Amount': '80',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/26/2017 10:30 AM'
    }, {
        'UniqueId': 'T101256',
        'DateTime': 1511754000000,
        'Category': 'Food',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Po\u0027 boy sandwich, RC Cola',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/27/2017 09:10 AM'
    }, {
        'UniqueId': 'T101257',
        'DateTime': 1511772000000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/27/2017 02:10 PM'
    }, {
        'UniqueId': 'T101258',
        'DateTime': 1511795280000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pickled pigs feet',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/27/2017 08:38 PM'
    }, {
        'UniqueId': 'T101259',
        'DateTime': 1511758980000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/27/2017 10:33 AM'
    }, {
        'UniqueId': 'T101260',
        'DateTime': 1511777220000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/27/2017 03:37 PM'
    }, {
        'UniqueId': 'T101261',
        'DateTime': 1511779860000,
        'Category': 'Health Care',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Health Care',
        'Amount': '70',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/27/2017 04:21 PM'
    }, {
        'UniqueId': 'T101262',
        'DateTime': 1511843160000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Fatback or hog jowl',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/28/2017 09:56 AM'
    }, {
        'UniqueId': 'T101263',
        'DateTime': 1511860740000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cheese straws, Red Rock Cola',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/28/2017 02:49 PM'
    }, {
        'UniqueId': 'T101264',
        'DateTime': 1511880480000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/28/2017 08:18 PM'
    }, {
        'UniqueId': 'T101265',
        'DateTime': 1511844420000,
        'Category': 'Transportation',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '9',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/28/2017 10:17 AM'
    }, {
        'UniqueId': 'T101266',
        'DateTime': 1511862300000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Maintenance and repairs',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/28/2017 03:15 PM'
    }, {
        'UniqueId': 'T101267',
        'DateTime': 1511853300000,
        'Category': 'Bills',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Electricity bill',
        'Amount': '180',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/28/2017 12:45 PM'
    }, {
        'UniqueId': 'T101268',
        'DateTime': 1511851440000,
        'Category': 'Business',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from investments',
        'Amount': '200',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/28/2017 12:14 PM'
    }, {
        'UniqueId': 'T101269',
        'DateTime': 1511928300000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/29/2017 09:35 AM'
    }, {
        'UniqueId': 'T101270',
        'DateTime': 1511946300000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Steen\u0027s cane syrup',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/29/2017 02:35 PM'
    }, {
        'UniqueId': 'T101271',
        'DateTime': 1511968380000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Peanuts in Coke',
        'Amount': '8',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/29/2017 08:43 PM'
    }, {
        'UniqueId': 'T101272',
        'DateTime': 1511932260000,
        'Category': 'Transportation',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Vehicle purchases',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/29/2017 10:41 AM'
    }, {
        'UniqueId': 'T101273',
        'DateTime': 1511950860000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Public and other transportation',
        'Amount': '11',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/29/2017 03:51 PM'
    }, {
        'UniqueId': 'T101274',
        'DateTime': 1511965200000,
        'Category': 'Shopping',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Beauty care things',
        'Amount': '65',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/29/2017 07:50 PM'
    }, {
        'UniqueId': 'T101275',
        'DateTime': 1511963040000,
        'Category': 'Others',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cinema, International Release',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/29/2017 07:14 PM'
    }, {
        'UniqueId': 'T101276',
        'DateTime': 1512015840000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '12',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/30/2017 09:54 AM'
    }, {
        'UniqueId': 'T101277',
        'DateTime': 1512033060000,
        'Category': 'Food',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Muffuletta sandwich, Mint julep',
        'Amount': '10',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/30/2017 02:41 PM'
    }, {
        'UniqueId': 'T101278',
        'DateTime': 1512042720000,
        'Category': 'Food',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Palmetto Cheese, Mint julep',
        'Amount': '6',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/30/2017 08:22 PM'
    }, {
        'UniqueId': 'T101279',
        'DateTime': 1512018360000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Cars and trucks, used',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/30/2017 10:36 AM'
    }, {
        'UniqueId': 'T101280',
        'DateTime': 1512036720000,
        'Category': 'Transportation',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Other vehicle expenses',
        'Amount': '7',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/30/2017 03:42 PM'
    }, {
        'UniqueId': 'T101281',
        'DateTime': 1512033060000,
        'Category': 'Clothing',
        'PaymentMode': 'Debit Card',
        'TransactionType': 'Expense',
        'Description': 'Pair of Running Shoes',
        'Amount': '45',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/30/2017 02:41 PM'
    }, {
        'UniqueId': 'T101282',
        'DateTime': 1512033720000,
        'Category': 'Housing',
        'PaymentMode': 'Credit Card',
        'TransactionType': 'Expense',
        'Description': 'Laundry and cleaning supplies',
        'Amount': '20',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/30/2017 02:52 PM'
    }, {
        'UniqueId': 'T101283',
        'DateTime': 1512031320000,
        'Category': 'Education',
        'PaymentMode': 'Cash',
        'TransactionType': 'Expense',
        'Description': 'Expense for Education',
        'Amount': '50',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/30/2017 02:12 PM'
    }, {
        'UniqueId': 'T101284',
        'DateTime': 1512033120000,
        'Category': 'Extra income',
        'PaymentMode': 'Cash',
        'TransactionType': 'Income',
        'Description': 'Income from Sale',
        'Amount': '110',
        'MonthShort': 'Nov',
        'MonthFull': 'November, 2017',
        'FormattedDate': '11/30/2017 02:42 PM'
    }];
exports.hardwareData = [
    {
        'TaskID': 1,
        'Name': 'Lenovo Yoga',
        'Category': 'Laptop',
        'SerialNo': 'CB27932009',
        'InvoiceNo': 'INV-2878',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'John Doe',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 2,
        'Name': 'Acer Aspire',
        'Category': 'Others',
        'SerialNo': 'CB35728290',
        'InvoiceNo': 'INV-3456',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'In-repair',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 3,
        'Name': 'Apple MacBook',
        'Category': 'Laptop',
        'SerialNo': 'CB35628728',
        'InvoiceNo': 'INV-2763',
        'DOP': '04/10/2018',
        'WEO': '04/03/2021',
        'Status': 'In-repair',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 4,
        'Name': 'Lenovo ThinkPad',
        'Category': 'Laptop',
        'SerialNo': 'CB56209872',
        'InvoiceNo': 'INV-2980',
        'DOP': '03/09/2018',
        'WEO': '05/12/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 5,
        'Name': 'Dell Inspiron',
        'Category': 'Laptop',
        'SerialNo': 'CB56289036',
        'InvoiceNo': 'INV-3782',
        'DOP': '01/10/2018',
        'WEO': '04/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'David Anto',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 6,
        'Name': 'HP Pavilion',
        'Category': 'Laptop',
        'SerialNo': 'CB56289305',
        'InvoiceNo': 'INV-2712',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'Mary Saveley',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 7,
        'Name': 'Asus ZenBook',
        'Category': 'Laptop',
        'SerialNo': 'CB25637891',
        'InvoiceNo': 'INV-0984',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 8,
        'Name': 'HP EliteBook',
        'Category': 'Laptop',
        'SerialNo': 'CB27819726',
        'InvoiceNo': 'INV-2561',
        'DOP': '02/19/2018',
        'WEO': '05/21/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 9,
        'Name': 'Apple MacBook Air',
        'Category': 'Laptop',
        'SerialNo': 'CB05262880',
        'InvoiceNo': 'INV-8970',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 10,
        'Name': 'Apple iPad Air',
        'Category': 'Tablet',
        'SerialNo': 'CB45262777',
        'InvoiceNo': 'INV-4555',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 11,
        'Name': 'Sony Vaio',
        'Category': 'Laptop',
        'SerialNo': 'CB01826739',
        'InvoiceNo': 'INV-0712',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 12,
        'Name': 'Dell UltraSharp UP3218K',
        'Category': 'Monitor',
        'SerialNo': 'CB01824746',
        'InvoiceNo': 'INV-1712',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'Pascale Cartrain',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 13,
        'Name': 'Asus MG248Q',
        'Category': 'Monitor',
        'SerialNo': 'CB01826899',
        'InvoiceNo': 'INV-3912',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'Paul Henriot',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 14,
        'Name': 'BenQ Zowie XL2540',
        'Category': 'Monitor',
        'SerialNo': 'CB01825748',
        'InvoiceNo': 'INV-5712',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'In-repair',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 15,
        'Name': 'Acer S277HK',
        'Category': 'Monitor',
        'SerialNo': 'CB01826747',
        'InvoiceNo': 'INV-0718',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 16,
        'Name': 'Acer S277HK',
        'Category': 'Monitor',
        'SerialNo': 'CB01836746',
        'InvoiceNo': 'INV-2312',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 17,
        'Name': 'Acer S277HK',
        'Category': 'Monitor',
        'SerialNo': 'CB01236746',
        'InvoiceNo': 'INV-4512',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 18,
        'Name': 'Alienware 25',
        'Category': 'Monitor',
        'SerialNo': 'CB01826723',
        'InvoiceNo': 'INV-5713',
        'DOP': '02/19/2018',
        'WEO': '05/21/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 19,
        'Name': 'Samsung Galaxy Tab S 10.5',
        'Category': 'Tablet',
        'SerialNo': 'CB01826235',
        'InvoiceNo': 'INV-3212',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'In-repair',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 20,
        'Name': 'Lenovo Yoga Tablet 2 Pro',
        'Category': 'Tablet',
        'SerialNo': 'CB01822378',
        'InvoiceNo': 'INV-3815',
        'DOP': '02/19/2018',
        'WEO': '05/21/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 21,
        'Name': 'Asus Google Nexus 7 ',
        'Category': 'Tablet',
        'SerialNo': 'CB01826712',
        'InvoiceNo': 'INV-0723',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'Assigned',
        'AssignedTo': 'Mary Saveley',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 22,
        'Name': 'Lenovo Yoga Tablet 2 Pro',
        'Category': 'Tablet',
        'SerialNo': 'CB01826744',
        'InvoiceNo': 'INV-0789',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'In-repair',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 23,
        'Name': 'HP Deskjet 3630 Printer',
        'Category': 'Others',
        'SerialNo': 'CB01826741',
        'InvoiceNo': 'INV-0711',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 24,
        'Name': 'Canon PIXMA TS9150',
        'Category': 'Others',
        'SerialNo': 'CB01826709',
        'InvoiceNo': 'INV-0718',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 25,
        'Name': 'Brother DCP-9020CDW',
        'Category': 'Others',
        'SerialNo': 'CB01826723',
        'InvoiceNo': 'INV-0726',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'Mario Pontes',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 26,
        'Name': 'Canon PIXMA TS9150',
        'Category': 'Others',
        'SerialNo': 'CB01826743',
        'InvoiceNo': 'INV-0734',
        'DOP': '02/19/2018',
        'WEO': '05/21/2021',
        'Status': 'Assigned',
        'AssignedTo': 'Karin Josephs',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 27,
        'Name': 'Lenovo Yoga',
        'Category': 'Laptop',
        'SerialNo': 'CB27932010',
        'InvoiceNo': 'INV-2879',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 28,
        'Name': 'Acer Aspire',
        'Category': 'Others',
        'SerialNo': 'CB35728293',
        'InvoiceNo': 'INV-3454',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'In-repair',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 29,
        'Name': 'Apple MacBook',
        'Category': 'Laptop',
        'SerialNo': 'CB35628723',
        'InvoiceNo': 'INV-2764',
        'DOP': '04/10/2018',
        'WEO': '04/03/2021',
        'Status': 'Assigned',
        'AssignedTo': 'David Anto',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 30,
        'Name': 'Lenovo ThinkPad',
        'Category': 'Laptop',
        'SerialNo': 'CB56209877',
        'InvoiceNo': 'INV-2934',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'In-repair',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 31,
        'Name': 'Dell Inspiron',
        'Category': 'Laptop',
        'SerialNo': 'CB56289033',
        'InvoiceNo': 'INV-3785',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 32,
        'Name': 'HP Pavilion',
        'Category': 'Laptop',
        'SerialNo': 'CB56289300',
        'InvoiceNo': 'INV-2700',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'Assigned',
        'AssignedTo': 'Mary Saveley',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 33,
        'Name': 'Asus ZenBook',
        'Category': 'Laptop',
        'SerialNo': 'CB25637892',
        'InvoiceNo': 'INV-0903',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 34,
        'Name': 'HP EliteBook',
        'Category': 'Laptop',
        'SerialNo': 'CB27819122',
        'InvoiceNo': 'INV-2562',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 35,
        'Name': 'Apple MacBook Air',
        'Category': 'Laptop',
        'SerialNo': 'CB45262730',
        'InvoiceNo': 'INV-4501',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 36,
        'Name': 'Apple iPad Air',
        'Category': 'Tablet',
        'SerialNo': 'CB45262831',
        'InvoiceNo': 'INV-4522',
        'DOP': '02/19/2018',
        'WEO': '05/21/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 37,
        'Name': 'Sony Vaio',
        'Category': 'Laptop',
        'SerialNo': 'CB01826739',
        'InvoiceNo': 'INV-0712',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 38,
        'Name': 'Dell UltraSharp UP3218K',
        'Category': 'Monitor',
        'SerialNo': 'CB01824746',
        'InvoiceNo': 'INV-1712',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'Assigned',
        'AssignedTo': 'Pascale Cartrain',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 39,
        'Name': 'Asus MG248Q',
        'Category': 'Monitor',
        'SerialNo': 'CB01826899',
        'InvoiceNo': 'INV-3912',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'Paul Henriot',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 40,
        'Name': 'BenQ Zowie XL2540',
        'Category': 'Monitor',
        'SerialNo': 'CB01828759',
        'InvoiceNo': 'INV-5813',
        'DOP': '02/19/2018',
        'WEO': '05/21/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 41,
        'Name': 'Acer S277HK',
        'Category': 'Monitor',
        'SerialNo': 'CB01826747',
        'InvoiceNo': 'INV-0718',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 42,
        'Name': 'Acer S277HK',
        'Category': 'Monitor',
        'SerialNo': 'CB01836746',
        'InvoiceNo': 'INV-2312',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 43,
        'Name': 'Acer S277HK',
        'Category': 'Monitor',
        'SerialNo': 'CB01236746',
        'InvoiceNo': 'INV-4512',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 44,
        'Name': 'Alienware 25',
        'Category': 'Monitor',
        'SerialNo': 'CB81826735',
        'InvoiceNo': 'INV-6612',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 45,
        'Name': 'Samsung Galaxy Tab S 10.5',
        'Category': 'Tablet',
        'SerialNo': 'CB01826235',
        'InvoiceNo': 'INV-3212',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 46,
        'Name': 'Lenovo Yoga Tablet 2 Pro',
        'Category': 'Tablet',
        'SerialNo': 'CB01822378',
        'InvoiceNo': 'INV-3815',
        'DOP': '02/12/2018',
        'WEO': '03/01/2023',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 47,
        'Name': 'Asus Google Nexus 7 ',
        'Category': 'Tablet',
        'SerialNo': 'CB01826712',
        'InvoiceNo': 'INV-0723',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'Mary Saveley',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 48,
        'Name': 'Lenovo Yoga Tablet 2 Pro',
        'Category': 'Tablet',
        'SerialNo': 'CB01826744',
        'InvoiceNo': 'INV-0789',
        'DOP': '02/19/2018',
        'WEO': '05/21/2021',
        'Status': 'In-repair',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 49,
        'Name': 'HP Deskjet 3630 Printer',
        'Category': 'Others',
        'SerialNo': 'CB01826741',
        'InvoiceNo': 'INV-0711',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Ordered',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 50,
        'Name': 'Canon PIXMA TS9150',
        'Category': 'Others',
        'SerialNo': 'CB01826709',
        'InvoiceNo': 'INV-0718',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Pending',
        'AssignedTo': '',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 51,
        'Name': 'Brother DCP-9020CDW',
        'Category': 'Others',
        'SerialNo': 'CB01826723',
        'InvoiceNo': 'INV-0726',
        'DOP': '06/16/2018',
        'WEO': '09/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'Mario Pontes',
        'Note': 'Remarks are noted'
    },
    {
        'TaskID': 52,
        'Name': 'Canon PIXMA TS9150',
        'Category': 'Others',
        'SerialNo': 'CB01826743',
        'InvoiceNo': 'INV-0734',
        'DOP': '04/10/2018',
        'WEO': '05/01/2021',
        'Status': 'Assigned',
        'AssignedTo': 'Karin Josephs',
        'Note': 'Remarks are noted'
    }
];
exports.complexData = [{
        'Employee': {
            'ID': 1001,
            'DOB': new Date(1996, 4, 23),
            'HireDate': new Date(2015, 3, 21),
            'Salary': 1000,
            'Age': 23,
            'Title': 'Mr'
        },
        'Name': {
            'FirstName': 'Joe',
            'LastName': 'peter'
        },
        'Country': {
            'State': {
                'City': 'San diego',
                'Zipcode': 22434,
            },
            'Region': 'Pacific',
            'Name': 'USA'
        }
    },
    {
        'Employee': {
            'ID': 1002,
            'DOB': new Date(1995, 2, 28),
            'HireDate': new Date(2015, 5, 25),
            'Salary': 1300,
            'Age': 25,
            'Title': 'Mr'
        },
        'Name': {
            'FirstName': 'Mark',
            'LastName': 'lawrence'
        },
        'Country': {
            'State': {
                'City': 'Houston',
                'Zipcode': 77001
            },
            'Region': 'South central',
            'Name': 'USA'
        }
    },
    {
        'Employee': {
            'ID': 1003,
            'DOB': new Date(1996, 7, 7),
            'HireDate': new Date(2018, 9, 11),
            'Salary': 1400,
            'Age': 20,
            'Title': 'Mr'
        },
        'Name': {
            'FirstName': 'David',
            'LastName': 'malan'
        },
        'Country': {
            'State': {
                'City': 'Jersey City',
                'Zipcode': 27097
            },
            'Region': 'Mid-Atlantic',
            'Name': 'USA'
        }
    }];
exports.basicData = [{
        'Employee ID': 1001,
        'First Name': 'Joe',
        'Last Name': 'Peter',
        'Hire Date': new Date(2018, 9, 11),
        'Country': 'USA',
    },
    {
        'Employee ID': 1002,
        'First Name': 'Mark',
        'Last Name': 'Lawrence',
        'Hire Date': new Date(2012, 6, 1),
        'Country': 'Spain',
    },
    {
        'Employee ID': 1003,
        'First Name': 'David',
        'Last Name': 'Malan',
        'Hire Date': new Date(2015, 3, 2),
        'Country': 'England',
    }];
