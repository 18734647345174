const ACTIVE_ID = "ACTIVE_ID";
const ACTIVE_ID_RESET = "ACTIVE_ID_RESET";

const activeIdReducer = (
  state = { id: null, },
  action
) => {
  switch (action.type) {
    case ACTIVE_ID:
      return { ...state, id: action.payload, };
    case ACTIVE_ID_RESET:
      return { ...state, id: null,};
    default:
      return { ...state };
  }
};

export default activeIdReducer;
