import { gql } from "apollo-boost";

const allOrganizationsQuery = gql`
  
    query(
    $owner: ID
    $domain: ID
  ) { 
    allOrganizations (
      owner: $owner
      domain: $domain

    ) {
    _id
    domain
    name
    owner
    industry
    language
    currency
    timeZone
    }
  }
  
`;

export default allOrganizationsQuery;
