import './index.scss';
import * as React from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Agenda, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';

import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { updateSampleSection } from './sample-base';
import { PropertyPane } from './property-pane';
import * as dataSource from './datasource.json';
import { extend, isNullOrUndefined } from "@syncfusion/ej2-base";

/**
 * Schedule Component sample
 */
function Component({resultsArr, addRecordFunc,
  updateRecordFunc,
  deleteRecordFunc,allEvents}) {
    React.useEffect(() => {
        updateSampleSection();
    }, []);
    let scheduleObj; 
    const data = extend([], dataSource.scheduleData, null, true);
    function change(args) {
        scheduleObj.selectedDate = args.value;
        scheduleObj.dataBind();
    }
    function onDragStart(args) {
        args.navigation.enable = true;
    }

  //   function onPopupClose(args) {
  //     if (args.data && !args.cancel) {
  //       let newData = Object.entries(args.data)
  //       let newD = newData.map(([key, x]) => {
  //         return {[key]: {name: key, value: x}}
  //       })
  //       let lastData = newD.reduce((a, b) => Object.assign(a, b), {}) 

  //       addRecordFunc(lastData)
  //     }
  // }

    return (<div className='schedule-control-section'
    style={{
      height: "600px",
      overflow: "scroll",
    }}>
      <div className=' control-section'>
        <div className='control-wrapper'>
          <ScheduleComponent height='650px' ref={schedule => scheduleObj = schedule} selectedDate={new Date(2021, 0, 10)} eventSettings={{ dataSource: resultsArr}} {...allEvents} dragStart={(onDragStart.bind(this))}>
            <ViewsDirective>
              <ViewDirective option='Day'/>
              <ViewDirective option='Week'/>
              <ViewDirective option='WorkWeek'/>
              <ViewDirective option='Month'/> 
              <ViewDirective option='Agenda'/>
            </ViewsDirective>
            <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]}/>
          </ScheduleComponent>
        </div>
      </div>
    </div>);
}
export default Component;
