import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Pricing2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-8 mx-auto">
        <p class="text-xl text-center text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="160612" is="div"><Text text="Choose your plan" /></Element>} </p>

        <h1 class="mt-4 text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="567798" is="div"><Text text="Pricing Plan" /></Element>} </h1>

        <div class="mt-6 space-y-8 xl:mt-12">
            <div class="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl dark:border-gray-700">
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-400 sm:h-9 sm:w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>

                    <div class="flex flex-col items-center mx-5 space-y-1">
                        <h2 class="text-lg font-medium text-gray-700 sm:text-2xl dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="200260" is="div"><Text text="Basic" /></Element>} </h2>
                        
                        <div class="px-2 text-xs text-blue-500 bg-gray-100 rounded-full sm:px-4 sm:py-1 dark:bg-gray-700 "> {<Element canvas style={{display:"inline-block"}} id="202583" is="div"><Text text="Save 20%" /></Element>} </div>
                    </div>
                </div>

                <h2 class="text-2xl font-semibold text-gray-500 sm:text-4xl dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="329348" is="div"><Text text="$49" /></Element>} <span class="text-base font-medium"> {<Element canvas style={{display:"inline-block"}} id="733279" is="div"><Text text="/Month" /></Element>} </span></h2>
            </div>

            <div class="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border border-blue-500 cursor-pointer rounded-xl">
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-600 sm:h-9 sm:w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>

                    <div class="flex flex-col items-center mx-5 space-y-1">
                        <h2 class="text-lg font-medium text-gray-700 sm:text-2xl dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="498855" is="div"><Text text="Popular" /></Element>} </h2>
                        <div class="px-2 text-xs text-blue-500 bg-gray-100 rounded-full sm:px-4 sm:py-1 dark:bg-gray-700 "> {<Element canvas style={{display:"inline-block"}} id="298575" is="div"><Text text="Save 20%" /></Element>} </div>
                    </div>
                </div>

                <h2 class="text-2xl font-semibold text-blue-600 sm:text-4xl"> {<Element canvas style={{display:"inline-block"}} id="482330" is="div"><Text text="$99" /></Element>} <span class="text-base font-medium"> {<Element canvas style={{display:"inline-block"}} id="883831" is="div"><Text text="/Month" /></Element>} </span></h2>
            </div>

            <div class="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl dark:border-gray-700">
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-400 sm:h-9 sm:w-9" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>

                    <div class="flex flex-col items-center mx-5 space-y-1">
                        <h2 class="text-lg font-medium text-gray-700 sm:text-2xl dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="928456" is="div"><Text text="Enterprise" /></Element>} </h2>
                        <div class="px-2 text-xs text-blue-500 bg-gray-100 rounded-full sm:px-4 sm:py-1 dark:bg-gray-700 "> {<Element canvas style={{display:"inline-block"}} id="513765" is="div"><Text text="Save 20%" /></Element>} </div>
                    </div>
                </div>

                <h2 class="text-2xl font-semibold text-gray-500 sm:text-4xl dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="574035" is="div"><Text text="$149" /></Element>} <span class="text-base font-medium"> {<Element canvas style={{display:"inline-block"}} id="418206" is="div"><Text text="/Month" /></Element>} </span></h2>
            </div>

            <div class="flex justify-center">
                <Element canvas style={{ width: "100%" }} id={"div559547"} is="div">
            <Button classn={"px-8 py-2 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="169558" is="div"><Text text="Choose Plan" /></Element>} 
            </Button>
          </Element>
            </div>
        </div>
    </div>
    </div>

      </div>;
};
Pricing2.craft = {
  displayName: "Pricing 2"
};