import { Element, useEditor } from '@craftjs/core';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCog, faPaperPlane} from "@fortawesome/free-solid-svg-icons"
import logo from "./Logo.png"
import Dropdown from "react-dropdown";
import trash from "../../../../../assests/images/trash.svg"
import Editor from "@monaco-editor/react";

import ButtonWithPopup from './ButtonComponent';
import { Input } from 'reactstrap';
import { PopupModal } from './popup';
const ToolboxDiv = styled.div`
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  ${(props) => (!props.enabled ? `width: 0;` : '')}
  ${(props) => (!props.enabled ? `opacity: 0;` : '')}
`;

export const ToolboxDown = ({ expanded, setExpanded}) => {

  const [codeContent, setCodeContent] = useState("")
  const monacoRef = useRef(null);

  function handleEditorWillMount(monaco) {
    // here is the monaco instance
    // do something before editor is mounted
    // monaco.languages.typescript.javascriptDefaults.addExtraLib("declare const testVar : () => 3")
    // monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare const allNodesArr: ${JSON.stringify(allNodes)}`)
    // monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare const allKeysArr: ${JSON.stringify(allKeys)}`)

  }

  function handleEditorDidMount(editor, monaco) {
    // here is another way to get monaco instance
    // you can also store it in `useRef` for further usage
    monacoRef.current = editor;
  }


  const [codeError, setCodeError] = useState("")
  function handleEditorValidation(markers) {
    // model markers
    markers?.length ? markers.forEach((marker) => setCodeError(marker.message)) : setCodeError("")

  }

  const {
    enabled,

    connectors: { create },
  } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));


  console.log(expanded)
  const [activeId, setActiveId]  = useState(null)
  const [actionHeader, setActionHeader] = useState("")


  const [selectedAction, setSelectedAction] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState("")
  const [selectedDirection, setSelectedDirection] = useState("")

  const [selectedMethod, setSelectedMethod] = useState("")
  const [headers, setHeaders] = useState([{id: "9381938917739279381a79897", label: "", value:""}])
  const [filters, setFilters] = useState([{id: "93819389177392793817s9897", label: "", value:""}])
  const [variables, setVariables] = useState([{id: "93819389177392793s8179897", label: "", value:""}])
  const [headerName, setHeaderName] = useState("")
  const [headerValue, setHeaderValue] = useState("")
  const [paramName, setParamName] = useState("")
  const [paramValue, setParamValue] = useState("")
  const [body, setBody] = useState("")
  const [params, setParams] = useState([{id: "938193891773927938179897", label: "", value:""}])
  


  const [activeHeader, setActiveHeader] = useState("Actions")
  const [activeTab, setActiveTab] = useState("header")
  const [toolOpen, setToolOpen] = useState(false)
  const [treeData, setTreeData] = useState([{_id: 1,name:"Page Actions",  children:[]},{_id:2,name:"App Actions", children:[]}]) 
  function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function TreeView({ data }) {
  
  
    function findNodeById(nodeId, nodes) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node._id === nodeId) {
          return node;
        }
        if (node.children) {
          const childNode = findNodeById(nodeId, node.children);
          if (childNode) {
            return childNode;
          }
        }
      }
      return null;
    }
  
    const [editingId, setEditingId] = useState(null);
  console.log(expanded, 111111)
    const handleToggle = (nodeId, selected) => {
      setExpanded((prevState) =>
        prevState.includes(nodeId) ? !selected
          ? prevState.filter((id) => id !== nodeId)
          : [...prevState] : [...prevState, nodeId]
      );
      setActiveId(selected || nodeId)
    };
  
    const handleDoubleClick = (nodeId) => {
      setEditingId(nodeId);
    }; 
  
    const handleEditNode = (nodeId, newName) => {
      const nodeToEdit = findNodeById(nodeId, data);
      nodeToEdit.name = newName;
      setEditingId(null);
      setExpanded(expanded);
    };
  
    const handleAddAction = (parentId) => {
      // generate a unique ID for the new action
      const newActionId = generateRandomString(5);
  
      // create a new action object with default values
      const newAction = {
        _id: newActionId,
        name: "New Action",
        children: [],
      };
  
      // find the parent node in the data array
      const parent = findNodeById(parentId, data);
  
      // add the new action to the parent node's children array
      parent.children.push(newAction);
      handleToggle(parentId, newActionId)
      
    };

    const renderTree = (nodes, child, level = 0) => {
      return (
        <ul style={{ padding: 0, width: "100%" }}>
          {nodes.map((node, i) => {
            const isExpanded = expanded.includes(node._id);
            
            return (
              <li key={node._id}>
                <div
                  style={{
                    width: "100%",
                    borderTop: i == 0 ? "1px solid #2d3038" : "0px",
                    cursor: "pointer",
                    background: "#959dad14",
                    color: activeId == node._id ? "#6db290" : "white"
                  }}
                  className="viewNC"
                  onClick={() => {
                    handleToggle(node._id);
                  }}
                  onDoubleClick={() => { 
                    handleDoubleClick(node._id);
                  }}
                >
                <div style={{width:"1.9rem", height:"1.6rem", background: !child ? "rgba(171, 176, 185, .6)" : "transparent", borderRadius:"3px"}}>{!child ? <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.794 12.104a.644.644 0 00.393-.524 4.904 4.904 0 014.884-4.372 4.91 4.91 0 014.834 4.026.643.643 0 00.626.527 4.53 4.53 0 014.47 4.515 4.521 4.521 0 01-4.517 4.516H9.516a4.514 4.514 0 01-1.722-8.689z" fill="#959dad"></path></svg>: null}</div> 
                {/* {node.children && (
                    <span style={{ marginRight: "0.5rem" }}>
                      {isExpanded ? "▼" : "►"}
                    </span>)} */}
  
                  <span className="viewWN" style={{width:"100%", padding:".125rem .625rem", marginLeft:`${level * 5}px`}}>{node.name}</span>
                  <PopupModal onClick={(e) => e.stopPropagation()} onButtonclicked={() => handleToggle(node._id)}  >
                  <div className='viewNC buttonNC dmColor' style={{margin:"10px auto"}} onClick={() => {
                    
                    handleAddAction(node._id)
                    
                    }}>add action</div>
                </PopupModal>
                 </div>
  
                 {node.children && isExpanded && renderTree(node.children, true, level + 1)}
              </li>
            );
          })}
        </ul>
      );
    };
  
    return <div style={{width:"100%"}}>{renderTree(data)}</div>;
  }

  if(enabled) { 
    return (
    <ToolboxDiv
      enabled={true}
      className="toolbox transition w-12 h-full flex flex-row spaceBetween"
      style={{width:`100vw`, paddingTop:"5px", position:"relative", minHeight:toolOpen ? "fit-content" : "2.5rem", boxShadow: "0 4px 1rem #101426b3",
      backgroundColor:"#1f2026"}}
    >

{
      toolOpen ? (
        activeHeader == "Actions" ? (
          <div style={{height:"500px", width:"100%", display:"flex"}}>
             <div style={{width:"15%" , height:"100%", borderRight:"1px solid #2d3038"}}>
              <TreeView data={treeData} />
             </div>
             {activeId ? <div style={{width:"85%" , height:"100%", height:"100%", overflow:"auto",  background:"#16171c", display:"flex", justifyContent:"flex-start", alignItems:"center", flexDirection:"column" }}>

<div style={{height:"fit-content", display:"flex", width:"99%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>

<Dropdown
                           
controlClassName="auth-input-dm-top half"
placeholder="Select Action"
arrowOpen={<div></div>}
arrowClosed={<div></div>}
value={selectedAction}
onChange={(e) => {
  setSelectedAction(e)
}}
options={[
  {
    type: 'group', label: 'Data', items: [
      {value:"HTTP Request", label:"HTTP Request"},
      {value:"SQL Query", label:"SQL Query"},
      {value:"GraphQL Query", label:"GraphQL Query"},
      {value:"Load Table", label:"Load Table"},
      {value:"Load Row", label:"Load Row"},
      {value:"Create Row", label:"Create Row"},
      {value:"Update Row", label:"Update Row"},
      {value:"Delete Row", label:"Delete Row"},
      {value:"DynamoDB Request", label:"DynamoDB Request"},
      {value:"Redis Command", label:"Redis Command"},
      {value:"S3 Query", label:"S3 Query"},
      {value:"Firebase Query", label:"Firebase Query"},
      {value:"Slack Message", label:"Slack Message"},
    ]
   },
   {
    type: 'group', label: 'Operations', items: [
      {value:"Code",label:"Code"},
      {value:"Execute Action",label:"Execute Action"},
      {value:"Loop Action",label:"Loop Action"},
      {value:"Interval",label:"Interval"},
      {value:"Save to State",label:"Save to State"},
      {value:"Save to Local Storage",label:"Save to Local Storage"},
      {value:"Navigate",label:"Navigate"},
      {value:"Generate File",label:"Generate File"},
      {value:"Show Notification",label:"Show Notification"},
    ]
   },
]} menuClassName="menu-dm-top colored" 
/>

          

            {selectedAction ? (
              <>
                <div>{">"}</div>
<Dropdown
                                      
controlClassName="auth-input-dm-top half"
placeholder="To APi"
arrowOpen={<div></div>}
arrowClosed={<div></div>}
value={selectedAction}
onChange={(e) => {
  setSelectedAction(e.value)
}}
options={[
  {
    type: 'group', label: 'Data', items: [
      {value:"HTTP Request", label:"HTTP Request"},
      {value:"SQL Query", label:"SQL Query"},
      {value:"GraphQL Query", label:"GraphQL Query"},
      {value:"Load Table", label:"Load Table"},
      {value:"Load Row", label:"Load Row"},
      {value:"Create Row", label:"Create Row"},
      {value:"Update Row", label:"Update Row"},
      {value:"Delete Row", label:"Delete Row"},
      {value:"DynamoDB Request", label:"DynamoDB Request"},
      {value:"Redis Command", label:"Redis Command"},
      {value:"S3 Query", label:"S3 Query"},
      {value:"Firebase Query", label:"Firebase Query"},
      {value:"Slack Message", label:"Slack Message"},
    ]
    },
    {
    type: 'group', label: 'Operations', items: [
      {value:"Code",label:"Code"},
      {value:"Execute Action",label:"Execute Action"},
      {value:"Loop Action",label:"Loop Action"},
      {value:"Interval",label:"Interval"},
      {value:"Save to State",label:"Save to State"},
      {value:"Save to Local Storage",label:"Save to Local Storage"},
      {value:"Navigate",label:"Navigate"},
      {value:"Generate File",label:"Generate File"},
      {value:"Show Notification",label:"Show Notification"},
    ]
    },
]} 
menuClassName="menu-dm-top colored" 
/>
</>
): null}

</div>
<div style={{width:"100%", padding:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
{selectedAction?.value == "HTTP Request" ? (
  <>
  
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Request</div>

  <div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
            <Dropdown
            baseClassName="pxMiniWidth Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Method"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={selectedMethod}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              setSelectedMethod(e)
            }}
            options={[
                  {value:"GET", label:"GET"}, 
                  {value:"POST", label:"POST"},
                  {value:"DELETE", label:"DELETE"},
                  {value:"PUT", label:"PUT"},
                  {value:"HEAD", label:"HEAD"},
                  {value:"OPTIONS", label:"Create Row"},
                  {value:"PATCH", label:"PATCH"},
                ] 
            }/>

        <Input
        style={{width:"130px",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Base URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

<Input
        style={{width:"100%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

  </div>
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", color:"#959dad"}}>
    
 
      <div className={`tab-dm ${activeTab == "header" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("header")
        }}>Header</div>
      <div className={`tab-dm ${activeTab == "body" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("body")
        }}>Body</div>
      <div className={`tab-dm ${activeTab == "params" ? "activeHeaderForDm" : ""}`} onClick={() => {
        setActiveTab("params")
        }}>Params</div>
    </div>

 

 { activeTab == "header" ?  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{headers.map(header => (
  <div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
      <Dropdown
            baseClassName="pxMiniWidth ten Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Header"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={header?.name ? {value: header.name, label: header.name} : ""}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              let newHeader = header
              const newHeaderId = generateRandomString(5)
              header.id = header.id || newHeaderId
              header.name = e.value
              setHeaders(headers.map(x => x.id == header.id ? newHeader : x))
            }}
            options={[

{value:"Accept-Charset", label:"Accept-Charset"},
{value:"Accept-Encoding", label:"Accept-Encoding"},
{value:"Accept-Language", label:"Accept-Language"},
{value:"Accept-Datetime", label:"Accept-Datetime"},
{value:"Access-Control-Request-Method", label:"Access-Control-Request-Method"},
{value:"Access-Control-Request-Headers", label:"Access-Control-Request-Headers"},
{value:"Authorization", label:"Authorization"},
{value:"Cache-Control", label:"Cache-Control"},
{value:"Connection", label:"Connection"},
{value:"Content-Length", label:"Content-Length"},
{value:"Content-Type", label:"Content-Type"},
{value:"Cookie", label:"Cookie"},
{value:"Date", label:"Date"},
{value:"Expect", label:"Expect"},
{value:"Forwarded", label:"Forwarded"},
{value:"From", label:"From"},
{value:"Host", label:"Host"},
{value:"If-Match", label:"If-Match"},
{value:"If-Modified-Since", label:"If-Modified-Since"},
{value:"If-None-Match", label:"If-None-Match"},
{value:"If-Range", label:"If-Range"},
{value:"If-Unmodified-Since", label:"If-Unmodified-Since"},
{value:"Max-Forwards", label:"Max-Forwards"},
{value:"Origin", label:"Origin"},
{value:"Proxy-Authorization", label:"Proxy-Authorization"},
{value:"Referer", label:"Referer"},
{value:"User-Agent", label:"User-Agent"},
{value:"Upgrade", label:"Upgrade"},
                ] 
            }/>
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setHeaders(headers.filter(x => x.id !== header.id))
       }} 
       src={trash}/>
  </div>

))}
</div>
<div onClick={() => {
  setHeaders([...headers, {id: generateRandomString(5), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add header</div>

</div> : activeTab == "body" ? (
  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>

</div>
</div>
) : activeTab == "params" ? (
<div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{params.map(param => (
  <div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
     <Input
        style={{width:"10%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param Value"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setParams(params.filter(x => x.id !== param.id))
       }} 
       src={trash}/>
  </div>

))}
</div>
<div onClick={() => {
  setParams([...params, {id: generateRandomString(5), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add param</div>

</div>
) : null}

</>
) : selectedAction?.value == "SQL Query" ? (

  <>

<div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>SQL QUERY
</div>


<div style={{color:"white", fontSize:"10px",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>
Load your data with SQL query. Use component references like {`{{ui.input.value}}`}to send values from forms and inputs.
</div>


  <div style={{ display:"flex", justifyContent:"center", alignItems:"center" ,width:"100%", height:"200px"}}>
  <Editor
  height="90%"
  width="95%"
  defaultLanguage="mysql"
  onValidate={handleEditorValidation}
  onChange={(e) => setCodeContent(e)}
  beforeMount={handleEditorWillMount}
  onMount={handleEditorDidMount}
  />
  </div>
  </>
) : selectedAction?.value == "GraphQL Query" ? (
  <>
  <Input
        style={{width:"98%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        className="auth-input-dm-class"
  />
<div style={{marginTop:"20px", height:"200px", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
<Editor
  height="90%"
  width="95%"
  defaultLanguage="mysql"
  onValidate={handleEditorValidation}
  onChange={(e) => setCodeContent(e)}
  beforeMount={handleEditorWillMount}
  onMount={handleEditorDidMount}
  />
  </div>

  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", color:"#959dad"}}>
    
 
    <div className={`tab-dm ${activeTab == "header" ? "activeHeaderForDm" : ""}`} onClick={() => {
      
      setActiveTab("header")
      }}>Header</div>
    <div className={`tab-dm ${activeTab == "variables" ? "activeHeaderForDm" : ""}`} onClick={() => {
      
      setActiveTab("variables")
      }}>Variables</div>
    <div className={`tab-dm ${activeTab == "params" ? "activeHeaderForDm" : ""}`} onClick={() => {
      setActiveTab("params")
      }}>Params</div>
  </div>



{ activeTab == "header" ?  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{headers.map(header => (
<div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
    <Dropdown
          baseClassName="pxMiniWidth ten Dropdown"
          controlClassName="pxMini auth-input-dm-top half auth-input-dm-class"
          placeholder="Header"
          arrowOpen={<div></div>}
          arrowClosed={<div></div>}
          value={header?.name ? {value: header.name, label: header.name} : ""}
          menuClassName="pxMini menu-dm-top colored" 
          onChange={(e) => {
            let newHeader = header
            const newHeaderId = generateRandomString(5)
            header.id = header.id || newHeaderId
            header.name = e.value
            setHeaders(headers.map(x => x.id == header.id ? newHeader : x))
          }}
          options={[

{value:"Accept-Charset", label:"Accept-Charset"},
{value:"Accept-Encoding", label:"Accept-Encoding"},
{value:"Accept-Language", label:"Accept-Language"},
{value:"Accept-Datetime", label:"Accept-Datetime"},
{value:"Access-Control-Request-Method", label:"Access-Control-Request-Method"},
{value:"Access-Control-Request-Headers", label:"Access-Control-Request-Headers"},
{value:"Authorization", label:"Authorization"},
{value:"Cache-Control", label:"Cache-Control"},
{value:"Connection", label:"Connection"},
{value:"Content-Length", label:"Content-Length"},
{value:"Content-Type", label:"Content-Type"},
{value:"Cookie", label:"Cookie"},
{value:"Date", label:"Date"},
{value:"Expect", label:"Expect"},
{value:"Forwarded", label:"Forwarded"},
{value:"From", label:"From"},
{value:"Host", label:"Host"},
{value:"If-Match", label:"If-Match"},
{value:"If-Modified-Since", label:"If-Modified-Since"},
{value:"If-None-Match", label:"If-None-Match"},
{value:"If-Range", label:"If-Range"},
{value:"If-Unmodified-Since", label:"If-Unmodified-Since"},
{value:"Max-Forwards", label:"Max-Forwards"},
{value:"Origin", label:"Origin"},
{value:"Proxy-Authorization", label:"Proxy-Authorization"},
{value:"Referer", label:"Referer"},
{value:"User-Agent", label:"User-Agent"},
{value:"Upgrade", label:"Upgrade"},
              ] 
          }/>
<Input
      style={{width:"90%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="URL"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
     <img 
     style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
     className="actionHover" 
     onClick={() => {
      setHeaders(headers.filter(x => x.id !== header.id))
     }} 
     src={trash}/>
</div>

))}
</div>
<div onClick={() => {
setHeaders([...headers, {id: generateRandomString(5), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add header</div>

</div> : activeTab == "variables" ? (
  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{variables.map(variable => (
<div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
   <Input
      style={{width:"10%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Variable"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
<Input
      style={{width:"90%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Variable Value"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
     <img 
     style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
     className="actionHover" 
     onClick={() => {
      setVariables(variables.filter(x => x.id !== variable.id))
     }} 
     src={trash}/>
</div>

))}
</div>
<div onClick={() => {
setVariables([...variables, {id: generateRandomString(5), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add variable</div>

</div>
) : activeTab == "params" ? (
<div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{params.map(param => (
<div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
   <Input
      style={{width:"10%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Param"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
<Input
      style={{width:"90%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Param Value"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
     <img 
     style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
     className="actionHover" 
     onClick={() => {
      setParams(params.filter(x => x.id !== param.id))
     }} 
     src={trash}/>
</div>

))}
</div>
<div onClick={() => {
setParams([...params, {id: generateRandomString(5), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add param</div>

</div>
) : null}
    </>
) : selectedAction?.value == "Load Table" ? (
<>
<div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>Filters</div>

<div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>Configure filter to retrieve records.</span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>

<div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{filters.map(filter => (
  <div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
      <Dropdown
                  baseClassName="pxMiniWidth Dropdown"
                  controlClassName="pxMini auth-input-dm-top half"
                  placeholder="Key"
                  arrowOpen={<div></div>}
                  arrowClosed={<div></div>}
                  value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                  menuClassName="pxMini menu-dm-top colored" 
                  onChange={(e) => {
                    let newFilter = filter
                    const newFilterId = generateRandomString(5)
                    filter.id = filter.id || newFilterId
                    filter.name = e.value
                    setfilters(filters.map(x => x.id == filter.id ? newFilter : x))
                  }}
                  options={[]} 
      />
      <Dropdown
            baseClassName="pxMiniWidth Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="operator"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={filter?.name ? {value: filter.name, label: filter.name} : ""}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              let newFilter = filter
              const newFilterId = generateRandomString(5)
              filter.id = filter.id || newFilterId
              filter.name = e.value
              setFilters(headers.map(x => x.id == filter.id ? newFilter : x))
            }}
            options={[
              {label:"=", value:"="},
              {label:"!=", value:"!="},
              {label:"<=", value:"<="},
              {label:"<", value:"<"},
              {label:">", value:">"},
              {label:">=", value:">="},
              {label:"like", value:"like"},
              {label:"in", value:"in"},

                ] 
            }/>
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="{{ui.input.value}}"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setFilters(filters.filter(x => x.id !== filter.id))
       }} 
       src={trash}/>
  </div>
))}
</div>
<div onClick={() => {
  setFilters([...filters, {id: generateRandomString(5), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add filter</div>
</div>

<div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>LIMITS & ORDER</div>
<div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Configure how your data will be limited and sorted.</div>

  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{display:"flex", width:"100%", justifyContent:"center", margin:"4px 0"}}>
  <Input
        style={{width:"50%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Limit"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
      <Input
      style={{width:"50%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Offset"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
    </div>
    <div style={{display:"flex", width:"100%", justifyContent:"center", margin:"4px 0"}}>
    <Dropdown                                
controlClassName="auth-input-dm-top half"
placeholder="Order by"
arrowOpen={<div></div>}
arrowClosed={<div></div>}
value={selectedOrder}
menuClassName="menu-dm-top colored" 
onChange={(e) => {
  setSelectedOrder(e.value)
}}
options={[
]}
/>
  
  <Dropdown                                
controlClassName="auth-input-dm-top half"
placeholder="Direction"
arrowOpen={<div></div>}
arrowClosed={<div></div>}
value={selectedDirection}
menuClassName="menu-dm-top colored" 
onChange={(e) => {
  setSelectedDirection(e.value)
}}
options={[
  {label:"ASC", value:"ASC"},
  {label:"DESC", value:"DESC"},
]}
/>
  
</div>
</div>

<div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>ADDITIONAL PARAMS</div>
<div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Configure additional API parameters (Airtable only).</div>

  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
  <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{params.map(param => (
  <div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
     <Input
        style={{width:"10%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param Value"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setParams(params.filter(x => x.id !== param.id))
       }} 
       src={trash}/>
  </div>

))}
</div>
<div onClick={() => {
  setParams([...params, {id: generateRandomString(5), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add param</div>

</div>

</>

) : selectedAction?.value == "Load Row" ? (
  <>
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>Filters</div>
  
  <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>Configure filter to retrieve records.</span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>
  
  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
  <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
  {filters.map(filter => (
    <div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
        <Dropdown
                    baseClassName="pxMiniWidth Dropdown"
                    controlClassName="pxMini auth-input-dm-top half"
                    placeholder="Key"
                    arrowOpen={<div></div>}
                    arrowClosed={<div></div>}
                    value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                    menuClassName="pxMini menu-dm-top colored" 
                    onChange={(e) => {
                      let newFilter = filter
                      const newFilterId = generateRandomString(5)
                      filter.id = filter.id || newFilterId
                      filter.name = e.value
                      setfilters(filters.map(x => x.id == filter.id ? newFilter : x))
                    }}
                    options={[]} 
        />
        <Dropdown
              baseClassName="pxMiniWidth Dropdown"
              controlClassName="pxMini auth-input-dm-top half"
              placeholder="operator"
              arrowOpen={<div></div>}
              arrowClosed={<div></div>}
              value={filter?.name ? {value: filter.name, label: filter.name} : ""}
              menuClassName="pxMini menu-dm-top colored" 
              onChange={(e) => {
                let newFilter = filter
                const newFilterId = generateRandomString(5)
                filter.id = filter.id || newFilterId
                filter.name = e.value
                setFilters(headers.map(x => x.id == filter.id ? newFilter : x))
              }}
              options={[
                {label:"=", value:"="},
                {label:"!=", value:"!="},
                {label:"<=", value:"<="},
                {label:"<", value:"<"},
                {label:">", value:">"},
                {label:">=", value:">="},
                {label:"like", value:"like"},
                {label:"in", value:"in"},
  
                  ] 
              }/>
  <Input
          style={{width:"90%",margin:"1px 5px"}}
          type={"text"}
          name="styling"
          defaultValue={""}
          maxLength={128}
          placeholder="{{ui.input.value}}"
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     handleLoginClick();
          //   }
          // }}
          className="auth-input-dm-class"
        />
         <img 
         style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
         className="actionHover" 
         onClick={() => {
          setFilters(filters.filter(x => x.id !== filter.id))
         }} 
         src={trash}/>
    </div>
  ))}
  </div>
  <div onClick={() => {
    setFilters([...filters, {id: generateRandomString(5), label:"", value:""}])
  }} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add filter</div>
    
  </div>
  
  </>
  
  ) : selectedAction?.value == "Create Row" ? (
    <>
    <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>CONFIGURE FIELDS
</div>
    
    <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>Configure new record fields. </span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>
    
    <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
    <div style={{ display:"flex", justifyContent:"center", alignItems:"center" ,width:"100%", height:"200px"}}>
  <Editor
  height="90%"
  width="95%"
  defaultLanguage="mysql"
  onValidate={handleEditorValidation}
  onChange={(e) => setCodeContent(e)}
  beforeMount={handleEditorWillMount}
  onMount={handleEditorDidMount}
  />
  </div>
    </div>
    </div>
    
    </>
    
    ) : selectedAction?.value == "Update Row" ? (
      <>
      <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>CONFIGURE FIELDS
  </div>
      
      <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>1. Configure filter to update record(s).</span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>
      
      <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
  {filters.map(filter => (
    <div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
        <Dropdown
                    baseClassName="pxMiniWidth Dropdown"
                    controlClassName="pxMini auth-input-dm-top half"
                    placeholder="Key"
                    arrowOpen={<div></div>}
                    arrowClosed={<div></div>}
                    value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                    menuClassName="pxMini menu-dm-top colored" 
                    onChange={(e) => {
                      let newFilter = filter
                      const newFilterId = generateRandomString(5)
                      filter.id = filter.id || newFilterId
                      filter.name = e.value
                      setfilters(filters.map(x => x.id == filter.id ? newFilter : x))
                    }}
                    options={[]} 
        />
        <Dropdown
              baseClassName="pxMiniWidth Dropdown"
              controlClassName="pxMini auth-input-dm-top half"
              placeholder="operator"
              arrowOpen={<div></div>}
              arrowClosed={<div></div>}
              value={filter?.name ? {value: filter.name, label: filter.name} : ""}
              menuClassName="pxMini menu-dm-top colored" 
              onChange={(e) => {
                let newFilter = filter
                const newFilterId = generateRandomString(5)
                filter.id = filter.id || newFilterId
                filter.name = e.value
                setFilters(headers.map(x => x.id == filter.id ? newFilter : x))
              }}
              options={[
                {label:"=", value:"="},
                {label:"!=", value:"!="},
                {label:"<=", value:"<="},
                {label:"<", value:"<"},
                {label:">", value:">"},
                {label:">=", value:">="},
                {label:"like", value:"like"},
                {label:"in", value:"in"},
  
                  ] 
              }/>
  <Input
          style={{width:"90%",margin:"1px 5px"}}
          type={"text"}
          name="styling"
          defaultValue={""}
          maxLength={128}
          placeholder="{{ui.input.value}}"
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     handleLoginClick();
          //   }
          // }}
          className="auth-input-dm-class"
        />
         <img 
         style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
         className="actionHover" 
         onClick={() => {
          setFilters(filters.filter(x => x.id !== filter.id))
         }} 
         src={trash}/>
    </div>
  ))}
  </div>
  <div onClick={() => {
    setFilters([...filters, {id: generateRandomString(5), label:"", value:""}])
  }} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add filter</div>
    
      
      <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>2. Configure record fields to update. </span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>

      <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
      <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
      <div style={{ display:"flex", justifyContent:"center", alignItems:"center" ,width:"100%", height:"200px"}}>
    <Editor
    height="90%"
    width="95%"
    defaultLanguage="mysql"
    onValidate={handleEditorValidation}
    onChange={(e) => setCodeContent(e)}
    beforeMount={handleEditorWillMount}
    onMount={handleEditorDidMount}
    />
    </div>
      </div>
      </div>
      
      </>
      
      ) : selectedAction?.value == "Delete Row" ? (
        <>
        <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>Filters</div>
        
        <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>Configure filter to delete record(s).</span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>
        
        <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"98%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
        {filters.map(filter => (
          <div style={{height:"fit-content", display:"flex", width:"98%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
              <Dropdown
                          baseClassName="pxMiniWidth Dropdown"
                          controlClassName="pxMini auth-input-dm-top half"
                          placeholder="Key"
                          arrowOpen={<div></div>}
                          arrowClosed={<div></div>}
                          value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                          menuClassName="pxMini menu-dm-top colored" 
                          onChange={(e) => {
                            let newFilter = filter
                            const newFilterId = generateRandomString(5)
                            filter.id = filter.id || newFilterId
                            filter.name = e.value
                            setfilters(filters.map(x => x.id == filter.id ? newFilter : x))
                          }}
                          options={[]} 
              />
              <Dropdown
                    baseClassName="pxMiniWidth Dropdown"
                    controlClassName="pxMini auth-input-dm-top half"
                    placeholder="operator"
                    arrowOpen={<div></div>}
                    arrowClosed={<div></div>}
                    value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                    menuClassName="pxMini menu-dm-top colored" 
                    onChange={(e) => {
                      let newFilter = filter
                      const newFilterId = generateRandomString(5)
                      filter.id = filter.id || newFilterId
                      filter.name = e.value
                      setFilters(headers.map(x => x.id == filter.id ? newFilter : x))
                    }}
                    options={[
                      {label:"=", value:"="},
                      {label:"!=", value:"!="},
                      {label:"<=", value:"<="},
                      {label:"<", value:"<"},
                      {label:">", value:">"},
                      {label:">=", value:">="},
                      {label:"like", value:"like"},
                      {label:"in", value:"in"},
        
                        ] 
                    }/>
        <Input
                style={{width:"90%",margin:"1px 5px"}}
                type={"text"}
                name="styling"
                defaultValue={""}
                maxLength={128}
                placeholder="{{ui.input.value}}"
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     handleLoginClick();
                //   }
                // }}
                className="auth-input-dm-class"
              />
               <img 
               style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
               className="actionHover" 
               onClick={() => {
                setFilters(filters.filter(x => x.id !== filter.id))
               }} 
               src={trash}/>
          </div>
        ))}
        </div>
        <div onClick={() => {
          setFilters([...filters, {id: generateRandomString(5), label:"", value:""}])
        }} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add filter</div>
          
        </div>
        
        </>
        
        ) :  null}
</div>
             </div> : null}
          
          
          </div>
        ) :activeHeader == "Logs" ? (
          <div style={{height:"500px"}}></div>
        ) : activeHeader == "Custom code" ? (
          <div style={{height:"500px"}}></div>
        ) : null
      ) : <div></div>
    }


    <div className='header-dm-left flex flex-row'>
      <div className={`tab-dm ${toolOpen && activeHeader == "Actions" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveHeader("Actions")
        setToolOpen(true)
        }}>Actions</div>
      <div className={`tab-dm ${toolOpen && activeHeader == "Logs" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveHeader("Logs")
        setToolOpen(true)
        }}>Logs</div>
      <div className={`tab-dm ${toolOpen && activeHeader == "Custom code" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveHeader("Custom code")
        setToolOpen(true)
        }}>Custom code</div>
    </div>
   
    <div className='header-dm-right activeHeaderDm' style={{display:"flex", alignItems:"center"}}>
    {!toolOpen ? <><div onClick={() => setToolOpen(true)} style={{width:"15px", height:"15px", marginRight:"7px"}}><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" class="eva eva-diagonal-arrow-right-up-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="diagonal-arrow-right-up"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect><path d="M18 7.05a1 1 0 0 0-1-1L9 6a1 1 0 0 0 0 2h5.56l-8.27 8.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L16 9.42V15a1 1 0 0 0 1 1 1 1 0 0 0 1-1z"></path></g></g></svg></div><div onClick={() => setToolOpen(true)} className={`tab-dm`}>{activeHeader}</div></> : <><div onClick={() => {
      setToolOpen(false)
    }} style={{width:"15px", height:"15px", marginRight:"7px"}}><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" class="eva eva-minus-outline" fill="currentColor"><g data-name="Layer 2"><g data-name="minus"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect><path d="M19 13H5a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2z"></path></g></g></svg></div><div onClick={() => {
      setToolOpen(false)
    }} className={`tab-dm`}>Hide</div></>}
    </div>
    </ToolboxDiv>
  )
  } else {
    return <div></div>
  }
}
