import React from "react";
import { useDispatch } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";

const TextInput = (props) => {
    const {
        type,
        label,
        value,
        setValue,
        placeHolder,
        error,
        setError,
        nextLabel,
        area
    } = props
  const dispatch = useDispatch();

  const emailTyping = e => {
    setValue(e.currentTarget.value);
    setError && setError("")
  };

  return (
    <FormGroup>
      <Label for={type} style={{width:"100%"}}>
        <div className="auth-label" style={{justifyContent:"space-between", display:"flex", width:"100%"}}><div>{label}</div> <div>{error ? (
        <div className="auth-error">
            error
        </div> 
      ) : nextLabel ? (<div>{nextLabel}</div>) : null}</div></div>
        
      </Label>
      <Input
        type={area ? "textarea" : "text"}
        name={type}
        defaultValue={value}
        maxLength={128}
        style={{height: area ? 200 : 50}}
        placeholder={placeHolder}
        className={`auth-input ${area ? "textarea" : "nonCode"} ${ error
        ? "auth-error-border"
        : ""}`}
        onChange={emailTyping}
        invalid={error ? true : false}
      />
    </FormGroup>
  );
};

export default TextInput;
