import React from "react";
import "./index.scss"
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";


const AdminDashboard = () => {
        const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }

    return (
        <div>            
            {returnToHomeScreen()}

            home
        </div>
    )
}

export default AdminDashboard