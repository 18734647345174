import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Navigation6 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        

    <nav x-data="{ isOpen: false }" class="relative bg-white shadow dark:bg-gray-800">
    <div class="container px-6 py-3 mx-auto">
        <div class="flex flex-col md:flex-row md:justify-between md:items-center">
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <Element canvas style={{ width: "100%" }} id={"div910870"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 transition-colors duration-300 transform dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="750041" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>

                    <div class="hidden mx-10 md:block">
                        <div class="relative">
                            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg class="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                                    <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </span>

                            <input type="text" class="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300" placeholder="Search" />
                        </div>
                    </div>
                </div>

                
                <div class="flex lg:hidden">
                    <Element canvas style={{ width: "100%" }} id={"div907325"} is="div">
            <Button classn={"text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"}>
              
                        <svg x-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                        </svg>
                
                        <svg x-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    
            </Button>
          </Element>
                </div>
            </div>

           
            <div class="absolute inset-x-0 z-20 w-full px-6 py-2 transition-all duration-300 ease-in-out bg-white top-24 dark:bg-gray-800 md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center">
                <div class="flex flex-col md:flex-row md:mx-1">
                    <Element canvas style={{ width: "100%" }} id={"div844529"} is="div">
            <Button href={"#"} classn={"my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:mx-4 md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="901865" is="div"><Text text="Home" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div260058"} is="div">
            <Button href={"#"} classn={"my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:mx-4 md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="684216" is="div"><Text text="Blog" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div525446"} is="div">
            <Button href={"#"} classn={"my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:mx-4 md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="997493" is="div"><Text text="Compoents" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div641574"} is="div">
            <Button href={"#"} classn={"my-2 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:mx-4 md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="634001" is="div"><Text text="Courses" /></Element>} 
            </Button>
          </Element>
                </div>

                <div class="my-4 md:hidden">
                    <div class="relative">
                        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg class="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                                <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </span>

                        <input type="text" class="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300" placeholder="Search" />
                    </div>
                </div>
            </div>
        </div>

        <div class="flex py-3 mt-3 -mx-3 overflow-y-auto whitespace-nowrap scroll-hidden">
            <Element canvas style={{ width: "100%" }} id={"div508659"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="409487" is="div"><Text text="News" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div215808"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="390154" is="div"><Text text="Articles" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div850543"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="516894" is="div"><Text text="Videos" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div865047"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="385782" is="div"><Text text="Tricks" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div129855"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="287722" is="div"><Text text="PHP" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div639420"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="877626" is="div"><Text text="Laravel" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div390332"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="213298" is="div"><Text text="Vue" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div433884"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="565527" is="div"><Text text="React" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div558716"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="162229" is="div"><Text text="Tailwindcss" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div959629"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="105609" is="div"><Text text="Meraki UI" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div388318"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="945542" is="div"><Text text="CPP" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div325764"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="832863" is="div"><Text text="JavaScript" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div578697"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="241707" is="div"><Text text="Ruby" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div665363"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="839930" is="div"><Text text="Mysql" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div557959"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="585806" is="div"><Text text="Pest" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div492349"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="510023" is="div"><Text text="PHPUnit" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div544311"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="555372" is="div"><Text text="Netlify" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div422638"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="646097" is="div"><Text text="VS Code" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div336796"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="455888" is="div"><Text text="PHPStorm" /></Element>} 
            </Button>
          </Element>
            <Element canvas style={{ width: "100%" }} id={"div694837"} is="div">
            <Button href={"#"} classn={"mx-4 text-sm leading-5 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:underline md:my-0"}>
               {<Element canvas style={{display:"inline-block"}} id="533691" is="div"><Text text="Sublime" /></Element>} 
            </Button>
          </Element>
        </div>
    </div>
    </nav>

      </div>;
};
Navigation6.craft = {
  displayName: "Navigation 6"
};