import React from "react";
import { useDispatch } from "react-redux";
// import { addAge, addName } from "../redux/features/messages-slice";

const ActionProvider = ({
  createChatBotMessage,
  setState,
  children,
}) => {
  const dispatch = useDispatch();
  const handleGotIt = () => {
    const botMessage = createChatBotMessage("Enter your Name", {});

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const handleUserInput = (age) => {
    setState(
      (prev) => {
        let botMessage;
        if (
          prev.messages[prev.messages.length - 2].message === "Enter your Name"
        ) {
          // dispatch(addName(prev.messages[prev.messages.length - 1].message));
          const botMessage = createChatBotMessage("Enter your Email", {});
          return {
            ...prev,
            messages: [...prev.messages, botMessage],
          };
        } else if (
          prev.messages[prev.messages.length - 2].message === "Enter your Email"
        ) {
          // dispatch(addName(prev.messages[prev.messages.length - 1].message));
          const botMessage = createChatBotMessage("Enter your Feedback", {});
          return {
            ...prev,
            messages: [...prev.messages, botMessage],
          };
        } else if (
          prev.messages[prev.messages.length - 2].message === "Enter your Feedback"
        ) {
          botMessage = createChatBotMessage(
            "Thank you.",
            {}
          );
          return {
            ...prev,
            messages: [...prev.messages, botMessage],
          };
        } else if (age) {
          // dispatch(addAge(age.toString()));
          botMessage = createChatBotMessage(
            "Thank you. In 5 seconds, bot will exit.",
            {}
          );
          return {
            ...prev,
            messages: [...prev.messages, botMessage],
          };
        } else {
          return prev;
        }
      }
    );
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleGotIt,
            handleUserInput,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
