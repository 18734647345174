import { gql } from "apollo-boost";

const addWorkspaceMutation = gql`
  mutation(
    $user: ID,
    $name: String,
    $users: [String]
    $editors: [String]
  ) {
    addWorkspace(
    user:$user,
    name:$name,
    users: $users
    editors: $editors
    ) {
    _id
    user,
    name,
    users,
    editors
    }
  }
`;

export default addWorkspaceMutation;
