import { useState } from 'react';

function ButtonWithPopup({ text, option1, option2 }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleOptionClick = (option) => {
    setIsPopupOpen(false);
    // Do something with the selected option
    console.log(`You clicked ${option}`);
  };

  return (
    <div className="button-with-popup">
      <div className='viewNC buttonNC' onClick={handleButtonClick}>{text}</div>
      {isPopupOpen && (
        <div className="popup">
          <div className='viewNC' onClick={() => handleOptionClick(option1)}>{option1}</div>
          <div className='viewNC' onClick={() => handleOptionClick(option2)}>{option2}</div>
        </div>
      )}
    </div>
  );
}

export default ButtonWithPopup;
