import * as React from 'react';
import './index.scss';
import { TreeMapComponent, LevelsDirective, LevelDirective, Inject, TreeMapLegend, TreeMapTooltip } from '@syncfusion/ej2-react-treemap';
import { SampleBase } from './sample-base';
import * as data from './datasource.json';

let datasource = data;
const SAMPLE_CSS = `
    .control-fluid {
		padding: 0px !important;
		}`;
class Component extends SampleBase {
    treemapInstance;
    prevTime;
    curTime;
    load(args) {
    }
    /* tslint:disable:no-string-literal */
    itemMove(args) {
        args.item['data'].Sales = args.item['weight'];
        args.treemap.tooltipSettings.format = args.item['groupIndex'] === 0 ? 'Country: ${Continent}<br/>Sales: ${Sales}' :
            'Country: ${Continent}<br/>Company: ${Company}<br/>Sales: ${Sales}';
    }
    render() {
        return (<div className='control-pane'>
				<style>
					{SAMPLE_CSS}
				</style>
				<div className='control-section'>
					<TreeMapComponent 
          {...this.props.allEvents}
          itemClick={this.itemMove.bind(this)} itemMove={this.itemMove.bind(this)} load={this.load.bind(this)} id='treemap-container' titleSettings={{
                text: 'Car Sales by Country - 2017',
                textStyle: { size: '15px' }
            }} rangeColorValuePath='Sales' format={"n"} useGroupingSeparator={true} dataSource={datasource.car_sale} legendSettings={{
                visible: true,
                position: 'Top',
                shape: 'Rectangle'
            }} palette={['#C33764', '#AB3566', '#993367', '#853169', '#742F6A', '#632D6C', '#532C6D', '#412A6F', '#312870', '#1D2671']} tooltipSettings={{ visible: true }} // To config tooltip for treemap 
         weightValuePath='Sales' leafItemSettings={{
                labelPath: 'Company',
                border: { color: 'white', width: 0.5 }
            }}>
						<Inject services={[TreeMapLegend, TreeMapTooltip]}/>
						<LevelsDirective>
							<LevelDirective groupPath='Continent' border={{ color: 'white', width: 0.5 }}/>
						</LevelsDirective>
					</TreeMapComponent>
				</div>
				<div style={{ float: 'right', marginRight: '10px' }}>Source:
       <a href="https://www.factorywarrantylist.com/car-sales-by-country.html/" target="_blank">www.factorywarrantylist.com</a>
				</div>

			</div>
      ); 
    } 
}

export default Component