import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Pricing3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <div class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-8 mx-auto">
        <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="593810" is="div"><Text text="Simple pricing plan" /></Element>} </h1>

        <p class="max-w-2xl mx-auto mt-4 text-center text-gray-500 xl:mt-6 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="128404" is="div"><Text text="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias quas magni libero consequuntur voluptatum velit amet id repudiandae ea, deleniti laborum in neque eveniet." /></Element>} </p>

        <div class="grid grid-cols-1 gap-8 mt-6 lg:grid-cols-3 xl:mt-12">
            <div class="flex items-center justify-between px-8 py-4 border cursor-pointer rounded-xl dark:border-gray-700">
                <div class="flex flex-col items-center space-y-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-400 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>

                    <h2 class="text-lg font-medium text-gray-700 sm:text-xl dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="669111" is="div"><Text text="Basic" /></Element>} </h2>
                </div>

                <h2 class="text-2xl font-semibold text-gray-500 sm:text-3xl dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="697324" is="div"><Text text="Free" /></Element>} </h2>
            </div>

            <div class="flex items-center justify-between px-8 py-4 border border-blue-500 cursor-pointer rounded-xl">
                <div class="flex flex-col items-center space-y-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-600 dark:text-blue-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>

                    <h2 class="text-lg font-medium text-gray-700 sm:text-xl dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="484523" is="div"><Text text="Standard" /></Element>} </h2>
                </div>


                <div class="flex flex-col items-center space-y-1">
                    <div class="px-2 text-xs text-blue-500 bg-gray-100 rounded-full dark:text-blue-400 sm:px-4 sm:py-1 dark:bg-gray-700 "> {<Element canvas style={{display:"inline-block"}} id="891219" is="div"><Text text="Save 30%" /></Element>} </div>

                    <h2 class="text-2xl font-semibold text-blue-600 dark:text-blue-500 sm:text-3xl"> {<Element canvas style={{display:"inline-block"}} id="830436" is="div"><Text text="$99" /></Element>} <span class="text-base font-medium"> {<Element canvas style={{display:"inline-block"}} id="964123" is="div"><Text text="/Yearly" /></Element>} </span></h2>
                </div>
            </div>

            <div class="flex items-center justify-between px-8 py-4 border cursor-pointer rounded-xl dark:border-gray-700">
                <div class="flex flex-col items-center space-y-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-400 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>

                    <h2 class="text-lg font-medium text-gray-700 sm:text-xl dark:text-gray-200"> {<Element canvas style={{display:"inline-block"}} id="803016" is="div"><Text text="Pro" /></Element>} </h2>
                </div>

                <div class="flex flex-col items-center space-y-1">
                    <div class="px-2 text-xs text-blue-500 bg-gray-100 rounded-full dark:text-blue-400 sm:px-4 sm:py-1 dark:bg-gray-700 "> {<Element canvas style={{display:"inline-block"}} id="701334" is="div"><Text text="Save 20%" /></Element>} </div>

                    <h2 class="text-2xl font-semibold text-gray-500 sm:text-3xl dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="399105" is="div"><Text text="$149" /></Element>} <span class="text-base font-medium"> {<Element canvas style={{display:"inline-block"}} id="371552" is="div"><Text text="/Month" /></Element>} </span></h2>
                </div>
            </div>
        </div>

        <div class="p-8 mt-8 space-y-8 bg-gray-100 dark:bg-gray-800 rounded-xl">
            <div class="flex items-center justify-between text-gray-800 dark:text-gray-200">
                <p class="textlg sm:text-xl"> {<Element canvas style={{display:"inline-block"}} id="366388" is="div"><Text text="Unlimited Links" /></Element>} </p>

                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
            </div>

            <div class="flex items-center justify-between text-gray-800 dark:text-gray-200">
                <p class="textlg sm:text-xl"> {<Element canvas style={{display:"inline-block"}} id="616448" is="div"><Text text="Own analytics platfrom" /></Element>} </p>

                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
            </div>

            <div class="flex items-center justify-between text-gray-800 dark:text-gray-200">
                <p class="textlg sm:text-xl"> {<Element canvas style={{display:"inline-block"}} id="624557" is="div"><Text text="Full Support with discussion" /></Element>} </p>

                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
            </div>

            <div class="flex items-center justify-between text-gray-800 dark:text-gray-200">
                <p class="textlg sm:text-xl"> {<Element canvas style={{display:"inline-block"}} id="952991" is="div"><Text text="Optimize hashtags" /></Element>} </p>

                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-red-400 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                </svg>
            </div>

            <div class="flex items-center justify-between text-gray-800 dark:text-gray-200">
                <p class="textlg sm:text-xl"> {<Element canvas style={{display:"inline-block"}} id="388008" is="div"><Text text="Mobile app" /></Element>} </p>

                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
            </div>

            <div class="flex items-center justify-between text-gray-800 dark:text-gray-200">
                <p class="textlg sm:text-xl"> {<Element canvas style={{display:"inline-block"}} id="678642" is="div"><Text text="Unlimited users" /></Element>} </p>

                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-red-400 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>

        <div class="flex justify-center mt-8">
            <Element canvas style={{ width: "100%" }} id={"div767671"} is="div">
            <Button classn={"px-8 py-2 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"}>
               {<Element canvas style={{display:"inline-block"}} id="974930" is="div"><Text text="Choose Plan" /></Element>} 
            </Button>
          </Element>
        </div>
    </div>
    </div>

      </div>;
};
Pricing3.craft = {
  displayName: "Pricing 3"
};