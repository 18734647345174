import { Editor, Frame, Element, useEditor } from '@craftjs/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import "./index.scss";
import "./nex.scss";
import { Viewport, RenderNode } from './editor';
import { Container, Text } from './selectors';
import { Button } from './selectors/others/Button';
import { Custom1, OnlyButtons } from './selectors/others/Custom1';
import { Custom2, Custom2VideoDrop } from './selectors/others/Custom2';
import { Custom3, Custom3BtnDrop } from './selectors/others/Custom3';
import { Video } from './selectors/others/Video';
import {AccordionN} from "./selectors/others/Accordion"
import {CardWidget} from "./selectors/others/Card"
import {GridWidget} from "./selectors/others/Grid"
import {FormBuilderWidget} from "./selectors/others/FormBuilder"
import {Scheduler} from "./selectors/calendars/Scheduler"
import {Calendar} from "./selectors/calendars/Calendar"
import {DatePicker} from "./selectors/calendars/DatePicker"
import {DateRangePicker} from "./selectors/calendars/DateRangePicker"
import {DateTimePicker} from "./selectors/calendars/DateTimePicker"
import {GantChart} from "./selectors/calendars/GantChart"
import {AutoComplete} from "./selectors/dropdowns/AutoComplete"
import {CompoBox} from "./selectors/dropdowns/CompoBox"
import {DropDownList} from "./selectors/dropdowns/DropDownList"
import {DropDownTree} from "./selectors/dropdowns/DropDownTree"
import {DropDownMulti} from "./selectors/dropdowns/DropDownMulti"
import {Mention} from "./selectors/dropdowns/Mention"
import {ListBox} from "./selectors/dropdowns/ListBox"
import {Accordion} from "./selectors/navigation/Accordion"
import {AppBar} from "./selectors/navigation/AppBar"
import {Carousel} from "./selectors/navigation/Carousel"
import {Context} from "./selectors/navigation/Context"
import {SideBar} from "./selectors/navigation/SideBar"
import {Wizard} from "./selectors/navigation/Wizard"
import {FileManager} from "./selectors/navigation/FileManager"
import {Message} from "./selectors/notifications/Message"
import {Badge} from "./selectors/notifications/Badge"
import {Toast} from "./selectors/notifications/Toast"
import {Input} from "./selectors/inputs/Input"
import {Mask} from "./selectors/inputs/Mask"
import {Numeric} from "./selectors/inputs/Numeric"
import {Signture} from "./selectors/inputs/Signture"
import {Button as ButtonOne} from "./selectors/inputs/Button"
import {Slider} from "./selectors/inputs/Slider"
import {FileUploader} from "./selectors/inputs/FileUploader"
import {QueryForm} from "./selectors/forms/QueryForm"
import {DropButton} from "./selectors/buttons/DropButton"
import {SpeedDial} from "./selectors/buttons/SpeedDial"
import {FloatingButton} from "./selectors/buttons/FloatingButton"
import {Dashboard} from "./selectors/layout/Dashboard"
import {Splitter} from "./selectors/layout/Splitter"
import {ListView} from "./selectors/layout/ListView"
import {Dialoag} from "./selectors/layout/Dialoag"
import {FlippedCard} from "./selectors/layout/FlippedCard"
import {AvatarCard} from "./selectors/layout/AvatarCard"
import {PDF} from "./selectors/editors/PDF"
import {ImageEditor} from "./selectors/editors/ImageEditor"
import {WordProcessor} from "./selectors/editors/WordProcessor"
import {TreeGrid} from "./selectors/grids/TreeGrid"
import {PivotTable} from "./selectors/grids/PivotTable"
import {DataGrid} from "./selectors/grids/DataGrid"


import {Code39} from "./selectors/dataVisualization/barcode/code39"
import {Eancode} from "./selectors/dataVisualization/barcode/ean"
import {Qrcode} from "./selectors/dataVisualization/barcode/qrcode"

import {BulletChart} from "./selectors/dataVisualization/charts/bullet"
import {Map} from "./selectors/dataVisualization/charts/map"
import {SmithChart} from "./selectors/dataVisualization/charts/smith"
import {MixedCharts} from "./selectors/dataVisualization/charts/mixedChart"
import {Treemap} from "./selectors/dataVisualization/charts/treemap"

import {Kanban} from "./selectors/dataVisualization/kanban/"




import lz from "lzutf8";
 
const theme = createTheme({
  typography: {
    fontFamily: [
      'acumin-pro',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

function NewEditor({
disabled,
isApp,
user,
html,
type,
shareables,
workspace,
shareableRefetch,
_id,
view,
viewsRefetch,
editMode,
setEditMode,
views,
setActiveView,
addUserToView,
addUserToApp,
setViewAddModal,
setAppAddModal,
setViewSettings,
dataBasesData,
dataBasesLoading,
viewSettings,
setBuilderModal,
builderModal,
widgetSettings,
widgetName,
dataBaseUrl,
setDataBaseUrl,
addDataBase,
dataBaseDb,
setDataBaseDb,
dataBaseAllDb,
setDataBaseAllDb,
dataBaseTable,
setDataBaseTable,
dataBaseAllTable,
setDataBaseAllTable,
formBuilder,
eventsData,
eventsLoading,
}) {

  const [expanded, setExpanded] = useState([])
  return (
    <ThemeProvider theme={theme}>
      <div className="h-full h-screen">
        {<Editor
          // enabled={false}
          resolver={{
            Container,
            Text,
            Custom1,
            Custom2,
            Custom2VideoDrop,
            Custom3,
            Custom3BtnDrop,
            OnlyButtons,
            Button,
            Video,
            CardWidget,
            AccordionN,
            GridWidget,
            FormBuilderWidget,
            Scheduler,
            Calendar,
            DatePicker,
            DateRangePicker,
            DateTimePicker,
            GantChart,
            AutoComplete,
            CompoBox,
            DropDownList,
            DropDownTree,
            DropDownMulti,
            Mention,
            ListBox,
            Accordion,
            AppBar,
            Carousel,
            Context,
            SideBar,
            Wizard,
            FileManager,
            Message,
            Badge,
            Toast,
            Input,
            Mask,
            Numeric,
            Signture,
            Button: ButtonOne,
            Slider,
            FileUploader,
            QueryForm, 
            DropButton,
            SpeedDial,
            Dashboard,
            Splitter,
            ListView,
            Dialoag,
            FlippedCard,
            AvatarCard,
            PDF,
            ImageEditor,
            WordProcessor,
            TreeGrid,
            PivotTable,
            DataGrid,
            Code39,
            Eancode,
            Qrcode,
            BulletChart,
            Map,
            SmithChart,
            MixedCharts,
            Treemap,
            Kanban,
            
          }}
          onRender={RenderNode}
        >
          {console.log("lolololo")}
          <Viewport
          expanded={expanded}
          setExpanded={setExpanded}
          isApp={isApp}
          shareableRefetch={shareableRefetch}
          shareables={shareables}
          setViewSettings={setViewSettings}
          viewSettings={viewSettings}
          setBuilderModal={setBuilderModal}
          builderModal={builderModal}
          dataBasesData={dataBasesData}
          dataBasesLoading={dataBasesLoading}
          eventsData={eventsData}
          eventsLoading={eventsLoading}
          user={user}
          html={html}
          type={type}
          workspace={workspace}
          _id={_id}
          view={view}
          viewsRefetch={viewsRefetch}
          editMode={editMode}
          setEditMode={setEditMode}
          views={views}
          setActiveView={setActiveView}
          addUserToView={addUserToView}
          addUserToApp={addUserToApp}
          setViewAddModal={setViewAddModal}
          setAppAddModal={setAppAddModal}
          widgetSettings={widgetSettings}
          widgetName={widgetName}
          formBuilder={formBuilder}
          dataBaseUrl={dataBaseUrl}
          setDataBaseUrl={setDataBaseUrl}
          addDataBase={addDataBase}
          dataBaseDb={dataBaseDb}
          setDataBaseDb={setDataBaseDb}
          dataBaseAllDb={dataBaseAllDb}
          setDataBaseAllDb={setDataBaseAllDb}
          dataBaseTable={dataBaseTable}
          setDataBaseTable={setDataBaseTable}
          dataBaseAllTable={dataBaseAllTable}
          setDataBaseAllTable={setDataBaseAllTable}
          >
            <Frame className="ilililililil">
            </Frame>
          </Viewport>
        </Editor>
        }
      </div>
    </ThemeProvider>
  );
}

export default NewEditor;