import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  return (
    <div style={{height:"100%", overflow:"auto"}}>
    <ToolbarSection
   title="Dialoag header"
 >
  <ToolbarItem
    full={true}
    propKey="header"
    type="text"
    label="Dialoag header"
  />
  </ToolbarSection>

  <ToolbarSection
   title="Dialoag Content"
 >
  <ToolbarItem
    full={true}
    propKey="content"
    type="text"
    label="Dialoag Content"
  />
  </ToolbarSection>

</div>
  );
};
