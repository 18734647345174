import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
import { Button } from '../Button';
export const Cta1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container flex flex-col items-center px-4 py-12 mx-auto text-center">
        <h2 class="text-3xl font-semibold tracking-tight text-gray-700 sm:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="827153" is="div"><Text text="Join us and get the update" /></Element>} <br /> {<Element canvas style={{display:"inline-block"}} id="292190" is="div"><Text text="from anywhere" /></Element>} </h2>

        <div class="mt-6 sm:-mx-2">
            <div class="inline-flex w-full sm:w-auto sm:mx-2">
                <Element canvas style={{width:"100%"}} id="div1" is="div"> <Button href="#" classn="inline-flex items-center justify-center w-full px-5 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">{<Element canvas style={{display:"inline-block"}} id="887586" is="div"><Text text="Buy Blox Now" /></Element>}</Button></Element>
            </div>

            <div class="inline-flex w-full mt-4 sm:w-auto sm:mx-2 sm:mt-0">
            <Element canvas style={{width:"100%"}} id="div2" is="div"> <Button href="#" classn="inline-flex items-center justify-center w-full px-5 py-2 text-gray-700 transition-colors duration-150 transform bg-white border border-gray-200 rounded-lg dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-100 dark:text-white sm:w-auto dark:hover:bg-gray-800 dark:ring-gray-700 focus:ring focus:ring-gray-200 focus:ring-opacity-80"> {<Element canvas style={{display:"inline-block"}} id="740829" is="div"><Text text="Start a Journey" /></Element>} </Button></Element>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Cta1.craft = {
  displayName: "Cta 1"
};