const TRIGGER_VIEW = "TRIGGER_VIEW";

const ACTION_TRIGGER_VIEW = (payload) => {
  return {
    type: TRIGGER_VIEW,
    payload
  };
};

export default ACTION_TRIGGER_VIEW;
 