import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";


import ReactDOM from "react-dom";
import { ApolloProvider} from "@apollo/react-hooks";
import { createStore, applyMiddleware } from "redux";
import { Provider, useDispatch, useSelector} from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension"; 
import findAndReplaceDOMText from "@thehonestscoop/findandreplacedomtext"
import { registerLicense } from '@syncfusion/ej2-base';
import { 
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,  
  useHistory
} from "react-router-dom";
 import { useLazyQuery, useQuery, useMutation } from "@apollo/client"; 
import RootReducer from "./RootReducer";

import apolloClient from "./apolloClient";


import Modal from "react-modal";
import { AliveScope } from "react-activation";

import "./style.scss"
import Home from "./components/Home";
import AdminRouter from "./components/admin/AdminRouter";


import ACTION_USER_AUTHENTICATED from "./actions/user/Authenticated/ACTION_USER_AUTHENTICATED";
import ACTION_USER_NOT_AUTHENTICATED from "./actions/user/Authenticated/ACTION_USER_NOT_AUTHENTICATED";
import ACTION_DUMMY_TOKEN from "./actions/user/Login/DummyToken/ACTION_DUMMY_TOKEN";
import ACTION_DUMMY_TOKEN_RESET from "./actions/user/Login/DummyToken/ACTION_DUMMY_TOKEN_RESET";
import ACTION_TEMPORARY_DUMMY_TOKEN from "./actions/user/Login/TemporaryDummyToken/ACTION_TEMPORARY_DUMMY_TOKEN";
import ACTION_TEMPORARY_DUMMY_TOKEN_RESET from "./actions/user/Login/TemporaryDummyToken/ACTION_TEMPORARY_DUMMY_TOKEN_RESET";
// loading spinner

import ACTION_LOADING_SPINNER_ACTIVE from "./actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "./actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";

import getUserQuery from "./graphql/queries/user/getUserQuery"
import allIndustrysQuery from "./graphql/queries/industry/allIndustrysQuery"
import allUsersQuery from "./graphql/queries/user/allUsersQuery"

import allGroupsQuery from "./graphql/queries/group/allGroupsQuery"
import allDepartmentsQuery from "./graphql/queries/department/allDepartmentsQuery"
import allKeywordsQuery from "./graphql/queries/keyword/allKeywordsQuery"
import Cookies, { set } from "js-cookie";
import jwt from "jsonwebtoken";


import Spinner from "./components/resuable/spinner" 
import updateUserInvalidateTokensMutation from "./graphql/mutations/user/updateUserInvalidateTokensMutation";
import allCollectionsQuery from "./graphql/queries/collection/allCollectionsQuery";
import allShareableQuery from "./graphql/queries/Shareable/allShareableQuery";
import allTemplatesQuery from "./graphql/queries/template/allTemplatesQuery";
import allThemesQuery from "./graphql/queries/theme/allThemesQuery";

// Registering Syncfusion license key

const middleware = [thunk];
const store = createStore( 
  RootReducer,
  process.env.REACT_APP_NODE_ENV === "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware))
);

const App = () => {
useEffect(() => {
  registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFac15JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdhX35fcndQRGdfVEE=');
},[])
  const history = useHistory()
  const dispatch = useDispatch();
  const location = useLocation();
  const userAuthenticated = useSelector(
    (state) => state.userAuthenticated.user_authenticated
  );
  const activeOrg = useSelector((state) => state.activeOrg.org);
  const dummyToken = useSelector((state) => state.dummyToken.dummy_token);
  const temporaryDummyToken = useSelector((state) => state.temporaryDummyToken.temporary_dummy_token);

  const [allUsers, { error: usersError, data: usersData, loading: usersLoading, refetch: usersRefetch }] = useLazyQuery(allUsersQuery, {
    fetchPolicy: "no-cache",
  });

  const [allShareable, { error: shareableError, data: shareableData, loading: shareableLoading, refetch: shareableRefetch }] = useLazyQuery(allShareableQuery, {
    fetchPolicy: "no-cache",
  });

  const [allIndustrys, { error: industrysError, data: industrysData, loading: industrysLoading, refetch: industrysRefetch }] = useLazyQuery(allIndustrysQuery, {
    fetchPolicy: "no-cache",
  });

  const [allTemplates, { error: templatesError, data: templatesData, loading: templatesLoading, refetch: templatesRefetch }] = useLazyQuery(allTemplatesQuery, {
    fetchPolicy: "no-cache",
  });


  const [allAdmins, { error: adminsError, data: adminsData, loading: adminsLoading, refetch: adminsRefetch }] = useLazyQuery(allUsersQuery, {
    fetchPolicy: "no-cache",
  });
  const [allGroups, { error: teamsError, data: teamsData, loading: teamsLoading, refetch: teamsRefetch }] = useLazyQuery(allGroupsQuery, {
    fetchPolicy: "no-cache",
  });

  const [allDepartments, { error: departmentsError, data: departmentsData, loading: departmentsLoading, refetch: departmentsRefetch }] = useLazyQuery(allDepartmentsQuery, {
    fetchPolicy: "no-cache",
  });

  const [allKeywords, { error: keywordsError, data: keywordsData, loading: keywordsLoading, refetch: keywordsRefetch }] = useLazyQuery(allKeywordsQuery, {
    fetchPolicy: "no-cache",
  });


  const [ allThemes, { error :allThemesError, data :allThemesData, loading :allThemesLoading, refetch :allThemesRefetch }] = useLazyQuery(allThemesQuery, {
    fetchPolicy: "no-cache",
  });



  const [allTables, { error: tablesError, data: tablesData, loading: tablesLoading, refetch: tablesRefetch }] = useLazyQuery(allCollectionsQuery, {
    fetchPolicy: "no-cache",
  });


  console.log(
userAuthenticated, 10910, "user"
  )

  useEffect(() => {


    let currentDummyToken;
    let temporaryUserDummyToken;
    const checkCookies = () => {

      if (
        currentDummyToken !== Cookies.get("dummy-token") ||
        temporaryUserDummyToken !== Cookies.get("temporary-dummy-token")
      ) {
        currentDummyToken = Cookies.get("dummy-token");
        temporaryUserDummyToken = Cookies.get("temporary-dummy-token");

        if (currentDummyToken) {
          if (!dummyToken) {
            dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET());
            dispatch(ACTION_DUMMY_TOKEN(jwt.decode(currentDummyToken)));
          }
          if (!userAuthenticated) {
             dispatch(ACTION_USER_AUTHENTICATED());
          }
        } else if (temporaryUserDummyToken) {
          if (temporaryUserDummyToken) {
            if (!temporaryDummyToken) {
              dispatch(
                ACTION_TEMPORARY_DUMMY_TOKEN(
                  jwt.decode(temporaryUserDummyToken)
                )
              );
            }
            if (userAuthenticated) {
              dispatch(ACTION_USER_NOT_AUTHENTICATED());
            }
          } else {
            if (temporaryDummyToken) {
              dispatch(ACTION_TEMPORARY_DUMMY_TOKEN_RESET());
            }

            if (userAuthenticated) {
              dispatch(ACTION_USER_NOT_AUTHENTICATED());
            }
          }
        }
      }
    };

    setInterval(checkCookies, 100);
  }, [
    dispatch,
    location.pathname,
    userAuthenticated,
    dummyToken,
    temporaryDummyToken
  ]);

    const {
    data: getUserData,
    error: getUserError,
    loading: getUserLoading,
    refetch: userDataRefetch,
    subscribeToMore: userSubscribeToMore,
  } = useQuery(getUserQuery, {
    variables: {
      _id: temporaryDummyToken
        ? temporaryDummyToken.id
        : dummyToken
        ? dummyToken.id
        : null,
    },
  });


  useEffect(() => {
    if(userAuthenticated) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }
  },[
    dispatch,
    userAuthenticated
  ])

  useEffect(() => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    if(!Cookies.get("dummy-token")) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }
  },[])

  const [usersDataProps, setUsersDataProps] = useState(null)
  const [industrysDataProps, setIndustrysDataProps] = useState(null)
  const [templatesDataProps, setTemplatesDataProps] = useState(null)

  const [adminsDataProps, setAdminsDataProps] = useState(null)
  const [loggingOut, changeLoggingOut] = useState(false);

  
  const [
    updateUserInvalidateTokens,
    { data: userLogoutData },
  ] = useMutation(updateUserInvalidateTokensMutation);

useEffect(() => {
  if (userLogoutData) {
    if (loggingOut) {
      if (userAuthenticated) {
        dispatch(ACTION_USER_NOT_AUTHENTICATED());
      }
      if (dummyToken) {
        dispatch(ACTION_DUMMY_TOKEN_RESET());
      }
      changeLoggingOut(false);
    }
  }
}, [userLogoutData, dispatch, userAuthenticated, loggingOut, dummyToken]);

function replaceText(selector, text, newText, flags) {
  var matcher = new RegExp(text, flags);
  var elems = document.querySelectorAll(selector), i;

  for (i = 0; i < elems.length; i++)
    if (!elems[i].childNodes.length)
      elems[i].innerHTML = elems[i].innerHTML.replace(matcher, newText);
}

  const handleLogout = () => {
    updateUserInvalidateTokens();
    changeLoggingOut(true);
  
};

  useEffect(() => {
    if(activeOrg) {
      
      // allShareable({
      //   variables:{
      //     org: activeOrg
      //   }
      // })
      allUsers({
        variables:{
          org: activeOrg
        }
      })
      allGroups({
        variables:{
          org: activeOrg
        }
      })
      allDepartments({
        variables:{
          org: activeOrg
        }
      })    
      allKeywords({
        variables:{
          org: activeOrg
        }
      })

      allThemes({
        variables:{
          org: activeOrg
        }
      })

      allTables({
        variables:{
          org: activeOrg
        }
      })
    }
  },[activeOrg])

  useEffect(() => {
    if(getUserData) {
      if(getUserData.user && getUserData.user.role == "superAdmin") {
        allUsers({
          variables:{
            role: "admin"
          }
        })
        allAdmins({
          variables: {
            role: "superAdmin"
          }
        })
      }
    }
  },[getUserData])

  useEffect(() => {
    allIndustrys()
    allTemplates()
   },[])

 
  const elem = useRef()
const [keywordsDone, setKeywordsDone] = useState(false)
  useEffect(() => {
    if(elem.current){
      const observer = new MutationObserver((mutations, observer) => {
        if(keywordsData && keywordsData.allKeywords && keywordsData.allKeywords.length > 0) {
          keywordsData.allKeywords.map(x => {
            document.body && x.after && x.before && findAndReplaceDOMText(document.body, {
            find:  new RegExp(x.before,"gi"),
            replace: x.after
          })
        })

        let allNodes = document.querySelectorAll(`[id=beforeDiv]`)
        keywordsData.allKeywords.map((x, i) => {
          allNodes && allNodes[i] && x.after && findAndReplaceDOMText(allNodes[i], {
            find:  x.after,
            replace: x.before,
          })
        })

        }
      });
      observer.observe(document, {
        subtree: true,
        attributes: true
      });
      
  }},[elem, keywordsData])
  
  useEffect(() => {
    if(keywordsData) {
      setKeywordsDone(true)
    }
  },[keywordsData])
  console.log(shareableData)
  return ( 

    <div className="app" ref={elem}>
      <Spinner isOpen={ userAuthenticated && ( !getUserData ) } />

      <Switch>
        <Route 
        exact path="/"
          render={() => (
            <Redirect to="/admin" />
          )}
        />

            <Route
            path="/admin"
              render={() => (
                <AdminRouter
                  path="/admin"
                  shareableRefetch={shareableRefetch}
                  user={getUserData}
                  keywordsData={keywordsData}
                  themesData={allThemesData}
                  themesRefetch={allThemesRefetch}
                  keywordsRefetch={keywordsRefetch}
                  users={usersData}
                  shareables={shareableData}
                  admins={adminsData}
                  adminsRefetch={adminsRefetch}
                  teams={teamsData}
                  teamsRefetch={teamsRefetch}
                  usersRefetch={usersRefetch}
                  usersData={usersDataProps}
                  adminsData={adminsDataProps}
                  setUsersData={setUsersDataProps}
                  setAdminsData={setAdminsDataProps}
                  departments={departmentsData}
                  departmentsRefetch={departmentsRefetch}
                  tables={tablesData}
                  tablesRefetch={tablesRefetch}
                  handleLogout={handleLogout}
                  industrys={industrysData}
                  industrysData={industrysDataProps}
                  setIndustrysData={setIndustrysDataProps}
                  industrysRefetch={industrysRefetch}

                  templates={templatesData}
                  templatesData={templatesDataProps}
                  setTemplatesData={setTemplatesDataProps}
                  templatesRefetch={templatesRefetch}

                />
              )}
            />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
        </div>
  );
};

const rootElement = document.getElementById("root");
Modal.setAppElement(rootElement);

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <AliveScope>
          <App />
        </AliveScope>
      </ApolloProvider>
    </Provider>
  </Router>,
  rootElement
)