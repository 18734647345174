import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  return (
    <div>
    <ToolbarSection
    title="Card name"
    >
   <ToolbarItem
     full={true}
     propKey="cardName"
     type="text"
     label="Card header"
   />
   </ToolbarSection>

    <ToolbarSection
    title="Card title"
  >
   <ToolbarItem
     full={true}
     propKey="title"
     type="text"
     label="Card title"
   />
   </ToolbarSection>
 
   <ToolbarSection
    title="Card email"
  >
   <ToolbarItem
     full={true}
     propKey="email"
     type="text"
     label="Card email"
   />
   </ToolbarSection>
 
 
   <ToolbarSection
    title="Card phone"
  >
   <ToolbarItem
     full={true}
     propKey="phone"
     type="text"
     label="Card phone"
   />
   </ToolbarSection>

   
   <ToolbarSection
    title="Card website"
  >
   <ToolbarItem
     full={true}
     propKey="website"
     type="text"
     label="Card website"
   />
   </ToolbarSection>
 
 
   <ToolbarSection
    title="Card address"
  >
   <ToolbarItem
     full={true}
     propKey="address"
     type="text"
     label="Card address"
   />
   </ToolbarSection>
 
 
 
 
 
 
 

 </div>
  );
};
