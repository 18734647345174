import React, { useState } from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const WidgetSettings = () => {
  
  const [items, setItems] = useState([])

  return (
    <div style={{height:"100%", overflow:"auto"}}>
       <ToolbarSection
        title="new item"
        onClick={() => setItems([...items, {}])}
      >
      </ToolbarSection>
      {items.map((x,i) => (<ToolbarSection
        title={`item ${i+1}`}
        props={['newItem', 'title']}
      >
        <ToolbarItem
          full={true}
          propKey="items"
          type="text"
          label="Item title"
          addTo="items"
          theKey={"text"}
          index={i}
        />
      </ToolbarSection>))}
    </div>
  );
};
