import React, { useEffect } from 'react'
import Modal from "react-modal"

import "../../../../assests/styles/Modal.scss"
import TextInput from '../../../admin/adminSignin/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { DataSpinner } from '../../spinner/dataSpinner';
import { Form } from '@tsed/react-formio';




const AddRecordModal = (props) => {

  const dispatch = useDispatch()
  
    const {
        open,
        setOpen,
        activeForm,
        setActiveForm,
        addRecord,
        recordsRefetch
        
    } = props
    
  return(
<Modal
        isOpen={open ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"20px",
            borderRadius: "none",
            width: "100vw",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        { open ? <div className="modal task-modal" style={{paddingLeft: "5rem", paddingRight:"5rem", height:"fit-content", width:"45vw"}}>  
        <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    setOpen(null)
                }}
              />  
          <h1 className="header" style={{width:"100%", justifyContent:"center", display:"flex", marginTop:"2rem"}}>{open.includes("Edit") ? open : `Add a ${open}`}</h1>
          <div className="form-group-container task-form">
          <div>
                <Form 
                onSubmit={(e) => addRecord(e)}
                    form={{
                        components: [...JSON.parse(activeForm?.form)]
                    }}
                />
          </div>
          </div>
            {/* <div
              className={`${tableName ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={tableName ? addTable : null }
            >
              
            {open.includes("Edit") ? "Update" :  "Add a table"}
            </div> */}
        </div> : (<></>)}
      </Modal>
  )}

  export default AddRecordModal
       