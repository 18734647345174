import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Blog3 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
  <section class="bg-white dark:bg-gray-900">
      <div class="container px-6 py-10 mx-auto">
          <div class="text-center">
              <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="930450" is="div"><Text text="From the blog" /></Element>} </h1>

              <p class="max-w-lg mx-auto mt-4 text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="138165" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure veritatis sint autem nesciunt, laudantium
                  quia tempore delect" /></Element>} </p>
          </div>

          <div class="grid grid-cols-1 gap-8 mt-8 lg:grid-cols-2">
              <div>
                  <Element canvas style={{ width: "100%" }} id={"div221959"} is="div">
          <ImageC class="relative z-10 object-cover w-full rounded-md h-96" src="https://images.unsplash.com/photo-1644018335954-ab54c83e007f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                  <div class="relative z-20 max-w-lg p-6 mx-auto -mt-20 bg-white rounded-md shadow dark:bg-gray-900">
                      <Element canvas style={{ width: "100%" }} id={"div642405"} is="div">
            <Button href={"#"} classn={"font-semibold text-gray-800 hover:underline dark:text-white md:text-xl"}>
               {<Element canvas style={{display:"inline-block"}} id="875599" is="div"><Text text="All the features you want to know" /></Element>} 
            </Button>
          </Element>

                      <p class="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm"> {<Element canvas style={{display:"inline-block"}} id="743007" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure veritatis sint autem nesciunt,
                          laudantium quia tempore delect" /></Element>} </p>

                      <p class="mt-3 text-sm text-blue-500"> {<Element canvas style={{display:"inline-block"}} id="486570" is="div"><Text text="21 October 2019" /></Element>} </p>
                  </div>
              </div>

              <div>
                  <Element canvas style={{ width: "100%" }} id={"div684786"} is="div">
          <ImageC class="relative z-10 object-cover w-full rounded-md h-96" src="https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                  <div class="relative z-20 max-w-lg p-6 mx-auto -mt-20 bg-white rounded-md shadow dark:bg-gray-900">
                      <Element canvas style={{ width: "100%" }} id={"div724467"} is="div">
            <Button href={"#"} classn={"font-semibold text-gray-800 hover:underline dark:text-white md:text-xl"}>
               {<Element canvas style={{display:"inline-block"}} id="956943" is="div"><Text text="How to use sticky note for problem solving" /></Element>} 
            </Button>
          </Element>

                      <p class="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm"> {<Element canvas style={{display:"inline-block"}} id="106750" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure veritatis sint autem nesciunt,
                          laudantium quia tempore delect" /></Element>} </p>

                      <p class="mt-3 text-sm text-blue-500"> {<Element canvas style={{display:"inline-block"}} id="891415" is="div"><Text text="20 October 2019" /></Element>} </p>
                  </div>
              </div>
          </div>
      </div>
  </section>

      </div>;
};
Blog3.craft = {
  displayName: "Blog 3"
};