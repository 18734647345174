import { ImageC } from '../Image';

import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Hero8 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <header class="bg-white dark:bg-gray-900">
    <nav x-data="{ isOpen: false }" class="border-b dark:border-gray-700">
        <div class="container px-6 py-4 mx-auto lg:flex lg:justify-between lg:items-center">
            <div class="flex items-center justify-between">
                <div>
                    <Element canvas style={{ width: "100%" }} id={"div127757"} is="div">
            <Button href={"#"} classn={"text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"}>
               {<Element canvas style={{display:"inline-block"}} id="312233" is="div"><Text text="Brand" /></Element>} 
            </Button>
          </Element>
                </div>

                
                <div class="flex lg:hidden">
                    <Element canvas style={{ width: "100%" }} id={"div967077"} is="div">
            <Button classn={"text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"}>
              
                        <svg x-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                        </svg>
                
                        <svg x-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    
            </Button>
          </Element>
                </div>
            </div>

           
            <div class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white shadow-md lg:bg-transparent lg:dark:bg-transparent lg:shadow-none dark:bg-gray-900 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center">
                <div class="flex flex-col space-y-8 lg:flex-row lg:items-center lg:space-y-0 lg:-px-8">
                    <Element canvas style={{ width: "100%" }} id={"div159953"} is="div">
            <Button href={"#"} classn={"block font-medium text-gray-700 dark:text-gray-200 lg:mx-8 hover:text-gray-900 dark:hover:text-gray-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="377711" is="div"><Text text="glasses Search" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div291680"} is="div">
            <Button href={"#"} classn={"block font-medium text-gray-700 dark:text-gray-200 lg:mx-8 hover:text-gray-900 dark:hover:text-gray-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="980730" is="div"><Text text="How it works!" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div729124"} is="div">
            <Button href={"#"} classn={"block font-medium text-gray-700 dark:text-gray-200 lg:mx-8 hover:text-gray-900 dark:hover:text-gray-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="331058" is="div"><Text text="Why us?" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div938941"} is="div">
            <Button href={"#"} classn={"block font-medium text-gray-700 dark:text-gray-200 lg:mx-8 hover:text-gray-900 dark:hover:text-gray-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="113210" is="div"><Text text="Contact" /></Element>} 
            </Button>
          </Element>

                    <Element canvas style={{ width: "100%" }} id={"div201387"} is="div">
            <Button classn={"flex items-center justify-center px-6 py-2.5 text-sm font-medium tracking-wide text-center text-white capitalize transition-colors duration-300 transform bg-gray-700 rounded-lg hover:bg-gray-600 focus:outline-none focus:bg-gray-600"}>
               {<Element canvas style={{display:"inline-block"}} id="796873" is="div"><Text text="Get in touch" /></Element>} 
            </Button>
          </Element>
                </div>
            </div>
        </div>
    </nav>

    <div class="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center">
        <div class="w-full lg:w-1/2">
            <div class="lg:max-w-lg">
                <h1 class="text-2xl font-medium tracking-wide text-gray-800 dark:text-white lg:text-4xl"> {<Element canvas style={{display:"inline-block"}} id="275617" is="div"><Text text="Find your premium new glasses exported from US" /></Element>} </h1>
                <p class="mt-2 text-gray-600 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="110125" is="div"><Text text="We work with the best remunated glasses dealers in US to find your new glasses." /></Element>} </p>
                <div class="grid gap-6 mt-8 sm:grid-cols-2">
                    <div class="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                        <svg class="w-5 h-5 mx-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>

                        <span class="mx-3"> {<Element canvas style={{display:"inline-block"}} id="313357" is="div"><Text text="Premium selection" /></Element>} </span>
                    </div>

                    <div class="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                        <svg class="w-5 h-5 mx-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>

                        <span class="mx-3"> {<Element canvas style={{display:"inline-block"}} id="765659" is="div"><Text text="Insurance" /></Element>} </span>
                    </div>

                    <div class="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                        <svg class="w-5 h-5 mx-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>

                        <span class="mx-3"> {<Element canvas style={{display:"inline-block"}} id="474375" is="div"><Text text="All legal documents" /></Element>} </span>
                    </div>

                    <div class="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                        <svg class="w-5 h-5 mx-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>

                        <span class="mx-3"> {<Element canvas style={{display:"inline-block"}} id="419143" is="div"><Text text="From US glasses dealers" /></Element>} </span>
                    </div>

                    <div class="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                        <svg class="w-5 h-5 mx-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>

                        <span class="mx-3"> {<Element canvas style={{display:"inline-block"}} id="454882" is="div"><Text text="Payment Security" /></Element>} </span>
                    </div>

                    <div class="flex items-center text-gray-800 -px-3 dark:text-gray-200">
                        <svg class="w-5 h-5 mx-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>

                        <span class="mx-3"> {<Element canvas style={{display:"inline-block"}} id="865920" is="div"><Text text="Fast shipping (+ Express)" /></Element>} </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-center justify-center w-full h-96 lg:w-1/2">
            <Element canvas style={{ width: "100%" }} id={"div932096"} is="div">
          <ImageC class="object-cover w-full h-full max-w-2xl rounded-md" src="https://images.unsplash.com/photo-1555181126-cf46a03827c0?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="glasses photo"  />
        </Element>
        </div>
    </div>
    </header>

      </div>;
};
Hero8.craft = {
  displayName: "Hero 8"
};