import React, { useEffect, useState } from "react"
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE";
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import AddTemplateModal from "../modals/addTemplateModal";

import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import gsWebpage from 'grapesjs-preset-webpage'
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import html2canvas from 'html2canvas';
import LZString from "lz-string";
import parse from 'html-react-parser';
import TemplateHistoryModal from "../modals/templateHistoryModal";
import updateViewMutation from "../../../graphql/mutations/view/updateViewMutation";

const DesignEditor = (props) => {    

  const {
    user,
    html,
    type,
    workspace,
    _id,
    view,
    viewsRefetch,
    editMode,
setEditMode
  } = props
  const returnToHomeScreen = () => {
        // if(!Cookies.get("dummy-token")) {
        //     return <Redirect to="/admin" />
        // }
  }


  const [updateView, {error: updateViewError, data: updateViewData, loading: updateViewLoading}] = useMutation(updateViewMutation)

    let history = useHistory();
    let dispatch = useDispatch()
    let location = useLocation()
    const returnToTemplates = () => {
      // if(!location.state) {
      //   return <Redirect to="/admin/dashboard" />
      // }
    }

       // grapesjs
       const [pluginLoaded, setPluginLoaded] = useState(false);
       const [editor, setEditor] = useState(null);
      
       
    function downloadInnerHtml(filename, elId, mimeType) {
      var elHtml = elId;
      var link = document.createElement('a');
      mimeType = mimeType || 'text/plain';
  
      link.setAttribute('download', filename);
      link.setAttribute('href', 'data:' + mimeType  +  ';charset=utf-8,' + encodeURIComponent(elHtml));
      link.click(); 
      dispatch(ACTION_LOADING_SPINNER_RESET())
  }

  const updateReportFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      let wrapper = `<html> <head><meta charset="utf-8" /></head> <body><style>${editor.getCss()}</style> ${editor.getHtml()}</body></html>`              
      /////console.log(_id)
        updateView({
          variables:{
            _id: _id,
            html: wrapper,

          }
      }).then(() => {
        viewsRefetch()
      })
  } 
  
       useEffect(() => {
           if (!pluginLoaded) {
              
               setPluginLoaded(true);
           } else if (!editor) {
             dispatch(ACTION_LOADING_SPINNER_ACTIVE())
            const e = grapesjs.init({
                   color:'white',
                   height: '100vh',
                   width: 'auto',
                   container: "#g",
                   plugins: [gsWebpage],
                   storageManager: { type: null },
                   noticeOnUnload: 0, 
                   dragMode:'translate',
                   canvas: {
                    styles: ['https://cdn.syncfusion.com/ej2/bootstrap5.css','https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css', 'https://unpkg.com/x-data-spreadsheet@1.1.5/dist/xspreadsheet.css'] 
                }
                   
                   })   
                   e.on('load', () => {
                    html || !workspace?.type == "owner" ||  !workspace?.type == "editor" ? e.runCommand('preview') : e.stopCommand('preview')
                    dispatch(ACTION_LOADING_SPINNER_RESET())
                   })
                   const cmdVis = 'sw-visibility';
                   e.Commands.extend('preview', {
                       run(editor, sender) {
                           this.sender = sender;
                           this.selected = [...editor.getSelectedAll()];
                           editor.select();
               
                           if (!this.shouldRunSwVisibility) {
                               this.shouldRunSwVisibility = editor.Commands.isActive(cmdVis);
                           }
               
                           this.shouldRunSwVisibility && editor.stopCommand(cmdVis);
                           editor.getModel().stopDefault();
               
                           const panels = this.getPanels(editor);
                           const canvas = editor.Canvas.getElement();
               
                           panels.forEach((panel) => panel.set('visible', false));
               
                           const canvasS = canvas.style;
                           canvasS.width = '100%';
                           canvasS.height = '100%';
                           canvasS.top = '0';
                           canvasS.left = '0';
                           canvasS.padding = '0';
                           canvasS.margin = '0';
                           editor.refresh();
                           this.tglEffects(1);
                       },
                   })

                   setEditor(e)
    
        }   
       });

       // load history modal

       const [templateHistoryModal, setTemplateHistoryModal] = useState(null)

const [selectedHistory, setSelectedHistory] = useState(null)
const [loadingHistory, setLoadingHistory] = useState(null)

const loadHistory = () => {  
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())  
  setTemplateHistoryModal(null)
  setLoadingHistory(selectedHistory)
}

useEffect(() => {
  if(loadingHistory) {
    setTimeout(() => {
      editor.setComponents(loadingHistory.html)
      editor.setStyle(loadingHistory.css)
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }, 1)
  }
},[loadingHistory])
useEffect(() => {
dispatch(ACTION_LOADING_SPINNER_ACTIVE())
},[])
useEffect(() => {
  if(editor) {
    if(_id) {
    if(html) {
      editor.on('update', () => {
        editor.runCommand('preview')
       })
      editor.setComponents(html)
      // editor.runCommand('preview')
    } else {
      if(workspace?.type == "owner" ||  workspace?.type == "editor") {
         editor.DomComponents.clear()
      editor.on('update', () => {
        editor.stopCommand('preview')
      })
      }
     
    }   
  }
  }
},[editor, html, _id, workspace])

    // add Template modal

    const [addTemplateModal,setAddTemplateModal] = useState(null)
    const [templateName,setTemplateName] = useState("")
    const [templateHtml, setTemplateHtml] = useState("")
    const [templateThumbnail, setTemplateThumbnail] = useState("")
    const [templateCss, setTemplateCss] = useState("")

    const addTemplateFunc = () => {
    dispatch(ACTION_LOADING_SPINNER_ACTIVE())
    setAddTemplateModal(null)

    addTemplate({
      variables:{
        name: templateName,
        html: templateHtml,
        css: templateCss,
        thumbnail: templateThumbnail,
        type: type.type,
        by: user.user._id
      }
    }).then(() => {
      setTemplateName("")
      setTemplateHtml("")
      setTemplateCss("")
      setTemplateThumbnail("")
      templatesRefetch().then((res) => {
        history.push("/admin/reports/templates")
      })
    })

    }

    const updateTemplateFunc = (html, css, thumbnail) => {
      // updateTemplate({
      //   variables:{
      //     _id: _id,
      //     html,
      //     css,
      //     thumbnail,
      //     by: user.user._id, 
      //   }
      // }).then(() => {
      //   setTemplateName("")
      //   setTemplateHtml("")
      //   setTemplateCss("")
      //   setTemplateThumbnail("")
      //   templatesRefetch().then((res) => {
      //     history.push("/admin/reports/templates")
      //   })
      // })
  
    }


    // templates refetch

    useEffect(() => {
      if(editMode) {
        editor.stopCommand('preview')
        setEditMode(false)
      }
    },[editMode])
    useEffect(() => {
      if(addTemplateModal) {
        dispatch(ACTION_LOADING_SPINNER_RESET())
      }
    },[addTemplateModal])

    const loadingSpinnerActive = useSelector(
      (state) => state.loadingSpinnerActive.loading_spinner
    );

    return(
      <>
      {!html&&<div onClick={updateReportFunc} style={{zIndex:100, display:"flex", alignItems:"center", justifyContent:"center", fontSize:"1rem", cursor:"pointer", width:"50px", height:"50px", borderRadius:"50%", position:"fixed", right:"10px", bottom:"10px", background:"white", color:"black"}}>
            save
          </div>}
        <div className="grapesJsContainer">
          {returnToHomeScreen()}
          {returnToTemplates()}
          <AddTemplateModal 
          addTemplateModal={addTemplateModal}
          setAddTemplateModal={setAddTemplateModal}
          templateName={templateName}
          setTemplateName={setTemplateName}
          addTemplateFunc={addTemplateFunc}
          updateTemplateFunc={updateTemplateFunc}
          />
          <TemplateHistoryModal 
            templateHistoryModal={templateHistoryModal}
            setTemplateHistoryModal={setTemplateHistoryModal}
            selectedHistory={selectedHistory}
            setSelectedHistory={setSelectedHistory}
            loadHistory={loadHistory}
          />
           <div id="g" style={{opacity: loadingSpinnerActive ? 0 : 1}} className="grapesInnerContainer test">
           </div>
        </div>
        </>
    )
}

export default DesignEditor