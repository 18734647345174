import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Features4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="372063" is="div"><Text text="explore our" /></Element>} <br /> {<Element canvas style={{display:"inline-block"}} id="783922" is="div"><Text text="awesome" /></Element>} <span class="text-blue-500"> {<Element canvas style={{display:"inline-block"}} id="791066" is="div"><Text text="Components" /></Element>} </span></h1>
        
        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2">
            <div class="p-6 border rounded-xl border-r-gray-200 dark:border-gray-700">
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4 dark:text-white dark:bg-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                        </svg>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-2xl font-medium text-gray-700 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="435390" is="div"><Text text="Copy & paste components" /></Element>} </h1>

                        <p class="mt-3 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="937272" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab nulla quod dignissimos vel non corrupti doloribus voluptatum eveniet" /></Element>} </p>
                    </div>
                </div>
            </div>

            <div class="p-6 border rounded-xl border-r-gray-200 dark:border-gray-700">
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4 dark:text-white dark:bg-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                        </svg>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-2xl font-medium text-gray-700 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="317975" is="div"><Text text="Zero Configrations" /></Element>} </h1>

                        <p class="mt-3 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="979066" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab nulla quod dignissimos vel non corrupti doloribus voluptatum eveniet" /></Element>} </p>
                    </div>
                </div>
            </div>

            <div class="p-6 border rounded-xl border-r-gray-200 dark:border-gray-700">
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4 dark:text-white dark:bg-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                        </svg>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-2xl font-medium text-gray-700 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="298326" is="div"><Text text="elegant Dark Mode" /></Element>} </h1>

                        <p class="mt-3 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="751298" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab nulla quod dignissimos vel non corrupti doloribus voluptatum eveniet" /></Element>} </p>
                    </div>
                </div>
            </div>

            <div class="p-6 border rounded-xl border-r-gray-200 dark:border-gray-700">
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4 dark:text-white dark:bg-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                        </svg>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-2xl font-medium text-gray-700 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="125879" is="div"><Text text="Simple & clean designs" /></Element>} </h1>

                        <p class="mt-3 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="405511" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident ab nulla quod dignissimos vel non corrupti doloribus voluptatum eveniet" /></Element>} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Features4.craft = {
  displayName: "Features 4"
};