import * as React from 'react';
import './index.scss';
import { SampleBase } from './sample-base';

import { SplitterComponent, PanesDirective, PaneDirective } from '@syncfusion/ej2-react-layouts';



class Component extends SampleBase {
    rightPaneContent1() {
        return (<div className="splitter-content-expand">
                <a href="https://www.syncfusion.com/ebooks/data_capture_and_extraction_with_c_sharp_succinctly" target="_blank"> Data Capture and Extraction with C# Succinctly </a>
                <p>Capturing and extracting information is one of the most important tasks a developer can perform, and making this task more
                    engaging without relying entirely on specialized tools is an efficient way to improve productivity.
                    In Data Capture and Extraction with C# Succinctly, author Ed Freitas guides readers toward getting more out of C# in minimal time.
                    Email has become a pillar of our modern and connected society, and it now serves as a primary means of communication. Because each email
                    is filled with valuable information, data extraction has emerged as a worthwhile skill set for developers in today’s business world.
                </p>
            </div>);
    }
    ;
    rightPaneContent2() {
        return (<div className="splitter-content-expand">
                <a href="https://www.syncfusion.com/ebooks/spark" target="_blank">Spark Succinctly</a>
                <p>Mastering big data requires an aptitude at every step of information processing.
                    Post-processing, one of the most important steps, is where you find Apache Spark frequently employed.
                    Spark Succinctly, by Marko Svaljek, addresses Spark’s use in the ultimate step in handling big data. This e-book, the
                    third installment in Svaljek’s IoT series, teaches the basics of using Spark and explores how to work with RDDs, Scala and
                    Python tasks, JSON files, and Cassandra.Many of the leading companies in the world today face the problem of big data.
                </p>
            </div>);
    }
    ;
    innerSplitterInstance;
    splitterInstance;
    leftPaneContent() {
        return (<div className="splitter-content-expand">
                <a href="https://www.syncfusion.com/ebooks/neuralnetworks" target="_blank">Neural Networks Using C# Succinctly</a>
                <p>Neural networks are an exciting field of software development used to calculate outputs from input data.
                While the idea seems simple enough, the implications of such networks are staggering—think optical character recognition,
                speech recognition, and regression analysis. With Neural Networks Using C# Succinctly by James McCaffrey, you’ll learn
                how to create your own neural network to solve classification problems, or problems where the outcomes can only be one of
                several values. <br /><br />Learn about encoding and normalizing data, activation functions and how to choose the right one, and ultimately
                    how to train a neural network to find weights and bias values that provide accurate predictions.
                    An artificial neural network (sometimes abbreviated ANN, or shortened to just "neural network" when the context is clear) is
                    a software system that loosely models biological neurons and synapses. Before explaining exactly how neural networks work, it is
                    useful to understand what types of problems they can solve.
            </p>
            </div>);
    }
    constructor() {
        super(arguments);
    }
    onCreate(e) {
        //Initialize Splitter component
        let cont = this.innerSplitterInstance.element.querySelectorAll(".e-pane")[1];
        cont.appendChild(this.splitterInstance.element);
    }
    render() {
        return (<div id="target" style={{minWidth:"500px"}} className="control-section splitter-expand">
ì            {this.props.items?.length ? this.props.items?.map(x => (
                <SplitterComponent {...this.props.allEvents} width="600px" id="Expand" separatorSize={3} height={"350px"} orientation={"Vertical"} ref={(splitter) => { this.innerSplitterInstance = splitter; }}>
                    <PanesDirective>
                        <PaneDirective size='48%' collapsible={true} content={x.content}/>
                        <PaneDirective collapsible={true}/>
                    </PanesDirective>
                </SplitterComponent>
            )) : <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                Click edit to begin
            </div>}
            </div>
      );
    } 
}

export default Component