import * as React from 'react';
import './index.scss';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { TreeViewComponent, ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { SampleBase } from './sample-base';

class Component extends SampleBase {
    sidebarobj;
    data = [
        {
            nodeId: '01', nodeText: 'Installation', iconCss: 'icon-microchip icon',
        },
        {
            nodeId: '02', nodeText: 'Deployment', iconCss: 'icon-thumbs-up-alt icon',
        },
        {
            nodeId: '03', nodeText: 'Quick Start', iconCss: 'icon-docs icon',
        },
        {
            nodeId: '04', nodeText: 'Components', iconCss: 'icon-th icon',
            nodeChild: [
                { nodeId: '04-01', nodeText: 'Calendar', iconCss: 'icon-circle-thin icon' },
                { nodeId: '04-02', nodeText: 'DatePicker', iconCss: 'icon-circle-thin icon' },
                { nodeId: '04-03', nodeText: 'DateTimePicker', iconCss: 'icon-circle-thin icon' },
                { nodeId: '04-04', nodeText: 'DateRangePicker', iconCss: 'icon-circle-thin icon' },
                { nodeId: '04-05', nodeText: 'TimePicker', iconCss: 'icon-circle-thin icon' },
                { nodeId: '04-06', nodeText: 'SideBar', iconCss: 'icon-circle-thin icon' }
            ]
        },
        {
            nodeId: '05', nodeText: 'API Reference', iconCss: 'icon-code icon',
            nodeChild: [
                { nodeId: '05-01', nodeText: 'Calendar', iconCss: 'icon-circle-thin icon' },
                { nodeId: '05-02', nodeText: 'DatePicker', iconCss: 'icon-circle-thin icon' },
                { nodeId: '05-03', nodeText: 'DateTimePicker', iconCss: 'icon-circle-thin icon' },
                { nodeId: '05-04', nodeText: 'DateRangePicker', iconCss: 'icon-circle-thin icon' },
                { nodeId: '05-05', nodeText: 'TimePicker', iconCss: 'icon-circle-thin icon' },
                { nodeId: '05-06', nodeText: 'SideBar', iconCss: 'icon-circle-thin icon' }
            ]
        },
        {
            nodeId: '06', nodeText: 'Browser Compatibility', iconCss: 'icon-chrome icon'
        },
        {
            nodeId: '07', nodeText: 'Upgrade Packages', iconCss: 'icon-up-hand icon'
        },
        {
            nodeId: '08', nodeText: 'Release Notes', iconCss: 'icon-bookmark-empty icon'
        },
        {
            nodeId: '09', nodeText: 'FAQ', iconCss: 'icon-help-circled icon'
        },
        {
            nodeId: '10', nodeText: 'License', iconCss: 'icon-doc-text icon'
        }
    ];
    width = '290px';
    target = '.main-sidebar-content';
    mediaQuery = '(min-width: 600px)';
    render() {
        let folderEle = '<div class= "e-folder"><div class= "e-folder-name">Navigation Pane</div></div>';
        return (<div className="control-section" id="responsive-wrapper">                
                <div id="reswrapper">
                    {/* header-section  declaration */}
                    <div>
                        <ToolbarComponent id="resToolbar" clicked={this.toolbarCliked.bind(this)}>
                            <ItemsDirective>
                                <ItemDirective prefixIcon="e-tbar-menu-icon tb-icons" tooltipText="Menu"></ItemDirective>
                                <ItemDirective template={folderEle}></ItemDirective>
                            </ItemsDirective>
                        </ToolbarComponent>
                    </div>
                    {/* end of header-section */}
                    {this.props.items.length ?<SidebarComponent {...this.props.allEvents} id="sideTree" className="sidebar-treeview" ref={Sidebar => this.sidebarobj = Sidebar} width={this.width} target={this.target} mediaQuery={this.mediaQuery} isOpen={true}>
                        <div className='res-main-menu'>
                            <div className="table-content">
                                <TextBoxComponent id="resSearch" placeholder="Search..."></TextBoxComponent>
                                <p className="main-menu-header">{this.props.header}</p>
                            </div>
                            <div>
                                <TreeViewComponent id='mainTree' cssClass="main-treeview" fields={{ dataSource: this.props.items || [], id: 'nodeId', text: 'nodeText', child: 'nodeChild', iconCss: "iconCss" }} expandOn='Click'/>
                            </div>
                        </div>
                    </SidebarComponent> : null}
                    <div className="main-sidebar-content" id="main-text">
                        <div className="sidebar-content">
                            
                        </div>
                    </div>
                </div>
            </div>);
    }
    toolbarCliked() {
        this.sidebarobj.toggle();
    }
}

export default Component