import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Header4 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <header class="text-gray-600 body-font">
  <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
      </svg>
      <span class="ml-3 text-xl"> {<Element canvas style={{display:"inline-block"}} id="401688" is="div"><Text text="Tailblocks" /></Element>} </span>
    </a>
    <nav class="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
      <a class="mr-5 hover:text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="121100" is="div"><Text text="First Link" /></Element>} </a>
      <a class="mr-5 hover:text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="107996" is="div"><Text text="Second Link" /></Element>} </a>
      <a class="mr-5 hover:text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="458104" is="div"><Text text="Third Link" /></Element>} </a>
      <a class="mr-5 hover:text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="893040" is="div"><Text text="Fourth Link" /></Element>} </a>
    </nav>
    <Element canvas style={{ width: "100%" }} id={"div279743"} is="div">
            <Button classn={"inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0"}>
               {<Element canvas style={{display:"inline-block"}} id="756914" is="div"><Text text="Button" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
        <path d="M5 12h14M12 5l7 7-7 7"></path>
      </svg>
    
            </Button>
          </Element>
  </div>
    </header>

      </div>;
};
Header4.craft = {
  displayName: "Header 4"
};