import { ImageC } from '../Image';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Team2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <div class="xl:flex xl:items-center xL:-mx-4">
            <div class="xl:w-1/2 xl:mx-4">
                <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="938953" is="div"><Text text="Our Team" /></Element>} </h1>

                <p class="max-w-2xl mt-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="201048" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo incidunt ex placeat modi magni quia error alias, adipisci rem similique, at omnis eligendi optio eos harum." /></Element>} </p>
            </div>

            <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-0 xl:mx-4 xl:w-1/2 md:grid-cols-2">
                <div>
                    <Element canvas style={{ width: "100%" }} id={"div265128"} is="div">
          <ImageC class="object-cover rounded-xl aspect-square" src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt=""  />
        </Element>

                    <h1 class="mt-4 text-2xl font-semibold text-gray-700 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="999242" is="div"><Text text="John Doe" /></Element>} </h1>

                    <p class="mt-2 text-gray-500 capitalize dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="824919" is="div"><Text text="Full stack developer" /></Element>} </p>
                </div>

                <div>
                    <Element canvas style={{ width: "100%" }} id={"div541916"} is="div">
          <ImageC class="object-cover rounded-xl aspect-square" src="https://images.unsplash.com/photo-1499470932971-a90681ce8530?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""  />
        </Element>

                    <h1 class="mt-4 text-2xl font-semibold text-gray-700 capitalize dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="384390" is="div"><Text text="Mia" /></Element>} </h1>

                    <p class="mt-2 text-gray-500 capitalize dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="490250" is="div"><Text text="Graphic Designer" /></Element>} </p>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Team2.craft = {
  displayName: "Team 2"
};