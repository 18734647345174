import './index.scss';
import * as React from 'react';
import { MentionComponent } from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query, WebApiAdaptor } from '@syncfusion/ej2-data';
import { SampleBase } from './sample-base';

class Component extends SampleBase {


  commonTarget = '#multipleList';

  dataFields = { text: 'name'};

  render() {


      return (<div className='control-pane'>
      <div className='control-section'>
          <div className="content-wrapper">
            <div id='mention_multiplelist'>
              <table>
                  <tr>
                    <td>
                        <label id="label" className="multiple">{this.props.placeholder}</label>
                        <div id="multipleList" placeholder={this.props.placeholder}></div>
                    </td>
                </tr>
              </table>

              <MentionComponent {...this.props.allEvents} dataSource={this.props.items} target={this.commonTarget} fields={this.dataFields} suggestionCount={15} popupWidth={250} popupHeight={250} allowSpaces={true}></MentionComponent>
            </div>
          </div>
      </div>
          
    </div>);
    }
}

export default Component