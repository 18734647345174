import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Pricing8 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font overflow-hidden">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="628928" is="div"><Text text="Pricing" /></Element>} </h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="178807" is="div"><Text text="Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical." /></Element>} </p>
    </div>
    <div class="flex flex-wrap -m-4">
      <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
        <div class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
          <h2 class="text-sm tracking-widest title-font mb-1 font-medium"> {<Element canvas style={{display:"inline-block"}} id="256354" is="div"><Text text="START" /></Element>} </h2>
          <h1 class="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none"> {<Element canvas style={{display:"inline-block"}} id="487537" is="div"><Text text="Free" /></Element>} </h1>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="650486" is="div"><Text text="Vexillologist pitchfork" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="167057" is="div"><Text text="Tumeric plaid portland" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="317714" is="div"><Text text="Mixtape chillwave tumeric" /></Element>} </span>
          </p>
          <Element canvas style={{ width: "100%" }} id={"div588257"} is="div">
            <Button classn={"flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="485738" is="div"><Text text="Button" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          
            </Button>
          </Element>
          <p class="text-xs text-gray-500 mt-3"> {<Element canvas style={{display:"inline-block"}} id="822832" is="div"><Text text="Literally you probably haven't heard of them jean shorts." /></Element>} </p>
        </div>
      </div>
      <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
        <div class="h-full p-6 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden">
          <span class="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl"> {<Element canvas style={{display:"inline-block"}} id="394619" is="div"><Text text="POPULAR" /></Element>} </span>
          <h2 class="text-sm tracking-widest title-font mb-1 font-medium"> {<Element canvas style={{display:"inline-block"}} id="107732" is="div"><Text text="PRO" /></Element>} </h2>
          <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
            <span> {<Element canvas style={{display:"inline-block"}} id="195021" is="div"><Text text="$38" /></Element>} </span>
            <span class="text-lg ml-1 font-normal text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="793310" is="div"><Text text="/mo" /></Element>} </span>
          </h1>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="581038" is="div"><Text text="Vexillologist pitchfork" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="548481" is="div"><Text text="Tumeric plaid portland" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="679094" is="div"><Text text="Hexagon neutra unicorn" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="799496" is="div"><Text text="Mixtape chillwave tumeric" /></Element>} </span>
          </p>
          <Element canvas style={{ width: "100%" }} id={"div863656"} is="div">
            <Button classn={"flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="583817" is="div"><Text text="Button" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          
            </Button>
          </Element>
          <p class="text-xs text-gray-500 mt-3"> {<Element canvas style={{display:"inline-block"}} id="176829" is="div"><Text text="Literally you probably haven't heard of them jean shorts." /></Element>} </p>
        </div>
      </div>
      <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
        <div class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
          <h2 class="text-sm tracking-widest title-font mb-1 font-medium"> {<Element canvas style={{display:"inline-block"}} id="668154" is="div"><Text text="BUSINESS" /></Element>} </h2>
          <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
            <span> {<Element canvas style={{display:"inline-block"}} id="600214" is="div"><Text text="$56" /></Element>} </span>
            <span class="text-lg ml-1 font-normal text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="386183" is="div"><Text text="/mo" /></Element>} </span>
          </h1>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="780773" is="div"><Text text="Vexillologist pitchfork" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="919365" is="div"><Text text="Tumeric plaid portland" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="800424" is="div"><Text text="Hexagon neutra unicorn" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="444359" is="div"><Text text="Vexillologist pitchfork" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="322375" is="div"><Text text="Mixtape chillwave tumeric" /></Element>} </span>
          </p>
          <Element canvas style={{ width: "100%" }} id={"div337570"} is="div">
            <Button classn={"flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="474337" is="div"><Text text="Button" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          
            </Button>
          </Element>
          <p class="text-xs text-gray-500 mt-3"> {<Element canvas style={{display:"inline-block"}} id="189118" is="div"><Text text="Literally you probably haven't heard of them jean shorts." /></Element>} </p>
        </div>
      </div>
      <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
        <div class="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
          <h2 class="text-sm tracking-widest title-font mb-1 font-medium"> {<Element canvas style={{display:"inline-block"}} id="273609" is="div"><Text text="SPECIAL" /></Element>} </h2>
          <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
            <span> {<Element canvas style={{display:"inline-block"}} id="426854" is="div"><Text text="$72" /></Element>} </span>
            <span class="text-lg ml-1 font-normal text-gray-500"> {<Element canvas style={{display:"inline-block"}} id="602550" is="div"><Text text="/mo" /></Element>} </span>
          </h1>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="770711" is="div"><Text text="Vexillologist pitchfork" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="735301" is="div"><Text text="Tumeric plaid portland" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="211120" is="div"><Text text="Hexagon neutra unicorn" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="660848" is="div"><Text text="Vexillologist pitchfork" /></Element>} </span>
          </p>
          <p class="flex items-center text-gray-600 mb-6">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span><span> {<Element canvas style={{display:"inline-block"}} id="673758" is="div"><Text text="Mixtape chillwave tumeric" /></Element>} </span>
          </p>
          <Element canvas style={{ width: "100%" }} id={"div451143"} is="div">
            <Button classn={"flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded"}>
               {<Element canvas style={{display:"inline-block"}} id="840065" is="div"><Text text="Button" /></Element>} <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          
            </Button>
          </Element>
          <p class="text-xs text-gray-500 mt-3"> {<Element canvas style={{display:"inline-block"}} id="505649" is="div"><Text text="Literally you probably haven't heard of them jean shorts." /></Element>} </p>
        </div>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Pricing8.craft = {
  displayName: "Pricing 8"
};