import { Button } from '../Button';

import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Faq5 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-12 mx-auto">
        <h1 class="text-2xl font-semibold text-center text-gray-800 lg:text-4xl dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="810980" is="div"><Text text="Have any Questions?" /></Element>} </h1>

        <div class="mt-8 xl:mt-16 lg:flex lg:-mx-12">
            <div class="lg:mx-12">
                <h1 class="text-xl font-semibold text-gray-800 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="583782" is="div"><Text text="Table of Content" /></Element>} </h1>

                <div class="mt-4 space-y-4 lg:mt-8">
                    <Element canvas style={{ width: "100%" }} id={"div415077"} is="div">
            <Button href={"#"} classn={"block text-blue-500 dark:text-blue-400 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="286324" is="div"><Text text="General" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div206264"} is="div">
            <Button href={"#"} classn={"block text-gray-500 dark:text-gray-300 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="402524" is="div"><Text text="Trust & Safety" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div683484"} is="div">
            <Button href={"#"} classn={"block text-gray-500 dark:text-gray-300 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="499957" is="div"><Text text="Services" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div238753"} is="div">
            <Button href={"#"} classn={"block text-gray-500 dark:text-gray-300 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="304942" is="div"><Text text="Billing" /></Element>} 
            </Button>
          </Element>
                    <Element canvas style={{ width: "100%" }} id={"div607622"} is="div">
            <Button href={"#"} classn={"block text-gray-500 dark:text-gray-300 hover:underline"}>
               {<Element canvas style={{display:"inline-block"}} id="467577" is="div"><Text text="Office Cleaning" /></Element>} 
            </Button>
          </Element>
                </div>
            </div>

            <div class="flex-1 mt-8 lg:mx-12 lg:mt-0">
                <div>
                    <Element canvas style={{ width: "100%" }} id={"div700766"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                        <svg class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path></svg>

                        <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="293871" is="div"><Text text="How i can play for my appoinment ?" /></Element>} </h1>
                    
            </Button>
          </Element>

                    <div class="flex mt-8 md:mx-10">
                        <span class="border border-blue-500"></span>

                        <p class="max-w-3xl px-4 text-gray-500 dark:text-gray-300"> {<Element canvas style={{display:"inline-block"}} id="783465" is="div"><Text text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, eum quae. Harum officiis reprehenderit ex quia ducimus minima id provident molestias optio nam vel, quidem iure voluptatem, repellat et ipsa." /></Element>} </p>
                    </div>
                </div>

                <hr class="my-8 border-gray-200 dark:border-gray-700" />

                <div>
                    <Element canvas style={{ width: "100%" }} id={"div993239"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                        </svg>

                        <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="247715" is="div"><Text text="What can i expect at my first consultation ?" /></Element>} </h1>
                    
            </Button>
          </Element>
                </div>

                <hr class="my-8 border-gray-200 dark:border-gray-700" />

                <div>
                    <Element canvas style={{ width: "100%" }} id={"div554319"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                        </svg>

                        <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="676042" is="div"><Text text="What are your opening house ?" /></Element>} </h1>
                    
            </Button>
          </Element>
                </div>

                <hr class="my-8 border-gray-200 dark:border-gray-700" />

                <div>
                    <Element canvas style={{ width: "100%" }} id={"div849931"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                        </svg>

                        <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="483755" is="div"><Text text="Do i need a referral ?" /></Element>} </h1>
                    
            </Button>
          </Element>
                </div>

                <hr class="my-8 border-gray-200 dark:border-gray-700" />

                <div>
                    <Element canvas style={{ width: "100%" }} id={"div287224"} is="div">
            <Button classn={"flex items-center focus:outline-none"}>
              
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                        </svg>

                        <h1 class="mx-4 text-xl text-gray-700 dark:text-white"> {<Element canvas style={{display:"inline-block"}} id="736279" is="div"><Text text="Is the cost of the appoinment covered by private health insurance ?" /></Element>} </h1>
                    
            </Button>
          </Element>
                </div>
            </div>
        </div>
    </div>
    </section>

      </div>;
};
Faq5.craft = {
  displayName: "Faq 5"
};