import './index.scss';
import * as React from 'react';
import { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject } from '@syncfusion/ej2-react-pdfviewer';
import { updateSampleSection } from './sample-base';
function Component({allEvents}) {
    React.useEffect(() => {
        updateSampleSection();
    }, []);

    return (<div>
        <div className='control-section' style={{height:"100%"}}>
            {/* Render the PDF Viewer */}
            <PdfViewerComponent enablePrint={false} height={"700px"} width={"700px"} {...allEvents} downloadEnd={() => allEvents.onDownload ? allEvents.onDownload() : null} id="container" documentPath="PDF_Succinctly.pdf" serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer" style={{ 'height': '640px' }}>
                <Inject services={[Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]}/>
            </PdfViewerComponent>
        </div>
    </div>);
}
export default Component;
