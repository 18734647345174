import { Element } from '@craftjs/core';
import Text from '../Text';

import { useNode } from '@craftjs/core';
import React from 'react';
export const Statistics1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div ref={connect}>
        
    <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
      <div class="p-4 sm:w-1/4 w-1/2">
        <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="547951" is="div"><Text text="2.7K" /></Element>} </h2>
        <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="985039" is="div"><Text text="Users" /></Element>} </p>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
        <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="234393" is="div"><Text text="1.8K" /></Element>} </h2>
        <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="728164" is="div"><Text text="Subscribes" /></Element>} </p>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
        <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="549495" is="div"><Text text="35" /></Element>} </h2>
        <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="636504" is="div"><Text text="Downloads" /></Element>} </p>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
        <h2 class="title-font font-medium sm:text-4xl text-3xl text-gray-900"> {<Element canvas style={{display:"inline-block"}} id="748606" is="div"><Text text="4" /></Element>} </h2>
        <p class="leading-relaxed"> {<Element canvas style={{display:"inline-block"}} id="855684" is="div"><Text text="Products" /></Element>} </p>
      </div>
    </div>
  </div>
    </section>

      </div>;
};
Statistics1.craft = {
  displayName: "Statistics 1"
};