import { useEditor, Element } from '@craftjs/core';
import cx from 'classnames';
import lz from 'lzutf8';
import React, { useCallback, useEffect, useState } from 'react';
import {Checkmark} from '../../../icons/check.js';
import {CustomizeIcon} from '../../../icons/customize.js';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Toolbox } from './Toolbox';
import addViewPink from "../../../../../assests/images/addViewPink.svg"
import ACTION_LOADING_SPINNER_ACTIVE from '../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE.js';
import ACTION_LOADING_SPINNER_RESET from '../../../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET.js';
import styled from 'styled-components';
import {ButtonSvg} from '../../../icons/toolbox/button.js';
import {SquareSvg} from '../../../icons/toolbox/rectangle.js';
import {TypeSvg} from '../../../icons/toolbox/Text.js';
import {YoutubeSvg} from '../../../icons/toolbox/video-line.js';
import AddFormModal from "../../../../resuable/modals/addFormModal";
import { Button } from '../../selectors/inputs/Button';
import { Container } from '../../selectors/others/Container';
import { Text } from '../../selectors/others/Text';
import { Video } from '../../selectors/others/Video';
import { CardWidget } from '../../selectors/others/Card';
import { AccordionN } from '../../selectors/others/Accordion';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { GridWidget } from '../../selectors/others/Grid/index.js';
import ViewSettings from '../../../modals/viewSettings/index.js';
import { useDispatch, useSelector } from 'react-redux';
import updateViewMutation from '../../../../../graphql/mutations/view/updateViewMutation.js';
import { useLazyQuery, useMutation } from '@apollo/client';
import ACTION_FORMBUILDER_RESET from '../../../../../actions/formBuilder/ACTION_FORMBUILDER_RESET.js';
import addFormMutation from '../../../../../graphql/mutations/form/addFormMutation.js';
import { FormBuilderWidget } from '../../selectors/others/FormBuilder/index.js';
import { Scheduler } from '../../selectors/calendars/Scheduler/index.js';
import { Calendar } from '../../selectors/calendars/Calendar/index.js';
import { DatePicker } from '../../selectors/calendars/DatePicker/index.js';
import { DateRangePicker } from '../../selectors/calendars/DateRangePicker';
import { DateTimePicker } from '../../selectors/calendars/DateTimePicker';
import { GantChart } from '../../selectors/calendars/GantChart';
import { AutoComplete } from '../../selectors/dropdowns/AutoComplete/index.js';
import { CompoBox } from '../../selectors/dropdowns/CompoBox/index.js';
import { DropDownList } from '../../selectors/dropdowns/DropDownList/index.js';
import { DropDownTree } from '../../selectors/dropdowns/DropDownTree/index.js';
import { DropDownMulti } from '../../selectors/dropdowns/DropDownMulti/index.js';
import { Mention } from '../../selectors/dropdowns/Mention/index.js';
import { ListBox } from '../../selectors/dropdowns/ListBox/index.js';
import { Accordion } from '../../selectors/navigation/Accordion'
import { AppBar } from '../../selectors/navigation/AppBar/index.js';
import { Carousel } from '../../selectors/navigation/Carousel/index.js';
import { Context } from '../../selectors/navigation/Context/index.js';
import { SideBar } from '../../selectors/navigation/SideBar/index.js';
import { Wizard } from '../../selectors/navigation/Wizard/index.js';
import { FileManager } from '../../selectors/navigation/FileManager/index.js';
import { Message } from '../../selectors/notifications/Message/index.js';
import { Badge } from '../../selectors/notifications/Badge/index.js';
import { Toast } from '../../selectors/notifications/Toast/index.js';
import { Input } from '../../selectors/inputs/Input/index.js';
import { Mask } from '../../selectors/inputs/Mask/index.js';
import { Numeric } from '../../selectors/inputs/Numeric/index.js';
import { Signture } from '../../selectors/inputs/Signture/index.js';
import { FileUploader } from '../../selectors/inputs/FileUploader/index.js';
import { QueryForm } from '../../selectors/forms/QueryForm/index.js';
import { DropButton } from '../../selectors/buttons/DropButton/index.js';
import { SpeedDial } from '../../selectors/buttons/SpeedDial/index.js';
import { Dashboard } from '../../selectors/layout/Dashboard/index.js';
import { Splitter } from '../../selectors/layout/Splitter/index';
import { ListView } from '../../selectors/layout/ListView/index';
import { Dialoag } from '../../selectors/layout/Dialoag/index.js';
import { FlippedCard } from '../../selectors/layout/FlippedCard/index.js';
import { AvatarCard } from '../../selectors/layout/AvatarCard/index.js';
import { PDF } from '../../selectors/editors/PDF/index.js';
import { WordProcessor } from '../../selectors/editors/WordProcessor/index.js';
import { TreeGrid } from '../../selectors/grids/TreeGrid/index.js';
import { PivotTable } from '../../selectors/grids/PivotTable/index.js';
import { DataGrid } from '../../selectors/grids/DataGrid/index.js';
import { ImageEditor } from '../../selectors/editors/ImageEditor/index.js';
import {Kanban} from "../../selectors/dataVisualization/kanban/"

import ExportModal from '../../../modals/exportModal/index.js';
import addShareableMutation from '../../../../../graphql/mutations/shareable/addShareableMutation.js';
import ImportModal from '../../../modals/importModal/index.js';
import PreviewModal from '../../../modals/PreviewModal/index.js';
import viewQuery from '../../../../../graphql/queries/view/viewQuery.js';
import updateTemplateMutation from '../../../../../graphql/mutations/template/updateTemplateMutation.js';
import { useHistory } from 'react-router-dom';



const Item = styled.a`
svg {
  width: 22px;
  height: 22px;
  fill: #707070;
}
${(props) =>
  props.move &&
  `
  cursor: move;
`} 
`;

export const OneEditorComponent = ({ 
  children,
  templateId,
  templateHtml
 }) => {

  const [over, setOver] = useState(false)

  const {
    enabled,
    connectors, 
    actions,
    nodes,
    json
  } = useEditor((state, query) => ({
    enabled: state.options.enabled || false,
    json: query.serialize() ,
    nodes: state.nodes
  }));


useEffect(() => {
  if(templateHtml) {
    const json = lz.decompress(lz.decodeBase64(templateHtml));
    actions.deserialize(json)
  } else {
    actions.deserialize({"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true, "props":{ "flexDirection":"column","alignItems":"flex-start","justifyContent":"flex-start","fillSpace":"no","padding":["40","40","40","40"],"margin":["0","0","0","0"],"background":{"r":255,"g":255,"b":255,"a":1},"color":{"r":0,"g":0,"b":0,"a":1},"shadow":0,"radius":0,"width":"100%%","height":"518px"},"displayName":"Container","custom":{"displayName":"App"},"hidden":false,"nodes":[],"linkedNodes":{}}})
  }
},[templateHtml])

const [layers, setLayers] = useState(true)
const [addWidgetActive, setAddWidgetActive] = useState(false)
const [groupActive, setGroupActive] = useState(null)

const [updateTemplate, {error: updateTemplateError, data: updateTemplateData, loading: updateTemplateLoading}] = useMutation(updateTemplateMutation)

useEffect(() => {
  if(!addWidgetActive) {
    setGroupActive(null)
  }
},[addWidgetActive])
const history = useHistory()
const dispatch = useDispatch()
const updateTemplateFunc = () => {
  let wrapper = lz.encodeBase64(lz.compress(json))   
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  updateTemplate({
    variables:{
      _id: templateId,
      html: wrapper

    }
  }).then(x => {
    history.push("/admin/templates")
  })
}

  return (
    <div className="viewport" onDrop={() => setAddWidgetActive(false)} style={{width:"100%", height:"100%", border:"2px solid green"}}>
            <div
              style={{ height:"100%"}}
              className={cx(['flex h-full overflow-hidden flex-row w-full'])}
            >
              { true && <Toolbox
        layers={layers}
        setLayers={setLayers}
        setAddWidgetActive={setAddWidgetActive}
        // setViewSettings={setViewSettings}
        // viewSettings={viewSettings}
        // setBuilderModal={setBuilderModal}
        // builderModal={builderModal}
        addWidgetActive={addWidgetActive}
        /> }
        <div className="page-container flex flex-1 h-full flex-col"
        style={{alignItems:"center", position:"relative", width:"100%"}}
        >

{addWidgetActive || groupActive ? <div onClick={(() => {
            setAddWidgetActive(false)
            setGroupActive(null)
          })} style={{ width:  over ? "100vw" : "0px", height:  over ? "100vh" : "0px", zIndex:"100000", position:"absolute", left:0, right:0}}> { addWidgetActive ? <div onClick={(event) => event.stopPropagation()}> <div style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", height:"700px", overflowY:"auto",position:"absolute", left:"0px", top:"98px", width:"300px", zIndex:99991, background:"rgba(255, 255, 255, 0.9)", borderTop:"3px solid #116DFF", padding:"20px 15px"}}>          
          <div
          className='widgetItemIn dm'
          onClick={() => setGroupActive("grids")}
        >
          <div className='widgetItemName'>
            grids
          </div>

          
        </div>
        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Data Visualization")}
        >
          <div className='widgetItemName'>
            Data Visualization
          </div>

          
        </div><div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Calendars")}
        >
          <div className='widgetItemName'>
            Calendars
          </div>

          
        </div><div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Dropdowns")}
        >
          <div className='widgetItemName'>
            Dropdowns
          </div>

          
        </div><div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("File Viewers & Editors")}
        >
          <div className='widgetItemName'>
            File Viewers & Editors
          </div>

          
        </div><div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Inputs")}
        >
          <div className='widgetItemName'>
            Inputs
          </div>

          
        </div><div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Navigation")}
        >
          <div className='widgetItemName'>
            Navigation
          </div>

          
        </div><div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Layout")}
        >
          <div className='widgetItemName'>
            Layout
          </div>

          
        </div><div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Buttons")}
        >
          <div className='widgetItemName'>
            Buttons
          </div>

          
        </div><div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("grids")}
        >
          <div className='widgetItemName'>
            grids
          </div>

          
        </div><div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Forms")}
        >
          <div className='widgetItemName'>
            Forms
          </div>

          
        </div>
        <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("Notifications")}
        >
          <div className='widgetItemName'>
            Notifications
          </div>

          
        </div>
          <div 
          className='widgetItemIn dm'
          onClick={() => setGroupActive("other")}
        >
          <div className='widgetItemName'>
            Other
          </div>

          
        </div>
          </div>
          {   groupActive && <div style={{position:"absolute", left:"300px", top:"98px", width:"300px", maxHeight:"700px", overflowY:"auto", zIndex:99991, background:"rgba(255, 255, 255, 0.9)", borderTop:"3px solid #116DFF", padding:"20px 15px"}}>          
          {   groupActive == "other" ? (
            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Element
             
                canvas
                is={Container}
                background={{ r: 78, g: 78, b: 78, a: 1 }}
                color={{ r: 0, g: 0, b: 0, a: 1 }}
                height="300px"
                width="300px"
              ></Element>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              <SquareSvg />
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Container
          </div>
        </div>
        <div 
className='widgetItemIn'
onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(ref, 
           <Text  fontSize="12" textAlign="left" text="Hi there"></Text>
           )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Text" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              <TypeSvg />
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Text
          </div>
        </div>
        <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <Button ></Button>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Button" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              <ButtonSvg />
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Button
          </div>
        </div>
        <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <Video ></Video>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              <YoutubeSvg />
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Video
          </div>        
          </div>

          <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <AccordionN ></AccordionN>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              acc
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Accordion
          </div>        
          </div>

          <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <CardWidget ></CardWidget>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Card
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Card
          </div>        
          </div>

          <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <GridWidget setViewSettings={setViewSettings}></GridWidget>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Grid
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Grid
          </div>        
          </div>

          <div 
        className='widgetItemIn'
        onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
        ref={(ref) => connectors?.create(ref, 
       <FormBuilderWidget setViewSettings={setViewSettings}></FormBuilderWidget>
        )}>
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Video" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              forms
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Form Builder
          </div>        
          </div>
            </>
          ) :  groupActive == "Data Visualization" ? (
            <>
             {/* <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Code39 ></Code39>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Code39
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Code39
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Eancode ></Eancode>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Eancode
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Eancode
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Qrcode ></Qrcode>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Qrcode
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Qrcode
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <BulletChart ></BulletChart>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              BulletChart
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          BulletChart
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Map ></Map>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Map
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Map
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <SmithChart ></SmithChart>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              SmithChart
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          SmithChart
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <MixedCharts ></MixedCharts>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              MixedCharts
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          MixedCharts
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Treemap ></Treemap>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Treemap
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Treemap
          </div>
        </div> */}
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Kanban ></Kanban>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Kanban
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Kanban
          </div>
        </div>
            </>
          ) : groupActive == "Calendars" ? (
            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref, 
          <Scheduler ></Scheduler>
          )}
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Sch
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Scheduler
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <Calendar ></Calendar>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Cal
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Calendar
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <DatePicker ></DatePicker>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              pick
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Date Picker
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <DateRangePicker ></DateRangePicker>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Range
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Date Range Picker
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <DateTimePicker ></DateTimePicker>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Date time Picker
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) =>
            connectors?.create(
              ref,
             <GantChart ></GantChart>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              GC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Gantt Chart
          </div>
        </div>
            </>
          ) : groupActive == "Dropdowns" ? (
            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <AutoComplete ></AutoComplete>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              AC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Auto complete
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <CompoBox  ></CompoBox>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              CB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Compo Box
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DropDownList ></DropDownList>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DW
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Drop Down List
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DropDownTree  ></DropDownTree>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DWT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Drop Down Tree
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DropDownMulti  ></DropDownMulti>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DWM
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Drop Down Multi Select
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Mention  ></Mention>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              @
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Mention
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <ListBox  ></ListBox>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              LB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          List Box
          </div>
        </div>
            </>
          ) : groupActive == "File Viewers & Editors" ? (
            <>
         <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <PDF  ></PDF>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              PDF
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          PDF Viewer & editor
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <ImageEditor ></ImageEditor>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              IE
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Image Editor
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <WordProcessor  ></WordProcessor>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              WP
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Word Processor
          </div>
        </div>
            </>
          ) : groupActive == "Inputs" ? (
            <>
                <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Input ></Input>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              In
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Input
          </div>
        </div>
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Mask  ></Mask>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              IM
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Input Mask
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Numeric ></Numeric>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              NI
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Numeric Input
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Signture  ></Signture>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Si
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Signature
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Button  ></Button>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Bu
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Button
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <FileUploader  ></FileUploader>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              FU
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          FileUploader
          </div>
        </div>


            </>
          ) : groupActive == "Navigation" ? (
            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Accordion ></Accordion>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              AC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Accordion
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <AppBar  ></AppBar>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              AB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          App Bar
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Carousel  ></Carousel>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              Ca
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Carousel
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Context  ></Context>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              CM
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Context Menu
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <SideBar  ></SideBar>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              SB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Side Bar
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Wizard  ></Wizard>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              WT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Wizard Tabs
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <FileManager ></FileManager>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              FM
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          File Manager
          </div>
        </div>


            </>
          ) : groupActive == "Layout" ? (
            <>
             <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Dashboard  ></Dashboard>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DL
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         Dashboard Layout
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Splitter  ></Splitter>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              SP
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         Splitter Layout
          </div>
        </div>


        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <ListView  ></ListView>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              LV
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         List View
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Dialoag  ></Dialoag>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DI
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
            Dialoag
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <FlippedCard  ></FlippedCard>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              FC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
           Flipped Card
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <AvatarCard  ></AvatarCard>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              AC
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
           Avatar Card
          </div>
        </div>
            </>
          ) : groupActive == "Buttons" ? (
            <>
              <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DropButton ></DropButton>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DDB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          DropDown Button
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <SpeedDial ></SpeedDial>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              SD
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Speed Dial Button
          </div>
        </div>
{/* 
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <FloatingButton  ></FloatingButton>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              FB
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Floating Button
          </div>
        </div> */}
            </>
          ) : groupActive == "grids" ? (
            <>
 <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}
          
          ref={(ref) => 
            connectors?.create(
              ref,
             <TreeGrid ></TreeGrid>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              TG
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Tree Grid
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <PivotTable  ></PivotTable>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              PT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         Pivot Table
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <DataGrid  ></DataGrid>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              DT
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
         Data Grid
          </div>
        </div>
            </>
          ) : groupActive == "Forms" ? (
            <>
             <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <QueryForm  ></QueryForm>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              QF
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Query Form Builder
          </div>
        </div>
            </>
          ) : groupActive == "Notifications" ? (
            <>
             
        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Message ></Message>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              M
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Message
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Badge ></Badge>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              B
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Badge
          </div>
        </div>

        <div 
          className='widgetItemIn'
          onDragStart={() => setOver(false)}
          onDragEnd={() => {
            setOver(true)
            setAddWidgetActive(false)
            setGroupActive(null)
          }}

          ref={(ref) => 
            connectors?.create(
              ref,
             <Toast ></Toast>
            )
          }
        >
          <Tooltip style={{display:"flex", justifyContent:"center", alignItems:"center", paddingBottom:"0"}} title="Container" placement="right">
            <Item className="m-2 pb-2 cursor-pointer block" move>
              T
            </Item>
          </Tooltip>
          <div className='widgetItemName'>
          Toast
          </div>
        </div>

            </>
          ) : null  } </div> } </div>  : null }
        

          </div> : null
          }

<div className="viewsHeader" style={{alignItems:"center", justifyContent:"space-between"}}>
        
        {true? <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
          {true && <> 
          { enabled && <div onClick={() => {
            actions.setOptions((options) => (options.enabled = false));
            // if(html) {
            //   const json = lz.decompress(lz.decodeBase64(html));
            //   actions.deserialize(json)
            // } else {
            //   actions.deserialize({"ROOT":{"type":{"resolvedName":"Container"},"isCanvas":true, "props":{"flexDirection":"column","alignItems":"flex-start","justifyContent":"flex-start","fillSpace":"no","padding":["40","40","40","40"],"margin":["0","0","0","0"],"background":{"r":255,"g":255,"b":255,"a":1},"color":{"r":0,"g":0,"b":0,"a":1},"shadow":0,"radius":0,"width":"100%","height":"518px"},"displayName":"Container","custom":{"displayName":"App"},"hidden":false,"nodes":[],"linkedNodes":{}}})
            // }
            }} style={{cursor:"pointer", width:"100px", display:"flex", justifyContent:"center", alignItems:"center", color:"red", padding:"0 5px", height:"44px"}}>
            Discard
          </div>}
         { enabled && <div onClick={() => {
            actions.setOptions((options) => (options.enabled = false));
            // setPreviewMode(true)
            }} style={{cursor:"pointer", width:"100px", display:"flex", justifyContent:"center", alignItems:"center", color:"#116dff", padding:"0 5px", height:"44px"}}>
            Preview
          </div>}
          
          </>}
          { <div style={{cursor:"pointer", width:"100px", display:"flex", justifyContent:"center", alignItems:"center", color:"white", backgroundColor:"#116dff",margin:"0 5px", padding:"0 18px", height:"30px", borderRadius:"15px"}}
          onClick={() => {
            actions.setOptions((options) => (options.enabled = !enabled));
            enabled && updateTemplateFunc()
          }}
        >
          {enabled ? 'Save' : 'Edit'}
          </div>}
        </div> : null }
        </div>
        <Header/>
          <div
            className={cx([
            `craftjs-renderer flex-1 h-full w-full transition pb-8 overflow-auto`,
            {
              'bg-renderer-gray': enabled,
            },
            ])}
            ref={(ref) => connectors.select(connectors.hover(ref, null), null)}
            style={{ 
            borderRadius:true ? "0px" : "15px", display:"flex", justifyContent:"center", width:true ? "100%" : true ? "800px" : "360px"}}
          >
            {children}
          </div>
        </div>  
      </div>
    </div>
  );
};
