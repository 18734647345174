import './index.scss';
import * as React from 'react';
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { SampleBase } from './sample-base';

import * as data from './dataSource.json';

class Component extends SampleBase {
  
  listObj;
  temp = 'countries';
  //define the filtering data
  searchData = data[this.temp];
  // maps the appropriate column to fields property
  fields = { text: 'name'};
  // filtering event handler to filter a Country
  onFiltering = (e) => {
      let query = new Query();
      //frame the query based on search string with filter type.
      query = (e.text !== '') ? query.where('name', 'startswith', e.text, true) : query;
      //pass the filter data source, filter query to updateData method.
      e.updateData(this.props.items, query);
  };

  render() {
      return (<div className='control-pane'>
      <div className='control-section'>
        <div id='filtering'>
          <ComboBoxComponent {...this.props.allEvents} id="country" ref={(ComboBox) => { this.listObj = ComboBox; }} dataSource={this.props.items} filtering={this.onFiltering.bind(this)} allowFiltering={true} fields={this.fields} placeholder={this.props.placeholder} popupHeight="270px"/>
        </div>
      </div>
          
    </div>);
    }
}

export default Component